import { Grid, Typography, useTheme } from "@mui/material";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { useTranslation } from "react-i18next";

export default function Accepted({ event }) {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Grid container spacing={1} pb="8px" pt="0px">
      <Grid item xs={1} md={0.7} container justifyContent="flex-start">
        <PersonOutlineOutlinedIcon
          style={{ width: "14px", color: theme.palette.text.primary }}
        />
      </Grid>
      <Grid
        item
        container
        xs={11}
        alignItems="center"
        style={{ color: theme.palette.text.primary }}
      >
        <Typography variant="h3" fontWeight="400">
          {/* {event.totalAccepted}/ */}
          {event.totalResponded}/
          {event?.skedingParticipants?.length +
            event?.nonskedingParticipants?.length +
            " " +
            t("responded")}
        </Typography>
      </Grid>
    </Grid>
  );
}
