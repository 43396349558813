import { useState } from "react";

import { Box, Typography } from "@mui/material";

import SnackbarComponent from "components/Global/SnackbarComponent";
import MeetingComponent from "components/meetings/MeetingComponent";
import { useTranslation } from "react-i18next";

export default function Meetings({
  meetings,
  tab,
  setMeetingDeleted,
  setMeetingResponded,
  setMeetingDetailPopup,
  meetingDetailPopup,
  handleDownload,
  calendarRef,
}) {
  //pending
  const [cancelSuccess, setCancelSuccess] = useState(false);
  const { t } = useTranslation();
  //invites
  const [acceptDialog, setAcceptDialog] = useState(false);
  //reject
  const [secondaryOpen, setSecondaryOpen] = useState(false);
  //messages
  var acceptMessage = "Meeting invite accepted successfully!";

  const handleClose = () => {
    setAcceptDialog(false);
  };

  return (
    <>
      {/* pending cancel case */}
      <SnackbarComponent
        open={cancelSuccess}
        handleClose={() => setCancelSuccess(false)}
        message={t("cancel_msg")}
        vertical={"bottom"}
        horizontal={"center"}
      />
      {/* invite accept case */}
      <SnackbarComponent
        open={acceptDialog}
        handleClose={handleClose}
        message={t(acceptMessage)}
        vertical={"bottom"}
        horizontal={"center"}
      />
      {/* invite reject case */}
      <SnackbarComponent
        open={secondaryOpen}
        handleClose={() => setSecondaryOpen(false)}
        message={t("reject_msg")}
        vertical={"bottom"}
        horizontal={"center"}
      />

      {meetings.map((event, i) => {
        return (
          // if the next start date is different from the current start date display the date above the card
          <Box key={event.id}>
            {event?.start?.getDate() !== meetings[i + 1]?.start?.getDate() ? (
              <Typography
                align="center"
                width="100%"
                variant="body2"
                color="#3C4242"
                sx={{ opacity: "0.5" }}
              >
                {/* {event?.start.toLocaleString('default', { month: 'short', day: 'numeric' })} */}
                {event?.start && !isNaN(event?.start.getTime())
                  ? event.start.toLocaleString("default", {
                      month: "short",
                      day: "numeric",
                    })
                  : t("date_no")}
              </Typography>
            ) : null}
            <MeetingComponent
              i={i}
              event={event}
              tab={tab}
              setMeetingDeleted={setMeetingDeleted}
              setMeetingResponded={setMeetingResponded}
              setMeetingDetailPopup={setMeetingDetailPopup}
              meetingDetailPopup={meetingDetailPopup}
              handleDownload={handleDownload}
              calendarRef={calendarRef}
              setCancelSuccess={setCancelSuccess}
              setAcceptDialog={setAcceptDialog}
              setSecondaryOpen={setSecondaryOpen}
              t={t}
            />
          </Box>
        );
      })}
    </>
  );
}
