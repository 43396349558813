import ColorIndicator from "../meetings/ColorIndicator";
import React from "react";

export default function Vibrant() {
  return (
    <>
      <ColorIndicator text={"legend_one_to_one"} color={"#72B0EE"} />
      <ColorIndicator text={"legend_group_meetings"} color={"#B488F6"} />
      <ColorIndicator text={"legend_website_bookings"} color={"#CFEE54"} />
      <ColorIndicator text={"legend_tasks"} color={"#96DCFF"} />
      <ColorIndicator text={"legend_external_meetings"} color={"#97EEDE"} />
      <ColorIndicator text={"legend_external_events"} color={"#F5D04F"} />
    </>
  );
}
