import makeStyles from "@mui/styles/makeStyles";
const useStyle = makeStyles((theme) => ({
  container: {
    marginLeft: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  noMsg: {
    flexGrow: 1,
    fontWeight: 500,
  },
}));

export default useStyle;
