import React, { useContext, useEffect, useState } from "react";
import { PreviewButtonGroup } from "./Availability";
import DialogWithCloseIcon from "../DialogWithCloseIcon";
import { AddButtonWithIcon, ButtonGroup } from "./Services";
import { useTranslation } from "react-i18next";
import { Box, Container, Typography, useTheme } from "@mui/material";
import useStyle from "./styles";
import Textfield from "./Textfield";
import PhysicalLocation from "../NewEvent/PhysicalLocation";
import Phone from "../NewEvent/Phone";
import * as yup from "yup";
import useWebsiteStore from "../../Utils/websiteStore";
import ErrorSnackbar from "../ErrorSnackbar";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import SnackbarComponent from "../Global/SnackbarComponent";
import { serverPOST } from "../../Utils/HttpFunctions";
import Location from "../NewEvent/Location";
import { RoomNo } from "../../pages/RoomNo";
import { useLocation } from "react-router-dom";
import { integrationContext } from "../../context/Integrations";
import VirtualLink from "../../components/NewEvent/Link";
import { isValidPhoneNumber } from "react-phone-number-input";
import WebsiteLocationPreview from "./Preview/WebsiteLocationPreview";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";

export type WebsiteLocation = {
  name: string;
  locationType: string;
  email: string;
  phone_number?: string;
};

export const schema = yup
  .object({
    name: yup.string().required("Location name is required"),
    email: yup
      .string()
      .email("Invalid email address")
      .required("Email is a required field"),
  })
  .required();

export default function WebsiteLocations() {
  const [loading, setLoading] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const theme = useTheme();
  const classes = useStyle(theme);
  const [addWebsiteLocationDialog, setAddWebsiteLocationDialog] =
    useState(false);
  const { t } = useTranslation();
  const websiteLocations = useWebsiteStore((store) => store.websiteLocations);
  const setWebsiteLocations = useWebsiteStore(
    (store) => store.setWebsiteLocations
  );
  const calendarData = useWebsiteStore((store) => store.calendarData);
  const setCalendarData = useWebsiteStore((store) => store.setCalendarData);
  const [message, setMessage] = useState("");
  const [editIndex, setEditIndex] = useState(-1);
  const [editLocation, setEditLocation] = useState(null);

  useEffect(() => {
    if (calendarData && calendarData.locations?.length > 0) {
      setWebsiteLocations(calendarData.locations);
    } else {
      setWebsiteLocations([]);
    }
  }, [calendarData]);

  const handleAddWebsiteLocationDialog = () => {
    const newVal = !addWebsiteLocationDialog;
    if (!newVal) {
      setEditLocation(null);
      setEditIndex(-1);
    }
    setAddWebsiteLocationDialog(newVal);
  };
  const addWebsiteLocation = async () => {
    setLoading(true);

    const response = await serverPOST(`/web-integration`, {
      locations: websiteLocations,
    });
    if (response && response.constructor !== String) {
      setCalendarData(response.data.calendarData);
      setMessage("Locations have been updated");
    }
    setLoading(false);
  };

  const handleDeleteLocation = (i) => {
    setWebsiteLocations(websiteLocations.filter((x, ind) => ind !== i));
  };
  return (
    <div>
      {showPreview ? (
        <WebsiteLocationPreview />
      ) : (
        <>
          <Box className={classes.serviceMainContainer}>
            {websiteLocations?.map((location, i) => {
              return (
                <Container maxWidth="xs" className={classes.serviceBox}>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="h3"
                      fontWeight="500"
                      style={{ textTransform: "capitalize", marginBottom: 8 }}
                      align="left"
                    >
                      {location.name}
                    </Typography>
                    <Box
                      style={{
                        display: "flex",
                      }}
                    >
                      <EditOutlinedIcon
                        fontSize="small"
                        onClick={() => {
                          setEditLocation(location);
                          setEditIndex(i);
                          handleAddWebsiteLocationDialog();
                        }}
                      />
                      <DeleteOutlineRoundedIcon
                        fontSize="small"
                        style={{
                          color: "red",
                          cursor: "pointer",
                        }}
                        onClick={() => handleDeleteLocation(i)}
                      />
                    </Box>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <LocationOnOutlinedIcon
                      style={{
                        width: "14px",
                        color: theme.palette.text.primary,
                      }}
                    />
                    <Typography
                      variant="h3"
                      align="left"
                      style={{ marginLeft: 5, fontWeight: "400" }}
                    >
                      {location.locationType}
                    </Typography>
                  </Box>

                  <Box display="flex" alignItems="center">
                    <PhoneOutlinedIcon
                      style={{
                        width: "14px",
                        color: theme.palette.text.primary,
                      }}
                    />
                    <Typography
                      variant="h3"
                      align="left"
                      style={{ marginLeft: 5, fontWeight: "400" }}
                    >
                      {location.phone_number}
                    </Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <EmailOutlinedIcon
                      style={{
                        width: "14px",
                        color: theme.palette.text.primary,
                      }}
                    />
                    <Typography
                      variant="h3"
                      align="left"
                      style={{ marginLeft: 5, fontWeight: "400" }}
                    >
                      {location.email}
                    </Typography>
                  </Box>
                </Container>
              );
            })}
          </Box>
          <AddButtonWithIcon
            handleClick={handleAddWebsiteLocationDialog}
            label={t("add_business_location")}
          />
        </>
      )}
      <PreviewButtonGroup
        onSave={addWebsiteLocation}
        loading={loading}
        showPreview={showPreview}
        setShowPreview={setShowPreview}
      />
      <AddWebsiteLocation
        addWebsiteLocationDialog={addWebsiteLocationDialog}
        handleAddWebsiteLocationDialog={handleAddWebsiteLocationDialog}
        editLocation={editLocation}
        editIndex={editIndex}
      />

      <SnackbarComponent
        open={!!message}
        handleClose={() => setMessage("")}
        message={message}
        vertical={"bottom"}
        horizontal={"center"}
      />
    </div>
  );
}

function AddWebsiteLocation({
  addWebsiteLocationDialog,
  handleAddWebsiteLocationDialog,
  editLocation,
  editIndex,
}) {
  const [addMessage, setAddMessage] = useState("");
  const [websiteLocation, setWebsiteLocation] = useState({} as WebsiteLocation);
  const classes = useStyle();
  const { t } = useTranslation();
  const [room, setRoom] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const websiteLocations = useWebsiteStore((store) => store.websiteLocations);
  const setWebsiteLocations = useWebsiteStore(
    (store) => store.setWebsiteLocations
  );
  const { user } = useContext(integrationContext);
  const locationProps = useLocation();
  const [message, setMessage] = useState("");
  const [link, setLink] = useState(() =>
    user && user.virtual_link ? user.virtual_link : ""
  );
  const [location, setLocation] = useState(
    locationProps?.state?.location
      ? locationProps?.state?.location
      : user && user.is_google_synced
      ? "Google Meet"
      : // : user && user.is_microsoft_synced
        // ? "Outlook"
        ""
  );

  useEffect(() => {
    if (editLocation) {
      setWebsiteLocation(editLocation);
    } else setWebsiteLocation({} as WebsiteLocation);
  }, [editLocation]);

  const handleChange = (e) => {
    let val = typeof e === "string" ? e : e?.target?.value;
    const name =
      typeof e === "string" || e === undefined
        ? "phone_number"
        : e?.target?.name;
    setWebsiteLocation({
      ...websiteLocation,
      [name]: val ? val : "",
    });
  };

  const handleWebsiteLocationSave = async () => {
    var loc =
      location === "Other"
        ? address
        : location === "Phone"
        ? phone
        : location === "Link"
        ? link
        : location;

    let locationObj = {
      locationType: loc,
      meeting_room:
        location === "Other" ? (room ? room : undefined) : undefined,
    };
    try {
      if (
        !isValidPhoneNumber(websiteLocation.phone_number || "") &&
        websiteLocation.phone_number
      ) {
        setMessage("Please enter correct number");
        return;
      }
      const newWebsiteLocation = { ...websiteLocation, ...locationObj };
      const errors = await schema.validate(newWebsiteLocation);

      if (editLocation) {
        let temp = [...websiteLocations];
        temp[editIndex] = newWebsiteLocation;
        setWebsiteLocations(temp);
        setAddMessage("Website location has been edited");
      } else {
        let temp = [...websiteLocations, newWebsiteLocation];
        setWebsiteLocations(temp);
        setAddMessage("Website location has been added");
      }

      handleAddWebsiteLocationDialog();
    } catch (errors) {
      console.log("🚀 ~ handleWebsiteLocationSave ~ errors:", errors);
      setMessage(errors?.errors?.[0]);
    }
  };
  return (
    <>
      <DialogWithCloseIcon
        open={addWebsiteLocationDialog}
        handleClose={handleAddWebsiteLocationDialog}
        dialogSize={"xs"}
      >
        <Box className={classes.addServiceContainer}>
          <Typography variant="h2">
            {editIndex !== -1
              ? t("edit_business_location")
              : t("add_business_location")}
          </Typography>

          <Textfield
            label={t("location_name")}
            placeholder={t("business_location_desc")}
            value={websiteLocation.name}
            handleChange={(e) => {
              setWebsiteLocation({ ...websiteLocation, name: e.target.value });
            }}
          />

          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "left",
            }}
          >
            <Typography variant="h4" align="left">
              {t("location")}
            </Typography>
            <Location
              setLocation={setLocation}
              location={location}
              user={user}
              notNewMeeting={undefined}
              showBorder={false}
              isService={true}
            />

            {location === "Other" && (
              <Box className={classes.locationContainer}>
                <Typography variant="h4">{t("address")}</Typography>
                <PhysicalLocation
                  setAddress={setAddress}
                  address={address}
                  isBorder={false}
                />
                {/* <Typography variant="h4">{t("room")}</Typography> */}
                {/* <RoomNo room={room} setRoom={setRoom} isService={true} /> */}
              </Box>
            )}
            {location === "Phone" && (
              <Box className={classes.locationContainer}>
                <Typography variant="h4">{t("phone_number")}</Typography>
                <Phone
                  setPhone={setPhone}
                  phone={phone}
                  isDisabled={undefined}
                  showBorder={false}
                />
              </Box>
            )}
            {location === "Link" && (
              <Box className={classes.locationContainer}>
                <Typography variant="h4">{t("virtual")}</Typography>
                <VirtualLink setLink={setLink} link={link} isService={true} />
              </Box>
            )}
          </Box>

          <Textfield
            label={t("email_address")}
            value={websiteLocation.email}
            handleChange={handleChange}
            placeholder={t("enter_email")}
            isBorder={false}
            name={"email"}
          />

          <Box className={classes.label} style={{ gap: 8 }}>
            <Typography variant="h4">{t("phone_number")}</Typography>
            <Phone
              setPhone={handleChange}
              phone={websiteLocation.phone_number}
              isDisabled={undefined}
              showBorder={false}
            />
          </Box>

          <ButtonGroup
            handleCancel={handleAddWebsiteLocationDialog}
            classes={classes}
            handleSave={handleWebsiteLocationSave}
            t={t}
          />
        </Box>
      </DialogWithCloseIcon>
      <ErrorSnackbar
        open={!!message}
        handleClose={() => setMessage("")}
        message={message}
        vertical={"bottom"}
        duration={3000}
        horizontal={"center"}
      />
      <SnackbarComponent
        open={!!addMessage}
        handleClose={() => setAddMessage("")}
        message={addMessage}
        vertical={"bottom"}
        horizontal={"center"}
      />
    </>
  );
}
