import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
    ellipsis: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': 2, /* number of lines to show before truncating */
      '-webkit-box-orient': 'vertical',
    },
});