import { useEffect, useState } from "react";

import { Button } from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

import { serverPOST } from "Utils/HttpFunctions";
import { useStyle } from "styles/tooltip";
import DialogWithSnackbar from "components/DialogWithSnackbar";
import ErrorSnackbar from "../ErrorSnackbar";
import InviteCollaboratorsForm from "./InviteCollaboratorsForm";
import { useTranslation } from "react-i18next";
import ToolTip from "../Global/ToolTip";

/**
 * Button for inviting users to skeding using their email
 * Rendered in the top NavBar component
 */
export default function InviteCollaboratorsButton() {
  const [dialog, setDialog] = useState(false);
  const [collaborators, setCollaborators] = useState([]);
  const [formValid, setFormValid] = useState(false);
  const [error, setError] = useState("");
  const { t } = useTranslation();
  const classes = useStyle();

  const DIALOG_CONSTANTS = {
    title: t("invite_title"),
    textContent: t("invite_desc"),
    buttonContent: t("send"),
  };

  /**
   * Disables the send invite button if there are no emails added
   */
  useEffect(() => {
    if (collaborators.length > 0) {
      setFormValid(true);
    } else setFormValid(false);
  }, [collaborators]);

  const openDialog = () => {
    setDialog(true);
  };

  const handleClose = () => {
    setDialog(false);
  };

  /**
   * Makes request to the backend to send email to users in the collaborators array
   */
  const sendInvitaiton = async () => {
    const body = {
      contacts: collaborators,
    };

    const response = await serverPOST("/contact/invite", body);

    if (response && response.constructor !== String) {
      if (!response?.data?.success) {
        setError(t("skeding_user_err"));
      }
    } else {
      setError(t("invite_server_err"));
    }

    return response;
  };

  return (
    <>
      <ToolTip title={t("invite_tooltip")} placement="bottom">
        <Button
          variant="submit"
          onClick={openDialog}
          sx={{
            fontWeight: "500",
            marginRight: "8px",
            whiteSpace: "nowrap",
            padding: "3px 20px 3px 16px",
          }}
          startIcon={<PersonAddIcon style={{ height: "18px" }} />}
        >
          {t("invite")}
        </Button>
      </ToolTip>

      {error ? (
        <ErrorSnackbar
          open={!!error}
          handleClose={() => setError("")}
          message={error}
          vertical={"bottom"}
          horizontal={"center"}
        />
      ) : (
        <DialogWithSnackbar
          open={dialog}
          handleClose={handleClose}
          title={DIALOG_CONSTANTS.title}
          textContent={DIALOG_CONSTANTS.textContent}
          form={
            <InviteCollaboratorsForm
              setCollaborators={setCollaborators}
              collaborators={collaborators}
              t={t}
            />
          }
          buttonContent={DIALOG_CONSTANTS.buttonContent}
          action={sendInvitaiton}
          responseMessage={t("invite_success")}
          errorMessage={t("invite_err")}
          cleanup={() => setCollaborators([])}
          formValid={formValid}
        />
      )}
    </>
  );
}
