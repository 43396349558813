export const termsAndCondition = [
  {
    id: 1,
    title: "OBJECT",
    description: (
      <>
        <p>
          SKEDING SRL is registered in the companies register under number
          0767.981.167. Its registered office is located at rue Faider 29 in
          1050 Brussels.
        </p>

        <p>
          The COMPANY specializes in the supply of services and software
          facilitating the management of projects, including making appointments
          and meetings. The COMPANY operates , as such , a platform to
          centralize the management of appointments/meetings involving the use
          of third-party products, services or platforms ( hereinafter referred
          to as the &quot; SOFTWARE &quot; ) .
        </p>

        <p>
          {" "}
          These general terms and conditions of use and sale (hereinafter
          referred to as the &quot; GTC &quot; ) define the legal and technical
          framework for the use of the SOFTWARE by the CUSTOMER, whether it is a
          use of the free version of the SKEDING SERVICE by CUSTOMERS having the
          status of CONSUMER or PROFESSIONAL, or paid use of the SKEDING PRO
          SERVICE by PROFESSIONAL CUSTOMERS. The COMPANY and the CLIENT are also
          hereinafter jointly referred to as the &ldquo;Parties &rdquo; and
          individually a &ldquo;Party &rdquo; .
        </p>

        <p>
          {" "}
          Concerning the paying SERVICE, the GTC constitute the CONTRACT binding
          the Parties. Concerning the paying SERVICE, the GTC constitute with
          the ORDER FORM, the CONTRACT between the parties. In the event of any
          conflict between the ORDER FORM and the T&amp;Cs, the ORDER FORM shall
          prevail over the T&amp;Cs. The CONTRACT expresses the entirety of the
          agreement between the parties and replaces any other previous
          documents issued by the parties. The CUSTOMER cannot therefore rely on
          any provision of their own general conditions of purchase .
        </p>

        <p>
          The COMPANY reserves the right to modify all or part of the provisions
          of its T&amp;Cs. Any new version will be enforceable against the
          CLIENT one month after its notification , unless the CLIENT objects
          before the expiry of this period. The CUSTOMER agrees to be notified
          of updated T &amp; Cs which will prevail over all others, by email.
        </p>

        <p>
          As part of its duty to inform and advise, the COMPANY has made
          available to the CLIENT the documentation presenting the SOFTWARE
          (hereinafter referred to as the &quot; DOCUMENTATION &quot; ) as well
          as these GTC. It is his responsibility, in particular on the basis of
          this information, to ensure that the SOFTWARE and the SERVICE are
          suitable for his own needs. The DOCUMENTATION and the provisions of
          these GTC are available at any time on the website, in a tab dedicated
          to this purpose, at the address https://skeding.io/ (hereinafter
          referred to as the &quot;SITE&quot; &deg;.
        </p>

        <p>
          By ticking the box corresponding to its acceptance of the GCs, the
          CUSTOMER declares to accept these GCs without reservation. At any
          time, if the CUSTOMER disagrees with one of the clauses of the GC, he
          must immediately terminate the use of the SOFTWARE.
        </p>
      </>
    ),
  },
  {
    id: 2,
    title: "DEFINITIONS",
    description: (
      <>
        <p>
          Each word or expression used in capital letters in this contract has
          the meaning given in the following definitions:
        </p>

        <p>
          <span style={{ fontWeight: 600 }}>ADMINISTRATOR </span>: designates a
          USER chosen by the CLIENT who manages the SERVICE and who determines,
          where applicable , the access rights of each authorized USER .
        </p>

        <p>
          <span style={{ fontWeight: 600 }}>ANOMALY</span> : means a significant
          defect in the execution of the SOFTWARE compared to the functional
          coverage contained in the DOCUMENTATION relating to the SOFTWARE and
          for the conditions of use described in the latter and in accordance
          with the CONTRACT.
        </p>

        <p>
          <span style={{ fontWeight: 600 }}>ORDER FORM</span> : designates all
          the information for the provision of the SERVICE and enabling the
          SERVICE to be activated. It includes in particular, without this list
          being restrictive, the CLIENT's name, the number of USERS, the expiry
          dates . It is communicated by the COMPANY .
        </p>

        <p>
          <span style={{ fontWeight: 600 }}>CUSTOMER</span> : refers to the
          natural person or legal entity to whom the SERVICE is granted. For
          paid SERVICE, its name must appear in the ORDER FORM. For the free
          SERVICE, this is the natural person who has created a user account;
        </p>

        <p>
          {" "}
          <span style={{ fontWeight: 600 }}>CG</span>: designates these general
          conditions of use and sale
        </p>

        <p>
          <span style={{ fontWeight: 600 }}>CONSUMER </span>: designates any
          natural person who acts for purposes that do not fall within the scope
          of his commercial, industrial, craft or liberal activity .
        </p>

        <p>
          <span style={{ fontWeight: 600 }}>CONTRACT</span> : designates the
          ORDER FORM and the T&amp;Cs with regard to the paid SERVICE and these
          T&amp;Cs with regard to the free SERVICE.
        </p>

        <p>
          <span style={{ fontWeight: 600 }}>DOCUMENTATION</span> : refers to the
          information provided by the COMPANY in the form of USER documentation
          accompanying the SOFTWARE and including all the functions and specific
          features of the SOFTWARE .
        </p>

        <p>
          <span style={{ fontWeight: 600 }}>DATA ENCODED BY THE CUSTOMER</span>{" "}
          : refers to the files, documents and information (including PERSONAL
          DATA ENCODED BY THE CUSTOMER) of which the CUSTOMER or a USER that he
          enters, informs, transmits and processes as part of his use of the
          SERVICE.
        </p>

        <p>
          <span style={{ fontWeight: 600 }}>
            PERSONAL DATA ENCODED BY THE CUSTOMER{" "}
          </span>
          : refers to personal data within the meaning of regulation n &deg;
          2016/679 said general regulation on data protection ( &ldquo; GDPR
          &rdquo; ) that the CLIENT or a USER collects, enters, informs ,
          transmits or processes within the framework of the use of the SERVICE
          .
        </p>

        <p>
          <span style={{ fontWeight: 600 }}>PARTY SUPPLIERS </span>: means any
          natural or legal person not affiliated with the COMPANY who offers
          software, services, applications, data, platforms to the CLIENT.
        </p>

        <p>
          <span style={{ fontWeight: 600 }}>SOFTWARE </span>: designates the
          software created by COMPANY and belonging to it as well as all the
          elements associated with it: documents, integrated databases ( Url ,
          applications, etc. ) , agents to be installed on the equipment, . ..
          in the initial version and all subsequent versions;
        </p>

        <p>
          <span style={{ fontWeight: 600 }}> UPDATE </span>: designates the
          improvements made to the SOFTWARE, decided unilaterally by the COMPANY
          , with regard to functional developments and provided that such
          adaptations or developments do not make it necessary to rewrite a
          substantial part of the SOFTWARE. UPDATES also include the correction
          of any ANOMALIES of the SOFTWARE compared to the DOCUMENTATION.
        </p>

        <p>
          <span style={{ fontWeight: 600 }}> PART COMPENSATED</span>: means (1)
          the CUSTOMER when the COMPANY is the Part who Indemnity and (2) the
          COMPANY when the CUSTOMER is the Party that Indemnify.
        </p>

        <p>
          <span style={{ fontWeight: 600 }}> PART WHO INDEMNIFIES </span>:
          designates the COMPANY with respect to CLAIMS ( a ) arising from the
          COMPANY's failure to comply with applicable laws relating to security
          and the protection of personal data as clarified by the roles ,
          responsibilities and obligations defined in these T&amp;Cs (art. 6,
          point 6.2.2. and 6.2.3 of the T&amp;Cs ) or the COMPANY's personal
          data protection policy ; and (ii) CLIENT with respect to CLAIMS
          arising out of CLIENT's failure to comply with (a) applicable security
          and personal data protection laws as clarified by the roles ,
          responsibilities and obligations defined in these GTC (art. 6, point
          6.1) or the personal data protection policy of the COMPANY .
        </p>

        <p>
          <span style={{ fontWeight: 600 }}>TECHNICAL REQUIREMENTS</span>:
          designates the minimum configurations required to allow optimal use of
          the SOFTWARE by the CUSTOMER. They are listed on the COMPANY's website
          or/and on the ORDER FORM .
        </p>

        <p>
          <span style={{ fontWeight: 600 }}> PROFESSIONAL </span>: designates
          any natural or legal person who acts for purposes that fall within the
          scope of his commercial, industrial, craft or liberal activity . Its
          VAT number must appear on the ORDER FORM for the paid SERVICE or its
          SKEDING account for the free SERVICE.
        </p>

        <p>
          <span style={{ fontWeight: 600 }}>CLAIM</span> : means a claim,
          action, complaint or proceeding by a regulatory, administrative or
          judicial authority brought against a Party.
        </p>

        <p>
          <span style={{ fontWeight: 600 }}>
            {" "}
            CLAIM RELATED TO THE PROTECTION OF PERSONAL DATA{" "}
          </span>
          : means a CLAIM arising from (a) failure to comply with by a Part of
          the laws applicable in matter of security and of protection of the
          personal data, as specified by the roles, responsibilities and
          obligations set out in the GC (Art. 6, point 6.3) or of the personal
          data protection policy to the COMPANY.
        </p>

        <p>
          <span style={{ fontWeight: 600 }}>REGULATION, GDPR </span>: refers to
          European Regulation 2016/679 on the protection of personal data, of
          April 27, 2016.
        </p>

        <p>
          <span style={{ fontWeight: 600 }}>SERVICE </span>: means the use of
          the SOFTWARE as a hosted service under the responsibility of the
          COMPANY as described in the ORDER FORM for paid SERVICE or on the
          WEBSITE for free SERVICE;
        </p>

        <p>
          <span style={{ fontWeight: 600 }}> ASSOCIATED SERVICES </span>:
          designates all the services from which the CUSTOMER benefits in
          accordance with the ORDER FORM .
        </p>

        <p>
          {" "}
          <span style={{ fontWeight: 600 }}>SITE </span>: means the COMPANY 'S
          website - https://skeding.io//
        </p>

        <p>
          <span style={{ fontWeight: 600 }}> USER ( USERS in the plural) </span>
          : designates any user authorized to access the software, even
          partially (non-limiting examples: nomadic workstation, workstation
          with free access , mobile terminals, smartphone, servers, printers,
          machines, etc. ) . The number of USERS appears on the ORDER FORM;
        </p>
      </>
    ),
  },
  {
    id: 3,
    title: "SUBSCRIPTION & ACCESS TO THE SERVICE AND/OR ASSOCIATED SERVICES",
    description: (
      <>
        <p style={{ fontWeight: 600 }}>SUBSCRIPTION TO THE SERVICE</p>

        <p style={{ fontWeight: 600 }}>MINORS</p>

        <p>
          Minors, namely natural persons under the age of 18, are not authorized
          to use the SERVICE, whether paid for or free, without the supervision
          of a parent or guardian. For this reason, only an adult is authorized
          to order a paid SERVICE and/or to create a user account.
        </p>

        <p style={{ fontWeight: 600 }}>
          3.1.2. SUBSCRIPTION TO THE SKEDING PAID SERVICE
        </p>

        <p style={{ fontWeight: 600 }}>3.1.2.1 PRE - CONTRACTUAL INFORMATION</p>

        <p>
          The CUSTOMER acknowledges having had communication, prior to placing
          his order and concluding the contract, in a readable and
          understandable manner, of these T&amp;Cs and of all the information
          relating to the service in accordance with the Code of Economic Law,
          and in particular concerning the essential characteristics and the
          price of the SERVICE and all information relating to the legal
          guarantees, the terms of implementation of the guarantees and other
          contractual conditions including the conditions of withdrawal
          concerning the SERVICE.
        </p>

        <p style={{ fontWeight: 600 }}>
          3.1.2.2 . INFORMATION REGARDING THE ORDER
        </p>
        <p>
          {" "}
          In order to place an order, the CUSTOMER must check the box &lsquo;By
          placing an I accept the general conditions of sale &lsquo; of the
          COMPANY .
        </p>

        <p>
          Upon receipt of his order, provided that it is issued in accordance
          with the provisions mentioned herein, the COMPANY will send him an
          order confirmation by e-mail to the address that the CUSTOMER has
          provided . to the COMPANY .
        </p>

        <p>
          A binding contract will be concluded as soon as the CLIENT receives
          the order confirmation, and his order will be processed upon receipt
          of your payment.
        </p>

        <p>
          Any order implies a payment obligation and payment by the CLIENT . The
          CUSTOMER acknowledges having been informed, prior to placing his order
          and concluding the contract, in a legible and comprehensible manner ,
          of this payment obligation.
        </p>

        <p style={{ fontWeight: 600 }}>3.1.2.3. WITHDRAWAL _ _</p>

        <p>
          In accordance with the legal provisions in force, the CLIENT who has
          the status of CONSUMER has a period of 14 days from the date of the
          conclusion of the CONTRACT indicated on the ORDER FORM to exercise his
          right of withdrawal without having to justify reasons or to pay a
          penalty .
        </p>

        <p>
          In accordance with the legal provisions, the CUSTOMER may exercise his
          right of withdrawal by any means, in particular by post or by email
          expressing his desire to retract without any ambiguity , and
          mentioning his contact details and the number of the order concerned.
          by this withdrawal. To help in this process , the CUSTOMER will find
          in the Appendix the standard withdrawal form to be sent to the COMPANY
          by email at the following address: xxxxxxxxxx or by post to SKEDING 's
          address .
        </p>

        <p>
          In the event of exercise of the right of withdrawal, the COMPANY will
          reimburse the sums paid at the latest within 14 days from the date on
          which it was informed of the CLIENT 's decision to withdraw. and
          according to the same means of payment as that used for the order.
        </p>

        <p style={{ fontWeight: 600 }}>
          3.1.3. SUBSCRIPTION TO THE FREE SKEDING SERVICE
        </p>

        <p>
          The CLIENT's subscription to the free service is done by creating a
          user account.
        </p>

        <p style={{ fontWeight: 600 }}>3.1.4. LICENSES</p>

        <p>
          COMPANY grants the CUSTOMER the non-exclusive and non-transferable
          right to use the SERVICE and the SOFTWARE .
        </p>

        <p>
          Concerning paid SERVICES, the conditions of its subscription to the
          paid SERVICE are specifed in THE ORDER FORM, in particular with regard
          to the duration and the number of USERS . By use, we mean the
          exploitation for processing of all or part of the instructions or data
          making up the SOFTWARE and the SERVICE. The right to use the SOFTWARE
          is conferred for the exclusive needs of the CLIENT, within the limit
          of the number of USERS authorized in the ORDER FORM, and to the
          exclusion of any third party, including for rental use. The CLIENT
          shall ensure that only authorized USERS have access to the SOFTWARE
          and the SERVICE.
        </p>

        <p>
          Except from the free subscription, the granting of user licenses and
          the use of the SERVICE can only take place after full payment of the
          invoices, principal and accessory.
        </p>

        <p>Are never included in the SERVICE:</p>

        <p>
          (1) work and interventions concerning the installation and proper
          functioning of the USER workstation and the CLIENT 's infrastructure (
          telecommunications , networks, security equipment ) allowing the
          CLIENT to access and use the SOFTWARE; and (2) work and interventions
          concerning problems caused by error or mishandling by the USERS.
        </p>

        <p style={{ fontWeight: 600 }}>
          3.2. ACCESS TO THE SERVICE &amp; SECURING THE SERVICE
        </p>

        <p>
          The SERVICE can be operated 24 hours a day. In the event of
          interruption of the SERVICE, the COMPANY undertakes to implement all
          the means at its disposal to restore the SERVICE as soon as possible .
          Access to the SOFTWARE will be done remotely , via the Internet
          network. The use of identifiers and passwords (hereinafter , the
          &quot; access codes &quot;), which will be determined by the CUSTOMER,
          will be necessary to access the SERVICE at each connection. These
          access codes are intended to reserve access to authorized USERS of the
          CLIENT, to protect the integrity and availability of the SOFTWARE and
          the SERVICE as well as the confidentiality of the CLIENT'S DATA as
          transmitted by the USERS. The CUSTOMER is solely responsible for the
          conservation, security and integrity of the access codes intended for
          the CUSTOMER or a USER and undertakes to notify the COMPANY of any
          loss or misuse of access codes, as soon as possible and by any means,
          provided that COMPANY confirms this loss or misuse without delay by
          registered letter with acknowledgment of receipt. Until the COMPANY
          receives such confirmation by registered mail with acknowledgment of
          receipt , any action performed by a USER will be deemed to have been
          performed by the CUSTOMER and will be the sole responsibility of the
          CUSTOMER with respect to COMPANY and third parties. The COMPANY
          reserves the right to suspend access to the SERVICE in the event of
          legitimate suspicion of fraudulent use or attempted fraudulent use of
          the SERVICE. The COMPANY will inform the CLIENT as soon as possible of
          such an occurrence.
        </p>

        <p style={{ fontWeight: 600 }}>3.3. RELATED SERVICES</p>

        <p>
          The ASSOCIATED SERVICES means all the services from which the CUSTOMER
          is likely to benefit in accordance with the ORDER FORM .
        </p>

        <p style={{ fontWeight: 600 }}>
          3.3.1. DATA STORAGE AND SECURITY MEASURES
        </p>

        <p>
          All CUSTOMER DATA is stored in a database, on one or more servers
          located on a dedicated and secure site . STOCK ADDRESS
        </p>

        <p>
          The COMPANY undertakes to implement compliant technical means to
          ensure the physical and logical security of the servers and networks
          which are under its responsibility and control .
        </p>

        <p>
          As soon as it becomes aware of it, each of the Parties will, as soon
          as possible, inform the other Party of any fact likely to constitute
          an attack on the physical or logical security of the environment of
          the other Party . (attempted intrusion for example) .
        </p>

        <p>
          The COMPANY undertakes to take all useful precautions in accordance
          with the state of the art to preserve the security of the CUSTOMER 'S
          DATA so that they are not, of its own doing , deformed , damaged or
          communicated to unauthorized third parties .
        </p>

        <p>
          Consequently , the COMPANY undertakes to respect and to ensure that
          its personnel respect the following obligations :
        </p>

        <p>
          (1) copies of documents and CUSTOMER DATA support entrusted to it ,
          except those strictly necessary for the execution of the SOFTWARE ;
          (2) Not to use CUSTOMER DATA for any purpose other than the T&amp;Cs ;
          (3) Not to disclose CUSTOMER DATA to other persons , whether private
          or public , natural or legal persons, unless such disclosure is
          required by law or a judicial or administrative authority competent or
          necessary in the context of a legal action.
        </p>

        <p style={{ fontWeight: 600 }}>3.3.2. SURVEILLANCE SYSTEM</p>

        <p>
          The COMPANY implements all necessary means to ensure traceability of
          USERS by IP, in order to ensure secure operation of the SOFTWARE .
        </p>

        <p>
          The COMPANY will set up an access control to the premises in which the
          services relating to the SOFTWARE are performed, so as to authorize
          access only to persons authorized by the COMPANY or accompanied by
          authorized personnel . The COMPANY will take all measures to avoid
          intrusions.
        </p>

        <p>
          The COMPANY will implement the necessary measures to allow access to
          the SOFTWARE only to persons authorized by the CLIENT.
        </p>

        <p>
          In order to guarantee the confidentiality of data in transit between
          the USER and the SOFTWARE access point , all connections are secure .
          Data flows, which use unsecured telecommunications networks , use
          recognized security protocols such as HTTPS (based on SSL/TLS Secure
          Socket Layer/Transport Layer Security) or SFTP (based on Secure Shell
          &ndash;SSH).
        </p>

        <p style={{ fontWeight: 600 }}>
          3.3.3 ASSISTANCE AND SUPPORT IN CASE OF ANOMALIES
        </p>

        <p>
          Throughout the period of provision of the SERVICE, the COMPANY
          provides USERS with assistance according to the methods and levels
          defined in the ORDER FORM. The purpose of this ASSOCIATED SERVICE is
          to inform the COMPANY of any ANOMALIES of the SOFTWARE , not
          attributable to misuse or non-compliant use or to a modification of
          access by the CUSTOMER.
        </p>

        <p>
          In the event of ANOMALIES of the SOFTWARE noted by the USERS, the
          CUSTOMER must indicate to the COMPANY the conditions of appearance of
          the ANOMALIES as observed by the USERS as well as the level of
          seriousness of the ANOMALY . The COMPANY undertakes to respond within
          a reasonable time to ANOMALIES observed and brought to its attention
          by the CLIENT .
        </p>

        <p style={{ fontWeight: 600 }}>3.3.4. MAINTENANCE &amp; UPDATES</p>

        <p>
          The subscription to the SERVICE includes a maintenance service except
          for the case of an evaluation or free version.
        </p>

        <p>
          The CUSTOMER is informed that legislative and regulatory changes may,
          at any time, make the SOFTWARE unsuitable. If necessary, the COMPANY
          will perform a standard SOFTWARE UPDATE so that the SOFTWARE meets the
          new legal and regulatory provisions, provided that such adaptations or
          developments do not make it necessary to rewrite a substantial part
          .of the SOFTWARE.
        </p>

        <p>
          The CUSTOMER is also informed that the evolution of technologies and
          the demand of the COMPANY 's clientele may lead the COMPANY to carry
          out UPDATES , which may lead to an evolution of the TECHNICAL
          PREREQUISITES whose COMPANY can not be held responsible of.
        </p>

        <p>
          The COMPANY also undertakes to carry out continuous improvements to
          the existing functionalities and the rectification of ANOMALIES in the
          functioning of the SOFTWARE .
        </p>

        <p>
          For the purposes of SOFTWARE maintenance operations, access to the
          SERVICE may be interrupted , generally during non-working hours and on
          non-working days. USERS will be notified in advance of maintenance
          operations by email and through an information window when accessing
          the SERVICE .
        </p>

        <p>
          It is specified that UPDATES do not in any way include the delivery of
          lists, optional modules and future products/services not purchased by
          the CUSTOMER and which are marketed by the COMPANY separately .
          Maintenance does not include services other than those defined herein.
        </p>

        <p style={{ fontWeight: 600 }}>3.3.5. USER TRAINING</p>

        <p>
          USERS have the option of benefiting from a number of hours of training
          enabling them to make optimal use of the SOFTWARE, in the form of a
          service invoiced separately . This ASSOCIATED SERVICE is available
          under the conditions defined in the ORDER FORM.
        </p>

        <p>
          The training sessions are available in the form of tutorial videos
          that can be viewed on the Site in the &ldquo; Help &rdquo; section .
        </p>

        <p style={{ fontWeight: 600 }}>3.3.6. DATABASE TRANSFER</p>

        <p>
          The Company offers the CUSTOMER a service for retrieving and importing
          to the SOFTWARE server the archives available on the CUSTOMER's
          servers for the purpose of building a CUSTOMER DATA base . This
          ASSOCIATED SERVICE is available under the conditions defined in the
          ORDER FORM.
        </p>

        <p style={{ fontWeight: 600 }}>
          3.4. THIRD PARTY SERVICES AND HYPERLINKS
        </p>

        <p>
          3.4.1. THIRD-PARTY SUPPLIER SERVICES The COMPANY provides the CLIENT
          with a communication interface with software, services, applications,
          or THIRD-PARTY SUPPLIER platforms :
        </p>

        <p>
          - Google meet - Microsoft TEAMS - ZOOM - Google Calendar - WEBBEX -
          Skype - Microsoft Office - Apple Icloud - Apple Calendar
        </p>

        <p>
          The SERVICE offered by the COMPANY allows the CUSTOMER to store or
          share through software, services, applications, or THIRD-PARTY
          SUPPLIER platforms the DATA ENCODED BY THE CUSTOMER, or to receive
          elements from other users. The COMPANY does not claim any right of
          ownership over its data and the Customer remains responsible for it.
        </p>

        <p>
          The CUSTOMER is, moreover, solely responsible for compliance with all
          terms and conditions applicable to data, products, services or
          platforms from THIRD-PARTY SUPPLIERS.
        </p>

        <p>
          CUSTOMER understands that the software, services, applications,
          platforms of THIRD-PARTY PROVIDERS may be unavailable from time to
          time, be offered on a limited basis or may vary depending on the
          Customer's country or device or other external factors such as their
          Internet or network connection.
        </p>

        <p>
          The COMPANY cannot be held responsible for the operation or use by the
          CLIENT of the software, services, applications or platforms of
          THIRD-PARTY SUPPLIERS.
        </p>

        <p style={{ fontWeight: 600 }}>
          3.4.2. THIRD-PARTY PROVIDERS HYPERLINKS OR DATA?
        </p>

        <p>
          The site contains links or hyperlinks to external websites or other
          forms of online portals. Such links do not mean that there is a
          relationship between the COMPANY and the owner of the external website
          or even that the COMPANY implicitly agrees with the content of these
          external sites.
        </p>

        <p>
          The COMPANY has no control over external websites. It is therefore not
          responsible for the safe and correct functioning of the hyperlinks and
          their final destination. As soon as the user clicks on the hyperlink,
          he leaves our site. The COMPANY can therefore no longer be held liable
          in the event of subsequent damage.
        </p>

        <p>
          It is possible that these external websites do not offer the same
          guarantees as the COMPANY. Thus, we suggest that you carefully read
          their general conditions of sale but also their disclaimer and their
          privacy policy.
        </p>
      </>
    ),
  },
  {
    id: 4,
    title: "FINANCIAL CONDITIONS FOR THE PAID SERVICE",
    description: (
      <>
        <p style={{ fontWeight: 600 }}>PRICES</p>

        <p>
          The financial conditions as well as the price details are given on the
          ORDER FORM. Transport and customs fees and taxes are the
          responsibility of the CLIENT. Any modification of the scope initially
          determined (number of USERS), will be the subject of an additional
          ORDER FORM .
        </p>

        <p style={{ fontWeight: 600 }}>4.2 PRICE REVISION</p>

        <p>
          The price is liable to be revised annually on January 1 of each year.
          If the CUSTOMER does not accept the price revision, he may terminate
          the CONTRACT and the COMPANY undertakes to reimburse him the price
          paid pro rata temporis of his actual use.
        </p>

        <p style={{ fontWeight: 600 }}>4.3 INVOICING TERMS</p>

        <p>
          Unless otherwise specified in the PURCHASE ORDER, the SERVICE will be
          invoiced monthly or yearly, in arrears. An invoice will be sent to the
          CLIENT on the last day of the month or year preceding payment.
        </p>

        <p style={{ fontWeight: 600 }}>4.4. LATE PAYMENT</p>

        <p>
          Late payment interest calculated at the legal rate and a lump sum
          indemnity of 40 euros for recovery costs are automatically due in the
          event of non-payment on the due date without any prior formal notice
          being necessary.
        </p>

        <p>
          Where applicable, when these costs exceed the amount of this
          compensation, the COMPANY may claim additional compensation from the
          Client, upon presentation of supporting documents specifying the steps
          taken. These indemnities will not be applied in cases where the
          CUSTOMER justifies that he is the subject of a receivership or
          judicial liquidation procedure.
        </p>

        <p>
          The COMPANY reserves the right, thirty (30) days after sending the
          formal notice to pay, in the recommended form, which has remained
          partially or totally without effect, to suspend access to the SERVICE
          and any service in progress until full payment of the sums due. This
          suspension cannot be considered as a termination of the CONTRACT.
        </p>

        <p>
          All unpaid costs, following a bank rejection of a payment by the
          CLIENT, will remain the financial responsibility of the latter.
        </p>

        <p>
          The CUSTOMER undertakes to reimburse the COMPANY for all reasonable
          costs and expenses incurred to recover the unpaid amounts, unless the
          CUSTOMER has disputed the invoice according to the conditions listed
          below in point 4.5.
        </p>

        <p style={{ fontWeight: 600 }}>4.5. CONTESTATION</p>

        <p>
          If the Customer contests in good faith an invoice sent by COMPANY ,
          the latter will inform the COMPANY by registered letter with
          acknowledgment of receipt, specifying the error and this, within
          thirty (30) days following the date of the corresponding invoice. In
          the event of partial dispute of an invoice, the CUSTOMER agrees to pay
          the undisputed portion of the invoice
        </p>
      </>
    ),
  },
  {
    id: 5,
    title: "DURATION OF THE CONTRACT",
    description: (
      <>
        <p>
          Regarding paid SERVICE, the CONTRACT will enter into force on the date
          of issue of the PURCHASE ORDER for a period specified therein . It
          will then be renewed by tacit agreement for identical contractual
          periods, unless terminated by one of the parties sent to the other by
          registered letter with acknowledgment of receipt sixty (60) calendar
          days before the expiry date of the contractual period . in progress
          and without any of the parties being able to claim any compensation
          whatsoever and for any reason whatsoever as a result of such
          denunciation . The price conditions at the time of renewal will be
          those appearing on the COMPANY'S WEBSITE at the time of renewal. The
          fees paid by the CUSTOMER for the current CONTRACT during which the
          aforementioned automatic termination occurs will remain with the
          COMPANY .
        </p>

        <p>
          Concerning the free SERVICE, the CONTRACT will enter into force on the
          date of the creation of the account necessary to access the free
          SERVICE (point 3.1.2.) and will end when said account is deleted by
          the CLIENT or by the COMPANY at the request of the CLIENT or by the
          COMPANY &acute; 2
        </p>
      </>
    ),
  },
  {
    id: 7,
    title:
      "DATA ENCODED BY THE CUSTOMER, RIGHTS AND OBLIGATIONS OF THE PARTIES",
    description: (
      <>
        <p style={{ fontWeight: 600 }}>6.1. DATA ENCODED BY THE CUSTOMER</p>

        <p>
          The DATA ENCODED BY CUSTOMER transmitted , delivered, stored by the
          USERS in the context of the use of the SERVICE on the servers made
          available by the COMPANY remain the exclusive property of the
          CUSTOMER.
        </p>

        <p>
          As such, the CUSTOMER guarantees that he is the holder of all the
          intellectual property rights on the entirety of the pages and data
          downloaded via the SOFTWARE , i.e. say reproduction, representation
          and distribution rights relating to the Internet medium, for a
          previously determined period .
        </p>

        <p>
          Reproduction or use by the COMPANY of DATA ENCODED BY THE CUSTOMER is
          prohibited.
        </p>

        <p>
          As part of the provision of the SERVICE, the DATA ENCODED BY CUSTOMER
          may be subcontracted to service providers listed exhaustively below :
          (processing activities subcontracted, identity and contact details of
          the subcontractor and dates of the subcontracting contract , site of
          the GCU: WhatsApp SMS Slack facebook messenger Files Google Drive
          Microsoft One Drive Dropbox Box CRM hubspot Salesforce Pipedrive Close
          mailchimp ActiveCampaign odoo Zapier Asana Jira Facebook
        </p>

        <p>
          The COMPANY may use another processor to carry out specific processing
          activities. In this case, the COMPANY informs the CUSTOMER beforehand
          in writing of any planned change concerning the addition or
          replacement of other subcontractors . This information will clearly
          indicate the outsourced processing activities, the identity and
          contact details of the outsourcer and the dates of the outsourced
          contract. The CUSTOMER has a minimum period of seven (7) days from the
          date of receipt of this information to present his objections. This
          subcontracting can only be carried out if the CLIENT has not raised
          any objections during the agreed period.
        </p>

        <p>
          The COMPANY undertakes to implement all means to ensure the best
          guarantees on the integrity of the data and in particular:
        </p>

        <p>
          (1) The implementation of an internal COMPANY IT Charter in order to
          recall good practices and processes in terms of data protection and
          confidentiality ; (2) Securing the premises with controlled access ;
          (3) The password policy: authentication with unique access requiring
          an identifier and a &ldquo; robust &rdquo; personal multi - character
          password ; (4) The presence of a confidentiality clause for COMPANY
          employees ; (5) Connection logging ; (6) The protection of
          workstations and servers by setting up anti-virus, installing a
          firewall ; (7) Staff awareness.
        </p>

        <p style={{ fontWeight: 600 }}>
          6.2. PERSONAL CUSTOMER DATA ENCODED BY THE CUSTOMER
        </p>

        <p style={{ fontWeight: 600 }}>
          6.2.1. OBLIGATIONS OF THE CUSTOMER AS RESPONSIBLE FOR PROCESSING
          PERSONAL DATA ENCODED BY THE CUSTOMER
        </p>

        <p>
          CUSTOMER is responsible for processing the PERSONAL DATA ENCODED BY
          CUSTOMER operated through the SOFTWARE. The CUSTOMER is informed that
          it is his responsibility to proceed, under his sole responsibility,
          with the procedures, requests for authorization provided for by the
          laws and regulations in force concerning any processing that it
          carries out.
        </p>

        <p>
          More generally , it will be up to the CUSTOMER to comply with any
          applicable local legislation requiring a specific administrative
          declaration procedure relating to Personal Data . The CUSTOMER
          guarantees to comply with all the obligations incumbent on him
          relating to the PERSONAL DATA ENCODED BY CUSTOMER.
        </p>

        <p>
          As soon as PERSONAL DATA ENCODED BY CUSTOMER is collected by the
          CUSTOMER or transferred by the CUSTOMER , or by the COMPANY on the
          instruction of the CUSTOMER, it is the responsibility of the CUSTOMER
          to ensure that the collection, processing and/or transfer of PERSONAL
          DATA ENCODED BY CUSTOMER is authorized .
        </p>

        <p>
          CUSTOMER is and remains responsible for the PERSONAL DATA ENCODED BY
          CUSTOMER.
        </p>

        <p>
          For the fore-mentioned reasons , the CUSTOMER undertakes to (1)
          respect the obligations incumbent on him in his capacity as data
          controller and (2) respond within fifteen (15) days to any request
          from the COMPANY relating to the processing of PERSONAL DATA ENCODED
          BY CUSTOMER as part of the provision of the SERVICE.
        </p>

        <p>
          In its capacity as data controller, the CUSTOMER determines under its
          responsibility the purposes of the processing entrusted to SKEDING and
          for which they have the processing authorizations, including in
          particular:
        </p>

        <p>
          (1) Contact management; (2) Email management; (3) Calendar management;
        </p>

        <p style={{ fontWeight: 600 }}>
          6.2.2. OBLIGATIONS OF THE COMPANY IN ITS QUALITY OF SUBCONTRACTOR OF
          PERSONAL DATA ENCODED BY THE CUSTOMER
        </p>

        <p>
          The personal data that the COMPANY will have to sub-process concern:
        </p>

        <p>
          (1) The personal data of USERS; (2) The personal data of the contacts
          of each of the USERS or of the CLIENT's contacts;
        </p>

        <p>
          The COMPANY acts as a subcontractor , on the instructions of the
          CLIENT and therefore undertakes to respect the following obligations
          and to ensure that they are respected by its staff and any
          subcontractors, sister companies or affiliated companies:
        </p>

        <p>
          (1) Only process the PERSONAL DATA ENCODED BY CUSTOMER for the sole
          purposes described above , unless the COMPANY is required to do so
          under applicable regulations . In this case, the COMPANY will inform
          the CLIENT of this legal obligation before processing, unless the
          applicable regulations prohibit such information; (2) Ensure that the
          persons authorized to process the PERSONAL DATA ENCODED BY CUSTOMER
          undertake to respect the confidentiality of this data ; (3) Notify the
          CUSTOMER as soon as possible of any requests from the persons
          concerned and cooperate reasonably with the CUSTOMER in order to
          enable him to comply with his obligations under the applicable
          regulations. The CLIENT will bear all reasonable costs resulting from
          the assistance that the COMPANY will provide ; (4) Assist and
          cooperate with the CLIENT in the event of the implementation of an
          impact analysis or prior consultation with a supervisory authority ;
          (5) Implement and maintain a procedure for reporting breaches of
          PERSONAL DATA ENCODED BY CUSTOMER or incidents leading to alerting the
          CUSTOMER as soon as possible; (6) Not to take any copy of the PERSONAL
          DATA ENCODED BY CUSTOMER , documents and information support entrusted
          , except for those necessary for the purposes of the execution of the
          SERVICE or ASSOCIATED SERVICES ; (7) Keep a record if the conditions
          of Article 30 ( 5) GDPR are met; (8) Not to use the PERSONAL DATA
          ENCODED BY CUSTOMER , documents and information processed for purposes
          other than those intended for the SERVICE or ASSOCIATED SERVICES ; (9)
          Not to disclose this data , documents or information to other persons,
          whether private or public , natural or legal persons outside of the
          cases specified in these GC ; (10) Take all measures to avoid any
          misuse or fraudulent use of the data , documents and information
          processed ; (11) Implement the appropriate technical and
          organizational measures in order to guarantee a level of security of
          personal data adapted to the risk and in particular , to prevent them
          from being deformed , damaged or communicated to unauthorized persons
          ; (12) Do not transfer the PERSONAL DATA ENCODED BY CUSTOMER outside
          the territory of the European Union , without having informed the
          CUSTOMER beforehand . Personal data may be transferred outside the
          territory of the European Union . The COMPANY 's service providers
          have adhered to the EU &ndash; US Privacy Shield Framework, the list
          of certified companies of which is accessible at the following address
          : www.privacyshield.gov/list . In the absence of adherence to the
          Privacy Shield Framework, appropriate safeguards are put in place by
          the COMPANY in accordance with Article 46 of the GDPR and the CUSTOMER
          may have access to them by contacting the COMPANY at the address
          mentioned below . _
        </p>

        <p style={{ fontWeight: 600 }}>
          6.3. THIRD - PARTY COMPLAINTS RELATED TO THE PROTECTION OF PERSONAL
          DATA
        </p>

        <p style={{ fontWeight: 600 }}>
          6.3.1. COMPLIANCE WITH APPLICABLE LAWS ON THE PROTECTION AND SECURITY
          OF PERSONAL DATA
        </p>

        <p>
          The COMPANY and the CLIENT will comply with the Applicable laws
          regarding the protection and security of personal data in connection
          with their respective roles as subcontractor and data controller as
          explained in point 6.2.1 and 6.2.2. of this article.
        </p>

        <p style={{ fontWeight: 600 }}>
          6.3.2. COOPERATION AND MUTUAL ASSISTANCE
        </p>

        <p>
          The two Parties will cooperate in good faith to avoid CLAIMS RELATED
          TO THE PROTECTION OF PERSONAL DATA , and if necessary to mitigate the
          consequences, taking into account the rights of the persons concerned
          as well as the reputation and brand of each Party. Each Party agrees
          to limit its losses in connection with such CLAIMS RELATED TO THE
          PROTECTION OF PERSONAL DATA.
        </p>

        <p>
          In the event of a PRIVACY CLAIM, both Parties agree to provide each
          other reasonable assistance to investigate, mitigate and resolve such
          PRIVACY CLAIM. Any information and material exchanged or discovered
          during the review, mitigation and resolution will be considered
          Confidential Information of the Disclosing Party and may not be
          disclosed by the Receiving Party except as permitted under of Article
          17 (Confidentiality).
        </p>

        <p style={{ fontWeight: 600 }}>6.3.3. COMPENSATION</p>

        <p>
          THE INDEMNIFIED PARTY shall pay for the damages of the INDEMNIFIED
          PARTY listed below insofar as they are directly attributable to a
          CLAIM RELATED TO THE PROTECTION OF PERSONAL DATA initiated by a third
          party against the INDEMNIFIED PARTY:
        </p>

        <p>
          (1) the amounts agreed to and negotiated by the INDEMNIFYING PARTY in
          a written settlement agreement, signed by it (to the extent that
          INDEMNIFYING PARTY is authorized to execute such agreement); (2)
          damages finally awarded by a competent court; (3) fines or penalties
          imposed by an independent administrative authority; (4) reasonable
          attorneys' fees; (5) reasonable expenses incurred to satisfy the
          requirements imposed by law to carry out a forensic analysis, to
          monitor the level of credit risk, and to inform the persons concerned
          of the occurrence of the incident which gives rise to the Complaint
          related to the Protection of Personal Data, if applicable.
        </p>

        <p style={{ fontWeight: 600 }}>6.3.4. EXCEPTION</p>

        <p>
          The INDEMNIFIED PARTY shall not be liable for ANY CLAIMS RELATING TO
          THE PROTECTION OF PERSONAL DATA resulting from acts or omissions of
          the INDEMNIFIED PARTY which interfere or prevent THE INDEMNIFIED PARTY
          from complying with Applicable Laws relating to the protection and
          security of personal data.
        </p>

        <p style={{ fontWeight: 600 }}>
          6.3.5. SOLE &amp; EXCLUSIVE REMEDY BETWEEN PARTIES
        </p>

        <p>
          In addition to the remedies expressly provided for in these GTC and
          allowing termination or suspension, the remedy for compensation
          provided for in article 6 (point 6.3.3.), constitutes the only remedy
          of the INDEMNIFIED PARTY with regard to the PART WHO COMPENSATES when
          an incident has given rise to a CLAIM RELATED TO THE PROTECTION OF
          PERSONAL DATA, notwithstanding any provision to the contrary appearing
          in the other provisions of the GTC.
        </p>

        <p style={{ fontWeight: 600 }}>
          6.4. DATA SHARING AT THE INITIATIVE OF THE CUSTOMER AND USERS
        </p>

        <p>
          When the CUSTOMER or a USER shares DATA ENCODED BY THE CUSTOMER with
          other people, in particular through software from THIRD-PARTY
          SUPPLIERS, the CUSTOMER understands that these people may, throughout
          the world, use, copy, record, reproduce, distribute , transmit, share
          and display the encoded information.
        </p>

        <p>
          If the CLIENT or a USER does not want others to have access to the
          data, he must not use the sharing options or allow USERS to use it.
        </p>

        <p>
          The CUSTOMER declares and guarantees that for the duration of the use
          of the SERVICE, that he has and will have all the necessary rights on
          the data that he uploads, stores or shares on or through the SERVICE,
          and that the collection, use and retention of such data through the
          SERVICE does not violate any law or third-party rights.{" "}
        </p>
      </>
    ),
  },
  {
    id: 8,
    title: "OWNERSHIP OF SOFTWARE AND SOURCES",
    description: (
      <>
        <p>
          In accordance with the provisions relating to intellectual property
          and the GTC, the COMPANY holds all the intellectual property rights
          relating to the SOFTWARE and the documentation that accompanies it ,
          as well as all their updates, old, current and future versions, and of
          all developments carried out by the COMPANY . These rights are in no
          way transferred to the CLIENT .
        </p>

        <p>
          The CUSTOMER is prohibited from infringing in any way whatsoever on
          the intellectual property rights of the COMPANY on the SOFTWARE . It
          is expressly forbidden for the CUSTOMER to use or use the SOFTWARE in
          a way that does not comply with these T&amp;Cs. In particular, he is
          prohibited from carrying out :
        </p>

        <p>
          (1) Any reproduction of the SOFTWARE in any form and on any type of
          medium whatsoever, in particular by modification, merger or inclusion
          in another software and/or modification of the documentation which
          accompanies it ; (2) Any reproduction other than a backup copy, it
          being specified that the backup copies are the property of the COMPANY
          ; (3) Any translation, adaptation, arrangement or modification of the
          SOFTWARE; (4) Any representation of the SOFTWARE on any type of
          support whatsoever; (5) Any provision of the SOFTWARE in whole or in
          part and by any means, rental, transfer, etc. ; (6) Any disclosure,
          marketing or use of the SOFTWARE for the benefit of third parties as
          well as any training of third parties in the use of all or part of the
          SOFTWARE; (7) Any compilation of the SOFTWARE, subject to the
          Applicable legal provisions ; (8) Any modification of the SOFTWARE or
          intervention on it, whatever its nature, including for the purpose of
          correcting errors likely to affect the operation of the SOFTWARE ,
          insofar as the right to correct said errors is reserved only to the
          COMPANY . Without prejudice to the other stipulations of the GTC, any
          breach by the CLIENT of this obligation releases the COMPANY from all
          of its warranty obligations and responsibilities ; (9) Any disclosure
          of SOFTWARE performance.
        </p>

        <p>
          The databases supplied with the SOFTWARE (Url, applications, etc. )
          benefit from the same property rights and must not be the subject of
          any attempt of analysis. They must not be queried outside of normal
          use of the SOFTWARE (example: generation of artificial traffic, etc.).
        </p>

        <p>
          The mentions of the rights of the COMPANY on the SOFTWARE and on any
          document accompanying it , whatever they may be , must in any case be
          maintained . No right is granted by the GC on the trademarks, patents
          of which the COMPANY is or will be the holder. It is recalled that the
          intellectual property rights of the COMPANY have a duration greater
          than the duration of the authorized use of the SERVICE. Consequently,
          the CUSTOMER's compliance with all of these rights must continue
          beyond the expiry of the SERVICE .
        </p>
      </>
    ),
  },
  {
    id: 9,
    title: "CUSTOMER WARRANTIES, OBLIGATIONS & PROHIBITIONS",
    description: (
      <>
        <p>
          In the event of non-compliance by the CLIENT or a USER with one of the
          guarantees or obligations incumbent on them individually or
          collectively under this article, the COMPANY reserves the right to
          suspend the SERVICE , after an email informing the CLIENT of such
          suspension. This option of suspension is without prejudice to the
          COMPANY 's other remedies and in particular its right to terminate the
          CONTRACT.
        </p>

        <p style={{ fontWeight: 600 }}>8.1 WARRANTIES</p>

        <p>
          CUSTOMER declares and guarantees to the COMPANY that he has the skills
          giving him the means to assess the exact scope of the characteristics
          of the SOFTWARE and the SERVICE and the use for which they are
          intended. The CUSTOMER confirms that he is interested in using the
          SOFTWARE and the SERVICE for his own needs . The CUSTOMER acknowledges
          that he must not use the SOFTWARE and the SERVICE to test its
          performance and functionality in order to offer competing services.
          The CUSTOMER guarantees to have provided the COMPANY with all relevant
          information likely to allow the lawful and normal use of the SOFTWARE
          and the SERVICE.
        </p>

        <p>
          The CUSTOMER declares and warrants to the COMPANY that he has the
          hardware, software and means allowing him to use the SERVICE
          (PREREQUISITES ) . The PREREQUISITES relate in particular to the
          version of the internet browser used to access the platform. The
          CUSTOMER declares and guarantees that he is familiar with the
          characteristics and constraints of the Internet, the response times
          for consulting, querying or transferring information. The CLIENT
          declares to be informed of the security measures put in place by
          COMPANY , but acknowledges that due to the characteristics of the
          Internet, security applies for COMPANY as an obligation of means. The
          CUSTOMER is fully responsible for the installation of appropriate
          security updates and patches . The COMPANY , its affiliates and its
          third-party suppliers are not responsible for any interruptions,
          delays, cancellations, delivery failures, loss of data , content
          alterations, loss of packets, or other damages resulting from these
          problems . .
        </p>

        <p>
          CUSTOMER declares and guarantees to the COMPANY that all the
          operations carried out by him, including the USERS, directly or
          indirectly through the SERVICE , will comply with the legislation
          applicable to his activities and the GTC. The CLIENT takes all
          measures to ensure that the regulations are applied by all its
          employees. It shall indemnify COMPANY against any action brought for
          violation of Applicable Laws resulting from unauthorized use by the
          CLIENT .
        </p>

        <p style={{ fontWeight: 600 }}>8.2 OBLIGATIONS</p>

        <p>
          8.2.1. OBLIGATION TO RESPECT THE LAWS AND REGULATIONS IN FORCE AND NOT
          TO INFRINGE THE RIGHTS OF THIRD PARTIES OR PUBLIC ORDER.
        </p>

        <p>
          The COMPANY does not control the validity or legality of the data
          entered or accessed by the CLIENT and of the activities carried out by
          the CLIENT through the SERVICE. The CUSTOMER is responsible for the
          data he enters through the SERVICE (list of USERS , messages to USERS,
          content accessed , etc. ) or for the use he and the USERS make of the
          SERVICE and more broadly of the compliance with the law by the CLIENT
          and the USERS, and guarantees COMPANY against any claim by a third
          party in this respect and will indemnify the COMPANY for the
          consequences (damages, convictions, costs, including justice and
          lawyers, etc.) of such a request. It is up to the CUSTOMER to ensure
          that he has the hardware, software and means enabling him to use the
          SOFTWARE and the SERVICE. The CUSTOMER undertakes not to modify,
          reproduce or pirate the SOFTWARE and/or not to modify or create
          another website in order to mislead potential customers of the COMPANY
          and lead them to believe that this website is associated with the
          COMPANY .
        </p>

        <p>
          The CUSTOMER is solely responsible for the aforementioned information
          with regard to third parties. Consequently, the CUSTOMER guarantees
          the COMPANY against any CLAIM from a third party based on this
          information and will indemnify the COMPANY against all consequences
          (damages, costs, including lawyers' fees, etc.) or of any condemnation
          pronounced in favor of said third parties against the COMPANY by an
          enforceable court decision.
        </p>

        <p>
          The CUSTOMER undertakes, for himself and the USERS , not to pursue
          illegal objectives, download, transmit or distribute viruses, illicit,
          malicious, misleading content, infringing morality, third parties or
          their rights, or encouraging the pursuit of illegal activities, to
          interfere with or disrupt the SOFTWARE, servers or networks connected
          to the SOFTWARE.
        </p>

        <p style={{ fontWeight: 600 }}>
          8.2.2. OBLIGATION TO USE THE SOFTWARE AND THE SERVICE UNDER ITS
          CONTROL, ITS MANAGEMENT AND UNDER ITS SOLE RESPONSIBILITY.
        </p>

        <p>
          The CUSTOMER uses the software and the service under his control , his
          management and under his sole responsibility . As such, the CLIENT is
          responsible in particular for:
        </p>

        <p>
          (1) The implementation of all useful processes and measures intended
          to protect its USERS' workstations, its equipment, software packages,
          software, passwords, in particular against any virus and intrusions;
          (2) The implementation of the necessary backup means, external to the
          SERVICE provided by COMPANY. (3) Compliance with the latest updated
          version of the TECHNICAL PREREQUISITES in order to avoid harmful
          consequences such as slowdowns, blockages, data corruption; (4) The
          choice of access provider or telecommunications medium, the Customer
          having to take charge of the administrative requests and take out the
          necessary subscriptions, the cost of which he will bear; (5) If
          applicable, the appointment, among its staff, of a privileged contact
          of the COMPANY acting as ADMINISTRATOR, for the CUSTOMER, within the
          framework of the SERVICE and in particular with regard to security
          aspects; (6) The use of identifiers and access codes created by the
          Customer for Users. He will ensure that no person not authorized by
          him has access to the Solution. In the event of unauthorized access,
          the Customer will inform COMPANY without delay; (7) Errors made by its
          staff in the use of the Solution and the procedures that allow it to
          connect to the Website and its Content, in particular concerning the
          means of access and Internet browsing. COMPANY will be released from
          any responsibility concerning the nature, the content of the
          information or data of the Customer and the exploitation which results
          therefrom.
        </p>

        <p>
          In the event of non-compliance with its commitments, COMPANY reserves
          the right to limit, restrict, interrupt or permanently suspend all or
          part of the SERVICE provided for an indefinite period, in the event of
          non-compliance with the warnings sent by COMPANY.
        </p>

        <p>
          Any use of the SOFTWARE or the SERVICE that does not comply with its
          intended purpose as referred to in the T&amp;Cs would constitute an
          infringement of the rights to use the SOFTWARE and therefore an act of
          counterfeiting.
        </p>

        <p style={{ fontWeight: 600 }}>
          8.2.3. OBLIGATION NOT TO INFRINGE THE RIGHTS OF THIRD-PARTY PROVIDERS
          THROUGH THE SOFTWARE
        </p>

        <p>
          The CUSTOMER undertakes to manage alone his relationship with any
          publisher, supplier or service provider other than the COMPANY ( the
          &quot; THIRD- PARTY SUPPLIERS &quot; ) and to ensure that any
          agreement entered into , even tacitly with a THIRD-PARTY SUPPLIER, is
          compatible with the execution of this AGREEMENT and allows the company
          not to infringe the rights of the said THIRD- PARTY SUPPLIERS .
        </p>

        <p style={{ fontWeight: 600 }}>
          8.4. PROHIBITIONS Unless expressly authorized by the provisions of the
          CONTRACT, the CLIENT is not authorized to :
        </p>

        <p>
          (1) Use the SOFTWARE or the SERVICE (1) in violation of Applicable
          laws or regulations or in connection with illegal content (such as
          content contrary to the regulations applicable to obscene or
          defamatory language or content, harassment, privacy, advertising, or
          intellectual property rights); or (2) in a manner that involves a
          significant risk to the security or operations of COMPANY or its other
          customers, or to the normal pursuit of the business of COMPANY or its
          other customers. (2) Transmit viruses or other codes that may cause
          the SOFTWARE and the SERVICE to malfunction or be damaged. (3) Copy,
          use, distribute, republish, download, display, transmit, sell, rent,
          host or sublicense the SOFTWARE or SERVICE; (4) Make accessible, use
          or allow the use of the SOFTWARE or the SERVICE on behalf of third
          parties, or to third parties, whether in the context of IT services,
          third-party outsourcing services, in the form of a subscription, a
          bureau service, a timeshare use or a hosted service, or on behalf of a
          third party; (5) Attempt to interact with the underlying operating
          system of the SOFTWARE or SERVICE, or modify, create derivative works
          from, adapt, reverse engineer (including monitoring or accessing
          inputs and outputs flowing in system or application), translate,
          decompile, or otherwise attempt to discover the source code, data
          representation, algorithms, and underlying processes and methods. This
          restriction does not apply when it is intended to limit the public
          order rights enjoyed by the CLIENT under the applicable law or
          regulation; (6) Delete, obscure or modify any proprietary notices
          associated with the SERVICE; (7) Use components, software modules or
          other services which may be made available with the SERVICE but which
          are not licensed and identified in the ORDER FORM; (8) Share its login
          credentials and passwords or allow the use of the same login
          credentials simultaneously by two or more USERS, and the CUSTOMER is
          responsible for any unauthorized access to login credentials and
          passwords.
        </p>
      </>
    ),
  },
  {
    id: 10,
    title: "WARRANTIES, OBLIGATIONS & LIABILITY OF THE COMPANY",
    description: (
      <>
        <p>
          The COMPANY guarantees the conformity of the SOFTWARE and the SERVICE
          with the DOCUMENTATION listed on the ORDER FORM or its WEBSITE. The
          COMPANY does not guarantee that the SOFTWARE or the SERVICE is free
          from all faults or hazards : the COMPANY undertakes exclusively to
          remedy , with all the diligence reasonably possible, the ANOMALIES
          observed in relation to the DOCUMENTATION. The Customer must notify
          the COMPANY in writing of the existence of such ANOMALIES as soon as
          possible to benefit from this guarantee. Within the framework of this
          guarantee, the COMPANY undertakes to reimburse the CLIENT for the
          price paid pro rata temporis of its actual use in the event that the
          COMPANY fails to remedy the said ANOMALIES . The COMPANY excludes all
          other express or implied guarantees, in particular any guarantee: (1)
          that the SERVICE would present specific qualities or qualities
          expected by the CUSTOMER, that it would correspond to the needs,
          expressed or not, of the CUSTOMER, or that it would be adapted to a
          given use , (2) that all flows or sites of an illicit or offensive
          nature or containing malicious codes or more generally those belonging
          to categories that the CUSTOMER wishes to prohibit, be filtered by the
          SERVICE. The CUSTOMER takes responsibility for the quality of the
          filtering vis - à -vis the populations it wishes to protect . The
          CUSTOMER uses access to the SERVICE at his own risk as soon as he
          acknowledges that, despite the high level of security standards
          implemented by the COMPANY : (1) The data transmitted by the Internet
          pass through independent electronic communication networks having
          various characteristics and capacities and which are sometimes
          overloaded , (2) The Internet is an open network and, therefore, the
          information transmitted by this means is not protected against the
          risks of misappropriation , fraudulent , malicious or unauthorized
          intrusion in the CLIENT's information system , hacking , extraction or
          unauthorized alteration of data , system programs and files,
          contamination by computer viruses, It is the responsibility of the
          CUSTOMER to take all appropriate measures including raising awareness
          and controlling the activities of its USERS in order to protect their
          information system and their data against contamination by viruses and
          attempts unauthorized intrusion by third parties. 9.1.2. WARRANTY
          AGAINST THIRD PARTY INTELLECTUAL PROPERTY CLAIMS 9.1.2.1. THIRD PARTY
          INTELLECTUAL PROPERTY CLAIMS The COMPANY undertakes for the duration
          of the CONTRACT or the use of the SERVICE, to defend at its own
          expense any CLAIM by third parties against the CUSTOMER as soon as the
          said third party maintains in this CLAIM that (1) the SOFTWARE or
          SERVICE directly infringes any patent, copyright, or trademark of any
          third party; or (2) COMPANY misappropriates a trade secret of a third
          party (“ infringement claim ” ) . The COMPANY will indemnify the
          Customer for any damages for which the CLIENT has been definitively
          condemned by a competent court (or for any amounts agreed in a written
          settlement agreement signed by the COMPANY ) . Concerning the paying
          SERVICE, on the occasion of its defense or within the framework of the
          amicable settlement of any CLAIM in Infringement , the COMPANY may ,
          at its option and at its expense: (1) Obtain for the Customer a
          license allowing him to continue to use the SERVICE in accordance with
          the terms of the CONTRACT; (2) Replace or modify the allegedly
          infringing SERVICE in order to avoid infringement ; Where (3) When the
          options offered in (1) or (2) above are not reasonably or commercially
          feasible, terminate Customer's licenses and access to the SERVICE (or
          the infringing part) and refund the royalties prepaid cards unused at
          the date of termination . The COMPANY shall not be liable for any
          infringement CLAIMS that result from: (1) the SERVICE or the SERVICE
          in violation of the AGREEMENT ; (2) Modification of the SERVICE or the
          SOFTWARE by the CLIENT (or any third party acting on behalf of the
          CLIENT); (3) The CLIENT 's failure to install the latest version of
          the SOFTWARE or SERVICE as requested by COMPANY to avoid
          counterfeiting ; or (4) Third-party products, services, materials ,
          software and other materials , possibly combined with the SERVICE or
          the SOFTWARE if , without this combination, the SERVICE or the
          SOFTWARE would not be infringing. The INDEMNIFIED PARTY, if any, shall
          not be liable for any infringement CLAIMS that result from any act or
          default of the INDEMNIFIED PARTY that: (1) Does not notify the
          INDEMNIFIED PARTY in writing of the occurrence of a CLAIM , promptly
          and from the time it became aware of the said CLAIM , or from the time
          it was notified , to the extent that the INDEMNIFIED PARTY suffers
          prejudice as a result of this breach; (2) Fails to provide the
          INDEMNIFIED PARTY reasonable assistance requested by THE INDEMNIFIED
          PARTY in the defense or settlement (as the case may be) of the CLAIM ;
          (3) Does not provide the Indemnifying Party with the exclusive right
          to control and the power to settle the Claim ; or (4) Makes any
          representations or acknowledges or admits any wrongdoing or liability
          of the INDEMNIFIED PARTY with respect to the CLAIM without the prior
          written consent of the INDEMNIFIED PARTY . 9.1.2.2. SOLE AND EXCLUSIVE
          REMEDY BETWEEN PARTIES In addition to the remedies expressly provided
          for in the GTC and allowing termination or suspension, the remedy for
          compensation provided for in article 9 (point 9.1.2.1), constitutes
          the only remedy of the INDEMNIFIED PARTY with regard to the
          COMPENSATING PARTY. when an incident has given rise to a COMPLAINT
          related to intellectual property , notwithstanding any provision to
          the contrary appearing in the other provisions of the GTC. 9.2.
          OBLIGATIONS The COMPANY undertakes to inform the CLIENT of any event
          that could compromise the provision of the SERVICE, including if this
          event is attributable to a third party. The COMPANY undertakes to
          implement all the means necessary for the execution of the SERVICE,
          despite any opposite condition mentioned in the GC. The COMPANY
          undertakes to take all the care and diligence necessary to provide a
          quality service in accordance with the practices of the profession.
          The COMPANY thus undertakes to : (1) Intervene quickly in the event of
          an incident. In the event of a serious incident affecting the proper
          functioning of the SOFTWARE, the COMPANY will endeavor to take all
          necessary measures for the continuity of the SERVICE. During this
          period the CUSTOMER accepts that, due to this incident, the
          performance of the SOFTWARE may be partially reduced; (2) Ensure that
          the quality of the SERVICE is maintained at the highest level; (3)
          Allow the customer to mask/encrypt their personal data; (4) Protect,
          as far as possible, its hosting platform. In general , the COMPANY
          undertakes for the duration of the CONTRACT or the use of the SERVICE
          to regularly inform the CUSTOMER of any useful element of which he may
          become aware. Regarding the PAID service, the COMPANY may design ,
          organize and size the SOFTWARE, modify it and develop it as needed
          with the partners and suppliers of its choice without the CLIENT's
          prior written consent, provided that this does not does not reduce the
          COMPANY's commitments as set out in the CONTRACT. 9.3. RESPONSIBILITY
          _ To the extent permitted by law, the FREE SERVICE is always provided
          as is and COMPANY expressly disclaims all warranties and warranties of
          any kind, express or implied, including, without limitation ,
          warranties of merchantability and fitness for a particular purpose. In
          other cases, the CLIENT expressly acknowledges that the COMPANY is
          only subject to an obligation of means. The CUSTOMER expressly
          acknowledges having received from the COMPANY all the information
          allowing him to personally assess the suitability of the SOFTWARE and
          the SERVICE to his needs and to take all useful precautions for the
          operation and implementation of the SERVICE. The CUSTOMER remains in
          any case responsible for his computer equipment as well as that used
          in connection with COMPANY . At no time does the COMPANY guarantee the
          reliability or operation of the telecommunications networks and means
          used . Concerning PROFESSIONALS, the COMPANY assumes no responsibility
          concerning in particular the financial or commercial damages , direct
          or indirect, resulting from the choice and the implementation of the
          SERVICE, such as the loss of profit, the increase in general expenses
          damages, criminal risk, disruption of planning, loss of profit,
          customer or expected savings, etc. Defects or errors resulting from
          improper use of the SOFTWARE or the SERVICE by the CUSTOMER or by the
          persons for whom he is responsible, as well as defects or errors
          resulting from an unauthorized modification of the SOFTWARE or its
          environment are excluded from the precaution. feels guaranteed. In any
          case, the COMPANY can not be held responsible for the non-performance
          of its obligations provided for in the GC, if the non-performance is
          due to force majeure. The Customer undertakes to limit the extent of
          its damage in the event of default by the other Party. Only non-
          avoidable damage will be compensated . Subject to the preceding
          provisions and insofar as the Law authorizes it , if the
          responsibility of COMPANY is engaged under any SERVICE provided or
          referred to in the CONTRACT, for any cause and on any basis whatsoever
          , the damages for which COMPANY will be liable, for all damages
          combined, will in any case be limited to the sums paid by the CLIENT
          under this contract for the SERVICE and pro rata temporis of its use.
        </p>
      </>
    ),
  },
  {
    id: 11,
    title: "COLLABORATION",
    description: (
      <>
        <p>
          The Parties agree that the execution of the SERVICE requires active
          and regular collaboration and that the permanent exchange of
          information makes it possible to avoid the generation of incidents
          prejudicial to the interests of both parties. Consequently, each of
          the Parties undertakes to : (1) Be actively involved in the
          performance of its obligations ; (2) Abstain from any behavior likely
          to affect and/or hinder the performance of the obligations of the
          other Party ; (3) Provide each other within a sufficient time,
          compatible with the proper respect of the deadlines agreed between the
          Parties, all information and documents necessary for the proper
          performance of their obligations ; (4) Alert each other as quickly as
          possible in the event of difficulties and work together to implement
          the best possible solution as soon as possible .
        </p>

        <p>
          For the paying SERVICE, it will be up to the CUSTOMER in particular to
          appoint a single point of contact (ADMINISTRATOR) as well as a
          substitute who will be named in the Order Form.
        </p>

        <p>
          Furthermore, the CUSTOMER undertakes to maintain sufficiently
          competent, qualified and trained USERS in place throughout the
          duration of the execution of these presents and to inform the COMPANY
          of any difficulties of which it may be aware.
        </p>
      </>
    ),
  },
  {
    id: 12,
    title: "LIMITATION OF USE WITHIN THE FRAMEWORK OF THE PAID SERVICE – AUDIT",
    description: (
      <>
        <p>
          The CUSTOMER has the right to use the SOFTWARE and the SERVICE within
          the limitations specifed in THE ORDER FORM and the DOCUMENTATION,
          particularly with regard to the number of USERS . The CUSTOMER
          acknowledges having implemented a mechanism enabling it to guarantee
          that the number of USERS does not exceed that defined in the ORDER
          FORM. If the CUSTOMER wishes to extend the conditions of use , he must
          pay additional fees . For this he can contact the COMPANY . The
          COMPANY may , at any time, carry out an audit to ensure compliance
          with the limitations of use . If the limitations of use are exceeded ,
          the COMPANY will contact the CLIENT to adjust the rights. If no
          regularization is made within fifteen (15) days, the COMPANY reserves
          the right to block access to the SERVICE by technical means. The
          CUSTOMER acknowledges that the use of the SERVICE or certain
          functionalities thereof may be limited , prohibited or require
          specific conditions by applicable law ( information of employees ,
          traceability , interoperability with third-party equipment in
          compliance with the provisions relating to the applicable intellectual
          property of the country, etc.). ARTICLE 12. ILLEGAL ACTIVITIES COMPANY
          reserves the right to investigate complaints or violations reported to
          the GCs and to take any action it deems appropriate, including without
          limitation reporting to law enforcement agencies, regulators or other
          third parties of any suspicion of illegal activity , and to disclose
          any necessary or appropriate information to the aforementioned persons
          and entities regarding user profiles, email addresses, usage
          histories, posted materials, IP addresses, and traffic information .
          The COMPANY reserves the right to pursue all available legal remedies
          for violations of the GC , including without limitation blocking
          access by an Internet address to this Website or to any other provider
          websites and their functionality . The actual or potential violation
          of certain clauses of the GC (including in particular the provisions
          on the intellectual property, the Licence, the protection of the
          private life , personal data and confidentiality) is deemed to
          immediately cause irreparable damage which would be difficult to
          quantify and which could not be remedied solely by the payment of
          damages . The COMPANY may request an injunction, temporary or
          definitive or any other fair retribution for such violation of the
          contractual stipulations. The CUSTOMER guarantees that he uses the
          SERVICE provided by COMPANY in compliance with applicable laws and
          regulations, particularly in tax matters . More specifically , in the
          event that the COMPANY is held jointly and severally liable by the tax
          authorities for the payment of reminders of rights issued due to the
          irregular use by the CLIENT of the Solution at its disposal , the
          CUSTOMER undertakes to indemnify COMPANY in full , ie up to the sums
          claimed by the administration.
        </p>
      </>
    ),
  },
  {
    id: 13,
    title: "TERMINATION OF THE CONTRACT",
    description: (
      <>
        <p>
          Without prejudice to any other rights, COMPANY has the right to
          terminate the CONTRACT at any time under the following conditions: (1)
          If the CLIENT does not meet the obligations stipulated in the
          CONTRACT, the COMPANY may , after sending a registered letter with
          acknowledgment of receipt to the CLIENT , terminate automatically and
          without prior notice to this contract , without prejudice to any
          damages ; (2) In the event of the implementation of restructuring
          decisions relating to all or a substantial part of the CLIENT's
          assets, the COMPANY may automatically terminate this contract with a
          fifteen (15) days notice , by sending a registered letter with
          acknowledgment of receipt to the CLIENT . (3) In the event of expiry
          of the CONTRACT , whatever the cause, and within a period of fifteen
          (15) days following this expiry, the CUSTOMER must on the one hand
          stop using the SOFTWARE and the SERVICE and on the other hand either
          confirm in writing to COMPANY that it has proceeded to the destruction
          of all copies and copies ( total or partial) of the different versions
          of the SOFTWARE (including the documentation and the backup copy ), or
          return those copies and documentation to it. In addition , the
          CUSTOMER undertakes to pay the COMPANY all sums remaining due under
          this contract . The CUSTOMER may terminate the contract at any time
          but remains liable for all amounts that may be due until the end of
          the term of the CONTRACT and cannot, under any circumstances, claim a
          refund corresponding to the period or the SERVICE will no longer be
          used.
        </p>
      </>
    ),
  },
  {
    id: 14,
    title: "ASSIGNMENT OF THE CONTRACT",
    description: (
      <p>
        This contract having, by express and decisive agreement an intuitive
        persona dimension, the CUSTOMER can not neither sell his rights and
        obligations to any third part, in any form whatsoever, even for a short
        time, neither to support a society, unless the COMPANY expressly and
        previously ha given his agreement Iin n writing.
      </p>
    ),
  },
  {
    id: 15,
    title: "NON-SOLICITATION",
    description: (
      <p>
        The CUSTOMER undertakes not to solicit or employ , directly or
        indirectly, any member of the COMPANY's staff , even if the initial
        solicitation is made by this person . This prohibition is valid for the
        duration of the CONTRACT or the use of the SERVICE and a period of two
        years from the expiry of the CONTRACT or the use of the SERVICE without
        prejudices of any damages or interests.
      </p>
    ),
  },
  {
    id: 16,
    title: "PROCESSING OF PERSONAL DATA BY THE COMPANY",
    description: (
      <>
        <p>
          As part of the SERVICE, the COMPANY collects certain data within the
          meaning of the GDPR as data controller.
        </p>

        <p>
          This PERSONAL DATA ENCODED BY THE CUSTOMER is collected and processed,
          on a contractual basis, for the purposes of managing the commercial
          relationship and for the purpose of sending information emails
          concerning the COMPANY's new services and products. Furthermore, the
          CLIENT's data may be processed for the purpose of sending any
          commercial prospecting in connection with its professional activity
          due to the COMPANY's legitimate commercial interest.
        </p>

        <p>
          Within the framework of these purposes, the categories of personal
          data processed are the following: professional contact details and
          those of users, account identification data, banking data. The
          recipients of the data are the persons in charge of customer relations
          within the COMPANY and the subcontractors of the COMPANY ensuring the
          backup of the data.
        </p>

        <p>
          Personal data may be transferred outside the territory of the European
          Union. The COMPANY's service providers have adhered to the EU &ndash;
          US Privacy Shield Framework, the list of certified companies of which
          is accessible at the following address: www.privacyshield.gov/list. In
          the absence of adherence to the Privacy Shield Framework, appropriate
          safeguards are put in place by the COMPANY in accordance with Article
          46 of the GDPR and the CLIENT can access them by contacting the
          COMPANY at the address mentioned below.
        </p>

        <p>
          For the management of the commercial relationship, the CUSTOMER'S
          PERSONAL DATA are kept for the duration of the contractual
          relationship between the COMPANY and the CUSTOMER, increased by the
          legal deadlines relating to the proof of these, and within the
          framework of prospecting commercial operations within a period of
          three years at the end of which they are definitively erased except in
          the event of new contact with the prospect.
        </p>

        <p>
          In application of the GDPR, the CLIENT as well as the USERS have the
          rights to their personal data (1) to access, (2) to rectify, (3) to
          delete, (4) to limit and (5) to object and (6) portability of personal
          data to a third party provider where applicable. They can assert their
          rights by contacting privacy@skeding.io, by justifying their
          identities.
        </p>

        <p>
          In the event of a dispute, the CLIENT and the USERS have the right to
          contact the CNIL. Any request to delete data, which may be made at any
          time to the above-mentioned address, is likely to prevent the
          execution of the SERVICE when it relates to data strictly necessary
          for this execution.
        </p>
      </>
    ),
  },
  {
    id: 18,
    title: "CONFIDENTIALITY",
    description: (
      <>
        <p>
          Information relating to the CONTRACT, and in general , and without
          this list being exhaustive, to the business project of the Parties and
          their present and future activities, their personnel, their know-how,
          whether this information is obtained directly or indirectly from the
          other party, its employees, its subcontractors, agents or service
          providers. Confidential Information is provided &quot;as such&quot;,
          without any warranty, expressed or implied, concerning its accuracy or
          integrity .
        </p>

        <p style={{ fontWeight: 600 }}>
          Are not considered confidential information:
        </p>

        <p>
          (1) Information that is publicly available without breach of the terms
          of the AGREEMENT by the party disclosing or using it; (2) Information
          validly held by one party prior to its disclosure by the other ; (3)
          Information validly obtained from a third party authorized to transfer
          or disclose such information, without breach of an obligation of
          confidentiality .
        </p>

        <p style={{ fontWeight: 600 }}>Each party undertakes :</p>

        <p>
          (1) Not to use the confidential information, for any reason
          whatsoever, except in execution of the rights and obligations arising
          from the CONTRACT, (2) Not to disclose confidential information to
          anyone, by any means whatsoever, except to those of their employees ,
          service providers or subcontractors to whom this information is
          necessary for the execution of the CONTRACT , during any the duration
          of the CONTRACT and for five (5) years after its termination ,
          whatever the cause.
        </p>

        <p>
          For the protection of the confidential information of the other party
          , each party undertakes to take the minimum protective measures that
          it would take to protect its own confidential information, and
          undertakes to ensure that its employees, service providers and
          possible subcontractors having access to confidential information have
          signed , prior to any disclosure for their benefit, a confidentiality
          agreement whose obligations are equivalent to those appearing in this
          article.
        </p>

        <p>
          Except when Article 6 of the GC is applicable, each party acknowledges
          that any of them who uses or discloses without authorization
          confidential information obtained from the other party during the
          negotiations engages its responsibility under the conditions of the
          common right.
        </p>
      </>
    ),
  },
  {
    id: 19,
    title: "FORCE MAJEURE",
    description: (
      <>
        <p>
          Parties may be held responsible for any breach whatsoever of its
          obligations, if it has been prevented from performing its obligation
          by an event of force majeure .
        </p>

        <p>
          In addition to the events usually retained by the case law of French
          courts and tribunals as falling under force majeure or fortuitous
          event, the parties expressly agree to attribute the effects of force
          majeure to the following events, even if even they would not present
          all the characteristics :
        </p>

        <p>
          Total or partial strikes at the CLIENT and/or COMPANY , lockouts , bad
          weather , epidemics , blockage of means of transport or supply for any
          reason whatsoever, earthquake, fire , storm , flood, water damage ,
          governmental or legal restrictions, legal or regulatory commercial
          changes, blocking of telecommunications, unforeseeable breakdown of
          infrastructures, massive computer attacks on infrastructures;
        </p>

        <p>
          The obligations thus suspended will be executed again as soon as the
          effects of the cause(s) of non-execution have come to an end, within a
          period which will depend on the availability at the time .
        </p>

        <p>
          In this case, the Party invoking force majeure shall notify the other
          Party , by registered letter with acknowledgment of receipt, as soon
          as possible, of the occurrence of such an event and the necessary
          extension of the expiry dates of its obligations.
        </p>

        <p>
          If the impediment is temporary , the performance of the obligation
          will be suspended until the Party invoking force majeure is no longer
          impeded by the event of force majeure . The Party invoking force
          majeure must keep the other Party informed and undertakes to do its
          best to limit the duration of the suspension.
        </p>

        <p>
          In the event that the suspension continues beyond a period of three
          (3) months, each of the Parties shall have the possibility of
          terminating the CONTRACT without compensation by notifying the other
          Party of its decision by registered letter with acknowledgment of
          receipt .
        </p>
      </>
    ),
  },
  {
    id: 20,
    title: "RIGHTS OF THIRD PARTIES",
    description: (
      <p>
        The provisions of item 9.3 of Section 9 regarding the COMPANY's
        limitation of liability and shall apply to the benefit of the COMPANY
        and its members, directors, employees , agents, licensors , or
        suppliers. Each of these individuals or entities is entitled to claim
        and enforce these provisions directly against the CLIENT and for its own
        account. No other party is entitled to enforce any provision of the GTC,
        without prejudice to the rights and remedies of existing third parties.
      </p>
    ),
  },
  {
    id: 21,
    title: "INSURANCE",
    description: (
      <p>
        <p>
          The CUSTOMER declares to have taken out the necessary insurance (and
          to keep it in force) concerning all the risks related to its activity
          and the use of the SERVICE, with a notoriously solvent insurance
          company, for all the pecuniary consequences of its liability for any
          damage caused to the COMPANY and/or any third party in the context of
          the use of the SERVICE, as well as, where applicable, for any damage
          that it may suffer in the context of the use of the SERVICE
        </p>
      </p>
    ),
  },
  {
    id: 22,
    title: "GENERAL PROVISIONS",
    description: (
      <>
        <p style={{ fontWeight: 600 }}>
          21.1. COMMUNICATIONS &amp; NOTIFICATIONS
        </p>

        <p>
          Unless the GC expressly provide otherwise, any notice under the
          AGREEMENT must be given in writing by electronic mail to the following
          addresses (or addresses notified in writing by either Party):
        </p>

        <p>
          (1) COMPANY : xxxxxxx@skeding.xyz ; and (2) To the CUSTOMER: to the
          Customer 's e-mail address indicated in the ORDER FORM or when opening
          an account .
        </p>

        <p>
          By express agreement, any Communication or notification sent by
          COMPANY will be deemed to have been received and read by the CLIENT
          within 5 days of its dispatch . It is therefore up to the CUSTOMER to
          regularly update and consult this email address. He will be solely
          responsible for the violation of his obligations in this regard.
        </p>

        <p style={{ fontWeight: 600 }}> 21.2. INTEGRALITY OF CONTRACT</p>

        <p>
          The CONTRACT constitutes the entirety of the agreement of the Parties
          as to its subject matter and replaces and prevails over all
          conventions, agreements, proposals, discussions, negotiations,
          commitments, written and oral, relating to this subject matter.
        </p>

        <p style={{ fontWeight: 600 }}>21.3. WAIVER</p>

        <p>
          All waiver, what what about that is the duration, at invoking the
          existence or total or partial violation of any of the clauses of the
          CONTRACT cannot constitute a modification, deletion of the said clause
          or a waiver to invoke previous violations, concomitant Where posterior
          of the same clause or other clauses. Such waiver shall be effective
          only if expressed in writing signed by the person duly empowered at
          this effect.
        </p>

        <p style={{ fontWeight: 600 }}>
          21.4. HIERARCHY OF CONTRACTUAL DOCUMENTS
        </p>

        <p>
          In the event of any conflict between the terms of the documents
          constituting the CONTRACT, the Order Form shall prevail over the GC
          (for the purposes of the conflict in question). The GCs are written in
          French . In the event that they are translated into one or more
          languages, only the French text will prevail in the event of a dispute
          or COMPLAINT.
        </p>

        <p style={{ fontWeight: 600 }}>21.5. INTERPRETATION</p>

        <p>
          All terms and conditions of the CONTRACT are binding. Each of them is
          a determining condition of this AGREEMENT without which the Parties
          would not have contracted . In case of difficulty of interpretation
          between any of the titles appearing at the head of the clauses, and
          any of the clauses, the titles will be declared non - existent. Words
          and expressions in capital letters refer to their contractual
          definition.
        </p>

        <p style={{ fontWeight: 600 }}>21.6. INDEPENDENCE OF THE PARTIES</p>

        <p>
          AGREEMENT may not be interpreted as an agency agreement, joint venture
          or partnership. Neither Party can bind the other Party or make
          commitments on behalf of the other.
        </p>

        <p style={{ fontWeight: 600 }}>21.7. PARTIAL DISABILITY</p>

        <p>
          The possible cancellation of one or more clauses of these GC cannot
          affect its other stipulations which will continue to produce their
          full and complete effect.
        </p>

        <p>
          In the event that the execution of one or more of the clauses of these
          GC is made impossible due to its cancellation, the Parties will
          attempt to come together in order to establish a new clause, the
          spirit and letter of which will also be close as possible to the old
          clause, the other stipulations remaining in force.
        </p>

        <p>
          In the event that any of the clauses of the CONTRACT would be declared
          null or inapplicable by any jurisdiction whatsoever and this, by a
          final decision, this clause will be deleted without this resulting in
          the nullity of the entire contract, all the clauses of which will
          remain in full force .
        </p>

        <p>
          However, in the event that the nullity or inapplicability of a clause
          of the CONTRACT would seriously affect the legal and/or economic
          balance of the latter, the Parties agree to meet in order to
          substitute for the said clause, a clause valid that is as close as
          possible to it both legally and economically.
        </p>

        <p>
          If at any time, it appears that any of the clauses and/or conditions
          stipulated in this AGREEMENT goes against the provisions of a treaty ,
          a law, a regulation, national or international, the Parties undertake
          not to terminate this AGREEMENT and to make , in respect of its
          economy, all the modifications necessary to bring it into harmony with
          its provisions without any compensation being claimed in this respect
          by either part .
        </p>

        <p style={{ fontWeight: 600 }}>21.8. APPLICABLE RIGHT</p>

        <p>
          This CONTRACT will be governed and interpreted in accordance with
          Belgian law, without taking into account the application of the rules
          and principles of conflict of laws or the United Nations Convention on
          CONTRACTS for the International Sale of Goods, the application of
          which is expressly excluded.
        </p>

        <p style={{ fontWeight: 600 }}>21.9. DISPUTE</p>

        <p>
          For any complaints, please contact customer service at the following
          address: xxxxxxxxx.
        </p>

        <p>
          With regard to CONSUMERS, the Consumer Mediation Service is an
          autonomous public service which handles the complaint itself with the
          aim of reaching an amicable settlement when no other mediation service
          can help the consumer. CUSTOMER :
        </p>

        <p>
          Address : North Gate II Boulevard du Roi Albert II 8 box 1 1000
          Brussels Tel : +32 2 702 52 20 Fax: +32 2 808 71 29 Email:
          contact@mediationconsommateur.be Website: www.mediationconsommateur.be
        </p>

        <p>
          In accordance with Article 14 of Regulation (EU) No 524/2013 , the
          European Commission has set up an Online Dispute Resolution platform ,
          facilitating the independent out -of-court settlement of disputes in
          line between consumers and professionals in the European Union .
        </p>

        <p>
          This platform is accessible at the following link:
          https://webgate.ec.europa.eu/odr/
        </p>

        <p>
          If the CUSTOMER is a CONSUMER and in the absence of an amicable
          agreement via one of these voluntary procedures, the competent court
          will be that designated by application of the rules of common law for
          any dispute concerning the interpretation or the execution of these
          general conditions of sale .
        </p>

        <p>
          If the CUSTOMER is a PROFESSIONAL and in the absence of an amicable
          agreement via one of these voluntary procedures , the competent
          jurisdiction will be that of the location of the registered office of
          the COMPANY .
        </p>
      </>
    ),
  },
];
