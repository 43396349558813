import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  drawer: {
    width: "176px",
  },
  paperDrawer: {
    width: "180px",
    overflow: "hidden",
    backgroundColor: "#479AE9",
    "&:hover": {
      backgroundColor: "#479AE9",
      borderRadius: 0,
    },
  },
  active: {
    opacity: 1,
  },
  inactive: { opacity: 0.7 },
  icon: {
    width: "16px",
    height: "16px",
    borderRadius: "50%",
    // backgroundColor: "#479AE9",
    color: "#FFFFFF",
    margin: "0px 8px 0px 12px",
  },
}));
