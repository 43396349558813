import React, { useState, useEffect, useContext } from "react";
import { serverGET } from "Utils/HttpFunctions";
import loggingInDev from "loggingInDev";
import { authContext } from "./Auth";

export const integrationContext = React.createContext();

export default function IntegrationProvider({ children }) {
  const [user, setUser] = useState();
  const { isLogged } = useContext(authContext);

  useEffect(() => {
    const abortController = new AbortController();
    if (isLogged === true) {
      getUser(abortController);
    }
    return () => {
      abortController.abort();
    };
  }, [isLogged]);

  const getUser = async (abortController) => {
    let response;
    if (abortController) {
      response = await serverGET(
        "/account/integration",
        abortController.signal
      );
    } else {
      response = await serverGET("/account/integration");
    }

    if (response && response.constructor !== String) {
      setUser(response.data);
      loggingInDev("user data", response.data);
    } else {
      loggingInDev(response);
    }
  };

  return (
    <integrationContext.Provider value={{ user, setUser, getUser }}>
      {children}
    </integrationContext.Provider>
  );
}
