import {
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import moment from "moment";
import TimePicker from "rc-time-picker";
import { useTranslation } from "react-i18next";

import Repeated from "components/Preferences/Repeated";
import useStyle from "./styles";
import { useState } from "react";

/**
 * Renders the component for allowing a user to select the times for which they're available
 */
export default function AvailableTimeslots({ calInfo, setCalInfo }) {
  const [generalDuration, setGeneralDuration] = useState([
    {
      start_hours: 9,
      start_minutes: 0,
      end_hours: 17,
      end_minutes: 0,
    },
  ]);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();
  const classes = useStyle();
  const startTime = "9 00";
  const endTime = "17 00";

  /**
   * Adds a timeslot to the whole week availalability slots
   */
  const addToGeneral = () => {
    const temp = [...generalDuration];
    const newSlot = {
      start_hours: temp[temp.length - 1].end_hours,
      start_minutes: temp[temp.length - 1].end_minutes,
      end_hours: 0,
      end_minutes: 0,
    };
    temp.push(newSlot);

    const temp2 = [...calInfo];
    for (let i = 0; i < temp2.length; i++) {
      temp2[i].slot.push({
        start_day: temp2[i].slot[0].start_day,
        end_day: temp2[i].slot[0].end_day,
        ...newSlot,
      });
    }
    setCalInfo(temp2);

    setGeneralDuration(temp);
  };

  /**
   * Removes a timeslot from the whole week availalability slots
   * @param {number} index - the index of the timeslot to be removed from the array of all general timeslots
   */
  const deleteFromGeneral = (index) => {
    const temp = [...generalDuration];
    temp.splice(index, 1);

    const temp2 = [...calInfo];

    for (let i = 0; i < temp2.length; i++) {
      temp2[i].slot.pop();
    }
    setGeneralDuration(temp);
    setCalInfo(temp2);
  };

  /**
   * Sets the time for the starting general time
   * @param {*} time - moment object of the selected time
   * @param {*} index - the index of the timeslot being set in the array of all general timeslots
   */
  const handleStartTime = (time, index) => {
    const temp = calInfo.map((info) => {
      return {
        ...info,
        slot: info.slot.map((s, i) => {
          if (i === index) {
            return {
              ...s,
              start_hours: parseInt(time.format("HH")),
              start_minutes: parseInt(time.format("mm")),
            };
          } else {
            return {
              ...s,
            };
          }
        }),
      };
    });
    setCalInfo(temp);
  };

  /**
   * Sets the time for the ending general time
   * @param {*} time - moment object of the selected time
   * @param {*} index - the index of the timeslot being set in the array of all general timeslots
   */
  const handleEndTime = (time, index) => {
    const temp = calInfo.map((info) => {
      return {
        ...info,
        slot: info.slot.map((s, i) => {
          if (i === index) {
            return {
              ...s,
              end_hours: parseInt(time.format("HH")),
              end_minutes: parseInt(time.format("mm")),
              end_day: parseInt(
                time.format("HH") === 0 ? s.end_day : s.start_day
              ),
            };
          } else {
            return {
              ...s,
            };
          }
        }),
      };
    });
    setCalInfo(temp);
  };

  return (
    <>
      <Box display="flex" flexDirection="column">
        <>
          <Box
            display="flex"
            justifyContent={"center"}
            flexDirection="column"
            mb={1}
          >
            {generalDuration.map((duration, index) => {
              return (
                <Box display="flex" alignItems="center" key={index}>
                  <Typography
                    variant="h5"
                    align="left"
                    sx={{
                      marginRight: "10px",
                      width: mobile ? "auto" : "70px",
                    }}
                  >
                    {t("start") + " "}
                    {mobile ? "" : t("time")}
                  </Typography>
                  <TimePicker
                    style={{
                      position: "relative",
                    }}
                    defaultValue={moment(
                      `${duration.start_hours}:${duration.start_minutes}:00`,
                      "HH A"
                    )}
                    focusOnOpen
                    showSecond={false}
                    showMinute={true}
                    minuteStep={30}
                    clearIcon={<></>}
                    use12Hours
                    inputReadOnly
                    onChange={(time) => handleStartTime(time, index)}
                    className={
                      mobile
                        ? "custom-time-picker-mobile"
                        : "custom-time-picker"
                    }
                  />
                  <Typography
                    variant="h5"
                    align="left"
                    sx={{
                      marginLeft: "14px",
                      marginRight: "10px",
                      width: mobile ? "auto" : "62px",
                    }}
                  >
                    {t("end") + " "}
                    {mobile ? "" : t("time")}
                  </Typography>
                  <TimePicker
                    style={{
                      position: "relative",
                    }}
                    defaultValue={moment(
                      `${duration.end_hours}:${duration.end_minutes}:00`,
                      "HH A"
                    )}
                    focusOnOpen
                    showSecond={false}
                    showMinute={true}
                    minuteStep={30}
                    clearIcon={<></>}
                    use12Hours
                    inputReadOnly
                    onChange={(time) => handleEndTime(time, index)}
                    className={
                      mobile
                        ? "custom-time-picker-mobile"
                        : "custom-time-picker"
                    }
                  />
                  {generalDuration.length > 1 && (
                    <IconButton
                      aria-label="plus"
                      onClick={() => deleteFromGeneral(index)}
                      style={{ marginLeft: 8 }}
                    >
                      <DeleteOutlineOutlinedIcon className={classes.addIcon} />
                    </IconButton>
                  )}
                  {duration.start_hours !== 0 && duration.end_hours !== 0 ? (
                    <IconButton
                      aria-label="plus"
                      onClick={addToGeneral}
                      style={{ marginLeft: 8 }}
                    >
                      <AddCircleOutlineOutlinedIcon
                        className={classes.addIcon}
                      />
                    </IconButton>
                  ) : null}
                </Box>
              );
            })}
          </Box>

          {calInfo &&
            calInfo.map((day, i) => {
              return (
                <>
                  <Repeated
                    key={i}
                    startTime={startTime}
                    endTime={endTime}
                    label={day.label}
                    checked={day.isActive}
                    slot={day.slot}
                    calInfo={calInfo}
                    setCalInfo={setCalInfo}
                  />
                  <hr />
                </>
              );
            })}
        </>
      </Box>
    </>
  );
}
