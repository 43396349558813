// module.exports.host = 'http://51.159.29.208/api/v1';
// module.exports.host = "http://localhost:5000/api/v1";
// module.exports.host = "https://skeding.eu/api/v1";
//cases
//meeting types
module.exports.INSTANT_MEETING = "instantMeeting";
module.exports.ALL_SKEDING_USERS = "allSkedingUsers";
module.exports.ONE_NONSKEDING_ONE_SKEDING_USER = "oneNonskedingOneSkedingUser";
module.exports.ONE_NONSKEDING_MULTIPLE_SKEDING_USERS =
  "oneNonskedingMultipleSkedingUser";
module.exports.ONE_SKEDING_MULTIPLE_NONSKEDING_USERS =
  "oneSkedingMultipleNonskedingUsers";
module.exports.MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS =
  "multipleSkedingMultipleNonskedingUsers";
module.exports.MEETING_THROUGH_SHAREABLE_CALENDAR =
  "meetingThroughShareableCalendar";
module.exports.MEETING_THROUGH_WEB_INTEGRATION_CALENDAR =
  "meetingThroughWebIntegrationCalendar";
module.exports.MEETING_THROUGH_QR_CODE = "meetingThroughQrCode";
module.exports.SINGLE_SLOT_MEETING = "singleSlotMeeting";
module.exports.GOOGLE_CALENDAR_EVENT = "googleCalendarEvent";
module.exports.OUTLOOK_CALENDAR_EVENT = "outlookCalendarEvent";
module.exports.SEARCH_EVENT = "searchEvent";
module.exports.RECURRING_EVENT = "recurringEvent";

//notifications
module.exports.MEETING_REMINDER = "reminder";
module.exports.MEETING_RESCHEDULED = "rescheduled ";
module.exports.MEETING_CANCELLED = "cancelled";
module.exports.MEETING_INVITATION_RECEIVED = "invitationReceived"; //accept/reject
module.exports.MEETING_INVITATION_REJECTED = "invitationRejected";
module.exports.MEETING_INVITATION_ACCEPTED = "invitationAccepted";
module.exports.MEETING_INVITATION_ACCEPTED_BY_ALL = "invitationAcceptedByAll";
module.exports.MEETING_NEW_TIMESLOTS_SUGGESTED = "newTimeslotsSuggested"; //choose timeslots
module.exports.MEETING_NO_TIMESLOTS_AVAILABLE = "noTimeslotsAvailable";
module.exports.MEETING_RESPONSE_REMINDER = "meetingResponseReminder";
module.exports.MEETING_INVITATION_INSTANT = "instantInvitation";
module.exports.COLLABORATOR_INVITE_ACCEPTED = "collaboratorInviteAccepted";
module.exports.APPOINTMENT_CONFIRMATION = "Appointment Confirmation";
module.exports.REMINDER_NOTIFICATION = "Reminder Notification";
module.exports.AFTER_APPOINTMENT = "After Appointment";

//qr links
module.exports.ACCOUNT_QR_CODE_REDIRECT =
  process.env.REACT_APP_ENV === "dev"
    ? "http://localhost:3000/qrmeeting"
    : process.env.REACT_APP_ENV === "staging"
    ? "https://app.skeding.xyz/qrmeeting"
    : "https://app.skeding.io/qrmeeting";

//colors
module.exports.PENDING_MEETINGS_COLOR = "#FEC1AD";
module.exports.MEETING_INVITES_COLOR = "#86D3E8";
module.exports.WEBSITE_BOOKINGS_COLOR = "#9FA3F6";
module.exports.PLANNED_EVENTS_COLOR = "#9FA3F6";
module.exports.PASTEL_COLORS = {
  0: "#95C3F2",
  1: "#CBADF8",
  2: "#DBF27E",
  3: "#B0E4FF",
  4: "#9FA3F6",
  5: "#F8E08C",
};
module.exports.VIBRANT_COLORS = {
  0: "#72B0EE",
  1: "#B488F6",
  2: "#CFEE54",
  3: "#96DCFF",
  4: "#97EEDE",
  5: "#F5D04F",
};

module.exports.BUSY_SLOTS_COLOR = "#F32D2D33";
module.exports.MODERATE_SLOTS_COLOR = "#FFB54533";
module.exports.OPEN_SLOTS_COLOR = "#249F5D33";

module.exports.FREE_PLAN = "Free plan";
module.exports.PREMIUM_PLAN = "Premium plan";
module.exports.YEARLY = "Billed yearly";
module.exports.MONTHLY = "Billed monthly";

//help text
module.exports.GROUP_TEXT = "GROUP_TEXT";
module.exports.NEW_MEETINGS_HEADING = "NEW_MEETINGS_HEADING";
module.exports.NEW_MEETINGS_START_TIME = "NEW_MEETINGS_START_TIME";
module.exports.NEW_MEETINGS_RECURRING = "NEW_MEETINGS_RECURRING";
module.exports.NEW_MEETINGS_MINI_CALENDAR = "NEW_MEETINGS_MINI_CALENDAR";
module.exports.NEW_MEETINGS_SLOTS = "NEW_MEETINGS_SLOTS";
module.exports.NEW_MEETINGS_ALLOW_OVERLAP = "NEW_MEETINGS_ALLOW_OVERLAP";
module.exports.BREAKS_DURATION = "BREAKS_DURATION";
module.exports.WEEKLY_UNAVAILABLE = "WEEKLY_UNAVAILABLE";
module.exports.VIRTUAL_LINK = "VIRTUAL_LINK";
module.exports.ADD_TO_WEBSITE = "ADD_TO_WEBSITE";
module.exports.SHAREABLE_MEETINGS = "SHAREABLE_MEETINGS";
module.exports.EMAIL = "EMAIL";
module.exports.ADD_PARTICIPANTS_IN_SHAREABLE = "ADD_PARTICIPANTS_IN_SHAREABLE";
// preferences calendar
module.exports.CAL_INFO = [
  {
    label: "Sun",
    isActive: false,
    slot: [
      {
        start_day: 0,
        start_hours: 9,
        start_minutes: 0,
        end_day: 0,
        end_hours: 17,
        end_minutes: 0,
      },
    ],
  },
  {
    label: "Mon",
    isActive: false,
    slot: [
      {
        start_day: 1,
        start_hours: 9,
        start_minutes: 0,
        end_day: 1,
        end_hours: 17,
        end_minutes: 0,
      },
    ],
  },
  {
    label: "Tue",
    isActive: false,
    slot: [
      {
        start_day: 2,
        start_hours: 9,
        start_minutes: 0,
        end_day: 2,
        end_hours: 17,
        end_minutes: 0,
      },
    ],
  },
  {
    label: "Wed",
    isActive: false,
    slot: [
      {
        start_day: 3,
        start_hours: 9,
        start_minutes: 0,
        end_day: 3,
        end_hours: 17,
        end_minutes: 0,
      },
    ],
  },
  {
    label: "Thr",
    isActive: false,
    slot: [
      {
        start_day: 4,
        start_hours: 9,
        start_minutes: 0,
        end_day: 4,
        end_hours: 17,
        end_minutes: 0,
      },
    ],
  },
  {
    label: "Fri",
    isActive: false,
    slot: [
      {
        start_day: 5,
        start_hours: 9,
        start_minutes: 0,
        end_day: 5,
        end_hours: 17,
        end_minutes: 0,
      },
    ],
  },
  {
    label: "Sat",
    isActive: false,
    slot: [
      {
        start_day: 6,
        start_hours: 9,
        start_minutes: 0,
        end_day: 6,
        end_hours: 17,
        end_minutes: 0,
      },
    ],
  },
];
module.exports.UNAVAILABLE_DATA = [
  {
    start_day: 0,
    start_hours: 0,
    start_minutes: 0,
    end_day: 1,
    end_hours: 0,
    end_minutes: 0,
  },
  {
    start_day: 1,
    start_hours: 0,
    start_minutes: 0,
    end_day: 2,
    end_hours: 0,
    end_minutes: 0,
  },
  {
    start_day: 2,
    start_hours: 0,
    start_minutes: 0,
    end_day: 3,
    end_hours: 0,
    end_minutes: 0,
  },
  {
    start_day: 3,
    start_hours: 0,
    start_minutes: 0,
    end_day: 4,
    end_hours: 0,
    end_minutes: 0,
  },
  {
    start_day: 4,
    start_hours: 0,
    start_minutes: 0,
    end_day: 5,
    end_hours: 0,
    end_minutes: 0,
  },
  {
    start_day: 5,
    start_hours: 0,
    start_minutes: 0,
    end_day: 6,
    end_hours: 0,
    end_minutes: 0,
  },
  {
    start_day: 6,
    start_hours: 0,
    start_minutes: 0,
    end_day: 0,
    end_hours: 0,
    end_minutes: 0,
  },
];

module.exports.colors = [
  "#f44336",
  "#e91e63",
  "#9c27b0",
  "#673ab7",
  "#3f51b5",
  "#2196f3",
  "#03a9f4",
  "#00bcd4",
  "#009688",
  "#4caf50",
  "#8bc34a",
  "#cddc39",
  "#ffeb3b",
  "#ffc107",
  "#ff9800",
  "#ff5722",
  "#795548",
  "#607d8b",
];
module.exports.ACTIVE = "Active";
module.exports.PENDING = "Pending";

module.exports.MEMBER = "member";
module.exports.SCHEDULER = "scheduler";
