import makeStyles from "@mui/styles/makeStyles";
const useStyle = makeStyles((theme) => ({
  nameText: {
    fontSize: "16px",
    marginLeft: 12,
    fontWeight: 400,

    [theme.breakpoints.up("md")]: {
      marginLeft: 0,
    },
  },
  orgText: {
    fontSize: "14px",
    color: "gray",
    fontWeight: 400,
    display: "flex",
    alignItems: "center",
    marginLeft: "4px",
  },

  icon: {
    width: "14px",
    color: theme.palette.text.primary,
  },

  downloadBtn: {
    color: theme.palette.text.primary,
    background: theme.palette.secondary.main,
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "25px",
    maxWidth: "150px",
    minWidth: "150px",
  },

  participantsContainer: {
    marginLeft: 3,
    overflow: "auto",
    textAlign: "start",
    display: "flex",
  },

  participants: {
    background: theme.palette.secondary.main,
    color: theme.palette.secondary.dark,
    "& .MuiChip-icon": {
      color: theme.palette.primary.main,
      fontSize: "20px",
    },
    marginRight: "8px",
    borderRadius: "8px",
  },

  titleText: {
    textTransform: "capitalize",
    fontWeight: 500,
  },
}));

export default useStyle;
