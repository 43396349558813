import { useContext, useEffect, useState } from "react";

import {
  Box,
  Typography,
  Alert,
  AlertTitle,
  Button,
  Grid,
  useTheme,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";
import ReplayRoundedIcon from "@mui/icons-material/ReplayRounded";
import TimePicker from "rc-time-picker";
import moment from "moment";
import { useTranslation } from "react-i18next";
import "rc-time-picker/assets/index.css";
import "styles/picker.css";
import { ADD_TO_WEBSITE } from "Utils/Constants";
import { serverPOST } from "Utils/HttpFunctions";
import { convertDateToUTCMilliseconds } from "Utils/CommonFunctions";
import Custom from "components/AddToWebsite/Custom";
import RepeatedForWebsite from "components/AddToWebsite/RepeatedForWebsite";
import HelpComponent from "components/Global/Help";
import CopyToClipboardDialog from "components/MeetingTemplates/CopyToClipboardDialog";
import SnackbarComponent from "components/Global/SnackbarComponent";
import useStyles from "styles/formInputs";
import useStyle from "../../pages/AddToWebsite/styles";
import useWebsiteStore from "../../Utils/websiteStore";
import { LeftLayout, RightLayout } from "../TwoSectionLayout";
import CustomTabPanel from "../Global/CustomTabPanel";
import SectionTabs from "./SectionTabs";
import ErrorSnackbar from "../../components/ErrorSnackbar";
import { CAL_INFO, UNAVAILABLE_DATA } from "../../Utils/Constants";
import Repeated from "../Preferences/Repeated";
import AvailableTimeslots from "../Preferences/AvailableTimeslots";
import { ShiftTabs } from "./AddDetails";
import AvailabilityPreview from "./Preview/AvailabilityPreview";

export default function Availability() {
  const classes = useStyles();
  const theme = useTheme();
  const [startTime, setStartTime] = useState("9 00");
  const [endTime, setEndTime] = useState("17 00");
  const [embedPopup, setEmbedPopup] = useState(false);
  const [error, setError] = useState("");
  const [warning, setWarning] = useState("");
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [timeError, setTimeError] = useState(0);
  const [response, setResponse] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const [customView, setCustomView] = useState(false);
  const [snackBar, setSnackBar] = useState(false);
  const { t } = useTranslation();
  const pClasses = useStyle();
  const calendarData = useWebsiteStore((store) => store.calendarData);
  const setCalendarData = useWebsiteStore((store) => store.setCalendarData);

  // const duration_minutes = calendarData.duration;
  const duration_minutes = 60;
  const days = ["Sun", "Mon", "Tue", "Wed", "Thr", "Fri", "Sat"];

  const breaks = calendarData.breaks || 0;
  const [calInfo, setCalInfo] = useState(CAL_INFO);
  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    if (calendarData) {
      const result = calendarData?.working_hours?.reduce(function (r, a) {
        r[a.start_day] = r[a.start_day] || [];
        r[a.start_day].push(a);
        return r;
      }, Object.create(null));

      const temp = [...calInfo];
      calendarData?.working_hours?.map((item) => {
        temp[item.start_day] = {
          isActive: true,
          label: days[item.start_day],
          slot: result[item.start_day],
        };
      });
      setCalInfo(temp);
    }
  }, [calendarData]);

  //calculate spare time
  // useEffect(() => {
  //   if (duration_minutes) {
  //     const start = new Date(
  //       2022,
  //       11,
  //       20,
  //       parseInt(startTime.split(" ")[0]),
  //       parseInt(startTime.split(" ")[1]),
  //       0,
  //       0
  //     );
  //     const end = new Date(
  //       2022,
  //       11,
  //       20,
  //       parseInt(endTime.split(" ")[0]),
  //       parseInt(endTime.split(" ")[1]),
  //       0,
  //       0
  //     );
  //     var diff = Math.abs(start - end);
  //     var minutes = Math.floor(diff / 1000 / 60);
  //     const time = duration_minutes + parseInt(breaks);
  //     if (minutes % time !== 0) {
  //       setTimeError(minutes % time);
  //     } else {
  //       setTimeError(0);
  //     }
  //   }
  // }, [duration_minutes, startTime, endTime]);

  // useEffect(() => {
  //   if (duration_minutes) {
  //     let duration = duration_minutes;
  //     let breaks_duration = parseInt(breaks);
  //     let breakDuration = decode(breaks_duration);

  //     let increment = breakDuration + duration / 60;
  //     let denominator = duration + breaks_duration;
  //     if (denominator === 0) {
  //       return;
  //     }

  //     let arr1 = new Array(Math.round((24 * 60) / denominator));
  //     let startValues = [];
  //     for (var i = 0; i < 7; i++) {
  //       let temp = 0;
  //       for (var j = 0; j < calInfo[i]?.slot.start_hours; j++) {
  //         temp++;
  //       }
  //       let minutes = calInfo[i]?.slot.start_minutes + temp * 60;
  //       if (minutes % (duration + breaks_duration) !== 0) {
  //         startValues.push(decode(minutes % (duration + breaks_duration)));
  //       } else {
  //         startValues.push(0);
  //       }
  //     }

  //     for (var i = 0; i < arr1.length; i++) {
  //       arr1[i] = new Array(7);
  //     }

  //     for (
  //       var i = 0;
  //       i < Math.round((24 * 60) / (duration + breaks_duration));
  //       i++
  //     ) {
  //       for (var j = 0; j < 7; j++) {
  //         if (calInfo[j].isActive) {
  //           arr1[i][j] = {
  //             day: j,
  //             hour:
  //               i === 0 ? i + startValues[j] : arr1[i - 1][j].hour + increment,
  //             isSelected:
  //               ((i === 0
  //                 ? i
  //                 : getHours(i === 0 ? i : arr1[i - 1][j].hour + increment) >
  //                     calInfo[j].slot.start_hours ||
  //                   (getHours(i === 0 ? i : arr1[i - 1][j].hour + increment) ===
  //                     calInfo[j].slot.start_hours &&
  //                     getMinutes(
  //                       i === 0 ? i : arr1[i - 1][j].hour + increment
  //                     ) >= calInfo[j].slot.start_minutes)) &&
  //                 (i === 0
  //                   ? i
  //                   : arr1[i - 1][j].hour +
  //                     increment +
  //                     decode(breaks_duration) +
  //                     decode(duration)) <= calInfo[j].slot.end_hours) ||
  //               (getHours(
  //                 i === 0
  //                   ? i
  //                   : arr1[i - 1][j].hour +
  //                       increment +
  //                       decode(breaks_duration) +
  //                       decode(duration)
  //               ) === calInfo[j].slot.end_hours &&
  //                 getMinutes(i === 0 ? i : arr1[i - 1][j].hour + increment) <=
  //                   calInfo[j].slot.end_minutes)
  //                 ? true
  //                 : false,
  //           };
  //         } else {
  //           arr1[i][j] = {
  //             day: j,
  //             hour: i === 0 ? i : arr1[i - 1][j].hour + increment,
  //             isSelected: false,
  //           };
  //         }
  //       }
  //     }
  //     setSelectedSlots(arr1);
  //   }
  // }, [duration_minutes, breaks, calInfo]);

  const getHours = (time) => {
    if (Number.isInteger(time)) {
      return time;
    }
    const wholeStr = time.toString().split(".")[0];
    return Number(wholeStr);
  };

  const getMinutes = (time) => {
    if (Number.isInteger(time)) {
      return time;
    }
    const decimalStr = time.toString().split(".")[1];
    if (decimalStr === "5") {
      return 30;
    }
    if (decimalStr === "25") {
      return 15;
    }
    if (decimalStr === "75") {
      return 45;
    }
  };

  const handleStartTime = (time) => {
    setStartTime(time && time.format("HH mm A"));
    const temp = calInfo.map((info) => {
      return {
        ...info,
        slot: {
          ...info.slot,
          start_hours: parseInt(time.format("HH")),
          start_minutes: parseInt(time.format("mm")),
        },
      };
    });
    setCalInfo(temp);
  };

  const handleEndTime = (time) => {
    setEndTime(time && time.format("HH mm A"));
    const temp = calInfo.map((info) => {
      return {
        ...info,
        slot: {
          ...info.slot,
          end_hours: parseInt(time.format("HH")),
          start_minutes: parseInt(time.format("mm")),
        },
      };
    });
    setCalInfo(temp);
  };

  const handleDayCheck = (name, value) => {
    const temp = [...calInfo];
    const newState = temp.map((obj) => {
      if (obj.label === name) {
        return { ...obj, isActive: value };
      }
      return obj;
    });
    setCalInfo(newState);
  };

  const handleStartDaySlot = (name, value, slot) => {
    const temp = [...calInfo];
    const selectedDate = temp.find((item) => item.label === name);
    const date = new Date(convertDateToUTCMilliseconds(value._d));
    const slots = {
      start_day: slot.start_day,
      start_hours: date.getHours(),
      start_minutes: date.getMinutes(),
      end_day: selectedDate.slot.end_day,
      end_hours: selectedDate.slot.end_hours,
      end_minutes: selectedDate.slot.end_minutes,
    };

    const newState = temp.map((obj) => {
      if (obj.label === name) {
        return { ...obj, slot: slots };
      }
      return obj;
    });
    setCalInfo(newState);
  };

  const handleEndDaySlot = (name, value, slot) => {
    const temp = [...calInfo];
    const selectedDate = temp.find((item) => item.label === name);
    const date = new Date(convertDateToUTCMilliseconds(value._d));
    const slots = {
      start_day: selectedDate.slot.start_day,
      start_hours: selectedDate.slot.start_hours,
      start_minutes: selectedDate.slot.start_minutes,
      end_day: slot.start_day,
      end_hours: date.getHours(),
      end_minutes: date.getMinutes(),
    };
    const newState = temp.map((obj) => {
      if (obj.label === name) {
        return { ...obj, slot: slots };
      }
      return obj;
    });

    setCalInfo(newState);
  };

  function getDecimalPart(num) {
    if (Number.isInteger(num)) {
      return 0;
    }
    const decimalStr = num.toString().split(".")[1];
    if (decimalStr === "5") {
      return 30;
    }
    if (decimalStr === "25") {
      return 15;
    }
    if (decimalStr === "75") {
      return 45;
    }
  }

  function getWholePart(num) {
    if (Number.isInteger(num)) {
      return num;
    }
    const wholeStr = num.toString().split(".")[0];
    return Number(wholeStr);
  }

  // const handleAdd = async () => {
  //   if (services.length === 0) {
  //     setWarning("You have not added any services yet");
  //     return;
  //   }
  //   setError("");
  //   setDisabled(true);

  //   const temp = [...calInfo];
  //   var slots;
  //   var newArr = [];
  //   for (var i = 0; i < selectedSlots.length; i++) {
  //     newArr = newArr.concat(selectedSlots[i]);
  //   }

  //   const filtered = await newArr.filter((item) => {
  //     return item.isSelected === true;
  //   });

  //   slots =
  //     filtered &&
  //     filtered.map((s) => {
  //       return {
  //         start_day: s.day,
  //         start_hours: getWholePart(s.hour),
  //         start_minutes: getDecimalPart(s.hour),
  //         end_day: s.day,
  //         end_hours: getWholePart(
  //           s.hour + decode(duration_minutes) + decode(parseInt(breaks))
  //         ),
  //         end_minutes: getDecimalPart(
  //           s.hour + decode(duration_minutes) + decode(parseInt(breaks))
  //         ),
  //       };
  //     });
  //   const groups = slots.reduce((groups, slot) => {
  //     let start = slot.start_day;
  //     if (!groups[start]) {
  //       groups[start] = [];
  //     }
  //     groups[start].push(slot);
  //     return groups;
  //   }, {});

  //   const groupArrays = Object.keys(groups).map((start) => {
  //     return {
  //       start_day: parseInt(start),
  //       slots: groups[start],
  //     };
  //   });

  //   let unavail = [];
  //   for (let i = 0; i < UNAVAILABLE_DATA.length; i++) {
  //     let uv = UNAVAILABLE_DATA[i];

  //     let un = groupArrays.find((f) => f.start_day === uv.start_day);
  //     if (un) {
  //       let slotsLength = un.slots.length;

  //       for (let j = 0; j < slotsLength; j++) {
  //         let s = un.slots[j];

  //         if (s.start_hours === 0 && s.end_hours === 0) {
  //           continue;
  //         }

  //         if (s.start_hours === 0 && s.start_minutes === 0) {
  //           let obj = {
  //             start_day: s.start_day,
  //             start_hours: s.end_hours,
  //             start_minutes: 0,
  //             end_day: s.end_day,
  //             end_hours: j < slotsLength - 1 ? un.slots[j + 1].start_hours : 0,
  //             end_minutes: 0,
  //           };

  //           if (obj.end_hours === 0) {
  //             obj = {
  //               ...obj,
  //               end_day: obj.end_day === 6 ? 0 : obj.end_day + 1,
  //             };
  //           }
  //           unavail.push(obj);

  //           continue;
  //         }

  //         if (j == 0) {
  //           let obj = {
  //             start_day: s.start_day,
  //             start_hours: 0,
  //             start_minutes: 0,
  //             end_day: s.end_day,
  //             end_hours: s.start_hours,
  //             end_minutes: s.start_minutes,
  //           };

  //           if (obj.end_hours !== 0 && obj.end_day !== obj.start_day) {
  //             obj = {
  //               ...obj,
  //               end_day: obj.end_day === 0 ? 6 : obj.end_day - 1,
  //             };
  //           }
  //           unavail.push(obj);
  //         }

  //         if (
  //           (s.start_hours !== 0 && s.end_hours !== 0) ||
  //           (s.start_hours === 0 && s.start_minutes !== 0)
  //         ) {
  //           let obj2 = {
  //             start_day: s.start_day,
  //             start_hours: s.end_hours,
  //             start_minutes: s.end_minutes,
  //             end_day: s.end_day,
  //             end_hours: j < slotsLength - 1 ? un.slots[j + 1].start_hours : 0,
  //             end_minutes:
  //               j < slotsLength - 1 ? un.slots[j + 1].start_minutes : 0,
  //           };

  //           if (obj2.end_hours === 0) {
  //             obj2 = {
  //               ...obj2,
  //               end_day: obj2.end_day === 6 ? 0 : obj2.end_day + 1,
  //             };
  //           }
  //           unavail.push(obj2);
  //         }
  //       }
  //     } else {
  //       unavail.push(uv);
  //     }
  //   }

  //   const data = {
  //     working_hours: slots,
  //     blocked_timeslots: unavail,
  //   };

  //   const response = await serverPOST(
  //     `/web-integration?offset=${new Date().getTimezoneOffset()}`,
  //     data
  //   );
  //   if (response && response.constructor !== String) {
  //     setResponse(response.data);
  //     setEmbedPopup(true);
  //     setDisabled(false);
  //     // console.log("response ==> ", response)
  //   }
  // };

  const calculateEndDay = (day) => {
    if (day === 6) {
      return 0;
    } else return ++day;
  };

  const handleAdd = async () => {
    setError("");
    setDisabled(true);
    const filtered1 = await calInfo.filter((item) => {
      return item.isActive === true;
    });
    const errorCheck = filtered1.filter((f) =>
      f.slot.some((item) => {
        const startDate = new Date(
          2022,
          10,
          10,
          item.start_hours,
          item.start_minutes,
          0,
          0
        );
        const endDate = new Date(
          2022,
          10,
          item.end_hours === 0 ? 11 : 10,
          item.end_hours,
          item.end_minutes,
          0,
          0
        );
        return endDate < startDate;
      })
    );

    if (errorCheck.length > 0) {
      setError("end time must be greater than start time");
      return;
    }

    let slotsArr = [];
    filtered1.forEach((sl) => {
      sl.slot.forEach((s) => {
        let obj;
        if (s.end_hours == 0 && (s.end_minutes == 0 || s.end_minutes == 30)) {
          obj = {
            start_hours: s.start_hours,
            start_minutes: s.start_minutes,
            start_day: s.start_day,
            end_hours: s.end_hours,
            end_minutes: s.end_minutes,
            end_day: calculateEndDay(s.start_day),
          };
        } else {
          obj = {
            start_hours: s.start_hours,
            start_minutes: s.start_minutes,
            start_day: s.start_day,
            end_hours: s.end_hours,
            end_minutes: s.end_minutes,
            end_day: s.end_day,
          };
        }

        slotsArr.push(obj);
      });
    });
    const groups = slotsArr.reduce((groups, slot) => {
      let start = slot.start_day;
      if (!groups[start]) {
        groups[start] = [];
      }
      groups[start].push(slot);
      return groups;
    }, {});

    const groupArrays = Object.keys(groups).map((start) => {
      return {
        start_day: parseInt(start),
        slots: groups[start],
      };
    });

    let unavail = [];
    for (let i = 0; i < UNAVAILABLE_DATA.length; i++) {
      let uv = UNAVAILABLE_DATA[i];

      let un = groupArrays.find((f) => f.start_day === uv.start_day);
      if (un) {
        let slotsLength = un.slots.length;

        for (let j = 0; j < slotsLength; j++) {
          let s = un.slots[j];

          if (s.start_hours === 0 && s.end_hours === 0) {
            continue;
          }

          if (s.start_hours === 0 && s.start_minutes === 0) {
            let obj = {
              start_day: s.start_day,
              start_hours: s.end_hours,
              start_minutes: 0,
              end_day: s.end_day,
              end_hours: j < slotsLength - 1 ? un.slots[j + 1].start_hours : 0,
              end_minutes: 0,
            };

            if (obj.end_hours === 0) {
              obj = {
                ...obj,
                end_day: obj.end_day === 6 ? 0 : obj.end_day + 1,
              };
            }
            unavail.push(obj);

            continue;
          }

          if (j == 0) {
            let obj = {
              start_day: s.start_day,
              start_hours: 0,
              start_minutes: 0,
              end_day: s.end_day,
              end_hours: s.start_hours,
              end_minutes: s.start_minutes,
            };

            if (obj.end_hours !== 0 && obj.end_day !== obj.start_day) {
              obj = {
                ...obj,
                end_day: obj.end_day === 0 ? 6 : obj.end_day - 1,
              };
            }
            unavail.push(obj);
          }

          if (
            (s.start_hours !== 0 && s.end_hours !== 0) ||
            (s.start_hours === 0 && s.start_minutes !== 0)
          ) {
            let obj2 = {
              start_day: s.start_day,
              start_hours: s.end_hours,
              start_minutes: s.end_minutes,
              end_day: s.end_day,
              end_hours: j < slotsLength - 1 ? un.slots[j + 1].start_hours : 0,
              end_minutes:
                j < slotsLength - 1 ? un.slots[j + 1].start_minutes : 0,
            };

            if (obj2.end_hours === 0) {
              obj2 = {
                ...obj2,
                end_day: obj2.end_day === 6 ? 0 : obj2.end_day + 1,
              };
            }
            unavail.push(obj2);
          }
        }
      } else {
        unavail.push(uv);
      }
    }

    const data = {
      working_hours: slotsArr,
      blocked_timeslots: unavail,
    };

    const response = await serverPOST(
      `/web-integration?offset=${new Date().getTimezoneOffset()}`,
      data
    );
    if (response && response.constructor !== String) {
      setResponse("Availability has been updated");
      setCalendarData(response.data.calendarData);
      // setEmbedPopup(true);
    }
    setDisabled(false);
  };

  const decode = (num) => {
    return num / 60;
  };

  return (
    <>
      {showPreview ? (
        <AvailabilityPreview />
      ) : (
        <>
          {/* {mobile && (
        <Box className={pClasses.availability} mt={2}>
          <Button
            variant="text"
            style={{ fontSize: 14 }}
            startIcon={
              customView ? <ReplayRoundedIcon /> : <DashboardCustomizeIcon />
            }
            onClick={() => setCustomView((prev) => !prev)}
          >
            {customView
              ? "Set repeated availability"
              : "Set custom availability"}
          </Button>
        </Box>
      )} */}

          <Box
            sx={{
              display: "block",
            }}
          >
            <Box>
              <Typography variant="h3" align="left">
                {t("working_hrs_title")}
              </Typography>

              <Typography variant="h4" className={pClasses.workingHrDesc}>
                {t("working_hrs_desc")}
              </Typography>
            </Box>
            <Grid container alignItems="flex-end">
              <Grid
                item
                xs={12}
                sx={{ display: mobile && customView ? "none" : "block" }}
              >
                {/* <Box className={pClasses.timeContainer}>
                  <Typography variant="h3" className={pClasses.time}>
                    {t("start_time")}
                  </Typography>
                  <TimePicker
                    className={"custom-time-picker" + " " + pClasses.timePicker}
                    defaultValue={moment("9:00:00", "HH A")}
                    focusOnOpen
                    showSecond={false}
                    showMinute={true}
                    minuteStep={30}
                    use12Hours
                    inputReadOnly
                    onChange={handleStartTime}
                  />
                  <Typography variant="h3" className={pClasses.time}>
                    {t("end_time")}
                  </Typography>
                  <TimePicker
                    style={{
                      position: "relative",
                    }}
                    defaultValue={moment("17:00:00", "HH A")}
                    className="custom-time-picker"
                    focusOnOpen
                    showSecond={false}
                    showMinute={true}
                    minuteStep={30}
                    use12Hours
                    inputReadOnly
                    onChange={handleEndTime}
                  />
                </Box> */}
                <Box
                  style={{
                    textAlign: "left",
                  }}
                >
                  <Typography
                    variant="caption"
                    align="left"
                    style={{ color: "red" }}
                  >
                    {timeError !== 0 &&
                      `Warning: Note that ${timeError} minutes from your whole day will be discarded.`}
                  </Typography>
                </Box>
                {error && (
                  <Typography
                    style={{ color: "red" }}
                    variant="body2"
                    align="left"
                  >
                    {error}
                  </Typography>
                )}
                <AvailableTimeslots calInfo={calInfo} setCalInfo={setCalInfo} />
                {/* <Box display="flex" flexDirection="column">
                  <>
                    {calInfo &&
                      calInfo.map((day, i) => {
                        return (
                  
                          <RepeatedForWebsite
                            startTime={startTime}
                            endTime={endTime}
                            label={day.label}
                            checked={day.isActive}
                            slot={day.slot}
                            handleDayCheck={handleDayCheck}
                            handleStartDaySlot={handleStartDaySlot}
                            handleEndDaySlot={handleEndDaySlot}
                            timeInterval={duration_minutes}
                          />
                        );
                      })}
                  </>
                </Box> */}
              </Grid>
              {/* {mobile && (
                <Grid
                  item
                  xs={12}
                  md={7.5}
                  sx={{ display: !customView ? "none" : "block", marginTop: 2 }}
                >
                  <Box style={{ marginLeft: 0 }}>
                    <Box style={{ textAlign: "left" }}>
                      <Typography
                        variant="caption"
                        align="left"
                        style={{ color: "red" }}
                      >
                        {timeError !== 0 &&
                          `Warning: Note that ${timeError} minutes from your whole day will be discarded.`}
                      </Typography>
                    </Box>
                    <Custom
                      calInfo={calInfo}
                      selectedSlots={selectedSlots}
                      setSelectedSlots={setSelectedSlots}
                      timeInterval={duration_minutes}
                      breaks={parseInt(breaks)}
                    />
                  </Box>
                </Grid>
              )} */}
            </Grid>
          </Box>
        </>
      )}
      <PreviewButtonGroup
        onSave={handleAdd}
        loading={disabled}
        showPreview={showPreview}
        setShowPreview={setShowPreview}
      />
      {/* <CopyToClipboardDialog
        open={embedPopup}
        handleCloseCopyToClipboard={() => setEmbedPopup(false)}
        copiedLink={response && response.integration_link}
        setSnackBar={setSnackBar}
      /> */}
      {/* <SnackbarComponent
        open={snackBar}
        handleClose={() => setSnackBar(false)}
        message={t("link_copied")}
        vertical={"bottom"}
        horizontal={"center"}
      /> */}
      <SnackbarComponent
        open={!!response}
        handleClose={() => setResponse("")}
        message={response}
        vertical={"bottom"}
        horizontal={"center"}
      />

      <ErrorSnackbar
        open={!!warning}
        handleClose={() => setWarning("")}
        message={warning}
        vertical={"bottom"}
        horizontal={"center"}
        duration={3000}
      />
    </>
  );
}

export function PreviewButtonGroup({
  showPreview,
  setShowPreview,
  onSave,
  loading,
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box style={{ display: "flex", marginTop: 16 }}>
      {mobile ? (
        <Button
          variant="outlined"
          onClick={() => setShowPreview(!showPreview)}
          style={{
            marginRight: 16,
          }}
        >
          {showPreview ? t("Back") : t("Preview")}
        </Button>
      ) : (
        <></>
      )}

      {showPreview ? (
        <></>
      ) : (
        <ShiftTabs
          showPreview={showPreview}
          onSave={onSave}
          loading={loading}
        />
      )}
    </Box>
  );
}
