import {
  Grid,
  Box,
  Container,
  Typography,
  Divider,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect } from "react";
import Scrollspy from "react-scrollspy";
import useStyles from "./styles";
import { Link } from "react-scroll";
import { termsAndCondition } from "Utils/terms";
import "styles/picker.css";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MenuIcon from "@mui/icons-material/Menu";
import { useRef } from "react";

export default function TermsConditons({ isInternal, setShowTerms }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [active, setActive] = useState(1);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const [drawer, setDrawer] = useState(false);

  return (
    <>
      <Box
        style={{
          scrollBehavior: "smooth",
          textAlign: "left",
        }}
      >
        <Grid container>
          <Grid
            item
            xs={5}
            md={3}
            className={classes.sidebar}
            style={{
              backgroundColor: theme.palette.secondary.main,
              padding: 28,
              position: mobile ? "fixed" : "relative",
              minHeight: "100vh",
              boxSizing: mobile ? "content-box" : "border-box",
              // left: mobile ? (drawer ? 0 : "-100%") : 0,
              opacity: mobile ? (!drawer ? "0" : "1") : "1",
              transition: "all .8s",
              visibility: mobile ? (!drawer ? "hidden" : "visible") : "visible",
            }}
          >
            <Container
              maxWidth="xl"
              style={{
                marginLeft: "0px",
                paddingLeft: "0px",
                paddingRight: "0px",
                position: "sticky",
                zIndex: 1200,
                top: "18px",
                display: "flex",
              }}
            >
              <div className={`scroller ${classes.tabContainer}`}>
                <Container
                  style={{
                    marginLeft: "0px",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                  }}
                >
                  <Box
                    style={{
                      display: isInternal
                        ? mobile
                          ? "block"
                          : "none"
                        : "none",
                    }}
                  >
                    <ArrowBackIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => setDrawer(false)}
                    />
                  </Box>
                  <Scrollspy
                    offset={-220}
                    className={classes.scrollpyStyles}
                    items={termsAndCondition.map((item) => item.id)}
                    currentClassName={classes.active}
                  >
                    {termsAndCondition.map((item, index) => (
                      <li
                        key={`STICKY_${index}`}
                        className={classes.tabListStyle}
                      >
                        <Link
                          to={item.id}
                          spy={true}
                          smooth={true}
                          className={classes.anchorStyle}
                          offset={-220}
                        >
                          <Typography
                            noWrap={true}
                            variant="h3"
                            style={{ color: "inherit" }}
                          >
                            {item.id}
                            {"          "} {item.title}
                          </Typography>
                        </Link>
                      </li>
                    ))}
                  </Scrollspy>
                </Container>
              </div>
            </Container>
          </Grid>

          <Grid
            item
            xs={12}
            md={9}
            style={{ padding: mobile ? 14 : 28, marginTop: 16 }}
          >
            <Container maxWidth="xl">
              {!mobile && (
                <Box
                  style={{
                    position: "fixed",
                    top: 16,
                    left: 36,
                    opacity: drawer ? "0" : "1",
                    transition: "all .4s",
                    visibility: mobile
                      ? drawer
                        ? "hidden"
                        : "visible"
                      : "hidden",
                    display: isInternal ? "block" : "none",
                  }}
                >
                  <MenuIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => setDrawer(true)}
                  />
                </Box>
              )}
              {/* {isInternal && ( */}
              <Typography
                variant="h2"
                style={{ marginBottom: 16, marginTop: mobile ? 40 : 0 }}
              >
                Terms and Conditions
              </Typography>
              {/* )} */}
              {termsAndCondition.map((item, index) => (
                <section key={`SECTION_${index}`} id={item.id}>
                  <Box display="flex" alignItems="center">
                    <Typography variant="h2" fontWeight={600}>
                      {item.title}
                    </Typography>
                    <Divider
                      light
                      orientation="horizontal"
                      style={{
                        // width: "-webkit-fill-available",
                        marginLeft: 10,
                      }}
                    />
                  </Box>
                  {item.description}
                </section>
              ))}
            </Container>
          </Grid>
        </Grid>
        {!mobile && (
          <Box
            style={{
              position: "fixed",
              top: 16,
              right: 16,
              display: isInternal ? "block" : "none",
            }}
          >
            <CloseIcon
              style={{ color: "gray", cursor: "pointer" }}
              onClick={() => {
                setShowTerms(false);
              }}
            />
          </Box>
        )}

        {mobile && (
          <Box
            style={{
              position: "fixed",
              top: 0,
              right: 0,
              left: 0,
              display: isInternal ? "block" : "none",
              backgroundColor: theme.palette.secondary.main,
              zIndex: drawer ? -1 : 999,
            }}
          >
            <Box
              display={"flex"}
              justifyContent="space-between"
              style={{ width: "85%", margin: "auto", height: "60px" }}
              alignItems="center"
            >
              <MenuIcon
                style={{ cursor: "pointer" }}
                onClick={() => setDrawer(true)}
              />
              <CloseIcon
                style={{ color: "gray", cursor: "pointer" }}
                onClick={() => {
                  setShowTerms(false);
                }}
              />
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
}
