import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { serverGET } from "Utils/HttpFunctions";
import loggingInDev from "loggingInDev";
import { authContext } from "./Auth";

export const accountContext = React.createContext();

export default function AccountProvider({ children }) {
  const [user, setUser] = useState(null);
  const { isLogged } = useContext(authContext);

  useEffect(() => {
    if (isLogged === true) {
      getUser();
    }
  }, [isLogged]);

  const getUser = async () => {
    const response = await serverGET("/account");
    if (response && response.constructor !== String) {
      setUser(response.data);
      loggingInDev("user data", response.data);
    } else {
      loggingInDev(response);
    }
  };

  return (
    <accountContext.Provider value={{ user, setUser, getUser }}>
      {children}
    </accountContext.Provider>
  );
}
