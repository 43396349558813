import { MenuItem, Select } from "@mui/material";
import React from "react";

export default function Dropdown({ value, onChange, options, label, style }) {
  return (
    <Select
      label={label}
      variant="standard"
      value={value}
      onChange={onChange}
      style={style}
    >
      {options.map((option, i) => {
        return (
          <MenuItem key={i} value={option}>
            {option}
          </MenuItem>
        );
      })}
    </Select>
  );
}
