import makeStyles from "@mui/styles/makeStyles";
const useStyle = makeStyles((theme) => ({
  container: {
    background: "transparent",
    borderRadius: "16px",
    boxSizing: "border-box",
    marginRight: "16px",
    padding: "16px",
    width: "100%",
    [theme.breakpoints.up("lg")]: {
      background: theme.palette.secondary.light,
      padding: "24px 20px",
    },
  },
  stickyContainer: {
    position: "sticky",
    top: 0,
    zIndex: 1,
    padding: "20px 10px",
    backgroundColor: theme.palette.secondary.light,

    [theme.breakpoints.up("lg")]: {
      padding: "20px 0px",
    },
  },
}));

export default useStyle;
