import { useContext, useEffect } from "react";
import {
  Box,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Switch,
  Typography,
} from "@mui/material";

import { themeContext } from "context/ThemeColorProvider";
import useDelegatedAcess from "../../Utils/SwtichAccount";
import useStyle from "./styles";
import { serverPUT } from "../../Utils/HttpFunctions";
import { accountContext } from "../../context/user";
import { useTranslation } from "react-i18next";

/**
 * Used for toggling darkmode in the NavigationDrawer
 */
export default function ToggleButton(props) {
  const { colorMode, mode } = useContext(themeContext);

  return (
    <Box
      className={({ isActive }) =>
        isActive ? `active ${props.classProp}` : `inactive ${props.classProp}`
      }
      id={props.classProp}
    >
      <ListItemButton alignItems="center">
        <ListItemIcon
          sx={{
            color: "#FFFFFF",
            minWidth: "30px",
          }}
        >
          {props.icon}
        </ListItemIcon>
        <ListItemText
          disableTypography
          primary={
            <Switch
              checked={mode === "light" ? false : true}
              onChange={colorMode.toggleColorMode}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
        />
      </ListItemButton>
    </Box>
  );
}

export function NotificationToggleButton() {
  const schedulerNotification = useDelegatedAcess(
    (state) => state.schedulerNotification
  );
  const setSchedulerNotification = useDelegatedAcess(
    (state) => state.setSchedulerNotification
  );
  const { members, setMembers } = useDelegatedAcess((state) => state);
  const { user } = useContext(accountContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (members.length > 0) {
      const currentUser = members.find((m) => m.email === user.email);
      if (currentUser) {
        setSchedulerNotification(currentUser?.scheduler?.is_email_enabled);
      }
    }
  }, [members, user]);

  const handleProceed = async (status) => {
    const membershipId = user?.package?.membership?._id;

    const response = await serverPUT(
      `/account/toggleNotification/${membershipId}`,
      {
        is_email_enabled: status,
      }
    );
    if (response && response.constructor !== String) {
      let mems = [...members];
      const currentUser = members.find((m) => m.email === user.email);
      if (currentUser) {
        currentUser.scheduler.is_email_enabled = status;
      }
      setMembers(mems);
      setSchedulerNotification(status);
    } else {
      console.log("error");
    }
  };

  const classes = useStyle();
  return (
    <Box>
      <ListItemButton alignItems="center">
        <ListItemText
          primary={
            <Box display="flex" alignItems="center">
              <Switch
                classes={{
                  root: classes.root,
                  switchBase: classes.switchBase,
                  thumb: classes.thumb,
                  track: classes.track,
                  checked: classes.checked,
                }}
                checked={schedulerNotification}
                onChange={() => handleProceed(!schedulerNotification)}
                inputProps={{ "aria-label": "controlled" }}
              />

              <Typography align="left" variant="h3" fontWeight={400}>
                {t("host_alert")}
              </Typography>
            </Box>
          }
        />
      </ListItemButton>
    </Box>
  );
}
