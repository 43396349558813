import makeStyles from "@mui/styles/makeStyles";
const useStyle = makeStyles((theme) => ({
  delIcon: {
    color: "#363B53",
    cursor: "pointer",
    width: 16,
    height: 16,
  },

  addText: {
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
  },

  eyeIcon: {
    width: "30%",
    cursor: "pointer",
  },

  qrCard: {
    position: "fixed",
    height: "100%",
    display: "block",
    top: 0,
    right: 0,
    left: 0,
    background: "white",
    zIndex: 99999,
    overflowY: "scroll",
  },

  textfield: {
    fontSize: "16px",
    fontWeight: 400,
    border: "1px solid #DAD2E5",
    borderRadius: "6px",
    backgroundColor: theme.palette.secondary.light,
    paddingLeft: "8px",
  },

  socialTextFieldContainer: {
    display: "flex",
    flexWrap: "nowrap",
    gap: 4,
    flexDirection: "column",

    [theme.breakpoints.up("md")]: {
      gap: 0,
      flexDirection: "row",
    },
  },

  logoNameContainer: {
    display: "flex",

    alignItems: "center",
    width: "20%",
  },

  textFieldHideContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
}));

export default useStyle;
