import { useRef, useEffect } from "react";

import { TextField, InputAdornment, IconButton, Box } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import HelpComponent from "./Global/Help";
import PasswordRequirements from "./password recovery/PasswordRequirements";
import { useTranslation } from "react-i18next";

export default function PasswordsForm(props) {
  const {
    values,
    setValues,
    validationError,
    setValidationError,
    matchError,
    validationHelper,
    setValidationHelper,
    matchHelperText,
    setShowRequirements,
    validationRef,
    matchingRef,
  } = props;
  const { t } = useTranslation();

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Box
      width="100%"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {/* first password validates if it meets the requirements */}

      <FirstPasswordInput
        id={"password1"}
        values={values}
        error={validationError}
        setValidationError={setValidationError}
        helperText={validationHelper}
        setValidationHelper={setValidationHelper}
        setValues={setValues}
        placeholder={t("new_pass")}
        handleClickShowPassword={handleClickShowPassword}
        handleMouseDownPassword={handleMouseDownPassword}
        setShowRequirements={setShowRequirements}
        validationRef={validationRef}
      />

      <Box mt={1.5} />
      <SecondPasswordInput
        id={"password2"}
        values={values}
        error={matchError}
        setValidationError={setValidationError}
        helperText={matchHelperText}
        setValidationHelper={setValidationHelper}
        setValues={setValues}
        placeholder={t("re_new_pass")}
        handleClickShowPassword={handleClickShowPassword}
        handleMouseDownPassword={handleMouseDownPassword}
        matchingRef={matchingRef}
      />
    </Box>
  );
}

function FirstPasswordInput(props) {
  const {
    values,
    setValues,
    setShowRequirements,
    id,
    error,
    helperText,
    handleClickShowPassword,
    handleMouseDownPassword,
    validationRef,
    placeholder,
  } = props;

  const handleShowRequirements = () => {
    setShowRequirements(true);
  };

  const handlePassword1 = () => {
    setValues({
      password1: validationRef.current.value,
      password2: values.password2,
      showPassword: values.showPassword,
    });
  };

  return (
    // <Box display={"flex"} alignItems="flex-start">
    <TextField
      required
      id={id}
      variant="outlined"
      placeholder={placeholder}
      type={values.showPassword ? "text" : "password"}
      onChange={handlePassword1}
      error={error}
      FormHelperTextProps={{ component: "div" }}
      helperText={helperText}
      inputRef={validationRef}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <LockOutlinedIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <Box mr={1}>
              <HelpComponent msg={<PasswordRequirements />} fullWidth={true} />
            </Box>
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {values.showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
        sx: { borderRadius: "8px" },
      }}
      onClick={handleShowRequirements}
      sx={{
        width: { xs: "100%", md: 360 },
      }}
    />
    // </Box>
  );
}

function SecondPasswordInput(props) {
  const handlePassword2 = () => {
    props.setValues({
      password1: props.values.password1,
      password2: props.matchingRef.current.value,
      showPassword: props.values.showPassword,
    });
  };

  return (
    <TextField
      required
      id={props.id}
      variant="outlined"
      placeholder={props.placeholder}
      type={props.values.showPassword ? "text" : "password"}
      onChange={handlePassword2}
      error={props.error}
      FormHelperTextProps={{ component: "div" }}
      helperText={props.helperText}
      inputRef={props.matchingRef}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <LockOutlinedIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={props.handleClickShowPassword}
              onMouseDown={props.handleMouseDownPassword}
            >
              {props.values.showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
        sx: { borderRadius: "8px" },
      }}
      sx={{
        width: { xs: "100%", md: 360 },
      }}
    />
  );
}
