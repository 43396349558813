import { useEffect, useState, useRef, useContext } from "react";

import Card from "@mui/material/Card";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import {
  Box,
  Typography,
  Grid,
  useMediaQuery,
  useTheme,
  Tooltip,
} from "@mui/material";

import { dayHeader } from "Utils/CommonFunctions";
import Navigation from "./Navigation";
import "styles/freeSlotCalendar.css";
import { useTranslation } from "react-i18next";
import { accountContext } from "../../context/user";

export default function FreeSlotCalendar({
  selectedDate,
  onCalendarMonthChange,
  setSelectedSlot,
  events: totalEvents,
  participants,
  reset,
  popover,
  initiatorResponse,
  isLogged,
  selectedTimes,
  setSelectedTimes,
}) {
  const [availableGaps, setAvailableGaps] = useState();
  const [freeSlots, setFreeSlots] = useState({ events: [] });
  const theme = useTheme();
  const [eventTooltips, setEventTooltips] = useState([]);
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const calendarRef = useRef(null);
  const { t } = useTranslation();
  const { user } = useContext(accountContext);
  /**
   * Moves the calendar to the currently selected date
   */
  useEffect(() => {
    if (selectedDate) {
      calendarRef?.current?.getApi()?.gotoDate(selectedDate);
    }
  }, [selectedDate]);

  /**
   * Updates the selectedSlot component when the selectedTimes object changes
   */
  useEffect(() => {
    if (selectedTimes) {
      setSelectedSlot(selectedTimes?.events);
    }
  }, [selectedTimes]);

  /**
   * Resets the selected times object
   */
  useEffect(() => {
    if (selectedTimes?.events?.length > 0) {
      setSelectedTimes({ events: [] });
    }
  }, [reset]);

  /**
   * Sets the calendar to display the first available time if it isn't in view
   */
  useEffect(() => {
    if (calendarRef) {
      setCalendarView();
    }
  }, [calendarRef]);

  /**
   * Removes the current user from being displayed in unavailable when the events are updated
   */
  useEffect(() => {
    let currentUserRemovedFromUnavailable = filterAvailableTimes();
    setEventBackgrounds(currentUserRemovedFromUnavailable);
  }, [totalEvents]);

  /**
   * Adds constraints to block users from selecting unavailable time slots
   * Creates tooltip for each available timeslot
   */
  useEffect(() => {
    if (freeSlots !== null) {
      selectConstraints();
      createTooltip();
    }
  }, [freeSlots]);

  /**
   * Removes current user from available times for when participants are responding to polling meeting
   */
  const filterAvailableTimes = () => {
    var currentUserEmail = user?.email;
    var currentUserRemoved = totalEvents?.filter(
      (availableTime) =>
        availableTime?.unAvailableParticipants?.find(
          (unavailableParticipant) =>
            unavailableParticipant?.email === currentUserEmail
        ) === undefined
    );
    return currentUserRemoved;
  };

  /**
   * Sorts available times and checks if the first avaialable time is in the current view
   */
  const setCalendarView = () => {
    var sortedTimes = sortTimes("earliest");

    sortedTimes.sort((a, b) => a.start.getTime() - b.start.getTime());
    var firstAvailableTime = sortedTimes[0];

    checkIfFirstAvailableInView(firstAvailableTime);
  };

  /**
   * Verifies if the first time slot for which the user is available is in the current view
   * moves the current view to include the time slot if it is not visible
   * @param {object} firstAvailableTime - the first time slot that is available for a user
   */
  const checkIfFirstAvailableInView = (firstAvailableTime) => {
    var viewStart =
      calendarRef && calendarRef.current.getApi().view.activeStart;
    var viewEnd = calendarRef && calendarRef.current.getApi().view.activeEnd;

    switch (
      firstAvailableTime?.start?.getTime() >= viewStart?.getTime() &&
      firstAvailableTime?.start?.getTime() <= viewEnd?.getTime()
    ) {
      case false:
        calendarRef.current
          .getApi()
          .changeView("timeGridWeek", firstAvailableTime.start);
        break;
      case true:
        break;
      default:
        break;
    }
  };

  /**
   * Adds background colors to each event
   * @param {object[]} totalEvents
   */
  const setEventBackgrounds = (totalEvents) => {
    var slotsWithBackground =
      totalEvents?.length > 0 &&
      totalEvents.map((availableTime) => {
        let availableNotIncludingInitiator =
          availableTime?.availableParticipants?.length - 1; //accounts for the initiator in the participants array

        return {
          start: availableTime.start,
          end: availableTime.end,
          availableParticipants: availableTime.availableParticipants,
          unavailableParticipants: availableTime.unAvailableParticipants,
          backgroundColor: colorWithOpacity(
            availableNotIncludingInitiator,
            availableTime.start
          ),
          className: ["border-background-event"],
          groupId: disablePassedEvents(availableTime.start),
        };
      });

    setFreeSlots({
      events: [...slotsWithBackground],
      display: "background",
    });
  };

  /**
   * Sets event background color with opacity based on the number of participants that are available
   * @param {number} numberOfAvailable
   * @param {Date} startTime
   * @returns
   */
  const colorWithOpacity = (numberOfAvailable, startTime) => {
    let opacity;
    let c;

    if (startTime.getTime() < new Date().getTime()) {
      c = "#DAD2E5";
    } else if (numberOfAvailable === 0) {
      c = "transparent";
    } else {
      opacity = numberOfAvailable / participants.length;
      c = `rgba(150, 220, 255, ${opacity})`;
    }

    return c;
  };

  /**
   * Blocks a user from selecting passed events by making them unclickable
   * @param {Date} startTime
   */
  const disablePassedEvents = (startTime) => {
    if (startTime.getTime() > new Date().getTime()) {
      return "clickable";
    } else return "passed";
  };

  /**
   * Finds where there are gaps in available timeslots,
   * used to set constraints to not allow user to select these times
   * @param {object[]} sortedAvailability
   * @param {array} gaps
   */
  const findGaps = (sortedAvailability, gaps) => {
    sortedAvailability.forEach((available, i) => {
      if (i >= 1) {
        if (
          sortedAvailability[i - 1].end.getTime() < available.start.getTime()
        ) {
          gaps.push({
            start: sortedAvailability[i - 1].end,
            end: available.start,
          });
        }
      }
    });
  };

  /**
   * Sets constraints to block a user from selecting times for which they are unavailable
   */
  const selectConstraints = () => {
    var sortedAvailability = [...freeSlots.events];

    sortedAvailability.sort((a, b) => a.start.getTime() - b.start.getTime());
    var gaps = [];

    findGaps(sortedAvailability, gaps);

    setAvailableGaps(gaps);
  };

  /**
   * Adds all available time in a date to a user's selected times
   * @param {Date} date - the selected date
   */
  const handleWeekdayClick = async (date) => {
    var timesInDay = [];
    freeSlots.events.forEach((availableTime) => {
      if (
        availableTime.start.getDate() === date.getDate() ||
        availableTime.end.getDate() === date.getDate()
      ) {
        timesInDay.push({
          start: availableTime.start,
          end: availableTime.end,
        });
      }
    });

    var timesSelectedFromDate = [];
    var clearTimesInDate = false;

    timesInDay.forEach((availableTime) => {
      if (
        selectedTimes.events.find(
          (selectedTime) => selectedTime.start === availableTime.start
        ) !== undefined
      ) {
        timesSelectedFromDate.push(availableTime);
      }
    });

    if (timesSelectedFromDate.length === timesInDay.length) {
      clearTimesInDate = true;
    }

    var tempSelected = selectedTimes.events.filter(
      (alreadySelected) =>
        timesInDay.find(
          (availableTime) => availableTime.start === alreadySelected.start
        ) === undefined
    );

    if (clearTimesInDate) {
      setSelectedTimes({
        events: [...tempSelected],
        backgroundColor: "rgba(114, 176, 238, 0.5)",
        display: "background",
      });
    } else {
      setSelectedTimes({
        events: [...tempSelected, ...timesInDay],
        backgroundColor: "rgba(114, 176, 238, 0.5)",
        display: "background",
      });
    }
  };

  /**
   * Helper for finding which time slots are in a range selected by the user on the calendar
   * @param {number} selectionStart
   * @param {number} selectionEnd
   * @returns - ranges of avaialable times that lie within the selected range by the user
   */
  const findSelectionInAvailable = (selectionStart, selectionEnd) => {
    var availableTimeRange = [];

    freeSlots.events.forEach((availableTime) => {
      //handles selections not within one available time
      if (
        (availableTime.start.getTime() > selectionStart &&
          availableTime.start.getTime() < selectionEnd) ||
        (availableTime.end.getTime() > selectionStart &&
          availableTime.end.getTime() < selectionEnd)
      ) {
        availableTimeRange.push({
          start: availableTime.start,
          end: availableTime.end,
        });
        //handles selections within one available time
      } else if (
        availableTime.start.getTime() <= selectionStart &&
        availableTime.end.getTime() >= selectionEnd
      ) {
        availableTimeRange.push({
          start: availableTime.start,
          end: availableTime.end,
        });
      }
    });
    return availableTimeRange;
  };

  /**
   * Helper for finding starts of selections that a user has made on the calendar in the available time slots
   * @param {number} selectionStart
   * @param {number} selectionEnd
   * @returns - ranges of avaialable times that lie within the selected range by the user
   */
  const findStartsInAvailable = (selectionStart, selectionEnd) => {
    var availableTimeRange = [];

    freeSlots.events.forEach((availableTime) => {
      //handles selections not within one available time
      if (
        (availableTime.start.getTime() > selectionStart &&
          availableTime.start.getTime() < selectionEnd) ||
        (availableTime.end.getTime() > selectionStart &&
          availableTime.end.getTime() < selectionEnd)
      ) {
        availableTimeRange.push(availableTime.start.getTime());
        //handles selections within one available time
      } else if (
        availableTime.start.getTime() <= selectionStart &&
        availableTime.end.getTime() >= selectionStart &&
        availableTime.start.getTime() <= selectionEnd &&
        availableTime.end.getTime() >= selectionEnd
      ) {
        availableTimeRange.push(availableTime.start.getTime());
      }
    });
    return availableTimeRange;
  };

  /**
   * Helper for selecting and deselecting a single  time from the calendar
   * @param {object} selection - contains the span of time which the user has selected
   */
  const handleSingleSelect = (selection) => {
    var selectionStart = selection.start.getTime();
    var selectionEnd = selection.end.getTime();
    var temp = [];

    var selectionInAvailable = findSelectionInAvailable(
      selectionStart,
      selectionEnd
    );
    temp.push(...selectionInAvailable);

    setSelectedTimes({
      events: [...temp],
      backgroundColor: "#72B0EE",
      display: "background",
    });
    calendarRef.current.getApi().unselect();
  };

  /**
   * Helper for selecting and deselecting multiple times from the calendar
   * @param {object} selection - contains the span of time which the user has selected
   */
  const handleSelect = (selection) => {
    var selectionStart = selection.start.getTime();
    var selectionEnd = selection.end.getTime();

    var selectedTimesInSelection = [];
    selectedTimes.events.forEach((alreadySelected) => {
      //case for selection fully within one time
      if (
        alreadySelected.start.getTime() <= selectionStart &&
        alreadySelected.start.getTime() <= selectionEnd &&
        alreadySelected.end.getTime() >= selectionStart &&
        alreadySelected.end.getTime() >= selectionEnd
      ) {
        selectedTimesInSelection.push(alreadySelected.start.getTime());
      }
      //case for selections within more than one available time
      else if (
        alreadySelected.start.getTime() >= selectionStart &&
        alreadySelected.start.getTime() <= selectionEnd &&
        alreadySelected.end.getTime() >= selectionStart &&
        alreadySelected.end.getTime() <= selectionEnd
      ) {
        selectedTimesInSelection.push(alreadySelected.start.getTime());
      }
    });

    //check if selection contains already selected times
    switch (selectedTimesInSelection.length === 0) {
      case true:
        var temp = [...selectedTimes.events];
        var selectionInAvailable = findSelectionInAvailable(
          selectionStart,
          selectionEnd
        );
        temp.push(...selectionInAvailable);
        break;
      case false:
        var temp = [];
        var selectionInAvailable = findStartsInAvailable(
          selectionStart,
          selectionEnd
        );
        selectedTimes.events.forEach((alreadySelected) => {
          if (
            selectionInAvailable.includes(alreadySelected.start.getTime()) ===
            false
          ) {
            temp.push({
              start: alreadySelected.start,
              end: alreadySelected.end,
            });
          }
        });
        break;
      default:
        break;
    }

    setSelectedTimes({
      events: [...temp],
      backgroundColor: "#72B0EE",
      display: "background",
    });
    calendarRef.current.getApi().unselect();
  };

  /**
   * Helper for getting the available/unavailable participants for a given time slot
   * @param {string} availability - determines if unavailable or available participants are returned
   * @returns - array of unavailable or available participants for the time slot
   */
  const getParticipantsArrays = (availability) => {
    return freeSlots.events.map((availableTime) => {
      if (availability === "available") {
        return availableTime?.availableParticipants;
      } else {
        return availableTime?.unavailableParticipants;
      }
    });
  };

  /**
   * Helper for getting the names of all participants for a given time slot
   * @param {array} participants
   * @returns - an array with the participant names (skeding users) or emails (nonskeding users)
   */
  const collectParticipantNames = (participants) => {
    var participantNames = [];
    participants?.map((participantArray) => {
      var temp = [];

      participantArray?.map((participant) => {
        temp.push(participant?.email);
      });
      participantNames.push(temp);
    });

    return participantNames;
  };

  /**
   * Converts participant names to grid elements
   * @param {array} available
   * @param {array} unavailable
   * @returns - array of react components
   */
  const participantElements = (available, unavailable) => {
    var availableElements = available?.map((participantArray, i) => {
      return (
        participantArray.length > 0 &&
        participantArray.map((participant, j) => {
          return (
            <Grid container key={j} sx={{ mt: "8px", mr: "5px" }}>
              <Typography
                sx={{
                  mr: "5px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                variant="h5"
              >
                {participant}
              </Typography>
            </Grid>
          );
          // }
        })
      );
    });

    var unavailableElements = unavailable.map((participantArray, i) => {
      return (
        participantArray.length > 0 &&
        participantArray.map((participant, j) => {
          return (
            <Grid container key={j} sx={{ mt: "8px", mr: "5px" }}>
              <Typography
                variant="h5"
                sx={{
                  textDecoration: "line-through",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {participant}
              </Typography>
            </Grid>
          );
        })
      );
    });

    return freeSlots.events.map((available, i) => {
      return (
        <Grid
          container
          item
          flexDirection={"column"}
          xs={6}
          sx={{ pr: "10px", pl: "8px" }}
        >
          <Grid item>
            <Typography variant="h5" fontWeight={500}>
              {t("available")}
            </Typography>
          </Grid>
          {availableElements[i]}
          {unavailableElements[i]}
        </Grid>
      );
    });
  };

  /**
   * Title displaying number of available in the tooltip
   * @param {array} available
   * @returns - array of react components
   */
  const popupTitle = (available) => {
    var fractionAvailable =
      available.length > 0 &&
      available.map((participantArray, i) => {
        var participantsWithoutInitiator = participantArray?.length - 1; //accounts for removing initiator from participant array
        // var participantsWithoutInitiator = participantArray?.length;

        return (
          <Grid item xs={12} style={{ padding: 8 }}>
            <Typography key={i} variant="h3" sx={{ fontWeight: "700" }}>
              {participantsWithoutInitiator.toString()}/
              {participants?.length.toString()} Members
            </Typography>
          </Grid>
        );
      });

    return fractionAvailable;
  };

  /**
   * The participants whose response is pending in the time slot tooltip
   * @param {array} available
   * @param {array} unavailable
   * @returns - array of react components with pending participant availability
   */
  const pendingParticipantElements = (available, unavailable) => {
    var respondedParticipants = freeSlots.events.map((availableTime, i) => {
      var temp = available[i];
      temp.push(...unavailable[i]);
      return temp;
    });
    var pending = respondedParticipants.map((respondedParticipant, i) => {
      return participants.map((participant) => {
        if (
          respondedParticipant.find(
            (respondedParticipant) => respondedParticipant === participant
          ) === undefined
        ) {
          return participant;
        } else {
          return;
        }
      });
    });

    var cleanedPending = pending.map((pendingParticipants) => {
      return pendingParticipants.filter(
        (participant) => participant !== undefined
      );
    });

    var pendingElements = cleanedPending.map((pendingParticipantsArray, i) => {
      return pendingParticipantsArray.length > 0 ? (
        <Grid item flexDirection={"column"} xs={6} sx={{ ml: "5px" }}>
          <Grid item>
            <Typography variant="h5" fontWeight={500}>
              {t("pending_avail")}
            </Typography>
          </Grid>
          {pendingParticipantsArray.map((pendingParticipant, i) => {
            return (
              <Typography
                variant="h5"
                key={i}
                sx={{
                  pt: "8px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {pendingParticipant}
              </Typography>
            );
          })}
        </Grid>
      ) : null;
    });

    return pendingElements;
  };

  /**
   * Time range displayed in a time slot tooltip
   * @returns - array of react components
   */
  const getDateTime = () => {
    var startAndEndSame = true;

    return freeSlots.events.map((availableTime, i) => {
      if (availableTime.start.getDate() !== availableTime.end.getDate()) {
        startAndEndSame = false;
      } else {
        startAndEndSame = true;
      }

      if (startAndEndSame) {
        return (
          <Grid item key={i} xs={12} sx={{ pb: "10px", pl: "8px" }}>
            <Typography variant="h4">
              {availableTime.start.toLocaleDateString()} (
              {availableTime.start.toLocaleTimeString(undefined, {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              })}{" "}
              -{" "}
              {availableTime.end.toLocaleTimeString(undefined, {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              })}
              )
            </Typography>
          </Grid>
        );
      } else {
        return (
          <Grid item key={i} xs={12} sx={{ pb: "10px", pl: "8px" }}>
            <Typography variant="h4">
              {availableTime.start.toLocaleDateString()} (
              {availableTime.start.toLocaleTimeString()}) -{" "}
              {availableTime.end.toLocaleDateString()} (
              {availableTime.end.toLocaleTimeString()})
            </Typography>
          </Grid>
        );
      }
    });
  };

  /**
   * The title and availability for a given time slot component
   * @param {array} available
   * @param {array} pending
   * @param {array} title
   * @param {array} dateTime
   * @returns
   */
  const toolTipTitleElement = (available, pending, title, dateTime) => {
    return freeSlots.events.map((availableTime, i) => {
      return (
        <Grid
          container
          style={{
            backgroundColor: "white",
            width: "100%",
            padding: 5,
            borderRadius: 10,
            display: "box",
          }}
          key={i}
          datastart={availableTime.start}
          dataend={availableTime.end}
        >
          <Box style={{ width: "100%" }}>
            <Typography variant="h3" fontWeight={400}>
              {title[i]}
            </Typography>
          </Box>
          <Box>
            <Typography variant="h4">{dateTime[i]}</Typography>
          </Box>
          <Box style={{ width: "100%", display: "flex" }}>
            {available[i]}
            {pending[i]}
          </Box>
        </Grid>
      );
    });
  };

  /**
   * Helper for creating tooltip titles for each time slot
   * @returns - array of titles
   */
  const createTooltipTitles = async () => {
    var availableParticipants = await getParticipantsArrays("available");
    var unavailableParticipants = await getParticipantsArrays("unavailable");

    var availableParticipantNames = collectParticipantNames(
      availableParticipants
    );
    var unavailableParticipantNames = collectParticipantNames(
      unavailableParticipants
    );

    var availableParticipantElements = participantElements(
      availableParticipantNames,
      unavailableParticipantNames
    );
    var pendingElements = pendingParticipantElements(
      availableParticipantNames,
      unavailableParticipantNames
    );
    var title = popupTitle(availableParticipants);
    var dateTimeElements = getDateTime();
    var toolTipTitle = toolTipTitleElement(
      availableParticipantElements,
      pendingElements,
      title,
      dateTimeElements
    );

    return toolTipTitle;
  };

  /**
   * Creates the tooltip component for a time slot
   */
  const createTooltip = async () => {
    var tooltipTitles = await createTooltipTitles();

    var eventTooltips = tooltipTitles?.map((participants, i) => {
      return (
        <Tooltip
          key={i}
          enterDelay={0}
          enterTouchDelay={0}
          leaveTouchDelay={5000}
          title={participants}
          placement={mobile ? "top-start" : "right-start"}
          datastart={participants.props.datastart}
          dataend={participants.props.dataend}
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: "transparent",
                padding: 0,
                wordWrap: "normal",
              },
            },
          }}
        >
          <div
            style={{
              minWidth: "fit-content",
              height: "100%",
            }}
          ></div>
        </Tooltip>
      );
    });

    setEventTooltips(eventTooltips);
  };

  /**
   * Adds tooltips to the event objects passed to FullCalendar
   * @param {object} arg - event object
   * @returns - events with tooltip array
   */
  const injectTooltip = (arg) => {
    var start = arg.event._instance.range.start;
    var end = arg.event._instance.range.end;
    var offset = start.getTimezoneOffset() / 60;

    var localStart = new Date(
      start?.getFullYear(),
      start?.getMonth(),
      start?.getDate(),
      start?.getHours() + offset,
      start?.getMinutes()
    );
    var localEnd = new Date(
      end?.getFullYear(),
      end?.getMonth(),
      end?.getDate(),
      end?.getHours() + offset,
      end?.getMinutes()
    );

    var tooltip = eventTooltips.find(
      (eventTooltip) =>
        eventTooltip.props.datastart.getTime() === localStart.getTime() &&
        eventTooltip.props.dataend.getTime() === localEnd.getTime()
    );

    return tooltip;
  };

  /**
   * Sorts times based on the time parameter passed to it
   * @param {string} time - the direction in which to sort the time (earliest/latest first)
   * @returns - sorted time array
   */
  const sortTimes = (time) => {
    var userUnavailableFiltered = filterAvailableTimes(totalEvents);

    var temp = [...userUnavailableFiltered];
    switch (time === "earliest") {
      case true:
        temp.sort((a, b) => a.start.getHours() - b.start.getHours());
        break;
      case false:
        temp.sort((a, b) => b.end.getHours() - a.end.getHours());
        break;
    }
    return temp;
  };

  /**
   * Finds the minimum or maximum time value in the avaialable times
   * @param {string} setting - time to be found (min/max)
   * @returns - the min or max time from the available time slots array
   */
  const getMinMaxTime = (setting) => {
    var sortedAvailability;
    var temp;

    if (setting === "min") {
      sortedAvailability = sortTimes("earliest");
      temp = sortedAvailability[0]?.start?.getHours();
    } else {
      sortedAvailability = sortTimes("latest");
      temp = sortedAvailability[0]?.end?.getHours() + 1;
    }

    if (temp < 10) {
      return `0${temp?.toString()}`;
    } else {
      return temp?.toString();
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card
          sx={{
            p: mobile ? "0" : "15px",
            boxShadow: "0",
            borderRadius: "10px",
            backgroundColor: theme.palette.secondary.light,
            marginLeft: mobile ? 0 : "20px",
          }}
        >
          <Navigation calendarRef={calendarRef} mobile={mobile} />
          <Box
            sx={{
              overFlow: "auto",
              height: "66vh",
              // maxHeight: "720px"
            }}
          >
            {mobile ? (
              <FullCalendar
                // firstDay={1}
                plugins={[timeGridPlugin, interactionPlugin]}
                eventContent={popover ? (arg) => injectTooltip(arg) : null}
                initialView="timeGridWeek"
                allDaySlot={false}
                slotDuration={"00:15:00"}
                slotMinTime={`${getMinMaxTime("min")}:00:00`}
                slotMaxTime={`${getMinMaxTime("max")}:00:00`}
                expandRows={true}
                height={600}
                headerToolbar={false}
                slotLabelFormat={{
                  hour: "numeric",
                  minute: "2-digit",
                  // omitZeroMinute: true,
                  hour12: true,
                }}
                eventSources={[freeSlots ?? [], selectedTimes ?? []]}
                eventDisplay="block"
                eventOrderStrict={true}
                slotEventOverlap={false}
                displayEventTime={false}
                views={{
                  timeGridWeek: {
                    dayHeaderContent: (date) => dayHeader(date, "week"),
                  },
                }}
                selectable={true}
                datesSet={onCalendarMonthChange}
                // longPressDelay={500}
                selectLongPressDelay={500}
                select={initiatorResponse ? handleSingleSelect : handleSelect}
                navLinks={!initiatorResponse}
                navLinkDayClick={(date) => handleWeekdayClick(date)}
                ref={calendarRef}
              />
            ) : (
              <FullCalendar
                // firstDay={1}
                plugins={[timeGridPlugin, interactionPlugin]}
                eventContent={(arg) => injectTooltip(arg)}
                // scrollTime={
                //   freeSlots.events.length > 0
                //     ? moment(freeSlots.events[0]?.start).format("hh:mm")
                //     : false
                // }
                datesSet={onCalendarMonthChange}
                initialView="timeGridWeek"
                allDaySlot={false}
                slotDuration={"00:15:00"}
                slotMinTime={`${getMinMaxTime("min")}:00:00`}
                slotLabelInterval={"01:00"}
                slotMaxTime={`${getMinMaxTime("max")}:00:00`}
                expandRows={true}
                headerToolbar={false}
                height={"100%"}
                slotLabelFormat={{
                  hour: "numeric",
                  minute: "2-digit",
                  // omitZeroMinute: true,
                  hour12: true,
                }}
                eventSources={[freeSlots ?? [], selectedTimes ?? []]}
                // eventDisplay="block"
                eventOrderStrict={true}
                slotEventOverlap={false}
                displayEventTime={false}
                views={{
                  timeGridWeek: {
                    dayHeaderContent: (date) => dayHeader(date, "week"),
                  },
                }}
                selectable={isLogged ? false : true}
                select={initiatorResponse ? handleSingleSelect : handleSelect}
                selectConstraint={"clickable"}
                navLinks={!initiatorResponse}
                navLinkDayClick={(date) => handleWeekdayClick(date)}
                ref={calendarRef}
              />
            )}
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
}
