import makeStyles from "@mui/styles/makeStyles";
const useStyle = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    justifyContent: "center",
    // height: "85vh",
    alignItems: "center",
    padding: 16,
    paddingTop: 80,
  },

  meetingContainer: {
    backgroundColor: "#96DCFF40",
    padding: "16px 32px",
    textAlign: "left",
    borderRadius: 16,
    width: "100%",

    [theme.breakpoints.up("md")]: {
      width: "30%",
    },
  },
  iconBox: {
    display: "flex",
    gap: 8,
    alignItems: "center",
    marginTop: 8,
  },
  timeIcon: {
    fontSize: "14px",
    color: theme.palette.text.primary,
  },
  boxContainer: {
    display: "flex",
    gap: 16,
    justifyContent: "center",
    padding: 8,
  },
  languageContainer: {
    display: "flex",
    justifyContent: "center",
    margin: "16px 0",
    gap: 8,
  },
}));

export default useStyle;
