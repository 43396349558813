import { useState } from "react";

import { Grid, Typography, Snackbar, Button, useTheme } from "@mui/material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";

export default function Location({ event }) {
  const [open, setOpen] = useState(false);
  const theme = useTheme();

  const checkIfLocationIsLink = () => {
    if (event?.location !== null || event?.location !== undefined) {
      if (event?.location?.search("http") !== -1) {
        return true;
      } else {
        return false;
      }
    }
  };

  const copyToClipboard = (str) => {
    navigator.clipboard.writeText(str);

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return event?.location === null || event?.location === undefined ? null : (
    <Grid container spacing={1} alignItems="center" pb="10px">
      <Grid item xs={1} md={0.7} container justifyContent="flex-start">
        <LocationOnOutlinedIcon
          style={{ width: "14px", color: theme.palette.text.primary }}
        />
      </Grid>

      <Grid item container xs={11} alignItems="center">
        {checkIfLocationIsLink() ? (
          <Button
            onClick={() => copyToClipboard(event?.location)}
            sx={{ pl: "0px" }}
          >
            <Typography
              variant="h3"
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                color: "#479AE9",
                maxWidth: { xs: 300, md: 460 },
              }}
              align="left"
              fontWeight={400}
            >
              {event.location}
            </Typography>
            <Snackbar
              open={open}
              onClose={handleClose}
              message="Meeting link copied to clipboard"
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              hiddenHideDuration={2000}
            />
          </Button>
        ) : (
          <Typography variant="h3" align="left" fontWeight={400}>
            {event.location}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}
