import { useNavigate } from "react-router-dom";
import { Button, Grid, Typography, Box } from "@mui/material";
import MeetingDetailsComponent from "components/NewEvent/MeetingDetailsComponent";
import {
  MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS,
  ONE_SKEDING_MULTIPLE_NONSKEDING_USERS,
  RECURRING_EVENT,
} from "Utils/Constants";
import Signup from "components/MeetingInvitation/Signup";
import MultiNonSignUp from "./MultiNonSignUp";
import { handleTimeZone } from "timeZone";
import DialogBox from "components/DialogBox";
import SnackbarComponent from "components/Global/SnackbarComponent";
import { ReactComponent as SkedingBetaIcon } from "../assets/logo-beta.svg";
import AllSkedingMeetingChip from "components/NewEvent/AllSkedingMeetingChip";
import ShowTimes from "./temp/show";
import { useState } from "react";
import RejectComment from "components/RejectComment";
import AcceptComment from "components/AcceptComment";
import { useTranslation } from "react-i18next";
import Languages from "components/Account/Languages";
import { SINGLE_SLOT_MEETING } from "../Utils/Constants";

export default function UserNonSignup({
  meetingData,
  selectedSlot,
  setSelectedSlot,
  errorAmount,
  setErrorAmount,
  disabled,
  events,
  modal,
  handleClose,
  successModal,
  handleSuccessClose,
  isLogged,
  theme,
  mobile,
  submit,
  handleDownload,
  calView,
  handleReject,
  handleSubmit,
  noMatchModal,
  handleNoMatchClose,
  sharedAvailableTimes,
  setSharedAvailableTimes,
}) {
  const navigate = useNavigate();
  const [rejectConfirm, setRejectConfirm] = useState(false);
  const [acceptConfirm, setAcceptConfirm] = useState(false);
  const { t } = useTranslation();

  const handleRejectConfirm = () => {
    setRejectConfirm((prev) => !prev);
  };

  const handleAcceptConfirm = () => {
    setAcceptConfirm((prev) => !prev);
  };

  return meetingData === null ? null : meetingData.case ===
      MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS ||
    meetingData.case === ONE_SKEDING_MULTIPLE_NONSKEDING_USERS ? (
    <MultiNonSignUp meetingData={meetingData} events={events} />
  ) : (
    <Grid container sx={{ minHeight: "100vh", width: "100%" }}>
      {modal && (
        <DialogBox
          modal={modal}
          handleClose={handleClose}
          msg={t("already_responded")}
          close={false}
          nonsignup={true}
        />
      )}

      <SnackbarComponent
        open={successModal}
        handleClose={handleSuccessClose}
        message={t("response_recorded")}
        vertical={"bottom"}
        horizontal={"center"}
      />
      {isLogged === false && !mobile && (
        <Grid
          container
          item
          xs={0}
          md={6.5}
          justifyContent="center"
          sx={{
            // backgroundColor: theme.palette.secondary.light,
            background:
              "linear-gradient(134.6deg, rgba(161, 106, 244, 0.15) 12.4%, rgba(71, 154, 233, 0.15) 40.57%, rgba(71, 154, 233, 0.15) 61.7%, rgba(31, 201, 184, 0.15) 80.57%)",
          }}
        >
          {!mobile && <Signup theme={theme} />}
        </Grid>
      )}

      <Grid
        container
        item
        xs={12}
        md={5.5}
        alignItems={mobile ? "flex-start" : "center"}
        justifyContent={"flex-start"}
      >
        <Grid container item xs={12} sx={{ ml: { xs: 2, md: "10%" } }}>
          {submit ? (
            <Grid item xs={12}>
              {/* <img src="meetingCreated.png" /> */}
              <Typography sx={{ fontSize: "36px", fontWeight: "600" }}>
                {t("scheduled_success")}
              </Typography>
            </Grid>
          ) : (
            <Grid item xs={12}>
              {mobile && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  style={{ width: "95%", marginTop: 10 }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "#479AE9",
                      width: "72px",
                    }}
                  >
                    <SkedingBetaIcon fill="#479AE9" />
                  </Box>
                  <Button
                    variant="contained"
                    style={{ borderRadius: 4 }}
                    onClick={() => navigate("/register")}
                  >
                    {t("sign_up")}
                  </Button>
                </Box>
              )}
              <Box>
                <Box style={{ textAlign: "left" }} mt={2}>
                  <Typography variant="h4" style={{ marginBottom: "4px" }}>
                    Language
                  </Typography>
                  <Languages showStyles={true} />
                </Box>
                <Typography
                  variant="h2"
                  align="left"
                  style={{ marginTop: 10, marginBottom: 15 }}
                >
                  {t("meeting_invite")}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="h4"
                  align="left"
                  sx={{
                    fontWeight: 400,
                    color: "#484747",
                  }}
                >
                  {t("timezone_title") + ":"}{" "}
                  {Intl.DateTimeFormat()
                    .resolvedOptions()
                    .timeZone.replace(/_/g, " ") +
                    " " +
                    handleTimeZone()}
                </Typography>
              </Box>

              <MeetingDetailsComponent
                theme={theme}
                meetingData={meetingData}
                handleDownload={handleDownload}
              />
              {[RECURRING_EVENT, SINGLE_SLOT_MEETING].includes(
                meetingData?.case
              ) ? (
                <Box
                  mt={2}
                  display="flex"
                  flexDirection={"column"}
                  alignItems="flex-start"
                >
                  {/* <Typography
                    variant="h2"
                    style={{ marginBottom: 16 }}
                    align="left"
                    fontWeight={500}
                  >
                    Choosen Timeslot
                  </Typography> */}
                  <AllSkedingMeetingChip meetingData={meetingData} />
                </Box>
              ) : null}
            </Grid>
          )}

          {/* calendar option remove temp */}

          <Grid item xs={12} style={{ marginTop: 20 }}>
            {/* {events?.length > 0 ? (
              <>
              
                <Box style={{ textAlign: "left" }}>
                  <Button
                    variant="text"
                    style={{ fontSize: 12 }}
                    startIcon={calView ? <ListIcon /> : <CalendarMonthIcon />}
                    onClick={() => setCalView((prev) => !prev)}
                  >
                    {calView ? "List view" : "Calendar view"}
                  </Button>
                </Box>
                <Box
                  style={{
                    display: calView ? "block" : "none",
                    width: "95%",
                    height: "400px",
                    overflow: "hidden",
                    maxWidth: "520px",
                  }}
                >
                  <TimeCalendar
                    available={
                      events?.length > 0
                        ? events
                        : meetingData?.proposed_timeslots
                    }
                    events={events}
                    meetingData={meetingData}
                    eventCase={
                      events?.length > 0 ? "NonSignUp" : "NonSignUpOneSelect"
                    }
                    selectedSlot={selectedSlot}
                    setSelectedSlot={setSelectedSlot}
                    errorAmount={errorAmount}
                    setErrorAmount={setErrorAmount}
                    calendarRef={calendarRef}
                    participants={["user"]}
                    timeInterval="not empty"
                    sharedAvailableTimes={sharedAvailableTimes}
                    setSharedAvailableTimes={setSharedAvailableTimes}
                    unavailability={[]}
                  />
                </Box>
              </>
            ) : null} */}

            <Box style={{ display: calView ? "none" : "block" }}>
              {events?.length > 0 ? (
                <>
                  <Typography
                    variant="h2"
                    style={{ marginTop: 16 }}
                    align="left"
                    fontWeight={500}
                  >
                    {t("new_slots_msg")}
                  </Typography>
                  <Typography
                    variant="h4"
                    align="left"
                    sx={{
                      fontWeight: 400,
                      color: theme.palette.info.light,
                      marginTop: "5px !important",
                    }}
                  >
                    {t("no_slots_avail")}
                  </Typography>
                  <ShowTimes
                    setSelectedSlot={setSelectedSlot}
                    selectedSlot={selectedSlot}
                    events={
                      events?.length > 0
                        ? events
                        : meetingData?.proposed_timeslots
                    }
                    errorAmount={errorAmount}
                    setErrorAmount={setErrorAmount}
                    sharedAvailableTimes={sharedAvailableTimes}
                    setSharedAvailableTimes={setSharedAvailableTimes}
                    eventCase="NonSignUp"
                  />
                </>
              ) : (
                <>
                  {meetingData?.proposed_timeslots?.length === 1 ? (
                    <Box
                      mt={2}
                      display="flex"
                      flexDirection={"column"}
                      alignItems="flex-start"
                    >
                      <Typography
                        variant="h2"
                        style={{ marginBottom: 4 }}
                        align="left"
                        fontWeight={500}
                      >
                        {t("chosen_slot")}
                      </Typography>
                      <AllSkedingMeetingChip
                        meetingData={{
                          start_datetime: meetingData?.proposed_timeslots[0]
                            ?.startTime
                            ? meetingData?.proposed_timeslots[0]?.startTime
                            : meetingData?.proposed_timeslots[0]?.start,

                          end_datetime: meetingData?.proposed_timeslots[0]
                            ?.endTime
                            ? meetingData?.proposed_timeslots[0]?.endTime
                            : meetingData?.proposed_timeslots[0]?.end,
                        }}
                        hideTitle={true}
                      />
                    </Box>
                  ) : meetingData?.proposed_timeslots?.length > 1 ? (
                    <>
                      <Typography
                        variant="h3"
                        fontWeight={500}
                        style={{ marginTop: 16 }}
                        align="left"
                      >
                        {t("pick_slot")}
                      </Typography>
                      <ShowTimes
                        setSelectedSlot={setSelectedSlot}
                        selectedSlot={selectedSlot}
                        events={meetingData?.proposed_timeslots}
                        errorAmount={errorAmount}
                        setErrorAmount={setErrorAmount}
                        eventCase="NonSignUpOneSelect"
                        sharedAvailableTimes={sharedAvailableTimes}
                        setSharedAvailableTimes={setSharedAvailableTimes}
                      />
                    </>
                  ) : null}
                </>
              )}
            </Box>
          </Grid>
          <Box
            display="flex"
            justifyContent={mobile ? "center" : "space-between"}
            style={{
              width: mobile ? "100%" : 200,
              marginBottom: mobile ? 16 : 10,
            }}
            mt="28px"
          >
            <Button onClick={handleRejectConfirm} variant="outlined">
              {t("reject")}
            </Button>
            <Box mr={"20px"} style={{ display: { xs: "block", md: "none" } }} />
            <Button
              // onClick={handleSubmit}
              onClick={handleAcceptConfirm}
              variant="contained"
              disabled={
                [RECURRING_EVENT, SINGLE_SLOT_MEETING].includes(
                  meetingData?.case
                )
                  ? false
                  : disabled || selectedSlot.length === 0
              }
            >
              {t("accept")}
            </Button>
          </Box>
        </Grid>

        <RejectComment
          rejectConfirm={rejectConfirm}
          handleRejectConfirm={handleRejectConfirm}
          handleReject={handleReject}
          loading={disabled}
        />
        <AcceptComment
          acceptConfirm={acceptConfirm}
          handleAcceptConfirm={handleAcceptConfirm}
          handleAccept={handleSubmit}
          loading={disabled}
        />

        {/* {mobile && (
          <Grid item xs={12} md={0} justifyContent="center">
            <Signup theme={theme} />
          </Grid>
        )} */}
        {events?.length > 0 && noMatchModal && (
          <DialogBox
            modal={noMatchModal}
            handleClose={handleNoMatchClose}
            msg={t("no_slot_endmsg")}
            close={true}
          />
        )}
      </Grid>
    </Grid>
  );
}
