import React, { useContext } from "react";
import ChooseLocation from "../ChooseLocation";
import { accountContext } from "../../../context/user";
import useStyle from "../styles";
import useWebsiteStore from "../../../Utils/websiteStore";

export default function WebsiteLocationPreview() {
  const classes = useStyle();
  const details = useWebsiteStore((store) => store.details);
  const websiteLocations = useWebsiteStore((store) => store.websiteLocations);

  const { user } = useContext(accountContext);

  return (
    <div className={classes.servicePreview}>
      <ChooseLocation
        locations={websiteLocations}
        title={details.title}
        description={details.description}
        skeding_user_id={{
          first_name: user?.name.split(" ")[0],
          last_name: user?.name.split(" ")[1],
          picture: details.picture,
        }}
        client={false}
        category={details.category}
      />
    </div>
  );
}
