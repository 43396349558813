import React, { useContext, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { authContext } from "context/Auth";
import useStyle from "./styles";
import { httpGetUnsecure } from "../../Utils/HttpFunctions";
import loggingInDev from "../../loggingInDev";

export default function AppleSigning() {
  const navigate = useNavigate();
  const [cookie] = useCookies();
  const classes = useStyle();

  const { setIsLogged } = useContext(authContext);

  useEffect(() => {
    getAuth();
  }, []);

  // const getAuth = async () => {
  //   const path = `/apple/callback${
  //     window.location.search
  //   }&offset=${new Date().getTimezoneOffset()}&timezone=${
  //     Intl.DateTimeFormat().resolvedOptions().timeZone
  //   }`;
  //   console.log("🚀 ~ getAuth ~ path:", path);
  //   const response = await httpGetUnsecure(path);

  //   if (response && response.constructor !== String) {
  //     loggingInDev("response inside component ===>", response);
  //     const data = response.data.user;

  //     localStorage.setItem("token", data.token);
  //     localStorage.setItem("first_name", data.first_name);
  //     localStorage.setItem("last_name", data.last_name);
  //     localStorage.setItem("refresh_token", data.refresh_token);
  //     localStorage.setItem("isEmailVerified", data.isEmailVerified);
  //     localStorage.setItem("user_id", data.id);
  //     localStorage.setItem("email", data.email);
  //     localStorage.openpages = Date.now();
  //     sessionStorage.setItem("loggedIn", true);
  //     console.log("data ===> ", data);
  //     setIsLogged(true);
  //     const redirectPath = cookie.redirectPath;
  //     if (redirectPath) {
  //       if (!data.is_welcomed) {
  //         navigate(`${redirectPath}&userSignup=true&isCalendar=true`, {
  //           replace: true,
  //         });
  //       } else {
  //         navigate(redirectPath);
  //       }
  //     } else {
  //       if (!data.is_welcomed) {
  //         navigate("/dashboard?userSignup=true&isCalendar=true", {
  //           replace: true,
  //         });
  //       } else {
  //         navigate("/dashboard", { replace: true });
  //       }
  //     }
  //   } else {
  //     navigate("/login?access_error=true");
  //     return;
  //   }
  // };
  const getAuth = async () => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    if (params) {
      console.log("seacrh query", params);
      localStorage.setItem("token", params.get("token"));
      sessionStorage.setItem("loggedIn", true);
      localStorage.setItem("first_name", params.get("first_name"));
      localStorage.setItem("last_name", params.get("last_name"));
      localStorage.setItem("refresh_token", params.get("refresh_token"));
      localStorage.setItem("isEmailVerified", params.get("isEmailVerified"));
      localStorage.setItem("email", params.get("email"));
      localStorage.openpages = Date.now();
      setIsLogged(true);
      const redirectPath = cookie.redirectPath;
      if (redirectPath) {
        navigate(redirectPath);
      } else {
        navigate("/dashboard", { replace: true });
      }
    }
  };

  return (
    <div className={classes.centerLoader}>
      <CircularProgress />
    </div>
  );
}
