import {
  Button,
  Dialog,
  Grid,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { ReactComponent as EyeVisible } from "assets/eye_visible.svg";
import { ReactComponent as EyeHidden } from "assets/eye_hidden.svg";

export function CardLabels({
  title,
  value,
  CardIcon,
  link,
  is_user,
  formName,
  placeHolder,
  handleFormChange,
  is_location,
  formData,
  isRequired,
  type,
  isDisabled,
  handleHideShow,
}) {
  const { t } = useTranslation();
  const [openLocation, setOpenLocation] = useState(false);
  const location = `${
    formData?.street?.value ? formData?.street?.value + " " : ""
  }${formData?.state?.value ? formData?.state?.value + " " : ""}${
    formData?.city?.value ? formData?.city?.value + " " : ""
  }${formData?.postal_code?.value ? formData?.postal_code?.value + " " : ""}${
    formData?.country?.value ? formData?.country?.value : ""
  }`;
  const locationLink = `https://www.google.com/maps/place/
  ${formData?.street?.value ? formData?.street?.value + "+" : ""}
  ${formData?.state?.value ? formData?.state?.value + "+" : ""}
  ${formData?.city?.value ? formData?.city?.value + "+" : ""}${
    formData?.country?.value ? formData?.country?.value : ""
  }`;

  const handleClose = () => {
    setOpenLocation(false);
  };

  const handleAccountClick = () => {
    if (link) {
      if (
        !link.startsWith("http://") &&
        !link.startsWith("https://") &&
        !link.startsWith("tel:")
      ) {
        link = "https://" + link;
      }

      window.open(link, "_blank");
    }
  };

  return (
    <>
      {!is_user && isDisabled ? (
        <></>
      ) : (
        <Grid
          item
          direction={"row"}
          display={"flex"}
          gap={1}
          pb={1}
          mb={3}
          sx={{
            borderBottom: "0.5px #B3B5B6 solid",
          }}
        >
          {CardIcon}
          <Grid item width={"100%"}>
            <Typography variant="h3" fontWeight={500} mb={0.5}>
              {title}
            </Typography>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"flex-end"}
              direction={"row"}
            >
              {is_location && is_user ? (
                <Typography
                  variant="h3"
                  fontWeight={400}
                  onClick={() => setOpenLocation(true)}
                  style={{
                    color: location ? "#479AE9" : "#B3B5B6",
                    width: "100%",
                  }}
                  // pr={6}
                >
                  {location ? location : "street, city, country"}
                </Typography>
              ) : is_user ? (
                <Box display={"flex"}>
                  <TextField
                    name={formName}
                    value={value}
                    onChange={handleFormChange}
                    variant="standard"
                    placeholder={placeHolder}
                    required={isRequired}
                    type={type}
                    disabled={isDisabled}
                    InputProps={{
                      disableUnderline: true,
                      style: {
                        color: "#479AE9",
                        width: "250px",
                      },
                    }}
                  />
                </Box>
              ) : link ? (
                <Link
                  onClick={handleAccountClick}
                  style={{ textDecoration: "none" }}
                >
                  <Typography
                    variant="h3"
                    fontWeight={400}
                    color={"#479AE9"}
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "280px",
                    }}
                  >
                    {value}
                  </Typography>
                </Link>
              ) : is_location ? (
                <Link
                  to={locationLink}
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <Typography variant="h3" fontWeight={400} color={"#479AE9"}>
                    {isDisabled ? "" : location}
                  </Typography>
                </Link>
              ) : (
                <Typography variant="h3" fontWeight={400} color={"#479AE9"}>
                  {isDisabled ? <></> : value}
                </Typography>
              )}
              {formName === "name" || formName === "email" || !is_user ? (
                <></>
              ) : isDisabled ? (
                <EyeHidden
                  style={{ cursor: "pointer", marginLeft: "auto" }}
                  onClick={() => handleHideShow(formName)}
                />
              ) : (
                <EyeVisible
                  style={{ cursor: "pointer", marginLeft: "auto" }}
                  onClick={() => handleHideShow(formName)}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      )}
      <Dialog
        open={openLocation}
        onClose={handleClose}
        sx={{ justifyContent: "center" }}
        PaperProps={{
          sx: {
            borderRadius: "8px",
            padding: "8px 16px",
            borderRadius: "16px",
            width: "75%",
            margin: "0px",
          },
        }}
      >
        <Box
          display="flex"
          justifyContent="flex-end"
          width="100%"
          style={{
            marginTop: "8px",
          }}
        >
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Grid container direction={"column"} gap={2}>
          <Typography variant="h2" textAlign={"center"}>
            {t("address")}
          </Typography>
          <LocationInput
            title={"Address line"}
            formName="street"
            placeholder={"Enter your location"}
            value={formData?.street?.value}
            isDisabled={formData?.street?.is_hidden}
            handleFormChange={handleFormChange}
            handleHideShow={handleHideShow}
          />

          <LocationInput
            title={t("state")}
            formName="state"
            placeholder={"e.g New York"}
            value={formData?.state?.value}
            isDisabled={formData?.state?.is_hidden}
            handleFormChange={handleFormChange}
            handleHideShow={handleHideShow}
          />
          <Grid item display={"flex"} gap={1}>
            <Grid item xs={7.5}>
              <LocationInput
                title={t("city")}
                formName="city"
                placeholder={"e.g New York"}
                value={formData?.city?.value}
                isDisabled={formData?.city?.is_hidden}
                handleFormChange={handleFormChange}
                handleHideShow={handleHideShow}
              />
            </Grid>

            <Grid item xs={4.5}>
              <LocationInput
                title={t("postal_code")}
                formName="postal_code"
                placeholder={"e.g 126000"}
                value={formData?.postal_code?.value}
                isDisabled={formData?.postal_code?.is_hidden}
                handleFormChange={handleFormChange}
                handleHideShow={handleHideShow}
              />
            </Grid>
          </Grid>

          <LocationInput
            title={t("country")}
            formName="country"
            placeholder={"e.g United States"}
            value={formData?.country?.value}
            isDisabled={formData?.country?.is_hidden}
            handleFormChange={handleFormChange}
            handleHideShow={handleHideShow}
          />
          <Grid display={"flex"} justifyContent={"center"}>
            <Button variant="contained" onClick={handleClose}>
              {t("save")}
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}

function LocationInput({
  title,
  formName,
  value,
  placeholder,
  isDisabled,
  handleFormChange,
}) {
  return (
    <Grid item>
      <Typography variant="h4">{title}</Typography>
      <TextField
        placeholder={placeholder}
        name={formName}
        disabled={isDisabled}
        value={value}
        onChange={handleFormChange}
        variant="standard"
        sx={{
          width: "100%",
          marginTop: "4px",
        }}
        InputProps={{
          disableUnderline: true,
          style: {
            fontSize: "16px",
            fontWeight: 400,
            border: "1px solid #DAD2E5",
            borderRadius: "6px",
            paddingLeft: "8px",
            backgroundColor: "#F7F7F9",
          },
        }}
      />
      {/* {isDisabled ? (
        <EyeHidden
          style={{ width: "15%", cursor: "pointer" }}
          onClick={() => handleHideShow(formName)}
        />
      ) : (
        <EyeVisible
          style={{ width: "15%", cursor: "pointer" }}
          onClick={() => handleHideShow(formName)}
        />
      )} */}
    </Grid>
  );
}
