import { useState } from "react";
import { Typography, Box, Button, useTheme } from "@mui/material";
import { ReactComponent as Email } from "assets/email.svg";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

export default function SignupVerification({
  email,
  resendEmail,
  resendDisable,
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Box mt={6}>
      <Typography variant="h3" style={{ fontSize: "16px" }} align="center">
        {t("thanking")}
      </Typography>

      <Typography
        variant="h5"
        align="center"
        style={{
          marginTop: 16,
          fontSize: "14px",
          marginTop: "24px",
          fontWeight: 400,
        }}
      >
        {t("verify_link")} <b>{email}</b>.
        <br /> {t("confirm_account")}
      </Typography>

      <Box mt={4} style={{ textAlign: "center" }}>
        <Email />
      </Box>
      <Box mt={6} style={{ textAlign: "center" }}>
        <Typography
          align="center"
          variant="h3"
          style={{ fontSize: "14px" }}
          fontWeight={400}
        >
          {t("verify_email_resend")}
          <span>
            <Button
              variant="text"
              style={{
                paddingLeft: "4px",
                color: !resendDisable ? theme.palette.primary.main : "#70757A",
                fontWeight: !resendDisable ? "bold" : 400,
              }}
              onClick={resendEmail}
              disabled={resendDisable}
            >
              {t("resend")}
            </Button>
          </span>
        </Typography>
      </Box>
    </Box>
  );
}
