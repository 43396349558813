import React from "react";
import UserRoutes from "UserRoutes";
import "./App.css";
import AuthProvider from "context/Auth";
import TourProvider from "context/Tour";
import NotificationProvider from "context/Notification";
import DrawerProvider from "context/Drawer";
import IntegrationProvider from "context/Integrations";
import AccountProvider from "context/user";
import BlogsProvider from "context/Blogs";
import ThemeColorProvider from "context/ThemeColorProvider";
import { CookiesProvider } from "react-cookie";
import * as Sentry from "@sentry/react";
import { useJsApiLoader } from "@react-google-maps/api";
import Spinner from "./components/Global/Spinner";

function App() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyB2y_R_q5fMF7BW4frpSY6sEC60_eC--uU",
    libraries: ["places"],
  });

  return !isLoaded ? (
    <Spinner />
  ) : (
    <div className="App">
      <CookiesProvider>
        <AuthProvider>
          <ThemeColorProvider>
            <AccountProvider>
              <NotificationProvider>
                <DrawerProvider>
                  <IntegrationProvider>
                    <BlogsProvider>
                      <TourProvider>
                        <UserRoutes />
                      </TourProvider>
                    </BlogsProvider>
                  </IntegrationProvider>
                </DrawerProvider>
              </NotificationProvider>
            </AccountProvider>
          </ThemeColorProvider>
        </AuthProvider>
      </CookiesProvider>
    </div>
  );
}

export default Sentry.withProfiler(App);
