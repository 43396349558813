import { useState } from "react";

import {
  Dialog,
  DialogActions,
  Button,
  Box,
  DialogTitle,
  CircularProgress,
  useTheme,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import Comment from "./meetings/Comment";
import { useTranslation } from "react-i18next";

export default function DialogWithSuccessMessage({
  open,
  setOpen,
  message: title,
  operation,
  form,
  disabled: submitted,
  closeAction,
  formIncomplete,
  showComment,
  type,
  mainRejectMessage,
  noMainHeading,
  qr,
}) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const [comment, setComment] = useState("");
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
    if (closeAction) {
      closeAction();
    }
  };

  const handleProceed = async () => {
    var responseSuccessful;
    if (operation) {
      if (showComment) {
        responseSuccessful = await operation(0, comment);
      } else {
        responseSuccessful = await operation();
      }
    }

    if (responseSuccessful) {
      setOpen(false);
      if (closeAction) {
        closeAction();
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{ justifyContent: "center" }}
      PaperProps={{
        sx: {
          borderRadius: "8px",
          padding: qr ? "8px 16px" : "20px",
          borderRadius: "16px",
          width: mobile ? "75%" : "25%",
          margin: "0px",
        },
      }}
    >
      <Box
        display="flex"
        justifyContent="flex-end"
        width="100%"
        style={{
          marginTop: "8px",
        }}
      >
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogTitle
        style={{
          fontSize: "20px",
        }}
        sx={{
          textAlign: "center",
          color: "#363B53",
          fontWeight: "600",
          paddingBottom: "4px",
          paddingTop: "0px",
        }}
      >
        {mainRejectMessage}
      </DialogTitle>
      {title ? (
        <DialogTitle
          style={{
            fontSize: noMainHeading ? "20px" : "16px",
          }}
          sx={{
            textAlign: "center",
            color: "#363B53",
            fontWeight: noMainHeading ? "500" : "400",
            paddingTop: "0px",
            paddingBottom: "14px",
          }}
        >
          {title}
        </DialogTitle>
      ) : null}
      {showComment ? (
        <Comment comment={comment} setComment={setComment} />
      ) : null}

      <DialogActions
        sx={{ justifyContent: "center", pt: "10px", flexDirection: "column" }}
      >
        {form}

        <Box>
          <Button
            variant="submit"
            onClick={handleProceed}
            disabled={formIncomplete || submitted}
            style={{
              fontSize: "14px",
            }}
          >
            {submitted ? (
              <>
                <CircularProgress style={{ color: "white" }} size={20} />
              </>
            ) : type === "edit" ? (
              t("save")
            ) : (
              t("proceed")
            )}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
