import React from "react";

import { TextField, useTheme } from "@mui/material";

import useStyle from "styles/formStyles";
import { useTranslation } from "react-i18next";

/**
 * Form component for adding a title to a meeting
 */
export const Title = React.memo(({ setTitle, title }) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };
  const theme = useTheme();

  return (
    <TextField
      placeholder={t("add_title") + " * "}
      variant="standard"
      autoComplete="off"
      value={title}
      style={{ marginTop: 10 }}
      sx={{ width: "100%" }}
      autoFocus
      InputProps={{
        disableUnderline: true,
        style: {
          fontSize: "16px",
          fontWeight: 400,
          color: theme.palette.text.primary,
        },
        classes: { input: classes.input },
      }}
      onChange={handleTitleChange}
      id="create-form"
    />
  );
});
