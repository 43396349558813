import React, { useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import {
  FREE_PLAN,
  MONTHLY,
  PREMIUM_PLAN,
  YEARLY,
} from "../../Utils/Constants";
import PlanFeatures from "./PlanFeatures";
import { serverPOST } from "../../Utils/HttpFunctions";
import { useTranslation } from "react-i18next";
import CounterBox from "./Counter";

type PaymentPlanProps = {
  planType: string;
  billingType: string;
  selectedPlan?: string;
  isTeamMember?: boolean;
};

export default function PaymentPlan({
  planType,
  billingType,
  selectedPlan,
  isTeamMember,
}: PaymentPlanProps) {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [quantity, setQuantity] = useState(1);

  const handleCheckout = async (key) => {
    setLoading(true);
    const response = await serverPOST("/account/create-checkout-session", {
      price_index: key,
      quantity: quantity,
    });
    if (response && response.constructor !== String) {
      if (response.data.url) {
        window.open(response.data.url, "_blank");
      }
    }
    setLoading(false);
  };

  const handleIncrement = () => {
    setQuantity((prevCount) => prevCount + 1);
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity((prevCount) => prevCount - 1);
    }
  };
  return (
    <Grid container direction={"column"} gap={1}>
      <Grid
        item
        sx={{
          alignItems: "flex-start",
          display: "flex",
          flexDirection: "column",
        }}
        gap={1}
      >
        <Typography variant="h3">
          {planType === FREE_PLAN ? t("skeding_free") : t("skeding_premium")}
        </Typography>
        <Grid item display={"flex"} alignItems={"center"} width="100%">
          <Typography variant="h2">
            {planType === FREE_PLAN
              ? "0.00€"
              : billingType === YEARLY
              ? (6.66 * quantity).toFixed(2) + "€"
              : (8.0 * quantity).toFixed(2) + "€"}
            <span style={{ fontSize: "12px" }}> /month</span>
          </Typography>
          {planType === FREE_PLAN || isTeamMember ? null : (
            <Box
              display="flex"
              alignItems={"center"}
              gap={3}
              sx={{
                marginLeft: "auto",
              }}
            >
              <Typography variant="h4">{billingType}</Typography>
              {planType === PREMIUM_PLAN && (
                <CounterBox
                  disable={undefined}
                  count={quantity}
                  handleDecrement={handleDecrement}
                  handleIncrement={handleIncrement}
                />
              )}
            </Box>
          )}
        </Grid>
      </Grid>

      <Divider style={{ color: "#E0E2E7" }} />
      <PlanFeatures planType={planType} />

      {isTeamMember ? (
        <></>
      ) : (
        <Grid
          item
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {planType === FREE_PLAN ? (
            <Button
              variant="outlined"
              sx={{
                borderColor: "#479AE9",
                color: "#479AE9",
              }}
            >
              {selectedPlan === FREE_PLAN ? t("current_plan") : t("free_plan")}
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={() => {
                // if (selectedPlan === FREE_PLAN)
                handleCheckout(billingType === MONTHLY ? 0 : 1);
              }}
              disabled={selectedPlan === PREMIUM_PLAN ? true : false}
            >
              {loading ? (
                <CircularProgress style={{ color: "white" }} size={20} />
              ) : selectedPlan === PREMIUM_PLAN ? (
                t("current_plan")
              ) : (
                t("get_started")
              )}
            </Button>
          )}
        </Grid>
      )}
    </Grid>
  );
}
