import React, { useState, useEffect, useRef, useContext } from "react";

import {
  Button,
  Grid,
  Box,
  useTheme,
  useMediaQuery,
  Divider,
  Typography,
  Avatar,
} from "@mui/material";
import { Link } from "react-router-dom";
import Person from "assets/bi_person-circle.svg";
import "styles/newEvent.css";
import { colors } from "Utils/colors";
import { convertUTCMillisecondsToDate } from "Utils/CommonFunctions";
import {
  CalendarComponent,
  MobileHeadingToggler,
  MobileSlotsToggler,
} from "pages/ShareableCalendar";
import DialogBox from "components/DialogBox";
import SkedingBanner from "components/SkedingBanner";
import BookingDetails from "components/PublicCalendar/BookingDetails";
import Spinner from "../Global/Spinner";
import { shareableCalendarStyles } from "../../styles/shareableCalendarStyles";
import Style from "style-it";
import { useTranslation } from "react-i18next";
import Timezones from "../Account/Timezones";
import useStyle from "./styles";
import useWebsiteClientStore from "../../Utils/websiteClientStore";
import MeetingDetailsComponent from "../NewEvent/MeetingDetailsComponent";
import BreadCrumb from "./BreadCrumb";
import moment from "moment";

export default function PublicCalendarComponent({
  handlePublicCreate,
  publicData,
  selectedSlot,
  setSelectedSlot,
  loading,
  responseModal,
  isOrganizer,
  meetingId,
  meetingData,
  getMeetingPublicData,
  indicator,
  setIndicator,
}) {
  const [navLink, setNavLink] = useState(false);
  const [date, setDate] = useState(null);
  const [eventDays, setEventDays] = useState([]);
  const [daySlots, setDaySlots] = useState([]);
  const [mobCalView, setMobCalView] = useState(true);
  const theme = useTheme();
  const calendarRef = useRef(null);
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();
  const classes = useStyle();
  const selectedService = useWebsiteClientStore(
    (state) => state.selectedService
  );
  const selectedLocation = useWebsiteClientStore(
    (state) => state.selectedLocation
  );

  const [selectedTimezone, setSelectedTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  useEffect(() => {
    if (publicData) {
      renderChips(new Date(publicData?.slots[0]));
    }
  }, [publicData]);

  useEffect(() => {
    if (publicData) {
      getUniqueDays();
    }
  }, [publicData, selectedTimezone]);

  const getUniqueDays = async () => {
    const parsedDays = await publicData?.slots.map((slot) => {
      let start = convertUTCMillisecondsToDate(slot);
      // let start = createDateWithTimezone(slot, selectedTimezone);

      return {
        start:
          start.getFullYear() +
          "-" +
          (start.getMonth() + 1 < 10
            ? "0" + (start.getMonth() + 1)
            : start.getMonth() + 1) +
          "-" +
          (start.getDate() < 10 ? "0" + start.getDate() : start.getDate()),
      };
    });
    const uniqueDays = parsedDays.filter(
      (v, i, a) => a.findIndex((v2) => v2.start === v.start) === i
    );
    setEventDays(uniqueDays);
  };

  const handleCalendarSelect = (selection) => {
    const temp = new Date(indicator);
    const selectedDate = moment(new Date(selection?.date)).toDate();

    const now = moment(temp).startOf("day");
    const future = moment(temp).add(6, "days").endOf("day");
    if (selectedDate.getTime() < moment().startOf("day").valueOf()) {
      return;
    } else {
      if (
        selectedDate.getTime() >= now.valueOf() &&
        selectedDate.getTime() <= future.valueOf()
      ) {
        console.log("in");
        const selectedDate = new Date(selection?.date);
        renderChips(selection?.date);
        // if (
        //   eventDays.some(
        //     (event) => event.start === moment(selectedDate).format("YYYY-MM-DD")
        //   )
        // ) {
        // }
      } else {
        const start = moment(selection?.date).startOf("day").toDate();
        setIndicator(start);
        getMeetingPublicData(
          `service_duration=${selectedService?.duration_minutes}`,
          start
        );
      }
    }
  };

  const compareDateWithoutTime = (dateA, dateB) => {
    const date1 = new Date(dateA.start);
    date1.setHours(0, 0, 0, 0);
    const date2 = new Date(dateB);
    date2.setHours(0, 0, 0, 0);
    if (date1 > date2) {
      return false;
    } else if (date1 < date2) {
      return false;
    } else {
      return true;
    }
  };

  const renderChips = async (selectedDate) => {
    // const currentTime = new Date();
    let parsedSlots = await publicData?.slots.map((slot, i) => {
      let start = convertUTCMillisecondsToDate(slot);
      // let start = createDateWithTimezone(slot, selectedTimezone);

      // if (start > currentTime) {
      let end = convertUTCMillisecondsToDate(
        slot +
          (selectedService?.duration_minutes || 60) * 60 * 1000 +
          (publicData?.userWebCalendarPreferences?.breaks || 0) * 60 * 1000
      );

      // let end = createDateWithTimezone(
      //   slot +
      //     publicData?.userWebCalendarPreferences?.duration * 60 * 1000 +
      //     publicData?.userWebCalendarPreferences?.breaks * 60 * 1000,
      //   selectedTimezone
      // );
      return {
        start,
        end,
      };
      // }
    });
    // parsedSlots = parsedSlots.filter(function (elem) {
    //   return elem !== undefined;
    // });

    const daySlots = await parsedSlots.filter((v) => {
      let result = compareDateWithoutTime(v, selectedDate);
      // let result = compareDateWithoutTime(
      //   v,
      //   createDateWithTimezone(selectedDate)
      // );

      if (result) {
        return v;
      }
    });
    setDate(new Date(selectedDate));
    setDaySlots(daySlots);
  };

  // !loading ? (
  return (
    <Style>
      {shareableCalendarStyles(isOrganizer)}
      <div
        className={classes.cientSideContainer}
        style={{
          width: isOrganizer || mobile ? "95%" : "90%",
          marginTop: "24px",
        }}
      >
        <Grid
          container
          columnSpacing={isOrganizer ? 3 : 6}
          width={"100%"}
          mb={1}
        >
          {!isOrganizer && <Grid item xs={12} md={3.5}></Grid>}
          <Grid item xs={12} md={isOrganizer ? 7 : 5}>
            <BreadCrumb selected={2} isClient={!isOrganizer} />
          </Grid>
        </Grid>

        <Grid container columnSpacing={isOrganizer ? 3 : 6} width={"100%"}>
          {!isOrganizer && (
            <Grid
              item
              xs={12}
              md={isOrganizer ? 3 : 3.5}
              style={{
                backgroundColor: mobile ? "" : "#F7F7F9",
                borderRadius: 6,
              }}
            >
              {meetingId ? (
                <Box>
                  <Box
                    mt={mobile ? 2 : 3}
                    style={{
                      display: "flex",
                      gap: 16,
                      alignItems: "center",
                    }}
                  >
                    <Avatar
                      sx={{
                        height: 80,
                        width: 80,
                      }}
                      alt="profile picture"
                      variant="round"
                      src={
                        publicData?.userWebCalendarPreferences?.picture
                          ? publicData?.userWebCalendarPreferences?.picture
                          : Person
                      }
                    />
                    <Typography
                      align="left"
                      variant={isOrganizer ? "h4" : "h3"}
                      className={classes.titleText}
                    >
                      {publicData?.userWebCalendarPreferences?.title}
                    </Typography>
                  </Box>

                  <MeetingDetailsComponent
                    theme={theme}
                    meetingData={meetingData}
                    fullWidth={true}
                  />
                </Box>
              ) : (
                <BookingDetails
                  heading={t("book_appointment")}
                  firstName={
                    publicData?.userWebCalendarPreferences?.skeding_user_id
                      ?.first_name
                  }
                  lastName={
                    publicData?.userWebCalendarPreferences?.skeding_user_id
                      ?.last_name
                  }
                  phone={selectedLocation?.phone_number}
                  email={selectedLocation?.email}
                  title={publicData?.userWebCalendarPreferences?.title}
                  duration={selectedService?.duration_minutes}
                  location={selectedLocation?.locationType}
                  agenda={publicData?.userWebCalendarPreferences?.description}
                  profile={publicData?.userWebCalendarPreferences?.picture}
                  roomNo={selectedService?.meeting_room}
                  showLng={true}
                  isAddToWebsite={true}
                  isOrganizer={isOrganizer}
                  category={publicData?.userWebCalendarPreferences?.category}
                />
              )}
            </Grid>
          )}

          <Grid item xs={12} md={isOrganizer ? 7 : 5}>
            {meetingId && (
              <Box
                style={{
                  backgroundColor: "#96DCFF66",
                  padding: "14px 20px",
                  borderRadius: 4,
                  margin: "8px 0",
                }}
              >
                <Typography variant="h3">
                  Here you can reschedule your booking start time and date.
                </Typography>
              </Box>
            )}
            <MobileHeadingToggler
              mobile={mobile}
              mobCalView={mobCalView}
              setMobCalView={setMobCalView}
              t={t}
              isOrganizer={isOrganizer}
            />
            {mobile && <Divider />}
            {loading ? (
              <Box>
                <Spinner />
              </Box>
            ) : mobile && !mobCalView ? (
              <MobileSlotsToggler
                mobile={mobile}
                date={date}
                theme={theme}
                daySlots={daySlots}
                selectedSlot={selectedSlot}
                setSelectedSlot={setSelectedSlot}
                t={t}
                isOrganizer={isOrganizer}
                timezone={selectedTimezone}
              />
            ) : (
              <CalendarComponent
                mobile={mobile}
                date={date}
                theme={theme}
                publicData={publicData}
                calendarRef={calendarRef}
                setNavLink={setNavLink}
                eventDays={eventDays}
                navLink={navLink}
                handleCalendarSelect={handleCalendarSelect}
                t={t}
                isOrganizer={isOrganizer}
              />
            )}
          </Grid>
          <Grid
            item
            xs={12}
            md={isOrganizer ? 5 : 3.5}
            className={classes.slotsContainer}
          >
            <Box style={{ textAlign: "left" }}>
              <Typography
                variant={isOrganizer ? "h5" : "h4"}
                style={{ marginBottom: "4px" }}
              >
                {t("timezone")}
              </Typography>
              <Timezones
                selectedTimezone={selectedTimezone}
                setSelectedTimezone={setSelectedTimezone}
                isOrganizer={isOrganizer}
              />
            </Box>

            <Box flexGrow={1}>
              <MobileSlotsToggler
                mobile={mobile}
                date={date}
                theme={theme}
                daySlots={daySlots}
                selectedSlot={selectedSlot}
                setSelectedSlot={setSelectedSlot}
                t={t}
                timezone={selectedTimezone}
                isOrganizer={isOrganizer}
              />
            </Box>
            <Box display="flex" justifyContent="center" marginTop={3}>
              <Box>
                <Link
                  to={isOrganizer ?? "/login"}
                  style={{ textDecoration: "none" }}
                >
                  <Button variant="outlined" sx={{ mr: "10px" }}>
                    {t("cancel")}
                  </Button>
                </Link>
              </Box>
              <Box>
                <Button
                  sx={{
                    backgroundColor: colors.primary,

                    ml: "10px",
                  }}
                  variant="contained"
                  onClick={handlePublicCreate}
                  disabled={selectedSlot ? false : true}
                >
                  {t("done")}
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <SkedingBanner />
        <DialogBox
          modal={responseModal}
          msg={t("appointment_success")}
          close={false}
          nonsignup={true}
        />
      </div>
    </Style>
  );
  // ) : (
  //   <Box>
  //     <Spinner />
  //   </Box>
  // );
}
