import makeStyles from "@mui/styles/makeStyles";
const useStyle = makeStyles((theme) => ({
  signup: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#479AE9",
    fontSize: "28px",
  },
}));

export default useStyle;
