import { Box } from "@mui/material";
import React from "react";
import useWebsiteStore from "../../../Utils/websiteStore";
import useStyle from "../styles";
import BookingDetails from "../../PublicCalendar/BookingDetails";
import { useContext } from "react";
import { accountContext } from "../../../context/user";

export default function AddDetailsPreview() {
  const details = useWebsiteStore((state) => state.details);
  const classes = useStyle();
  const { user } = useContext(accountContext);

  return (
    <div>
      <Box className={classes.bookingPreview} height={"375px"}>
        <BookingDetails
          agenda={details.description}
          email={details.email}
          firstName={user?.name?.split(" ")[0]}
          lastName={user?.name?.split(" ")[1]}
          category={details?.category}
          title={details.title}
          profile={details.picture}
          phone={details?.phone_number}
          location={details.location}
          duration={undefined}
          fileName={undefined}
          showLng={true}
          heading={undefined}
          roomNo={undefined}
          eventId={undefined}
          participants={undefined}
          polling={undefined}
          isAddToWebsite={true}
          isOrganizer={true}
        />
      </Box>
    </div>
  );
}
