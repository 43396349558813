import { makeStyles } from "@mui/styles";

const useStyle = makeStyles((theme) => ({
  root: {
    "& .MuiInputBase-input": {
      padding: "6px 8px",
      borderRadius: "6px",
      color: theme.palette.text.primary,
      fontWeight: 400,
      width: "100%",
      borderBottom: "none",
      outline: "none",
      backgroundColor: theme.palette.secondary.light,
      "&:focus": {
        // borderBottom: "1px solid #B3B5B6",
        borderRadius: "6px",
        boxShadow: "inset 0px -502px 0px -500.5px #b3b5b6",
        backgroundColor: theme.palette.secondary.light,
      },
      "&:hover": {
        // borderBottom: "1px solid #B3B5B6",
        borderRadius: "6px",
        boxShadow: "inset 0px -502px 0px -500.5px #b3b5b6",
        backgroundColor: theme.palette.secondary.light,
      },
    },
    "& .MuiAutocomplete-root": {
      "&:hover": {
        // borderBottom: "1px solid #B3B5B6",
        boxShadow: "inset 0px -502px 0px -500.5px #b3b5b6",
        borderRadius: "6px",
        backgroundColor: theme.palette.secondary.light,
      },
      "&:focus": {
        // borderBottom: "1px solid #B3B5B6",
        boxShadow: "inset 0px -502px 0px -500.5px #b3b5b6",
        borderRadius: "6px",
        backgroundColor: theme.palette.secondary.light,
      },
      "& .MuiInput-input": {
        padding: "6px 8px",
      },
      "& .MuiInput-root": {
        // padding: 6,
        backgroundColor: theme.palette.secondary.light,

        "&:hover": {
          // borderBottom: "1px solid #B3B5B6",
          // backgroundColor: "transparent",
          // backgroundColor: theme.palette.secondary.lighter,
        },
        "&:focus": {
          // borderBottom: "1px solid #B3B5B6",
          // backgroundColor: "transparent",
          // backgroundColor: theme.palette.secondary.lighter,
        },
      },
    },
  },
  participantParent: {
    "& .MuiInputBase-root": {
      padding: "0px",
      borderRadius: "6px",
    },
    "& .MuiInputBase-input": {
      borderBottom: "none",
      outline: "none",
      "&:focus": {
        boxShadow: "none",
        backgroundColor: "transparent",
      },
      "&:hover": {
        boxShadow: "none",
        backgroundColor: "transparent",
      },
    },
  },
  input: {
    "&::placeholder": {
      color: "#363B53",
      fontWeight: 400,
    },
  },
  desc: {
    "& .MuiInputBase-input": {
      color: theme.palette.text.primary,
      fontWeight: 400,
      width: "100%",
      borderBottom: "1px solid #B3B5B6",
      margingBottom: "2px",
    },
  },
  participant: {
    "& .MuiInputBase-input": {
      color: theme.palette.text.primary,
      width: "100%",
      borderBottom: "none",
      outline: "none",
      "&:focus": {
        borderBottom: "none",
      },
      "&:hover": {
        borderBottom: "none",
      },
    },
  },
  dateTime: {
    "& .MuiInputBase-input": {
      padding: 4,
      cursor: "none",
      caretColor: "transparent",
      backgroundColor: "transparent",
      "&:focus": {
        borderBottom: "none",
        outline: "none",
        cursor: "none",
        boxShadow: "none",
      },
      "&:hover": {
        borderBottom: "none",
        outline: "none",
        cursor: "pointer",
        boxShadow: "none",
      },
    },
  },
  yearSelected: {
    backgroundColor: "#ff0000", // set the background color to red
  },
  menuItem: {
    fontSize: "14px",
    fontWeight: "400",
  },
}));

export default useStyle;
