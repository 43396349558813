import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useStyle from "./styles";
import Textfield from "./Textfield";
import useWebsiteStore from "../../Utils/websiteStore";
import {
  APPOINTMENT_CONFIRMATION,
  REMINDER_NOTIFICATION,
} from "../../Utils/Constants";
import SnackbarComponent from "../Global/SnackbarComponent";

export function NotificationEmailTemplate({ handleSave }) {
  const { t } = useTranslation();
  const classes = useStyle();
  const [success, setSuccess] = useState("");
  const [disabled, setDisabled] = useState(false);
  const emailTemplateType = useWebsiteStore((state) => state.emailTemplateType);
  const setEmailTemplateType = useWebsiteStore(
    (state) => state.setEmailTemplateType
  );
  const selectedEmailTemplate = useWebsiteStore(
    (state) => state.selectedEmailTemplate
  );
  const setSelectedEmailTemplate = useWebsiteStore(
    (state) => state.setSelectedEmailTemplate
  );

  const handleChange = (e) => {
    setSelectedEmailTemplate({
      ...selectedEmailTemplate,
      [e.target.name]: e.target.value,
    });
  };
  const emailTriggers =
    emailTemplateType === APPOINTMENT_CONFIRMATION
      ? [
          { name: "Immediately", value: 0 },
          { name: "After 1 hour", value: 0.1 },
        ]
      : emailTemplateType === REMINDER_NOTIFICATION
      ? [
          { name: "1 hour before", value: 1 },
          { name: "1 day before", value: 12 },
        ]
      : [
          { name: "Immediately", value: 0 },

          { name: "1 hour after", value: 1 },
          { name: "1 day after", value: 12 },
        ];

  return (
    <Box>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 16,
          marginTop: 8,
        }}
      >
        <Box>
          <Typography variant="h4" align="left" mb={2}>
            {emailTemplateType}
          </Typography>
          <Typography variant="h4" align="left">
            {t("email_reminder")}
          </Typography>
          <FormControl
            variant="standard"
            sx={{
              width: "100%",
              marginTop: "10px",
              textAlign: "left",
              borderRadius: "6px",
              backgroundColor: "#F7F7F9",
              marginRight: 2,
            }}
          >
            <Select
              label={"Notifications"}
              defaultValue={emailTriggers[0].name}
              variant="standard"
              value={emailTriggers
                .find((e) => e.value == selectedEmailTemplate.trigger_time)
                ?.value?.toString()}
              onChange={(e) => {
                setSelectedEmailTemplate({
                  ...selectedEmailTemplate,
                  trigger_time: e.target.value,
                });
              }}
              disableUnderline={true}
              style={{
                textAlign: "left",
                fontSize: 14,
                paddingLeft: 6,
                border: "1px solid #DAD2E5",
                borderRadius: 8,
                padding: "2px 0 2px 8px",
              }}
            >
              {emailTriggers?.map((option, i) => {
                return (
                  <MenuItem key={i} value={option.value?.toString()}>
                    {option.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>

        <Textfield
          label={t("email_name")}
          value={selectedEmailTemplate.title}
          handleChange={handleChange}
          placeholder={t("email_name_placeholder")}
          isBorder={true}
          name={"title"}
        />

        <Textfield
          label={t("email_subject")}
          value={selectedEmailTemplate.subject}
          handleChange={handleChange}
          placeholder={t("email_subject_placeholder")}
          isBorder={true}
          name={"subject"}
        />

        <Textfield
          label={t("email_content")}
          value={selectedEmailTemplate.body}
          handleChange={handleChange}
          placeholder={t("email_content_placeholder")}
          isBorder={true}
          name={"body"}
          multiline={true}
        />
        <Typography
          variant="caption"
          style={{ color: "#70757A", textAlign: "left" }}
        >
          {`To add dynamic meeting data copy
             {MEETING_TITLE} to add title and {MEETING_DATE_TIME} to add meeting date and time`}
        </Typography>
      </Box>
      <Box>
        <Button
          variant="outlined"
          onClick={() => {
            setSelectedEmailTemplate({});
            setEmailTemplateType("");
          }}
          style={{ marginTop: 8, marginRight: 8 }}
        >
          {t("cancel")}
        </Button>

        <Button
          style={{ marginTop: 8 }}
          variant="contained"
          onClick={() => {
            setDisabled(true);
            handleSave(setDisabled);

            setSuccess("Your information has been saved");
          }}
          disabled={disabled}
        >
          {disabled ? (
            <CircularProgress style={{ color: "white" }} size={20} />
          ) : (
            t("save_changes")
          )}
        </Button>
      </Box>

      <SnackbarComponent
        open={!!success}
        handleClose={() => setSuccess("")}
        message={success}
        vertical={"bottom"}
        horizontal={"center"}
      />
    </Box>
  );
}
