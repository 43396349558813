import { Box, Divider, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useWebsiteStore from "../../Utils/websiteStore";
import { NotificationContainer } from "./NotificationContainer";
import { NotificationEmailTemplate } from "./NotificationEmailTemplate";
import { serverPOST } from "../../Utils/HttpFunctions";

export default function WebsiteNotifications() {
  const calendarData = useWebsiteStore((state) => state.calendarData);

  // const [openEmailTemplate, setOpenEmailTemplate] = useState(null);
  const { t } = useTranslation();

  const emailTemplateType = useWebsiteStore((state) => state.emailTemplateType);
  const setEmailTemplateType = useWebsiteStore(
    (state) => state.setEmailTemplateType
  );
  const selectedEmailTemplate = useWebsiteStore(
    (state) => state.selectedEmailTemplate
  );
  const setSelectedEmailTemplate = useWebsiteStore(
    (state) => state.setSelectedEmailTemplate
  );
  const websiteNotifications = useWebsiteStore(
    (state) => state.websiteNotifications
  );
  const setWebsiteNotifications = useWebsiteStore(
    (state) => state.setWebsiteNotifications
  );
  const setCalendarData = useWebsiteStore((state) => state.setCalendarData);

  useEffect(() => {
    if (calendarData.email_notifications)
      setWebsiteNotifications(calendarData.email_notifications);
  }, [calendarData]);

  const handleSave = async (setDisabled) => {
    const temp = [...websiteNotifications];

    let emailNotification = temp.find(
      (t) => t.trigger_title === emailTemplateType
    );

    emailNotification.types[0] = { ...selectedEmailTemplate };

    for (let i = 0; i < temp.length; ++i) {
      delete temp[i]["_id"];
      for (let j = 0; j < temp[i].types?.length; ++j) {
        delete temp[i].types[j]["_id"];
      }
    }

    const response = await serverPOST(
      `/web-integration?offset=${new Date().getTimezoneOffset()}`,
      { email_notifications: temp }
    );

    if (response && response.constructor !== String) {
      setCalendarData(response.data.calendarData);

      setSelectedEmailTemplate({});
      setEmailTemplateType("");
      setDisabled(false);
    }
  };

  const handleDelete = async (title) => {
    const temp = [...websiteNotifications];

    let emailNotification = temp.find((t) => t.trigger_title === title);
    emailNotification.types.splice(0, 1);

    for (let i = 0; i < temp.length; ++i) {
      delete temp[i]["_id"];
      for (let j = 0; j < temp[i].types?.length; ++j) {
        delete temp[i].types[j]["_id"];
      }
    }
    const response = await serverPOST(
      `/web-integration?offset=${new Date().getTimezoneOffset()}`,
      { email_notifications: temp }
    );

    if (response && response.constructor !== String) {
      setCalendarData(response.data.calendarData);
    }
  };
  return (
    <>
      {emailTemplateType ? (
        <NotificationEmailTemplate handleSave={handleSave} />
      ) : (
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 16,
          }}
        >
          {websiteNotifications?.map((e) => {
            return (
              <NotificationContainer
                trigger={e}
                handleEdit={() => {
                  setEmailTemplateType(e.trigger_title);
                }}
                // setOpenEmailTemplate={setOpenEmailTemplate}
                handleDelete={handleDelete}
              />
            );
          })}
        </Box>
      )}
    </>
  );
}
