import { useEffect, useState } from "react";
import { serverGET } from "../HttpFunctions";

export default function useContactGroups(participants) {
  const [contacts, setContacts] = useState([]);
  const [minimizedContacts, setMinimizedContacts] = useState([]);
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    const abortController = new AbortController();
    getContacts(abortController);
    getGroups(abortController);
    return () => {
      abortController.abort();
    };
  }, []);

  const getContacts = async (abortController) => {
    const validator = require("validator");
    try {
      const response = await serverGET("/contact", abortController.signal);
      if (response && response.constructor !== String) {
        const filtered = await response.data.filter((contact) =>
          validator.isEmail(contact.contact_email)
        );
        const parsedContacts = filtered.map((contact) => {
          return {
            email: contact.contact_email,
            _id: contact.skeding_user_id,
            name: contact.contact_name,
            isSkeding: contact.is_contact_skeding_user,
            is_team_member: contact.is_contact_team_member,
          };
        });

        setContacts(parsedContacts);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getGroups = async (abortController) => {
    try {
      const response = await serverGET(
        "/contact/group",
        abortController.signal
      );
      if (response && response.constructor !== String) {
        const parsedContacts = response.data.map((group) => {
          return {
            group_name: group.group_name,
            contacts: group.contacts.map((contact) => {
              return {
                email: contact.contact_email,
                _id: contact._id,
                name: contact.contact_name,
              };
            }),
          };
        });
        setGroups(parsedContacts);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (participants?.length > 0) {
      const temp = [...participants];
      const remaining = contacts.filter(
        (c) => !temp.some((e) => c.email === e)
      );
      setMinimizedContacts(remaining);
    } else {
      setMinimizedContacts(contacts);
    }
  }, [participants, contacts]);

  return [contacts, groups, minimizedContacts];
}
