import {
  Badge,
  Box,
  Button,
  Chip,
  CircularProgress,
  IconButton,
  Paper,
  Rating,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { ReactComponent as Logo } from "assets/feedback_logo.svg";
import { ReactComponent as F1 } from "assets/f1.svg";
import { ReactComponent as F2 } from "assets/f2.svg";
import { ReactComponent as F3 } from "assets/f3.svg";
import { ReactComponent as Star } from "assets/star.svg";
import { ReactComponent as EmptyStar } from "assets/empty.svg";
import CloseIcon from "@mui/icons-material/Close";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { serverPOST, serverPUT } from "Utils/HttpFunctions";
import SnackbarComponent from "components/Global/SnackbarComponent";
import ErrorSnackbar from "components/ErrorSnackbar";
import { integrationContext } from "context/Integrations";
import { useTranslation } from "react-i18next";
import { convertBase64 } from "../../Utils/CommonFunctions";

export default function Choice({ choice, setChoice }) {
  const [rate, setRate] = useState(5);
  const [selectedChoice, setSelectedChoice] = useState(0);
  const [image, setImage] = useState([]);
  const [imageName, setImageName] = useState([]);
  const theme = useTheme();
  const [invisible, setInvisible] = useState(true);
  const [message, setMessgae] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const handleInput = (e) => {
    if (image.length > 2) {
      setError("You cannot send more than 3 images");
      return;
    }
    setImageName([...imageName, e.target?.files[0]?.name]);
    setImage([...image, e.target.files[0]]);
  };

  const handleSelect = (choice) => {
    setSelectedChoice(choice);
  };

  const handleImage = async (image) => {
    const base64 = await image.map((i) => convertBase64(i));
    return base64;
  };

  const handleSubmit = async () => {
    setLoading(true);
    let data = {
      message,
      rating: rate,
      type: selectedChoice - 1, //0 for rating, 1 for request feature, 2 for report a bug
    };
    if (
      [1, 2].includes(data.type) &&
      message.trim() === "" &&
      imageName.length > 0
    ) {
      setError("Fill out form please");
      return;
    }
    if (image.length > 0) {
      const img = await handleImage(image);
      await Promise.all(img).then(function (results) {
        data = {
          ...data,
          image: results,
        };
      });
    }

    const response = await serverPOST(`/feedback/`, data);
    if (response && response.constructor !== String) {
      setSuccess(true);
    } else {
      setError("Something went wrong!");
      console.log("error =>", response);
    }
    setLoading(false);
    setTimeout(() => {
      clearForm();
    }, 500);
  };

  const clearForm = () => {
    setSelectedChoice(0);
    setMessgae("");
    setImage([]);
    setImageName([]);
    setInvisible(false);
  };
  return choice ? (
    <ChoiceContainer setChoice={setChoice}>
      <SnackbarComponent
        open={success}
        handleClose={() => setSuccess(false)}
        message={t("feedback_submitted")}
        vertical={"bottom"}
        horizontal={"center"}
      />
      <ErrorSnackbar
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        vertical={"bottom"}
        horizontal={"center"}
      />
      {selectedChoice === 0 ? (
        <Box pt={3} pb={4}>
          <Typography variant="h3">
            {t("hi")}!
            <br />
            <span
              style={{
                fontSize: "12px",
                fontWeight: "400",
                color: "#70757A",
                marginTop: "8px",
              }}
            >
              {t("rate_experience_title")}
            </span>
          </Typography>

          <BoxWrapper
            title={t("rate_experience")}
            desc={t("rate_experience_desc")}
            color={"#F5D04F40"}
            img={<F3 />}
            choice={1}
            handleSelect={handleSelect}
          />
          <BoxWrapper
            title={t("request_feature")}
            desc={t("request_feature_desc")}
            color={"#97EEDE40"}
            img={<F1 />}
            choice={2}
            handleSelect={handleSelect}
          />
          <BoxWrapper
            title={t("report_issue")}
            desc={t("report_issue_desc")}
            color={"#B488F640"}
            img={<F2 />}
            choice={3}
            handleSelect={handleSelect}
          />
        </Box>
      ) : selectedChoice === 1 ? (
        <Box pt={3} pb={4}>
          <Typography variant="h2" align="center">
            {t("rate_experience")}
          </Typography>
          {/* <Typography variant="h6" align="center" style={{ color: "#70757A" }}>
            Let us know how we can improve Skeding
          </Typography> */}
          <Box display={"flex"} justifyContent="center" mt={2}>
            <Rating
              name="rate"
              value={rate}
              icon={<Star />}
              emptyIcon={<EmptyStar />}
              onChange={(event, newValue) => {
                setRate(newValue);
              }}
            />
          </Box>
          <Box
            style={{
              height: "168px",
              background: "#FBFBFF",
              border: "1px solid #F7F7F9",
              borderRadius: "8px",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
            mt={2}
          >
            <TextInputComponent
              message={message}
              setMessgae={setMessgae}
              imageName={imageName}
              setImageName={setImageName}
              invisible={invisible}
              setInvisible={setInvisible}
              handleInput={handleInput}
              setImage={setImage}
              image={image}
              theme={theme}
              placeholder={t("rate_experience_text")}
            />
          </Box>

          <SubmitButtons
            clearForm={clearForm}
            handleSubmit={handleSubmit}
            loading={loading}
          />
        </Box>
      ) : selectedChoice === 2 ? (
        <Box pt={3} pb={4}>
          <Typography variant="h2" align="center">
            {t("request_feature")}
          </Typography>
          {/* <Typography variant="h6" align="center" style={{ color: "#70757A" }}>
            Tell us about new feature
          </Typography> */}

          <Box
            style={{
              height: "168px",
              background: "#FBFBFF",
              border: "1px solid #F7F7F9",
              borderRadius: "8px",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
            mt={2}
          >
            <TextInputComponent
              message={message}
              setImage={setImage}
              image={image}
              setMessgae={setMessgae}
              imageName={imageName}
              setImageName={setImageName}
              invisible={invisible}
              setInvisible={setInvisible}
              handleInput={handleInput}
              theme={theme}
              placeholder={t("request_feature_text")}
            />
          </Box>

          <SubmitButtons
            clearForm={clearForm}
            handleSubmit={handleSubmit}
            loading={loading}
            messageEmpty={!!!message}
          />
        </Box>
      ) : selectedChoice === 3 ? (
        <Box pt={3} pb={4}>
          <Typography variant="h2" align="center">
            {t("report_issue")}
          </Typography>
          {/* <Typography variant="h6" align="center" style={{ color: "#70757A" }}>
            Facing any problem? Let us know!
          </Typography> */}

          <Box
            style={{
              height: "168px",
              background: "#FBFBFF",
              border: "1px solid #F7F7F9",
              borderRadius: "8px",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
            mt={2}
          >
            <TextInputComponent
              message={message}
              setImage={setImage}
              image={image}
              setMessgae={setMessgae}
              imageName={imageName}
              setImageName={setImageName}
              invisible={invisible}
              setInvisible={setInvisible}
              handleInput={handleInput}
              theme={theme}
              placeholder={t("report_issue_text")}
            />
          </Box>

          <SubmitButtons
            clearForm={clearForm}
            handleSubmit={handleSubmit}
            loading={loading}
            messageEmpty={!!!message}
          />
        </Box>
      ) : null}
    </ChoiceContainer>
  ) : null;
}

export function ChoiceContainer({ children, setChoice }) {
  return (
    <div>
      <Box
        sx={{
          textAlign: "start",
          padding: "0px",
          width: "320px",
          position: "absolute",
          zIndex: 999,
          top: "25%",
          right: "40px",
          backgroundColor: "#fff",
          boxShadow: "rgb(0 0 0 / 35%) 0 6px 100px 0",
          borderRadius: "16px",
        }}
      >
        <Box style={{ width: "90%", margin: "auto" }}>
          <Box
            display={"flex"}
            justifyContent="space-between"
            pt={2}
            alignItems="center"
          >
            <Box display={"flex"} alignItems="center">
              <Logo />
              <Typography variant="h2" style={{ marginLeft: "8px" }}>
                Skeding
              </Typography>
            </Box>
            <IconButton
              style={{ width: 40, height: 40 }}
              onClick={() => setChoice((prev) => !prev)}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          {children}
        </Box>
      </Box>
    </div>
  );
}

function BoxWrapper({ img, title, desc, color, choice, handleSelect }) {
  return (
    <Box
      display={"flex"}
      style={{
        backgroundColor: color,
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        cursor: "pointer",
        padding: "16px 20px",
        borderRadius: 4,
      }}
      mt={2}
      onClick={() => handleSelect(choice)}
    >
      <Box
        style={{
          background: "#fff",
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "38px",
          height: "38px",
          boxSizing: "border-box",
        }}
      >
        {img}
      </Box>
      <Box ml={3}>
        <Typography variant="h3">{title}</Typography>
        {/* <Typography variant="h6" style={{ color: "#70757A" }}>
          {desc}
        </Typography> */}
      </Box>
    </Box>
  );
}

function SubmitButtons({ clearForm, handleSubmit, loading, messageEmpty }) {
  const { t } = useTranslation();

  return (
    <Stack
      display={"flex"}
      justifyContent="center"
      mt={2}
      spacing="12px"
      direction={"row"}
    >
      <Button
        variant="outlined"
        component="label"
        style={{ padding: "4px 20px" }}
        onClick={clearForm}
      >
        {t("go_back")}
      </Button>
      <Button
        variant="contained"
        component="label"
        style={{ padding: "4px 20px" }}
        onClick={handleSubmit}
        disabled={loading || messageEmpty}
      >
        {" "}
        {loading ? (
          <CircularProgress style={{ color: "white" }} size={20} />
        ) : (
          t("submit")
        )}
      </Button>
    </Stack>
  );
}

function TextInputComponent({
  message,
  setMessgae,
  imageName,
  setImageName,
  invisible,
  setInvisible,
  setImage,
  handleInput,
  theme,
  placeholder,
  image,
}) {
  const onInputClick = (event) => {
    event.target.value = "";
  };

  const removeFile = (index) => {
    if (imageName.length === 1) {
      setImage([]);
      setImageName([]);
    } else {
      const imageNames = [...imageName];
      imageNames.splice(index, 1);
      setImageName(imageNames);
      const images = [...image];
      images.splice(index, 1);
      setImage(images);
    }
  };
  return (
    <>
      <TextField
        id="rating"
        variant="standard"
        multiline
        style={{ width: "100%" }}
        placeholder={placeholder}
        rows={6}
        value={message}
        onChange={(e) => setMessgae(e.target.value)}
        InputProps={{
          disableUnderline: true,
          style: {
            fontSize: "12px",
            fontWeight: 400,
            padding: "10px",
          },
        }}
      />
      <Box display={"flex"} justifyContent="flex-end">
        <Box
          variant="text"
          style={{
            color: "rgb(74 43 43 / 82%)",
          }}
          display="flex"
          flexWrap={"wrap"}
        >
          {imageName &&
            imageName.map((i, index) => (
              <Badge
                badgeContent={
                  <IconButton onClick={() => removeFile(index)}>
                    <CloseIcon
                      sx={{
                        color: "#96DCFF",
                        width: "12px",
                        height: "12px",
                      }}
                    />
                  </IconButton>
                }
                invisible={invisible}
                onMouseOver={() => setInvisible(false)}
                onMouseOut={() => setInvisible(true)}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  "& .MuiBadge-badge": {
                    maxWidth: "0",
                    maxHeight: "35%",
                  },
                }}
              >
                <Chip
                  variant="filled"
                  label={i}
                  key={index}
                  sx={{
                    background: theme.palette.secondary.light,
                    overflow: "hidden",
                    textOverflow: "ellipses",
                    width: "100px",
                  }}
                />
              </Badge>
            ))}
        </Box>
        <IconButton component="label">
          <AttachFileIcon style={{ transform: "rotate(45deg)" }} />
          <input
            type="file"
            accept="image/*"
            hidden
            name="image"
            multiple
            onClick={onInputClick}
            onChange={handleInput}
          />
        </IconButton>
      </Box>
    </>
  );
}
