import React, { useState } from "react";
import { YEARLY, MONTHLY, PREMIUM_PLAN } from "../../Utils/Constants";
import PaymentPlan from "./PaymentPlan";
import Toggler from "./Toggler";

export default function RenewSubscription() {
  const [billingType, setBillingType] = useState(YEARLY);

  return (
    <div style={{ width: "100%" }}>
      <Toggler billingType={billingType} setBillingType={setBillingType} />
      <PaymentPlan planType={PREMIUM_PLAN} billingType={billingType} />
    </div>
  );
}
