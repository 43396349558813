import React, { useEffect, useState, useContext } from "react";

import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  useTheme,
  useMediaQuery,
  Typography,
  Dialog,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";

import "react-calendar/dist/Calendar.css";
import "styles/smallCalendar.css";
import { colors } from "Utils/colors";
import { useLocation } from "react-router-dom";
import { serverGET, serverPOST } from "Utils/HttpFunctions";
import { SEARCH_EVENT, WEBSITE_BOOKINGS_COLOR } from "Utils/Constants";
import { convertUTCMillisecondsToDate } from "Utils/CommonFunctions";
import ErrorSnackbar from "components/ErrorSnackbar";
import SearchAutocomplete from "components/SearchAutocomplete";
import SearchByDate from "components/SearchEvents/SearchByDate";
import EventInfo from "components/SearchEvents/EventInfo";
import EventBox from "components/SearchEvents/EventBox";
import { useTranslation } from "react-i18next";
import useEventsStore from "../../Utils/eventStore";
import useStyle from "./styles";

export default function SearchEvents() {
  const { state } = useLocation();
  const { search, events } = state;
  const { plannedEvents, setPlannedEvents } = useEventsStore();

  const [searchDate, setSearchDate] = useState(null);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [searchedEvents, setSearchedEvents] = useState([]);
  const [index, setIndex] = useState(0);
  const [location, setLocation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [clickedEvent, setClickedEvent] = useState(null);
  const [userMeetings, setUserMeetings] = useState([]);
  const [modal, setModal] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [error, setError] = useState("");
  const { t } = useTranslation();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const tab = useMediaQuery(theme.breakpoints.down("lg"));
  const classes = useStyle();

  useEffect(() => {
    populateEvents();
  }, [search, events]);

  useEffect(() => {
    searchedEvents.length > 0 && setClickedEvent(searchedEvents[0]);
  }, [searchedEvents]);

  const populateEvents = async () => {
    const e = await events.map((ev) => ({
      ...ev,
      selected: false,
    }));
    setSearchedEvents(
      events.filter(
        (x) => x.dates?.endDatetime != null || x.dates?.start_date != null
      )
    );
    // setSearchedEvents(events);
  };

  const fetchData = async () => {
    setIndex((prev) => prev + 10);
  };

  const searchEvents = (searchText) => {
    const data = [];
    searchedEvents.forEach((event) => {
      const regex = new RegExp(
        searchText.replace(/[\\[\]()+?.*]/g, (c) => "\\" + c),
        "i"
      );
      const result = event?.location[0].search(regex);
      if (result > -1) {
        data.push(event);
      }
    });
    return data;
  };

  const dateSearch = (searchDate) => {
    const data = [];
    searchedEvents.forEach((event) => {
      const eventDate = event?.dates?.startDatetime
        ? new Date(event?.dates?.startDatetime)
        : new Date(event?.dates?.start_date + ", 2022");
      eventDate.setHours(0, 0, 0, 0);
      const search = new Date(searchDate);
      search.setHours(0, 0, 0, 0);
      if (eventDate.getTime() == search.getTime()) {
        data.push(event);
      }
    });
    return data;
  };

  const appendData = async () => {
    let response;
    if (!location) {
      response = await serverGET(`/search?q=${search}&start=${index}`);
    } else {
      response = await serverGET(
        `/search?q=${search}&start=${index}&gl=${location.code}&location=${location.label}`
      );
    }
    if (response && response.constructor !== String) {
      const temp = [...searchedEvents];
      const loadedData = await response?.data?.results;
      const modified = loadedData.map((ev) => ({
        ...ev,
        selected: false,
      }));
      const appended = temp.concat(modified);
      setSearchedEvents(appended);
    }
  };

  const handleLocationSearch = async () => {
    let url;
    setFetchLoading(true);
    if (!location) {
      url = `/search?q=${search}&start=${index}`;
    } else {
      url = `/search?q=${search}&start=${index}&gl=${location.code}&location=${location.label}`;
    }
    if (searchDate) {
      const date = moment(searchDate).format("YYYY-MM-DD");
      url = url + `&date=date:${date}`;
    }

    const response = await serverGET(url);
    if (response && response.constructor !== String) {
      if (response?.data?.results.length > 0) {
        const modified = response?.data?.results.map((ev) => ({
          ...ev,
          selected: false,
        }));
        setSearchedEvents(modified);
      } else {
        setSearchedEvents([]);
      }
    }
    setFetchLoading(false);
  };

  useEffect(() => {
    if (index !== 0) {
      appendData();
    }
  }, [index]);

  const addToCalendar = async (event) => {
    let data;
    if (event?.dates?.startDatetime) {
      data = {
        title: event?.title,
        duration_minutes: moment(event?.dates?.endDatetime).diff(
          moment(event?.dates?.startDatetime),
          "minutes"
        ),
        start_datetime: event?.dates?.startDatetime,
        participants: [],
        location: event?.link,
        time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        utc_offset: new Date().getTimezoneOffset(),
        case: SEARCH_EVENT,
        meeting_type: { bgColor: "#F8E08C" },
      };
    } else {
      data = {
        title: event?.title,
        duration_minutes: 60,
        start_datetime: new Date(
          event?.dates?.start_date + `, ${new Date().getFullYear()}, 19:00:00`
        ).getTime(),
        participants: [],
        location: event?.link,
        time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        utc_offset: new Date().getTimezoneOffset(),
        case: SEARCH_EVENT,
        meeting_type: { bgColor: "#F8E08C" },
      };
    }

    if (data.duration_minutes > 720 || data.duration_minutes <= 0) {
      data.duration_minutes = 60;
      // setError("Duration of event cannot be greater than 12 hours");
      // setLoading(false);
      // return;
    }

    const response = await serverPOST(`/meeting`, data);
    if (response && response.constructor !== String) {
      const temp = [...searchedEvents];
      const value = temp.find((ev) => ev === event);
      value.selected = true;
      setSearchedEvents(temp);
      setLoading(false);
      let responseData = response.data;
      let newEvent = {
        id: responseData.id,
        title: responseData.title,
        start: convertUTCMillisecondsToDate(responseData.start_datetime),
        end: convertUTCMillisecondsToDate(responseData.end_datetime),
        description: responseData.description,
        case: responseData.case,
        location: responseData.location,
        skedingParticipants: responseData.skeding_participants,
        nonskedingParticipants: responseData.non_skeding_participants,
        totalAccepted: responseData.total_accepted,
        fileName: responseData.attachment_file_name,
        durationMinutes: responseData.duration_minutes,
        nonskedingParticipantsNotResponded:
          responseData?.non_skeding_participants_not_responded,
        editable: false,
        backgroundColor: WEBSITE_BOOKINGS_COLOR,
      };
      let newPlannedEventsList = [...plannedEvents, newEvent];
      setPlannedEvents(newPlannedEventsList);
    } else {
      console.log("error");
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserMeetings();
  }, []);

  const getUserMeetings = async () => {
    const response = await serverGET("/meeting?futureMeetings=true");
    if (response && response.constructor !== String) {
      var temp = response.data;
      setUserMeetings([...temp]);
    } else {
      return response?.data?.message;
    }
  };

  const openWebsite = (eventUrl) => {
    window.open(eventUrl, "_blank");
  };

  const handleSearchDate = async (e) => {
    setClickedEvent(null);
    setOpenCalendar((prev) => !prev);
    setSearchDate(e);
    let url;
    let date = moment(e);
    if (date.isValid()) {
      date = date.format("YYYY-MM-DD");
      url = `/search?q=${search}&start=${index}&date=date:${date}`;
      if (location) {
        url = url + `&gl=${location.code}&location=${location.label}`;
      }
    } else {
      url = `/search?q=${search}&start=${index}`;

      if (location) {
        url = url + `&gl=${location.code}&location=${location.label}`;
      }
    }
    setFetchLoading(true);
    const response = await serverGET(url);
    if (response && response.constructor !== String) {
      if (response?.data?.results.length > 0) {
        const modified = response?.data?.results.map((ev) => ({
          ...ev,
          selected: false,
        }));
        setSearchedEvents(modified);
      } else {
        setSearchedEvents([]);
      }
    }
    setFetchLoading(false);
  };
  return (
    <>
      <Backdrop
        sx={{
          color: colors.primary,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
        onClick={() => setLoading((prev) => !prev)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Backdrop
        sx={{
          color: colors.primary,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={fetchLoading}
        onClick={() => setFetchLoading((prev) => !prev)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <ErrorSnackbar
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        vertical={"bottom"}
        horizontal={"center"}
      />
      <Grid container>
        <Grid container item xs={12} lg={8}>
          <Box className={classes.container}>
            <Box
              display="flex"
              justifyContent={"space-between"}
              flexWrap="wrap"
              alignItems="center"
              className={classes.stickyContainer}
            >
              <Typography variant="h2" fontWeight="600" align="left">
                {t("search_events")}
              </Typography>
              <Box
                display="flex"
                justifyContent="flex-end"
                sx={{ marginTop: { xs: 2, sm: 0 }, columnGap: "5px" }}
              >
                <SearchAutocomplete
                  handleLocationSearch={handleLocationSearch}
                  location={location}
                  setLocation={setLocation}
                  mobile={mobile}
                />
                <SearchByDate
                  mobile={mobile}
                  searchDate={searchDate}
                  openCalendar={openCalendar}
                  setOpenCalendar={setOpenCalendar}
                  setSearchDate={setSearchDate}
                  handleSearchDate={handleSearchDate}
                />
              </Box>
            </Box>
            <InfiniteScroll
              dataLength={
                searchedEvents && searchedEvents.length > 0
                  ? searchedEvents.length
                  : 10
              } //This is important field to render the next data
              next={fetchData}
              hasMore={true}
              style={{
                overflow: "initial",
              }}
            >
              <EventBox
                key={index}
                events={searchDate ? dateSearch(searchDate) : searchedEvents}
                addToCalendar={addToCalendar}
                loading={loading}
                setModal={setModal}
                setLoading={setLoading}
                clickedEvent={clickedEvent}
                setClickedEvent={setClickedEvent}
                searchedEvents={searchedEvents}
                fetchData={fetchData}
              />
            </InfiniteScroll>
          </Box>
        </Grid>

        {tab ? (
          modal ? (
            <Dialog
              open={modal}
              onClose={() => setModal(false)}
              maxWidth={"xs"}
              PaperProps={{
                sx: { padding: 1, borderRadius: "8px", minWidth: "240px" },
              }}
            >
              <Box display="flex" justifyContent="flex-end">
                <IconButton onClick={() => setModal(false)}>
                  <CloseIcon />
                </IconButton>
              </Box>
              {clickedEvent && (
                <EventInfo
                  clickedEvent={clickedEvent}
                  theme={theme}
                  setLoading={setLoading}
                  addToCalendar={addToCalendar}
                  openWebsite={openWebsite}
                  userMeetings={userMeetings}
                  tab={tab}
                />
              )}
            </Dialog>
          ) : null
        ) : (
          <Grid item xs={0} lg={4}>
            {clickedEvent && (
              <EventInfo
                clickedEvent={clickedEvent}
                theme={theme}
                setLoading={setLoading}
                addToCalendar={addToCalendar}
                openWebsite={openWebsite}
                userMeetings={userMeetings}
                tab={tab}
              />
            )}
          </Grid>
        )}
      </Grid>
    </>
  );
}
