import React, { Component } from "react";
import moment from "moment";
import { saveAs } from "file-saver";
import {
  AlignmentType,
  Document,
  HeadingLevel,
  Packer,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  TextRun,
  WidthType,
} from "docx";

export default function CreateDoc({ handleAttachment }) {
  return (
    <div>
      <p>
        <button
        //  onClick={generateDoc}
        >
          Generate Agenda
        </button>
      </p>
    </div>
  );
}

export class DocumentCreator {
  // tslint:disable-next-line: typedef
  public create([meetingTime, agendas]): Document {
    const document = new Document({
      sections: [
        {
          children: [
            new Paragraph({
              text: "Meeting Agenda",
              heading: HeadingLevel.TITLE,
              alignment: AlignmentType.CENTER,
            }),
            this.createMeetingTime(meetingTime?.start, meetingTime?.end),
            this.createSubHeading("Agendas"),
            // ...agendas
            //   .map((agenda, index) => {
            //     const arr: Paragraph[] = [];
            //     arr.push(this.createRoleText((index + 1).toString()));

            //     arr.push(this.createBullet(agenda.topic));
            //     arr.push(this.createBullet(agenda.keypoints));
            //     arr.push(this.createBullet(agenda.participants.join(",")));
            //     arr.push(this.createBullet(agenda.time.toString()));
            //     return arr;
            //   })
            //   .reduce((prev, curr) => prev.concat(curr), []),
            this.createTable(agendas),
          ],
        },
      ],
    });

    return document;
  }

  public createTable(agendas) {
    const rows = ["Topic", "Keynotes", "Assignees", "Time"];
    return new Table({
      rows: [
        // header
        new TableRow({
          children: rows.map(
            (r) =>
              new TableCell({
                width: {
                  size: 3505,
                  type: WidthType.DXA,
                },
                children: [
                  new Paragraph({
                    children: [new TextRun({ text: r, bold: true })],
                  }),
                ],
              })
          ),
        }),

        // body
        ...agendas.map(
          (a) =>
            new TableRow({
              children: [
                new TableCell({
                  width: {
                    size: 3505,
                    type: WidthType.DXA,
                  },
                  children: [new Paragraph(a.topic)],
                }),
                new TableCell({
                  width: {
                    size: 5505,
                    type: WidthType.DXA,
                  },
                  children: [new Paragraph(a.keypoints)],
                }),
                new TableCell({
                  width: {
                    size: 5505,
                    type: WidthType.DXA,
                  },
                  children: [new Paragraph(a.participants.join(","))],
                }),
                new TableCell({
                  width: {
                    size: 5505,
                    type: WidthType.DXA,
                  },
                  children: [new Paragraph(a.time)],
                }),
              ],
            })
        ),
      ],
    });
  }

  public createMeetingTime(start: number, end: number): Paragraph {
    return new Paragraph({
      alignment: AlignmentType.CENTER,
      children: [
        new TextRun(
          start && end
            ? `Date: ${moment(start).format(
                "dddd, D MMMM YYYY"
              )} | Time: ${moment(start).format("HH:mm A")} - ${moment(
                end
              ).format("HH:mm A")}`
            : "Date time not yet decided"
        ),
      ],
    });
  }

  public createHeading(text: string): Paragraph {
    return new Paragraph({
      text: text,
      heading: HeadingLevel.HEADING_1,
      thematicBreak: true,
    });
  }

  public createSubHeading(text: string): Paragraph {
    return new Paragraph({
      text: text,
      heading: HeadingLevel.HEADING_2,
    });
  }
  public createBullet(text: string): Paragraph {
    return new Paragraph({
      text: text,
      bullet: {
        level: 0,
      },
    });
  }

  public createSkillList(skills: any[]): Paragraph {
    return new Paragraph({
      children: [
        new TextRun(skills.map((skill) => skill.name).join(", ") + "."),
      ],
    });
  }

  public createRoleText(roleText: string): Paragraph {
    return new Paragraph({
      children: [
        new TextRun({
          text: roleText,
          italics: true,
        }),
      ],
    });
  }
}

type Agenda = {
  topic: string;
  keypoints: string;
  participants: string[];
  time: string;
};

type MeetingData = {
  start_datetime: number;
  duration_minutes: number;
};

export const generateDoc = async (
  agendas: Agenda,
  meetingData: MeetingData
): Promise<Blob> => {
  const documentCreator = new DocumentCreator();
  let obj: any = {};

  if (meetingData?.start_datetime) {
    obj.start = meetingData.start_datetime;
    obj.end =
      meetingData.start_datetime + meetingData.duration_minutes * 60 * 1000;
  }
  const doc = documentCreator.create([obj, agendas]);

  const blob = await Packer.toBlob(doc);
  return blob;
  // to test by saving locally
  //  .then((blob) => {
  //     console.log(blob);
  //     return blob;
  //     // handleAttachment(blob);
  //     // return;

  //     saveAs(blob, "example.docx");
  //     console.log("Document created successfully");
  //   });
};
