import React, { useState, useEffect, useContext } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { accountContext } from "../../context/user";
import DialogWithCloseIcon from "../DialogWithCloseIcon";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import useDelegatedAcess from "../../Utils/SwtichAccount";
import { serverPUT } from "../../Utils/HttpFunctions";

const SchedularNotificationPopup = () => {
  const [open, setOpen] = useState(false);
  const [doNotAskAgain, setDoNotAskAgain] = useState(false);
  const { user } = useContext(accountContext);
  const members = useDelegatedAcess((state) => state.members);
  const setMembers = useDelegatedAcess((state) => state.setMembers);

  const setSchedulerNotification = useDelegatedAcess(
    (state) => state.setSchedulerNotification
  );

  const { t } = useTranslation();

  useEffect(() => {
    const storedPreference = localStorage.getItem("notificationPreference");
    if (storedPreference === "true") {
      setDoNotAskAgain(true);
    } else {
      setOpen(true); // Open the popup if preference not set
    }
  }, []);

  useEffect(() => {
    if (members.length > 0) {
      const currentUser = members.find((m) => m.email === user.email);
      if (currentUser) {
        setSchedulerNotification(currentUser?.scheduler?.is_email_enabled);
      }
    }
  }, [members, user]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleProceed = async (status) => {
    const membershipId = user?.package?.membership._id;
    const response = await serverPUT(
      `/account/toggleNotification/${membershipId}`,
      {
        is_email_enabled: status,
      }
    );
    if (response && response.constructor !== String) {
      let mems = [...members];
      const currentUser = members.find((m) => m.email === user.email);
      if (currentUser) {
        currentUser.scheduler.is_email_enabled = status;
      }
      setMembers(mems);
      setSchedulerNotification(status);
    } else {
      console.log("error");
    }
    setOpen(false);
  };

  const handleCheckboxChange = (event) => {
    setDoNotAskAgain(event.target.checked);
    if (event.target.checked) {
      localStorage.setItem("notificationPreference", "true");
    } else {
      localStorage.removeItem("notificationPreference");
    }
  };

  if (!open) {
    return null; // Don't render the popup if it's not open
  }

  return (
    <DialogWithCloseIcon
      open={open}
      handleClose={handleClose}
      dialogSize={"xs"}
    >
      <Typography align="left" variant="h3">
        {t("turnOffEmailAlerts", { userName: user?.name })}
      </Typography>
      <FormControlLabel
        control={
          <Checkbox
            checked={doNotAskAgain}
            onChange={handleCheckboxChange}
            color="primary"
            size="small"
          />
        }
        label={t("ask_again")}
      />
      <Box mb={"10px"}>
        <Button variant="outlined" onClick={() => handleProceed(true)}>
          {t("no")}
        </Button>
        <Button
          variant="contained"
          style={{
            marginLeft: 20,
          }}
          onClick={() => handleProceed(false)}
        >
          {t("yes")}
        </Button>
      </Box>
    </DialogWithCloseIcon>
  );
};

export default SchedularNotificationPopup;
