import React, { useState } from "react";
import { Typography, Button, Grid, Box, IconButton } from "@mui/material";
import useMeetingStore from "../../Utils/createMeetingStore";
import AgendaForm from "./AgendaForm";
import { AiOutlinePlusCircle } from "react-icons/ai";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useDebouncedCallback } from "use-debounce";
import { useTranslation } from "react-i18next";

export function MeetingAgenda({ participants, maxInterval }) {
  const agendas = useMeetingStore((state) => state.agendas);
  const setAgendas = useMeetingStore((state) => state.setAgendas);
  const { t } = useTranslation();

  const debounced = useDebouncedCallback((e, i) => {
    let agendaTemp = [...agendas];
    let agenda = agendaTemp[i];
    agenda[e.target.name] = e.target.value;
    setAgendas(agendaTemp);
  }, 1000);

  const handleDelete = (i) => {
    let agendaTemp = [...agendas];
    agendaTemp.splice(i, 1);
    setAgendas(agendaTemp);
  };
  return (
    <Grid container direction={"column"} my={2} textAlign={"left"}>
      <Typography variant="h3">
        {agendas?.length > 0 ? t("meeting_agenda") : ""}
      </Typography>

      {agendas?.map((a, i) => {
        return (
          <Box
            style={{
              border: "1px solid #E0E2E7",
              marginTop: "8px",
              padding: "0 8px",
              borderRadius: "8px",
            }}
            display="flex"
            alignItems={"center"}
            gap={1}
            key={i}
          >
            <AgendaForm
              participants={participants}
              agenda={a}
              handleAddAgenda={(e, i) => debounced(e, i)}
              index={i}
              maxInterval={maxInterval}
            />
            <IconButton aria-label="delete" onClick={() => handleDelete(i)}>
              <DeleteOutlineOutlinedIcon
                style={{
                  color: "#363B53",
                  cursor: "pointer",
                  width: 16,
                  height: 16,
                }}
              />
            </IconButton>
          </Box>
        );
      })}

      <Box mt={2}>
        <Button
          variant="contained"
          style={{
            background: "none",
            color: "#479AE9",
            paddingLeft: 6,
            paddingRight: 6,
          }}
          startIcon={<AiOutlinePlusCircle />}
          onClick={() => {
            setAgendas([
              ...agendas,
              { topic: "", keypoints: "", participants: [], time: "" },
            ]);
          }}
        >
          {t("add_agenda")}
        </Button>
      </Box>
    </Grid>
  );
}
