import makeStyles from "@mui/styles/makeStyles";
const useStyle = makeStyles((theme) => ({
  drawerLogo: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "45px",
    marginTop: 30,
    marginBottom: 32,
  },
  logoIcon: {
    color: "#FFFFFF",
    cursor: "pointer",
  },
  drawerContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "77%",
    [theme.breakpoints.up("md")]: {
      height: "85%",
    },
  },
  drawerContentInside: {
    overflowY: "scroll",
    scrollBehavior: "smooth",
    scrollbarWidth: "none",
  },
  meetingsIcon: {
    width: "16px",
    height: "16px",
    borderRadius: "50%",
    backgroundColor: "#FFFFFF",
    color: "#479AE9",
    margin: "0px 8px 0px 12px",
  },
  searchIcon: {
    fontSize: "17px",
    color: theme.palette.text.primary,
    marginRight: "8px",
  },

  searchInput: {
    height: "30px",
    borderRadius: "8px",
    padding: "0px",
  },
  bellIcon: {
    fontSize: "30px",
    color: theme.palette.text.primary,
  },

  notificationTitleWrapper: {
    paddingBottom: "4px",
    margin: "0px 26px ",
    cursor: "default",
  },

  markRead: {
    marginLeft: "12px",
    marginRight: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    height: 30,
  },

  notificationContent: {
    flexGrow: 1,
    margin: "2px 16px 2px 0px",
    justifyContent: "center",
    textAlign: "start",
    minHeight: 60,
    gap: 2,
    display: "flex",
    flexDirection: "column",
  },

  lightColor: {
    color: theme.palette.info.light,
  },

  link: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    textDecoration: "none",
  },
  userImg: {
    width: 80,
    height: 80,
    marginTop: 5,
    borderRadius: "100%",
  },
  userEmail: {
    marginTop: 8,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "140px",
  },
  packageTitle: {
    background: "rgba(150, 220, 255, 0.25)",
    borderRadius: "6px",
    padding: "6px 28px",
  },

  navContainer: {
    backgroundColor: theme.palette.background.default,
    color: "#252733",
    boxShadow: 0,
    width: "100%",
    marginTop: "8px",
    [theme.breakpoints.up("md")]: {
      marginTop: "20px",
      marginBottom: "25px",
    },
  },
  subscribedBtn: {
    borderRadius: "7px",
    marginRight: "8px",
    padding: "3px 20px 3px 16px",
    borderColor: "#479AE9",
    color: "#479AE9",
    minWidth: "200px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  creatBtn: {
    borderRadius: "7px",
    marginRight: "8px",
    padding: "3px 20px 3px 16px",
  },
}));

export default useStyle;
