import React from "react";

import { FormControl, MenuItem, Select, Box } from "@mui/material";

import "styles/newEvent.css";
import { GoogleMeetIcon } from "components/MeetingIcons";
import { ZoomMeetingIcon } from "components/MeetingIcons";
import TeamsIcon from "assets/teams-dropdown.png";
import useStyle from "../../styles/formStyles";
import { useTranslation } from "react-i18next";

function Location({
  location,
  setLocation,
  user,
  isInstant = false,
  notNewMeeting,
  showBorder,
  isService,
}) {
  const { t } = useTranslation();
  const classes = useStyle();
  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };

  return (
    <>
      <FormControl
        variant="standard"
        sx={{
          width: notNewMeeting ? "100%" : null,
          marginTop: "10px",
          textAlign: "left",
          border: showBorder ? "1px solid #DAD2E5" : "",
          borderRadius: "6px",
          backgroundColor: isService ? "#F7F7F9" : "",
          // paddingLeft: 1,
        }}
      >
        <Select
          labelId="location-label"
          id="event-location"
          value={location}
          onChange={handleLocationChange}
          placeholder={t("location") + "*"}
          disableUnderline={true}
          style={{
            textAlign: "left",
            fontSize: 14,
          }}
          displayEmpty={true}
        >
          <MenuItem value={""} className={classes.menuItem}>
            {t("location")}{" "}
          </MenuItem>

          <MenuItem
            value={"Google Meet"}
            spacing="5px"
            disabled={user ? !user.is_google_synced : false}
            className={classes.menuItem}
          >
            <Box display="flex" columnGap="5px" alignItems="center">
              {location !== "Google Meet" ? <GoogleMeetIcon /> : ""}
              Google Meet
            </Box>
          </MenuItem>
          <MenuItem
            value={"Zoom"}
            disabled={user ? !user.is_zoom_synced : false}
            className={classes.menuItem}
          >
            <Box display="flex" columnGap="5px" alignItems="center">
              {location !== "Zoom" ? <ZoomMeetingIcon /> : ""} Zoom
            </Box>
          </MenuItem>
          <MenuItem
            value={"Teams"}
            disabled={user ? !user.is_teams_synced : false}
            className={classes.menuItem}
          >
            <Box display="flex" columnGap="5px" alignItems="center">
              {location !== "Teams" ? (
                <img src={TeamsIcon} alt="teams" style={{ width: 25 }} />
              ) : (
                ""
              )}{" "}
              Teams
            </Box>
          </MenuItem>
          {!isInstant && (
            <MenuItem value={"Other"} className={classes.menuItem}>
              Physical location
            </MenuItem>
          )}
          {!isInstant && (
            <MenuItem value={"Phone"} className={classes.menuItem}>
              Phone number
            </MenuItem>
          )}
          {
            <MenuItem value={"Link"} className={classes.menuItem}>
              Virtual link
            </MenuItem>
          }
        </Select>
      </FormControl>
    </>
  );
}

export default React.memo(Location);
