import makeStyles from "@mui/styles/makeStyles";

const useStyle = makeStyles((theme) => ({
  paymentPlan: {
    backgroundColor: "#96DCFF40",
    padding: 16,
    borderRadius: 2,
  },
}));

export default useStyle;
