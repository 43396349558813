import React, { useEffect, useState } from "react";

import {
  Dialog,
  DialogTitle,
  Typography,
  Button,
  DialogActions,
  Box,
  Container,
  IconButton,
  Popover,
  Snackbar,
  useTheme,
  Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import LockIcon from "@mui/icons-material/Lock";
import { serverDELETE, serverGET, serverPUT } from "Utils/HttpFunctions";
import LinkExpirationDialog from "./LinkExpirationDialog";
import EditTemplate from "./EditTemplate";
import QRCode from "pages/QRCode";
import CopyToClipboardDialog from "./CopyToClipboardDialog";
import DesktopFlow from "../NewEvent/DesktopFlow";
import { useTranslation } from "react-i18next";
import SnackbarComponent from "../Global/SnackbarComponent";

const useStyles = makeStyles((theme) => ({
  popOverElement: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(114, 176, 238, 0.5)",
    },
  },
}));

export default function TemplateComponent({
  title,
  agenda,
  location,
  id: templateId,
  setRefresh,
  duration,
  setModal,
  setTemplates,
  templates,
  organizer_preferences,
  participants,
  isLocked,
}) {
  const navigate = useNavigate();
  const theme = useTheme();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [linkExpirationDialog, setLinkExpirationDialog] = useState(false);
  const [identifier, setIdentifier] = useState(0);
  const [qrCode, setQrCode] = useState("");
  const [snackBar, setSnackBar] = useState(false);
  const [copyDialog, setCopyDialog] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [qrModal, setQrModal] = useState(false);
  const [copiedLink, setCopiedLink] = useState("");
  const [deleteMessage, setDeleteMessage] = useState(false);
  const [isDeleteMessage, setIsDeleteMessage] = useState(false);
  const [scheduled, setSchedule] = useState(false);
  const { t } = useTranslation();

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  /**
   * Opens the qrCode dialogue once the data has been retrieved from the backend
   */
  useEffect(() => {
    if (qrCode) {
      setQrModal(true);
      handleClose();
    }
  }, [qrCode]);

  /**
   * Closes the dropdown for the more button (...)
   */
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!deleteModal && isDeleteMessage) {
      setDeleteMessage(true);
    }
  }, [deleteModal, isDeleteMessage]);

  const handleDeleteClose = () => {
    setDeleteModal(false);
    setIsDeleteMessage(true);
  };

  const handleDeleteGoBack = () => {
    setDeleteModal(false);
  };

  /**
   * Makes request to backend to duplicate a template
   * Refreshes the templates when the response is successful
   * @async
   */
  const duplicateTemplate = async () => {
    const response = await serverGET(
      `/meeting-template/duplicate/${templateId}`
    );
    if (response && response.constructor !== String) {
      setRefresh((prev) => !prev);
      handleClose();
    } else {
      console.log("duplicate template failed");
    }
  };

  /**
   * Opens the dialogue box to confirm deletion of a template
   */
  const deleteTemplate = () => {
    setDeleteModal(true);
    handleClose();
  };

  /**
   * Makes request to the meeting-template delete API to delete the meeting template
   * @async
   */
  const handleDelete = async () => {
    const temp = [...templates];
    const response = await serverDELETE(`/meeting-template/${templateId}`);
    if (response && response.constructor !== String) {
      //delete current
      // setDeleteMessage("Template deleted successfully");
      const template = temp.find((t) => t._id === templateId);
      const index = temp.indexOf(template);
      temp.splice(index, 1);

      //unlock next
      if (response.data.nextTemplate) {
        const template1 = temp.find(
          (t) => t._id === response.data.nextTemplate._id
        );
        if (template1) {
          template1.is_locked = false;
        }
      }
      setTemplates(temp);
    } else {
      console.log("remove template failed");
    }
  };

  /**
   * Opens the dialogue box to set the link expiration time
   * Sets the link id for the template link being copied
   * @param {string} id - the id of the meeting template whose link is being copied
   */
  const handleCopyLink = (id) => {
    setLinkExpirationDialog(true);
    setIdentifier(id);
  };

  /**
   * Opens a dialogue box to allow the user to copy a meeting template link to their clipboard
   * @param {string} link - the link being copied to the clipboard
   */
  const openCopyToClipboard = (link) => {
    setCopiedLink(link);
    setCopyDialog(true);
  };

  /**
   * Closes the dialogue box to copy to the clipboard and resets the copiedLink var
   */
  const handleCloseCopyToClipboard = () => {
    setCopyDialog(false);
    setCopiedLink("");
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBar(false);
  };

  /**
   * Makes a request to the backend to generate a shareable link for a meeting template
   * @param {number} time
   * @param {string} id
   */
  const copyLinkRequest = async (time, id) => {
    setDisabled(true);

    let response;
    if (typeof time === "number") {
      response = await serverGET(
        `/meeting-template/link/${templateId}?expiry=${time}`
      );
      setDisabled(false);
    } else {
      response = await serverGET(`/meeting-template/link/${templateId}`);
      setDisabled(false);
    }
    if (response && response.constructor !== String) {
      if (response.code === 200) {
        if (id === 1) {
          setQrCode(response.data);
        } else {
          openCopyToClipboard(response.data);
        }
      } else {
        console.log("link template failed");
      }
    }
  };

  /**
   * Takes a user to the new meeting page when they press the Schedule button
   */
  const navigateToNewMeeting = () => {
    navigate("/createmeeting", {
      state: {
        title: title,
        previousURL: "/shareable-meeting",
        agenda: agenda,
        location: location,
        blocked_timeslots_utc: organizer_preferences,
        participants: participants,
        duration:
          duration === 60
            ? "0" +
              Math.floor(duration / 60) +
              ":0" +
              ((duration % 60) + ":" + "00").toString()
            : "0" +
              Math.floor(duration / 60) +
              ":" +
              ((duration % 60) + ":" + "00").toString(),
      },
    });
  };
  const handleUnlock = async () => {
    try {
      const response = await serverPUT(
        `/meeting-template/unlock/${templateId}`
      );
      if (response && response.constructor !== String) {
        console.log(response);
        const temp = [...templates];
        //update exisitng
        const template1 = temp.find((t) => !t.is_locked);
        if (template1) {
          template1.is_locked = true;
        }

        //update current
        const template = temp.find((t) => t._id === templateId);
        template.is_locked = false;

        setTemplates(temp);
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <LockedShareableTemplate isLocked={isLocked} handleUnlock={handleUnlock}>
      <Container
        maxWidth="xs"
        style={{
          backgroundColor: theme.palette.info.contrastText,
          borderRadius: 8,
          height: "140px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          opacity: isLocked ? 0.5 : 1,
        }}
      >
        <SnackbarComponent
          open={snackBar}
          handleClose={handleCloseSnackbar}
          message={t("link_copied")}
          vertical={"bottom"}
          horizontal={"center"}
        />

        {copyDialog && (
          <CopyToClipboardDialog
            open={copyDialog}
            handleCloseCopyToClipboard={handleCloseCopyToClipboard}
            copiedLink={copiedLink}
            setSnackBar={setSnackBar}
          />
        )}

        {deleteModal && (
          <Dialog
            open={deleteModal}
            onClose={handleDeleteClose}
            maxWidth={"xs"}
            fullWidth
            PaperProps={{ sx: { padding: 3, boxSizing: "border-box" } }}
          >
            <DialogTitle
              id="alert-dialog-title"
              style={{ textAlign: "center" }}
            >
              <Typography variant="h3">{t("del_shareable")}</Typography>
            </DialogTitle>
            <DialogActions
              sx={{
                display: "flex",
                justifyContent: "center",
                columnGap: "10px",
              }}
            >
              <Button
                onClick={handleDeleteGoBack}
                variant="outlined"
                // style={{ padding: "4px 20px", fontSize: 10 }}
              >
                {t("go_back")}
              </Button>
              <Button
                onClick={() => {
                  handleDelete();
                  handleDeleteClose();
                }}
                variant="small-submit"
              >
                {t("del")}
              </Button>
            </DialogActions>
          </Dialog>
        )}

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          PaperProps={{
            elevation: 1,
            sx: {
              width: 200,
              borderRadius: "8px",
              boxShadow: "2px 3px 3px 2px #00000026",
            },
          }}
        >
          <Box
            className={classes.popOverElement}
            onClick={() => {
              setEditModal(true);
              handleClose();
            }}
          >
            <Typography variant="h4" sx={{ p: "12px", fontWeight: 400 }}>
              {t("edit")}
            </Typography>
          </Box>
          <Box className={classes.popOverElement} onClick={duplicateTemplate}>
            <Typography variant="h4" sx={{ p: "12px", fontWeight: 400 }}>
              {t("duplicate")}
            </Typography>
          </Box>
          <Box
            className={classes.popOverElement}
            onClick={() => handleCopyLink(1)}
          >
            <Typography variant="h4" sx={{ p: "12px", fontWeight: 400 }}>
              {t("generate_qrCode")}
            </Typography>
          </Box>
          <Box className={classes.popOverElement} onClick={deleteTemplate}>
            <Typography variant="h4" sx={{ p: "12px", fontWeight: 400 }}>
              {t("del")}
            </Typography>
          </Box>
        </Popover>
        <Box
          display={"flex"}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            variant="h3"
            fontWeight="500"
            style={{ textTransform: "capitalize" }}
            align="left"
          >
            {title}
          </Typography>
          <IconButton
            aria-label="more"
            aria-describedby={id}
            onClick={(event) => setAnchorEl(event.currentTarget)}
            style={{ marginRight: "-10px" }}
          >
            <MoreVertOutlinedIcon
              style={{ color: theme.palette.text.primary }}
            />
          </IconButton>
        </Box>
        <Box display="flex" alignItems="center">
          <AccessTimeOutlinedIcon
            style={{
              width: 14,
              color: theme.palette.text.primary,
              paddingBottom: "1px",
            }}
          />
          <Typography
            variant="h3"
            align="left"
            style={{ marginLeft: 5, fontWeight: "400" }}
          >
            {duration} {t("mins")}
          </Typography>
        </Box>
        <Box
          mt={"16px"}
          mb={1}
          display="flex"
          alignItems="center"
          sx={{ justifyContent: "center" }}
        >
          <Button
            variant="outlined"
            sx={{
              m: "0 20px 0 0",
              maxWidth: "120px",
            }}
            onClick={() => handleCopyLink(0)}
          >
            {t("copy_title")}
          </Button>

          <Button
            variant="small-submit"
            onClick={navigateToNewMeeting}
            sx={{
              height: "28px",
            }}
          >
            {t("schedule")}
          </Button>
        </Box>

        <LinkExpirationDialog
          linkExpirationDialog={linkExpirationDialog}
          setLinkExpirationDialog={setLinkExpirationDialog}
          copyLinkRequest={copyLinkRequest}
          identifier={identifier}
          disabled={disabled}
        />
        <EditTemplate
          templateId={templateId}
          disabled={disabled}
          open={editModal}
          handleClose={() => setEditModal((prev) => !prev)}
          setModal={setModal}
          setRefresh={setRefresh}
        />
        <QRCode
          code={qrCode}
          title={title}
          duration={duration}
          open={qrModal}
          handleClose={() => setQrModal((prev) => !prev)}
        />
        {scheduled && <DesktopFlow isSchedule={true} />}
      </Container>
      <SnackbarComponent
        open={deleteMessage}
        handleClose={() => {
          setDeleteMessage(false);
          setIsDeleteMessage(false);
        }}
        message={"Template has been deleted successfully"}
        vertical={"bottom"}
        horizontal={"center"}
      />
    </LockedShareableTemplate>
  );
}

const LockedShareableTemplate = ({ isLocked, children, handleUnlock }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box position="relative">
      {isLocked && (
        <Box
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          zIndex={1}
          color={"#70757A"}
        >
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            PaperProps={{
              elevation: 1,
              sx: {
                width: 200,
                borderRadius: "8px",
                boxShadow: "2px 3px 3px 2px #00000026",
              },
            }}
          >
            <Box
              className={classes.popOverElement}
              onClick={() => {
                handleUnlock();
                handleClose();
              }}
            >
              <Typography variant="h4" sx={{ p: "12px", fontWeight: 400 }}>
                {t("unlock")}
              </Typography>
            </Box>
            <Box
              className={classes.popOverElement}
              onClick={() => {
                handleClose();
              }}
            >
              <Typography
                variant="h4"
                sx={{ p: "12px", fontWeight: 400 }}
                onClick={() => navigate("/payment-services")}
              >
                {t("get_premium")}
              </Typography>
            </Box>
          </Popover>
          <LockIcon
            fontSize="large"
            style={{
              cursor: "pointer",
            }}
            onClick={(event) => setAnchorEl(event.currentTarget)}
          />
        </Box>
      )}
      {children}
    </Box>
  );
};
