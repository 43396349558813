import React from "react";
import { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import {
  Button,
  Card,
  Stack,
  Typography,
  Grid,
  useTheme,
  useMediaQuery,
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import { serverGET } from "Utils/HttpFunctions";
import { useContext } from "react";
import { authContext } from "context/Auth";
import {
  convertDateToUTCMilliseconds,
  convertUTCMillisecondsToDate,
  dayHeader,
  removeOffsetFromDate,
} from "Utils/CommonFunctions";
import ScrollButton from "components/ScrollButton";
import moment from "moment";
import ErrorSnackbar from "components/ErrorSnackbar";
import {
  ALL_SKEDING_USERS,
  NEW_MEETINGS_ALLOW_OVERLAP,
  ONE_NONSKEDING_MULTIPLE_SKEDING_USERS,
  ONE_NONSKEDING_ONE_SKEDING_USER,
} from "Utils/Constants";
import { useCallback } from "react";
import HelpComponent from "components/Global/Help";
import { applyOffsetToPreferenceTimeslot } from "../../Utils/CommonFunctions";
import { createMeetingCalendarStyles } from "../../styles/createMeetingCalendar";
import Style from "style-it";
import { serverGETWithToken } from "../../Utils/HttpFunctions";
import { useTranslation } from "react-i18next";
import useSuggestionsStore from "../../Utils/suggestionsStore";

export default function TimeCalendarTemp(props) {
  const {
    calendarRef,
    sharedAvailableTimes,
    setSharedAvailableTimes,
    events,
    available: availableTimes,
    eventsLoaded,
    eventCal,
    setEventCal,
    meetingType,
    setErrorAmount,
    pU,
    // overlapCheck,
    // setOverlapCheck,
    setSelectedDate,
    setDate,
    setMonth,
    setYear,
    setRecommendedDate,
    token,
  } = props;
  const preferencesCheck = useSuggestionsStore(
    (state) => state.preferencesCheck
  );
  const [navLink, setNavLink] = useState(false);
  const [unavailable, setUnavailable] = useState({});
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [userMeetings, setUserMeetings] = useState([]);
  const [available, setAvailable] = useState([]);
  const [maxSelection, setMaxSelection] = useState(1);
  const [selectedBtn, setSelectedBtn] = useState(2);
  const [overlapCheck, setOverlapCheck] = useState(false);
  const { isLogged } = useContext(authContext);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const [pastError, setPastError] = useState("");
  const [participantUnavailibility, setParticipantUnavailibility] = useState(
    {}
  );
  const [participantMeetings, setParticipantMeetings] = useState({});
  const { t } = useTranslation();

  let participantPrefrences = [];
  let participantPrefrencesTemp = [];

  useEffect(() => {
    setOverlapCheck(preferencesCheck);
  }, [preferencesCheck]);

  useEffect(() => {
    const abortController = new AbortController();
    if (isLogged) {
      getUserMeetings(abortController);
    } else if (props?.qrMeeting && token) {
      getOrgUserMeetings();
    }
    return () => {
      abortController.abort();
    };
  }, [calendarRef, isLogged, events]);

  useEffect(() => {
    if (eventsLoaded) {
      if (events?.length > 0) {
        setCalendarView("new-event", events);
      } else if (availableTimes?.length > 0) {
        setCalendarView("meeting-poll", availableTimes);
      }
    }
  }, [eventsLoaded, events]);

  useEffect(() => {
    getParticpantData();
  }, [pU]);

  function getDifference(array1, array2) {
    return array1.filter((object1) => {
      return !array2?.some((object2) => {
        return (
          new Date(object1.start).getTime() ===
          new Date(object2.start).getTime()
        );
      });
    });
  }
  const getParticpantData = async () => {
    //participant preferences
    if (pU?.allUnavailbleSlots?.length > 1) {
      const meetings = await pU.allUnavailbleSlots.map((meeting) => {
        return {
          title: "Participant unavailibility",
          start: new Date(meeting.startTime),
          end: new Date(meeting.endTime),
        };
      });

      const filterMeetings = await getDifference(
        meetings,
        userMeetings?.events
      );

      setParticipantMeetings({
        events: filterMeetings,
        backgroundColor: "rgba(218, 210, 229)",
        display: overlapCheck ? "background" : "block",
        editable: false,
        borderColor: "#0000",
      });
    } else {
      setParticipantMeetings({});
    }
    if (pU?.allPreferences?.length > 0) {
      for (let i = 0; i < pU.allPreferences.length - 1; i++) {
        const p = pU.allPreferences[i]?.blocked_timeslots_utc?.filter(
          (p) =>
            !props?.unavailability?.some(
              (s) =>
                p.start_day === s.start_day &&
                p.end_day === s.end_day &&
                p.start_hours === s.start_hours &&
                p.start_minutes === s.start_minutes
            )
        );
        for (let j = 0; j < p.length; j++) {
          participantPrefrencesTemp.push(p[j]);
        }
      }

      for (let i = 0; i < participantPrefrencesTemp.length; i++) {
        const slot = participantPrefrencesTemp[i];
        let s = await applyOffsetToPreferenceTimeslot(
          slot,
          new Date().getTimezoneOffset()
        );
        participantPrefrences.push(s);
      }

      const prefs = await handleParticipantUnavailability(
        participantPrefrences
      );
    } else {
      setParticipantUnavailibility({});
    }
  };

  useEffect(() => {
    if (eventCal) {
      const mapping = eventCal.map((event) => {
        return {
          value: {
            start:
              typeof event?.start === "number"
                ? event.start
                : convertDateToUTCMilliseconds(event.start),
            end:
              typeof event?.end === "number"
                ? event.end
                : convertDateToUTCMilliseconds(event.end),
          },
        };
      });
      const eventChips = props.selectedSlot.filter(
        (slot) => slot.originalIndex !== undefined
      );
      if (props.eventCase === ALL_SKEDING_USERS || meetingType) {
        props.setSelectedSlot(mapping);
      } else {
        props.setSelectedSlot(eventChips.concat(mapping));
      }
    }
  }, [eventCal, events]);

  useEffect(() => {
    if (eventCal) {
      const temp = [...eventCal];
      const updatedEvents = temp.map((event) => {
        const end = moment(event.start).add(
          moment.duration(props.timeInterval).asMinutes(),
          "minutes"
        );
        return {
          id: event.id,
          title:
            moment(event.start).format("LT") + " - " + moment(end).format("LT"),
          start: event.start,
          end: end.toDate(),
          borderColor: event.borderColor,
          backgroundColor: event.backgroundColor,
        };
      });
      setEventCal(updatedEvents);
    }
  }, [props.timeInterval]);

  useEffect(() => {
    if (props?.participants?.length > 0 && props?.timeInterval !== "") {
      //when non skeding schedule manually then to show availbale slots
      let parseResponse;
      if (props.eventCase === "NonSignUpOneSelect") {
        parseResponse = props?.available?.map((item) => {
          return {
            start: convertUTCMillisecondsToDate(item.startTime),
            end: convertUTCMillisecondsToDate(item.endTime),
          };
        });
      }
      //--end--
      setAvailable({
        events:
          props.eventCase === "NonSignUpOneSelect"
            ? parseResponse
            : props.events,
        backgroundColor: "rgba(150, 220, 255, 0.5)",
        display: "background",
      });
    } else {
      setAvailable([]);
    }
  }, [props.events, props.participants]);

  useEffect(() => {
    if (props.unavailability) {
      handleUnavailability();
    }
  }, [props.unavailability]);

  const regetParticipant = useCallback(() => {
    if (participantUnavailibility) {
      handleParticipantUnavailability(participantPrefrences);
    }
  }, [pU]);

  useEffect(() => {
    renderSelectedTimes();
  }, [props.selectedSlot]);

  useEffect(() => {
    if (
      props.eventCase === "oneNonskedingOneSkedingUser" ||
      props.eventCase === "oneNonskedingMultipleSkedingUser" ||
      props.eventCase === "NonSignUp"
    ) {
      setMaxSelection(5);
    }
  }, [props.eventCase]);

  const getUserMeetings = async (abortController) => {
    const response = await serverGET("/meeting?futureMeetings=true");

    if (response && response.constructor !== String) {
      var temp = await response.data.map((meeting) => {
        return {
          title: meeting.title,
          start: new Date(meeting.start_datetime),
          end: new Date(meeting.end_datetime),
        };
      });
      setUserMeetings({
        events: temp,
        backgroundColor: "rgba(218, 210, 229)",
        display: overlapCheck ? "background" : "block",
        editable: false,
        borderColor: "#0000",
      });
    }
  };

  const getOrgUserMeetings = async (abortController) => {
    const response = await serverGETWithToken(
      "/meeting?futureMeetings=true",
      token
    );

    if (response && response.constructor !== String) {
      var temp = await response.data.map((meeting) => {
        return {
          title: meeting.title,
          start: new Date(meeting.start_datetime),
          end: new Date(meeting.end_datetime),
        };
      });
      setUserMeetings({
        events: temp,
        backgroundColor: "rgba(218, 210, 229)",
        display: overlapCheck ? "background" : "block",
        editable: false,
        borderColor: "#0000",
      });
    }
  };
  useEffect(() => {
    if (overlapCheck) {
      setUserMeetings({
        ...userMeetings,
        display: "background",
      });
      setUnavailable({
        ...unavailable,
        display: "background",
      });
      setParticipantUnavailibility({
        ...participantUnavailibility,
        display: "background",
      });
      setParticipantMeetings({
        ...participantMeetings,
        display: "background",
      });
    } else {
      setUserMeetings({
        ...userMeetings,
        display: "block",
      });
      setUnavailable({
        ...unavailable,
        display: "block",
      });
      setParticipantUnavailibility({
        ...participantUnavailibility,
        display: "block",
      });
      setParticipantMeetings({
        ...participantMeetings,
        display: "block",
      });
    }
  }, [overlapCheck]);

  const sortTimesByStartHour = (time) => {
    var temp = [...props.events];

    switch (time === "min") {
      case true:
        temp.sort((a, b) => a.start.getHours() - b.start.getHours());
        break;
      case false:
        temp.sort((a, b) => b.end.getHours() - a.end.getHours());
        break;
    }

    return temp;
  };

  const getMinMaxTime = (setting) => {
    var temp;
    var sortedAvailability = sortTimesByStartHour(setting);

    switch (sortedAvailability.length === 0) {
      case true:
        switch (setting === "min") {
          case true:
            return "00";
          case false:
            return "24";
        }
      case false:
        if (setting === "min") {
          temp = sortedAvailability[0]?.start?.getHours();
        } else {
          temp = sortedAvailability[0]?.end?.getHours() + 1;
        }

        if (temp < 10) {
          return `0${temp?.toString()}`;
        } else {
          return temp?.toString();
        }
    }
  };

  const sortTimesByDate = (timeDirection, data, displayedIn) => {
    let temp = [];
    if (displayedIn === "meeting-poll") {
      temp = data.map((availableTime) => {
        return {
          start: new Date(availableTime.startTime),
          end: new Date(availableTime.endTime),
          _id: availableTime._id,
        };
      });
    } else {
      temp = [...data];
    }

    switch (timeDirection === "earliest") {
      case true:
        temp.sort((a, b) => a.start.getTime() - b.start.getTime());
        break;
      case false:
        temp.sort((a, b) => b.end.getTime() - a.end.getTime());
        break;
    }

    return temp;
  };

  const setCalendarView = (displayedIn, data) => {
    let sortedTimes = sortTimesByDate("earliest", data, displayedIn);

    let firstAvailableTime = sortedTimes[0];

    checkIfFirstAvailableInView(firstAvailableTime);
  };

  const checkIfFirstAvailableInView = (firstAvailableTime) => {
    var viewStart = calendarRef?.current?.getApi()?.view.activeStart;
    var viewEnd = calendarRef?.current?.getApi()?.view.activeEnd;

    switch (
      firstAvailableTime.start.getTime() >= viewStart.getTime() &&
      firstAvailableTime.start.getTime() <= viewEnd.getTime()
    ) {
      case false:
        calendarRef?.current
          ?.getApi()
          ?.changeView("timeGridWeek", firstAvailableTime.start);
        //to fetch for next week
        handleUnavailability();
        break;
      case true:
        break;
    }
  };

  const handleParticipantUnavailability = async (arr) => {
    //current day is either the actual current day on first load or the first day of the week if the user has clicked next month
    var currentDay = calendarRef?.current.getApi()?.getDate()?.getDay();
    var currentDate = calendarRef?.current?.getApi()?.getDate();

    if (currentDay > 0) {
      currentDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() - currentDay
      );
    }
    let sundayHolder = {};

    var unavailabilityAsDates = arr.map((unavailable, i) => {
      let endDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() + unavailable.end_day,
        unavailable.end_hours,
        unavailable.end_minutes
      );
      let startDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() + unavailable.start_day,
        unavailable.start_hours,
        unavailable.start_minutes
      );
      let startDate1 = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() + unavailable.start_day,
        unavailable.start_hours,
        unavailable.start_minutes
      );

      let satEnd;
      if (endDate.getDay() === 0 && startDate.getDay() === 6) {
        if (endDate.getHours() > 0) {
          sundayHolder = {
            start: startDate,
            end: endDate,
          };
        }
        startDate.setHours(23);
        startDate.setMinutes(59);
        startDate.setSeconds(59);
        satEnd = new Date(startDate);
      }

      let startTime =
        endDate.getDay() === 0 && startDate.getDay() === 6
          ? startDate1
          : new Date(
              currentDate.getFullYear(),
              currentDate.getMonth(),
              currentDate.getDate() + unavailable.start_day,
              unavailable.start_hours,
              unavailable.start_minutes
            );

      let endTime =
        endDate.getDay() === 0 && startDate.getDay() === 6
          ? satEnd
          : new Date(
              currentDate.getFullYear(),
              currentDate.getMonth(),
              currentDate.getDate() + unavailable.end_day,
              unavailable.end_hours,
              unavailable.end_minutes
            );

      // const diffTime = Math.abs(endTime.getTime() - startTime.getTime());
      // const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

      // console.log(
      //   "🚀 ~ file: time.js:489 ~ unavailabilityAsDates ~ diffTime:",
      //   diffTime,
      //   diffDays,
      //   startTime,
      //   endTime
      // );

      // if (diffDays > 0) {
      //   startTime = new Date(
      //     startTime.getFullYear(),
      //     startTime.getMonth(),
      //     startTime.getDate(),
      //     0,
      //     0,
      //     0,
      //     0
      //   ).getTime();

      //   endTime = new Date(
      //     endTime.getFullYear(),
      //     endTime.getMonth(),
      //     endTime.getDate(),
      //     0,
      //     0,
      //     0,
      //     0
      //   ).getTime();
      // } else if (endDate.getDay() === 0 && startDate.getDay() === 6) {
      //   startTime = new Date(
      //     startTime.getFullYear(),
      //     startTime.getMonth(),
      //     startTime.getDate(),
      //     0,
      //     0,
      //     0,
      //     0
      //   ).getTime();

      //   endTime = new Date(
      //     endTime.getFullYear(),
      //     endTime.getMonth(),
      //     endTime.getDate() + 1,
      //     0,
      //     0,
      //     0,
      //     0
      //   ).getTime();
      // } else {

      // startTime = startTime.getTime() - new Date().getTimezoneOffset() * 60000;
      // endTime = endTime.getTime() - new Date().getTimezoneOffset() * 60000;

      // }

      return {
        title: "Participant Unavailibility",
        start: startTime,
        end: endTime,
      };
    });

    if (Object.keys(sundayHolder).length > 0) {
      let start = new Date(sundayHolder.end);
      start.setHours(0);
      start.setMinutes(0);

      let end = new Date(sundayHolder.end);
      unavailabilityAsDates.push({
        title: "Participant Unavailibility",
        start,
        end,
      });
    }

    setParticipantUnavailibility({
      events: unavailabilityAsDates,
      backgroundColor: "rgba(218, 210, 229)",
      editable: false,
      display: overlapCheck ? "background" : "block",
      borderColor: "#0000",
    });
  };

  const handleUnavailability = async () => {
    //current day is either the actual current day on first load or the first day of the week if the user has clicked next month
    var currentDay = calendarRef?.current.getApi()?.getDate()?.getDay();
    var currentDate = calendarRef?.current?.getApi()?.getDate();

    if (currentDay > 0) {
      currentDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() - currentDay
      );
    }
    let arrayDays = [];

    var unavailabilityAsDates = props.unavailability.map((unavailable) => {
      const endDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() + unavailable.end_day,
        unavailable.end_hours,
        unavailable.end_minutes
      );
      const startDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() + unavailable.start_day,
        unavailable.start_hours,
        unavailable.start_minutes
      );
      const startDate1 = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() + unavailable.start_day,
        unavailable.start_hours,
        unavailable.start_minutes
      );
      let satEnd;
      if (endDate.getDay() === 0 && startDate.getDay() === 6) {
        startDate.setHours(23);
        startDate.setMinutes(59);
        startDate.setSeconds(59);
        satEnd = new Date(startDate);
        const temp = {
          start: startDate1,
          end: satEnd,
        };
        // arrayDays.push(temp);
      }
      return {
        title: t("you_are_unavail"),
        start:
          endDate.getDay() === 0 && startDate.getDay() === 6
            ? startDate1
            : new Date(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                currentDate.getDate() + unavailable.start_day,
                unavailable.start_hours,
                unavailable.start_minutes
              ),
        end:
          endDate.getDay() === 0 && startDate.getDay() === 6
            ? satEnd
            : new Date(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                currentDate.getDate() + unavailable.end_day,
                unavailable.end_hours,
                unavailable.end_minutes
              ),
      };
    });

    setUnavailable({
      // events: unavailabilityAsDates.concat(arrayDays),
      events: unavailabilityAsDates,
      backgroundColor: "#DAD2E5",
      editable: false,
      display: overlapCheck ? "background" : "block",
      borderColor: "#0000",
    });
  };

  const renderSelectedTimes = () => {
    var temp =
      props?.selectedSlot?.length > 0 &&
      props.selectedSlot.map((selected) => {
        return selected.value;
      });

    setSelectedSlots({
      events: temp,
      backgroundColor: "#72B0EE",
    });
  };

  const handleSingleSelect = (selection) => {
    var selectionStart = selection.start.getTime();
    var selectionEnd = selection.end.getTime();
    var temp = [];

    var selectionInAvailable = findSelectionInAvailableCopy(
      selectionStart,
      selectionEnd
      // false
    );

    temp.push(...selectionInAvailable);
    if (selectionInAvailable.length > 0 && props.selectedSlot?.length < 1) {
      const tempAll = [...sharedAvailableTimes];
      tempAll.splice(selectionInAvailable[0].originalIndex, 1);
      setSharedAvailableTimes(tempAll);
      // props.setSelectedSlot([{ originalIndex: originalIndex, value: temp[0] }]);
      props.setSelectedSlot(selectionInAvailable);
    }

    calendarRef?.current?.getApi()?.unselect();
  };

  const handleSelect = (selection) => {
    var selectionStart = selection.start.getTime();
    var selectionEnd = selection.end.getTime();

    var selectedTimesInSelection = [];
    props.selectedSlot.forEach((alreadySelected) => {
      var alreadySelectedStart = alreadySelected.value.start.getTime();
      var alreadySelectedEnd = alreadySelected.value.end.getTime();

      //case for selection fully within one already selected time
      if (
        alreadySelectedStart <= selectionStart &&
        alreadySelectedStart <= selectionEnd &&
        alreadySelectedEnd >= selectionStart &&
        alreadySelectedEnd >= selectionEnd
      ) {
        selectedTimesInSelection.push(alreadySelectedStart);
      }
      //case for selections within more than one already selected time
      else if (
        alreadySelectedStart >= selectionStart &&
        alreadySelectedStart <= selectionEnd &&
        alreadySelectedEnd >= selectionStart &&
        alreadySelectedEnd <= selectionEnd
      ) {
        selectedTimesInSelection.push(alreadySelectedStart);
      }
    });
    // console.log("selectedTimesInSelection", selectedTimesInSelection);
    //checks if selection contains already selected times
    //if not add all new selections
    //if yes overwrite existing selections with new selections

    //if selections aren't overlapping this should add to selection up to length 6
    switch (selectedTimesInSelection.length === 0) {
      case true:
        var temp = [...props.selectedSlot];
        var selectionInAvailable = findSelectionInAvailableCopy(
          selectionStart,
          selectionEnd
        );
        // console.log("--selectionInAvailable", selectionInAvailable);
        temp.push(...selectionInAvailable);
        if (props.eventCase === "NonSignUp") {
          if (temp.length <= 3) {
            const tempAll = [...sharedAvailableTimes];
            tempAll.splice(selectionInAvailable[0].originalIndex, 1);
            setSharedAvailableTimes(tempAll);
            props.setSelectedSlot(temp);
          } else {
            setErrorAmount(
              "Please select no more than 5 timeslots for the convenience of your participants"
            );
          }
        } else if (temp.length <= 5) {
          const tempAll = [...sharedAvailableTimes];
          tempAll.splice(selectionInAvailable[0]?.originalIndex, 1);
          setSharedAvailableTimes(tempAll);
          props.setSelectedSlot(temp);
        } else {
          setErrorAmount(
            "Please select no more than 5 timeslots for the convenience of your participants"
          );
        }

        break;
      //remove slot
      case false:
        var temp = [...props.selectedSlot];
        var selectionInAvailable = findSelectionInAvailable(
          selectionStart,
          selectionEnd
        );

        // console.log("selectionInAvailable", selectionInAvailable);
        selectionInAvailable.forEach((availableTime) => {
          var alreadySelectedInAvailable = props.selectedSlot.findIndex(
            (alreadySelected) =>
              alreadySelected.value.start.getTime() ===
              availableTime.value.start.getTime()
          );
          if (alreadySelectedInAvailable === -1) {
            temp.push(availableTime);
          }
          //if selection is already in selected, remove it
          else {
            if (props?.errorAmount) {
              setErrorAmount("");
            }

            const tempAllSlots = [...sharedAvailableTimes];
            tempAllSlots.splice(
              selectionInAvailable[0].originalIndex,
              0,
              selectionInAvailable[0].value
            );
            setSharedAvailableTimes(tempAllSlots);
            temp.splice(alreadySelectedInAvailable, 1);
          }
        });
        if (temp.length < 5) {
          props.setSelectedSlot(temp);
        } else {
          setErrorAmount(
            "Please select no more than 5 timeslots for the convenience of your participants"
          );
        }

        break;
    }

    calendarRef?.current?.getApi()?.unselect();
  };

  const findSelectionInAvailable = (selectionStart, selectionEnd, index) => {
    var availableTimeRange = [];

    available.events.forEach((availableTime, i) => {
      //handles selections not within one available time
      if (
        (availableTime.start.getTime() > selectionStart &&
          availableTime.start.getTime() < selectionEnd) ||
        (availableTime.end.getTime() > selectionStart &&
          availableTime.end.getTime() < selectionEnd)
      ) {
        availableTimeRange.push({
          originalIndex: i,
          value: { start: availableTime.start, end: availableTime.end },
        });
      }
      //handles selections within one available time
      if (
        availableTime.start.getTime() <= selectionStart &&
        availableTime.end.getTime() >= selectionEnd
      ) {
        availableTimeRange.push({
          originalIndex: i,
          value: { start: availableTime.start, end: availableTime.end },
        });
      }
    });

    return availableTimeRange;
  };

  const findSelectionInAvailableCopy = (
    selectionStart,
    selectionEnd,
    index
  ) => {
    var availableTimeRange = [];
    // console.log("availbel shared", sharedAvailableTimes);

    sharedAvailableTimes.forEach((availableTime, i) => {
      if (
        (availableTime.start.getTime() > selectionStart &&
          availableTime.start.getTime() < selectionEnd) ||
        (availableTime.end.getTime() > selectionStart &&
          availableTime.end.getTime() < selectionEnd)
      ) {
        availableTimeRange.push({
          originalIndex: i,
          value: { start: availableTime.start, end: availableTime.end },
        });
      }
      //handles selections within one available time
      if (
        availableTime.start.getTime() <= selectionStart &&
        availableTime.end.getTime() >= selectionEnd
      ) {
        availableTimeRange.push({
          originalIndex: i,
          value: { start: availableTime.start, end: availableTime.end },
        });
      }
    });

    return availableTimeRange;
  };

  /**
   * Sets state variables that track the date whenever a date is clicked on the calendar
   * @param {Date} date - the selected date
   */
  const handleDateChange = (date) => {
    const currentString = date.toLocaleString("default", { weekday: "long" });

    setSelectedDate(currentString);
    setDate(date.getDate());
    setMonth(date.toLocaleString("default", { month: "long" }));
    setYear(date.toLocaleString("default", { year: "numeric" }));

    const recommendedString = `${date.getMonth()}/${date.getDate()}/${date.getFullYear()}`;
    setRecommendedDate(recommendedString);
  };

  const handleDateClick = (dateClicked) => {
    const view = calendarRef?.current?.getApi().view.type;
    if (view === "dayGridMonth") {
      calendarRef.current.getApi().changeView("timeGridDay", dateClicked.date);
      setSelectedBtn(1);
    }
    handleDateChange(dateClicked);
  };

  const dateClickEvent = (info) => {
    var check = moment(info.date);
    var today = moment();
    if (check > today) {
      const end = moment(info.date).add(
        moment.duration(props.timeInterval).asMinutes(),
        "minutes"
      );

      //exisiting meeting and slot check (not confirmed yet but added)
      if (!overlapCheck) {
        const meetings = userMeetings?.events?.filter(
          (e) => new Date(e.start).getDate() === new Date(info.date).getDate()
        );
        const test = meetings?.filter(
          (m) =>
            (new Date(end) > m.start && new Date(end) <= m.end) ||
            (new Date(info.date) < m.start && new Date(end) > m.end)
        );
        if (test?.length > 0) {
          setPastError("Slot will overlap with existing meeting");
          return;
        }
        const pref = unavailable?.events?.filter(
          (e) => new Date(e.start).getDate() === new Date(info.date).getDate()
        );
        const test2 = pref?.filter(
          (m) => new Date(end) > m.start && new Date(end) < m.end
        );
        if (test2?.length > 0) {
          setPastError("Slot will overlap with blocked slot");
          return;
        }
      }

      const parseResponse = {
        id: eventCal.length > 0 ? eventCal[eventCal.length - 1].id + 1 : 1,
        title:
          moment(info.date).format("LT") + " - " + moment(end).format("LT"),
        start: info.date,
        end: end.toDate(),
        borderColor: "#479AE9",
        backgroundColor: "#bbdeff",
      };
      if (meetingType) {
        if (props.selectedSlot?.length < 1) {
          setEventCal([parseResponse]);
        } else {
          setErrorAmount("You can only select a single timeslot");
        }
        return;
      }
      if (
        props.eventCase === ALL_SKEDING_USERS ||
        (props.eventCase === ONE_NONSKEDING_ONE_SKEDING_USER &&
          props.qrMeeting &&
          props.selectedSlot?.length < 1)
      ) {
        setEventCal([parseResponse]);
      } else if (
        ((props.eventCase === ONE_NONSKEDING_ONE_SKEDING_USER &&
          !props.qrMeeting) ||
          props.eventCase === ONE_NONSKEDING_MULTIPLE_SKEDING_USERS) &&
        props.selectedSlot?.length < 5
      ) {
        if (eventCal?.length < 5) {
          setEventCal([...eventCal, parseResponse]);
        }
      } else if (
        props.eventCase === "NonSignUp" &&
        props.selectedSlot?.length < 3
      ) {
        if (eventCal?.length < 3) {
          setEventCal([...eventCal, parseResponse]);
        }
      } else if (
        props.eventCase === "NonSignUpOneSelect" &&
        props.selectedSlot?.length < 1
      ) {
        if (eventCal?.length < 1) {
          setEventCal([...eventCal, parseResponse]);
        }
      } else {
        if (
          props.eventCase === ALL_SKEDING_USERS ||
          (props.eventCase === ONE_NONSKEDING_ONE_SKEDING_USER &&
            props.qrMeeting)
        ) {
          setErrorAmount("You can only select a single timeslot");
        } else if (
          props.eventCase === ONE_NONSKEDING_ONE_SKEDING_USER ||
          props.eventCase === ONE_NONSKEDING_MULTIPLE_SKEDING_USERS
        ) {
          setErrorAmount(
            "Please select no more than 5 timeslots for the convenience of your participants"
          );
        } else if (props.eventCase === "NonSignUpOneSelect") {
          setErrorAmount("You can only select a single slot for meeting");
        } else if (props.eventCase === "NonSignUp") {
          setErrorAmount("You can only select 3 timeslots");
        }
      }
    } else {
      setPastError("Past dates are not available");
    }
  };

  const eventClicked = async (info) => {
    //public id is only for selected slots
    if (info.event._def.publicId) {
      const temp = [...eventCal];
      var eventId = info.event._def.publicId;
      // console.log("event click info: ", eventId);
      let event = await temp.find((event) => event.id === parseInt(eventId));
      const index = temp.indexOf(event);
      if (index !== -1) {
        temp.splice(index, 1);
        setEventCal(temp);
        setErrorAmount("");
      }
    }
  };

  const updateEventDrop = async (eventInfo, body) => {
    var check = moment(eventInfo.event.start);
    var today = moment();
    const temp = [...eventCal];
    let eventId = eventInfo.event._def.publicId;
    if (check < today) {
      setEventCal(temp);
      setPastError("Past dates are not available");
      return;
    }
    let event = await temp.find((event) => event.id === parseInt(eventId));
    const index = temp.indexOf(event);
    temp[index].start = body.start;
    temp[index].end = body.end;
    temp[index].title =
      moment(body.start).format("LT") + " - " + moment(body.end).format("LT");
    setEventCal(temp);
  };

  return (
    <Style>
      {createMeetingCalendarStyles(props.eventCase)}
      <div>
        <Grid container>
          <Grid item xs={12}>
            <ErrorSnackbar
              open={!!pastError}
              handleClose={() => setPastError("")}
              message={pastError}
              vertical={"bottom"}
              horizontal={"center"}
            />
            <Card
              sx={{
                p: mobile ? 0 : "15px",
                boxShadow: "0",
                borderRadius: "10px",
                backgroundColor: theme.palette.secondary.lighter,
                marginLeft: mobile ? 0 : "20px",
              }}
              id="create-calendar"
            >
              <Stack direction="column" justifyContent="center" spacing="2px">
                <Navigation
                  calendarRef={calendarRef}
                  setNavLink={setNavLink}
                  handleUnavailability={handleUnavailability}
                  mobile={mobile}
                  setSelectedBtn={setSelectedBtn}
                  overlapCheck={overlapCheck}
                  // handleChangeOverlap={handleChangeOverlap}
                  setOverlapCheck={setOverlapCheck}
                  regetParticipant={regetParticipant}
                  qrMeeting={props.qrMeeting}
                />

                <Box
                  sx={{
                    overFlow: "auto",
                    height: "58vh",
                    // 66vh
                    //  maxHeight: "720px"
                  }}
                >
                  <FullCalendar
                    // firstDay={1}
                    plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin]}
                    dateClick={(info) => dateClickEvent(info)}
                    id="create-calendar-arrows create-calendar-date"
                    initialView="timeGridWeek"
                    themeSystem="bootstrap5"
                    nowIndicator={true}
                    editable={true}
                    eventDurationEditable={false}
                    eventStartEditable={true}
                    headerToolbar={false}
                    // selectMirror={true}
                    // selectAllow={() => console.log("hello")}
                    scrollTime={"9:00:00"}
                    allDaySlot={false}
                    slotDuration={"00:15:00"}
                    // slotMinTime={`${getMinMaxTime("min")}:00:00`}
                    // slotMaxTime={`${getMinMaxTime("max")}:00:00`}
                    expandRows={true}
                    // datesSet={props.onCalendarMonthChange}
                    eventDisplay="block"
                    // eventDisplay="background"
                    slotLabelFormat={{
                      hour: "numeric",
                      // minute: "2-digit",
                      hour12: true,
                    }}
                    height={"100%"}
                    slotLabelInterval={"01:00"}
                    eventSources={[
                      eventCal?.length > 0 ? eventCal : [],
                      Object.keys(unavailable).length > 0 && !preferencesCheck
                        ? unavailable
                        : [],
                      Object.keys(userMeetings).length > 0 ? userMeetings : [],
                      Object.keys(participantUnavailibility).length > 0
                        ? participantUnavailibility
                        : [],
                      Object.keys(participantMeetings).length > 0
                        ? participantMeetings
                        : [],
                      [
                        {
                          start: moment().subtract(6, "months").valueOf(),
                          end: moment().subtract(1, "minute").valueOf(),
                          display: "background",
                          backgroundColor: "#F7F7F9",
                        },
                      ],
                    ]}
                    eventTextColor="#3C4242"
                    eventOrderStrict={true}
                    slotEventOverlap={true}
                    eventOverlap={overlapCheck ? true : false}
                    eventClick={eventClicked}
                    displayEventTime={false}
                    views={{
                      dayGridMonth: {
                        dayHeaderFormat: { weekday: "short" },
                      },
                      timeGridWeek: {
                        dayHeaderContent: (date) => dayHeader(date, "week"),
                      },
                      timeGridDay: {
                        dayHeaderContent: (date) => dayHeader(date, "day"),
                      },
                    }}
                    // dayHeaderContent={(date) => dayHeader(date)}
                    navLinks={navLink}
                    navLinkDayClick={(date) => handleDateClick(date)}
                    selectable={true}
                    longPressDelay={500}
                    // selectOverlap={(event) => eventClicked(event)}
                    selectLongPressDelay={500}
                    // select={
                    //   maxSelection === 5 ? handleSelect : handleSingleSelect
                    // }
                    ref={calendarRef}
                    eventDrop={function (eventDropInfo) {
                      updateEventDrop(eventDropInfo, {
                        start: removeOffsetFromDate(
                          eventDropInfo.event._instance.range.start
                        ),
                        end: removeOffsetFromDate(
                          eventDropInfo.event._instance.range.end
                        ),
                      });
                    }}
                  />
                </Box>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </div>
    </Style>
  );
}

function Navigation({
  calendarRef,
  setNavLink,
  mobile,
  handleUnavailability,
  regetParticipant,
  setSelectedBtn,
  overlapCheck,
  // handleChangeOverlap,
  setOverlapCheck,
  qrMeeting,
}) {
  // const [title, setTitle] = useState("");
  const { t } = useTranslation();
  const title =
    calendarRef?.current?.getApi()?.currentDataManager?.data?.viewTitle;

  // useEffect(() => {
  //   handleMonthHeading();
  // }, [
  //   calendarRef?.current?.getApi()?.currentDataManager?.state?.dateProfile
  //     .activeRange,
  // ]);

  // const handleMonthHeading = () => {
  //     const viewTitle =
  //       calendarRef?.current?.getApi()?.currentDataManager?.data?.viewTitle;
  //     setTitle(viewTitle);
  // };

  // const handleDayView = () => {
  //   calendarRef?.current?.getApi()?.changeView("timeGridDay");
  //   setNavLink(false);
  //   setSelectedBtn(1);
  // };

  // const handleWeekView = () => {
  //   calendarRef?.current?.getApi()?.changeView("timeGridWeek");
  //   setNavLink(false);
  //   setSelectedBtn(2);
  // };

  // const handleMonthView = () => {
  //   calendarRef?.current?.getApi()?.changeView("dayGridMonth");
  //   setNavLink(true);
  //   setSelectedBtn(3);
  // };

  return (
    <Grid
      container
      alignItems="flex-start"
      justifyContent="center"
      style={{ padding: mobile ? 16 : 0, marginBottom: mobile ? 0 : "20px" }}
    >
      <Grid
        container
        item
        xs={12}
        justifyContent="center"
        alignItems="flex-end"
        style={{ position: "relative", marginBottom: mobile ? 0 : "16px" }}
      >
        {mobile ? (
          <Box style={{ display: "flex" }}>
            <Typography variant="h2">{title}</Typography>
          </Box>
        ) : (
          <Box
            style={{
              position: "absolute",
              left: "4px",
              top: "8px",
              display: "flex",
            }}
          >
            {/* Time range being dispalyed in Create Meeting dialog */}
            <Stack justifyContent="flex-start" direction="row">
              <Typography variant="h3">{title}</Typography>
            </Stack>
            <Stack
              justifyContent="flex-start"
              direction="row"
              style={{ marginLeft: "12px" }}
            >
              <Button
                variant="outlined"
                style={{
                  marginTop: "-4px",
                  padding: 0,
                  fontWeight: 500,
                  color: "#363B53",
                }}
                onClick={() => {
                  calendarRef?.current?.getApi()?.today();
                  // handleMonthHeading();
                  handleUnavailability();
                }}
              >
                {t("today")}
              </Button>
            </Stack>
          </Box>
        )}
        {/* <Box
          display="flex"
          flexDirection="column"
          justifyContent={"center"}
          alignItems="center"
          // sx={{ mt: { xs: 2, md: 0 } }}
        >
          <Stack
            direction="row"
            sx={{
              width: "fit-content",
              height: "26px",
              background: "#FBFBFF",
              borderRadius: "8px",
            }}
          >
            <Button
              sx={{
                borderRadius: "8px",
                backgroundColor: selectedBtn === 1 ? "null" : "#FBFBFF",
              }}
              onClick={handleDayView}
              variant={selectedBtn === 1 ? "selected" : "unselected"}
            >
              Day
            </Button>
            <Button
              sx={{
                borderRadius: "8px",
                backgroundColor: selectedBtn === 2 ? "null" : "#FBFBFF",
              }}
              onClick={handleWeekView}
              variant={selectedBtn === 2 ? "selected" : "unselected"}
            >
              Week
            </Button>
            <Button
              sx={{
                borderRadius: "8px",
                backgroundColor: selectedBtn === 3 ? "null" : "#FBFBFF",
              }}
              onClick={handleMonthView}
              variant={selectedBtn === 3 ? "selected" : "unselected"}
            >
              Month
            </Button>
          </Stack>
        </Box> */}
        {/* {mobile ? null : ( */}
        <Box
          style={{
            position: mobile ? "relative" : "absolute",
            right: 0,
            top: mobile ? "2px" : "-8px",
            width: mobile ? "100%" : "auto",
          }}
        >
          <Stack
            justifyContent={mobile ? "space-between" : "flex-end"}
            alignItems="center"
            direction="row"
          >
            {mobile && (
              <ScrollButton
                direction="prev"
                calendarRef={calendarRef}
                callback={handleUnavailability}
                regetParticipant={regetParticipant}
              />
            )}
            {qrMeeting ? null : (
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="14px"
                      checked={overlapCheck}
                      sx={{
                        color: "black",
                      }}
                      onChange={(e) => {
                        setOverlapCheck((prev) => !prev);
                        // handleChangeOverlap(e);
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label={
                    <Box
                      display={"flex"}
                      alignItems="center"
                      justifyContent={"flex-start"}
                    >
                      <Typography variant="h3" fontWeight={"500"}>
                        {t("allow_overlap")}
                      </Typography>
                      <Box ml={1}>
                        <HelpComponent
                          msg={NEW_MEETINGS_ALLOW_OVERLAP}
                          position={"top"}
                        />
                      </Box>
                    </Box>
                  }
                />
              </FormGroup>
            )}
            {!mobile && (
              <ScrollButton
                direction="prev"
                calendarRef={calendarRef}
                callback={handleUnavailability}
                regetParticipant={regetParticipant}
              />
            )}

            <ScrollButton
              direction="next"
              calendarRef={calendarRef}
              callback={handleUnavailability}
              regetParticipant={regetParticipant}
            />
          </Stack>
        </Box>
        {/* )} */}
      </Grid>
    </Grid>
  );
}
