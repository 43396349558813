import { useEffect, useState } from "react";
import { Grid, Box, Stack, Typography } from "@mui/material";

import ScrollButton from "./ScrollButton";

/**
 * Navigation components for the FreeSlotCalendar component
 */
export default function Navigation({ calendarRef, mobile }) {
  const [title, setTitle] = useState("");

  useEffect(() => {
    handleMonthHeading();
  }, [
    calendarRef?.current?.getApi()?.currentDataManager?.state?.dateProfile
      .activeRange,
  ]);

  const handleMonthHeading = () => {
    const viewTitle =
      calendarRef?.current?.getApi()?.currentDataManager?.data?.viewTitle;
    setTitle(viewTitle);
  };

  return (
    <Grid container alignItems="flex-start" justifyContent="center" mb="40px">
      <Grid
        container
        item
        xs={12}
        justifyContent="center"
        alignItems="flex-end"
        style={{ position: "relative", margin: mobile ? "10px" : 0 }}
      >
        <Box style={{ position: "absolute", left: "4px", top: "8px" }}>
          <Stack justifyContent="flex-start" direction="row">
            <Typography variant="h2">{title}</Typography>
          </Stack>
        </Box>
        <Box style={{ position: "absolute", right: 0, top: "-10px" }}>
          <Stack justifyContent="flex-end" direction="row">
            <ScrollButton
              direction="prev"
              calendarRef={calendarRef}
              setTitle={setTitle}
            />
            <ScrollButton
              direction="next"
              calendarRef={calendarRef}
              setTitle={setTitle}
            />
          </Stack>
        </Box>
        {/* )} */}
      </Grid>
    </Grid>
  );
}
