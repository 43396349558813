import React from "react";

import "react-phone-number-input/style.css";
import "styles/phone.css";
import PhoneInput from "react-phone-number-input";
import { useTranslation } from "react-i18next";

function Phone({ setPhone, phone, showBorder, isDisabled }) {
  const { t } = useTranslation();
  return (
    <>
      <PhoneInput
        international
        countryCallingCodeEditable={false}
        defaultCountry="BE"
        placeholder={t("phone_no")}
        value={phone}
        onChange={setPhone}
        style={{
          marginTop: showBorder ? "" : "10px",
          border: showBorder ? "1px solid #DAD2E5" : "",
          borderRadius: "6px",
          paddingLeft: showBorder ? "10px" : "",
          width: "inherit",
        }}
        disabled={isDisabled}
      />
    </>
  );
}

export default React.memo(Phone);
