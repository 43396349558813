import React, { useState, useContext, useEffect } from "react";
import {
  Typography,
  Button,
  Grid,
  Box,
  CircularProgress,
  InputLabel,
} from "@mui/material";
import "styles/newEvent.css";
import { serverPOST } from "Utils/HttpFunctions";
import { Title } from "../NewEvent";
import Participants from "../../components/NewEvent/Participants";
import Description from "components/NewEvent/Description";
import Location from "components/NewEvent/Location";
import TimeInterval from "components/NewEvent/TimeInterval";
import { integrationContext } from "context/Integrations";
import moment from "moment";
import PhysicalLocation from "components/NewEvent/PhysicalLocation";
import Phone from "components/NewEvent/Phone";
import VirtualLink from "components/NewEvent/Link";
import { checkUrl } from "Utils/CommonFunctions";
import CustomInterval from "components/NewEvent/CustomInterval";
import useStyle from "styles/formStyles";
import useContactGroups from "../../Utils/hooks/useContactGroups";
import useAvailability from "../../Utils/hooks/useAvailability";
import AvailableTimeslots from "../../components/Preferences/AvailableTimeslots";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import HelpComponent from "components/Global/Help";
import { ADD_PARTICIPANTS_IN_SHAREABLE } from "../../Utils/Constants";
import { useTranslation } from "react-i18next";
import ErrorSnackbar from "../../components/ErrorSnackbar";
import { ReactComponent as CrownIcon } from "../../assets/crown.svg";
import { useNavigate } from "react-router-dom";
import pUseStyle from "./styles";
import { RoomNo } from "../RoomNo";

export default function CreateTemplate({
  setModal,
  setRefresh,
  tab,
  closeDialogue,
  dialogue,
  totalTemplates,
  isLocked,
}) {
  //add is locked property
  const { user } = useContext(integrationContext);
  const classes = useStyle();
  const { t } = useTranslation();
  const [title, setTitle] = useState("");
  const [timeInterval, setTimeInterval] = useState("01:00:00");
  const [error, setError] = useState("");
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [room, setRoom] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [participants, setParticipants] = useState([]);
  const [link, setLink] = useState(() =>
    user && user.virtual_link ? user.virtual_link : ""
  );
  const [toggleParticipant, setToggleParticipant] = useState(false);
  const [customTime, setCustomTime] = useState("");
  const [contacts, groups, minimizedContacts] = useContactGroups(participants);
  const pClasses = pUseStyle();

  const { setCalInfo, handleSaveButtonClick, calInfo } = useAvailability();

  /**
   * Set the value of the location on the first render based on what the user has synced
   */
  useEffect(() => {
    setDefaultLocation();
  }, []);

  /**
   * Disables the create button until all form fields are filled
   */
  useEffect(() => {
    if (title && timeInterval && location) {
      setDisabled(false);
    } else setDisabled(true);
  }, [title, timeInterval, location]);

  /**
   * Clears the form fields after submission
   */
  const clearForm = () => {
    setTitle("");
    setDescription("");
    setTimeInterval("01:00:00");
    setDefaultLocation();
    setError("");
    setParticipants([]);
  };

  const setDefaultLocation = () => {
    if (user?.is_google_synced) {
      setLocation("Google Meet");
    } else if (user?.is_zoom_synced) {
      setLocation("Zoom");
    } else if (user?.is_teams_synced) {
      setLocation("Teams");
    }
  };

  /**
   * Makes a request to the backend to create a new template
   * Handles form validation before making the request
   */
  const handleCreate = async () => {
    setError("");
    if (timeInterval === "" || location === "") {
      setError(t("mandatory_field_error"));
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return;
    }
    if (timeInterval === "custom" && customTime === "") {
      setError(t("mandatory_field_error"));
      return;
    }
    if (
      (location === "Other" && address === "") ||
      (location === "Phone" && phone === "") ||
      (location === "Link" && link === "")
    ) {
      setError(t("address_err"));
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return;
    }
    if (location === "Phone" && phone.length < 6) {
      setError(t("phone_err"));
      return false;
    }
    if (location === "Link" && !checkUrl(link)) {
      setError(t("link_err"));
      return false;
    }

    setSubmitted(true);
    setDisabled(true);

    var loc =
      location === "Other"
        ? address
        : location === "Phone"
        ? phone
        : location === "Link"
        ? link
        : location;

    const duration_minutes =
      timeInterval === "custom"
        ? moment.duration(customTime).asMinutes()
        : moment.duration(timeInterval).asMinutes();

    const organizerAvailability = await handleSaveButtonClick();

    let data = {
      title,
      agenda: description,
      location: loc,
      duration: duration_minutes,
      meeting_room: location === "Other" ? (room ? room : "") : "",
      organizer_available_timeslots: organizerAvailability.available_timeslots,
      organizer_blocked_timeslots: organizerAvailability.blocked_timeslots,
      participants,
    };
    const response = await serverPOST(
      `/meeting-template?offset=${new Date().getTimezoneOffset()}`,
      data
    );
    if (response && response.constructor !== String) {
      setModal({ open: true, msg: t("snackbar_messages.8") });
      setDisabled(false);
      setSubmitted(false);
      clearForm();
      setRefresh((prev) => !prev);
      if (dialogue) {
        closeDialogue();
      }
    } else {
      setSubmitted(false);
      setDisabled(false);
      setError(response);
    }
  };

  return (
    <ShareableCreatedLocked isLocked={isLocked}>
      <Box
        className={classes.root}
        sx={{
          opacity: isLocked ? "0.5" : "1",
        }}
        // style={{ opacity: totalTemplates > 0 ? 0.3 : 1 }}
      >
        {/* {
        props is passed 0 incase of paid users and actual length in case of unpaid users
        totalTemplates > 0 ? (
          
         ) : (
      <> */}
        <Box>
          <Typography fontSize="20px" fontWeight={500} align={"center"}>
            {t("create_shareable_title")}
          </Typography>
        </Box>

        <ErrorSnackbar
          open={!!error}
          handleClose={() => setError("")}
          message={error}
          vertical={"bottom"}
          horizontal={"center"}
        />
        <Grid container rowGap="12px">
          <Grid item xs={12}>
            <InputLabel className={pClasses.inputLabel}>
              <Typography variant="h4" align="left">
                {t("title") + ":"}
              </Typography>
            </InputLabel>
            <Title setTitle={setTitle} title={title} />
          </Grid>

          <Grid item xs={12} style={{ textAlign: "left" }}>
            {!toggleParticipant && (
              <Box display={"flex"} alignItems="center">
                <Button
                  onClick={() => setToggleParticipant((prev) => !prev)}
                  style={{ fontSize: "14px", fontWeight: 500 }}
                  startIcon={<PersonAddIcon style={{ padding: 0 }} />}
                >
                  {t("add_participants")}
                </Button>
                <span style={{ marginLeft: 6 }}>
                  <HelpComponent
                    msg={ADD_PARTICIPANTS_IN_SHAREABLE}
                    position={"bottom"}
                  />
                </span>
              </Box>
            )}

            {toggleParticipant && (
              <>
                <InputLabel className={pClasses.inputLabel}>
                  <Typography variant="h4" align="left">
                    {t("participants") + ":"}
                  </Typography>
                </InputLabel>
                <Participants
                  setParticipants={setParticipants}
                  participants={participants}
                  participantLabels={[]}
                  contacts={contacts}
                  groups={groups.filter((g) =>
                    g.contacts.every((c) => c.is_contact_skeding_user === true)
                  )}
                  minimizedContacts={minimizedContacts.filter(
                    (p) => p.isSkeding
                  )}
                  user={user}
                  checkOnlySkeding={true}
                  isRequired={false}
                />
              </>
            )}
          </Grid>

          <Grid container xs={12} className={pClasses.mainContainer}>
            <InputLabel className={pClasses.inputLabel}>
              <Typography variant="h4" align="left">
                {t("duration") + ":"}
              </Typography>
            </InputLabel>
            <div style={{ textAlign: "left" }}>
              <TimeInterval
                timeInterval={timeInterval}
                handleIntervalChange={(event) =>
                  setTimeInterval(event.target.value)
                }
                notNewMeeting={true}
              />
            </div>
          </Grid>
          {timeInterval === "custom" ? (
            <Grid container item xs={12}>
              <Box className={pClasses.timeInterval}>
                <CustomInterval
                  setCustomTime={setCustomTime}
                  customTime={customTime}
                  showDropDown={true}
                />
              </Box>
            </Grid>
          ) : null}
          <Grid container xs={12} className={pClasses.mainContainer}>
            <InputLabel className={pClasses.inputLabel}>
              <Typography variant="h4" align="left">
                {t("location") + ":"}
              </Typography>
            </InputLabel>
            <div style={{ textAlign: "left" }}>
              <Location
                setLocation={setLocation}
                location={location}
                user={user}
                notNewMeeting={true}
              />
            </div>
          </Grid>
          {location === "Other" && (
            <>
              <InputLabel className={pClasses.inputLabel}>
                <Typography variant="h4" align="left">
                  {t("search_location") + ":"}
                </Typography>
              </InputLabel>
              <PhysicalLocation setAddress={setAddress} address={address} />
              <Grid item xs={11}>
                <InputLabel className={pClasses.inputLabel}>
                  <Typography variant="h4" align="left">
                    {t("room_no") + ":"}
                  </Typography>
                </InputLabel>
                <RoomNo room={room} setRoom={setRoom} />
              </Grid>
            </>
          )}
          {location === "Phone" && (
            <>
              <InputLabel className={pClasses.inputLabel}>
                <Typography variant="h4" align="left">
                  {t("phone_no") + ":"}
                </Typography>
              </InputLabel>
              <Phone setPhone={setPhone} phone={phone} />
            </>
          )}
          {location === "Link" && (
            <>
              <InputLabel className={pClasses.inputLabel}>
                <Typography variant="h4" align="left">
                  {t("link") + ":"}
                </Typography>
              </InputLabel>
              <VirtualLink setLink={setLink} link={link} />
            </>
          )}
          <Grid container item xs={12}>
            <InputLabel className={pClasses.inputLabel}>
              <Typography variant="h4" align="left">
                {t("desc") + ":"}
              </Typography>
            </InputLabel>
            <Description
              setDescription={setDescription}
              description={description}
              dark={true}
            />
          </Grid>
        </Grid>

        <Box mb="14px" mt={2.5}>
          <Box display="flex" alignItems={"center"}>
            <Typography variant="h3" className={pClasses.availTitle}>
              {t("avail_title")}
            </Typography>
          </Box>

          <Typography variant="h4" className={pClasses.availDesc}>
            {t("avail_desc")}
          </Typography>
        </Box>

        <AvailableTimeslots calInfo={calInfo} setCalInfo={setCalInfo} />

        <Grid
          container
          xs={12}
          className={pClasses.buttonContainer}
          sx={{ mt: 4 }}
        >
          {tab && (
            <Grid item>
              <Button variant="outlined" onClick={closeDialogue}>
                {t("close")}
              </Button>
            </Grid>
          )}

          <Grid item>
            <Button
              sx={{
                width: tab ? "auto" : "120px",
                marginLeft: 4,
              }}
              variant="contained"
              onClick={handleCreate}
              disabled={disabled}
            >
              {submitted ? (
                <CircularProgress style={{ color: "white" }} size={20} />
              ) : (
                t("create")
              )}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </ShareableCreatedLocked>
  );
}

const ShareableCreatedLocked = ({ isLocked, children }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const pClasses = pUseStyle();

  return (
    <Box position="relative">
      {isLocked && (
        <Box className={pClasses.lockedBox}>
          <Grid container className={pClasses.lockedContainer}>
            <Grid item>
              <CrownIcon fontSize={"large"} />
            </Grid>

            <Typography variant="h3" fontWeight={400} textAlign={"left"}>
              <span
                className={pClasses.textDecor}
                onClick={() => navigate("/payment-services")}
              >
                {t("Go premium")}
              </span>{" "}
              {t("shareable_premium")}
            </Typography>
          </Grid>
        </Box>
      )}
      {children}
    </Box>
  );
};
