import { useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";

/**
 * Dialog for prompting the user to confirm if they want to cancel a meeting
 */
export default function MeetingRejectDialog(props) {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const { t } = useTranslation();
  const handleClose = () => {
    setOpen((prev) => !prev);
  };

  const handleCancel = () => {
    props.setCancelMeeting(true);
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-meeting-reject"
      aria-describedby="alert-reject-meeting-invitation"
      sx={{ justifyContent: "center" }}
      PaperProps={{
        style: { padding: "24px" },
      }}
    >
      <DialogContent>
        <DialogContentText
          sx={{
            textAlign: "center",
            color: theme.palette.text.primary,
            fontSize: "12px",
            fontWeight: "500",
          }}
        >
          {t("cancel_confirm")}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button onClick={handleClose} variant="outlined">
          {t("close")}
        </Button>
        <Button onClick={handleCancel} variant="contained">
          {"proceed"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
