import { Container, Skeleton} from "@mui/material";

export default function TemplateSkeleton() {
    return (
      <Container maxWidth="xs">
        <Skeleton
          height={"280px"}
          animation="wave"
          style={{ borderRadius: 20, marginTop: -50 }}
        />
      </Container>
    );
  }