import { makeStyles } from "@mui/styles";

export const useStyle = makeStyles((theme) => ({
    root: {
      "& .MuiInputBase-input": {
        color: theme.palette.text.primary,
        fontWeight: 400,
        width: "100%",
        borderBottom: "none",
        outline: "none",
        "&:focus": {
          borderBottom: "1px solid #B3B5B6",
        },
        "&:hover": {
          borderBottom: "1px solid #B3B5B6",
        },
        paddingLeft: "8px",
      },
      "& .MuiAutocomplete-root": {
        "& .MuiInput-root": {
          "&:hover": {
            borderBottom: "1px solid #B3B5B6",
          },
          "&:focus": {
            borderBottom: "1px solid #B3B5B6",
          },
        },
      },
    },
    input: {
      "&::placeholder": {
        color: "#363B53",
        fontWeight: 400,
        paddingLeft: "8px",
      },
    },
  }));