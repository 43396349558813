import { FormControl, MenuItem, Select } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import useStyle from "../../styles/formStyles";
import categoryData from "../../Utils/website.json";
import useWebsiteStore from "../../Utils/websiteStore";

export default function Categories() {
  const { t } = useTranslation();
  const classes = useStyle();
  const setCategory = useWebsiteStore((state) => state.setCategory);
  const category = useWebsiteStore((state) => state.category);

  const handleChange = (event) => {
    setCategory(event.target.value);
  };

  const styles = {
    width: "100%",
    marginTop: "10px",
    textAlign: "left",
    border: "1px solid #DAD2E5",
    borderRadius: "6px",
    backgroundColor: "#F7F7F9",
    marginRight: 2,
    padding: "2px 0 2px 8px",
  };

  return (
    <FormControl variant="standard" sx={styles}>
      <Select
        labelId="category-label"
        id="category"
        onChange={handleChange}
        value={category}
        placeholder={t("location") + "*"}
        disableUnderline={true}
        style={{
          textAlign: "left",
          fontSize: 14,
        }}
        displayEmpty={true}
      >
        {categoryData &&
          categoryData.map((data) => {
            return (
              <MenuItem
                key={data.title}
                value={data.title}
                className={classes.menuItem}
              >
                {data.title}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
}
