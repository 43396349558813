import { serverGET, serverPUT } from "./HttpFunctions";
import moment from "moment";
import {
  ALL_SKEDING_USERS,
  GOOGLE_CALENDAR_EVENT,
  MEETING_THROUGH_SHAREABLE_CALENDAR,
  MEETING_THROUGH_WEB_INTEGRATION_CALENDAR,
  ONE_NONSKEDING_ONE_SKEDING_USER,
  OUTLOOK_CALENDAR_EVENT,
  RECURRING_EVENT,
  SEARCH_EVENT,
  SINGLE_SLOT_MEETING,
} from "./Constants";
import { pastelTheme, vibrantTheme, newTheme } from "Utils/colors";
let ObjectStorage = require("scaleway-object-storage");
const validator = require("validator");

export const convertUTCMillisecondsToDate = (milliseconds) => {
  let actualDate = new Date(milliseconds);
  return actualDate;
  /*
    let actualDate = 0;

    const offset = utcDate.getTimezoneOffset();

    if (offset < 0) {
        actualDate = new Date(utcDate.getTime() - offset * 60000);
    }
    else {
        actualDate = new Date(utcDate.getTime() + offset * 60000);
    }

    return actualDate;
    */
};

export function createDateWithTimezone(dateString, timezone) {
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZone: timezone,
  };

  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
    new Date(dateString)
  );

  return new Date(formattedDate);
}

export const convertDateToUTCMilliseconds = (date) => {
  // console.log("original date ===> ", date.toString());

  return date.getTime();
  /*
    const offset = date.getTimezoneOffset();
    let utcDate = 0;    // date of the UTC zone

    if (offset < 0) {
        utcDate = new Date(date.getTime() + offset * 60000);
    }
    else {
        utcDate = new Date(date.getTime() - offset * 60000);
    }
    // console.log("UTC date ===> ", utcDate.toString());
    return utcDate.getTime();
    */
};

export const removeOffsetFromDate = (date, offset) => {
  if (offset === undefined) offset = new Date().getTimezoneOffset();

  date.setMinutes(date.getMinutes() + offset);
  return date;
  return;
  if (offset < 0) {
    // return new Date(date.getTime() - offset * 60000).getTime();
    return new Date(date.getTime() - offset * 60000);
  } else {
    // return new Date(date.getTime() + offset * 60000).getTime();
    return new Date(date.getTime() + offset * 60000);
  }
};

export const addOffsetToDate = (date) => {
  const offset = new Date().getTimezoneOffset();

  if (offset > 0) {
    return new Date(date.getTime() + offset * 60000).getTime();
  } else {
    return new Date(date.getTime() - offset * 60000).getTime();
  }
};

export const getToken = () => {
  const token = localStorage.getItem("delegateToken");
  if (token) {
    return token;
  }
  return localStorage.getItem("token");
};

export const getOnlyAccessToken = () => {
  return localStorage.getItem("token");
};

export const uploadFileAttachment = async (fileName, data, meetingId) => {
  console.log("Uploading file to scaleway bucket");
  // console.log(fileName, data, meetingId);

  let objectStorage = new ObjectStorage({
    accessKey: "SCWA72WCCSS8XMQC483D",
    secretKey: "915b7726-8ca7-4749-b592-49bbdcbe1007",
    region: "fr-par",
  });

  try {
    let bucket = "skeding-scaleway-bucket-test-1";
    let key = "/meeting-attachments/" + meetingId + "-" + fileName;
    let body = data;

    let putResponse = await objectStorage.putObject({
      bucket,
      key,
      body,
    });

    console.log(`put status: ${putResponse.status}`);
    console.log(`put response body: '${await putResponse.text()}'`);

    if (putResponse.status == 200) {
      let response = await serverGET(
        `/meeting/attachment?fileName=${fileName}&meetingId=${meetingId}`
      );

      console.log("response ACL ===> ", response);
    }

    /*
       let getResponse = await objectStorage.getObject({ bucket, key });
   
       console.log(`get status: ${getResponse.status}`)
       console.log(`get response body: '${await getResponse.text()}'`)
      
           let delResponse = await objectStorage.deleteObject({ bucket, key });
       
           console.log(`del status: ${delResponse.status}`)
           console.log(`del response body: '${await delResponse.text()}'`)
       */
  } catch (e) {
    console.log(e);
  }
};

export const deleteFileAttachment = async (fileName, meetingId) => {
  console.log("Deleting file to scaleway bucket");
  // console.log(fileName, data, meetingId);

  let objectStorage = new ObjectStorage({
    accessKey: "SCWA72WCCSS8XMQC483D",
    secretKey: "915b7726-8ca7-4749-b592-49bbdcbe1007",
    region: "fr-par",
  });

  try {
    let bucket = "skeding-scaleway-bucket-test-1";
    let key = "/meeting-attachments/" + meetingId + "-" + fileName;

    let putResponse = await objectStorage.deleteObject({
      bucket,
      key,
    });

    console.log(`put status: ${putResponse.status}`);
    console.log(`put response body: '${await putResponse.text()}'`);

    if (putResponse.status == 204) {
      let response = await serverPUT(
        `/meeting/summarize/${meetingId}?removeCheck=true`
      );

      console.log("response ACL ===> ", response);
      return response;
    }

    /*
       let getResponse = await objectStorage.getObject({ bucket, key });
   
       console.log(`get status: ${getResponse.status}`)
       console.log(`get response body: '${await getResponse.text()}'`)
      
           let delResponse = await objectStorage.deleteObject({ bucket, key });
       
           console.log(`del status: ${delResponse.status}`)
           console.log(`del response body: '${await delResponse.text()}'`)
       */
  } catch (e) {
    console.log(e);
  }
};

export const uploadMultipleFileAttachments = async (
  fileNames,
  files,
  meetingId
) => {
  console.log("Uploading file to scaleway bucket");
  // console.log(fileName, data, meetingId);

  let objectStorage = new ObjectStorage({
    accessKey: "SCWA72WCCSS8XMQC483D",
    secretKey: "915b7726-8ca7-4749-b592-49bbdcbe1007",
    region: "fr-par",
  });

  try {
    let bucket = "skeding-scaleway-bucket-test-1";
    for (let i = 0; i < fileNames.length; i++) {
      let key = "/meeting-attachments/" + meetingId + "-" + fileNames[i];
      let body = files[i];

      let putResponse = await objectStorage.putObject({
        bucket,
        key,
        body,
      });

      console.log(`put status: ${putResponse.status}`);
      console.log(`put response body: '${await putResponse.text()}'`);

      if (putResponse.status == 200) {
        let response = await serverGET(
          `/meeting/attachment?fileName=${fileNames[i]}&meetingId=${meetingId}`
        );

        console.log("response ACL ===> ", response);
      }
    }

    /*
       let getResponse = await objectStorage.getObject({ bucket, key });
   
       console.log(`get status: ${getResponse.status}`)
       console.log(`get response body: '${await getResponse.text()}'`)
      
           let delResponse = await objectStorage.deleteObject({ bucket, key });
       
           console.log(`del status: ${delResponse.status}`)
           console.log(`del response body: '${await delResponse.text()}'`)
       */
  } catch (e) {
    console.log(e);
  }
};

export const downloadFileAttachment = async (fileName, meetingId) => {
  const fullName = meetingId + "-" + fileName;
  try {
    // let encodedFileName = encodeURI(fileName);
    let link = `https://skeding-scaleway-bucket-test-1.s3.fr-par.scw.cloud/meeting-attachments/${fullName}`;
    window.open(link, "_blank");
    // window.location.href = link;
  } catch (e) {
    console.log(e);
  }
};

// Function to Sort the Data by given Property
export const sortByProperty = (property) => {
  return function (a, b) {
    let sortStatus = 0,
      aProp = a[property],
      bProp = b[property];
    if (aProp < bProp) {
      sortStatus = -1;
    } else if (aProp > bProp) {
      sortStatus = 1;
    }
    return sortStatus;
  };
};

export const getUniqueKey = () => {
  crypto.randomUUID();
};

export const dayHeader = (arg, view) => {
  let dateWithWeekday;

  if (view === "day") {
    dateWithWeekday = moment(arg.date).format("dddd D");
  } else {
    dateWithWeekday = moment(arg.date).format("ddd D");
  }

  return dateWithWeekday;
};

export const handleEmailValidation = (
  inputRef,
  setEmailError,
  setEmailHelperText
) => {
  if (!validator.isEmail(inputRef.current.value)) {
    setEmailError(true);
    setEmailHelperText("Please enter a valid email.");
  } else {
    setEmailError(false);
    setEmailHelperText("");
  }
};

export const checkUrl = (string) => {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
};

export const checkLocation = (location) => {
  const googleRegex = /www\.google\.com/g;
  const microsoftRegex = /outlook\.live\.com/g;

  if (googleRegex.test(location) || microsoftRegex.test(location)) {
    return null;
  } else return location;
};

export const getContactInitials = (contact) => {
  const splitNameArr = contact.contact_name.split(" ");
  const contactFirstInit = splitNameArr[0][0];
  let contactSecondInit;

  if (splitNameArr.length > 1) {
    contactSecondInit = splitNameArr[1][0];
    return [contactFirstInit, contactSecondInit];
  } else return [contactFirstInit];
};

export const stringToColor = (string, palette) => {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color;

  switch (Math.abs(hash % 6)) {
    case 0:
      color = palette["0"];
      break;
    case 1:
      color = palette["1"];
      break;
    case 2:
      color = palette["2"];
      break;
    case 3:
      color = palette["3"];
      break;
    case 4:
      color = palette["4"];
      break;
    case 5:
      color = palette["5"];
      break;
    default:
      color = "#bdbdbd";
  }

  return color;
};

export const getContacts = async (abortController) => {
  const validator = require("validator");
  const response = await serverGET("/contact", abortController.signal);
  if (response && response.constructor !== String) {
    const filtered = await response.data.filter((contact) =>
      validator.isEmail(contact.contact_email)
    );
    const parsedContacts = filtered.map((contact) => {
      return {
        email: contact.contact_email,
        _id: contact.skeding_user_id,
        name: contact.contact_name,
      };
    });

    // console.log("parsedContacts", parsedContacts);
    return parsedContacts;
  }
};

export const sortEventsByStartTime = (events) => {
  events.sort(function (a, b) {
    if (!a.start || !b.start) {
      // If either a.start or b.start is not set, move it to the end
      return !a.start ? 1 : -1;
    } else if (isNaN(a.start.getTime()) || isNaN(b.start.getTime())) {
      // If either a.start or b.start is an invalid Date object, move it to the end
      return isNaN(a.start.getTime()) ? 1 : -1;
    } else {
      return a.start.getTime() - b.start.getTime();
    }
  });
  return events;
};

export const getCalendarTitle = (calendarRef, setTitle) => {
  const tempTitle =
    calendarRef?.current?.getApi()?.currentDataManager?.data?.viewTitle;
  setTitle(tempTitle);
};

export const applyOffsetToPreferenceTimeslot = (timeslot, clientOffset) => {
  let hours = parseInt(clientOffset / 60);
  let min = clientOffset - 60 * hours;

  let start_day = timeslot.start_day;
  let start_hours = timeslot.start_hours - hours;
  let start_minutes = timeslot.start_minutes - min;
  let end_day = timeslot.end_day;
  let end_hours = timeslot.end_hours - hours;
  let end_minutes = timeslot.end_minutes - min;

  // let s = new Date();
  // s.setHours(start_hours);
  // s.setMinutes(start_minutes);
  // s.setSeconds(0);

  // let e = new Date();
  // e.setHours(end_hours);
  // e.setMinutes(end_minutes);
  // e.setSeconds(0);

  // const diffTime = Math.abs(e.getTime() - s.getTime());

  // const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  // console.log(
  //   "🚀 ~ file: CommonFunctions.js:310 ~ applyOffsetToPreferenceTimeslot ~ diffDays:",
  //   diffDays
  // );

  if (start_minutes > 59) {
    start_hours++;
    start_minutes -= 60;
  }
  if (start_minutes < 0) {
    start_hours--;
    start_minutes = 60 + start_minutes;
  }

  if (end_minutes > 59) {
    end_hours++;
    end_minutes -= 60;
  }
  if (end_minutes < 0) {
    end_hours--;
    end_minutes = 60 + end_minutes;
  }
  /**************************************************** */
  if (start_hours > 23) {
    start_day++;
    start_hours = start_hours - 24;
  }
  if (start_hours < 0) {
    start_day--;
    start_hours = 24 + start_hours;
  }

  if (end_hours > 23) {
    end_day++;
    end_hours = end_hours - 24;
  }
  if (end_hours < 0) {
    end_day--;
    end_hours = 24 + end_hours;
  }
  /**************************************************** */
  if (start_day > 6) {
    start_day = 7 - start_day;
  }
  if (end_day > 6) {
    end_day = 7 - end_day;
  }

  if (start_day < 0) {
    start_day = 7 + start_day;
  }
  if (end_day < 0) {
    end_day = 7 + end_day;
  }
  /**************************************************** */

  // if (start_hours - end_hours === 0) {
  //   let toReturn = {
  //     start_day,
  //     start_hours,
  //     start_minutes,
  //     end_day,
  //     end_hours,
  //     end_minutes,
  //   };
  //  //toReturn.end_hours = 0;
  //      toReturn.start_hours = 0;
  // return toReturn;
  // }

  let toReturn = {
    start_day,
    start_hours,
    start_minutes,
    end_day,
    end_hours,
    end_minutes,
  };

  return toReturn;
};

export const handleIsLock = (user) => {
  if (user?.package?.paid === 1) return false;

  if (user?.free_plan) return false;

  return true;
};

export const findColor = (
  eventCase,
  participants,
  contacts,
  isDraft
  // skedingParticipants,
  // nonSkedingParticipants,
  // theme
) => {
  // new
  if (isDraft) {
    return newTheme.draft;
  }
  if (
    [
      MEETING_THROUGH_WEB_INTEGRATION_CALENDAR,
      MEETING_THROUGH_SHAREABLE_CALENDAR,
    ].includes(eventCase)
  ) {
    return newTheme.websiteBooking;
  }
  if (
    [GOOGLE_CALENDAR_EVENT, OUTLOOK_CALENDAR_EVENT, SEARCH_EVENT].includes(
      eventCase
    )
  ) {
    return newTheme.externalEvents;
  }
  const internalMeeting = participants.some((email) =>
    contacts.some((obj) => obj.email === email && obj.is_team_member)
  );
  if (internalMeeting) {
    return newTheme.internalMeetings;
  }

  return newTheme.externalMeetings;
  // theme implementation

  // const selectedTheme = theme ? vibrantTheme : pastelTheme;
  // return [ALL_SKEDING_USERS, SINGLE_SLOT_MEETING].includes(eventCase) &&
  //   skedingParticipants.length + nonSkedingParticipants.length === 1
  //   ? selectedTheme[ONE_NONSKEDING_ONE_SKEDING_USER]
  //   : eventCase === RECURRING_EVENT &&
  //     skedingParticipants.length + nonSkedingParticipants.length === 1
  //   ? selectedTheme[ONE_NONSKEDING_ONE_SKEDING_USER]
  //   : eventCase === RECURRING_EVENT &&
  //     !skedingParticipants &&
  //     !nonSkedingParticipants === 0
  //   ? selectedTheme[GOOGLE_CALENDAR_EVENT]
  //   : selectedTheme[eventCase];
};

export const checkIfAllDay = (start, end) => {
  if (end - start === 86400000) {
    return true;
  } else return false;
};

export const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};
