import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog, IconButton } from "@mui/material";

export default function DialogWithCloseIcon({
  handleClose,
  open,
  children,
  dialogSize,
}) {
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      maxWidth={!!dialogSize ? dialogSize : "xs"}
      fullWidth
      PaperProps={{
        style: {
          borderRadius: 8,
          textAlign: "center",
          padding: 16,
          boxSizing: "border-box",
        },
      }}
    >
      <Box display="flex" justifyContent="flex-end" width="100%">
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      {children}
    </Dialog>
  );
}
