import { create } from "zustand";

const useDelegatedAcess = create((set) => ({
  // that lie under this scheudler
  members: [],
  delegatedMode: !!localStorage.getItem("delegateToken"),

  setDelegatedMode: (token) => {
    set({ delegatedMode: !!token });
    if (token) localStorage.setItem("delegateToken", token);
  },

  setMembers: (data) => {
    set({ members: data });
  },

  schedulerNotification: true,
  setSchedulerNotification: (flag) => {
    set({ schedulerNotification: flag });
  },
}));

export default useDelegatedAcess;
