import { useContext, useEffect, useState, useRef } from "react";

import {
  Typography,
  IconButton,
  Button,
  Box,
  useTheme,
  Divider,
  useMediaQuery,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  List,
  ListItemText,
} from "@mui/material";
import { useCookies } from "react-cookie";
import { Player } from "@lottiefiles/react-lottie-player";
import { useSearchParams, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import jwt_decode from "jwt-decode";
import DialogBox from "components/DialogBox";
import { ReactComponent as GoogleLogo } from "assets/google-logo.svg";
import { ReactComponent as MicrosoftLogo } from "assets/microsoft-logo.svg";
import { ReactComponent as AppleLogo } from "assets/Apple_logo_black.svg";
import { authContext } from "context/Auth";
import { ReactComponent as Bubbles } from "assets/bubbles.svg";
import { serverGETWithToken } from "Utils/HttpFunctions";
import { ReactComponent as SkedingLogo } from "assets/logo.svg";
import {
  MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS,
  ONE_NONSKEDING_MULTIPLE_SKEDING_USERS,
  ONE_NONSKEDING_ONE_SKEDING_USER,
  ONE_SKEDING_MULTIPLE_NONSKEDING_USERS,
  RECURRING_EVENT,
} from "Utils/Constants";
import {
  serverGETPOSTWithToken,
  serverPUTWithToken,
} from "Utils/HttpFunctions";
import {
  convertDateToUTCMilliseconds,
  convertUTCMillisecondsToDate,
  downloadFileAttachment,
} from "Utils/CommonFunctions";
import UserAgreesToSignup from "../../components/NewEvent/UserAgreesToSignup";
import UserNonSignup from "pages/UserNonSignup";
import loggingInDev from "loggingInDev";
import Animate from "assets/invite.json";
import Spinner from "../../components/Global/Spinner";
import { useTranslation } from "react-i18next";
import { SINGLE_SLOT_MEETING } from "../../Utils/Constants";
import useStyle from "./styles";

export default function UserSignup() {
  const [manuallySchedule, setManuallySchedule] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState([]);
  const [meetingData, setMeetingData] = useState(null);
  const [error, setError] = useState("");
  const [errorAmount, setErrorAmount] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [events, setEvents] = useState([]);
  const [modal, setModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [noMatchModal, setNoMatchModal] = useState(false);
  const [calView, setCalView] = useState(false);
  const [sharedAvailableTimes, setSharedAvailableTimes] = useState([]);
  const [isUserMatch, setIsUserMatch] = useState(false);
  const [open, setOpen] = useState(true);
  const [refetch, setRefetch] = useState(0);
  const { t } = useTranslation();
  const ref = useRef("");
  const [searchParams] = useSearchParams();
  const meetingId = searchParams.get("meetingId");
  const token = searchParams.get("token");
  const [meetingCancelled, setMeetingCancelled] = useState(false);
  const { isLogged } = useContext(authContext);
  const [fetchError, setFetchError] = useState("");

  const [cookie, setCookie] = useCookies(["redirectPath"]);

  const navigate = useNavigate();

  const calendarRef = useRef(null);
  const classes = useStyle();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  /**
   * Fetches the suggested time slots, ensuring they start after the current day
   */
  useEffect(() => {
    checkSlots(new Date(new Date().getTime() + refetch * 24 * 60 * 60 * 1000));
  }, [meetingData, refetch]);
  /**
   * Opens the response dialog for the
   * ONE_NONSKEDING_ONE_SKEDING_USER, ONE_NONSKEDING_MULTIPLE_SKEDING_USERS and RECURRING_EVENT cases
   */
  useEffect(() => {
    if (meetingData?.case === ONE_NONSKEDING_ONE_SKEDING_USER) {
      if (
        (meetingData?.start_datetime && meetingData?.end_datetime) ||
        !meetingData?.is_active
      ) {
        setModal(true);
        setOpen(false);
      }
    } else if (meetingData?.case === ONE_NONSKEDING_MULTIPLE_SKEDING_USERS) {
      if (
        (meetingData?.start_datetime && meetingData?.end_datetime) ||
        !meetingData?.is_active ||
        meetingData?.non_skeding_participants_not_responded.length === 0
      ) {
        setModal(true);
        setOpen(false);
      }
    } else if (
      meetingData?.case === RECURRING_EVENT ||
      meetingData?.case === SINGLE_SLOT_MEETING
    ) {
      if (
        (!meetingData.non_skeding_participants_not_responded.includes(
          meetingData?.token?.participantEmail
        ) &&
          meetingData?.non_skeding_participants.includes(
            meetingData?.token?.participantEmail
          )) ||
        !meetingData?.is_active
      ) {
        setModal(true);
        setOpen(false);
      }
    }
  }, [meetingData]);

  /**
   * Gets the meeting information once the meetingId and token are passed in the search parameters
   */
  useEffect(() => {
    if (meetingId && token) {
      getMeeting();
    }
  }, [meetingId, token]);

  /**
   * Defaults the selectedSlot to the proposed time slot if there is only 1 suggested time slot
   */
  useEffect(() => {
    if (meetingData?.proposed_timeslots?.length === 1) {
      setSelectedSlot([
        {
          originalIndex: 0,
          value: {
            start: meetingData?.proposed_timeslots[0]?.startTime
              ? new Date(meetingData?.proposed_timeslots[0].startTime)
              : new Date(meetingData?.proposed_timeslots[0].start),
            end: meetingData?.proposed_timeslots[0]?.endTime
              ? new Date(meetingData?.proposed_timeslots[0].endTime)
              : new Date(meetingData?.proposed_timeslots[0].end),
          },
        },
      ]);
    }
  }, [meetingData]);

  /**
   * Verifies that the logged in user is authorized to view the meeting details
   */
  useEffect(() => {
    if (meetingData) {
      const token = localStorage.getItem("token");
      try {
        if (token) {
          let decodedToken = jwt_decode(token);
          if (
            decodedToken?.email ===
              meetingData?.token?.participantEmail?.toLowerCase() ||
            decodedToken?.email === meetingData?.token?.email?.toLowerCase()
          ) {
            setIsUserMatch(true);
          }
        }
      } catch (e) {
        loggingInDev(e);
      }
      return;
    }
  }, [meetingData]);

  /**
   * Sets the redirect path for after the user logs in
   */
  useEffect(() => {
    checkStatus();
  }, [meetingData]);

  useEffect(() => {
    if (meetingCancelled) setFetchError(t("snackbar_messages.6"));
  }, [meetingCancelled]);

  const handleClose = () => {
    setModal(false);
  };

  const handleSuccessClose = () => {
    setSuccessModal(false);
    navigate("/dashboard?userSignup=true&isCalendar=true");
  };

  const handleNoMatchClose = () => {
    setNoMatchModal(false);
  };

  /**
   * Checks the meeting suggested time slots against the current date to only show valid start times
   * @param {Date} startDate - the current date
   */
  const checkSlots = async (startDate) => {
    if (meetingData) {
      if (
        meetingData?.proposed_timeslots?.length < 1 &&
        ![
          MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS,
          ONE_SKEDING_MULTIPLE_NONSKEDING_USERS,
          RECURRING_EVENT,
          SINGLE_SLOT_MEETING,
        ].includes(meetingData.case)
      ) {
        loggingInDev("inside suggesstion");
        const data = {
          current_date: convertDateToUTCMilliseconds(startDate),
          duration_minutes: meetingData.duration_minutes,
          participants: meetingData.non_skeding_participants,
        };
        const response = await serverGETPOSTWithToken(
          "/meeting/suggestions",
          token,

          data
        );
        if (response && response.constructor !== String) {
          const parseResponse = await response?.data?.allAvailbleSlots.map(
            (item) => {
              return {
                start: convertUTCMillisecondsToDate(item.startTime),
                end: convertUTCMillisecondsToDate(item.endTime),
              };
            }
          );
          setEvents([...events, ...parseResponse]);
          if (response?.data?.allAvailbleSlots?.length + events.length < 3) {
            setRefetch((prev) => prev + 7);
          }
          setNoMatchModal(true);
          loggingInDev("Slots ==>", parseResponse);
        } else {
          setError(response.data?.message);
        }
      }
    }
  };

  const handleDownload = async (file) => {
    await downloadFileAttachment(file, meetingData?._id);
  };

  const handleManuallySchedule = () => {
    setManuallySchedule(true);
  };

  /**
   * Fetches meeting data from the backend
   */
  const getMeeting = async () => {
    const response = await serverGETWithToken(`/meeting/${meetingId}`, token, {
      params: { currentTime: convertDateToUTCMilliseconds(new Date()) },
    });
    if (
      response === "Meeting could not be found or may have been deleted" &&
      !!!meetingData
    ) {
      setMeetingCancelled(true);
      return;
    }
    if (response) {
      setMeetingData(response.data);
    } else {
      setError(response);
    }
  };
  /**
   * Sets the times selected by the user by making request to backend
   * @returns
   */
  const handleSubmit = async (notes) => {
    setDisabled(true);

    if (events.length > 0) {
      if (selectedSlot.length !== 3) {
        setErrorAmount(t("three_slot_err"));
        setDisabled(false);
        return;
      }
      const parsedSlots =
        selectedSlot &&
        selectedSlot.map((slot) => {
          return {
            startTime: convertDateToUTCMilliseconds(slot.value.start),
            endTime: convertDateToUTCMilliseconds(slot.value.end),
          };
        });

      const data = {
        proposed_timeslots: parsedSlots,
        notes: notes,
      };
      const response = await serverPUTWithToken(
        `/meeting/${meetingData._id}`,
        token,
        data
      );
      if (response && response.constructor !== String) {
        loggingInDev("update response =>", response);
        setSuccessModal(true);
        setDisabled(false);
      } else {
        loggingInDev("error =>", response);
        setError(response);
        setDisabled(false);
      }
    } else {
      if (meetingData?.case === RECURRING_EVENT) {
        let data = {
          to_cancel: false,
          notes: notes,
        };
        let response = await serverPUTWithToken(
          `/meeting/recurring/${meetingData._id}`,
          token,
          data
        );
        if (response && response.constructor !== String) {
          loggingInDev("update response =>", response);
          setSuccessModal(true);
          setDisabled(false);
        } else {
          loggingInDev("error =>", response);
          setModal(true);
          setDisabled(false);
        }
      } else if (meetingData?.case === SINGLE_SLOT_MEETING) {
        let data = {
          to_cancel: false,
          notes: notes,
        };
        let response = await serverPUTWithToken(
          `/meeting/${meetingData._id}`,
          token,
          data
        );
        if (response && response.constructor !== String) {
          loggingInDev("update response =>", response);
          setSuccessModal(true);
          setDisabled(false);
        } else {
          loggingInDev("error =>", response);
          setModal(true);
          setDisabled(false);
        }
      } else {
        const data = {
          start_datetime: convertDateToUTCMilliseconds(
            selectedSlot[0].value.start
          ),
          end_datetime: convertDateToUTCMilliseconds(selectedSlot[0].value.end),
          time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          utc_offset: new Date().getTimezoneOffset(),
          notes: notes,
        };

        const response = await serverPUTWithToken(
          `/meeting/${meetingData._id}`,
          token,
          data
        );
        if (response && response.constructor !== String) {
          loggingInDev("update response =>", response);
          setSuccessModal(true);
          setDisabled(false);
        } else {
          loggingInDev("error =>", response);
          setError(response);
          setDisabled(false);
        }
      }
    }
    // navigate("/login");
  };

  /**
   * Sets the redirect url for if a nonskeding user signs up
   */
  const checkStatus = async () => {
    if (meetingData) {
      if (
        meetingData?.case === ONE_NONSKEDING_MULTIPLE_SKEDING_USERS ||
        meetingData?.case === MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS ||
        meetingData?.case === ONE_SKEDING_MULTIPLE_NONSKEDING_USERS ||
        meetingData?.case === ONE_NONSKEDING_ONE_SKEDING_USER ||
        meetingData?.case === RECURRING_EVENT ||
        meetingData?.case === SINGLE_SLOT_MEETING
      ) {
        setCookie(
          "redirectPath",
          `${window.location.pathname}${window.location.search}&isLogged=true`,
          { path: "/", maxAge: 300 }
        );
      }
    }
  };

  const handleReject = async (comment) => {
    setDisabled(true);
    if (
      [
        ONE_NONSKEDING_MULTIPLE_SKEDING_USERS,
        ONE_NONSKEDING_ONE_SKEDING_USER,
        SINGLE_SLOT_MEETING,
      ].includes(meetingData?.case)
    ) {
      const data = {
        is_active: false,
        comment: comment.trim() ? comment : undefined,
      };
      const response = await serverPUTWithToken(
        `/meeting/${meetingData._id}`,
        token,
        data
      );
      if (response && response.constructor !== String) {
        loggingInDev("update (reject) response =>", response);
        setSuccessModal(true);
        setDisabled(false);
      } else {
        loggingInDev("error =>", response);
        setError(response);
        setDisabled(false);
      }
    } else if (meetingData?.case === RECURRING_EVENT) {
      const data = {
        to_cancel: true,
        comment: comment.trim() ? comment : undefined,
      };
      const response = await serverPUTWithToken(
        `/meeting/recurring/${meetingData._id}`,
        token,
        data
      );
      if (response && response.constructor !== String) {
        loggingInDev("update (reject) response =>", response);
        setSuccessModal(true);
        setDisabled(false);
      } else {
        loggingInDev("error =>", response);
        setError(response);
        setDisabled(false);
      }
    }
  };

  const showMsg = () => {
    if (!!fetchError) {
      return (
        <DialogBox
          modal={!!fetchError}
          handleClose={() => setFetchError("")}
          close={false}
          msg={fetchError}
        />
      );
    } else return <Spinner />;
  };

  return meetingData ? (
    isLogged && isUserMatch === false ? (
      <Typography>{t("unauthorized_err")}</Typography>
    ) : manuallySchedule ? (
      <UserNonSignup
        meetingData={meetingData}
        selectedSlot={selectedSlot}
        setSelectedSlot={setSelectedSlot}
        errorAmount={errorAmount}
        setErrorAmount={setErrorAmount}
        disabled={disabled}
        events={events}
        modal={modal}
        handleClose={handleClose}
        successModal={successModal}
        handleSuccessClose={handleSuccessClose}
        isLogged={isLogged}
        theme={theme}
        mobile={mobile}
        submit={submit}
        handleDownload={handleDownload}
        calView={calView}
        handleReject={handleReject}
        handleSubmit={handleSubmit}
        noMatchModal={noMatchModal}
        handleNoMatchClose={handleNoMatchClose}
        sharedAvailableTimes={sharedAvailableTimes}
        setSharedAvailableTimes={setSharedAvailableTimes}
      />
    ) : isLogged ? (
      <UserAgreesToSignup
        theme={theme}
        open={open}
        meetingData={meetingData}
        setSelectedSlot={setSelectedSlot}
        selectedSlot={selectedSlot}
        errorAmount={errorAmount}
        setErrorAmount={setErrorAmount}
        isLogged={isLogged}
        events={events}
        handleSubmit={handleSubmit}
        disabled={disabled}
        calView={calView}
        setCalView={setCalView}
        calendarRef={calendarRef}
        sharedAvailableTimes={sharedAvailableTimes}
        setSharedAvailableTimes={setSharedAvailableTimes}
        successModal={successModal}
        handleSuccessClose={handleSuccessClose}
        modal={modal}
        handleClose={handleClose}
        handleReject={handleReject}
      />
    ) : (
      <Box
        className={classes.main}
        alignItems="center"
        display="flex"
        flexDirection={mobile ? "column" : "row-reverse"}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          width={mobile ? "100%" : "60%"}
          mt={mobile ? "10%" : null}
        >
          {mobile ? (
            <>
              <Box display="flex" alignItems="center">
                <SkedingLogo height={"90%"} style={{ paddingRight: "0" }} />
              </Box>
              <Typography
                align="center"
                mt="20px"
                className={classes.welcomeMsg}
              >
                {t("welcome_skeding")}
              </Typography>
            </>
          ) : null}
          <div>
            <Player
              ref={ref} // set the ref to your class instance
              autoplay={true}
              loop={true}
              controls={true}
              src={Animate}
              speed={1}
              style={{
                width: "100%",
                marginTop: "30px",
                height: mobile ? "21vh" : null,
              }}
            ></Player>
          </div>
          {mobile ? null : (
            <Box
              display="flex"
              flexDirection="column"
              rowGap="8px"
              mt="5%"
              style={{ width: "80%" }}
            >
              <Typography
                sx={{ fontSize: "28px", fontWeight: "500", color: "#fff" }}
              >
                {t("welcome_desc")}
              </Typography>
              <Typography
                color={theme.palette.text.disabled}
                variant="h3"
                style={{ color: "#fff" }}
              >
                {t("welcome_desc2")} <br /> {t("welcome_desc3")}
              </Typography>
            </Box>
          )}
        </Box>
        <Box
          className={mobile ? classes.mobileWrapper : classes.desktopWrapper}
        >
          {!mobile && (
            <Box className={classes.bubbleWrapper}>
              <Bubbles
                style={{
                  height: "105vh",
                }}
              />
            </Box>
          )}
          {mobile ? null : (
            <>
              <Box display="flex" alignItems="center">
                <SkedingLogo height={"90%"} style={{ paddingRight: "0" }} />
              </Box>
              <Typography
                sx={{
                  color: theme.palette.secondary.dark,
                }}
                className={classes.welcomeMsg}
                align="center"
                // mt="10px"
              >
                {t("welcome_skeding")}
              </Typography>
            </>
          )}
          <Box sx={{ mt: "20px", width: "50%" }}>
            <Typography
              variant="h3"
              sx={{ fontWeight: 500, color: "#70757A" }}
              align="center"
            >
              {t("signup_msg")}
            </Typography>
          </Box>
          <Box sx={{ mt: "14px" }}>
            <ExtLogins login={false} />
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <Divider sx={{ width: "40%" }}>
              <div>{t("or")}</div>
            </Divider>
          </Box>
          <Button
            variant="contained"
            onClick={handleManuallySchedule}
            style={{ padding: "8px 36px" }}
          >
            {t("manual")}
          </Button>
        </Box>
      </Box>
    )
  ) : (
    showMsg()
  );
}

export function ExtLogins({ isLoginPage }) {
  const { t } = useTranslation();
  const googleLogin = async () => {
    loggingInDev("Google login!");
    window.open(process.env.REACT_APP_GOOGLE_CALLBACK, "_self");
  };

  const outlookLogin = async () => {
    loggingInDev("Outlook login!");
    window.open(process.env.REACT_APP_OUTLOOK_CALLBACK, "_self");
  };
  const appleLogin = async () => {
    loggingInDev("Apple login!");
    window.open(process.env.REACT_APP_APPLE_CALLBACK, "_self");
  };

  return (
    <Box display="flex" justifyContent="center">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "230px",
        }}
      >
        <LogoButton
          login={googleLogin}
          logo={<GoogleLogo />}
          text="Google"
          isLoginPage={isLoginPage}
          t={t}
        />
        <LogoButton
          login={outlookLogin}
          logo={<MicrosoftLogo />}
          text="Microsoft"
          isLoginPage={isLoginPage}
          t={t}
        />
        <LogoButton
          login={appleLogin}
          logo={<AppleLogo />}
          text="Apple"
          isLoginPage={isLoginPage}
          t={t}
        />
      </Box>
    </Box>
  );
}

function LogoButton({ login, logo, text, isLoginPage, t }) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    if (!isLoginPage) setOpen(true);
    else login();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dialogDescription = [
    `Skeding never sees or stores your ${text} password.`,
    "privacy_msg",
    "privacy_msg2",
  ];

  return (
    <>
      <Button
        onClick={handleClickOpen}
        sx={{
          width: "100%",
          background: "#FFFFFF",
          marginBottom: "10px",
          "&:hover": {
            background: "#FFFFFF",
          },
          boxShadow: "0 2px 4px 0 rgb(0 0 0 / 25%)",
          padding: "6px 18px",
        }}
      >
        <Box
          style={{
            display: "flex",
            // justifyContent: "center",
            justifyContent: "space-between",
            height: "40px",
            alignItems: "center",
            columnGap: "24px",
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: "18px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {logo}
          </Box>
          <Box
            sx={{
              width: "150px",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Typography fontSize="14px" fontWeight="500">
              {/* {console.log("login: ", isLoginPage)} */}
              {isLoginPage
                ? `${t("signin_with")} ${text}`
                : `${t("signup_with")} ${text}`}
            </Typography>
          </Box>
        </Box>
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            padding: "20px",
            margin: "20px",
          },
        }}
        maxWidth="sm"
      >
        <IconButton
          aria-label="close"
          style={{
            justifyContent: "right",
            borderRadius: "0px",
          }}
          disableRipple="true"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          style={{
            paddingLeft: mobile ? "0px" : "20px",
            paddingRight: mobile ? "0px" : "20px",
          }}
        >
          <DialogTitle
            style={{
              padding: "0px",
              fontSize: "20px",
              fontWeight: "500",
            }}
          >
            {`${t("privacy_title")} ${text} ${t("account")}`}
          </DialogTitle>
          <List sx={{ listStyleType: "disc", p: "16px 24px 16px 42px" }}>
            {dialogDescription.map((desc) => {
              return (
                <ListItemText
                  sx={{ display: "list-item", fontSize: "20px" }}
                  primary={
                    <Typography
                      variant="h3"
                      fontWeight={400}
                      lineHeight={"160%"}
                    >
                      {t(desc)}
                    </Typography>
                  }
                />
              );
            })}
          </List>
          <Typography
            id="alert-dialog-description"
            variant="h3"
            fontWeight={400}
          >
            {t("permission_msg")}
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            paddingBottom: "20px",
          }}
        >
          <Button
            onClick={login}
            autoFocus
            variant="contained"
            style={{
              padding: "8px 36px",
            }}
          >
            {t("proceed")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
