import React, { useContext } from "react";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { serverGET, serverPOST, serverPUT } from "../../Utils/HttpFunctions";
import { accountContext } from "../../context/user";
import { useNavigate, useSearchParams } from "react-router-dom";
import useMembersStore from "../../Utils/membersStore";

export default function CancelReasonDialog({
  closeReasonDialog,
  setSuccess,
  team,
  membership,
  dataToSend,
}: {
  closeReasonDialog: () => void;
  setSuccess: React.Dispatch<SetStateAction<string>>;
  // only required for team
  team?: boolean;
  membership?: any;
  dataToSend?: any;
}) {
  const { t } = useTranslation();
  const [deleteReason, setDeleteReason] = useState(t("cancel_reaon_1"));
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const { getUser } = useContext(accountContext) as any;
  const setMembershipData = useMembersStore((state) => state.setMembershipData);
  const navigate = useNavigate();
  const deleteReasons = [
    t("cancel_reaon_1"),
    t("cancel_reaon_2"),
    t("cancel_reaon_3"),
  ];

  const handleReasonSelect = (e) => {
    setDeleteReason(e.target.value);
  };

  const handleCancelClick = async () => {
    const response = await serverPOST("/account/cancel-subscription");
    if (response && response.constructor !== String) {
      // setRenewSubscription(true);
      setSuccess("Your premium plan has been cancelled");
      navigate("/payment-services");
      setTimeout(() => {
        getUser();
      }, 4000);
    } else {
      setSuccess("Something went wrong while cancelling your plan");
    }
    closeReasonDialog();
  };

  const handleTeamCancel = async () => {
    const response = await serverPUT(
      `/account/cancelseats/${membership?._id}`,
      {
        users: dataToSend.users,
        quantity: dataToSend.remaining.checked
          ? dataToSend.remaining.quantity
          : 0,
      }
    );
    if (response && response.constructor !== String) {
      // setRenewSubscription(true);
      setSuccess("Your subscriptions have been cancelled");
      if (response.data.cancel) {
        const response = await serverGET(
          "/account/availableseats",
          undefined,
          undefined
        );
        if (response && response.constructor !== String) {
          setMembershipData(response.data);
        }
        setTimeout(() => {
          getUser();
        }, 4000);
      }
    } else {
      setSuccess("Something went wrong while cancelling your plan");
    }
    closeReasonDialog();
  };

  console.log("datu", dataToSend);
  return (
    <Grid container direction={"column"} p={2}>
      <Typography variant="h2">{t("cancel_subscription")}</Typography>
      <Typography variant="h3" my={2} fontWeight={400}>
        {t("cancel_desc")}
      </Typography>

      <Typography variant="h3">{t("cancel_subscription_title")}</Typography>

      <FormControl sx={{ width: "100%", marginTop: "12px" }}>
        <InputLabel
          id="select-reason-label"
          style={{
            fontSize: "16px",
            fontWeight: 400,
          }}
        >
          {t("select_reason_title")}
        </InputLabel>
        <Select
          id="delete-reason"
          label="select a reason"
          variant="standard"
          value={deleteReason}
          onChange={handleReasonSelect}
          style={{ fontSize: "14px" }}
        >
          {deleteReasons.map((reason, i) => {
            return (
              <MenuItem key={i} value={reason}>
                {reason}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <Grid
        item
        display="flex"
        gap={3}
        justifyContent={"center"}
        my={2}
        flexDirection={mobile ? "column" : "row"}
      >
        <Button
          variant="outlined"
          onClick={() => {
            if (team) {
              handleTeamCancel();
            } else {
              handleCancelClick();
            }
          }}
        >
          {t("confirm_cancel")}
        </Button>
        <Button variant="contained" onClick={() => closeReasonDialog()}>
          {t("stay_premium")}
        </Button>
      </Grid>
    </Grid>
  );
}
