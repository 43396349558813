import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FREE_PLAN, PREMIUM_PLAN, YEARLY } from "../../Utils/Constants";
import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import PaymentPlan from "../../components/payment/PaymentPlan";
import PlanDetails from "../../components/payment/PlanDetails";
import { LeftLayout, RightLayout } from "../../components/TwoSectionLayout";
import { accountContext } from "../../context/user";
import SnackbarComponent from "../../components/Global/SnackbarComponent";
import Toggler from "../../components/payment/Toggler";
import useStyle from "./styles";
import DialogWithCloseIcon from "../../components/DialogWithCloseIcon";
import { serverPUT } from "../../Utils/HttpFunctions";

export default function PaymentServices() {
  const { user, getUser } = useContext(accountContext) as any;
  const [selectedPlan, setSelectedPlan] = useState(() => {
    return user?.package?.paid === 1 ? PREMIUM_PLAN : FREE_PLAN;
  });
  const [success, setSuccess] = useState("");
  const { t } = useTranslation();
  const [isTeamMember, setisTeamMember] = useState(false);
  const theme = useTheme();
  const [leaveTeamDialog, setLeaveTeamDialog] = useState(false);
  const classes = useStyle();
  const [billingType, setBillingType] = useState(YEARLY);
  const [leaveTeamModal, setLeaveTeamModal] = useState("");
  const [searchParams]: any = useSearchParams();

  useEffect(() => {
    if (user) {
      setSelectedPlan(user?.package?.paid === 1 ? PREMIUM_PLAN : FREE_PLAN);
      setisTeamMember(
        user?.package?.renewal_date === null && user?.package?.paid
      );
    }
  }, [user]);

  useEffect(() => {
    if (JSON.parse(searchParams.get("success"))) {
      getUser();
      setSuccess("You have successfully subscribed!");
    }
  }, [searchParams]);

  const handleLeaveTeamDialogClose = () => {
    setLeaveTeamDialog(!leaveTeamDialog);
  };

  const handleLeaveTeam = async () => {
    setLeaveTeamDialog(!leaveTeamDialog);
    const response = await serverPUT(
      `/account/removeuser/${user.package.membership._id}`,
      {
        email: user.email,
        is_admin: false,
      }
    );
    if (response && response.constructor !== String) {
      if (response.data.updated) {
        setLeaveTeamModal(t("toast_messages.4"));
        getUser();
      }
    } else {
      console.log("error =>", response);
    }
  };

  return (
    <Grid container>
      <LeftLayout
        scrollable={undefined}
        showFull={selectedPlan === FREE_PLAN || isTeamMember ? true : false}
      >
        <Grid lg={selectedPlan === FREE_PLAN ? 8 : 12}>
          <Typography variant="h2" align="left">
            {t("payment_heading")}
          </Typography>
          {!isTeamMember ? (
            <></>
          ) : (
            <Box
              style={{
                display: "flex",
                borderRadius: "8px",
                border: `1px solid ${theme.palette.primary.main}`,
                justifyContent: "space-between",
                padding: 20,
              }}
            >
              {/* <Typography variant="h3" fontWeight={400}>
                {t("teamMembership", {
                  team: (
                    <span style={{ fontWeight: 700 }}>
                      {user.package?.membership?.team_name}
                    </span>
                  ),
                  plan: (
                    <span style={{ fontWeight: 700 }}>
                      Skeding Premium Plan
                    </span>
                  ),
                })}
              </Typography> */}
              <Typography variant="h3" fontWeight={400}>
                You are on a{" "}
                <span style={{ fontWeight: 700 }}>
                  {user.package?.membership?.team_name}
                </span>{" "}
                and currently subscribed to the{" "}
                <span style={{ fontWeight: 700 }}>Skeding Premium Plan</span>{" "}
                Premium Plan
              </Typography>
              <Typography
                variant="h3"
                color={theme.palette.primary.main}
                style={{
                  cursor: "pointer",
                }}
                onClick={handleLeaveTeamDialogClose}
              >
                {t("leave_team")}
              </Typography>
            </Box>
          )}
          {isTeamMember ? null : (
            <Toggler
              billingType={billingType}
              setBillingType={setBillingType}
            />
          )}
          <Grid container mt={1} gap={1}>
            <Grid
              item
              xs={12}
              lg={5.8}
              sx={{
                backgroundColor: "#96DCFF40",
              }}
              p={2}
              borderRadius={2}
            >
              <PaymentPlan
                planType={FREE_PLAN}
                billingType={billingType}
                selectedPlan={selectedPlan}
                isTeamMember={isTeamMember}
              />
            </Grid>
            <Grid
              item
              xs={12}
              lg={5.8}
              ml="auto"
              sx={{
                backgroundColor: "#96DCFF40",
              }}
              p={2}
              borderRadius={2}
            >
              <PaymentPlan
                planType={PREMIUM_PLAN}
                billingType={billingType}
                selectedPlan={selectedPlan}
                isTeamMember={isTeamMember}
              />
            </Grid>
          </Grid>
        </Grid>
      </LeftLayout>
      {selectedPlan == FREE_PLAN || isTeamMember ? null : (
        <RightLayout scrollable={undefined}>
          <PlanDetails />
        </RightLayout>
      )}
      <SnackbarComponent
        open={!!success}
        handleClose={() => setSuccess("")}
        message={success}
        vertical={"bottom"}
        horizontal={"center"}
      />
      <DialogWithCloseIcon
        open={leaveTeamDialog}
        handleClose={handleLeaveTeamDialogClose}
        dialogSize={"xs"}
      >
        <Typography variant="h3">{t("leave_team_message")}</Typography>
        <Box display={"flex"} gap={4} justifyContent={"center"} mt={2}>
          <Button variant="outlined" onClick={handleLeaveTeamDialogClose}>
            {t("cancel")}
          </Button>
          <Button variant="contained" onClick={handleLeaveTeam}>
            {t("leave")}
          </Button>
        </Box>
      </DialogWithCloseIcon>
      <SnackbarComponent
        open={!!leaveTeamModal}
        handleClose={() => setLeaveTeamModal("")}
        message={leaveTeamModal}
        vertical={"bottom"}
        horizontal={"center"}
      />
    </Grid>
  );
}
