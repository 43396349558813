import { useState, useEffect, useRef } from "react";

import {
  Stack,
  Typography,
  IconButton,
  Box,
  useTheme,
  useMediaQuery,
  Button,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import moment from "moment";

import {
  ALL_SKEDING_USERS,
  NEW_MEETINGS_MINI_CALENDAR,
  ONE_NONSKEDING_MULTIPLE_SKEDING_USERS,
  ONE_NONSKEDING_ONE_SKEDING_USER,
} from "Utils/Constants";
import { convertUTCMillisecondsToDate } from "Utils/CommonFunctions";
import isDate from "validator/es/lib/isDate";
import useStyle from "../../styles/formStyles";
import RecommendedTimes from "./RecommendedTimes";
import SelectedTimes from "./SelectedTimes";
import HelpComponent from "components/Global/Help";
import { useTranslation } from "react-i18next";
import Spinner from "../Global/Spinner";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { StaticDatePicker } from "@mui/x-date-pickers";
import MeetingLabels from "./MeetingLabels";
import { TimeslotPreferences } from "./TimeslotPreferences";
import Datepicker from "../Global/Datepicker";

/**
 * Component that renders the recommended and selected times
 */
export default function ShowTimes({
  events,
  eventCase,
  selectedSlot,
  setSelectedSlot,
  errorAmount,
  setErrorAmount,
  meetingCreated,
  qrMeeting,
  dateObject,
  setSharedAvailableTimes: setTimes,
  sharedAvailableTimes: times,
  appendSlots,
  months,
  formDate,
  eventCal,
  setEventCal,
  setFormDate,
  meetingType,
  isPopup,
  createMeeting,
  duration,
  participants,
  participantLabels,
  allUnavailableSlots,
}) {
  const [error, setError] = useState("");
  const [noTimesError, setNoTimesError] = useState(false);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [scrollToElem, setScrollToElem] = useState(null);
  const { t } = useTranslation();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const selectRef = useRef(null);
  const classes = useStyle();
  const start = new Date(formDate);

  useEffect(() => {
    const convertSlots = async () => {
      if (eventCase === "NonSignUpOneSelect") {
        const timeArr =
          events &&
          events.map((e) => {
            return {
              start: convertUTCMillisecondsToDate(e.startTime),
              end: convertUTCMillisecondsToDate(e.endTime),
            };
          });
        setTimes(timeArr);
      } else {
        setTimes(events);
      }
    };
    convertSlots();
  }, [events]);

  useEffect(() => {
    if (meetingType) {
      if (selectedSlot?.length > 1) {
        setSelectedSlot([]);
        setEventCal([]);
        setError(t("recur_err"));
      }
    }
  }, [meetingType]);

  // useEffect(() => {
  //   setSelectedSlot(selectedSlot);

  //   //working fine but no need in popup

  //   // if (selectRef.current) {
  //   //   setTimeout(() => {
  //   //     selectRef.current?.scrollIntoView({
  //   //       behavior: "smooth",
  //   //       block: "end",
  //   //     });
  //   //   }, 0);
  //   // }
  // }, [selectedSlot]);

  useEffect(() => {
    if (meetingCreated) setSelectedSlot("");
  }, [meetingCreated]);

  /**
   * Sets the date to move the calendar to
   * @param {object} newValue - date object from the DesktopDatePicker component
   * @async
   */
  const handleDateChange = async (newValue) => {
    const time = newValue.toDate();
    const momentTime = moment(newValue);
    console.log(momentTime, moment(), moment().isSame(momentTime, "day"));
    if (isDate(time)) {
      // grabbing first and last event start to detect scroll
      const eventStartDate =
        events.length > 0 && moment(events[0]?.start).startOf("day");
      const eventEndDate =
        events.length > 0 &&
        moment(events[events.length - 1]?.start).startOf("day");

      // if user returns back then current hours, minute should be changed
      if (
        moment().isSame(momentTime, "day") &&
        moment().isSame(momentTime, "month") &&
        moment().isSame(momentTime, "year")
      ) {
        const currentDate = moment();
        time.setHours(currentDate.hour());
        time.setMinutes(currentDate.minute());
        time.setSeconds(currentDate.second());
      } else {
        // otherwise start from 0, 0
        time.setHours(0);
        time.setMinutes(0);
        time.setSeconds(0);
        time.setMilliseconds(0);
      }

      if (momentTime.isBetween(eventStartDate, eventEndDate, "[]")) {
        handleScrollToDate(momentTime);
      } else {
        appendSlots(time);
        handleScrollToDate(null);
      }
    }
  };
  /**
   * Moves recommended times to selected times array
   * @param {number} index - index of the time to be moved
   * @returns
   */
  const handleMoveRecommended = (index) => {
    setError("");

    setErrorAmount("");
    if (meetingType) {
      if (selectedSlot?.length < 1) {
        const selectedTimeSlot = times[index];
        const selectedToAdd = {
          originalIndex: index,
          value: selectedTimeSlot,
        };
        const tempSelected = [...selectedSlot, selectedToAdd];
        const tempAll = [...times];
        tempAll.splice(index, 1);
        setSelectedSlot(tempSelected);

        setTimes(tempAll);
      } else {
        setError(t("single_slot_err"));
      }
      return;
    }
    if (
      (eventCase === ALL_SKEDING_USERS ||
        (eventCase === ONE_NONSKEDING_ONE_SKEDING_USER && qrMeeting)) &&
      selectedSlot?.length < 1
    ) {
      const selectedTimeSlot = times[index];
      const selectedToAdd = {
        originalIndex: index,
        value: selectedTimeSlot,
      };
      const tempSelected = [...selectedSlot, selectedToAdd];
      const tempAll = [...times];
      tempAll.splice(index, 1);
      setSelectedSlot(tempSelected);

      setTimes(tempAll);
    } else if (
      ((eventCase === ONE_NONSKEDING_ONE_SKEDING_USER && !qrMeeting) ||
        eventCase === ONE_NONSKEDING_MULTIPLE_SKEDING_USERS) &&
      selectedSlot?.length < 5
    ) {
      const selectedTimeSlot = times[index];
      const selectedToAdd = {
        originalIndex: index,
        value: selectedTimeSlot,
      };
      const tempSelected = [...selectedSlot, selectedToAdd];
      const tempAll = [...times];
      tempAll.splice(index, 1);
      setSelectedSlot(tempSelected);
      setTimes(tempAll);
    } else if (eventCase === "NonSignUp" && selectedSlot?.length < 3) {
      const selectedTimeSlot = times[index];
      const selectedToAdd = {
        originalIndex: index,
        value: selectedTimeSlot,
      };
      const tempSelected = [...selectedSlot, selectedToAdd];
      const tempAll = [...times];
      tempAll.splice(index, 1);
      setSelectedSlot(tempSelected);
      setTimes(tempAll);
    } else if (eventCase === "NonSignUpOneSelect" && selectedSlot?.length < 1) {
      const selectedTimeSlot = times[index];
      const selectedToAdd = {
        originalIndex: index,
        value: selectedTimeSlot,
      };
      const tempSelected = [...selectedSlot, selectedToAdd];
      const tempAll = [...times];
      tempAll.splice(index, 1);
      setSelectedSlot(tempSelected);
      setTimes(tempAll);
    } else {
      if (
        eventCase === ALL_SKEDING_USERS ||
        (eventCase === ONE_NONSKEDING_ONE_SKEDING_USER && qrMeeting)
      ) {
        setError(t("single_slot_err"));
      } else if (
        eventCase === ONE_NONSKEDING_ONE_SKEDING_USER ||
        eventCase === ONE_NONSKEDING_MULTIPLE_SKEDING_USERS
      ) {
        setError(t("five_slot_err"));
      } else if (eventCase === "NonSignUpOneSelect") {
        setError(t("single_slot_err"));
      } else if (eventCase === "NonSignUp") {
        setError(t("three_slot_err"));
      }
    }
  };

  /**
   * Moves selected time back to recommended
   * @param {number} index - index of the time to be moved
   */
  const handleRemoveClick = async (index) => {
    setError("");
    setErrorAmount("");
    const tempSelected = [...selectedSlot];
    const specificSlot = tempSelected[index];
    const tempAllSlots = [...times];
    tempSelected.splice(index, 1);
    //if slot is selected from chip section
    if (specificSlot.originalIndex || specificSlot.originalIndex === 0) {
      tempAllSlots.splice(specificSlot.originalIndex, 0, specificSlot.value);
      setTimes(tempAllSlots);
    }
    //if slot is from calendar
    else {
      const tempCalSlots = [...eventCal];
      const eventToPop = await tempCalSlots.findIndex(
        (event) =>
          event.start === specificSlot.value.start &&
          event.end === specificSlot.value.end
      );
      tempCalSlots.splice(eventToPop, 1);
      setEventCal(tempCalSlots);
    }
    setSelectedSlot(tempSelected);
  };

  /**
   * Moves the calendar to the newDate value
   * @param {object} newDate - date object from the DesktopDatePicker component
   */
  const handleScrollToDate = (newDate) => {
    if (newDate === null) {
      setScrollToElem(null);
    } else {
      const startDate = newDate.$d.getDate();
      setScrollToElem(
        document.querySelector(`[data-startdate=${CSS.escape(startDate)}]`)
      );
    }
  };

  const handleLeftClick = () => {
    let copy = new Date(formDate);
    let val = moment(copy).subtract(7, "days");
    setFormDate(val);
    handleDateChange(val);
  };

  const handleRightClick = () => {
    let copy = new Date(formDate);
    const val = moment(copy).add(7, "days");
    setFormDate(val);
    handleDateChange(val);
  };

  return (
    <Stack spacing="10px">
      <Box display="flex" alignItems="center" flexWrap="wrap">
        <Typography
          variant={mobile ? "h3" : isPopup ? "h3" : "h2"}
          align="left"
          sx={{
            display:
              eventCase === "NonSignUpOneSelect" || eventCase === "NonSignUp"
                ? // qrMeeting
                  "none"
                : "block",
            fontWeight: isPopup ? 500 : 500,
          }}
        >
          {t("suggested_title")}
        </Typography>
        {["/qrmeeting", "/newmeeting"].includes(window.location.pathname) ||
          (isPopup && (
            <>
              <Button
                variant="text"
                style={{
                  width: "140px",
                  marginLeft: 6,
                  margin: "0px 0px 0px 2px",
                  padding: 0,
                }}
                disableRipple={true}
              >
                <Datepicker
                  open={calendarOpen}
                  onChange={(newValue) => {
                    setFormDate(newValue);
                    handleDateChange(newValue);
                  }}
                  toggler={() => setCalendarOpen((prev) => !prev)}
                  value={formDate}
                />
              </Button>
              <Box>
                <HelpComponent
                  msg={NEW_MEETINGS_MINI_CALENDAR}
                  position={"bottom"}
                />
              </Box>
            </>
          ))}
        {noTimesError ? (
          <Box display="flex" alignItems="center" columnGap="5px">
            <ErrorOutline fontSize="small" sx={{ color: "red" }} />
            <Typography
              variant="h4"
              align="left"
              sx={{
                fontWeight: 400,
                color: "red",
              }}
            >
              {t("no_slot_err")}
            </Typography>
          </Box>
        ) : null}
      </Box>

      <Typography
        variant="h4"
        align="left"
        sx={{
          display:
            eventCase === "NonSignUpOneSelect" ||
            eventCase === "NonSignUp" ||
            qrMeeting
              ? "none"
              : "block",
          fontWeight: 400,
          color: theme.palette.info.light,
          marginTop: "-3px !important",
        }}
      >
        {eventCase === ALL_SKEDING_USERS ||
        (eventCase === ONE_NONSKEDING_ONE_SKEDING_USER && qrMeeting) ? (
          <>{t("select_single_slot")}</>
        ) : (
          <>{t("select_five_slot")}</>
        )}
      </Typography>
      {error && !errorAmount && (
        <Typography style={{ color: "red" }} variant="body2" align="left">
          {error}
        </Typography>
      )}
      {errorAmount && (
        <Typography
          style={{ color: "red", marginTop: 2 }}
          variant="body2"
          align="left"
        >
          {errorAmount}
        </Typography>
      )}

      {["/qrmeeting", "/newmeeting"].includes(window.location.pathname) ||
        (isPopup && (
          <>
            <MeetingLabels />
            <Box display="flex" justifyContent={"center"} alignItems="center">
              <IconButton
                onClick={() => handleLeftClick()}
                disabled={moment(start).isSameOrBefore(moment(), "day")}
              >
                <ChevronLeftIcon
                  style={{
                    color: moment(start).isSameOrBefore(moment(), "day")
                      ? theme.palette.info.light
                      : theme.palette.text.primary,
                  }}
                />
              </IconButton>
              <Box display="flex" justifyContent={"center"} alignItems="center">
                {moment(start).format("MM/DD/YYYY") +
                  " - " +
                  moment(start).add(7, "days").format("MM/DD/YYYY")}
              </Box>
              <IconButton onClick={() => handleRightClick()}>
                <ChevronRightIcon
                  style={{ color: theme.palette.text.primary }}
                />
              </IconButton>
            </Box>
          </>
        ))}

      <RecommendedTimes
        handleMoveRecommended={(e, index, bgColor) =>
          handleMoveRecommended(index, bgColor)
        }
        times={times}
        setNoTimesError={setNoTimesError}
        formDate={formDate}
        dateObject={dateObject}
        months={months}
        appendSlots={appendSlots}
        scrollToElem={scrollToElem}
        eventCase={eventCase}
        createMeeting={createMeeting}
      />
      {/* previously only shown for no slots */}
      {/* {(times.length > 0 && !populateEvents) || !isPopup ? (
        <></>
      ) : (
        <TimeslotPreferences participants={participants} duration={duration} />
      )} */}
      {isPopup ? (
        <TimeslotPreferences
          participants={participants}
          participantLabels={participantLabels}
          duration={duration}
          t={t}
          allUnavailableSlots={allUnavailableSlots}
        />
      ) : null}

      <Box mt={2} />
      {selectedSlot?.length > 0 ? (
        <Typography
          variant={isPopup ? "h3" : "h2"}
          align="left"
          sx={{
            fontWeight: 500,
          }}
        >
          {t("selected_times")}
        </Typography>
      ) : null}
      <SelectedTimes
        selectedSlot={selectedSlot}
        setSelectedSlot={setSelectedSlot}
        handleRemoveClick={handleRemoveClick}
        eventCase={eventCase}
        selectRef={selectRef}
        createMeeting={createMeeting}
      />
    </Stack>
  );
}

export const CheckLabel = ({ checked, label, onChange, desc }) => {
  const theme = useTheme();
  return (
    <FormControlLabel
      name=""
      control={
        <Checkbox
          size="small"
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
          sx={{
            color: theme.palette.text.primary,
            transform: "scale(0.8)",
            "&.Mui-checked": {
              color: "#F7F7F9",
              stroke: theme.palette.text.primary,
              strokeWidth: "1.5px",
              strokeLineJoin: "round",
            },
          }}
        />
      }
      label={
        <>
          <Typography variant="h3" fontWeight="500">
            {label}
          </Typography>
          <Typography variant="h4" fontWeight="400">
            {desc}
          </Typography>
        </>
      }
    />
  );
};
