import makeStyles from "@mui/styles/makeStyles";
const useStyle = makeStyles((theme) => ({
  addIcon: {
    cursor: "pointer",
    width: 16,
    height: 16,
  },
  radio: {
    color: "#479AE9",
    paddingRight: "0px",
    paddingLeft: "0px",
    justifyContent: "top",
    "& .MuiSvgIcon-root": {
      fontSize: "14px",
    },
  },
  themeText: {
    marginLeft: 10,
    minWidth: "68px",
    textAlign: "left",
  },
  formControl: {
    width: "100%",
    marginLeft: "8px",
  },
  accordion: {
    background: "none",
    width: "95%",
    padding: "0",

    "&:before": {
      display: "none",
    },
  },

  repeatedWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    minWidth: "100%",

    [theme.breakpoints.up("sm")]: {
      minWidth: 60,
    },
  },

  checkbox: {
    color: theme.palette.text.primary,
    "&.Mui-checked": {
      color: "#F2F3FF",
      stroke: theme.palette.text.primary,
      strokeWidth: "2px",
      strokeLineJoin: "round",
    },
  },
}));

export default useStyle;
