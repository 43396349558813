import { useState } from "react";
import { Button, Grid, Typography, useTheme, Snackbar } from "@mui/material";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useTranslation } from "react-i18next";

export default function Duration({ event }) {
  const { t } = useTranslation();
  const theme = useTheme();

  var eventStart = event.start.getTime();
  var timeSet = true;

  if (Number.isNaN(eventStart)) {
    timeSet = false;
  }

  return (
    <Grid container pt="16px" pb="8px" alignItems="center">
      <Grid item xs={1} md={0.7} container>
        <AccessTimeIcon
          style={{ width: "14px", color: theme.palette.text.primary }}
        />
      </Grid>
      <Grid
        item
        container
        xs={11}
        flexDirection={"column"}
        alignItems="flex-start"
      >
        {timeSet ? (
          <>
            <Grid item style={{ color: theme.palette.text.primary }}>
              <Typography variant="h3" fontWeight="400">
                {event.start.toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}{" "}
                to{" "}
                {event.end.toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}{" "}
                ({event.duration} minutes){" "}
              </Typography>
            </Grid>
            <Grid item style={{ color: theme.palette.text.primary }}>
              <Typography variant="h3" fontWeight="400">
                {event.start.toLocaleDateString(undefined, {
                  weekday: "long",
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                })}
              </Typography>
            </Grid>
          </>
        ) : (
          <Grid item style={{ color: theme.palette.text.primary }}>
            <Typography variant="h3" fontWeight="400">
              {t("date_time_pending")}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
