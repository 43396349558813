import React from "react";
import { Button, Dialog, DialogActions, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

/**
 *
 * @param {any} handleClose //closes popup
 * @param {boolean} modal //popup open indicator
 * @param {string} msg
 * @param {boolean} close //to show close message + indicate to close popup
 * @param {string} closeMsg
 * @param {boolean} nonsignup true then naviagte to register else dashboard
 * @returns
 */
export default function DialogBox({
  handleClose,
  modal,
  msg,
  closeMsg,
  close,
  nonsignup,
}) {
  const { t } = useTranslation();

  const navigate = useNavigate();
  return (
    <Dialog
      open={modal}
      onClose={
        close
          ? handleClose
          : nonsignup
          ? () => navigate("/register")
          : () => navigate("/dashboard")
      }
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"xs"}
      PaperProps={{ style: { padding: "24px", borderRadius: "16px" } }}
    >
      <Typography variant="h3" fontWeight={500} align="center">
        {msg}
      </Typography>{" "}
      <DialogActions
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: 8,
          paddingBottom: "0px",
        }}
      >
        <Button
          onClick={
            close
              ? handleClose
              : nonsignup
              ? () => navigate("/register")
              : () => navigate("/dashboard")
          }
          style={{
            backgroundColor: "transparent",
            border: `1px solid #363B53`,
            color: "#363B53",
          }}
          variant="outlined"
        >
          {closeMsg ? closeMsg : t("close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
