import React, { useState, useEffect, useRef, useContext } from "react";
import styles from "styles/styles.module.css";
import { Draggable } from "@fullcalendar/interaction";

import {
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
  Button,
  Box,
  useTheme,
  useMediaQuery,
  Divider,
} from "@mui/material";
import { serverPOST, serverPUT } from "Utils/HttpFunctions";
import moment from "moment";
import { useTranslation } from "react-i18next";
import useEventsStore from "../../Utils/eventStore";
import useStyle from "./styles";
import ToolTip from "../Global/ToolTip";

function Todos() {
  const { calendarTasks, setCalendarTasks } = useEventsStore();
  const [taskName, setTaskName] = useState("");
  const [editId, setEditId] = useState(-1);
  const inpRef = useRef(null);
  const elRef = useRef(null);
  const todoRef = useRef(null);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();
  const classes = useStyle();
  useEffect(() => {
    let draggable = new Draggable(elRef.current, {
      itemSelector: ".fc-event",
      eventData: function (eventEl) {
        let title = eventEl.getAttribute("title");
        let duration = eventEl.getAttribute("duration");
        let id = eventEl.getAttribute("data");
        let start = eventEl.getAttribute("start");
        return {
          title: title,
          id: id,
          duration:
            duration === "15"
              ? "00:15"
              : duration === "30"
              ? "00:30"
              : duration === "45"
              ? "00:45"
              : "1:00",
          bgColor: "#B0E4FF",
          meetingColor: "#B0E4FF",
          borderColor: "#fff",
          create: false,
        };
      },
    });
    return () => draggable.destroy();
  }, []);

  const addTodo = async () => {
    const title = `Task Name`;
    const response = await serverPOST(`/todo`, {
      title: title,
      duration_minutes: 60,
    });
    if (response && response.constructor !== String) {
      const newTodo = {
        id: response.data._id,
        title: response.data.title,
        duration: response.data.duration_minutes,
        is_done: false,
        bgColor: "#B0E4FF",
        meetingColor: "#B0E4FF",
        borderColor: "#fff",
      };
      setCalendarTasks([newTodo, ...calendarTasks]);
    }
  };

  const handleTodoDuration = async (e, event) => {
    const tds = [...calendarTasks];
    const todosDuration = await tds?.map((el) =>
      el.id === event.id ? { ...el, duration: e.target.value } : el
    );
    setCalendarTasks(todosDuration);
    updateTodo(event.id, {
      duration_minutes: e.target.value,
    });
  };

  const updateTodo = async (eventId, body) => {
    const response = await serverPUT(`/todo/${eventId}`, body);
    if (response && response.constructor !== String) {
      console.log("todo updated");
    }
  };

  const handleRadioClick = async (todo) => {
    const temp = [...calendarTasks];
    const obj = temp.find((task) => task.id === todo.id);

    obj.is_done = true;
    const response = await serverPUT(`/todo/${todo.id}`, {
      is_done: true,
    });
    if (response && response.constructor !== String) {
      const index = temp.indexOf(obj);
      temp.splice(index, 1);
      setCalendarTasks(temp);
    }
  };

  const handleTextFieldBlur = (id) => {
    const temp = [...calendarTasks];
    const obj = temp.find((task) => task.id === id);
    obj.title = taskName;
    setEditId(-1);
    if (taskName.trim() === "") {
      setTaskName("");
      obj.title = "Task name";
      updateTodo(id, { title: "Task name" });
      setCalendarTasks(temp);
      return;
    }
    setTaskName("");
    updateTodo(id, { title: taskName });
    setCalendarTasks(temp);
  };
  const handleKeyDown = (e, id) => {
    if (e.keyCode == 13) {
      handleTextFieldBlur(id);
    }
  };
  return (
    <>
      <Box pt={1} style={{ order: mobile ? 1 : 2 }}>
        <Box
          display={"flex"}
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Typography variant="h3" align="left">
            {t("todo_list")}
          </Typography>
          <Button
            disableRipple
            onClick={addTodo}
            style={{
              padding: mobile ? 0 : "auto",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                color: theme.palette.primary.main,
              }}
            >
              {t("add_task")}
            </Typography>
          </Button>
        </Box>
        {!mobile && calendarTasks && calendarTasks.length > 0 ? (
          <Typography
            variant="body1"
            align="left"
            style={{ color: "#70757A", paddingBottom: "8px" }}
          >
            {t("todo_desc")}
          </Typography>
        ) : (
          <Divider />
        )}

        <Box
          display="flex"
          flexDirection={"column"}
          justifyContent={"space-between"}
          alignItems="flex-start"
          className={`${styles.upper} ${classes.todoHeight}`}
          ref={elRef}
        >
          <Box
            display="flex"
            justifyContent="flex-start"
            style={{ width: "100%" }}
          >
            <Box style={{ width: "100%" }}>
              {calendarTasks.length > 0 &&
                calendarTasks.map((event) => (
                  <div
                    className="fc-event"
                    style={{
                      cursor: "move",
                      marginTop: "12px",
                    }}
                    title={event.title}
                    data={event.id}
                    duration={event.duration}
                    start={event.start}
                    end={event.end}
                    key={event.id}
                  >
                    <Box display="flex" className={classes.calTask}>
                      <ToolTip title="Mark as done">
                        <Box
                          style={{
                            backgroundColor: event.is_done ? "#479AE9" : "#fff",
                          }}
                          className={classes.markRead}
                          onClick={() => handleRadioClick(event)}
                        />
                      </ToolTip>
                      <Box style={{ width: "100%" }}>
                        {editId && editId === event.id ? (
                          <Box ref={todoRef}>
                            <TextField
                              placeholder={event.title}
                              variant="standard"
                              onClick={() => inpRef.current?.focus()}
                              value={taskName}
                              onBlur={() => handleTextFieldBlur(event.id)}
                              sx={{ width: "100%" }}
                              onKeyDown={(e) => handleKeyDown(e, event.id)}
                              InputProps={{
                                disableUnderline: true,
                                style: {
                                  color: theme.palette.text.primary,
                                  width: "90%",
                                },
                              }}
                              onChange={(e) => setTaskName(e.target.value)}
                              inputRef={(input) => input && input.focus()}
                            />
                          </Box>
                        ) : (
                          <Typography
                            variant="h3"
                            align="left"
                            fontWeight={400}
                            className={classes.ellipsesText}
                            onClick={() => {
                              setEditId(event.id);
                              setTaskName(event.title);
                            }}
                          >
                            {event.title}
                          </Typography>
                        )}

                        <Box style={{ textAlign: "left" }}>
                          {event.start && event.end ? (
                            <>
                              <Typography
                                variant="h4"
                                align="left"
                                fontWeight="400"
                                style={{ marginTop: 4 }}
                              >
                                {moment(event?.start).format("LT")} to{" "}
                                {moment(event?.end).format("LT")} (
                                {moment(event?.end).diff(
                                  event?.start,
                                  "minutes"
                                )}{" "}
                                minutes)
                              </Typography>
                              <Typography
                                variant="h4"
                                align="left"
                                fontWeight="400"
                                // style={{ marginTop: 1 }}
                              >
                                {moment(event?.start).format(
                                  "dddd, MMMM DD, YYYY"
                                )}
                              </Typography>
                            </>
                          ) : (
                            <FormControl
                              variant="standard"
                              sx={{ minWidth: "80px" }}
                            >
                              <Select
                                labelId="time-interval-label"
                                id="event-time-interval"
                                style={{ fontSize: 10, fontWeight: 400 }}
                                defaultValue={60}
                                value={event.duration}
                                onChange={(e) => handleTodoDuration(e, event)}
                              >
                                <MenuItem value={15}>15 minutes</MenuItem>
                                <MenuItem value={30}>30 minutes</MenuItem>
                                <MenuItem value={45}>45 minutes</MenuItem>
                                <MenuItem value={60}>
                                  1 hour (60 minutes)
                                </MenuItem>
                              </Select>
                            </FormControl>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </div>
                ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default React.memo(Todos);
