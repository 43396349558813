import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { AddButtonWithIcon } from "./Services";
import { useTranslation } from "react-i18next";
import DragHandleOutlinedIcon from "@mui/icons-material/DragHandleOutlined";
import useWebsiteStore from "../../Utils/websiteStore";

export function NotificationContainer({
  trigger,
  handleEdit,
  // setOpenEmailTemplate,
  handleDelete,
}) {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();
  const [deleteModal, setDeleteModal] = useState(false);

  const setEmailTemplateType = useWebsiteStore(
    (state) => state.setEmailTemplateType
  );
  const setSelectedEmailTemplate = useWebsiteStore(
    (state) => state.setSelectedEmailTemplate
  );
  const toggleExpanded = () => {
    setExpanded(!expanded);
  };
  const handleDeleteClose = () => {
    setDeleteModal(false);
  };

  return (
    <Box>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          border: "1px solid #DAD2E5",
          borderRadius: 6,
          padding: 12,
          textAlign: "left",
        }}
      >
        <Box>
          <Typography variant="h3" fontWeight={400}>
            {trigger.trigger_title}
          </Typography>
          <Typography
            variant="h4"
            fontWeight={400}
            color={"#70757A"}
            mt={0.5}
            onClick={() => {}}
          >
            {trigger.trigger_title}
          </Typography>
        </Box>

        <Box
          onClick={toggleExpanded}
          style={{
            cursor: "pointer",
          }}
        >
          {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </Box>
      </Box>
      <Collapse in={expanded}>
        <div>
          {trigger.types.map((t) => {
            return (
              <Box
                style={{
                  display: "flex",
                  gap: 6,
                  alignItems: "center",
                }}
              >
                <DragHandleOutlinedIcon
                  style={{
                    color: "#DAD2E5",
                  }}
                />

                <Box
                  style={{
                    width: "100%",
                    margin: 8,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    border: "1px solid #DAD2E5",
                    borderRadius: 6,
                    padding: 12,
                    textAlign: "left",
                  }}
                >
                  <Typography variant="h3" fontWeight={400}>
                    {t.title}
                  </Typography>
                  <Box>
                    <Box
                      style={{
                        display: "flex",
                      }}
                    >
                      <EditOutlinedIcon
                        fontSize="small"
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleEdit();
                          setSelectedEmailTemplate(t);
                        }}
                      />
                      <DeleteOutlineRoundedIcon
                        fontSize="small"
                        style={{
                          color: "red",
                          cursor: "pointer",
                        }}
                        onClick={() => setDeleteModal(true)}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            );
          })}
        </div>
        {trigger.types.length === 0 && (
          <AddButtonWithIcon
            handleClick={() => {
              setEmailTemplateType(trigger.trigger_title);
            }}
            label={t("add_email")}
          />
        )}

        {deleteModal && (
          <Dialog
            open={deleteModal}
            onClose={handleDeleteClose}
            maxWidth={"xs"}
            fullWidth
            PaperProps={{ sx: { padding: 3, boxSizing: "border-box" } }}
          >
            <DialogTitle
              id="alert-dialog-title"
              style={{ textAlign: "center" }}
            >
              <Typography variant="h3">
                {t("Are you sure you want to delete this notification?")}
              </Typography>
            </DialogTitle>
            <DialogActions
              sx={{
                display: "flex",
                justifyContent: "center",
                columnGap: "10px",
              }}
            >
              <Button
                onClick={async () => {
                  await handleDelete(trigger.trigger_title);
                  setDeleteModal(false);
                }}
                variant="outlined"
              >
                {t("del")}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Collapse>
    </Box>
  );
}
