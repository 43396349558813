import React, { useState, useEffect, useContext } from "react";
import { authContext } from "./Auth";
import { serverGET, serverPUT } from "Utils/HttpFunctions";
import loggingInDev from "loggingInDev";
import useEventsStore from "../Utils/eventStore";
import { accountContext } from "./user";
import { useMemo } from "react";
import useDelegatedAcess from "../Utils/SwtichAccount";
const { io } = require("socket.io-client");

export const notificationContext = React.createContext();

export default function NotificationProvider({ children }) {
  const [socket, setSocket] = useState(null);
  const [notifications, setNotifications] = useState();
  const [showBell, setShowBell] = useState(false);
  const [notificationReceived, setNotificationReceived] = useState(false);
  const { isLogged } = useContext(authContext);
  const { getPlannedEvents } = useEventsStore();
  const { user, getUser } = useContext(accountContext);
  const setMembers = useDelegatedAcess((state) => state.setMembers);
  const delegatedMode = useDelegatedAcess((state) => state.delegatedMode);
  const setDelegatedMode = useDelegatedAcess((state) => state.setDelegatedMode);

  useEffect(() => {
    if (isLogged) {
      const s = io(`${process.env.REACT_APP_SOCKET_URL}`);
      setSocket(s);
      return () => {
        s.disconnect();
      };
    }
  }, [isLogged]);

  useEffect(() => {
    const abortController = new AbortController();
    if (socket === null) return;
    socket.on("connect", () => {
      socket.emit("sendingUserId", user?.id);
      let socketTopic = socket.id + "-newNotifications";
      socket.on(socketTopic, (data) => {
        loggingInDev("data from socket ==> ", data);
        setShowBell(true);
        setNotificationReceived(true);
        // getNotifications();
        getPlannedEvents(abortController);
      });

      let memberTopic = socket.id + "-memberNotification";
      socket.on(memberTopic, (data) => {
        console.log("🚀 ~ file: Notification.js:51 ~ socket.on ~ data:", data);
        // scheduler changed back to member
        setMembers([]);
        if (delegatedMode) {
          localStorage.removeItem("delegateToken");
          setDelegatedMode(null);
          window.location.reload();
        }
      });

      let cancelTeamTopic = socket.id + "-cancelTeamNotification";
      socket.on(cancelTeamTopic, async (data) => {
        // scheduler changed back to member
        await getUser();
        if (delegatedMode) {
          localStorage.removeItem("delegateToken");
          setDelegatedMode(null);
          window.location.reload();
        }
      });
    });
    return () => {
      abortController.abort();
    };
  }, [socket]);

  const value = useMemo(
    () => ({
      socket,
      setSocket,
      notifications,
      setNotifications,
      showBell,
      setShowBell,
      notificationReceived,
      setNotificationReceived,
    }),
    [user]
  );
  return (
    <notificationContext.Provider value={value}>
      {children}
    </notificationContext.Provider>
  );
}
