import React from "react";

import { Dialog } from "@mui/material";

import { CreateContactDialogInterface } from "../../interfaces";
import NewContactForm from "./NewContactForm";

/**
 * Renders a dialog box for creating a contact in the mobile view of the contact page
 */
export default function CreateContactDialog({
  createContactDialog,
  theme,
  mobile,
  getContacts,
  handleCreateContactDialog,
  setAddContact,
}: CreateContactDialogInterface) {
  return (
    <Dialog
      open={createContactDialog}
      onClose={handleCreateContactDialog}
      PaperProps={{
        sx: {
          p: "28px 20px",
          borderRadius: "8px",
          width: mobile ? "75%" : "25%",
          margin: "0px",
        },
      }}
    >
      <NewContactForm
        theme={theme}
        getContacts={getContacts}
        createContactDialog={createContactDialog}
        handleCreateContactDialog={handleCreateContactDialog}
      />
    </Dialog>
  );
}
