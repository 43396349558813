import { useContext, useState } from "react";
import PPUploadDialog from "./PPUploadDialog";
import UserName from "./UserName";
import CongratsDialog from "./CongratsDialog";
import IntegrateCalendar from "./IntegrateCalendar";
import SetupPreferences from "./SetupPreferences";
import CreateMeetingTour from "./CreateMeetingTour";
import { tourContext } from "../../context/Tour";
import { useTranslation } from "react-i18next";
import DialogWithCloseIcon from "components/DialogWithCloseIcon";
import FreeTrialDialog from "components/payment/FreeTrialDialog";
import { Button, DialogActions } from "@mui/material";

export default function SignupDialogs({ isCalendar }) {
  const [userNameDialog, setUserNameDialog] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [pictureDialog, setPictureDialog] = useState(false);
  const [congratsDialog, setCongratsDialog] = useState(true);
  const [integrateCalendar, setIntegrateCalendar] = useState(false);
  const [tourDialog, setTourDialog] = useState(false);
  const [trialDialog, setTrialDialog] = useState(false);

  const { t } = useTranslation();

  const handleClose = () => {
    setTourDialog(false);
    setIntegrateCalendar(true);
  };

  const handleNext = () => {
    setTrialDialog(false);
    if (isCalendar) {
      setIntegrateCalendar(true);
    } else {
      setUserNameDialog(true);
    }
  };

  return (
    <>
      <CongratsDialog
        congratsDialog={congratsDialog}
        setCongratsDialog={setCongratsDialog}
        isCalendar={isCalendar}
        setTrialDialog={setTrialDialog}
        setIntegrateCalendar={setIntegrateCalendar}
      />
      <DialogWithCloseIcon
        open={trialDialog}
        handleClose={() => setTrialDialog(false)}
        dialogSize={"sm"}
      >
        <FreeTrialDialog
          dialogTitle={t("Your 15-Day Free Trial Has Begun!")}
          dialogDesc={t(
            "Enjoy free access to premium features for the next 15 days. So dive in and continue to utilize our features"
          )}
          hideBtn={true}
        />
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button onClick={handleNext} variant="contained">
            {t("cont")}
          </Button>
        </DialogActions>
      </DialogWithCloseIcon>
      {!isCalendar && (
        <>
          <UserName
            userNameDialog={userNameDialog}
            setUserNameDialog={setUserNameDialog}
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            setPictureDialog={setPictureDialog}
          />
          <PPUploadDialog
            pictureDialog={pictureDialog}
            setPictureDialog={setPictureDialog}
            firstName={firstName}
            lastName={lastName}
            setIntegrateCalendar={setIntegrateCalendar}
            setUserNameDialog={setUserNameDialog}
            setTourDialog={setTourDialog}
          />
        </>
      )}
      <CreateMeetingTour tourDialog={tourDialog} handleClose={handleClose} />
      <IntegrateCalendar
        integrateCalendar={integrateCalendar}
        setIntegrateCalendar={setIntegrateCalendar}
        setPictureDialog={setPictureDialog}
        msg={!isCalendar ? t("integrate_cal") : t("set_avail")}
      />
    </>
  );
}
