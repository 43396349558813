import {
  Typography,
  Box,
  Chip,
  Button,
  useMediaQuery,
  Grid,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { GoLocation } from "react-icons/go";

import { ReactComponent as AttachmentIcon } from "assets/attachment.svg";
import { ReactComponent as PeopleIcon } from "assets/people.svg";
import { ReactComponent as AgendaIcon } from "assets/agenda.svg";
import { ReactComponent as DownloadIcon } from "assets/download.svg";
import {
  MEETING_THROUGH_WEB_INTEGRATION_CALENDAR,
  RECURRING_EVENT,
} from "../../Utils/Constants";
import scrollbar from "styles/scrollBar.module.css";
import RecurringTag from "../Global/RecurringTag";
import { useStyles } from "styles/participantChip";
import { useTranslation } from "react-i18next";
import Attachement from "../Global/Attachement";
import Invitee from "../meetings/Invitee";
import ToolTip from "../Global/ToolTip";

/**
 * Renders the details of a meeting
 */
export default function MeetingDetailsComponent({
  theme,
  meetingData,
  fullWidth,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  const particpantsLength =
    meetingData?.skeding_participants?.length +
    meetingData?.non_skeding_participants?.length;
  return (
    <Box rowGap={"8px"}>
      <Typography
        variant="h3"
        align="left"
        sx={{ textTransform: "capitalize" }}
        mt={2}
        mb={1}
      >
        {meetingData?.title}
      </Typography>

      <Box display="flex" alignItems="baseline" columnGap="3px">
        <Typography variant="h3" fontWeight="400">
          {meetingData?.initiator_firstname +
            " " +
            meetingData?.initiator_lastname}{" "}
        </Typography>
        <Typography
          variant="h4"
          fontWeight="400"
          color={theme.palette.text.disabled}
        >
          {t("organizer")}
        </Typography>
        {/* <Typography variant={"body1"} fontWeight={400} style={{ marginTop: 4 }}>
            {meetingData?.initiator_email}
          </Typography> */}
      </Box>
      {meetingData?.case === RECURRING_EVENT && <RecurringTag />}

      <ContentContainer>
        <AccessTimeIcon
          style={{ color: theme.palette.text.primary, width: "16px" }}
        />
        <Typography variant={"h3"} fontWeight={400} style={{ marginLeft: 10 }}>
          {meetingData?.duration_minutes} minutes
        </Typography>
      </ContentContainer>
      {meetingData?.description === "No Description" ? null : (
        <ContentContainer>
          <AgendaIcon
            style={{ color: theme.palette.text.primary, minWidth: 16 }}
          />

          <Typography
            variant={"h3"}
            fontWeight={400}
            style={{ marginLeft: 10 }}
          >
            {meetingData?.description}
          </Typography>
        </ContentContainer>
      )}
      <Box
        sx={{
          display:
            meetingData?.attachment_file_name.length > 0 ? "block" : "none",
        }}
      >
        <ContentContainer align={true}>
          <AttachmentIcon
            style={{
              color: theme.palette.text.primary,
              width: "16px",
            }}
          />
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 8,
            }}
          >
            {meetingData?.attachment_file_name.map((file) => {
              return (
                <Attachement filename={file} meetingId={meetingData._id} />
              );
            })}
          </Box>
        </ContentContainer>
      </Box>
      <ContentContainer>
        <GoLocation
          style={{
            color: theme.palette.text.primary,
            minWidth: "16px",
            height: "14px",
          }}
        />
        <Grid display={"block"}>
          <Typography
            variant="h3"
            fontWeight="400"
            sx={{
              marginLeft: "10px",
              overflow: "auto",
              wordBreak: "break-word",
              textAlign: "left",
            }}
            mr={2}
          >
            {meetingData?.location?.includes("https") ? (
              <a
                href={meetingData?.location}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#479AE9" }}
              >
                {meetingData?.location}
              </a>
            ) : (
              meetingData?.location
            )}
          </Typography>
          <Typography
            style={{
              marginLeft: "10px",

              fontSize: "12px",
              textAlign: "left",
            }}
          >
            {meetingData?.meeting_room &&
              `(${t("room_no")} ` + meetingData?.meeting_room + ")"}
          </Typography>
        </Grid>
      </ContentContainer>
      {meetingData?.case === MEETING_THROUGH_WEB_INTEGRATION_CALENDAR ? (
        fullWidth ? (
          <Invitee event={meetingData} />
        ) : (
          <ContentContainer>
            <Invitee event={meetingData} />
          </ContentContainer>
        )
      ) : (
        <ContentContainer>
          <PeopleIcon
            style={{
              color: theme.palette.text.primary,
              width: "16px",
              height: "14px",
            }}
          />
          <div
            style={{
              overflow: "auto",
              display: "flex",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              flexWrap: "nowrap",
              textAlign: "start",
              marginRight: 10,
            }}
            className={scrollbar.root}
          >
            {meetingData?.non_skeding_participants.map((participant, index) => {
              return (
                <ToolTip title={participant} placement="top" key={index}>
                  <Chip
                    key={index}
                    variant="filled"
                    label={participant}
                    className={
                      particpantsLength > 1 ? classes.chip : classes.fullChip
                    }
                  />
                </ToolTip>
              );
            })}
            {meetingData?.skeding_participants.map((participant, index) => {
              return (
                <ToolTip title={participant.email} placement="top" key={index}>
                  <Chip
                    key={index}
                    variant="filled"
                    label={participant.email}
                    className={
                      particpantsLength > 1 ? classes.chip : classes.fullChip
                    }
                  />
                </ToolTip>
              );
            })}
          </div>
        </ContentContainer>
      )}
    </Box>
  );
}

export function ContentContainer({ children, align }) {
  return (
    <Box
      mt={2}
      display="flex"
      alignItems={align ? "none" : "center"}
      justifyContent="flex-start"
    >
      {children}
    </Box>
  );
}
