import React from "react";
import { create } from "zustand";
import { serverGET } from "Utils/HttpFunctions";
import loggingInDev from "loggingInDev";
import { GET_ALL_USER_MEETINGS_PATH } from "Utils/ApiPaths";
import {
  convertUTCMillisecondsToDate,
  checkLocation,
} from "Utils/CommonFunctions";
import { checkIfAllDay, findColor } from "./CommonFunctions";

const useEventsStore = create((set) => ({
  plannedEvents: [],
  setPlannedEvents: (events) => set({ plannedEvents: events }),

  draftEvents: [],
  setDraftEvents: (events) => set({ draftEvents: events }),

  loadingPlannedEvents: false,
  calendarTasks: [],
  setCalendarTasks: (tasks) => set({ calendarTasks: tasks }),

  getTodos: async (abortController) => {
    try {
      let response;
      if (abortController) {
        response = await serverGET("/todo", abortController.signal);
      } else {
        response = await serverGET("/todo");
      }
      if (response && response.constructor !== String) {
        const formattedTodos = [...response.data.todoTasks];

        let temp = formattedTodos.map((event) => {
          return {
            id: event._id,
            title: event.title,
            start: event.start_datetime
              ? convertUTCMillisecondsToDate(event.start_datetime)
              : undefined,
            end: event.end_datetime
              ? convertUTCMillisecondsToDate(event.end_datetime)
              : undefined,
            duration: event.duration_minutes,
            bgColor: "#B0E4FF",
            meetingColor: "#B0E4FF",
            borderColor: "#fff",
            is_done: event.is_done,
            display: "block",
          };
        });
        set({ calendarTasks: temp });
      }
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Request was canceled:", error.message);
      }
    }
  },

  getPlannedEvents: async (abortController) => {
    set({ loadingPlannedEvents: true });
    try {
      const response = await serverGET(
        GET_ALL_USER_MEETINGS_PATH,
        abortController.signal
      );

      if (response && response.constructor !== String) {
        const formattedEvents = [...response.data];
        loggingInDev("formattedEvents ===> ", formattedEvents);
        let tempPlannedEvents = formattedEvents.map((event) => {
          let bgColor = event?.meeting_type?.bgColor;

          // for previous meetings where we dont have meetin type attr
          if (!bgColor) {
            bgColor = findColor(
              event.case,
              [],
              []
              // event.skeding_participants,
              // event.non_skeding_participants,
              // event.theme
            );
          }

          return {
            id: event.id,
            title: event.title,
            start: convertUTCMillisecondsToDate(event.start_datetime),
            end: convertUTCMillisecondsToDate(event.end_datetime),
            description: event.description,
            case: event.case,
            location: event.location,
            skedingParticipants: event.skeding_participants,
            nonskedingParticipants: event.non_skeding_participants,
            totalAccepted: event.total_accepted,
            nonskedingParticipantPollingStatus:
              event.non_skeding_participants_polling_status,
            totalResponded: event.total_responded,
            fileName: event.attachment_file_name,
            durationMinutes: event.duration_minutes,
            nonskedingParticipantsNotResponded:
              event?.non_skeding_participants_not_responded,
            editable: false,
            initiator: event?.initiator,
            initiatorEmail: event?.initiator_email,
            non_skeding_participant_data: event?.non_skeding_participant_data,
            allDay: checkIfAllDay(event.start_datetime, event.end_datetime),
            // backgroundColor: bgColor,
            bgColor: bgColor,
            meetingColor: event?.meeting_type?.color
              ? event.meeting_type.color
              : bgColor,
            recurringId: event?.recurring_event_id,
            borderColor: "#fff",
            borderRadius: "4px",
            // only for single slot meeting case
            nonSkedingParticipantsRejected:
              event?.non_skeding_participants_rejected,
            transcript_summary: event?.transcript_summary,
            google_event_id: event?.google_event_id,
            meeting_notes: event?.meeting_notes,
          };
        });

        // loggingInDev("tempPlannedEvents ===> ", tempPlannedEvents);
        set({ plannedEvents: tempPlannedEvents });
      }
      set({ loadingPlannedEvents: false });
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Request was canceled:", error.message);
      }
    }
  },

  getDraftEvents: async () => {
    try {
      const response = await serverGET("/draft");

      if (response && response.constructor !== String) {
        const formattedEvents = [...response.data];
        loggingInDev("formattedEvents ===> ", formattedEvents);
        let tempDraftEvents = formattedEvents.map((event) => {
          let bgColor = event?.meeting_type?.bgColor;

          if (!bgColor) {
            bgColor = findColor(undefined, undefined, undefined, true);
          }
          return {
            id: event._id,
            title: event.title,
            start: convertUTCMillisecondsToDate(event.start_datetime),
            // end: convertUTCMillisecondsToDate(event.end_datetime),
            description: event.description,
            case: event.case,
            location: checkLocation(event.location),
            locationName: event.location_name,
            timeInterval: event.time_interval,
            participants: event.participants,
            durationMinutes: event.duration_minutes,
            editable: false,
            eventType: "draft",
            room: event.meeting_room,
            bgColor: bgColor,
            meetingColor: event?.meeting_type?.color
              ? event.meeting_type.color
              : bgColor,
          };
        });

        set({ draftEvents: tempDraftEvents });
      }
      set({ loadingPlannedEvents: false });
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Request was canceled:", error.message);
      }
    }
  },

  filteredData: [],
  setFilteredData: (events) => set({ filteredData: events }),

  optionsSelected: {
    title: "",
    duration: [],
    case: [],
    participants: [],
    date: null,
    endDate: null,
  },
  setOptionsSelected: (events) => set({ optionsSelected: events }),
}));

export default useEventsStore;
