import { useState } from "react";

import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material";

import { serverPOST } from "Utils/HttpFunctions";
import loggingInDev from "loggingInDev";
import SnackbarComponent from "components/Global/SnackbarComponent";
import { useTranslation } from "react-i18next";

/**
 * Dialog for sending a reminder to a meeting participant
 */
export default function ReminderDialog({
  reminderDialog,
  setReminderDialog,
  participantEmail,
  meetingId,
}) {
  const [reminderSuccess, setReminderSuccess] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const { t } = useTranslation();
  const handleClose = () => {
    setReminderDialog(false);
  };

  /**
   * Makes a request to the backend API to send a reminder email for the selected participant
   * @async
   */
  const handleProceed = async () => {
    setDisabled(true);
    const response = await serverPOST(`/meeting/reminder`, {
      meeting_id: meetingId,
      participant_email: participantEmail,
    });

    if (response && response.constructor !== String) {
      setReminderDialog(false);
      setReminderSuccess(true);
      loggingInDev("accepted response =>", response);
      setDisabled(false);
    } else {
      loggingInDev("error =>", response);
      setDisabled(false);
    }
  };

  return (
    <>
      <Dialog
        open={reminderDialog}
        onClose={handleClose}
        aria-labelledby="alert-reminder-dialog"
        aria-describedby="alert-send-reminder-to-pending"
        maxWidth={"xs"}
        PaperProps={{
          style: { padding: "25px 0px 25px 0px", borderRadius: "16px" },
        }}
      >
        <DialogTitle
          style={{
            textAlign: "center",
            fontSize: 20,
            paddingBottom: 4,
            fontWeight: 600,
          }}
        >
          {t("send_reminder")}
        </DialogTitle>
        <DialogTitle
          style={{ textAlign: "center", paddingTop: 0, fontWeight: 400 }}
        >
          <Typography variant="h3"> {t("remind_confirm")}</Typography>
        </DialogTitle>
        <DialogActions style={{ display: "flex", justifyContent: "center" }}>
          <Button onClick={handleClose} sx={{ mr: "20px" }} variant="outlined">
            {t("cancel")}
          </Button>
          <Button variant="submit" onClick={handleProceed} disabled={disabled}>
            {disabled ? (
              <CircularProgress style={{ color: "white" }} size={20} />
            ) : (
              t("proceed")
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <SnackbarComponent
        open={reminderSuccess}
        handleClose={() => setReminderSuccess(false)}
        message={t("reminder_success")}
        vertical={"bottom"}
        horizontal={"center"}
      />
    </>
  );
}
