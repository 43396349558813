import { Box, Typography } from "@mui/material";
import Pastel from "components/EventTheme/Pastel";
import Vibrant from "components/EventTheme/Vibrant";
import ScrollButton from "components/ScrollButton";
import { useTranslation } from "react-i18next";
import NewTheme from "../EventTheme/NewTheme";

/**
 * Renders the header for the calendar on the My Calendar page
 */
export default function CalendarHeader({
  calendarRef,
  mobile,
  user,
  callback,
  title,
}) {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const dateObj = new Date(title);
  const month = dateObj.toLocaleString(
    currentLanguage === "en" ? "default" : "fr-FR",
    { month: "long" }
  );
  const year = dateObj.getFullYear();

  let calendarTitle = `${month}, ${year}`;

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mt="15px"
      mb="15px"
    >
      <Typography variant="h3">{calendarTitle}</Typography>

      {!mobile && (
        <Box display="flex" gap={1}>
          {/* {user && user.event_theme === 0 ? <Pastel /> : <Vibrant />} */}
          <NewTheme />
        </Box>
      )}
      {!mobile && (
        <Box display="flex" columnGap="6px">
          <ScrollButton
            direction="prev"
            calendarRef={calendarRef}
            callback={callback}
            homePage={true}
            background={"#F7F7F9"}
          />
          <ScrollButton
            direction="next"
            calendarRef={calendarRef}
            callback={callback}
            homePage={true}
            background={"#F7F7F9"}
          />
        </Box>
      )}
    </Box>
  );
}
