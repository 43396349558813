import { IconButton, useTheme } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

/**
 * The Button components use to scroll through a user's calendar
 */
export default function ScrollButton({
  direction,
  calendarRef,
  callback,
  regetParticipant,
  homePage,
  background
}) {
  const theme = useTheme();

  /**
   * Moves the calendar view forward or backwards depending on the value of the 
   * direction prop passed to the component
   */
  const handleClick = () => {
    switch (direction === "prev") {
      case true:
        calendarRef?.current?.getApi()?.prev();
        break;
      case false:
        calendarRef?.current?.getApi()?.next();
        break;
    }

    callback();
    if (!homePage) {
      regetParticipant();
    }
  };

  return (
    <IconButton 
      onClick={handleClick} 
      sx={{ 
        padding: "2px",
        "&:hover": {
          borderRadius: "6px",
          backgroundColor: "inherit"
        }
      }}
    >
      {direction === "prev" ? (
        <ChevronLeftIcon
          fontSize="large"
          sx={{ 
            color: theme.palette.text.primary,
            backgroundColor: background ? background : null,
            borderRadius: "6px",
            width: "24px",
            height: "24px",
          }}
        />
      ) : (
        <ChevronRightIcon
          fontSize="large"
          sx={{ 
            color: theme.palette.text.primary,
            backgroundColor: background ? background : null,
            borderRadius: "6px",
            width: "24px",
            height: "24px"
          }}
        />
      )}
    </IconButton>
  );
}
