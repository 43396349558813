import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export default function CancelSeatsDialog() {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography variant="h2">{t("cancel_seats_title")}</Typography>
      <Typography variant="h4">{t("cancel_seats_desc")}</Typography>
    </Box>
  );
}
