import React, { useState, useEffect, useContext } from "react";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Stack,
  Grid,
  Box,
  Button,
  DialogActions,
  IconButton,
  CircularProgress,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  useTheme,
} from "@mui/material";
import { GoLocation } from "react-icons/go";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import CloseIcon from "@mui/icons-material/Close";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import moment from "moment";

import {
  checkLocation,
  checkUrl,
  downloadFileAttachment,
} from "Utils/CommonFunctions";
import {
  MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS,
  ONE_NONSKEDING_MULTIPLE_SKEDING_USERS,
  ONE_NONSKEDING_ONE_SKEDING_USER,
  ONE_SKEDING_MULTIPLE_NONSKEDING_USERS,
  RECURRING_EVENT,
  SEARCH_EVENT,
  OUTLOOK_CALENDAR_EVENT,
  GOOGLE_CALENDAR_EVENT,
} from "Utils/Constants";
import { ReactComponent as CardImage } from "assets/card.svg";
import { integrationContext } from "context/Integrations";
import loggingInDev from "loggingInDev";
import MeetingInvitesButtons from "../meetings/meeting buttons/MeetingInvitesButtons";
import Comment from "../meetings/Comment";
import { useTranslation } from "react-i18next";
import { SINGLE_SLOT_MEETING } from "../../Utils/Constants";
import { ContentContainer } from "../NewEvent/MeetingDetailsComponent";
import UploadTranscript from "./UploadTranscript";
import useStyle from "./styles";
import Attachement from "../Global/Attachement";
import RecurringTag from "../Global/RecurringTag";
import SkedingParticipants from "../Global/Participants";
import { serverPOST } from "../../Utils/HttpFunctions";
import { CgNotes } from "react-icons/cg";
import DialogWithCloseIcon from "../DialogWithCloseIcon";
import SendNotes from "./SendNotes";
/**
 * A dialogue box for a given user meeting, rendered by the Calendar component
 */
function MeetingDetails({
  isOpen,
  onClose,
  source,
  event,
  handleCancelClick,
  confirmPopup,
  onConfirmClose,
  handleConfirmClick,
  disabled,
  cancelTodo,
  cancelAll,
  setCancelAll,
  markTodo,
  isMeetingInvite,
  setMeetingResponded,
}) {
  const [participants, setParticipants] = useState([]);
  const [comment, setComment] = useState("");
  const [location, setLocation] = useState(null);
  const [msg, setMsg] = useState("Remove");
  const { t } = useTranslation();
  const [attachmentData, setAttachmentData] = useState(null);
  const classes = useStyle();
  const [sendNotes, setSendNotes] = useState(false);

  const theme = useTheme();
  const { user } = useContext(integrationContext);

  useEffect(() => {
    if (event?.google_event_id) {
      const fetchTranscript = async () => {
        const response = await serverPOST("/meeting/getattachment", {
          eventId: event?.google_event_id,
        });
        if (response && response.constructor !== String) {
          const data = response.data.attachment_data;
          setAttachmentData(data);
        }
      };
      fetchTranscript();
    }
  }, [event]);

  useEffect(() => {
    populateParticipants(event);
    handleRenderLocation(event?.location);
  }, [event]);
  /**
   * Sets the participants to be displayed with their invitation status
   * @param {object} event - meeting object that contains all the meeting participants
   */
  const populateParticipants = async (event) => {
    let tempParticipants = [];
    let tempNonSkeding = [];

    if (event && event.skedingParticipants) {
      const skedingParticipants = event.skedingParticipants;
      tempParticipants = skedingParticipants.map((participant) => {
        return {
          is_invitation_accepted:
            participant.is_invitation_accepted === 1
              ? 1
              : participant.is_invitation_accepted === 0
              ? 0
              : -1,
          email: participant?.email ? participant.email : participant.user_id,
        };
      });
      loggingInDev("tempParticipants ===> ", tempParticipants);
    }
    if (event && event.nonskedingParticipants) {
      tempNonSkeding =
        event &&
        event?.nonskedingParticipants?.map((participant, index) => {
          return {
            is_invitation_accepted: findStatus(participant, event, index),
            email: participant,
          };
        });
    }

    setParticipants(tempParticipants.concat(tempNonSkeding));
  };

  /**
   * Determines the response status of a nonskeding participant to a meeting invitation
   * @param {string} email - email of the participant
   * @param {object} event - the meeting object for which the participant's status is being checked
   * @param {number} index - the index of the participant whose status is being evaluated
   * @returns
   */
  const findStatus = (email, event, index) => {
    return event?.case == ONE_NONSKEDING_MULTIPLE_SKEDING_USERS
      ? event?.start && event?.nonskedingParticipantsNotResponded.length === 1
        ? 1
        : 0
      : event?.case == ONE_NONSKEDING_ONE_SKEDING_USER
      ? event?.start
        ? 1
        : 0
      : event?.case == MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS ||
        event?.case == ONE_SKEDING_MULTIPLE_NONSKEDING_USERS
      ? event?.nonskedingParticipantPollingStatus[index] === 1
        ? 1
        : event?.nonskedingParticipantPollingStatus[index] === 0
        ? 0
        : -1
      : event?.case == SINGLE_SLOT_MEETING &&
        !event?.nonskedingParticipantsNotResponded?.includes(email)
      ? event?.nonSkedingParticipantsRejected?.includes(email)
        ? 0
        : 1
      : event?.nonskedingParticipantsNotResponded?.includes(email)
      ? -1
      : 1;
  };

  /**
   * Sets the meeting location to a clickable link if it's a virtual meeting
   * If meeting isn't virtual sets it to a text string
   * @param {string} location
   */
  const handleRenderLocation = (location) => {
    if (location !== undefined || location !== null) {
      if (location?.search(/http/g) !== -1) {
        setLocation(
          <a
            href={location}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.link}
            style={{
              color:
                checkLocation(location) === null
                  ? theme.palette.text.primary
                  : theme.palette.primary.main,
            }}
          >
            {location}
          </a>
        );
      } else {
        setLocation(location);
      }
    }
  };

  /**
   * Opens meeting in a new window if the meeting is virtual
   */
  const handleJoin = () => {
    if (
      event?.location !== undefined &&
      event?.location !== null &&
      event?.location.search(/http/g) !== -1
    ) {
      window.open(event?.location, "_blank");
    } else {
      onClose();
    }
  };

  /**
   * Downloads an attachment to a meeting if one is provided
   */
  const handleDownload = async (filename) => {
    await downloadFileAttachment(filename, event.id);
  };

  const handleNoteClick = () => {
    setSendNotes((prev) => !prev);
  };
  return confirmPopup ? (
    // cancel popup
    <Dialog
      open={confirmPopup}
      onClose={onConfirmClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"xs"}
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "8px",
          padding: "12px",
          boxSizing: "border-box",
        },
      }}
    >
      <Box display="flex" justifyContent="flex-end">
        <IconButton onClick={onConfirmClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box>
        <Typography variant="h2" align="center" sx={{ marginBottom: "8px" }}>
          {t("meeting_cancel")}
        </Typography>
        <Typography variant="h3" align="center" fontWeight={400}>
          {event?.case === RECURRING_EVENT
            ? t("cancel_recurring_confirm")
            : t("cancel_confirm")}
        </Typography>
      </Box>

      {event?.case === RECURRING_EVENT ? (
        <Box mt={2}>
          <FormControl
            style={{
              width: "80%",
              margin: "auto",
              display: "flex",
              alignSelf: "center",
              justifySelf: "center",
              margin: "auto",
            }}
          >
            <RadioGroup
              aria-labelledby="radio-buttons-group"
              name="meeting"
              value={cancelAll}
              onChange={(e) => setCancelAll(e.target.value)}
            >
              <FormControlLabel
                value="0"
                control={<Radio style={{ color: "#479AE9" }} />}
                label={
                  <Box display="flex" alignItems="center">
                    <Typography
                      variant={"h3"}
                      fontWeight={400}
                      // style={{ marginLeft: 10 }}
                    >
                      {t("only_this_event")}
                    </Typography>
                  </Box>
                }
              />
              <FormControlLabel
                value="1"
                control={<Radio style={{ color: "#479AE9" }} />}
                label={
                  <Box display="flex" alignItems="center">
                    <Typography
                      variant={"h3"}
                      fontWeight={400}
                      // style={{ marginLeft: 10 }}
                    >
                      {t("all_events")}
                    </Typography>
                  </Box>
                }
              />
            </RadioGroup>
          </FormControl>
        </Box>
      ) : null}

      {event?.initiatorEmail === user?.email &&
      ![GOOGLE_CALENDAR_EVENT, OUTLOOK_CALENDAR_EVENT, SEARCH_EVENT].includes(
        event?.case
      ) ? (
        <Comment comment={comment} setComment={setComment} />
      ) : null}

      <DialogActions style={{ display: "flex", justifyContent: "center" }}>
        <Button
          onClick={
            source === "event"
              ? () => handleConfirmClick(comment)
              : source === "todo"
              ? cancelTodo
              : console.log("hi")
          }
          variant="contained"
          disabled={disabled}
          style={{
            fontSize: "16px",
          }}
        >
          {disabled ? (
            <CircularProgress style={{ color: "white" }} size={20} />
          ) : (
            t("proceed")
          )}
        </Button>
      </DialogActions>
    </Dialog>
  ) : source === "event" ? (
    // event details
    sendNotes ? (
      <DialogWithCloseIcon
        open={sendNotes}
        handleClose={() => setSendNotes(false)}
      >
        <SendNotes meeting={event} handleNoteClick={handleNoteClick} />
      </DialogWithCloseIcon>
    ) : (
      <Dialog
        open={isOpen}
        onClose={onClose}
        maxWidth="xs"
        fullWidth={true}
        sx={{
          "& .MuiDialog-paper": { background: "#FBFBFF", minWidth: 340 },
        }}
      >
        <DialogTitle>
          <Stack direction="column">
            <Box display="flex" justifyContent="flex-end">
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Typography variant="h3" align="left" textTransform="capitalize">
              {event?.title}
            </Typography>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack direction="column" spacing="15px">
            {event?.initiator && event?.case !== SEARCH_EVENT && (
              <Stack direction="column" spacing="2px">
                <Box display="flex" columnGap="3px" alignItems="baseline">
                  <Typography variant="h3" fontWeight="400">
                    {event?.initiator}
                  </Typography>
                  <Typography variant="h4" fontWeight={400}>
                    ( {t("organizer")})
                  </Typography>
                </Box>
              </Stack>
            )}

            {event?.non_skeding_participant_data?.participant_email && (
              // Object.keys(event?.non_skeding_participant_data).length !== 0 &&
              <Stack direction="row" spacing="15px" alignItems="flex-start">
                <CardImage
                  style={{ color: "#323232", fontSize: "16px", marginTop: 4 }}
                />
                <Typography variant="h3" fontWeight={400}>
                  {event?.non_skeding_participant_data?.participant_email}
                  <br />
                  {
                    event?.non_skeding_participant_data
                      ?.participant_phone_number
                  }
                </Typography>
              </Stack>
            )}

            {event?.case === RECURRING_EVENT && <RecurringTag />}

            <Stack direction="row" spacing="15px" alignItems="flex-start">
              <AccessTimeIcon style={{ color: "#323232", fontSize: "16px" }} />
              <Typography variant="h3" fontWeight="400">
                {moment(event?.start).format("dddd, MMMM Do YYYY")}
                <br />
                {moment(event?.start).format("LT")} to{" "}
                {moment(event?.end).format("LT")} (
                {moment(event?.end).diff(event?.start, "minutes")} minutes)
              </Typography>
            </Stack>
            {event?.description === "No Description" ? null : (
              <Stack direction="row" spacing="15px" alignItems="flex-start">
                <PlaylistAddCheckIcon
                  style={{ color: "#323232", fontSize: "16px" }}
                />
                <Typography variant="h3" fontWeight="400">
                  {event?.description}
                </Typography>
              </Stack>
            )}
            {event.fileName.length > 0 && (
              <ContentContainer align={true}>
                <AttachFileIcon
                  style={{
                    color: "#323232",
                    width: "16px",
                    marginLeft: "-3px",
                  }}
                />
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 8,
                  }}
                >
                  {event.fileName?.map((filename) => {
                    return (
                      <Attachement filename={filename} meetingId={event.id} />
                    );
                  })}
                </Box>
              </ContentContainer>
            )}

            {event.location === null ? null : (
              <Stack direction="row" spacing="19px" alignItems="flex-start">
                <GoLocation style={{ color: "#323232", fontSize: "13px" }} />
                <Typography
                  variant="h3"
                  fontWeight="400"
                  style={{
                    whiteSpace: "wrap",
                    maxWidth: "80%",
                    overflow: "auto",
                  }}
                >
                  {location}{" "}
                </Typography>
              </Stack>
            )}

            {attachmentData && (
              <Stack direction="row" spacing="19px" alignItems="flex-start">
                <CgNotes style={{ color: "#323232", fontSize: "13px" }} />
                <Typography
                  variant="h3"
                  fontWeight="400"
                  style={{
                    whiteSpace: "wrap",
                    maxWidth: "80%",
                    overflow: "auto",
                  }}
                >
                  <a
                    href={attachmentData.fileUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    {attachmentData.fileUrl}{" "}
                  </a>
                </Typography>
              </Stack>
            )}
            {participants?.length > 0 && (
              <>
                <Stack direction="row" spacing="15px" alignItems="center">
                  <PersonOutlineOutlinedIcon
                    style={{ color: "#323232", width: "16px" }}
                  />
                  <Typography variant="h3" fontWeight="400">
                    {event?.totalResponded}/
                    {event?.skedingParticipants?.length +
                      event?.nonskedingParticipants?.length}{" "}
                    {t("invitees_have_responded")}
                  </Typography>
                </Stack>
                <Grid container item xs={12} spacing="5px">
                  <SkedingParticipants
                    participants={participants}
                    total={participants.length}
                  />
                </Grid>
              </>
            )}
            <UploadTranscript
              meeting={event}
              onClose={onClose}
              t={t}
              isOrganizer={event?.initiatorEmail === user?.email}
              transcriptFile={event?.transcript_summary}
              handleDownload={handleDownload}
              handleNoteClick={handleNoteClick}
            />
          </Stack>
        </DialogContent>
        {/* transcript feature */}

        {isMeetingInvite ? (
          <Box pb="20px">
            <MeetingInvitesButtons
              event={event}
              setMeetingResponded={setMeetingResponded}
              onClose={onClose}
            />
          </Box>
        ) : (
          <>
            <Stack
              direction="row"
              spacing="20px"
              justifyContent="center"
              pb={4}
            >
              <Button onClick={handleCancelClick} variant="outlined">
                {t("cancel")}
              </Button>
              {checkUrl(event?.location) ? (
                <Button
                  variant="small-submit"
                  onClick={handleJoin}
                  style={{ minWidth: 100 }}
                >
                  {t("join")}
                </Button>
              ) : null}
            </Stack>
          </>
        )}
      </Dialog>
    )
  ) : source === "todo" ? (
    // todo popup
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="xs"
      PaperProps={{
        style: { padding: "24px", boxSizing: "border-box" },
      }}
      sx={{
        "& .MuiDialog-paper": { background: "#FBFBFF" },
      }}
    >
      <Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h3">{event?.title}</Typography>
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Stack direction="row" spacing="15px" style={{ marginTop: 8 }}>
          <AccessTimeIcon style={{ color: "#323232", width: "22px" }} />
          <Typography variant="h3" fontWeight={400}>
            {moment(event?.start).format("LT")} to{" "}
            {moment(event?.end).format("LT")} (
            {moment(event?.end).diff(event?.start, "minutes")} minutes)
            <br />
            {moment(event?.start).format("dddd, MMMM Do YYYY")}
          </Typography>
        </Stack>
        <Stack direction="row" spacing="20px" justifyContent="center" mt={3}>
          <Button
            onClick={cancelTodo}
            onMouseOver={() => setMsg("Remove from calendar")}
            onMouseLeave={() => setMsg("Remove")}
            variant="outlined"
          >
            {msg}
          </Button>
          <Button
            variant="contained"
            style={{ textTransform: "initial" }}
            onClick={() => markTodo(event)}
          >
            {t("mark_as_done")}
          </Button>
          {/* )} */}
        </Stack>
      </Box>
    </Dialog>
  ) : (
    <></>
  );
}

export default React.memo(MeetingDetails);
