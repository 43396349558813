import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import useStyle from "./styles";

export default function RecurringTag() {
  const { t } = useTranslation();
  const classes = useStyle();

  return (
    <Box className={classes.recurring}>
      <Typography variant="h4">{t("recurring_event")}</Typography>
    </Box>
  );
}
