import { create } from "zustand";

const useContactStore = create((set) => ({
  // store list of contacts
  contacts: [],
  // store list of groups
  groups: [],
  // flag to loader contacts
  fetchingContacts: false,
  // flag to refresh contacts
  refreshContacts: false,
  // pagination index

  // flag to enable create team button
  makeGroup: false,

  //search contacts list
  searchedContacts: [],

  newGroupMembers: [],

  setContacts: (contacts) => set({ contacts }),
  setGroups: (groups) => set({ groups }),
  setFetchingContacts: (fetchingContacts) => set({ fetchingContacts }),
  setRefreshContacts: (refreshContacts) => set({ refreshContacts }),

  setMakeGroup: (makeGroup) => set({ makeGroup }),
  setSearchedContacts: (searchedContacts) => set({ searchedContacts }),
  setNewGroupMembers: (newGroupMembers) => set({ newGroupMembers }),
}));

export default useContactStore;
