import React from "react";
import InsertInvitationOutlinedIcon from "@mui/icons-material/InsertInvitationOutlined";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { IconButton, TextField, InputAdornment } from "@mui/material";
import useStyle from "../../styles/formStyles";

export default function Datepicker({ open, onChange, toggler, value }) {
  const classes = useStyle();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        open={open}
        onOpen={toggler}
        onClose={toggler}
        value={value}
        disablePast={true}
        onChange={(newValue) => onChange(newValue)}
        minDate={new Date()}
        PaperProps={{
          sx: {
            span: { color: "black" },
            "& .MuiPickersDay-root": {
              color: "black",
              "&.Mui-selected": {
                color: "white !important",
                backgroundColor: "#479AE9 !important",
                "&:hover": {
                  opacity: 0.8,
                },
              },
            },
          },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            onClick={toggler}
            onKeyDown={(e) => e.preventDefault()}
            className={classes.dateTime}
            InputProps={{
              //not applying font weight idk why
              disableUnderline: true,
              style: { fontWeight: "600" },
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton aria-label="calendar" onClick={toggler}>
                    <InsertInvitationOutlinedIcon
                      onClick={toggler}
                      sx={{
                        fontSize: "16px",
                        color: "#363B53",
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
}
