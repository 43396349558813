import React from "react";
import { Grid, Typography } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import { FREE_PLAN, PREMIUM_PLAN } from "../../Utils/Constants";
import ClearIcon from "@mui/icons-material/Clear";
import { useTranslation } from "react-i18next";

export default function PlanFeatures({ planType }: { planType: string }) {
  const { t } = useTranslation();
  return (
    <Grid container direction={"column"} gap={1.5} textAlign={"left"}>
      <List
        title={
          planType === PREMIUM_PLAN
            ? t("plan_features.0")
            : t("plan_features.1")
        }
        icon={<DoneIcon />}
      />
      <List title={t("plan_features.2")} icon={<DoneIcon />} />
      <List title={t("plan_features.3")} icon={<DoneIcon />} />

      <List
        title={
          planType === PREMIUM_PLAN
            ? t("plan_features.4")
            : t("plan_features.5")
        }
        icon={<DoneIcon />}
      />
      <List
        title={
          planType === PREMIUM_PLAN
            ? t("plan_features.6")
            : t("plan_features.7")
        }
        icon={<DoneIcon />}
      />

      <List
        title={t("plan_features.8")}
        icon={planType === FREE_PLAN ? <ClearIcon /> : <DoneIcon />}
      />
      <List
        title={t("plan_features.9")}
        icon={planType === FREE_PLAN ? <ClearIcon /> : <DoneIcon />}
      />
      <List
        title={t("plan_features.10")}
        icon={planType === FREE_PLAN ? <ClearIcon /> : <DoneIcon />}
      />
      <List
        title={t("plan_features.11")}
        icon={planType === FREE_PLAN ? <ClearIcon /> : <DoneIcon />}
      />
    </Grid>
  );
}

function List({ title, icon }) {
  return (
    <Typography alignItems={"center"} display={"flex"} variant={"h4"} gap={1}>
      {icon}
      {title}
    </Typography>
  );
}
