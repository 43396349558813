import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  Paper,
  useMediaQuery,
  IconButton,
  useTheme,
  Box,
} from "@mui/material";
import { ACCOUNT_QR_CODE_REDIRECT } from "Utils/Constants";
import { QRCodeSVG, QRCodeCanvas } from "qrcode.react";
import CloseIcon from "@mui/icons-material/Close";
import { useContext } from "react";
import { accountContext } from "../../context/user";
import { getToken } from "../../Utils/CommonFunctions";

export function QRCodeDialog({ open, handleDialogOpen, t }) {
  const theme = useTheme();
  const { user } = useContext(accountContext);
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const token = getToken();
  // const qrMeetingParticipant = localStorage.getItem("email");
  // const id = localStorage.getItem("user_id");
  const [meetingLink, setMeetingLink] = useState("");

  useEffect(() => {
    setMeetingLink(
      // `${ACCOUNT_QR_CODE_REDIRECT}?participant=${qrMeetingParticipant}&token=${token}&id=${id}`

      `${ACCOUNT_QR_CODE_REDIRECT}?participant=${user?.email}&token=${token}`
    );
  }, [token, user]);

  const downloadCode = () => {
    const qrCodeURL = document
      .getElementById("accountQR")
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download = `qr.png`;
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  };

  return (
    <Dialog
      open={open}
      onClose={handleDialogOpen}
      sx={{ justifyContent: "center" }}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        style: {
          padding: "30px 10px",
          overflow: "hidden",
        },
      }}
    >
      <DialogActions
        sx={{
          justifyContent: "flex-end",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box width="100%" display="flex" justifyContent="flex-end">
          <IconButton onClick={handleDialogOpen}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Paper
          sx={{
            p: "20px",
            backgroundColor: "#F7F7F9",
            boxShadow: "none",
            borderRadius: "23px",
            width: "75%",
            aspectRatio: "1 / 1",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <QRCodeCanvas
            bgColor="#F7F7F9"
            // style={{ width: "95%", height: "95%" }}
            style={{ padding: 6 }}
            value={meetingLink}
            id="accountQR"
            renderAs="canvas"
            // size={280}
            includeMargin={true}
            size={mobile ? 200 : 280}
          />
        </Paper>
        <Button onClick={downloadCode} variant="cancel" sx={{ mt: "30px" }}>
          {t("download")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
