import React from "react";
import { Tooltip } from "@mui/material";
import { useStyle } from "../../styles/tooltip";

type Props = {
  title: string;
  placement?:
    | "bottom"
    | "left"
    | "right"
    | "top"
    | "bottom-end"
    | "bottom-start"
    | "left-end"
    | "left-start"
    | "right-end"
    | "right-start"
    | "top-end"
    | "top-start"
    | undefined;
  children: any;
};

export default function ToolTip({ title, placement, children }: Props) {
  const tooltipStyle = useStyle();
  return (
    <div>
      <Tooltip
        title={title}
        placement={placement ? placement : "bottom-end"}
        arrow
        classes={{
          tooltip: tooltipStyle.tooltip,
          arrow: tooltipStyle.arrow,
        }}
      >
        {children}
      </Tooltip>
    </div>
  );
}
