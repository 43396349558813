import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useWebsiteStore from "../../Utils/websiteStore";
import useStyle from "./styles";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { AddQuestionDialog } from "./AddQuestionDialog";
import { Question } from "./Services";
import { serverPOST } from "../../Utils/HttpFunctions";
import { RiDeleteBin5Line } from "react-icons/ri";
import SnackbarComponent from "../Global/SnackbarComponent";
import Booking from "./Preview/Booking";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { PreviewButtonGroup } from "./Availability";

const questionTypes = [
  { type: "long", title: "long_answer", label: "long_answer_desc" },
  { type: "number", title: "number", label: "number_desc" },
  {
    type: "address",
    title: "address",
    label: "address_desc",
  },
  { type: "checkbox", title: "checkbox", label: "checkbox_desc" },
];

export default function BookingForm() {
  const calendarData = useWebsiteStore((store) => store.calendarData);
  const setCalendarData = useWebsiteStore((store) => store.setCalendarData);
  const askUser = useWebsiteStore((store) => store.askUser);
  const setAskUser = useWebsiteStore((store) => store.setAskUser);
  const { t } = useTranslation();
  const [showQuestions, setShowQuestions] = useState(false);
  const [saveMessage, setSaveMessage] = useState("");
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const [showPreview, setShowPreview] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (calendarData && calendarData.ask_user) {
      setAskUser(calendarData.ask_user);
    }
  }, [calendarData]);

  const handleCheck = (e) => {
    setAskUser({
      ...askUser,
      [e.target.name]: e.target.checked,
    });
  };

  const handleQuestionCheck = (e, index) => {
    let temp = [...askUser.questions];
    temp[index].is_required = e.target.checked;
    setAskUser({
      ...askUser,
      questions: temp,
    });
  };

  const handleSaveClick = async () => {
    setLoading(true);
    const ask_user = { ...askUser };
    if (ask_user.questions && ask_user.questions.length > 0) {
      for (let i = 0; i < ask_user.questions.length; i++) {
        delete ask_user.questions[i]["_id"];
      }
    }
    const response = await serverPOST(
      `/web-integration?offset=${new Date().getTimezoneOffset()}`,
      { ask_user }
    );
    if (response && response.constructor !== String) {
      setSaveMessage("Booking form has been updated");
      setCalendarData(response.data.calendarData);
    } else {
      return undefined;
    }
    setLoading(false);
  };
  const handleDeleteQuestion = (i: number) => {
    let temp: any = [...askUser.questions];
    temp.splice(i, 1);
    setAskUser({ ...askUser, questions: temp });
  };

  return (
    <div>
      {showPreview ? (
        <Booking />
      ) : (
        <>
          <QuestionBox
            title={t("full_name")}
            subTitle={t("name_sub")}
            checked={true}
            name={"name"}
            handleCheck={handleCheck}
          />
          <QuestionBox
            title={t("email_address")}
            subTitle={t("email_sub")}
            checked={true}
            name="email"
            handleCheck={handleCheck}
          />

          {askUser?.questions?.map((question, index) => (
            <QuestionBox
              key={index}
              title={question.label}
              subTitle={`(${t(question.type_of_question)})`}
              checked={question.is_required}
              name="questions"
              handleCheck={(e) => handleQuestionCheck(e, index)}
              handleDeleteQuestion={() => handleDeleteQuestion(index)}
            />
          ))}

          <Box mt={2} mb={2} justifyContent={"left"} display={"flex"}>
            <Button
              variant="outlined"
              style={{
                border: "none",
                color: "#479AE9",
              }}
              startIcon={
                showQuestions ? (
                  <RemoveCircleOutlineIcon />
                ) : (
                  <AiOutlinePlusCircle />
                )
              }
              onClick={() => setShowQuestions(!showQuestions)}
            >
              {t("add_question")}
            </Button>
          </Box>

          {showQuestions && <QuestionTypes types={questionTypes} />}
        </>
      )}

      <PreviewButtonGroup
        onSave={handleSaveClick}
        loading={loading}
        showPreview={showPreview}
        setShowPreview={setShowPreview}
      />

      <SnackbarComponent
        open={!!saveMessage}
        handleClose={() => setSaveMessage("")}
        message={saveMessage}
        vertical={"bottom"}
        horizontal={"center"}
      />
    </div>
  );
}

export function QuestionTypes({ types }) {
  const classes = useStyle();
  const [questionType, setQuestionType] = useState("");
  const [currentQuestion, setCurrentQuestion] = useState({
    label: "",
    is_required: false,
    type_of_question: "",
  } as Question);
  console.log("open ssim", types);
  const askUser = useWebsiteStore((store) => store.askUser);
  const setAskUser = useWebsiteStore((store) => store.setAskUser);
  const { t } = useTranslation();

  const handleClick = (questionType) => {
    handleClose();
    if (questionType === "checkbox") {
      setCurrentQuestion({
        ...currentQuestion,
        type_of_question: "checkbox",
        options: [
          `${t("option")} 1`,
          `${t("option")} 2`,
          `${t("option")} 3`,
          `${t("option")} 4`,
        ],
      });
    } else {
      setCurrentQuestion({
        ...currentQuestion,
        type_of_question: questionType,
      });
    }
    setQuestionType(questionType);
  };
  const handleClose = () => {
    setQuestionType("");
  };

  const handleSave = () => {
    if (askUser.questions) {
      if (askUser?.questions?.length > 0) {
        setAskUser({
          ...askUser,
          questions: [...askUser?.questions, currentQuestion],
        });
      } else {
        setAskUser({
          ...askUser,
          questions: [currentQuestion],
        });
      }
    }
  };

  return (
    <Box className={classes.questionsBox}>
      <Typography variant="h3" align="left">
        {t("questions")}
      </Typography>
      {types.map((question) => (
        <Box
          className={classes.typeBox}
          key={question.title}
          onClick={() => handleClick(question.type)}
        >
          <Typography variant="h3" fontWeight={400}>
            {t(question.title)}
          </Typography>
          <Typography
            variant="h4"
            fontWeight={400}
            className={classes.grayText}
          >
            {t(question.label)}
          </Typography>
        </Box>
      ))}

      <AddQuestionDialog
        addQuestion={undefined}
        handleAddQuestion={handleClose}
        question={currentQuestion}
        setQuestion={setCurrentQuestion}
        questionType={questionType}
        questionHeading={t(`add_${questionType}`)}
        handleSave={handleSave}
      />
    </Box>
  );
}

function QuestionBox({
  title,
  subTitle,
  checked,
  name,
  handleCheck,
  handleDeleteQuestion,
}: {
  title: string;
  subTitle: string;
  checked: boolean;
  name: string;
  handleCheck: any;
  handleDeleteQuestion?: any;
}) {
  const classes = useStyle();
  return (
    <Box className={classes.serviceBox} mt={2}>
      <Box
        display={"flex"}
        alignItems="center"
        gap={1}
        className={classes.w100}
        justifyContent={"space-between"}
      >
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={checked}
                onChange={handleCheck}
                className={classes.checkbox}
                name={name}
              />
            }
            label={
              <Box>
                <Typography variant="h3" fontWeight={400}>
                  {title}
                </Typography>
                <Typography
                  variant="h4"
                  fontWeight={400}
                  className={classes.grayText}
                >
                  {subTitle}
                </Typography>
              </Box>
            }
          />
        </FormGroup>
        {!["name", "email"].includes(name) && (
          <RiDeleteBin5Line
            className={classes.removeIcon}
            onClick={handleDeleteQuestion}
          />
        )}
      </Box>
    </Box>
  );
}
