import { Box, Typography } from "@mui/material";
import React from "react";
import useWebsiteStore from "../../../Utils/websiteStore";

export default function WebsiteNotificationPreview() {
  const selectedEmailTemplate = useWebsiteStore(
    (state) => state.selectedEmailTemplate
  );

  return (
    <>
      {Object.keys(selectedEmailTemplate).length !== 0 && (
        <Box
          style={{
            backgroundColor: "white",
            borderRadius: 6,
            border: "1px solid #F7F7F9",
            textAlign: "left",
            padding: 25,
            gap: 8,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="h3">
            Subject:{selectedEmailTemplate.subject}
          </Typography>

          <Typography variant="h3" fontWeight={400}>
            {selectedEmailTemplate.body}
          </Typography>
        </Box>
      )}
    </>
  );
}
