import { useEffect, useState } from "react";

import { TextField, Stack, InputAdornment } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { useTranslation } from "react-i18next";

export default function EmailForm({
  email,
  setEmail,
  setEmailValid,
  emailError,
}) {
  const [helperText, setHelperText] = useState("");
  const { t } = useTranslation();
  var validator = require("validator");

  const handleEmailInput = () => {
    switch (!validator.isEmail(email)) {
      case true:
        setEmailValid(false);
        setHelperText(t("invalid_email"));
        break;
      case false:
        setEmailValid(true);
        setHelperText("");
        break;
      default:
        break;
    }
  };

  const handleEmailValue = (e) => {
    setEmail(e.target.value);
  };

  useEffect(() => {
    if (emailError) {
      setHelperText(emailError);
    }
  }, [emailError]);

  return (
    <TextField
      required
      id="email-form"
      variant="outlined"
      placeholder={t("email")}
      value={email}
      error={!helperText ? false : true}
      helperText={helperText}
      onChange={handleEmailValue}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <MailOutlineIcon />
          </InputAdornment>
        ),
        sx: { borderRadius: "8px" },
      }}
      onBlur={handleEmailInput}
      // sx={{ width: "100%" }}
      sx={{
        width: { xs: "100%", md: 360 },
      }}
    />
  );
}
