import React, { useContext } from "react";
import {
  Typography,
  Button,
  Grid,
  Box,
  Stack,
  Chip,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import "styles/newEvent.css";
import Person from "assets/bi_person-circle.svg";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import ViewAgendaOutlinedIcon from "@mui/icons-material/ViewAgendaOutlined";
import { handleTimeZone } from "timeZone";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { downloadFileAttachment } from "Utils/CommonFunctions";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import scrollbar from "styles/scrollBar.module.css";
import { Avatar } from "@mui/material";
import { useTranslation } from "react-i18next";
import Languages from "../Account/Languages";
import { authContext } from "context/Auth";
import useStyle from "./styles";
import { ContentContainer } from "../NewEvent/MeetingDetailsComponent";
import Attachement from "../Global/Attachement";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

export default function BookingDetails({
  firstName,
  lastName,
  title,
  duration,
  category,
  location,
  heading,
  agenda,
  fileName,
  eventId,
  participants,
  profile,
  polling,
  email,
  roomNo,
  showLng,
  phone,
  isOrganizer,
  isAddToWebsite,
}) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();
  const { isLogged } = useContext(authContext);
  const classes = useStyle();

  return (
    <>
      {!isAddToWebsite && (
        <Box>
          <Typography align="left" variant={isOrganizer ? "h3" : "h2"}>
            {heading}
          </Typography>
        </Box>
      )}
      {showLng && !isLogged && (
        <Box style={{ textAlign: "left" }} mt={mobile || polling ? 2 : 3}>
          <Typography variant="h4" style={{ marginBottom: "4px" }}>
            Language
          </Typography>
          <Languages showStyles={true} />
        </Box>
      )}

      {!isAddToWebsite && (
        <Box mt={mobile || polling ? 2 : 3}>
          <Typography align="left" variant={isOrganizer ? "h6" : "h5"}>
            {t("timezone_title")}: {handleTimeZone()}
          </Typography>
        </Box>
      )}

      {!isAddToWebsite ? (
        <Box
          display="flex"
          flexDirection={mobile ? "column" : "column"}
          alignItems={mobile ? "left" : "flex-start"}
          mt={mobile || polling ? 2 : 3}
        >
          <Avatar
            sx={{
              height: mobile || polling ? 80 : 120,
              width: mobile || polling ? 80 : 120,
            }}
            alt="profile picture"
            variant="round"
            src={profile ? profile : Person}
          />

          {/* <Person style={{ width: 120, height: 120 }} /> */}
          <Box display="flex">
            <Typography
              // variant="h3"
              align="left"
              style={{
                marginTop: mobile || polling ? 0 : "8px",
              }}
              className={classes.nameText}
            >
              {firstName + " " + lastName}
            </Typography>
            {/* <Typography
            // variant="h4"
            style={{
              marginTop: mobile || polling ? 0 : "8px",
            }}
            className={classes.orgText}
          >
            ({t("organizer")})
          </Typography> */}
          </Box>
          <Box
            display="flex"
            alignItems="center"
            mt={mobile || polling ? 2 : 3}
          >
            <EmailOutlinedIcon className={classes.icon} />
            <Typography
              // variant="h4"
              fontSize={isOrganizer ? "12px" : "14px"}
              fontWeight={400}
              align="left"
              style={{
                marginLeft: 5,
              }}
            >
              {email}
            </Typography>
          </Box>
        </Box>
      ) : (
        <>
          <Box
            mt={mobile || polling ? 2 : 3}
            style={{
              display: "flex",
              gap: 16,
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{
                height: !isOrganizer ? 80 : 40,
                width: !isOrganizer ? 80 : 40,
              }}
              alt="profile picture"
              variant="round"
              src={profile ? profile : Person}
            />
            <Typography
              align="left"
              variant={isOrganizer ? "h4" : "h3"}
              className={classes.titleText}
            >
              {title}
            </Typography>
          </Box>
          {email && (
            <Box
              display="flex"
              alignItems="center"
              mt={mobile || polling ? 2 : 3}
            >
              <EmailOutlinedIcon className={classes.icon} />
              <Typography
                // variant="h4"
                fontSize={isOrganizer ? "12px" : "14px"}
                fontWeight={400}
                align="left"
                style={{
                  marginLeft: 5,
                }}
              >
                {email}
              </Typography>
            </Box>
          )}
        </>
      )}

      {!isAddToWebsite && (
        <Box mt={mobile || polling ? 2 : 3}>
          <Typography
            align="left"
            variant={isOrganizer ? "h4" : "h3"}
            className={classes.titleText}
          >
            {title}
          </Typography>
        </Box>
      )}

      {category && isAddToWebsite && (
        <Box
          display="flex"
          alignItems="center"
          mt={mobile || polling ? 2 : isOrganizer ? 1 : 3}
        >
          <FormatListBulletedIcon className={classes.icon} />
          <Typography
            variant={isOrganizer ? "h6" : "h3"}
            fontWeight={400}
            align="left"
            style={{ marginLeft: 5 }}
          >
            {category}
          </Typography>
        </Box>
      )}

      {!duration ? (
        <></>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          mt={mobile || polling ? 2 : isOrganizer ? 1 : 3}
        >
          <AccessTimeOutlinedIcon className={classes.icon} />
          <Typography
            variant={isOrganizer ? "h6" : "h3"}
            fontWeight={400}
            align="left"
            style={{ marginLeft: 5 }}
          >
            {duration} {t("mins")}
          </Typography>
        </Box>
      )}

      {location && (
        <Box
          display="flex"
          alignItems="center"
          mt={mobile || polling ? 2 : isOrganizer ? 1 : 3}
        >
          <LocationOnOutlinedIcon className={classes.icon} />
          <Box>
            <Typography
              variant={isOrganizer ? "h6" : "h3"}
              fontWeight={400}
              align="left"
              style={{ marginLeft: 5 }}
            >
              {location}
            </Typography>
            {roomNo && (
              <Typography
                variant={isOrganizer ? "h6" : "h3"}
                fontWeight={400}
                align="left"
                style={{ marginLeft: 5 }}
              >
                <span>{t("room")}: </span>
                {roomNo}
              </Typography>
            )}
          </Box>
        </Box>
      )}

      <Grid
        container
        item
        xs={12}
        style={{ marginTop: 10, marginBottom: 10 }}
        sx={{ display: fileName?.length > 0 ? "flex" : "none" }}
      >
        <ContentContainer align={true}>
          <AttachFileIcon sx={{ color: "#323232", fontSize: "14px" }} />
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 8,
            }}
          >
            {fileName?.map((file) => {
              return <Attachement filename={file} meetingId={eventId} />;
            })}
          </Box>
        </ContentContainer>
      </Grid>

      {phone && (
        <Box
          display="flex"
          alignItems="center"
          mt={mobile || polling ? 2 : isOrganizer ? 1 : 3}
        >
          <LocalPhoneIcon className={classes.icon} />
          <Box>
            <Typography
              variant={isOrganizer ? "h6" : "h3"}
              fontWeight={400}
              align="left"
              style={{ marginLeft: 5 }}
            >
              {phone}
            </Typography>
          </Box>
        </Box>
      )}

      {agenda?.toString()?.toLowerCase() === "no description" ||
      agenda === "" ? null : (
        <Box
          display="flex"
          alignItems="center"
          mt={mobile || polling ? 2 : isOrganizer ? 1 : 3}
        >
          <>
            <ViewAgendaOutlinedIcon className={classes.icon} />

            <Typography
              variant={isOrganizer ? "h6" : "h3"}
              fontWeight={400}
              align="left"
              style={{ marginLeft: 5 }}
            >
              {agenda}
            </Typography>
          </>
        </Box>
      )}
      <Box
        display="flex"
        alignItems="center"
        mt={isOrganizer ? 1 : 3}
        sx={{ display: participants?.length > 0 ? "flex" : "none" }}
      >
        <PersonOutlineOutlinedIcon className={classes.icon} />
        <Box className={`${scrollbar.root} ${classes.participantsContainer}`}>
          {participants &&
            participants.map((participant, i) => {
              return (
                <Chip
                  sx={{
                    marginLeft: i === 0 ? 0 : 1,
                  }}
                  className={classes.participants}
                  key={i}
                  label={
                    <Typography variant="h4" fontWeight={400}>
                      {participant ? participant : participant?.user_id}
                    </Typography>
                  }
                />
              );
            })}
        </Box>
      </Box>
    </>
  );
}
