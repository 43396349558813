import { useMemo, useEffect, useState, useRef, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  IconButton,
  useTheme,
  Grid,
  Divider,
} from "@mui/material";
import moment from "moment";
import { useTranslation } from "react-i18next";
import PersonIcon from "@mui/icons-material/Person";
import stylesScroller from "styles/scrollBar.module.css";
import { notificationContext } from "context/Notification";
import {
  MEETING_INVITATION_ACCEPTED_BY_ALL,
  MEETING_INVITATION_RECEIVED,
  MEETING_NEW_TIMESLOTS_SUGGESTED,
  MEETING_INVITATION_INSTANT,
  MEETING_INVITATION_REJECTED,
  ONE_NONSKEDING_MULTIPLE_SKEDING_USERS,
} from "Utils/Constants";
import { checkUrl } from "Utils/CommonFunctions";
import { serverGET, serverPUT } from "Utils/HttpFunctions";
import { ReactComponent as Bell } from "assets/bell.svg";
import styles from "styles/styles.module.css";
import ProfileAvatar from "components/ProfileAvatar";
import PaymentIcon from "@mui/icons-material/Payment";
import { authContext } from "context/Auth";
import { useCookies } from "react-cookie";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { accountContext } from "../../context/user";
import GroupIcon from "@mui/icons-material/Group";
import SwitchAccount from "./SwitchAccount";
import useDelegatedAcess from "../../Utils/SwtichAccount";
import useStyle from "./styles";
import { NotificationToggleButton } from "./ToggleButton";
import ToolTip from "../Global/ToolTip";

/**
 * Bell icon and profile info details rendered in the navbar component
 */
export default function Info(props) {
  const [notification, setNotification] = useState(false);
  const [notifications, setNotifications] = useState();
  const [showProfile, setShowProfile] = useState(false);
  const { t } = useTranslation();
  const classes = useStyle();
  const wrapperRef = useRef(null);
  const profileRef = useRef(null);

  const navigate = useNavigate();
  const theme = useTheme();
  const { isLogged, setIsLogged } = useContext(authContext);
  const [cookies, setCookie, removeCookie] = useCookies(["redirectPath"]);
  const {
    showBell,
    setShowBell,
    notificationReceived,
    setNotificationReceived,
  } = useContext(notificationContext);

  const [switchAccount, setSwitchAccount] = useState(false);
  const delegatedMode = useDelegatedAcess((state) => state.delegatedMode);
  const setDelegatedMode = useDelegatedAcess((state) => state.setDelegatedMode);
  const { members, setMembers } = useDelegatedAcess((state) => state);

  useEffect(() => {
    const getMembers = async () => {
      const response = await serverGET(
        "/account/schedulermembers",
        undefined,
        true
      );
      if (response && response.constructor !== String) {
        setMembers(response.data.members);
      }
    };

    getMembers();
  }, []);
  useEffect(() => {
    if (notificationReceived) {
      getNotifications();
    }
  }, [notificationReceived]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setNotification(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        setShowProfile(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [profileRef]);

  const notificationLength = useMemo(() => {
    return (
      notifications &&
      notifications.notifications.filter((n) => n.status === 0).length
    );
  }, [notifications]);

  const markAsRead = async (id) => {
    const response = await serverPUT("/notification/updatestatus", {
      notificationId: id,
    });
    if (response && response.constructor !== String) {
      const temp = notifications;
      let notification = await temp?.notifications.find((n) => n._id === id);
      const index = temp?.notifications.indexOf(notification);
      temp.notifications[index].status = 1;
      if (index !== -1) {
        setNotifications(temp);
      }
    } else {
      return undefined;
    }
  };

  const getNotifications = async () => {
    const response = await serverGET("/notification/minimal?page=0");
    if (response && response.constructor !== String) {
      console.log("notification", response.data);
      setNotifications(response.data);
      setNotificationReceived(false);
    } else {
      return undefined;
    }
  };

  const handleNotificationIconClick = () => {
    handleNotification();
    if (showBell) {
      setShowBell(false);
    }
    if (!notification) {
      getNotifications();
    }
  };

  const handleClickOnBox = async (item) => {
    if (item.status === 0) {
      markAsRead(item._id);
    }
    handleNotification();
    navigate("/notifications", { state: { id: item._id } });
  };

  const handleNotification = () => {
    setNotification((prev) => !prev);
  };

  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    removeCookie("redirectPath", { path: "/" });
    setIsLogged(false);
  };

  useEffect(() => {
    if (isLogged === false) {
      navigate("/login");
    }
  }, [isLogged]);

  const handleSwitch = () => {
    setSwitchAccount((prev) => !prev);
  };

  const handleSwitchBack = () => {
    localStorage.removeItem("delegateToken");
    setDelegatedMode(null);
    window.location.reload();
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent={props.small ? "flex-end" : "flex-start"}
    >
      <IconButton ref={wrapperRef}>
        <ToolTip title="Notifications">
          <Bell
            onClick={handleNotificationIconClick}
            className={classes.bellIcon}
          />
        </ToolTip>
        {showBell && (
          <Box className={styles.dot}>
            <Typography variant="body2" style={{ color: "white" }}>
              {notificationLength < 10 ? notificationLength : "9+"}
            </Typography>
          </Box>
        )}
        {notification && (
          <Box
            style={{
              boxShadow: "0px 0px 4px 0px #00000026",
            }}
            className={
              props.small
                ? `${styles.smNotifyContainer}`
                : `${styles.notifyContainer}`
            }
          >
            <Box
              display="flex"
              alignItems="center"
              className={classes.notificationTitleWrapper}
            >
              <Typography variant="h3"> {t("notifications")}</Typography>
              {notifications && notificationLength > 0 && (
                <Box ml={1} display="flex" alignItems="center">
                  <Typography variant="body1"> {t("unread")} </Typography>
                  <Box className={styles.unread}>
                    <Typography variant="h6" style={{ color: "white" }}>
                      {notificationLength < 10 ? notificationLength : "9+"}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
            <Box className={`${styles.content} ${stylesScroller.root}`}>
              {notifications === undefined ? (
                <Box>
                  <Typography variant="h4" fontWeight={300}>
                    {t("no_notifications")}
                  </Typography>
                </Box>
              ) : (
                notifications &&
                notifications?.notifications?.map((item) => {
                  return (
                    <Box
                      className={styles.insideNotify}
                      key={item._id}
                      style={{
                        backgroundColor:
                          item.status === 0
                            ? "rgba(150, 220, 255, 0.1)"
                            : "none",
                        cursor: "pointer",
                      }}
                    >
                      <Box className={classes.markRead}>
                        <Box
                          className={styles.badge}
                          style={{
                            backgroundColor:
                              item.status === 0 ? "#DF6054" : "none",
                          }}
                        />
                      </Box>
                      <Box className={classes.notificationContent}>
                        <Box onClick={() => handleClickOnBox(item)}>
                          <Typography variant="h4">{item.title}</Typography>
                          <Box
                            display="flex"
                            justifyContent={"space-between"}
                            alignItems="center"
                            mt={0.5}
                          >
                            <Typography variant="h5" fontWeight={400}>
                              <span className={classes.lightColor}>
                                {item?.meeting_id?.start_datetime
                                  ? moment(
                                      item.meeting_id.start_datetime
                                    ).format(
                                      // "hh:mm:ss a"
                                      "LT"
                                    )
                                  : moment(item.createdAt).format("LT")}
                              </span>

                              <span
                                style={{
                                  marginLeft: "0.5rem",
                                }}
                                className={classes.lightColor}
                              >
                                {item?.meeting_id?.start_datetime
                                  ? moment(
                                      item.meeting_id.start_datetime
                                    ).format("ddd")
                                  : moment(item.createdAt).format("ddd")}
                                ,
                              </span>
                              <span
                                style={{
                                  marginLeft: "0.3rem",
                                }}
                                className={classes.lightColor}
                              >
                                {/* {moment(item.createdAt).format("YYYY-MM-DD")} */}
                                {moment(
                                  item?.meeting_id?.start_datetime
                                ).format("LL")}
                              </span>
                            </Typography>
                            <Typography
                              variant="h6"
                              fontWeight={400}
                              className={classes.lightColor}
                            >
                              {moment(item.createdAt).fromNow(true)} ago
                            </Typography>
                          </Box>
                        </Box>
                        <Box display="flex" alignItems="flex-start" mt={0.5}>
                          <Typography
                            variant="h4"
                            style={{
                              color: theme.palette.primary.main,
                            }}
                            onClick={() => setNotification(false)}
                          >
                            {item.type === MEETING_INVITATION_RECEIVED ? (
                              <Link
                                to={`/meeting-response?meetingId=${item?.meeting_id?._id}`}
                                className={classes.link}
                              >
                                {t("accept/reject")}
                              </Link>
                            ) : item.type === MEETING_INVITATION_INSTANT &&
                              checkUrl(item?.meeting_id?.location) ? (
                              <Box
                                onClick={() =>
                                  window.open(
                                    item?.meeting_id?.location,
                                    "_blank"
                                  )
                                }
                              >
                                <Typography
                                  variant="h3"
                                  className={classes.link}
                                >
                                  {t("join")}
                                </Typography>
                              </Box>
                            ) : item.type === MEETING_INVITATION_REJECTED &&
                              item?.meeting_id?.case ===
                                ONE_NONSKEDING_MULTIPLE_SKEDING_USERS &&
                              !item?.meeting_id?.start_datetime ? (
                              <Link
                                to={`/initiator-select?meetingId=${item?.meeting_id?._id}&redirect=true`}
                                className={classes.link}
                              >
                                {t("choose_time")}
                              </Link>
                            ) : item.type ===
                              MEETING_NEW_TIMESLOTS_SUGGESTED ? (
                              <Link
                                to={`/meeting-response?meetingId=${item?.meeting_id?._id}`}
                                className={classes.link}
                              >
                                {t("choose_time_slots")}
                              </Link>
                            ) : item.type ===
                              MEETING_INVITATION_ACCEPTED_BY_ALL ? (
                              <Link
                                to={`/meeting-poll/results?meetingId=${item?.meeting_id?._id}`}
                                className={classes.link}
                              >
                                {t("select_time_slot")}
                              </Link>
                            ) : null}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  );
                })
              )}
            </Box>
            <Link
              to="/notifications"
              style={{
                textDecoration: "none",
              }}
              className={classes.lightColor}
            >
              <Button
                variant="outlined"
                style={{
                  marginTop: 10,
                  padding: "4px 20px",
                  borderColor: theme.palette.primary.dark,
                }}
                onClick={handleNotification}
              >
                <Typography
                  variant="h4"
                  style={{
                    color: theme.palette.primary.dark,
                  }}
                >
                  {t("view_all")}
                </Typography>
              </Button>
            </Link>
          </Box>
        )}
      </IconButton>
      <Box style={{ position: "relative" }} ref={profileRef}>
        {props.small ? (
          <IconButton
            style={{ padding: "0px" }}
            onClick={() => setShowProfile((prev) => !prev)}
          >
            <ProfileAvatar height={"35px"} width={"35px"} user={props.user} />
          </IconButton>
        ) : (
          <ToolTip title="My Account">
            <IconButton onClick={() => setShowProfile((prev) => !prev)}>
              <ProfileAvatar height="35px" width="35px" user={props.user} />
            </IconButton>
          </ToolTip>
        )}

        {showProfile && (
          <Box
            className={
              props.small
                ? `${styles.profileContainer} ${styles.profileContainerMobile}`
                : styles.profileContainer
            }
            style={{
              backgroundColor: theme.palette.info.main,
              boxShadow: "0px 0px 4px 0px #00000026",
            }}
          >
            <Box className={styles.insideProfile}>
              {props?.user?.picture ? (
                <img src={props?.user?.picture} className={classes.userImg} />
              ) : (
                <Box style={{ marginTop: 5 }}>
                  <ProfileAvatar
                    height={"80px"}
                    width={"80px"}
                    user={props.user}
                  />
                </Box>
              )}

              <Typography variant="h3" style={{ marginTop: 8 }}>
                {props?.user?.name}
              </Typography>
              <Typography
                variant="h4"
                fontWeight="400"
                className={classes.userEmail}
              >
                {props?.user?.email}
              </Typography>
              <Box className={classes.packageTitle} mt={2} mb={2} p={"4px 8px"}>
                <Typography variant="h4">
                  {props?.user?.package.title}
                </Typography>
              </Box>

              <Link to="/accountdetails" className={styles.link}>
                <Grid
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  gap={1}
                  px={3}
                >
                  <PersonIcon fontSize="small" />
                  <Typography
                    textAlign={"left"}
                    variant="h3"
                    sx={{ width: "180px" }}
                    onClick={() => setShowProfile(false)}
                    fontWeight={400}
                  >
                    {t("account_details")}
                  </Typography>
                </Grid>
              </Link>

              <Link to="/payment-services" className={styles.link}>
                <Grid
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  gap={1}
                  px={3}
                >
                  <PaymentIcon fontSize="small" />
                  <Typography
                    textAlign={"left"}
                    variant="h3"
                    sx={{ width: "180px" }}
                    onClick={() => setShowProfile(false)}
                    fontWeight={400}
                  >
                    {t("billing")}
                  </Typography>
                </Grid>
              </Link>

              <hr
                style={{
                  // height: ".2rem",
                  width: "90%",
                  opacity: "0.3",
                }}
              />

              {!delegatedMode && members.length > 0 && (
                <Box className={styles.link} onClick={handleSwitch}>
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    gap={1}
                    px={3}
                  >
                    <GroupIcon fontSize="small" />
                    <Typography
                      textAlign={"left"}
                      variant="h3"
                      sx={{ width: "180px" }}
                      onClick={() => setShowProfile(false)}
                      fontWeight={400}
                    >
                      {t("switch_account")}
                    </Typography>
                  </Box>
                </Box>
              )}
              {delegatedMode && (
                <>
                  <Box className={styles.link}>
                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      gap={1}
                      px={3}
                      onClick={handleSwitchBack}
                    >
                      <GroupIcon fontSize="small" />
                      <Typography
                        textAlign={"left"}
                        variant="h3"
                        sx={{ width: "180px" }}
                        fontWeight={400}
                      >
                        {t("switch_back")}
                      </Typography>
                    </Box>
                  </Box>
                  <NotificationToggleButton />
                </>
              )}
              <Grid
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                gap={1}
                px={3}
                className={styles.link}
              >
                <ExitToAppIcon fontSize="small" />
                <Typography
                  textAlign={"left"}
                  variant="h3"
                  sx={{ width: "180px" }}
                  onClick={handleLogout}
                  fontWeight={400}
                >
                  {t("logout")}
                </Typography>
              </Grid>
            </Box>
          </Box>
        )}
        <SwitchAccount
          switchAccount={switchAccount}
          setSwitchAccount={setSwitchAccount}
          members={members}
        />
      </Box>
    </Box>
  );
}
