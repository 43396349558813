import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { isValidPhoneNumber } from "react-phone-number-input";
import {
  Typography,
  Button,
  Grid,
  Box,
  Dialog,
  useTheme,
  useMediaQuery,
  InputLabel,
  Backdrop,
  CircularProgress,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import "styles/newEvent.css";
import { serverGETPOST } from "Utils/HttpFunctions";
import {
  checkUrl,
  convertDateToUTCMilliseconds,
  convertUTCMillisecondsToDate,
} from "Utils/CommonFunctions";
import {
  ALL_SKEDING_USERS,
  MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS,
  ONE_NONSKEDING_MULTIPLE_SKEDING_USERS,
  ONE_NONSKEDING_ONE_SKEDING_USER,
  ONE_SKEDING_MULTIPLE_NONSKEDING_USERS,
} from "Utils/Constants";
import { integrationContext } from "context/Integrations";
import { Title } from "../../pages/NewEvent";
import Description from "components/NewEvent/Description";
import Location from "components/NewEvent/Location";
import TimeInterval from "components/NewEvent/TimeInterval";
import PhysicalLocation from "components/NewEvent/PhysicalLocation";
import Phone from "components/NewEvent/Phone";
import VirtualLink from "components/NewEvent/Link";
import useStyle from "../../styles/formStyles";
import CustomInterval from "./CustomInterval";
import Progress from "./Progress";
import ErrorSnackbar from "components/ErrorSnackbar";
import NoCalendar from "./NoCalendar";
import { serverPOST } from "../../Utils/HttpFunctions";
import useMeetingStore from "../../Utils/createMeetingStore";
import ColorIndicator from "../meetings/ColorIndicator";
import useContactGroups from "../../Utils/hooks/useContactGroups";
import { useTranslation } from "react-i18next";
import Counter from "components/NewEvent/Counter";
import {
  findColor,
  handleIsLock,
  uploadMultipleFileAttachments,
} from "../../Utils/CommonFunctions";
import { accountContext } from "../../context/user";
import { useLayoutEffect } from "react";
import SingleSlot from "./SingleSlot";
import { SINGLE_SLOT_MEETING } from "../../Utils/Constants";
import SnackbarComponent from "components/Global/SnackbarComponent";
import { generateDoc } from "../CreateDoc";
import { MeetingAgenda } from "./MeetingAgenda";
import useSuggestionsStore, {
  getInitialStartTime,
} from "../../Utils/suggestionsStore";
import puseStyle from "./styles";
import { RoomNo } from "../../pages/RoomNo";
import Participants from "./Participants";
import MeetingTypeColor from "./MeetingTypeColor";
import useEventsStore from "../../Utils/eventStore";
export default function Inputs({
  inputModal,
  handleContinue,
  setMeetingModal,
  closeInput,
  isMobile,
  // for dashboard click
  modalDate,
  draft,
}) {
  const classes = useStyle();
  const pclasses = puseStyle();
  const locationProps = useLocation();
  const { user } = useContext(integrationContext);
  const { user: userAccount } = useContext(accountContext);
  const { t } = useTranslation();
  const startTime = useSuggestionsStore((state) => state.startTime);
  const setStartTime = useSuggestionsStore((state) => state.setStartTime);
  const { getDraftEvents } = useEventsStore();
  // using setOrganizerMeetings from store (used in suggesstions API)
  const setOrganizerMeetings = useMeetingStore(
    (state) => state.setOrganizerMeetings
  );
  const getUserRemainingMeetings = useMeetingStore(
    (state) => state.getUserRemainingMeetings
  );
  const isFetched = useMeetingStore((state) => state.isFetched);
  const agendas = useMeetingStore((state) => state.agendas);
  const setAgendas = useMeetingStore((state) => state.setAgendas);
  const setMeetingData = useMeetingStore((state) => state.setMeetingData);

  const [timeInterval, setTimeInterval] = useState(
    draft
      ? draft?.timeInterval
      : locationProps?.state?.duration
      ? locationProps?.state?.duration
      : "01:00:00"
  );
  const [meetingColorType, setMeetingColorType] = useState({
    color: "",
    title: "",
  });

  const [title, setTitle] = useState(
    draft
      ? draft?.title
      : locationProps?.state?.title
      ? locationProps?.state?.title
      : ""
  );
  const [description, setDescription] = useState(
    draft
      ? draft?.description
      : locationProps?.state?.agenda
      ? locationProps?.state?.agenda
      : ""
  );
  const [location, setLocation] = useState(
    draft
      ? draft?.locationName
      : locationProps?.state?.location
      ? locationProps?.state?.location
      : user && user.is_google_synced
      ? "Google Meet"
      : // : user && user.is_microsoft_synced
        // ? "Outlook"
        ""
  );
  const [participants, setParticipants] = useState(
    draft
      ? draft?.participants
      : locationProps?.state?.participants
      ? locationProps?.state?.participants
      : []
  );

  const [participantLabels, setParticipantLabels] = useState([]);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");

  const [files, setFiles] = useState([]);
  const [fileNames, setFileNames] = useState([]);

  const [room, setRoom] = useState(
    draft && draft?.locationName === "Other" ? draft?.room : ""
  );
  const [address, setAddress] = useState(
    draft && draft?.locationName === "Other" ? draft?.location : ""
  );
  const [customTime, setCustomTime] = useState(
    draft && draft.timeInterval === "custom"
      ? convertToTimeString(draft?.durationMinutes * 60)
      : ""
  );
  const [phone, setPhone] = useState(
    draft && draft?.locationName === "Phone" ? draft?.location : ""
  );
  const [error, setError] = useState("");
  const [noCalendarModal, setNoCalendarModal] = useState(false);
  const [meetingTitle, setMeetingTitle] = useState("Create a New Meeting");
  const [suggesstionsLoading, setSuggesstionsLoading] = useState(false);
  const [recommededSlots, setRecommededSlots] = useState(false);
  const [eventCase, setEventCase] = useState("");
  const [pU, setPU] = useState([]);
  const [link, setLink] = useState(() =>
    draft && draft?.locationName === "Link"
      ? draft?.location
      : user && user.virtual_link
      ? user.virtual_link
      : ""
  );
  const [fetched, setFetched] = useState(false);
  const [searchParams] = useSearchParams();

  // single slot meeting states
  const [formDate, setFormDate] = useState(moment());
  const [singleSlot, setSingleSlot] = useState(!!modalDate ? true : false);
  const [success, setSuccess] = useState("");
  const [contacts, groups, minimizedContacts] = useContactGroups(participants);
  const theme = useTheme();
  const previousURL = window.history.state?.usr?.previousURL || null;
  const navigate = useNavigate();

  const handleIntervalChange = (event) => {
    setTimeInterval(event.target.value);
  };

  const handleMeetingTypeChange = (selectedMeeting) => {
    setMeetingColorType(selectedMeeting);
  };

  useEffect(() => {
    // Fetch data only if it hasn't been fetched yet
    if (!isFetched && handleIsLock(userAccount)) {
      getUserRemainingMeetings();
    }
  }, [isFetched]);

  useEffect(() => {
    setStartTime(getInitialStartTime());
  }, []);

  useLayoutEffect(() => {
    const participant = searchParams.get("participant");
    if (participant) {
      setParticipants([participant?.toLowerCase()]);
    }
  }, [searchParams]);

  useEffect(() => {
    if (modalDate) {
      setFormDate(moment(modalDate));
    }
  }, [modalDate]);

  useEffect(() => {
    if (fetched && error === "") {
      const duration_minutes =
        timeInterval === "custom"
          ? moment.duration(customTime).asMinutes()
          : moment.duration(timeInterval).asMinutes();

      var loc =
        location === "Other"
          ? address
          : location === "Phone"
          ? phone
          : location === "Link"
          ? link
          : location;

      let meetingData = {
        title: title ? title : undefined,
        duration_minutes,
        participants: participants,
        locationKey: location,
        location: loc,
        description: description ? description : undefined,
        attachment_file_name: fileName ? fileName : undefined,
        file: file ? file : undefined,
        attachment_file_names: fileNames ? fileNames : undefined,
        files: files ? files : undefined,
        meeting_room:
          location === "Other" ? (room ? room : undefined) : undefined,
        meetingTitle: meetingTitle,
        slots: recommededSlots,
        case: eventCase,
        timeInterval: timeInterval,
        customTime: customTime,
        unavailability: pU,
        address: address,
        participantLabels: participantLabels,
        draft_id: draft?.id,
        meeting_type: meetingColorType,
        organizer_preferences:
          locationProps?.state?.organizer_preferences ?? undefined,
      };

      //store data in context
      setMeetingData(meetingData);

      //move to next popup
      handleContinue();
    }
  }, [fetched]);

  const checkSlots = async () => {
    setFetched(false);
    if (
      participants.length === 0 ||
      timeInterval === "" ||
      (timeInterval === "custom" && customTime === "")
    ) {
      setMeetingTitle("Create A New Meeting");
      return;
    }
    const current_date = parseStartTime(formDate.toDate());

    if (timeInterval === "custom" && customTime === "") {
      return;
    }
    if (participants.length > 0 && timeInterval !== "") {
      setSuggesstionsLoading(true);

      const duration_minutes =
        timeInterval === "custom"
          ? moment.duration(customTime).asMinutes()
          : moment.duration(timeInterval).asMinutes();

      const data = {
        current_date: convertDateToUTCMilliseconds(current_date),
        duration_minutes,
        participants: participants,
      };

      const response = await serverGETPOST(
        `/meeting/suggestions?offset=${new Date().getTimezoneOffset()}
        `,
        data
      );
      // &blocked_participants=${[{}]}
      if (response && response.constructor !== String) {
        let parseResponse;
        if (
          response.data.case === MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS ||
          response.data.case === ONE_SKEDING_MULTIPLE_NONSKEDING_USERS
        ) {
          parseResponse = await response.data.slotsData.map((item) => {
            return {
              start: convertUTCMillisecondsToDate(item.slotStart),
              end: convertUTCMillisecondsToDate(item.slotEnd),
              availableParticipants: item.availableParticipantsData,
              unAvailableParticipants: item.unavailableParticipantsData,
            };
          });
        } else {
          parseResponse = await response.data.allAvailbleSlots.map((item) => {
            return {
              start: convertUTCMillisecondsToDate(item.startTime),
              end: convertUTCMillisecondsToDate(item.endTime),
            };
          });
        }

        setSuggesstionsLoading(false);
        setRecommededSlots(parseResponse);
        setPU(response.data.Participant_unavailibility);
        setEventCase(response.data.case);
        setOrganizerMeetings(response.data.organizerMeetings);

        if (
          response.data.case === ALL_SKEDING_USERS &&
          participants.length === 1
        ) {
          setMeetingTitle("all_memeber_meeting");
        } else if (response.data.case === ONE_NONSKEDING_ONE_SKEDING_USER) {
          setMeetingTitle("non_member_meeting");
        } else if (
          response.data.case === ALL_SKEDING_USERS &&
          participants.length > 1
        ) {
          setMeetingTitle("all_memeber_meeting");
        } else if (
          response.data.case === ONE_NONSKEDING_MULTIPLE_SKEDING_USERS
        ) {
          setMeetingTitle("non_member_meeting");
        } else if (
          response.data.case === MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS ||
          response.data.case === ONE_SKEDING_MULTIPLE_NONSKEDING_USERS
        ) {
          setMeetingTitle("multi_non_member");
        }
        setFetched(true);
      } else {
        setSuggesstionsLoading(false);
        setError(response.data.message);
      }
    }
  };

  const parseStartTime = (currentDate) => {
    const current_date = currentDate ? new Date(currentDate) : new Date();
    if (startTime) {
      let start = startTime;
      if (start == "00") {
        start = "59";
      }

      current_date.setMinutes(parseInt(start));
      if (current_date < new Date()) {
        let hours = current_date.getHours();
        current_date.setHours(hours + 1);
      }
    }
    return current_date;
  };

  const handleDateChange = (date) => {
    setFormDate(date);
  };

  const handleNext = async () => {
    setError("");
    if (!user?.is_google_synced) {
      if (!user?.is_microsoft_synced) {
        setNoCalendarModal((prev) => !prev);
        return;
      }
    }
    if (
      timeInterval === "" ||
      participants.length < 1 ||
      location === "" ||
      (timeInterval === "custom" && customTime === "")
    ) {
      setError(t("mandatory_field_error"));
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return;
    }
    if (
      (location === "Other" && address === "") ||
      (location === "Phone" && phone === "") ||
      (location === "Link" && link === "")
    ) {
      setError("Error! Please fill out location field");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return;
    }
    if (location === "Phone" && !isValidPhoneNumber(phone)) {
      setError(t("phone_err"));
      return;
    }
    if (location === "Link" && !checkUrl(link)) {
      setError(t("link_err"));
      return;
    }

    if (singleSlot) {
      var loc =
        location === "Other"
          ? address
          : location === "Phone"
          ? phone
          : location === "Link"
          ? link
          : location;
      const duration_minutes =
        timeInterval === "custom"
          ? moment.duration(customTime).asMinutes()
          : moment.duration(timeInterval).asMinutes();

      let fileCopies = files;
      let fileNameCopies = fileNames;
      let agendaDoc = null;
      let start_datetime = formDate.valueOf();
      if (agendas.length > 0) {
        const emptyAgendas = agendas.filter((a) => a.topic.trim() === "");
        if (emptyAgendas.length > 0) {
          setError(t("agenda_error"));
          return;
        }
        agendaDoc = await generateDoc(agendas, {
          start_datetime,
          duration_minutes,
        });

        fileCopies = [...files, agendaDoc];
        fileNameCopies = [
          ...fileNames,
          title ? `Agenda: ${title}.docx` : "Agenda.docx",
        ];
      }

      // loader for button in case of this case
      setSuggesstionsLoading(true);

      const data = {
        title: title ? title : undefined,
        start_datetime,
        duration_minutes,
        participants,
        time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        utc_offset: new Date().getTimezoneOffset(),
        location: loc,
        description: description ? description : undefined,
        case: SINGLE_SLOT_MEETING,
        attachment_file_name: fileNameCopies ? fileNameCopies : undefined,
        draft_id: draft?.id,
        meeting_type: {
          ...meetingColorType,
          bgColor: findColor(SINGLE_SLOT_MEETING, participants, contacts),
        },
        // attachment_file_names: fileNameCopy ? fileNameCopy : undefined,
        meeting_room:
          location === "Other" ? (room ? room : undefined) : undefined,
      };
      const response = await serverPOST("/meeting", data);
      if (response && response.constructor !== String) {
        // if (fileNameCopy) {
        //   await uploadFileAttachment(fileNameCopy, fileCopy, response.data._id);
        // }
        if (fileNameCopies.length > 0) {
          await uploadMultipleFileAttachments(
            fileNameCopies,
            fileCopies,
            response.data._id
          );
        }

        setSuccess(t("invite_sent"));

        handleClose();
        clearForm();
      }
      setSuggesstionsLoading(false);
    } else {
      await checkSlots();
    }
  };

  function convertToTimeString(seconds) {
    const date = new Date(seconds * 1000);
    const hours = date.getUTCHours().toString().padStart(2, "0");
    const minutes = date.getUTCMinutes().toString().padStart(2, "0");
    const formattedTime = `${hours}:${minutes}:00`;
    return formattedTime;
  }

  useEffect(() => {
    getParticipantStatus();
  }, [participants]);

  //change color of participants
  const getParticipantStatus = async () => {
    if (participants?.length <= participantLabels.length) {
      const temp = [...participantLabels];
      const filteredRecords = temp.filter((record) =>
        participants.includes(record.email)
      );
      setParticipantLabels(filteredRecords);
    } else {
      if (participants?.length > 0) {
        // const [lastEmail] = participants.slice(-1);

        const response = await serverPOST(`/contact/skedingstatus`, {
          participants,
        });
        if (response && response.constructor !== String) {
          // const temp = [...participantLabels, response.data];
          // const filteredRecords = temp.filter((record) =>
          //   participants.includes(record.email)
          // );
          setParticipantLabels(response.data);
        }
      }
    }
  };
  const handleCheck = () => {
    setSingleSlot((prev) => !prev);
  };

  const handleClose = () => {
    setSuccess("");
    if (previousURL === "/shareable-meeting") {
      setTimeout(() => {
        navigate(previousURL);
      }, 4000);
    } else {
      navigate("/meetings");
      return;
    }
  };

  const clearForm = () => {
    closeInput();
    setAgendas([]);
  };

  const handleTaskDraft = async () => {
    let start_datetime = formDate.valueOf();

    var loc =
      location === "Other"
        ? address
        : location === "Phone"
        ? phone
        : location === "Link"
        ? link
        : location;
    const duration_minutes =
      timeInterval === "custom"
        ? moment.duration(customTime).asMinutes()
        : moment.duration(timeInterval).asMinutes();

    const data = {
      title: title ? title : undefined,
      start_datetime,
      duration_minutes,
      time_interval: timeInterval,
      participants,
      time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      utc_offset: new Date().getTimezoneOffset(),
      location: loc,
      location_name: location,
      description: description ? description : undefined,
      meeting_room:
        location === "Other" ? (room ? room : undefined) : undefined,
      meeting_type: {
        ...meetingColorType,
        bgColor: findColor(undefined, undefined, undefined, true),
      },
    };

    if (!draft) {
      const response = await serverPOST("/draft", data);
      getDraftEvents();
    }
    clearForm();
  };

  return (
    <ResponsiveWrapper
      inputModal={inputModal}
      setMeetingModal={setMeetingModal}
    >
      <ErrorSnackbar
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        vertical={"bottom"}
        horizontal={"center"}
      />
      {singleSlot ? (
        <SnackbarComponent
          open={!!success}
          handleClose={handleClose}
          message={success}
          vertical={"bottom"}
          horizontal={"center"}
        />
      ) : (
        <Backdrop
          sx={{
            color: theme.palette.primary.main,
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={suggesstionsLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      {!isMobile && (
        <Box display="flex" justifyContent="flex-end">
          <IconButton sx={{ justifyContent: "flex-end" }} onClick={clearForm}>
            <CloseIcon />
          </IconButton>
        </Box>
      )}

      <Box mt={1} mb={1} className={classes.root}>
        <Box mb={2}>
          <Typography variant="h2" align="center">
            {t("create_meeting")}
          </Typography>
        </Box>

        {isFetched && handleIsLock(userAccount) ? (
          <div>{<Counter handleClose={() => closeInput(false)} />}</div>
        ) : null}
        {!singleSlot && (
          <Box mt={2}>
            <Progress stage={0} t={t} />
          </Box>
        )}

        <Box className={pclasses.inputsBox} mt={2}>
          <Box className={pclasses.inputsInnerBox}>
            <Typography variant="h3">{t("meeting_details")}</Typography>
          </Box>

          <Grid
            container
            rowSpacing={1}
            alignItems="center"
            className={pclasses.inputsContent}
          >
            <Grid item xs={12} md={1.5}>
              <InputLabel htmlFor="title" className={pclasses.mt10}>
                <Typography variant="h4">{t("title") + ":"}</Typography>
              </InputLabel>
            </Grid>
            <Grid item xs={12} md={10.5}>
              <Title setTitle={setTitle} title={title} />
            </Grid>

            <Grid item xs={12} md={1.5}>
              <InputLabel htmlFor="title" className={pclasses.mt10}>
                <Typography variant="h4" width={"100px"}>
                  {t("participants") + ":"}
                </Typography>
              </InputLabel>
            </Grid>
            <Grid item xs={12} md={10.5}>
              <Participants
                setParticipants={setParticipants}
                participants={participants}
                contacts={contacts}
                groups={groups}
                minimizedContacts={minimizedContacts}
                participantLabels={participantLabels}
              />
              <Grid display={"flex"} alignItems={"center"}>
                <ColorIndicator text={t("member")} color={"#B0E4FF"} />
                <ColorIndicator text={t("non_member")} color={"#D5D6D7"} />
              </Grid>
            </Grid>

            {isMobile ? (
              <>
                <Grid item xs={12} md={1}>
                  <InputLabel htmlFor="title" className={pclasses.mt10}>
                    <Typography variant="h4"> {t("duration") + ":"}</Typography>
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={11}>
                  <TimeInterval
                    timeInterval={timeInterval}
                    handleIntervalChange={handleIntervalChange}
                  />
                </Grid>
              </>
            ) : (
              <Grid container item xs={12} alignItems="center">
                <Grid item xs={12} md={1.5}>
                  <InputLabel htmlFor="title" className={pclasses.mt10}>
                    <Typography variant="h4"> {t("duration") + ":"}</Typography>
                  </InputLabel>
                </Grid>
                <Grid item xs={10.5}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                    alignItems={"center"}
                  >
                    <TimeInterval
                      timeInterval={timeInterval}
                      handleIntervalChange={handleIntervalChange}
                    />
                    <Box display="flex" alignItems={"center"} flex={0.8}>
                      <MeetingTypeColor
                        meetingType={meetingColorType}
                        handleMeetingTypeChange={handleMeetingTypeChange}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              //
            )}

            {timeInterval === "custom" ? (
              <Grid container item xs={12} alignItems="center">
                <Grid item xs={12} md={1.5}>
                  <InputLabel htmlFor="title" className={pclasses.mt10}>
                    <Typography variant="h4">{t("custom") + ":"}</Typography>
                  </InputLabel>
                </Grid>
                <Grid item xs={10.5}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <CustomInterval
                      customTime={customTime}
                      setCustomTime={setCustomTime}
                    />
                  </Box>
                </Grid>
              </Grid>
            ) : null}

            <Grid container item xs={12} alignItems="center">
              <Grid item xs={12} md={1.5}>
                <InputLabel htmlFor="title" className={pclasses.mt10}>
                  <Typography variant="h4">{t("location") + ":"}</Typography>
                </InputLabel>
              </Grid>
              <Grid item xs={10.5}>
                <Location
                  setLocation={setLocation}
                  location={location}
                  user={user}
                />
              </Grid>
            </Grid>

            {location === "Other" && (
              <>
                <Grid container item xs={12} alignItems="center">
                  <Grid item xs={12} md={1.5}>
                    <InputLabel htmlFor="title" className={pclasses.mt10}>
                      <Typography variant="h4">{t("address") + ":"}</Typography>
                    </InputLabel>
                  </Grid>
                  <Grid item xs={10.5}>
                    <PhysicalLocation
                      setAddress={setAddress}
                      address={address}
                    />
                  </Grid>
                </Grid>

                <Grid container item xs={12} alignItems="center">
                  <Grid item xs={12} md={1.5}>
                    <InputLabel htmlFor="title" className={pclasses.mt10}>
                      <Typography variant="h4">{t("room") + ":"}</Typography>
                    </InputLabel>
                  </Grid>
                  <Grid item xs={11} md={10.5}>
                    <RoomNo room={room} setRoom={setRoom} />
                  </Grid>
                </Grid>
              </>
            )}
            {location === "Phone" && (
              <>
                <Grid container item xs={12} alignItems="center">
                  <Grid item xs={12} md={1.5}>
                    <InputLabel htmlFor="title" className={pclasses.mt10}>
                      <Typography variant="h4">
                        {t("phone_number") + ":"}
                      </Typography>
                    </InputLabel>
                  </Grid>
                  <Grid item xs={10.5}>
                    <Phone setPhone={setPhone} phone={phone} />
                  </Grid>
                </Grid>
              </>
            )}
            {location === "Link" && (
              <>
                <Grid container item xs={12} alignItems="center">
                  <Grid item xs={12} md={1.5}>
                    <InputLabel htmlFor="title" className={pclasses.mt10}>
                      <Typography variant="h4">{t("virtual") + ":"}</Typography>
                    </InputLabel>
                  </Grid>
                  <Grid item xs={11} md={10.5}>
                    <VirtualLink setLink={setLink} link={link} />
                  </Grid>
                </Grid>
              </>
            )}
            <Grid container item xs={12}>
              <Grid
                item
                xs={12}
                md={1.5}
                justifyContent={"space-between"}
                display={"flex"}
                direction={"column"}
              >
                <InputLabel htmlFor="title" style={{ marginTop: "22px" }}>
                  <Typography variant="h4">{t("desc") + ":"}</Typography>
                </InputLabel>
                {/* {isMobile ? (
                  <></>
                ) : (
                  <Typography variant="h4"> {t("attachment")}:</Typography>
                )} */}
              </Grid>
              <Grid item xs={11} md={10.5}>
                <Description
                  setDescription={setDescription}
                  description={description}
                  showFile={true}
                  file={file}
                  setFile={setFile}
                  fileName={fileName}
                  setFileName={setFileNames}
                  fileNames={fileNames}
                  files={files}
                  setFiles={setFiles}
                  setFileNames={setFileNames}
                  isMobile={isMobile}
                />
              </Grid>
            </Grid>
            <MeetingAgenda
              participants={participants}
              maxInterval={timeInterval}
            />

            <SingleSlot
              singleSlot={singleSlot}
              handleCheck={handleCheck}
              handleDateChange={handleDateChange}
              formDate={formDate}
            />
          </Grid>
        </Box>

        <Grid
          container
          xs={12}
          justifyContent={"center"}
          columnSpacing={4}
          gap={4}
          sx={{ mt: 2 }}
        >
          <Button
            variant="outlined"
            onClick={handleTaskDraft}
            disabled={suggesstionsLoading && singleSlot}
          >
            {t("cancel")}
          </Button>
          <Button
            variant="contained"
            style={{
              padding: "6px 27px",
            }}
            onClick={handleNext}
            disabled={suggesstionsLoading && singleSlot}
          >
            {suggesstionsLoading && singleSlot ? (
              <CircularProgress style={{ color: "white" }} size={20} />
            ) : singleSlot ? (
              t("create")
            ) : (
              t("cont")
            )}
          </Button>
        </Grid>
      </Box>

      <NoCalendar modal={noCalendarModal} handleToggle={setNoCalendarModal} />
    </ResponsiveWrapper>
  );
}

export function ResponsiveWrapper({ children, inputModal, setMeetingModal }) {
  const mobile = useMediaQuery(useTheme().breakpoints.down("md"));
  const location = useLocation();
  return !mobile ? (
    <Dialog
      open={inputModal}
      onClose={() => setMeetingModal(false)}
      maxWidth={"lg"}
      fullWidth
      PaperProps={{ sx: { padding: 3, borderRadius: "16px" } }}
    >
      {children}
    </Dialog>
  ) : location.pathname === "/createmeeting" ? (
    <Box>{children}</Box>
  ) : null;
}
