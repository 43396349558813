import makeStyles from "@mui/styles/makeStyles";

const useStyle = makeStyles((theme) => ({
  mainContainer: {
    height: "auto",
    marginRight: 0,

    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      height: "88vh",
      marginRight: "16px",
      overflow: "hidden",
    },
  },

  calendarMobileTitle: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "space-between",
    width: "100%",
  },

  calendarTitle: {
    display: "flex",
    background: theme.palette.secondary.light,
    borderRadius: "6px",
    cursor: "pointer",
    padding: "4px 16px",
  },

  calendarButtons: {
    justifyContent: "center",
    marginTop: 0,
    marginBottom: 8,

    [theme.breakpoints.up("md")]: {
      justifyContent: "flex-end",
      marginTop: 16,
      marginBottom: 0,
      width: "80%",
    },
  },

  calendarHeader: {
    flexGrow: 1,
    overflow: "auto",
  },

  calendarUpcoming: {
    display: "flex",
    background: "transparent",
    borderRadius: 8,
    boxSizing: "border-box",
    flexDirection: "column",
    height: "auto",
    margin: "16px 0px",
    padding: 0,
    width: "100%",

    [theme.breakpoints.up("md")]: {
      display: "flex",
      background: "#F7F7F9",
      borderRadius: 8,
      boxSizing: "border-box",
      height: "88vh",
      flexDirection: "column",
      margin: "8px 0px",
      padding: "20px 12px",
      width: "300px",
    },
  },
}));

export default useStyle;
