import { useState } from "react";

import {
  Dialog,
  IconButton,
  Box,
  DialogContentText,
  DialogActions,
  Typography,
  TextField,
  Button,
  Snackbar,
  Alert,
  AlertTitle,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

import { serverPOST } from "../../Utils/HttpFunctions";
import loggingInDev from "loggingInDev";
import SnackbarComponent from "../Global/SnackbarComponent";
import useContactStore from "../../Utils/contactStore";
import useStyle from "./styles";
export default function CreateNewTeamDialog({
  createGroup,
  setCreateGroup,
  newGroupName,
  setNewGroupName,
  getGroups,
  groups,
  theme,
  setTeamCreated,
}) {
  const [proceed, setProceed] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [disabled, setDisabled] = useState(false);
  const { t } = useTranslation();
  const classes = useStyle();

  const { setMakeGroup, newGroupMembers, setNewGroupMembers } =
    useContactStore();

  const handleClose = () => {
    setCreateGroup(false);
    setDisabled(false);
  };

  const handleGroupName = (e) => {
    setNewGroupName(e.target.value);
  };

  /**
   * Validates new team name and creates new team if name is valid
   * Verifies that team name doesn't already exist
   */
  const handleProceedClick = () => {
    const duplicateName = groups.findIndex((group) => {
      return group.group_name === newGroupName;
    });

    if (duplicateName !== -1) {
      setError(true);
      setErrorMessage("A group with that name already exists");
      return;
    }

    switch (newGroupName.length !== 0) {
      case true:
        setDisabled(true);
        setError(false);
        setErrorMessage("");
        handleClose();
        createNewTeam();
        break;
      case false:
        setError(true);
        setErrorMessage("Please enter a group name.");
        break;
    }
  };

  /**
   * Makes a request to the backend to create a new team
   * @async
   */
  const createNewTeam = async () => {
    var data = {
      name: newGroupName,
      contacts: newGroupMembers.map((contact) => contact.id),
    };

    const response = await serverPOST("/contact/group", data);
    if (response && response.constructor !== String) {
      loggingInDev("accepted response =>", response);
      setTeamCreated(true);
      setNewGroupMembers([]);
      setProceed(true);
      setMakeGroup(false);
    } else {
      loggingInDev("error =>", response);
      setError(response);
    }
    setDisabled(false);
    getGroups();
  };

  /**
   * Clears parameters passed to createNewTeam when a team has been successfully created
   */
  const handleSuccessClose = () => {
    setProceed(false);
    setNewGroupName("");
    setNewGroupMembers([]);
  };

  return (
    <>
      <Dialog
        open={createGroup}
        onClose={handleClose}
        aria-labelledby="alert-new-group"
        aria-describedby="alert-create-new-group"
        maxWidth="xs"
        fullWidth
        PaperProps={{
          style: {
            padding: "12px",
            borderRadius: "16px",
            boxSizing: "border-box",
          },
        }}
      >
        <Box display="flex" justifyContent="flex-end" width="100%">
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContentText display="flex" justifyContent="center">
          <Typography variant="h2">{t("create_team")}</Typography>
        </DialogContentText>
        <DialogActions className={classes.dialogContent}>
          <Box display="flex" width="80%" alignItems="center" sx={{ pt: "5%" }}>
            <Typography width="25%" variant="h4">
              {t("name") + ":"}
            </Typography>
            <TextField
              id="new-group-name"
              variant="standard"
              placeholder={t("team_name")}
              value={newGroupName}
              error={error}
              helperText={errorMessage}
              onChange={handleGroupName}
              sx={{ width: "100%" }}
              InputProps={{
                disableUnderline: true,
                classes: { input: classes.textInput },
              }}
            />
          </Box>
          <Box sx={{ display: "flex", mt: "30px", columnGap: "20px" }}>
            <Button
              onClick={handleProceedClick}
              variant="submit"
              disabled={disabled}
            >
              {disabled ? (
                <CircularProgress style={{ color: "white" }} size={20} />
              ) : (
                t("proceed")
              )}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert severity="error">
          <AlertTitle style={{ textAlign: "left" }}>{t("error")}</AlertTitle>
          {"Invalid email found, group can only be created with valid email"}
        </Alert>
      </Snackbar>
      <SnackbarComponent
        open={proceed}
        handleClose={handleSuccessClose}
        message={t("team_created", { team: newGroupName })}
        vertical={"bottom"}
        horizontal={"center"}
      />
    </>
  );
}
