import { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import CreateNewTeamDialog from "./CreateNewTeamDialog";
import useContactStore from "../../Utils/contactStore";
import ToolTip from "../Global/ToolTip";

export default function CreateTeamButton({
  theme,
  newGroupName,
  setNewGroupName,
  getGroups,
  getContacts,
  mobile,
  groups,
  setInvalidTeam,
  setTeamCreated,
}) {
  const [createGroup, setCreateGroup] = useState(false);
  const { t } = useTranslation();
  const { makeGroup, setMakeGroup, newGroupMembers } = useContactStore();

  /**
   * Enables the make group button if the user has selected more than one contact
   */
  useEffect(() => {
    switch (newGroupMembers.length > 1) {
      case true:
        setMakeGroup(true);
        break;
      case false:
        setMakeGroup(false);
        break;
    }
  }, [newGroupMembers]);

  /**
   * Opens the CreateNewTeamDialog if the user has selected more than 2 team members
   * Required because the buttons aren't actually disabled when they're grayed out because Tooltips won't render on disabled components
   * Replicates functionality of a disabled button by doing nothing if the user hasn't selected at least 2 team members
   */
  const handleMakeGroup = () => {
    if (newGroupMembers.length < 2) {
      setInvalidTeam(true);
      return;
    } else {
      setInvalidTeam(false);
      setCreateGroup(true);
    }
  };

  return (
    <Box display="flex" justifyContent="flex-end">
      <Box flexDirection="row">
        <ToolTip
          title={
            "Select users from your contact list and click on ‘Create Team’ to create a team."
          }
          placement={"top-end"}
        >
          <Button
            variant="small-submit"
            onClick={handleMakeGroup}
            disabled={!makeGroup}
          >
            {t("create_team")}
          </Button>
        </ToolTip>
        <CreateNewTeamDialog
          theme={theme}
          createGroup={createGroup}
          setCreateGroup={setCreateGroup}
          newGroupName={newGroupName}
          setNewGroupName={setNewGroupName}
          getGroups={getGroups}
          getContacts={getContacts}
          mobile={mobile}
          groups={groups}
          setTeamCreated={setTeamCreated}
        />
      </Box>
    </Box>
  );
}
