import React, { SetStateAction, useEffect } from "react";
import { useState, useRef } from "react";

import {
  Checkbox,
  Avatar,
  Box,
  Typography,
  IconButton,
  Popover,
  useTheme,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useStyles } from "../../styles/popoverElement";
import { getContactInitials, stringToColor } from "../../Utils/CommonFunctions";
import { AllContactsProps, Contact } from "../../interfaces";
import useContactStore from "../../Utils/contactStore";

type SearchedContactsRowProps = AllContactsProps & {
  contact: Contact;
  small: boolean;
  contactMenu: boolean;
  anchorEl: HTMLElement | null;
  isInNewGroupMembers?: boolean;

  setAnchorEl: React.Dispatch<SetStateAction<HTMLElement | null>>;
  setContactMenu: React.Dispatch<SetStateAction<boolean>>;
  handleEdit: () => void;
  handleDelete: () => void;
  handleMoreClick: (e, contact: Contact) => void;
};

export default function SearchedContactsRow({
  contact,
  small,
  contactMenu,
  anchorEl,
  palette,
  inviteSent,
  invalidTeam,
  isInNewGroupMembers,
  handleDelete,
  handleMoreClick,
  handleEdit,
  setInvalidTeam,
  setAnchorEl,
  setContactMenu,
}: SearchedContactsRowProps) {
  const [checked, setChecked] = useState(false);
  const [contactInitials, setContactInitials] = useState<any[]>([]);
  const classes = useStyles();
  const checkboxRef: any = useRef(null);
  const theme = useTheme();

  const { groups, fetchingContacts, newGroupMembers, setNewGroupMembers } =
    useContactStore();

  /**
   * Focuses on the checkboxes if a user clicks create team without selecting > 2 team members
   */
  useEffect(() => {
    if (invalidTeam) {
      checkboxRef.current.focus();
      setTimeout(() => {
        checkboxRef.current.blur();
        setInvalidTeam(false);
      }, 3000);
    }
  }, [invalidTeam]);

  /**
   * Unchecks the checkboxes after a new group is created
   */
  useEffect(() => {
    setChecked(false);
  }, [groups]);

  /**
   * Unchecks boxes after an invite to join skeding has been sent to the selected contacts
   */
  useEffect(() => {
    if (inviteSent) setChecked(false);
  }, [inviteSent]);

  /**
   * Sets the initials in the contact avatars
   */
  useEffect(() => {
    if (!fetchingContacts) {
      let tempInitials = getContactInitials(contact);
      setContactInitials(tempInitials);
    }
  }, [fetchingContacts]);

  /**
   * Sets the searched contacts as checked if they were already checked before search
   */
  useEffect(() => {
    if (isInNewGroupMembers) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, []);

  /**
   * Sets the selected group members in the newGroupMembers array when the checkboxes are clicked
   * @param {MouseEvent} e - click event
   * @param {string} id - id of the selected contact (unique to the user's contact list, not the same across users)
   * @param {string} email - email of the selected contact
   * @param {boolean} is_skeding_user - determines if contact is already registered with skeding
   */
  const handleCheckbox = (e, id, email, is_skeding_user) => {
    console.log("handleCheckbox is called");
    setChecked((prev) => !prev);

    var tempSelected = [...newGroupMembers];
    switch (e.target.checked) {
      case true:
        tempSelected.push({
          id: id,
          email: email,
          is_skeding_user: is_skeding_user,
        });

        setNewGroupMembers([...tempSelected]);
        break;
      case false:
        var removeIndex = tempSelected.findIndex((removeId) => removeId === id);
        tempSelected.splice(removeIndex, 1);
        setNewGroupMembers([...tempSelected]);
        break;
    }
  };

  const handleClose = () => {
    setContactMenu(false);
    setAnchorEl(null);
  };

  return (
    <Box
      alignItems={"center"}
      sx={{
        pt: "20px",
        display: "flex",
        justifyContent: small ? "space-between" : null,
      }}
    >
      <Checkbox
        onChange={(e) =>
          handleCheckbox(
            e,
            contact._id,
            contact.contact_email,
            contact.is_contact_skeding_user ?? false
          )
        }
        checked={checked}
        disableRipple={true}
        sx={{
          "& .MuiSvgIcon-root": { fontSize: 16 },
          color: theme.palette.text.primary,
          pr: small ? "0px" : "28px",
          pl: "0px",
        }}
        ref={checkboxRef}
      />
      <Avatar
        src=""
        sx={
          small
            ? {
                height: "40px",
                width: "40px",
                mr: "20px",
                color: theme.palette.text.primary,
                fontWeight: "500",
                bgcolor: stringToColor(contact.contact_name, palette),
              }
            : {
                height: "40px",
                width: "40px",
                mr: "28px",
                fontSize: "16px",
                color: theme.palette.text.primary,
                fontWeight: "500",
                bgcolor: stringToColor(contact.contact_name, palette),
              }
        }
      >
        {contactInitials[0]?.toUpperCase()}
        {contactInitials.length > 1 ? contactInitials[1].toUpperCase() : null}
      </Avatar>
      {small ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            width: "45%",
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              align="left"
              variant="h3"
              sx={{
                fontWeight: "600",
                width: "max-content",
              }}
            >
              {contact.contact_name}
            </Typography>
            {contact.organization ? (
              <Typography
                variant="h4"
                fontWeight="400"
                pt="1px"
                width="max-content"
              >
                {`(${contact?.organization})`}
              </Typography>
            ) : null}
          </Box>
          <Typography variant="body1">{contact.contact_email}</Typography>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            // pl: "17px",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Typography variant="h3" align="left" width="50%">
            {contact.contact_name}
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
            // sx={{ pt: contact?.organization ? "7%" : null }}
          >
            <Typography variant="h3" fontWeight="400">
              {contact.contact_email}
            </Typography>
            <Typography
              sx={{
                color: theme.palette.text.disabled,
              }}
              variant="body1"
            >
              {contact?.organization}
            </Typography>
          </Box>
        </Box>
      )}
      <Box display={"flex"} justifyContent="flex-end">
        <IconButton
          onClick={(e) => handleMoreClick(e, contact)}
          data-id={contact._id}
          data-contact={contact}
          component="button"
          sx={{ color: theme.palette.text.primary, padding: "0px" }}
        >
          <MoreHorizIcon />
        </IconButton>
        <Popover
          open={contactMenu}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          PaperProps={{
            elevation: 1,
            sx: {
              width: 200,
              borderRadius: "8px",
            },
          }}
        >
          <Box onClick={handleEdit} className={classes.popOverElement}>
            <Typography sx={{ p: 2 }}>Edit</Typography>
          </Box>
          <Box onClick={handleDelete} className={classes.popOverElement}>
            <Typography sx={{ p: 2 }}>Delete</Typography>
          </Box>
        </Popover>
      </Box>
    </Box>
  );
}
