import { useState } from "react";

import { Box } from "@mui/material";

import LoginBoilerplate from "components/LoginBoilerplate";
import SignupVerification from "components/signup/SignupVerification";
import SignupForm from "components/signup/SignupForm";
import { serverPOST } from "Utils/HttpFunctions";
import loggingInDev from "loggingInDev";
import TermsConditons from "components/TermsConditions/TermsConditons";
import SnackbarComponent from "components/Global/SnackbarComponent";
import { useTranslation } from "react-i18next";

export default function InitialSignup() {
  const [signupClick, setSignupClick] = useState(false);
  const [email, setEmail] = useState("");
  const [showTerms, setShowTerms] = useState(false);
  const [resendDisable, setResendDisable] = useState(false);
  const [snackbar, setSnackbar] = useState(false);
  const [resendResponse, setResendResponse] = useState(false);
  const { t } = useTranslation();
  const [values, setValues] = useState({
    password1: "",
    password2: "",
    showPassword: false,
  });

  const resendEmail = async () => {
    setResendDisable(true);
    const response = await serverPOST("/auth/resend-email", {
      email: email,
    });
    if (response && response.constructor !== String) {
      if (response.code === 201) {
        loggingInDev("link sent =>", response);
        setSnackbar(true);
        setResendResponse(response);
        setResendDisable(false);
      }
    } else {
      loggingInDev("error =>", response);
      setSnackbar(true);
      setResendResponse(response);
      setResendDisable(false);
    }
  };

  return (
    <>
      <Box>
        <LoginBoilerplate
          formSize={6}
          form={
            signupClick ? (
              <SignupVerification
                email={email}
                resendEmail={resendEmail}
                resendDisable={resendDisable}
              />
            ) : (
              <SignupForm
                setSignupClick={setSignupClick}
                email={email}
                setEmail={setEmail}
                values={values}
                setValues={setValues}
                setShowTerms={setShowTerms}
              />
            )
          }
          title={signupClick ? "" : t("Welcome to Skeding") + "!"}
          image={true}
          setShowTerms={setShowTerms}
          showTerms={showTerms}
        />
      </Box>
      <SnackbarComponent
        vertical={"bottom"}
        horizontal={"center"}
        handleClose={() => setSnackbar(false)}
        open={snackbar}
        message={
          resendResponse.code === 201
            ? t("signup_sent")
            : resendResponse.message
        }
        deleteUnsuccessful={resendResponse.code === 201 ? false : true}
      />
    </>
  );
}
