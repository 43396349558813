import React, { useState, useEffect, useContext } from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Typography, Grid, Box, useTheme, useMediaQuery } from "@mui/material";
import { motion } from "framer-motion";
import timeGridPlugin from "@fullcalendar/timegrid";
import Style from "style-it";
import moment from "moment";

import { authContext } from "context/Auth";
import { dayHeader, downloadFileAttachment } from "Utils/CommonFunctions";
import { calendarStyles } from "styles/calendarStyles";
import { meetingsContext } from "context/meetings";
import { themeContext } from "context/ThemeColorProvider";
import useConfirmMeetings from "../../hooks/useConfirmMeetings";
import MeetingDetailsMyMeetings from "./MeetingDetailsMyMeetings";
import ScrollButton from "components/ScrollButton";
import Upcoming from "components/Dashboard/Upcoming";
import MeetingsCalendarNavigation from "components/meetings/MeetingsCalendarNavigation";
import { useTranslation } from "react-i18next";

/**
 * Renders the calendar on the Pending Meetings page
 */
export default function MeetingsCalendar({ calendarRef }) {
  const { pendingMeetings, meetingInvites, websiteBookings } =
    useContext(meetingsContext);
  const { isLogged } = useContext(authContext);
  const { mode } = useContext(themeContext);
  const { t, i18n } = useTranslation();

  const { plannedEvents, setPlannedEvents } = useConfirmMeetings();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  const [isOpen, setIsOpen] = useState(false);
  const [selectedBtn, setSelectedBtn] = useState(2);
  const [title, setTitle] = useState("");
  const [tab, setTab] = useState(null);
  const [clickedEvent, setClickedEvent] = useState({
    start: {},
    participants: [],
  });
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const currentLanguage = i18n.language;

  /**
   * Sets the default view on mobile to month view
   */
  useEffect(() => {
    if (mobile) {
      calendarRef.current.getApi().changeView("dayGridMonth");
    } else {
      calendarRef.current.getApi().changeView("timeGridWeek");
    }
  }, [mobile]);

  /**
   * Moves to the tab corresponding to type (pending/invite/website booking)
   */
  useEffect(() => {
    handleTab();
  }, [clickedEvent]);

  /**
   * Gets the calendar month and year to set the calendar title
   */
  useEffect(() => {
    getTitle();
  }, [calendarRef, mobile]);

  /**
   * Moves the calendar to the date clicked on the calendar
   * @param {object} dateClickInfo
   */
  const handleDateClick = (dateClickInfo) => {
    calendarRef.current.getApi().changeView("timeGridDay", dateClickInfo.date);
    setSelectedBtn(1);
  };

  /**
   * Sets the selected date when a date is clicked on the calendar in mobile view
   */
  const handleMobileDateClick = (e) => {
    setSelectedDate(e.dateStr);
  };

  /**
   * Sets the info for the event that's clicked to be shown in MeetingDetailsMyMeetings component
   * @param {object} eventClickInfo
   */
  const handleEventClick = async (eventClickInfo) => {
    var eventId = eventClickInfo.event._def.publicId;
    setIsOpen(true);

    let event = await pendingMeetings.find((event) => event.id === eventId);
    if (event) {
      setClickedEvent({ event: event, source: "event" });
    } else {
      let event = await meetingInvites.find((event) => event.id === eventId);
      if (event) {
        setClickedEvent({ event: event, source: "event" });
      } else {
        let event = await websiteBookings.find((event) => event.id === eventId);
        if (event) {
          setClickedEvent({ event: event, source: "event" });
        } else {
          let event = await plannedEvents.find((event) => event.id === eventId);
          if (event) {
            setClickedEvent({ event: event, source: "event" });
          }
        }
      }
    }
  };

  const onClose = () => {
    setIsOpen(false);
  };

  /**
   * Gets the title using the FullCalendar api from the FullCalendar reference
   */
  const getTitle = () => {
    if (mobile) {
      setTitle(
        calendarRef.current?.getApi()?.getDate().toLocaleDateString("default", {
          month: "long",
          day: "numeric",
          year: "numeric",
        })
      );
    } else {
      setTitle(
        calendarRef.current?.getApi()?.getDate().toLocaleDateString("default", {
          month: "long",
          day: "numeric",
          year: "numeric",
        })
      );
    }
  };

  /**
   * Downloads attached files for a given event
   * @param {string} fileName
   * @param {string} eventId
   */
  const handleDownload = async (fileName, eventId) => {
    await downloadFileAttachment(fileName, eventId);
  };

  /**
   * Sets the tab in the My Meetings card to the one corresponding to
   * the event type clicked on the calendar
   */
  const handleTab = () => {
    if (clickedEvent) {
      let invite = meetingInvites.find((invite) => {
        return clickedEvent.event?.id === invite.id;
      });

      let booking = websiteBookings.find((booking) => {
        return clickedEvent.event.id === booking.id;
      });

      if (invite) {
        setTab(1);
      } else if (booking) {
        setTab(2);
      } else {
        setTab(0);
      }
    }
  };

  return (
    isLogged && (
      <Style>
        {calendarStyles(mode, mobile)}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="home"
        >
          <Box
            sx={{
              overFlow: "hidden",
              height: mobile ? "auto" : "75vh",
              flexGrow: 1,
              p: mobile ? 0 : "24px 20px",
              boxShadow: "0",
              borderRadius: "10px",
              backgroundColor: mobile
                ? "transparent"
                : theme.palette.secondary.light,
            }}
            display="flex"
            flexDirection="column"
          >
            {!mobile && (
              <Typography variant="h3" align="left">
                {t("your") + " " + t("meetings")}
              </Typography>
            )}

            {mobile ? (
              <Grid
                container
                item
                xs={12}
                md={8}
                sx={{
                  justifyContent: { xs: "center", md: "flex-start" },
                  alignItems: "baseline",
                }}
              >
                <Box
                  display="flex"
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center"
                  style={{ cursor: "pointer" }}
                >
                  <ScrollButton
                    direction="prev"
                    calendarRef={calendarRef}
                    homePage={true}
                    callback={getTitle}
                  />
                  <Typography
                    color={theme.palette.primary.main}
                    variant="h2"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      calendarRef?.current?.getApi()?.gotoDate(new Date())
                    }
                  >
                    {title}
                  </Typography>
                  <ScrollButton
                    direction="next"
                    calendarRef={calendarRef}
                    homePage={true}
                    callback={getTitle}
                  />
                </Box>
              </Grid>
            ) : (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                columnGap="2px"
                marginTop="12px"
              >
                <MeetingsCalendarNavigation
                  setSelectedBtn={setSelectedBtn}
                  selectedBtn={selectedBtn}
                  calendarRef={calendarRef}
                  t={t}
                />
              </Box>
            )}
            {clickedEvent.event !== undefined ? (
              <MeetingDetailsMyMeetings
                open={isOpen}
                setIsOpen={setIsOpen}
                event={clickedEvent.event}
                setPlannedEvents={setPlannedEvents}
                tab={tab}
                plannedEvents={plannedEvents}
                handleClose={onClose}
                handleDownload={handleDownload}
                title={
                  tab === 0
                    ? t("meeting_details")
                    : tab === 1
                    ? t("meeting_invite")
                    : t("web_bookings")
                }
              />
            ) : null}
            <Box
              component="div"
              sx={{
                overflow: "auto",
                flexGrow: 1,
                // paddingLeft: "10px",
                // paddingRight: "10px",
              }}
            >
              <FullCalendar
                plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
                initialView="timeGridWeek"
                locale={currentLanguage}
                ref={calendarRef}
                height={mobile ? "270px" : "100%"}
                headerToolbar={false}
                eventSources={
                  mobile
                    ? []
                    : [
                        // plannedEvents?.filter(
                        //   (obj) =>
                        //     !pendingMeetings?.some(
                        //       (arr1Obj) => arr1Obj.id === obj.id
                        //     ) &&
                        //     !websiteBookings?.some(
                        //       (arr2Obj) => arr2Obj.id === obj.id
                        //     )
                        // ),
                        pendingMeetings,
                        meetingInvites,
                        websiteBookings,
                        // searchEvents,
                      ]
                }
                eventColor="#ABE0FB"
                eventTextColor="#3C4242"
                eventClick={handleEventClick}
                eventDisplay="block"
                eventOrderStrict={true}
                slotEventOverlap={false}
                displayEventTime={false}
                views={{
                  timeGridWeek: {
                    dayHeaderContent: (date) => dayHeader(date, "week"),
                  },
                  timeGridDay: {
                    dayHeaderContent: (date) => dayHeader(date, "day"),
                  },
                }}
                titleFormat={{
                  month: "long",
                  year: "numeric",
                }}
                slotDuration={"01:00:00"}
                expandRows={"true"}
                slotLabelFormat={{
                  hour: "numeric",
                  hour12: true,
                }}
                scrollTime={"9:00:00"}
                slotMinTime={"00:00:00"}
                slotMaxTime={"24:00:00"}
                dateClick={mobile ? handleMobileDateClick : handleDateClick}
                allDaySlot={false}
              />
            </Box>
          </Box>

          <Box mt={2} />
          {mobile && (
            <Upcoming
              plannedEvents={[]
                .concat(pendingMeetings)
                .concat(meetingInvites)
                // .concat(plannedEvents)
                .concat(websiteBookings)}
              clickedEvent={clickedEvent}
              setClickedEvent={setClickedEvent}
              setIsOpen={setIsOpen}
              isOpen={isOpen}
              myMeetings={true}
              selectedDate={selectedDate}
            />
          )}
        </motion.div>
      </Style>
    )
  );
}
