import React, { useContext, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import DialogWithCloseIcon from "../../components/DialogWithCloseIcon";
import CancelSubscriptionDialog from "./CancelSubscriptionDialog";
import CancelReasonDialog from "./CancelReasonDialog";
import ErrorSnackbar from "../../components/ErrorSnackbar";
import ClearIcon from "@mui/icons-material/Clear";
import { useNavigate, useSearchParams } from "react-router-dom";
import { accountContext } from "../../context/user";
import moment from "moment";
import RenewSubscription from "./RenewSubscription";
import PaymentDetails from "./PaymentDetails";
import CancelSeatsDialog from "./CancelSeatsDialog";
import UpgradeSubsciption from "./UpgradeSubsciption";

export default function PlanDetails() {
  const [dialog, setDialog] = useState(false);
  const [reasonDialog, setReasonDialog] = useState(false);
  const [renewDialog, setRenewDialog] = useState(false);
  const [upgradeDialog, setUpgradeDialog] = useState(false);
  const [success, setSuccess] = useState("");
  const { user } = useContext(accountContext) as any;
  const [searchParams] = useSearchParams();
  const paymentFailed = searchParams.get("cancelled");
  const { t } = useTranslation();
  const status =
    user?.package?.is_cancelled?.current_status === "paid" &&
    !user?.package?.is_cancelled?.cancel_date;
  const [isCancelled] = useState(() => paymentFailed);
  const today = moment();
  const [cancelDialog, setCancelDialog] = useState(false);
  const navigate = useNavigate();

  const handleCancelDialog = () => {
    setCancelDialog(!cancelDialog);
  };

  const openDialog = () => {
    setDialog(true);
  };

  const handleClose = () => {
    setDialog(false);
  };

  const openReasonDialog = () => {
    setReasonDialog(true);
  };

  const handleReasonClose = () => {
    setReasonDialog(false);
  };

  const handleCancelSubscription = () => {
    navigate("/manage-members");
  };

  return (
    <>
      {status ? (
        <Grid container direction={"column"}>
          <Typography variant="h3">{t("current_package_plan")}</Typography>
          {isCancelled !== "true" ? null : (
            <Grid
              item
              sx={{
                border: "1px solid #FF2B2B",
              }}
              borderRadius={2}
              p={2}
              my={2}
            >
              <Grid item display={"flex"} justifyContent={"flex-end"}>
                <ClearIcon fontSize="small" />
              </Grid>
              <Typography variant="h3" fontWeight={400} textAlign={"left"}>
                <span style={{ fontWeight: "700", color: "#FF2B2B" }}>
                  {t("payment_failed_title")}:
                </span>{" "}
                {t("payment_failed_desc")}
              </Typography>
            </Grid>
          )}

          <PaymentDetails userPackage={user.package} />
          <Grid>
            {/* <Button
              variant="contained"
              sx={{
                backgroundColor: isCancelled === "true" ? "#F14336" : "",
              }}
              onClick={() => {}}
            >
              Update payment details
            </Button> */}

            <Typography
              sx={{
                textDecoration: "underline",
                cursor: "pointer",
              }}
              variant="h4"
              mt={2}
              onClick={() => setUpgradeDialog(true)}
            >
              {t("Upgrade Subscription")}
            </Typography>

            <Typography
              sx={{
                textDecoration: "underline",
                cursor: "pointer",
              }}
              variant="h4"
              mt={2}
              onClick={openDialog}
            >
              {t("cancel_this_plan")}
            </Typography>

            <Typography
              sx={{
                textDecoration: "underline",
                cursor: "pointer",
              }}
              variant="h4"
              mt={2}
              onClick={handleCancelSubscription}
            >
              {t("cancel_subscription")}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid container direction={"column"} gap={2}>
          <Typography variant="h3">{t("renew_subscription")}</Typography>
          <Typography variant="h4" fontWeight={400} lineHeight={"22.4px"}>
            Your <span style={{ fontWeight: "600" }}>Skeding Premium</span>{" "}
            subscription was cancelled and{" "}
            <span>
              {moment(user?.package?.renewal_date).diff(today) > 0
                ? "will downgrade "
                : "was downgraded "}
            </span>
            to Skeding Free plan on{" "}
            <span style={{ fontWeight: "600" }}>
              {moment(user?.package?.renewal_date).format("MMM DD, YYYY")}
            </span>
            .
          </Typography>
          <Typography variant="h4" fontWeight={400} lineHeight={"22.4px"}>
            {`Your can renew your subscription after ${moment(
              user?.package?.renewal_date
            ).format("MMM DD, YYYY")}`}
          </Typography>
          {user?.package?.renewal_status && (
            <Typography variant="h4" fontWeight={400} lineHeight={"22.4px"}>
              {`Your next subscription will begin on ${moment(
                user?.package?.renewal_date
              ).format("MMM DD, YYYY")}`}
            </Typography>
          )}
          {/* <Grid item>
            <Button
              variant="contained"
              onClick={handleRenewClick}
              disabled={user?.package?.renewal_status}
            >
              {t("renew_subscription")}
            </Button>
          </Grid> */}
        </Grid>
      )}

      {/* Dialog to be used elsewhere placed here for testing */}
      {/* <DialogWithCloseIcon
        open={dialog}
        handleClose={handleClose}
        dialogSize={"sm"}
      >
        <FreeTrialDialog
          dialogTitle={t("free_trial")}
          dialogDesc={t("free_trial_desc")}
        />
      </DialogWithCloseIcon> */}
      <ErrorSnackbar
        open={!!success}
        handleClose={() => setSuccess("")}
        message={success}
        vertical={"bottom"}
        horizontal={"center"}
        duration={undefined}
      />

      <DialogWithCloseIcon
        open={dialog}
        handleClose={handleClose}
        dialogSize={"md"}
      >
        <CancelSubscriptionDialog
          openReasonDialog={openReasonDialog}
          handleMainDialogCancel={handleClose}
        />
      </DialogWithCloseIcon>

      <DialogWithCloseIcon
        open={reasonDialog}
        handleClose={handleReasonClose}
        dialogSize={undefined}
      >
        <CancelReasonDialog
          closeReasonDialog={handleReasonClose}
          setSuccess={setSuccess}
        />
      </DialogWithCloseIcon>

      <DialogWithCloseIcon
        open={renewDialog}
        handleClose={() => setRenewDialog(false)}
        dialogSize={"sm"}
      >
        <RenewSubscription />
      </DialogWithCloseIcon>

      <DialogWithCloseIcon
        open={cancelDialog}
        handleClose={handleCancelDialog}
        dialogSize={"sm"}
      >
        <CancelSeatsDialog />
      </DialogWithCloseIcon>

      <DialogWithCloseIcon
        open={upgradeDialog}
        handleClose={() => setUpgradeDialog(false)}
        dialogSize={"xs"}
      >
        <UpgradeSubsciption handleClose={() => setUpgradeDialog(false)} />
      </DialogWithCloseIcon>
    </>
  );
}
