import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ReactJoyride, { ACTIONS, EVENTS } from "react-joyride";
import { useContext } from "react";
import { meetingContext } from "./NewMeeting";

export const tourContext = React.createContext();

export default function TourProvider({ children }) {
  const [run, setRun] = useState(false);
  // const { setMeetingModal } = useContext(meetingContext);

  // const [steps, setSteps] = useState([
  //   {
  //     target: "#calendar",
  //     content:
  //       "View all your scheduled meetings, breaks, invites and events details.",
  //     disableBeacon: true,
  //     title: "My Calendar",
  //     placement: "right",
  //   },
  //   {
  //     target: "#calendar-week",
  //     content:
  //       "Easily switch to see daily, weekly or monthly view of your calendar. Click on Month to see how it works!",
  //     disableBeacon: true,
  //     title: "Change Calendar View",
  //   },
  //   {
  //     target: "#instant",
  //     content:
  //       "Enter participants, location and agenda to start the meeting right away.",
  //     disableBeacon: true,
  //     title: "Set Up Instant Meeting",
  //   },
  //   {
  //     target: "#search-bar",
  //     content: "Search by Name, Company Name or Email Address",
  //     disableBeacon: true,
  //     title: "Search Easily",
  //     placement: "bottom",
  //   },
  //   {
  //     target: "#new-meeting",
  //     content:
  //       "Set up new meeting and send invite to participants in or outside skeding",
  //     disableBeacon: true,
  //     title: "New Meeting",
  //     placement: "right",
  //   },
  //   {
  //     target: "#create-form",
  //     content:
  //       "Enter the information as per your requirements in the text fields to schedule meeting",
  //     disableBeacon: true,
  //     title: "Create Event",
  //   },
  //   {
  //     target: "#create-calendar",
  //     content:
  //       "Check availability of participants at any given time by hovering over the boxes in the calendar",
  //     disableBeacon: true,
  //     title: "Setting Up Time",
  //     placement: "right",
  //   },
  //   {
  //     target: "#create-calendar-arrows",
  //     content:
  //       "You can click on these arrows to see dates from next or last week or month",
  //     disableBeacon: true,
  //     title: "Move throw arrows",
  //   },
  //   {
  //     target: "#create-calendar-date",
  //     content:
  //       "Choose any date, month or year from the dropdown by clicking and search easily",
  //     disableBeacon: true,
  //     title: "Select Date and Time",
  //   },
  //   //recommendations 2
  //   {
  //     target: "#preferences",
  //     content:
  //       "Set up your preferences for notifications, breaks between each meeting and unavailability here",
  //     disableBeacon: true,
  //     title: "Preferences",
  //     placement: "right",
  //   },
  //   {
  //     target: "#preferences-break",
  //     content:
  //       "Set up your preferred time interval for breaks between each meeting to prepare for the next one",
  //     disableBeacon: true,
  //     title: "Breaks between Meetings",
  //   },
  //   {
  //     target: "#preferences-calendar",
  //     content:
  //       "Select time or day that you will not be available for meetings and skeding shall schedule your calendar accordingly",
  //     disableBeacon: true,
  //     title: "Unavailability for Meetings",
  //     placement: "left",
  //   },
  //   {
  //     target: "#contacts",
  //     content:
  //       "Manage your contacts list, groups, subgroups and/or update their info if needed",
  //     disableBeacon: true,
  //     title: "Contacts",
  //     placement: "right",
  //   },
  //   {
  //     target: "#addcontact",
  //     content:
  //       "You can create new contact by adding their info (name, email address)",
  //     disableBeacon: true,
  //     title: "Create New Contact",
  //   },
  //   {
  //     target: "#filter-contact",
  //     content:
  //       "You can filter contacts by their name and organization.You can also search for skeding or non-skeding users through filter.",
  //     disableBeacon: true,
  //     title: "Search for Contact",
  //   },
  //   //contact list 1
  //   {
  //     target: "#integrations",
  //     content: "Manage all your connected apps and calendar",
  //     disableBeacon: true,
  //     title: "Integrations",
  //     placement: "right",
  //   },
  //   {
  //     target: "#faq",
  //     content: "If you have any questions/queries, check our FAQs section.",
  //     disableBeacon: true,
  //     title: "FAQs",
  //     placement: "right",
  //   },
  // ]);
  // const [initialStep, setInitialStep] = useState(4);

  // let navigate = useNavigate();

  // const callbackHandler = (data) => {
  //   console.log("joyride data", data);
  //   if (
  //     data.action === "close" &&
  //     data.lifecycle === "complete" &&
  //     data.type === "step:after"
  //   ) {
  //     setRun(false);
  //   }
  //   if (data.index === 4 && data.type === "step:after") {
  //     setMeetingModal(true);
  //   }
  //   if (data.index === 4 && data.type === "step:before") {
  //     navigate("/newmeeting", { replace: true });
  //   }
  //   if (data.index === 9 && data.type === "step:before") {
  //     navigate("/preferences", { replace: true });
  //   }
  //   if (data.index === 12 && data.type === "step:before") {
  //     navigate("/contacts", { replace: true });
  //   }
  //   if (data.index === 15 && data.type === "step:before") {
  //     navigate("/integrations", { replace: true });
  //   }
  //   if (data.index === 16 && data.type === "step:before") {
  //     navigate("/faqs", { replace: true });
  //   }
  // };

  // const customLocale = {
  //   next: "Continue",
  //   skip: "Skip tour",
  // };

  return (
    <tourContext.Provider
      value={{
        run,
        // steps,
        setRun,
        // initialStep,
        // callbackHandler,
        // setInitialStep,
      }}
    >
      {/* <ReactJoyride
        continuous
        showSkipButton
        hideBackButton
        run={run}
        steps={steps}
        scrollToFirstStep
        locale={customLocale}
        callback={callbackHandler}
        spotlightClicks={false}
        showProgress={false}
        styles={{
          buttonNext: {
            backgroundColor: "#479AE9",
            width: 113,
            padding: "6px 28px",
          },
          buttonSkip: {
            border: "1px solid #70757A",
            borderRadius: "6px",
            width: 113,
            padding: "6px 28px",
          },
          buttonClose: {
            display: "none",
          },
          spotlight: {
            display: "none",
          },
          options: {
            zIndex: 10000,
          },
          tooltip: {
            borderRadius: 10,
          },
          tooltipContainer: {
            // textAlign: "start",
            paddingTop: 10,
            paddingBottom: 10,
          },
          tooltipTitle: {
            // margin: "0 0 0 8px",
            display: "flex",
            justifyContent: "center",
          },
          tooltipContent: {
            display: "flex",
            justifyContent: "center",
            padding: "14px 10px",
            color: "black",
            fontSize: 15,
            // fontWeight: 500,
          },
          tooltipFooter: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "0 20px",
            // flexDirection: "column-reverse",
            // marginTop: 5,
          },
        }}
      /> */}
      {children}
    </tourContext.Provider>
  );
}
