import { IconButton, useTheme } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

export default function ScrollButton({ direction, calendarRef, setTitle }) {
    const theme = useTheme();

    const handleClick = () => {
      switch (direction === "prev") {
        case true:
          setTitle(calendarRef?.current?.getApi()?.prev());
          break;
        case false:
          setTitle(calendarRef?.current?.getApi()?.next());
          break;
      }
    };
  
    return (
      <IconButton onClick={handleClick} pt="0">
        {direction === "prev" ? (
          <ChevronLeftIcon
            fontSize="large"
            style={{ color: theme.palette.text.primary }}
          />
        ) : (
          <ChevronRightIcon
            fontSize="large"
            style={{ color: theme.palette.text.primary }}
          />
        )}
      </IconButton>
    );
  }