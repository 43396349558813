import { useEffect, useRef, useState } from "react";

import {
  Box,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { handleEmailValidation } from "Utils/CommonFunctions";
import { useStyle } from "../../styles/dialogFormStyle";
import { serverPUT } from "Utils/HttpFunctions";
import loggingInDev from "loggingInDev";
import DialogWithSuccessMessage from "components/DialogWithSuccessMessage";
import { useTranslation } from "react-i18next";
import SnackbarComponent from "../Global/SnackbarComponent";
import puseStyle from "./styles";

export default function EditContactsDialog({
  dialog,
  setDialog,
  selectedContact,
  setRefreshContacts,
  setContactMenu,
}) {
  const { t } = useTranslation();
  const [updatedContactName, setUpdatedContactName] = useState(
    selectedContact.contact_name ?? ""
  );
  const [updatedContactEmail, setUpdatedContactEmail] = useState(
    selectedContact.contact_email ?? ""
  );
  const [updatedContactOrganization, setUpdatedContactOrganization] = useState(
    selectedContact.organization ?? ""
  );
  const [disabled, setDisabled] = useState(false);
  const [formIncomplete, setFormIncomplete] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const organizationRef = useRef(null);
  const [snackbar, setSnackbar] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (nameError || emailError) {
      setFormIncomplete(true);
    } else setFormIncomplete(false);
  }, [nameError, emailError]);

  const handleUpdateContact = async () => {
    if (
      organizationRef.current !== null &&
      organizationRef.current.value === ""
    ) {
      setUpdatedContactOrganization(null);
    }

    setDisabled(true);
    const body = {
      contact_name: updatedContactName
        ? updatedContactName
        : selectedContact.contact_name,
      contact_email: updatedContactEmail
        ? updatedContactEmail
        : selectedContact.contact_email,
      organization: updatedContactOrganization,
    };

    const response = await serverPUT(`/contact/${selectedContact._id}`, body);
    if (response && response.constructor !== String) {
      loggingInDev(response.message);
      setRefreshContacts(true);
      setDisabled(false);
      setSnackbar(true);

      setMessage(t("snackbar_messages.2"));
      return true;
    } else {
      loggingInDev(response.message);

      setDisabled(false);
      return false;
    }
  };

  const clearFormData = () => {
    setContactMenu(false);
    setUpdatedContactName("");
    setUpdatedContactEmail("");
    setUpdatedContactOrganization("");
  };

  return (
    <>
      <DialogWithSuccessMessage
        open={dialog}
        setOpen={setDialog}
        message={"Edit Contact"}
        operation={handleUpdateContact}
        successMessage={t("snackbar_messages.2")}
        closeAction={clearFormData}
        disabled={disabled}
        formIncomplete={formIncomplete}
        noMainHeading={true}
        form={
          <EditContactsForm
            selectedContact={selectedContact}
            setUpdatedContactName={setUpdatedContactName}
            setUpdatedContactEmail={setUpdatedContactEmail}
            setUpdatedContactOrganization={setUpdatedContactOrganization}
            emailError={emailError}
            setEmailError={setEmailError}
            nameError={nameError}
            setNameError={setNameError}
            nameRef={nameRef}
            emailRef={emailRef}
            organizationRef={organizationRef}
            t={t}
          />
        }
        type="edit"
      />
      <SnackbarComponent
        open={snackbar}
        handleClose={() => setSnackbar(false)}
        message={message}
        vertical={"bottom"}
        horizontal={"center"}
      />
    </>
  );
}

function EditContactsForm({
  selectedContact,
  setUpdatedContactName,
  setUpdatedContactEmail,
  emailError,
  setEmailError,
  nameError,
  setNameError,
  nameRef,
  emailRef,
  t,
}) {
  const [emailHelperText, setEmailHelperText] = useState("");
  const classes = useStyle();
  const pclasses = puseStyle();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleFormData = (e, setter) => {
    setter(e.target.value);
  };

  const handleNameValidation = () => {
    if (nameRef.current.value === "") {
      setNameError(true);
    } else {
      setNameError(false);
    }
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        width="95%"
        mb="35px"
        rowGap="10px"
      >
        <Box
          display={"flex"}
          alignItems={nameError ? "baseline" : "center"}
          columnGap="5px"
          width="100%"
        >
          <Typography variant="h3" width={mobile ? "25%" : "15%"}>
            {t("name")}
          </Typography>
          <TextField
            placeholder={t("contact_name") + " * "}
            variant="standard"
            defaultValue={selectedContact.contact_name}
            sx={{ width: "100%", marginLeft: 1 }}
            InputProps={{
              disableUnderline: true,
              classes: { input: `${classes.input} ${pclasses.textInput}` },
            }}
            onChange={(e) => handleFormData(e, setUpdatedContactName)}
            onBlur={handleNameValidation}
            error={nameError}
            helperText={nameError ? t("username_err") : null}
            id="modify-user-name"
            inputRef={nameRef}
          />
        </Box>

        <Box
          display={"flex"}
          alignItems={emailError ? "baseline" : "center"}
          columnGap="5px"
          width="100%"
        >
          <Typography variant="h3" width={mobile ? "25%" : "15%"}>
            {t("email")}
          </Typography>
          <TextField
            placeholder={t("email") + " " + t("address") + " " + "*"}
            variant="standard"
            defaultValue={selectedContact.contact_email}
            sx={{ width: "100%", marginLeft: 1 }}
            InputProps={{
              disableUnderline: true,
              classes: { input: `${classes.input} ${pclasses.textInput}` },
            }}
            onChange={(e) => handleFormData(e, setUpdatedContactEmail)}
            onBlur={() =>
              handleEmailValidation(emailRef, setEmailError, setEmailHelperText)
            }
            error={emailError}
            helperText={emailHelperText}
            id="modify-user-email"
            inputRef={emailRef}
          />
        </Box>
      </Box>
    </>
  );
}
