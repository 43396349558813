import React from "react";
import { TextField, useTheme } from "@mui/material";
import useStyle from "../styles/formStyles";
import { useTranslation } from "react-i18next";

export const RoomNo = React.memo(
  ({ setRoom, room, isService }: { room: string; setRoom; isService? }) => {
    const classes = useStyle();
    const handleRoomChange = (event) => {
      setRoom(event.target.value);
    };
    const theme = useTheme();
    const { t } = useTranslation();

    return (
      <TextField
        placeholder={t("add_room_no")}
        variant="standard"
        autoComplete="off"
        value={room}
        sx={{ width: "100%" }}
        autoFocus
        InputProps={{
          disableUnderline: true,
          style: {
            color: theme.palette.text.primary,
            paddingLeft: "8px",
            marginTop: isService ? "" : 10,
            backgroundColor: isService ? "#F7F7F9" : "",
            borderRadius: 6,
          },
          classes: { input: classes.input },
        }}
        onChange={handleRoomChange}
        id="create-form"
      />
    );
  }
);
