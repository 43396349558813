import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { serverPOST } from "../Utils/HttpFunctions";

export default function useParticipantStatus(participants) {
  const [participantLabels, setParticipantLabels] = useState([]);

  useEffect(() => {
    getParticipantStatus();
  }, [participants]);

  //change color of participants
  const getParticipantStatus = async () => {
    if (participants.length <= participantLabels.length) {
      const temp = [...participantLabels];
      const filteredRecords = temp.filter((record) =>
        participants.includes(record.email)
      );
      setParticipantLabels(filteredRecords);
    } else {
      if (participants.length > 0) {
        const response = await serverPOST(`/contact/skedingstatus`, {
          participants,
        });
        if (response && response.constructor !== String) {
          setParticipantLabels(response.data);
        }
      }
    }
  };
  return [participantLabels];
}
