import {
  Box,
  Button,
  DialogActions,
  Grid,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { LeftLayout, RightLayout } from "../../components/TwoSectionLayout";
import useStyle from "../../styles/formStyles";
import puseStyle from "./styles";
import { serverGET, serverDELETE } from "../../Utils/HttpFunctions";
import { useTranslation } from "react-i18next";
import DialogWithCloseIcon from "../../components/DialogWithCloseIcon";
import CustomTabPanel from "../../components/Global/CustomTabPanel";
import { CreateBulkEmail } from "../../components/BulkEmail/CreateBulkEmail";
import BulkEmailTemplates from "../../components/BulkEmail/BulkEmailTemplates";
import EmailPreview from "../../components/AddToWebsite/Preview/EmailPreview";
import History from "../../components/Email/History";
import useBulkEmailStore from "../../Utils/bulkEmailStore";
import SnackbarComponent from "../../components/Global/SnackbarComponent";

export default function Email() {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();
  const classes = useStyle();
  const pclasses = puseStyle();
  const tabIndex = useBulkEmailStore((store) => store.tabIndex);
  const setTabIndex = useBulkEmailStore((store) => store.setTabIndex);
  const setTemplates = useBulkEmailStore((store) => store.setTemplates);
  const subject = useBulkEmailStore((store) => store.subject);
  const body = useBulkEmailStore((store) => store.body);
  const signature = useBulkEmailStore((store) => store.signature);
  const files = useBulkEmailStore((store) => store.files);
  const deleteModal = useBulkEmailStore((store) => store.deleteModal);
  const setDeleteModal = useBulkEmailStore((store) => store.setDeleteModal);
  const setBody = useBulkEmailStore((store) => store.setBody);
  const setFiles = useBulkEmailStore((store) => store.setFiles);
  const setSignature = useBulkEmailStore((store) => store.setSignature);
  const setSubject = useBulkEmailStore((store) => store.setSubject);
  const setFileNames = useBulkEmailStore((store) => store.setFileNames);
  const setPassword = useBulkEmailStore((store) => store.setPassword);
  const setContactEmail = useBulkEmailStore((store) => store.setContactEmail);
  const setSelectedHost = useBulkEmailStore((store) => store.setSelectedHost);
  const setTemplateId = useBulkEmailStore((store) => store.setTemplateId);
  const setEmails = useBulkEmailStore((store) => store.setEmails);
  const [deleteMessage, setDeleteMessage] = useState("");
  useEffect(() => {
    fetchTemplates();
  }, []);

  const fetchTemplates = async () => {
    const response = await serverGET(`/email/templates`, undefined, undefined);
    if (response && response.constructor !== String) {
      setTemplates(response.data.emailTemplates);
    }
  };

  const handleDelete = async () => {
    const response = await serverDELETE(`/email/template/${deleteModal}`);
    if (response && response.constructor !== String) {
      setDeleteModal(null);
      setDeleteMessage("Email template has been deleted successfully");
      fetchTemplates();
    }
  };

  const clearForm = () => {
    setSubject("");
    setContactEmail("");
    setSelectedHost("");
    setPassword("");
    setTemplateId("");
    setBody("");
    setSignature("");
    setFileNames([]);
    setFiles([]);
    setEmails([]);
  };

  const handleFormData = (e, setter) => {
    setter(e.target.value);
  };

  const handleDeleteClose = () => {
    setDeleteModal(null);
  };

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <Grid container>
      <>
        <RightLayout scrollable={true}>
          <Box
          // style={{
          //   margin: "20px 20px 0 20px",
          // }}
          >
            <Typography variant="h2" textAlign={"left"}>
              Bulk Email
            </Typography>
            <Box>
              <Tabs
                value={tabIndex}
                onChange={handleChange}
                variant="scrollable"
                aria-label="website"
                centered
                scrollButtons={true}
              >
                <Tab
                  label={t("create_email")}
                  style={{
                    textTransform: "capitalize",
                    paddingBottom: 0,
                  }}
                />
                <Tab
                  label={t("templates")}
                  style={{
                    textTransform: "capitalize",
                    paddingBottom: 0,
                  }}
                />
                <Tab
                  label={t("reports")}
                  style={{
                    paddingBottom: 0,
                    textTransform: "capitalize",
                  }}
                />
              </Tabs>
            </Box>
            <CustomTabPanel value={tabIndex} index={0}>
              <CreateBulkEmail
                classes={classes}
                pclasses={pclasses}
                clearForm={clearForm}
                handleFormData={handleFormData}
                fetchTemplates={fetchTemplates}
              />
            </CustomTabPanel>

            <CustomTabPanel value={tabIndex} index={1}>
              <BulkEmailTemplates
                classes={classes}
                pclasses={pclasses}
                clearForm={clearForm}
                handleFormData={handleFormData}
                fetchTemplates={fetchTemplates}
              />
            </CustomTabPanel>

            <CustomTabPanel value={tabIndex} index={2}>
              <History />
            </CustomTabPanel>
          </Box>
        </RightLayout>
        {mobile && tabIndex === 2 ? (
          <></>
        ) : (
          <LeftLayout inverted={true}>
            <Box>
              <Typography variant="h2" align="left" mb={2}>
                Preview
              </Typography>
              <EmailPreview
                body={body}
                files={files}
                signature={signature}
                subject={subject}
              />
            </Box>
          </LeftLayout>
        )}

        {deleteModal && (
          <DialogWithCloseIcon
            open={deleteModal}
            handleClose={handleDeleteClose}
            dialogSize={undefined}
          >
            <Typography variant="h2" style={{ marginBottom: "12px" }}>
              {t("Are you sure you want to delete?")}
            </Typography>
            <DialogActions
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button onClick={handleDeleteClose} variant="outlined">
                {t("cancel")}
              </Button>
              <Button onClick={handleDelete} variant="contained">
                {t("del")}
              </Button>
            </DialogActions>
          </DialogWithCloseIcon>
        )}
      </>
      <SnackbarComponent
        open={!!deleteMessage}
        handleClose={() => setDeleteMessage("")}
        message={deleteMessage}
        vertical={"bottom"}
        horizontal={"center"}
      />
    </Grid>
  );
}
