import {
  Dialog,
  DialogContentText,
  IconButton,
  DialogTitle,
  DialogActions,
  Button,
  CircularProgress,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import SnackbarComponent from "./Global/SnackbarComponent";
import { useState } from "react";

export default function DialogWithSnackbar({
  open,
  handleClose,
  title,
  textContent,
  form,
  buttonContent,
  action,
  cleanup,
  responseMessage,
  errorMessage,
  formValid,
}) {
  const [snackbar, setSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const [unsuccessful, setUnsuccessful] = useState(false);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleAction = async () => {
    setLoading(true);
    const response = await action();
    setSnackbar(true);

    if (response && response.constructor !== String) {
      setMessage(responseMessage);
    } else {
      setMessage(errorMessage);
      setUnsuccessful(true);
    }
    setLoading(false);
    cleanup();
    handleClose();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{ justifyContent: "center" }}
        PaperProps={{
          sx: {
            borderRadius: "8px",
            padding: "0px 20px 28px 20px",
            borderRadius: "16px",
            width: mobile ? "75%" : "25%",
            margin: "0px",
          },
        }}
      >
        <Box display="flex" justifyContent="flex-end" width="100%">
          <IconButton
            style={{
              marginTop: "22px",
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        {title ? (
          <DialogTitle
            sx={{
              textAlign: "center",
              color: "#363B53",
              fontSize: "16px",
              fontWeight: "500",
              paddingTop: "0px",
              paddingBottom: "8px",
            }}
          >
            {title}
          </DialogTitle>
        ) : null}
        {textContent ? (
          <DialogContentText align="center" fontSize={14} pb={"14px"}>
            {textContent}
          </DialogContentText>
        ) : null}

        <DialogActions
          sx={{ justifyContent: "center", pt: "10px", flexDirection: "column" }}
        >
          {form}
          <Button
            variant="submit"
            onClick={handleAction}
            disabled={!formValid || loading}
          >
            {loading ? (
              <CircularProgress style={{ color: "white" }} size={20} />
            ) : (
              buttonContent
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <SnackbarComponent
        open={snackbar}
        handleClose={() => setSnackbar(false)}
        message={message}
        vertical={"bottom"}
        horizontal={"center"}
        deleteUnsuccessful={unsuccessful}
      />
    </>
  );
}
