import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  active: {
    background: "#72B0EE",
    borderLeft: "4px solid #F1F1F1",
    padding: "22px 0px",
    cursor: "pointer",
  },
  inactive: {
    "&:hover": {
      background: "#72B0EE",
    },
    padding: "22px 0px",
    cursor: "pointer",
  },
  activeButton: {
    margin: 0,
    padding: 0,
    color: "#FFFFFF",
    background: "#72B0EE",
    "&:hover": {
      background: "#72B0EE",
    },
  },
  inactiveButton: {
    margin: 0,
    padding: 0,
    color: "#FFFFFF",
    background: "inherit",
    "&:hover": {
      background: "#72B0EE",
    },
  },
  icon: {
    color: "#FFFFFF",
    marginLeft: "10px",
    marginRight: "8px",
    minWidth: "0px",
  },
  iconSize: {
    color: "#FFFFFF",
    width: "16px",
    height: "16px",
  },
  text: {
    margin: "0px 5px 0px 0px",
    color: "#FFFFFF",
    fontSize: "14px",
    fontWeight: "500",
  },
}));
