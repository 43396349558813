import React from "react";

import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PlacesAutocomplete from "react-places-autocomplete";
import Geocode from "react-geocode";
import styles from "styles/inputStyles.module.css";
import { useTranslation } from "react-i18next";

Geocode.setApiKey("AIzaSyB2y_R_q5fMF7BW4frpSY6sEC60_eC--uU");
Geocode.setLanguage("en");
Geocode.enableDebug(false);

const useStyle = makeStyles((theme) => ({
  participant: {
    "& .MuiInputBase-input": {
      color: theme.palette.text.primary,
      width: "100%",
      borderBottom: "none",
      outline: "none",
      "&:focus": {
        borderBottom: "none",
      },
      "&:hover": {
        borderBottom: "none",
      },
    },
  },
}));

//custom code with geocode version

// export default function PhysicalLocation() {
//   const classes = useStyle();
//   const [options, setOptions] = useState(["Loading ..."]);
//   const [inputValue, setInputValue] = useState("");
//   const [loading, setLoading] = useState(false);
//   const [location, setLocation] = useState(null);
//   const [search, setSearch] = useState("");
//   const [value, setValue] = useState(null);

//   const autocompleteService = { current: null };

//   const fetch = React.useMemo(
//     () =>
//       throttle((request, callback) => {
//         autocompleteService.current.getPlacePredictions(request, callback);
//       }, 200),
//     []
//   );
//   const latLngToGeoString = async ({ latitude, longitude }) => {
//     const location = await Geocode.fromLatLng(latitude, longitude);
//     return location.results[0].formatted_address;
//   };
//   const getCurrentLocation = (callback) => {
//     navigator.geolocation.getCurrentPosition(
//       async (position) => {
//         const { latitude, longitude } = position.coords;
//         try {
//           const location = await Geocode.fromLatLng(latitude, longitude);
//           callback(null, {
//             label: "Home",
//             latitude,
//             longitude,
//             deliveryAddress: location.results[0].formatted_address,
//           });
//           console.log(location);
//         } catch (error) {
//           callback(error);
//         }
//       },
//       (error) => {
//         callback(error.message);
//         console.log(error.message);
//       }
//     );
//   };
//   const locationCallback = (error, data) => {
//     console.log("data", data);
//     setLoading(false);
//     if (error) {
//       return;
//     }
//     setSearch(data.deliveryAddress);
//     setLocation(data);
//   };

//   useEffect(() => {
//     let active = true;

//     if (!autocompleteService.current && window.google) {
//       autocompleteService.current =
//         new window.google.maps.places.AutocompleteService();
//     }
//     if (!autocompleteService.current) {
//       return undefined;
//     }

//     if (inputValue === "") {
//       setOptions(value ? [value] : []);
//       return undefined;
//     }

//     fetch({ input: inputValue }, (results) => {
//       if (active) {
//         let newOptions = [];
//         if (value) {
//           newOptions = [value];
//         }
//         if (results) {
//           newOptions = [...newOptions, ...results];
//         }
//         setOptions(newOptions);
//       }
//     });

//     return () => {
//       active = false;
//     };
//   }, [
//     value,
//     inputValue,
//     // fetch
//   ]);

//   return (
//     <Grid item xs={11}>
//       <Autocomplete
//         style={{ marginTop: 10 }}
//         id="google-map-demo"
//         getOptionLabel={(option) =>
//           typeof option === "string" ? option : option.description
//         }
//         filterOptions={(x) => x}
//         options={options}
//         autoComplete
//         includeInputInList
//         filterSelectedOptions
//         value={
//           loading
//             ? "Loading ..."
//             : search
//             ? search
//             : location
//             ? location.deliveryAddress
//             : ""
//         }
//         onChange={(event, newValue) => {
//           if (newValue) {
//             const b = new window.google.maps.Geocoder();
//             b.geocode({ placeId: newValue.place_id }, (res) => {
//               const location = res[0].geometry.location;
//               setLocation({
//                 label: "Home",
//                 deliveryAddress: newValue.description,
//                 latitude: location.lat(),
//                 longitude: location.lng(),
//               });
//             });
//           } else {
//             setSearch("");
//           }
//           setOptions(newValue ? [...options] : options);
//           setValue(newValue);
//         }}
//         onInputChange={(event, newInputValue) => {
//           setInputValue(newInputValue);
//         }}
//         disableClearable
//         renderInput={(params) => (
//           <TextField
//             {...params}
//             variant="standard"
//             placeholder="Enter your full address"
//             className={classes.participant}
//             InputProps={{
//               ...params.InputProps,
//               disableUnderline: true,
//               style: {
//                 fontSize: 12,
//                 fontWeight: 400,
//                 flexWrap: "no-wrap",
//               },

//               endAdornment: (
//                 <>
//                   {/* {params.InputProps.endAdornment} */}
//                   <InputAdornment
//                     position="end"
//                     onClick={(e) => {
//                       e.preventDefault();
//                       setValue(search?.deliveryAddress ?? "");
//                       setSearch(location?.deliveryAddress ?? "");
//                     }}
//                   >
//                     {loading ? (
//                       <CircularProgress />
//                     ) : (
//                       <LocationOnIcon
//                         style={{ color: "rgb(0 0 0 / 26%)" }}
//                         onClick={(e) => {
//                           e.preventDefault();
//                           setLoading(true);
//                           getCurrentLocation(locationCallback);
//                         }}
//                       />
//                     )}
//                   </InputAdornment>
//                 </>
//               ),
//             }}
//           />
//         )}
//         renderOption={(props, option) => {
//           // const matches =
//           //   option.structured_formatting.main_text_matched_substrings;
//           let parts = null;
//           // if (matches) {
//           //   parts = parse(
//           //     option.structured_formatting.main_text,
//           //     matches.map((match) => [
//           //       match.offset,
//           //       match.offset + match.length,
//           //     ])
//           //   );
//           // }

//           return (
//             <Grid {...props} container alignItems="center">
//               <Grid item>
//                 <LocationOnIcon />
//               </Grid>
//               <Grid item xs>
//                 {parts &&
//                   parts.map((part, index) => (
//                     <span
//                       key={index}
//                       style={{
//                         fontWeight: part.highlight ? 700 : 400,
//                         color: "black",
//                       }}
//                     >
//                       {part.text}
//                     </span>
//                   ))}

//                 {/* <Typography variant="body2" color="textSecondary">
//                     {option.structured_formatting.secondary_text}
//                   </Typography> */}
//               </Grid>
//             </Grid>
//           );
//         }}
//       />
//     </Grid>
//   );
// }

export default function PhysicalLocation({ address, setAddress, isBorder }) {
  const { t } = useTranslation();
  const handleSelect = (address) => {
    setAddress(address);
    // geocodeByAddress(address)
    //   .then((results) => getLatLng(results[0]))
    //   .then((latLng) => console.log("Success", latLng))
    //   .catch((error) => console.error("Error", error));
  };

  return (
    <PlacesAutocomplete
      value={address}
      onChange={(e) => setAddress(e)}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div style={{ width: "100%" }}>
          <input
            {...getInputProps({
              placeholder: t("search_location"),
              className: styles.customInput,
              style: { border: isBorder ? "1px solid #DAD2E5" : "none" },
            })}
          />
          <div
            className="autocomplete-dropdown-container"
            style={{
              borderLeft: "1px solid #b3b5b6",
              borderRight: "1px solid #b3b5b6",
              borderBottom:
                suggestions?.length > 0 ? "1px solid #b3b5b6" : "none",
            }}
          >
            {loading && (
              <div>
                {" "}
                <CircularProgress color="primary" />
              </div>
            )}
            {suggestions.map((suggestion, i) => {
              const className = suggestion.active
                ? "suggestion-item--active"
                : "suggestion-item";
              // inline style for demonstration purpose
              const style = suggestion.active
                ? { backgroundColor: "#fafafa", cursor: "pointer" }
                : { backgroundColor: "#ffffff", cursor: "pointer" };
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    style,
                  })}
                  key={i}
                  className={styles.suggestionBox}
                >
                  <div style={{ display: "flex" }}>
                    <LocationOnIcon
                      style={{
                        width: "12px",
                        height: "12px",
                        color: "#479AE9",
                      }}
                    />
                    <span
                      style={{
                        marginLeft: "10px",
                        color: "#363B53",
                      }}
                    >
                      {suggestion.description}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
}
