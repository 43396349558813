import React from "react";
import { FormControl, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";

/**
 * Component for selecting a time interval
 */
function TimeInterval({
  timeInterval,
  handleIntervalChange,
  notNewMeeting,
  hideCustom = false,
  maxInterval,
  isBorder,
  isService,
}) {
  const { t } = useTranslation();
  const styles = isBorder
    ? {
        fontSize: "16px",
        fontWeight: 400,
        borderRadius: "6px",
        paddingLeft: "8px",
        textAlign: "left",
        border: isService ? "" : "1px solid #DAD2E5",
        backgroundColor: isService ? "#F7F7F9" : "",
      }
    : {
        textAlign: "left",
        fontSize: 14,
      };
  return (
    <FormControl
      variant="standard"
      sx={{ width: notNewMeeting ? "100%" : null, marginTop: "10px" }}
    >
      <Select
        labelId="time-interval-label"
        id="event-time-interval"
        disableUnderline={true}
        value={timeInterval}
        // IconComponent={"div"}
        onChange={handleIntervalChange}
        style={styles}
        displayEmpty={true}
      >
        <MenuItem value={""}>{t("duration")} </MenuItem>
        {maxInterval >= "00:15:00" || maxInterval === undefined ? (
          <MenuItem value={"00:15:00"}>15 min</MenuItem>
        ) : (
          <></>
        )}
        {maxInterval >= "00:30:00" || maxInterval === undefined ? (
          <MenuItem value={"00:30:00"}>30 min</MenuItem>
        ) : (
          <></>
        )}
        {maxInterval >= "00:45:00" || maxInterval === undefined ? (
          <MenuItem value={"00:45:00"}>45 min</MenuItem>
        ) : (
          <></>
        )}
        {maxInterval >= "01:00:00" || maxInterval === undefined ? (
          <MenuItem value={"01:00:00"}>1 hr</MenuItem>
        ) : (
          <></>
        )}
        {/* <MenuItem value={"00:30:00"}>30 min</MenuItem>
        <MenuItem value={"00:45:00"}>45 min</MenuItem>
        <MenuItem value={"01:00:00"}>1 hr (60 minutes)</MenuItem> */}
        {!hideCustom && <MenuItem value={"custom"}>{t("custom")} </MenuItem>}
      </Select>
    </FormControl>
  );
}

export default React.memo(TimeInterval);
