import { Tabs } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 115,
    width: "100%",
    backgroundColor: "#3C4242",
  },
  "& .MuiTabs-flexContainer": {
    // overflowX: "scroll",
  },
  marginLeft: "-15px",
});

export default StyledTabs;
