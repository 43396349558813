import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { useState, useEffect, useContext } from "react";

import {
  Button,
  Divider,
  Typography,
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Backdrop,
  useTheme,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import styled from "styled-components";

import LoginBoilerplate from "components/LoginBoilerplate";
import EmailForm from "components/EmailForm";
import { ExtLogins } from "pages/MeetingSignup/UserSignup";
import { colors } from "Utils/colors";
import { serverGETWithToken, serverPOST } from "Utils/HttpFunctions";
import { authContext } from "context/Auth";
import loggingInDev from "loggingInDev";
import SnackbarComponent from "components/Global/SnackbarComponent";
import ErrorSnackbar from "components/ErrorSnackbar";
import { LoginLoader } from "components/LoginLoader";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";

const ForgotPassword = styled(Typography)`
  a {
    color: #3c4242;
  }
`;

export default function LoginEmailPass() {
  const { t } = useTranslation();

  return (
    <Box>
      <LoginBoilerplate
        formSize={6}
        form={<LoginForm t={t} />}
        title={t("welcome") + "!"}
        image={true}
      />
    </Box>
  );
}

function LoginForm({ t }) {
  const [disabled, setDisabled] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [formError, setFormError] = useState("");
  const { setIsLogged } = useContext(authContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const emailParams = searchParams.get("email");
  const accessDenied = searchParams.get("access_error");
  const redirectPath = searchParams.get("redirect_path");
  const [modal, setModal] = useState(false);
  const [email, setEmail] = useState(emailParams ? emailParams : "");
  const [denied, setDenied] = useState(false);
  const theme = useTheme();
  const [cookie] = useCookies();

  const [loader, setLoader] = useState(false);
  const setStorage = (data) => {
    localStorage.setItem("token", data.token);
    localStorage.setItem("first_name", data.first_name);
    localStorage.setItem("last_name", data.last_name);
    localStorage.setItem("refresh_token", data.refresh_token);
    localStorage.setItem("isEmailVerified", data.isEmailVerified);
    localStorage.setItem("user_id", data.id);
    localStorage.setItem("email", data.email);
    localStorage.openpages = Date.now();
    sessionStorage.setItem("loggedIn", true);
    setIsLogged(true);
  };

  useEffect(() => {
    if (token) {
      // setIsLogged(true);
      checkEmail();
    }
  }, [token]);

  useEffect(() => {
    setDenied(accessDenied);
  }, [accessDenied]);

  const checkEmail = async () => {
    const response = await serverGETWithToken("/auth/is-email-verified", token);
    if (response && response.constructor !== String) {
      if (response.data.is_email_verified) {
        // console.log("verified");
        setModal(true);
      } else {
        loggingInDev("not verified");
      }
    } else {
      loggingInDev(response);
    }
  };

  const validateForm = () => {
    var formValid = false;
    var nonEmptyPassword = false;
    var nonEmptyEmail = false;

    if (!email) {
      setEmailError(t("account_email_err"));
      nonEmptyEmail = false;
    } else {
      setPasswordError("");
      nonEmptyEmail = true;
    }

    if (!password) {
      setPasswordError(t("account_pass_err"));
      nonEmptyPassword = false;
    } else {
      setPasswordError("");
      nonEmptyPassword = true;
    }

    if (nonEmptyEmail && nonEmptyPassword) {
      formValid = true;
    } else {
      formValid = false;
    }
    return formValid;
  };

  const handleLogin = async () => {
    setFormError("");
    const formIsValid = validateForm();
    const data = {
      email: email,
      password: password,
    };
    if (formIsValid) {
      setLoader(true);
      const response = await serverPOST("/auth/login", data);
      if (response && response.constructor !== String) {
        loggingInDev("accepted response =>", response);
        setStorage(response.data);
        setLoader(false);
        // navigate to stored cookie path
        const cookiePath = cookie?.redirectPath;
        if (cookiePath) {
          if (response.data?.is_welcomed) {
            navigate(`${cookiePath}&userSignup=true`, {
              replace: true,
            });
          } else {
            navigate(cookiePath);
          }
        }
        else{
          //already paths
          if (redirectPath) {
            navigate(`/${redirectPath}`);
          }
          if (response.data.is_welcomed) {
            navigate("/dashboard?userSignup=true");
          } else {
            navigate("/dashboard");
          }
        }
      } else {
        loggingInDev("error =>", response);
        setLoader(false);
        setFormError(response);
      }
    }
  };

  return (
    <>
      <Backdrop
        sx={{
          color: colors.primary,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loader}
        onClick={() => setLoader((prev) => !prev)}
      >
        <LoginLoader />
      </Backdrop>

      <SnackbarComponent
        open={modal}
        handleClose={() => setModal(false)}
        message={t("verified")}
        vertical={"bottom"}
        horizontal={"center"}
      />
      <ErrorSnackbar
        open={denied}
        handleClose={() => setDenied(false)}
        message={t("login_permit")}
        vertical={"bottom"}
        horizontal={"center"}
        duration={6000}
      />
      <Box style={{ width: "fit-content" }}>
        <Typography
          variant="h3"
          align="center"
          style={{
            marginBottom: 25,
            marginTop: 40,
            fontSize: 16,
          }}
        >
          {t("login_account")}
        </Typography>
        <ExtLogins isLoginPage={true} />
        <Box mt={3} mb={3}>
          <Divider textAlign="center" variant="middle" flexItem={true}>
            {t("or")}
          </Divider>
        </Box>
        <Box
          pb="10px"
          sx={{
            width: { xs: "100%", md: 360 },
          }}
        >
          {formError ? (
            <Typography color="red" align="center">
              {formError}
            </Typography>
          ) : null}
        </Box>
        <Box>
          <EmailForm
            email={email}
            setEmail={setEmail}
            setEmailValid={setEmailValid}
            emailError={emailError}
          />
        </Box>
        <Box mt={1.5}>
          <PasswordExisting
            setPassword={setPassword}
            passwordError={passwordError}
            t={t}
          />
        </Box>

        <Box mt={1.5}>
          <Button
            variant="contained"
            sx={{
              fontSize: "14px",
              fontWeight: 500,
              borderRadius: "8px",
              backgroundColor: colors.primary,
              width: "100%",
              paddingTop: "8px",
              paddingBottom: "8px",
            }}
            onClick={handleLogin}
            disabled={disabled}
          >
            {t("login")}
          </Button>
        </Box>
        <Box mt={1.5}>
          <ForgotPassword align="right">
            <NavLink
              to="/forgotpassword"
              style={{ fontSize: 14, fontWeight: 500 }}
            >
              {t("forgot")}
            </NavLink>
          </ForgotPassword>
        </Box>

        <Typography
          style={{ marginTop: 16, marginBottom: 16 }}
          align="center"
          variant="h3"
        >
          {t("no_account")}{" "}
          <NavLink to="/register" style={{ color: theme.palette.primary.main }}>
            {t("sign_up")}
          </NavLink>
        </Typography>
      </Box>
    </>
  );
}

function PasswordExisting({ setPassword, passwordError, t }) {
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePasswordValue = (e) => {
    setPassword(e.target.value);
  };

  return (
    <TextField
      required
      id="existing-password-check"
      variant="outlined"
      placeholder={t("password")}
      type={values.showPassword ? "text" : "password"}
      onChange={handlePasswordValue}
      error={passwordError ? true : false}
      helperText={passwordError}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <LockOutlinedIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {values.showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
        sx: { borderRadius: "8px" },
      }}
      sx={{
        width: { xs: "100%", md: 360 },
      }}
    />
  );
}
