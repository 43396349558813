import React, { useRef, useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Player } from "@lottiefiles/react-lottie-player";
import Animate from "assets/invite.json";
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function CongratsDialog({
  congratsDialog,
  setCongratsDialog,
  isCalendar,
  setTrialDialog,
  setIntegrateCalendar,
}) {
  const { t } = useTranslation();
  const handleNext = () => {
    setCongratsDialog(false);
    setTrialDialog(true);
    // if (isCalendar) {
    //   setIntegrateCalendar(true);
    // } else {
    //   setUserNameDialog(true);
    // }
  };
  const ref = useRef("");

  return (
    <Dialog
      open={congratsDialog}
      aria-labelledby="alert-welcome-to-skeding"
      sx={{ justifyContent: "center" }}
      PaperProps={{
        style: {
          borderRadius: "8px",
          padding: "15px 15px 0 15px",
          boxSizing: "border-box",
        },
      }}
    >
      <DialogContent style={{ padding: "0" }}>
        <Grid container>
          <Box
            display="flex"
            justifyContent="flex-end"
            style={{ width: "100%" }}
          >
            <IconButton
              sx={{ justifyContent: "flex-end" }}
              onClick={() => setCongratsDialog(false)}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Grid item xs={12} sx={{ pb: "10px" }}>
            <Typography align="center" variant="h2">
              {t("welcome_skeding") + "!"}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ pb: "30px" }}>
            <Typography align="center" variant="h3" fontWeight={400}>
              {t("steps_account")}
            </Typography>
          </Grid>

          <div>
            <Player
              ref={ref} // set the ref to your class instance
              autoplay={true}
              loop={true}
              controls={true}
              src={Animate}
              speed={1}
              style={{
                width: "100%",
                height: "100%",
                marginTop: "30px",
              }}
            ></Player>
          </div>
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{ justifyContent: "center" }}
        style={{ padding: "24px 0 0 0 " }}
      >
        <Button
          onClick={handleNext}
          variant="submit"
          style={{
            fontWeight: 500,
            padding: "4px 28px",
            marginBottom: "24px",
            fontSize: "14px",
            fontWeight: 400,
          }}
        >
          {t("cont")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
