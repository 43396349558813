import { useContext, useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/styles";
import { Box, Dialog, IconButton, Skeleton, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { serverGET, serverPOST, serverPUT } from "Utils/HttpFunctions";
import {
  convertDateToUTCMilliseconds,
  downloadFileAttachment,
} from "Utils/CommonFunctions";
import { authContext } from "context/Auth";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

import {
  ALL_SKEDING_USERS,
  MEETING_THROUGH_QR_CODE,
  MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS,
  ONE_NONSKEDING_MULTIPLE_SKEDING_USERS,
  ONE_NONSKEDING_ONE_SKEDING_USER,
  ONE_SKEDING_MULTIPLE_NONSKEDING_USERS,
  RECURRING_EVENT,
} from "Utils/Constants";
import DialogBox from "components/DialogBox";
import loggingInDev from "loggingInDev";
import MeetingRejectDialog from "components/MeetingInvitation/MeetingRejectDialog";
import ShowTimes from "pages/temp/show";
import SnackbarComponent from "components/Global/SnackbarComponent";
import MeetingDetailsComponent from "components/NewEvent/MeetingDetailsComponent";
import moment from "moment";
import useStyle from "./styles";

export default function InitiatorSelect() {
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { isLogged } = useContext(authContext);
  const [meetingData, setMeetingData] = useState(null);
  const [error, setError] = useState("");
  const currentURL = window.location.href;
  const [selectedSlot, setSelectedSlot] = useState([]);
  const [modal, setModal] = useState(true);
  const [disableAccept, setDisableAccept] = useState(false);
  const [reject, setReject] = useState(false);
  const [cancelMeeting, setCancelMeeting] = useState(false);
  const [sharedAvailableTimes, setSharedAvailableTimes] = useState([]);
  const [errorAmount, setErrorAmount] = useState("");
  const [e, setE] = useState(false);
  const isRedirect = searchParams.get("redirect");
  const { t } = useTranslation();
  const classes = useStyle();

  useEffect(() => {
    if (meetingData && isRedirect !== "true") {
      const start = meetingData.proposed_timeslots[0]?.start
        ? meetingData.proposed_timeslots[0]?.start
        : meetingData.proposed_timeslots[0]?.startTime;
      const firstSlot = moment(start);
      const today = moment();
      const created = moment(meetingData.createdAt);
      const checkDate = moment(created);
      const difference = firstSlot.diff(created, "days");
      let daysToAdd = difference / 2;
      checkDate.add(daysToAdd, "d");
      console.log(new Date(checkDate._d), today.toDate());
      if (
        new Date(checkDate._d) > today.toDate() ||
        meetingData.start_datetime
      ) {
        setE(true);
      }
    }
  }, [meetingData]);

  const handleClose = () => {
    setModal(false);
    navigate("/meetings");
  };

  const handleDownload = async (file) => {
    await downloadFileAttachment(file, meetingData?._id);
  };
  useEffect(() => {
    const abortController = new AbortController();
    getMeeting(abortController);
    return () => {
      abortController.abort();
    };
  }, [isLogged]);

  useEffect(() => {
    if (meetingData?.start_datetime && meetingData?.end_datetime) {
      setModal(true);
    }
  }, [meetingData]);

  useEffect(() => {
    if (cancelMeeting) {
      handleRejectConfirmation();
    }
  }, [cancelMeeting]);

  const getMeeting = async (abortController) => {
    const meetingId = await searchParams.get(
      "meetingId",
      abortController.signal
    );

    const response = await serverGET(`/meeting/${meetingId}`);
    if (response && response.constructor !== String) {
      setMeetingData(response.data);
      // loggingInDev("getting data", response.data);
    } else {
      // setError(response);
      setE(true);
    }
  };

  const handleSubmit = async () => {
    setError("");
    const data = {
      start_datetime: convertDateToUTCMilliseconds(selectedSlot[0].value.start),
      end_datetime: convertDateToUTCMilliseconds(selectedSlot[0].value.end),
      time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      utc_offset: new Date().getTimezoneOffset(),
    };
    const response = await serverPUT(`/meeting/${meetingData._id}`, data);
    if (response && response.constructor !== String) {
      loggingInDev("update response =>", response);
      setDisableAccept(true);
    } else {
      loggingInDev("error =>", response);
      setError(response);
    }

    if (!error) {
      navigate("/dashboard?scheduledMeeting=true");
    }
  };

  const handleRejectConfirmation = async () => {
    setError("");
    let data;
    if (
      meetingData?.case === MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS ||
      meetingData?.case === ONE_SKEDING_MULTIPLE_NONSKEDING_USERS
    ) {
      data = {
        to_cancel: true,
      };
    } else if (meetingData?.case === ONE_NONSKEDING_MULTIPLE_SKEDING_USERS) {
      data = {
        // is_active: false,
        to_cancel: true,
      };
    }

    const response = await serverPUT(`/meeting/${meetingData._id}`, data);
    if (response && response.constructor !== String) {
      loggingInDev("rejected ==>", response);
      setReject(true);
    } else {
      loggingInDev("rejected else ==>", response);
    }
    if (!error) {
      navigate("/dashboard");
    }
  };

  const handleReject = () => {
    setReject((prev) => !prev);
  };

  if (e) {
    return <Typography>{t("unauthorized")}</Typography>;
  }
  return meetingData !== null ? (
    <>
      <DialogBox
        modal={modal}
        handleClose={handleClose}
        // close={false}
        msg={t("you_have_responded")}
      />

      <>
        <Dialog
          open={modal}
          maxWidth={"md"}
          fullWidth
          PaperProps={{
            sx: {
              padding: 3,
            },
          }}
        >
          <Box display="flex" justifyContent="flex-end">
            <IconButton
              sx={{ justifyContent: "flex-end" }}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography variant="h2" className={classes.meetingDetailsText}>
            {t("meeting_details")}
          </Typography>
          <Typography variant="body2" style={{ color: "red" }}>
            {error}{" "}
          </Typography>
          <Grid container>
            <Grid item xs={12} md={5}>
              <MeetingDetailsComponent
                theme={theme}
                meetingData={meetingData}
                handleDownload={handleDownload}
              />
            </Grid>

            <Grid item xs={12} md={7}>
              <Box>
                {meetingData?.proposed_timeslots?.length > 0 ? (
                  <>
                    <Typography variant="h2" className={classes.pickSlot}>
                      {t("pick_slot")}
                    </Typography>
                    <ShowTimes
                      setSelectedSlot={setSelectedSlot}
                      selectedSlot={selectedSlot}
                      events={meetingData?.proposed_timeslots}
                      eventCase="NonSignUpOneSelect"
                      sharedAvailableTimes={sharedAvailableTimes}
                      setSharedAvailableTimes={setSharedAvailableTimes}
                      errorAmount={errorAmount}
                      setErrorAmount={setErrorAmount}
                    />
                  </>
                ) : null}
              </Box>
            </Grid>
          </Grid>
          <Grid container xs={12} justifyContent="center">
            <Grid container xs={12} className={classes.buttonContainer}>
              <Grid item>
                <Button variant="outlined" onClick={handleReject}>
                  {isRedirect === "true" ? "Cancel" : "Reject"}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={disableAccept || selectedSlot?.length === 0}
                >
                  {t("accept")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Dialog>
      </>

      {reject ? (
        <MeetingRejectDialog setCancelMeeting={setCancelMeeting} />
      ) : null}
      <SnackbarComponent
        open={cancelMeeting}
        handleClose={() => navigate("/dashboard")}
        message={
          "Meeting has been canceled and the initiator/participant will be informed!"
        }
        vertical={"bottom"}
        horizontal={"center"}
      />
    </>
  ) : (
    <Box className={classes.loadingContainer}>
      <Box>
        <Skeleton animation="wave" className={classes.topSkeleton} />
        <Skeleton
          animation="wave"
          className={`${classes.topSkeleton} ${classes.topSkeletonMargin}`}
        />
        <Skeleton animation="wave" className={classes.topSkeleton} />
      </Box>

      <Box className={classes.loadingContainerbottom}>
        <Skeleton animation="wave" className={classes.bottomSkeleton} />
        <Skeleton animation="wave" className={classes.bottomSkeleton} />
      </Box>
    </Box>
  );
}
