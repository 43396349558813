import makeStyles from "@mui/styles/makeStyles";
const useStyle = makeStyles((theme) => ({
  container: {
    background: "transparent",
    borderRadius: "16px",
    height: "85vh",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.up("lg")]: {
      background: theme.palette.secondary.light,
    },
  },
  panelBox: {
    overflow: "auto",
    padding: 0,
    marginTop: "15px",
    [theme.breakpoints.up("lg")]: {
      padding: "0px 24px 0px 24px",
    },
  },

  noMsg: {
    height: "100%",
    width: "100%",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  padding: {
    padding: 0,
    [theme.breakpoints.up("lg")]: {
      padding: "24px 24px 0px 24px",
    },
  },
}));

export default useStyle;
