import { useState } from "react";
import {
  Grid,
  Chip,
  Typography,
  useMediaQuery,
  useTheme,
  Box,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";

import ReminderDialog from "components/meetings/ReminderDialog";
import scrollbar from "styles/scrollBar.module.css";
import ErrorSnackbar from "components/ErrorSnackbar";
import {
  MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS,
  ONE_NONSKEDING_MULTIPLE_SKEDING_USERS,
  ONE_NONSKEDING_ONE_SKEDING_USER,
  ONE_SKEDING_MULTIPLE_NONSKEDING_USERS,
} from "Utils/Constants";
import { useStyles } from "styles/participantChip";
import { useTranslation } from "react-i18next";
import { SINGLE_SLOT_MEETING } from "../../Utils/Constants";
import ToolTip from "../Global/ToolTip";
import SkedingParticipants, {
  NonSkedingParticipants,
} from "../Global/Participants";

/**
 * Renders participants in the pending meetings cards
 */
export default function PendingMeetingParticipants({ event, isPopup = false }) {
  const [reminderDialog, setReminderDialog] = useState(false);
  const [reminderParticipantEmail, setReminderParticipantEmail] = useState("");
  const [reminderMeetingId, setReminderMeetingId] = useState("");
  const [skedingError, setSkedingError] = useState("");

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("lg"));
  const classes = useStyles();
  const { t } = useTranslation();
  /**
   * Sets variables for sending a reminder to skeding participants that haven't responded
   * @param {string} eventId - the event for which the reminder is being sent
   * @param {string} participantEmail - the email of the user who will receive the email
   */
  const handleSkedingParticipantClick = (eventId, participantEmail) => {
    if (
      Object.prototype.toString.call(event?.start) === "[object Date]" &&
      !isNaN(event?.start)
    ) {
      setReminderMeetingId(eventId);
      setReminderParticipantEmail(participantEmail);
      setReminderDialog(true);
    } else {
      setSkedingError(t("remind_err"));
    }
  };

  /**
   * Sets variables for sending a reminder to nonskeding participants that haven't responded
   * @param {string} eventId - the event for which the reminder is being sent
   * @param {string} participantEmail - the email of the user who will receive the email
   */
  const handleNonSkedingParticipantClick = (eventId, participantEmail) => {
    setReminderMeetingId(eventId);
    setReminderParticipantEmail(participantEmail);
    setReminderDialog(true);
  };

  const particpantsLength =
    event?.nonskedingParticipants?.length + event?.skedingParticipants?.length;

  const getIcon = (participant, j) => {
    return event?.case == ONE_NONSKEDING_MULTIPLE_SKEDING_USERS ? (
      Object.prototype.toString.call(event?.start) === "[object Date]" &&
      isNaN(event?.start) &&
      event?.nonskedingParticipantsNotResponded.length === 1 ? (
        <NotificationsNoneIcon style={{ color: "red" }} />
      ) : event?.nonskedingParticipantsNotResponded.length === 1 ? (
        <CheckCircleIcon />
      ) : (
        <CancelIcon />
      )
    ) : event?.case == ONE_NONSKEDING_ONE_SKEDING_USER ? (
      Object.prototype.toString.call(event?.start) === "[object Date]" &&
      isNaN(event?.start) ? (
        <NotificationsNoneIcon style={{ color: "red" }} />
      ) : (
        <CheckCircleIcon />
      )
    ) : event?.case == MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS ||
      event?.case == ONE_SKEDING_MULTIPLE_NONSKEDING_USERS ? (
      event.nonskedingParticipantPollingStatus[j] === 1 ? (
        <CheckCircleIcon />
      ) : event.nonskedingParticipantPollingStatus[j] === 0 ? (
        <CancelIcon />
      ) : (
        <NotificationsNoneIcon style={{ color: "red" }} />
      )
    ) : event?.case == SINGLE_SLOT_MEETING ? (
      !event?.nonskedingParticipantsNotResponded?.includes(participant) ? (
        event.nonSkedingParticipantsRejected.includes(participant) ? (
          <CancelIcon />
        ) : (
          <CheckCircleIcon />
        )
      ) : (
        <NotificationsNoneIcon style={{ color: "red" }} />
      )
    ) : event?.nonskedingParticipantsNotResponded?.includes(participant) ? (
      <NotificationsNoneIcon style={{ color: "red" }} />
    ) : (
      <CheckCircleIcon />
    );
  };

  const particpantClick = (participant) => {
    return participant.is_invitation_accepted === -1
      ? (e) => handleSkedingParticipantClick(event.id, participant.email)
      : null;
  };

  const nonParticipantClick = (participant) => {
    return (event?.case === ONE_NONSKEDING_ONE_SKEDING_USER ||
      event?.case === ONE_NONSKEDING_MULTIPLE_SKEDING_USERS) &&
      Object.prototype.toString.call(event?.start) === "[object Date]" &&
      isNaN(event?.start)
      ? (e) => handleNonSkedingParticipantClick(event.id, participant)
      : event?.nonskedingParticipantsNotResponded?.includes(participant)
      ? (e) => handleNonSkedingParticipantClick(event.id, participant)
      : null;
  };
  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"flex-start"}
        rowGap={1}
        style={{
          overflow: isPopup || mobile ? "auto" : "hidden",
          // display: isPopup || mobile ? "flex" : "inline-block",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          // flexWrap: isPopup || mobile ? "nowrap" : "wrap",
          flexWrap: "wrap",
          textAlign: "start",
        }}
        className={scrollbar.root}
      >
        <ReminderDialog
          reminderDialog={reminderDialog}
          setReminderDialog={setReminderDialog}
          participantEmail={reminderParticipantEmail}
          meetingId={reminderMeetingId}
        />
        <ErrorSnackbar
          open={!!skedingError}
          handleClose={() => setSkedingError("")}
          message={skedingError}
          vertical={"bottom"}
          horizontal={"center"}
        />
        <SkedingParticipants
          participants={event?.skedingParticipants}
          total={particpantsLength}
          onClick={(participant) => particpantClick(participant)}
        />
        <NonSkedingParticipants
          participants={event?.nonskedingParticipants}
          total={particpantsLength}
          getIcon={(participant, index) => getIcon(participant, index)}
          onClick={(participant) => nonParticipantClick(participant)}
        />
      </Box>
    </>
  );
}
