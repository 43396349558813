import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

export default function IntegrateCalendar({
  integrateCalendar,
  setIntegrateCalendar,
  msg,
}) {
  const { t } = useTranslation();
  const [routeToIntegrations, setRouteToIntegrations] = useState(false);

  const handleNext = () => {
    setIntegrateCalendar(false);
    setRouteToIntegrations(true);
  };

  const handleGoBack = () => {
    setIntegrateCalendar(false);
  };

  return (
    <Dialog
      open={integrateCalendar}
      aria-labelledby="alert-integrate-calendar"
      sx={{ justifyContent: "center" }}
      PaperProps={{
        style: {
          borderRadius: "16px",
          padding: "0",
          boxSizing: "border-box",
          width: "504px",
        },
      }}
    >
      <DialogContent style={{ padding: "0", paddingBottom: "24px" }}>
        <Grid container item xs={12} style={{ padding: "0" }}>
          <Box
            display="flex"
            justifyContent="flex-end"
            style={{
              width: "100%",
              padding: "10px 10px 17.5px 28px",
            }}
          >
            <IconButton
              sx={{ justifyContent: "flex-end" }}
              onClick={handleGoBack}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Grid item xs={12} sx={{ p: "0px 28px" }}>
            <Typography
              align="center"
              variant="h3"
              // style={{ padding: "0px 15px" }}
            >
              {msg}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{ justifyContent: "center", columnGap: 3, paddingBottom: "28px" }}
      >
        {/* <Button
          // onClick={handleSkip}
          onClick={handleGoBack}
          variant="outlined"
          style={{
            padding: "4px 20px",
          }}
        >
          Go back
        </Button> */}
        <Button
          onClick={handleNext}
          variant="submit"
          style={{
            padding: "5px 20px",
          }}
        >
          {t("cont")}
        </Button>
        {routeToIntegrations ? (
          <Navigate to="/preferences" replace={true} />
        ) : null}
      </DialogActions>
    </Dialog>
  );
}
