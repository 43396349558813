import React, { useContext, useEffect, useState } from "react";

import { useMediaQuery, useTheme } from "@mui/material";

import Details from "./Details";
import Inputs from "./Inputs";
import Message from "./Message";
import { useNavigate } from "react-router-dom";
import useMeetingStore from "../../Utils/createMeetingStore";
import useSuggestionsStore from "../../Utils/suggestionsStore";

export default function DesktopFlow({
  isMobile,
  modalDate,
  setModalDate,
  clickedEvent,
}) {
  const [inputModal, setInputModal] = useState(true);
  const [messageModal, setMessageModal] = useState(false);
  const [detailModal, setDetailModal] = useState(false);
  const { setMeetingModal } = useMeetingStore();
  const mobile = useMediaQuery(useTheme().breakpoints.down("md"));
  const navigate = useNavigate();
  const previousURL = window.history.state?.usr?.previousURL || null;
  const resetStore = useSuggestionsStore((state) => state.resetStore);

  const handleInputContinue = () => {
    setInputModal(false);
    setMessageModal(true);
  };

  const handleMessageContinue = () => {
    setMessageModal(false);
    setDetailModal(true);
  };

  const handleBackContinue = () => {
    setMessageModal(false);
    setInputModal(true);
  };

  const closeDetail = (redirect = true) => {
    resetStore();

    setDetailModal(false);
    setMeetingModal(false);
    if (redirect) {
      if (previousURL === "/shareable-meeting") navigate(previousURL);
      else navigate("/dashboard");
    }
  };
  const handleMeetingCreate = () => {
    setDetailModal(false);
    setInputModal(true);
  };
  const closeInput = (redirect = true) => {
    setInputModal(false);
    setMeetingModal(false);

    // only for dashbaord click
    if (modalDate) {
      setModalDate((prev) => !prev);
    }
    if (redirect) {
      if (previousURL === "/shareable-meeting") navigate(previousURL);
    }
  };
  const closeMessageInput = () => {
    setMessageModal(false);
    setMeetingModal(false);
    if (previousURL === "/shareable-meeting") navigate(previousURL);
  };
  const closeDetailMobile = () => {
    setDetailModal(false);
    setMeetingModal(false);
    setInputModal(true);
  };
  return (
    <>
      {isMobile && mobile ? (
        <>
          {inputModal && (
            <Inputs
              inputModal={inputModal}
              handleContinue={handleInputContinue}
              setInputModal={setInputModal}
              closeInput={closeInput}
              isMobile={isMobile}
              // for dashboard click
              modalDate={modalDate}
              setModalDate={setModalDate}
              draft={clickedEvent.event}
            />
          )}
          {detailModal && (
            <Details
              detailModal={detailModal}
              closeDetail={closeDetail}
              handleMeetingCreate={handleMeetingCreate}
              isMobile={isMobile}
              closeDetailMobile={closeDetailMobile}
            />
          )}
          {messageModal && (
            <Message
              messageModal={messageModal}
              handleContinue={handleMessageContinue}
              handleBack={handleBackContinue}
              closeMessageInput={closeMessageInput}
            />
          )}
        </>
      ) : (
        <>
          <Inputs
            inputModal={inputModal}
            handleContinue={handleInputContinue}
            setInputModal={setInputModal}
            closeInput={closeInput}
            setMeetingModal={setMeetingModal}
            // for dashboard click
            modalDate={modalDate}
            setModalDate={setModalDate}
            draft={clickedEvent?.event}
          />
          <Details
            detailModal={detailModal}
            closeDetail={closeDetail}
            handleMeetingCreate={handleMeetingCreate}
          />
          <Message
            messageModal={messageModal}
            handleContinue={handleMessageContinue}
            handleBack={handleBackContinue}
            closeMessageInput={closeMessageInput}
          />
        </>
      )}
    </>
  );
}
