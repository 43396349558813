import {
  Typography,
  Box,
  IconButton,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import useMeetingStore from "../../Utils/createMeetingStore";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function Counter({ handleClose }: { handleClose: () => void }) {
  const [show, setShow] = useState(true);
  const { remainingMeetings } = useMeetingStore((state) => state);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const navigate = useNavigate();

  return show ? (
    <Box
      display="flex"
      justifyContent={"space-between"}
      alignItems="center"
      flexWrap={"wrap"}
      style={{
        border: `1px solid #479AE9`,
        borderRadius: "8px",
        padding: "20px",
      }}
      rowGap={2}
    >
      <Box display="flex">
        <Typography variant="h3" fontWeight={400} align="left">
          {t("only")} {4 - remainingMeetings < 0 ? 0 : 4 - remainingMeetings}{" "}
          {t("meetings_allowed")}
        </Typography>
        {mobile && (
          <IconButton
            sx={{ justifyContent: "flex-end", minWidth: 20 }}
            onClick={() => setShow(false)}
          >
            <CloseIcon style={{ width: 16, height: 16 }} />
          </IconButton>
        )}
      </Box>
      <Box display="flex" gap={2}>
        <Button
          variant="outlined"
          onClick={() => {
            navigate("/payment-services");
            handleClose();
          }}
        >
          {t("get_premium")}
        </Button>
        {!mobile && (
          <IconButton
            sx={{ justifyContent: "flex-end" }}
            onClick={() => setShow(false)}
          >
            <CloseIcon style={{ width: 16, height: 16 }} />
          </IconButton>
        )}
      </Box>
    </Box>
  ) : (
    <></>
  );
}
