import { useState, useContext } from "react";

import {
  Dialog,
  DialogActions,
  Button,
  Box,
  useTheme,
  Typography,
  CircularProgress,
  Grid,
  IconButton,
  InputLabel,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { integrationContext } from "context/Integrations";
import { serverPOST } from "Utils/HttpFunctions";
import { checkUrl, convertDateToUTCMilliseconds } from "Utils/CommonFunctions";
import loggingInDev from "loggingInDev";
import useStyle from "styles/formStyles";
import VirtualLink from "components/NewEvent/Link";
import ErrorSnackbar from "components/ErrorSnackbar";
import Location from "components/NewEvent/Location";
import useParticipantStatus from "../../hooks/useParticipantStatus";
import ColorIndicator from "../meetings/ColorIndicator";
import { useTranslation } from "react-i18next";
import useContactGroups from "../../Utils/hooks/useContactGroups";
import Participants from "../NewEvent/Participants";

/**
 * Dialog for creating an instant meeting
 */
export default function InstantMeeting({ instantMeeting, setInstantMeeting }) {
  const theme = useTheme();

  const handleClose = () => {
    setInstantMeeting(false);
  };

  return (
    <Dialog
      open={instantMeeting}
      onClose={handleClose}
      aria-labelledby="alert-instant-meeting"
      aria-describedby="alert-create-instant-meeting"
      maxWidth="xs"
      fullWidth
      sx={{
        "& .MuiPaper-root": {
          justifyContent: "center",
          padding: 3,
          borderRadius: "16px",
        },
      }}
    >
      <DialogActions sx={{ flexDirection: "column" }}>
        <Form handleClose={handleClose} theme={theme} />
      </DialogActions>
    </Dialog>
  );
}

function Form({ handleClose }) {
  const theme = useTheme();
  const [participants, setParticipants] = useState([]);
  const { user } = useContext(integrationContext);
  const [msg, setMsg] = useState("");
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");
  const classes = useStyle();
  const [link, setLink] = useState(() =>
    user && user.virtual_link ? user.virtual_link : ""
  );
  const [participantLabels] = useParticipantStatus(participants);
  const [location, setLocation] = useState(
    user && user.is_google_synced
      ? "Google Meet"
      : // : user && user.is_microsoft_synced
        // ? "Outlook"
        ""
  );
  const [contacts, groups, minimizedContacts] = useContactGroups(participants);
  const { t } = useTranslation();

  const createInstantMeeting = async () => {
    if (location === "Link" && link === "" && !checkUrl(link)) {
      setError(t("link_err"));
      setDisabled(false);
      return;
    }

    var loc = location === "Link" ? link : location;
    setDisabled(true);
    const data = {
      start_datetime: convertDateToUTCMilliseconds(new Date()),
      participants: participants,
      location: loc,
      time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      utc_offset: new Date().getTimezoneOffset(),
    };
    const response = await serverPOST("/meeting/instant", data);
    if (response && response.constructor !== String) {
      setMsg("Meeting successfully created");
      loggingInDev("instant response == ", response.data);
      if (response.code === 201 && response.data.toRedirect) {
        console.log("inside");
        window.open(response.data.location, "_blank");
        handleClose();
      }
      clearForm();
      setDisabled(false);
    } else {
      setMsg(response);
      setDisabled(false);
    }
  };

  const clearForm = () => {
    setParticipants([]);
    setLocation("");
    setLink("");
  };

  return (
    <Box flexDirection="column" style={{ width: "100%", height: "100%" }}>
      <IconButton
        style={{
          marginLeft: "auto",
          display: "flex",
        }}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
      <Box
        display="flex"
        flexDirection="column"
        style={{ width: "100%", height: "100%" }}
        justifyContent="center"
        alignItems="center"
        className={classes.root}
      >
        <ErrorSnackbar
          open={!!error}
          handleClose={() => setError("")}
          message={error}
          vertical={"bottom"}
          horizontal={"center"}
        />

        <Box>
          <Typography variant="h2" align="center" sx={{ marginBottom: "8px" }}>
            {t("start_instant")}
          </Typography>
        </Box>
        <Grid
          direction={mobile ? "column" : "row"}
          display="flex"
          // justifyContent="flex-start"
          width="100%"
          alignItems={"left"}
        >
          <Grid item xs={11} md={1.5} display="flex" alignItems={"center"}>
            <InputLabel htmlFor="title">
              <Typography variant="h4" width={"100px"} mb={1}>
                {t("participants") + ":"}
              </Typography>
            </InputLabel>
          </Grid>

          <Grid
            item
            xs={11}
            md={10.5}
            className={classes.participant}
            width={"100%"}
          >
            <Participants
              participants={participants}
              setParticipants={setParticipants}
              participantLabels={participantLabels}
              contacts={contacts}
              isRequired={false}
              groups={groups}
              minimizedContacts={minimizedContacts}
            />

            <Grid display={"flex"} alignItems={"center"}>
              <ColorIndicator text={"member"} color={"#B0E4FF"} />
              <ColorIndicator text={"non_member"} color={"#D5D6D7"} />
            </Grid>
          </Grid>
        </Grid>
        <Box
          display="flex"
          justifyContent="flex-start"
          width="100%"
          alignItems={!mobile && "center"}
          flexDirection={mobile ? "column" : "row"}
        >
          <Grid item xs={11} md={1.5} display="flex" alignItems={"center"}>
            <InputLabel htmlFor="title" style={{ marginTop: "10px" }}>
              <Typography variant="h4" width={"100px"}>
                {t("location") + ":"}
              </Typography>
            </InputLabel>
          </Grid>
          {/* <Typography variant="h4" mr={1} mt={1} width={"80px"}>
            {t("location") + ":"}
          </Typography> */}
          <Location
            location={location}
            setLocation={setLocation}
            user={user}
            isInstant={true}
          />
        </Box>
        {location === "Link" && (
          <>
            <span style={{ height: "10px" }} />
            <VirtualLink setLink={setLink} link={link} />
          </>
        )}
        <Box sx={{ display: "flex", mt: 3 }}>
          <Button
            onClick={handleClose}
            variant="outlined"
            style={{
              marginRight: "16px",
            }}
          >
            {t("cancel")}
          </Button>
          <Button
            variant="contained"
            onClick={createInstantMeeting}
            disabled={disabled}
          >
            {disabled ? (
              <CircularProgress style={{ color: "white" }} size={20} />
            ) : (
              t("send_invite")
            )}
          </Button>
        </Box>
        <Box>
          {msg && <Typography style={{ color: "green" }}>{msg}</Typography>}
        </Box>
      </Box>
    </Box>
  );
}
