import React, { useState, useEffect } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, useTheme } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import { ReactComponent as ProfileIcon } from "assets/bi_person-circle.svg";
import { serverPUT } from "Utils/HttpFunctions";
import loggingInDev from "loggingInDev";
import { useTranslation } from "react-i18next";
import { convertBase64 } from "../../Utils/CommonFunctions";

export default function PPUploadDialog({
  pictureDialog,
  firstName,
  lastName,
  setPictureDialog,
  setTourDialog,
}) {
  const [file, setFile] = useState();
  const [disabled, setDisabled] = useState(false);
  const uploadedImage = React.useRef(null);
  const imageUploader = React.useRef(null);
  const theme = useTheme();
  const { t } = useTranslation();

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        current.src = e.target.result;
      };
    }
  }, [file]);

  const handlePPUpload = (e) => {
    setFile(e.target.files[0]);
  };

  const handleNext = () => {
    if (file) {
      handleImage(file);
    }
    setPictureDialog(false);
    // setIntegrateCalendar(true);
    setTourDialog(true);
  };

  const handleImage = async (image) => {
    setDisabled(true);

    const base64 = await convertBase64(image);
    if (base64) {
      const data = {
        picture: base64,
        // first_name: firstName,
        // last_name: lastName,
      };
      const response = await serverPUT(`/auth/verify-email`, data);
      if (response && response.constructor !== String) {
        loggingInDev("image modified =>", response);
        setPictureDialog(false);
        setTourDialog(true);
        setDisabled(false);
      } else {
        loggingInDev("error =>", response);
        setDisabled(false);
      }
    }
  };

  return (
    <Dialog
      open={pictureDialog}
      aria-labelledby="alert-set-profile-picture"
      sx={{ justifyContent: "center" }}
      maxWidth="xs"
      PaperProps={{
        style: {
          borderRadius: "8px",
          padding: "24px 20px 24px 20px",
          boxSizing: "border-box",
        },
      }}
    >
      <Grid container>
        <Box display="flex" justifyContent="flex-end" style={{ width: "100%" }}>
          <IconButton
            sx={{ justifyContent: "flex-end" }}
            onClick={() => {
              setPictureDialog(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Grid item xs={12} sx={{ pb: "10px" }}>
          <Typography align="center" variant="h2">
            {t("hi")} {firstName} {lastName}!
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          sx={{
            pb: "30px",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography variant="h3">{t("upload")}</Typography>
          <Typography variant="body1" color={theme.palette.text.disabled}>
            {t("upload_size")}
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          sx={{ justifyContent: "center", pb: "10px" }}
        >
          {file ? (
            <img
              ref={uploadedImage}
              style={{ width: "120px", height: "120px", borderRadius: "8px" }}
            />
          ) : (
            <ProfileIcon style={{ width: "120px", height: "120px" }} />
          )}
        </Grid>
      </Grid>
      <DialogActions>
        <Grid container>
          <Grid
            container
            item
            xs={12}
            sx={{ justifyContent: "center", pb: "10px" }}
          >
            <input
              type="file"
              accept="image/png, image/jpeg"
              onChange={handlePPUpload}
              multiple={false}
              ref={imageUploader}
              style={{ display: "none" }}
            />
            <Button
              // variant="outlined"
              onClick={() => imageUploader.current.click()}
              style={{
                padding: "4px 28px",
              }}
              variant="contained"
              component="label"
              startIcon={<CloudUploadIcon />}
            >
              {t("upload_title")}
            </Button>
          </Grid>
          <Grid
            container
            item
            xs={12}
            columnSpacing={3}
            sx={{ justifyContent: "center", marginTop: "12px" }}
          >
            <Grid item>
              <Button variant="submit" onClick={handleNext} disabled={disabled}>
                {t("cont")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
