import { useState } from "react";

import {
  Box,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { useStyles } from "../../styles/navigationButtonStyles.js";
import { QRCodeDialog } from "./QRCodeDialog.jsx";
import QRCodeCard from "../../pages/QrClient/QRCodeCard";
import QRCodeInformation from "./QRCardInformation.jsx";
import puseStyle from "./styles";
import { useNavigate } from "react-router-dom";
import ToolTip from "../Global/ToolTip";

export default function QRCodeButton({ handleDrawerToggle, t }) {
  const [open, setOpen] = useState(false);
  const [qrOpen, setQROpen] = useState(false);
  const theme = useTheme();
  const [qrCodeCard, setQRCodeCard] = useState(false);
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const pclasses = puseStyle();
  const classes = useStyles();
  const navigate = useNavigate();

  const openQRCode = () => {
    navigate("/qr-code");
    // if (mobile) setQRCodeCard(true);
    // else setOpen(true);
    handleDrawerToggle();
  };

  return (
    <>
      <ToolTip title={t("qr_tooltip")} placement="right">
        <Box
          className={open ? classes.active : classes.inactive}
          onClick={openQRCode}
        >
          <ListItemButton
            alignItems="center"
            disableRipple
            className={open ? classes.activeButton : classes.inactiveButton}
          >
            <ListItemIcon className={classes.icon}>
              <QrCodeScannerIcon className={classes.iconSize} />
            </ListItemIcon>
            <ListItemText
              className={classes.text}
              disableTypography={true}
              primary={
                <Typography align="left" variant="h4" color={"white"}>
                  {t("qr_code")}
                </Typography>
              }
            />
          </ListItemButton>
        </Box>
      </ToolTip>
      {open && !mobile ? (
        <QRCodeInformation
          open={open}
          handleClose={() => setOpen(false)}
          setQROpen={setQROpen}
          t={t}
        />
      ) : (
        <></>
      )}

      {!mobile ? (
        <></>
      ) : !qrCodeCard ? (
        <></>
      ) : (
        <Box className={pclasses.qrCard}>
          <QRCodeCard
            is_user={true}
            setQRCodeCard={setQRCodeCard}
            handleQROpen={() => setQROpen(true)}
          />
        </Box>
      )}

      {qrOpen && (
        <QRCodeDialog
          open={qrOpen}
          handleDialogOpen={() => {
            setQROpen(false);
            setQRCodeCard(false);
          }}
          t={t}
        />
      )}
    </>
  );
}
