import makeStyles from "@mui/styles/makeStyles";
const useStyle = makeStyles(() => ({
  centerLoader: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },
}));

export default useStyle;
