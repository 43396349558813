import { Box, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import { ReactComponent as EyeVisible } from "assets/eye_visible.svg";
import { ReactComponent as EyeHidden } from "assets/eye_hidden.svg";

export function SocialCard({
  socialName,
  SocialLogo,
  bgColor,
  value,
  is_user,
  formName,
  handleFormChange,
  isDisabled,
  handleHideShow,
}) {
  const handleAccountClick = () => {
    if (value && !isDisabled) {
      window.open(value, "_blank");
    }
  };
  return (
    <>
      <Grid
        container
        direction="row"
        alignItems={"center"}
        height={"70px"}
        sx={{
          backgroundColor: bgColor,
          borderRadius: "8px",
          opacity: value && !is_user && !isDisabled ? 1 : is_user ? 1 : 0.6,
          cursor: "pointer",
        }}
        // gap={1}
      >
        <img
          src={SocialLogo}
          alt="twitter"
          style={{ marginLeft: 10, marginRight: 10 }}
        />
        {is_user ? (
          <Box display={"flex"} width={"80%"} justifyContent={"space-between"}>
            <TextField
              name={formName}
              value={value}
              onChange={handleFormChange}
              variant="standard"
              type="url"
              disabled={isDisabled}
              sx={{
                width: "80%",
                marginTop: "4px",
              }}
              placeholder="e.g http://social-media.com"
              InputProps={{
                disableUnderline: true,
                style: {
                  fontSize: "16px",
                  fontWeight: 400,
                  border: "1px solid #DAD2E5",
                  borderRadius: "6px",
                  paddingLeft: "8px",
                  backgroundColor: "#F7F7F9",
                },
              }}
            />
            {isDisabled ? (
              <EyeHidden
                style={{
                  width: "20%",
                  cursor: "pointer",
                }}
                onClick={() => handleHideShow(formName)}
              />
            ) : (
              <EyeVisible
                style={{ width: "20%", cursor: "pointer" }}
                onClick={() => handleHideShow(formName)}
              />
            )}
          </Box>
        ) : (
          <Typography
            variant="h3"
            fontWeight={400}
            onClick={handleAccountClick}
          >
            {`Visit my ${socialName}
          ${socialName === "LinkedIn" ? "profile" : "account"}`}
          </Typography>
        )}
      </Grid>
    </>
  );
}
