import { Box, Tab, Tabs } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import useWebsiteStore from "../../Utils/websiteStore";

export default function SectionTabs() {
  const { t } = useTranslation();
  const tabIndex = useWebsiteStore((state) => state.tabIndex);
  const setTabIndex = useWebsiteStore((state) => state.setTabIndex);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };
  return (
    <Box>
      <Tabs
        value={tabIndex}
        onChange={handleChange}
        variant="scrollable"
        aria-label="website"
        centered
        scrollButtons={true}
      >
        <Tab
          label={t("details")}
          style={{
            textTransform: "capitalize",
            paddingBottom: 0,
          }}
        />
        <Tab
          label={t("locations")}
          style={{
            textTransform: "capitalize",
            paddingBottom: 0,
          }}
        />
        <Tab
          label={t("services")}
          style={{
            paddingBottom: 0,
            textTransform: "capitalize",
          }}
        />
        <Tab
          label={t("set_availibility")}
          style={{
            paddingBottom: 0,
            textTransform: "capitalize",
          }}
        />
        <Tab
          label={t("booking_form")}
          style={{
            paddingBottom: 0,
            textTransform: "capitalize",
          }}
        />
        <Tab
          label={t("notifications")}
          style={{
            paddingBottom: 0,
            textTransform: "capitalize",
          }}
        />
      </Tabs>
    </Box>
  );
}
