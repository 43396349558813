import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import useStyle from "./styles";

export default function AccordionComponent({ children, title }) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const [expanded, setExpanded] = useState(true);
  const classes = useStyle();

  return (
    <Accordion
      elevation={0}
      expanded={expanded}
      disableGutters
      onChange={() => setExpanded((prev) => !prev)}
      className={classes.accordion}
    >
      <AccordionSummary
        style={{
          padding: 0,
          display: "flex",
          justifyContent: "flex-start",
          // marginBottom: 14,
        }}
        expandIcon={
          expanded ? (
            <Typography
              color="primary"
              variant="h3"
              style={{ transform: "rotate(180deg)" }}
            >
              <KeyboardArrowUpIcon style={{ color: "#B3B5B6" }} />
            </Typography>
          ) : (
            <Typography color="primary" variant="h3">
              <KeyboardArrowDownIcon style={{ color: "#B3B5B6" }} />
            </Typography>
          )
        }
        aria-controls="integration-content"
        id="integration-header"
      >
        <Typography align="left" variant="h3" style={{ width: "fit-content" }}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ padding: 0 }}>
        {<>{children}</>}
      </AccordionDetails>
    </Accordion>
  );
}
