import { Box, Button, Typography, Grid, useTheme, Chip } from "@mui/material";
import React, { useEffect, useState } from "react";
import useStyle from "./styles";
import { AiOutlinePlusCircle } from "react-icons/ai";
import useWebsiteStore from "../../Utils/websiteStore";
import { useTranslation } from "react-i18next";
import { AddAService } from "./AddAService";
import { serverPOST } from "../../Utils/HttpFunctions";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import SnackbarComponent from "../Global/SnackbarComponent";
import ServiceOptions from "./Preview/ServiceOptions";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import categoryData from "../../Utils/website.json";
import AddIcon from "@mui/icons-material/Add";
import { PreviewButtonGroup } from "./Availability";

export type Question = {
  type_of_question: string;
  label: string;
  max_characters?: number;
  is_required: boolean;
  options?: string[];
};

export type Service = {
  name: string;
  duration_minutes: number | undefined;
  additional_notes?: string;
  questions?: Question[];
  location: string;
  meeting_room?: string;
};

export default function Services() {
  const theme = useTheme();
  const classes = useStyle(theme);
  const calendarData = useWebsiteStore((store) => store.calendarData);
  const setCalendarData = useWebsiteStore((store) => store.setCalendarData);
  const [addServiceDialog, setAddServiceDialog] = useState(false);
  const services = useWebsiteStore((store) => store.services);
  const setServices = useWebsiteStore((store) => store.setServices);
  const category = useWebsiteStore((store) => store.category);
  const { t } = useTranslation();
  const [message, setMessage] = useState("");
  const [showPreview, setShowPreview] = useState(false);
  const [editService, setEditService] = useState(undefined);
  const [editIndex, setEditIndex] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [suggestedService, setSuggestedService] = useState<any>(undefined);
  const [selectedCategory, setSelectedCategory] = useState(
    categoryData && categoryData.find((data) => data.title === category)
  );

  useEffect(() => {
    if (calendarData && calendarData.services?.length > 0) {
      setServices(calendarData.services);
    }
  }, [calendarData]);

  const handleDeleteQuestion = (i: number, j: number) => {
    let temp: any = [...services];
    temp[i]?.questions.splice(j, 1);
    setServices(temp);
  };

  const addService = async (serviceArray) => {
    setLoading(true);
    for (let i = 0; i < serviceArray.length; ++i) {
      delete serviceArray[i]["_id"];
      for (let j = 0; j < serviceArray[i].questions?.length; ++j) {
        delete serviceArray[i].questions[j]["_id"];
        delete serviceArray[i].questions[j]["index"];
      }
    }

    const response = await serverPOST(`/web-integration`, {
      services: serviceArray,
    });
    if (response && response.constructor !== String) {
      setCalendarData(response.data.calendarData);
      setMessage("Services have been updated");
    }
    setLoading(false);
  };

  const handleAddServiceDialog = () => {
    const newVal = !addServiceDialog;
    if (!newVal) {
      setEditService(undefined);
      setSuggestedService(undefined);
      setEditIndex(undefined);
    }
    setAddServiceDialog(newVal);
  };

  const handleDeleteService = (i) => {
    setServices(services.filter((x, ind) => ind !== i));
  };

  const handleServicesSave = () => {
    addService(services);
  };

  return (
    <>
      {showPreview ? (
        <ServiceOptions client={false} />
      ) : (
        <>
          <Box className={classes.serviceMainContainer}>
            {services?.map((service, i) => (
              <Box key={i} className={classes.serviceBox}>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="h3">{service.name}</Typography>
                  <Box
                    style={{
                      display: "flex",
                    }}
                  >
                    <EditOutlinedIcon
                      fontSize="small"
                      onClick={() => {
                        setEditService(service);
                        setEditIndex(i);
                        handleAddServiceDialog();
                      }}
                    />
                    <DeleteOutlineRoundedIcon
                      fontSize="small"
                      style={{
                        color: "red",
                        cursor: "pointer",
                      }}
                      onClick={() => handleDeleteService(i)}
                    />
                  </Box>
                </Box>
                <Typography
                  variant="h4"
                  className={classes.secondaryText}
                  fontWeight={400}
                >
                  {service.duration_minutes + " " + t("minutes")}
                </Typography>
                <ServiceQuestion
                  service={service}
                  classes={classes}
                  handleDeleteQuestion={handleDeleteQuestion}
                  i={i}
                />
              </Box>
            ))}
            <AddButtonWithIcon
              handleClick={handleAddServiceDialog}
              label={t("add_service")}
            />
            {category === "Other" || !category ? null : (
              <Box className={classes.w100}>
                <Typography variant="h4" fontWeight={500} align="left">
                  {t("suggested_services")}
                </Typography>
                <Box display="flex" mt={1} flexWrap={"wrap"} gap={2}>
                  {selectedCategory &&
                    selectedCategory?.services?.map((service, i) => {
                      return (
                        <Chip
                          clickable={true}
                          label={service.title}
                          icon={<AddIcon style={{ width: 16, height: 16 }} />}
                          sx={{
                            background: "#96DCFF3D",
                            borderRadius: "8px",
                            "&&:hover": {
                              opacity: 0.9,
                              background: "#96DCFF3D",
                            },
                          }}
                          onClick={() => {
                            setSuggestedService(service);
                            handleAddServiceDialog();
                          }}
                        />
                      );
                    })}
                </Box>
              </Box>
            )}
          </Box>
        </>
      )}
      <PreviewButtonGroup
        onSave={handleServicesSave}
        loading={loading}
        showPreview={showPreview}
        setShowPreview={setShowPreview}
      />

      <AddAService
        addServiceDialog={addServiceDialog}
        handleAddServiceDialog={handleAddServiceDialog}
        editService={editService}
        suggestedService={suggestedService}
        editIndex={editIndex}
        // handleSuggestedService={handleSuggestedService}
        t={t}
      />
      <SnackbarComponent
        open={!!message}
        handleClose={() => setMessage("")}
        message={message}
        vertical={"bottom"}
        horizontal={"center"}
      />
    </>
  );
}

export function AddServiceQuestions({ label, desc, onClick }) {
  const theme = useTheme();
  const classes = useStyle(theme);

  return (
    <Box className={classes.questionBox} onClick={onClick}>
      <Typography variant="h3" fontWeight={400}>
        {label}
      </Typography>
      <Typography
        variant="h4"
        style={{
          color: "#70757A",
          fontWeight: 400,
        }}
      >
        {desc}
      </Typography>
    </Box>
  );
}

export function ButtonGroup({ handleCancel, handleSave, t, classes }) {
  return (
    <Box className={classes.buttonGroup}>
      <Button variant="outlined" onClick={handleCancel}>
        {t("cancel")}
      </Button>
      <Button variant="contained" onClick={handleSave}>
        {t("save")}
      </Button>
    </Box>
  );
}

export function ServiceQuestion({
  service,
  classes,
  isQuestion,
  handleQuestion,
  suggestedService,
  suggestedQuestions,
  setSuggestedQuestions,
  handleDeleteQuestionInService,
}: {
  service;
  classes;
  handleDeleteQuestion?;
  i?;
  isQuestion?;
  handleQuestion?;
  suggestedService?;
  suggestedQuestions?;
  setSuggestedQuestions?;
  handleDeleteQuestionInService?;
}) {
  return (
    <>
      {service.questions &&
        service.questions.map((question, j) => (
          <Box className={classes.mt10} key={j}>
            <Grid container display="flex" alignItems={"center"} gap={1}>
              <Grid item xs={9}>
                <Typography variant="h3" fontWeight={400}>
                  {question.label}
                </Typography>
              </Grid>

              {isQuestion && (
                <Grid
                  item
                  xs={2}
                  style={{ marginLeft: "auto", cursor: "pointer" }}
                >
                  <EditOutlinedIcon
                    fontSize="small"
                    onClick={() => handleQuestion(undefined, question, j)}
                    style={{
                      marginRight: 8,
                    }}
                  />
                  <DeleteOutlineRoundedIcon
                    fontSize="small"
                    style={{
                      color: "red",
                    }}
                    onClick={() => {
                      if (question.index >= 0) {
                        const suggestedQuestion =
                          suggestedService.questions[question.index];
                        setSuggestedQuestions([
                          ...suggestedQuestions,
                          suggestedQuestion,
                        ]);
                      }
                      handleDeleteQuestionInService(j);
                    }}
                  />
                </Grid>
              )}
            </Grid>
            {question.options &&
              question.options.map((option) => (
                <Box
                  display="flex"
                  alignItems={"center"}
                  className={classes.mt10}
                >
                  <Box
                    style={{
                      backgroundColor: "#fff",
                    }}
                    className={classes.circle}
                  />
                  <Box>
                    <Typography variant="h4" fontWeight={400}>
                      {option}
                    </Typography>
                  </Box>
                </Box>
              ))}
          </Box>
        ))}
    </>
  );
}

export function AddButtonWithIcon({ handleClick, label }) {
  const theme = useTheme();
  const classes = useStyle(theme);

  return (
    <Box mt={1} display="flex" className={classes.w100}>
      <Button
        variant="outlined"
        style={{
          border: "none",
          color: "#479AE9",
        }}
        startIcon={<AiOutlinePlusCircle />}
        onClick={() => {
          handleClick();
        }}
      >
        {label}
      </Button>
    </Box>
  );
}
