import { Box, Button, useTheme, Typography } from "@mui/material";
import React from "react";
import { downloadFileAttachment } from "../../Utils/CommonFunctions";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import useStyle from "./styles";

export default function Attachement({ filename, meetingId }) {
  const theme = useTheme();
  const classes = useStyle();

  const handleDownload = async () => {
    await downloadFileAttachment(filename, meetingId);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start">
      <Button
        variant="text"
        className={classes.attachement}
        onClick={handleDownload}
      >
        <Typography
          variant="h3"
          fontWeight="400"
          className={classes.dottedText}
        >
          {filename}
        </Typography>
        <FileDownloadOutlinedIcon
          fontSize={"small"}
          style={{ marginLeft: "auto" }}
        />
      </Button>
    </Box>
  );
}
