import { Box } from "@mui/material";
import BadgedSelectedChip from "./BadgedSelectedChip";

export default function SelectedTimeChip(props) {
  const renderSelected = () => {
    if (props?.selectedSlot !== undefined) {
      const selected =
        props.selectedSlot &&
        props.selectedSlot.map((selected, i) => (
          <BadgedSelectedChip
            key={i}
            selected={selected}
            id={i}
            handleRemoveClick={props.handleRemoveClick}
          />
        ));
      return selected;
    }
  };

  const selectedComponents = renderSelected();

  return (
    <Box
      spacing="8px"
      display="flex"
      justifyContent={"flex-start"}
      sx={{
        flexWrap: "wrap",
        width: { xs: "100%", md: "95%" },
        marginLeft: "40px",
        // maxHeight: "35vh",
        width: "100%",
        // overflow: "auto",
      }}
    >
      {selectedComponents}
    </Box>
  );
}
