import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
    disconnect: {
      background: "transparent",
      border: "1px solid #479AE9",
      padding: "0px 18px",
      color: "#479AE9",
      "&:hover": {
        background: "#EFFAFF",
        color: "#479AE9",
      },
      "&:disabled": {
        background: "transparent",
        color: "#479AE9",
      },
    },
  });