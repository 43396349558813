import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiInputBase-input": {
      padding: "6px 8px",
      color: theme.palette.text.primary,
      fontWeight: 400,
      width: "100%",
      borderBottom: "none",
      outline: "none",
      backgroundColor: theme.palette.secondary.main,
      borderRadius: "6px",
      border: null,
      "&:focus": {
        boxShadow: "inset 0px -502px 0px -500.5px #b3b5b6",
        backgroundColor: theme.palette.secondary.main,
      },
      "&:hover": {
        boxShadow: "inset 0px -502px 0px -500.5px #b3b5b6",
        backgroundColor: theme.palette.secondary.main,
      },
    },
    "& .MuiInputBase-root": {
      width: "100%",
    },
    width: "100%",
  },
  label: {
    textAlign: "left",
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  // for inputs of color #F7F7F9
  input: {
    "& .MuiInputBase-input": {
      padding: "6px 8px",
      color: theme.palette.text.primary,
      fontWeight: 400,
      width: "100%",
      borderBottom: "none",
      outline: "none",
      backgroundColor: theme.palette.secondary.lighter,
      borderRadius: "6px",
      border: null,
      "&:focus": {
        boxShadow: "inset 0px -502px 0px -500.5px #b3b5b6",
        backgroundColor: theme.palette.secondary.lighter,
      },
      "&:hover": {
        boxShadow: "inset 0px -502px 0px -500.5px #b3b5b6",
        backgroundColor: theme.palette.secondary.lighter,
      },
    },
    "& .MuiInputBase-root": {
      width: "100%",
    },
    width: "100%",
  },
}));

export default useStyles;
