import { useContext, useState } from "react";

import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Button,
  Typography,
  Box,
  useMediaQuery,
  useTheme,
  Grid,
  Backdrop,
  CircularProgress,
  Popover,
} from "@mui/material";
import PostAddIcon from "@mui/icons-material/PostAdd";

import { drawerContext } from "context/Drawer";
import { accountContext } from "context/user";
import { ReactComponent as SkedingBetaIcon } from "assets/logo-beta.svg";
import { serverGET } from "Utils/HttpFunctions";
import { colors } from "Utils/colors";
import { useStyle } from "styles/tooltip";
import InstantMeeting from "./InstantMeeting";
import InviteCollaboratorsButton from "./InviteCollaboratorsButton";
import Info from "./Info";
import EventsSearchbar from "./EventsSearchbar";
import DrawerButton from "./DrawerButton";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { handleIsLock } from "../../Utils/CommonFunctions";
import Languages from "../Account/Languages";
import useMeetingStore from "../../Utils/createMeetingStore";
import puseStyle from "./styles";
import ToolTip from "../Global/ToolTip";
/**
 * Renders the Nav bar at the top of the screen
 * Returns different implementations for mobile and desktop because the layouts differ significantly
 */
export default function NavBar() {
  const { user } = useContext(accountContext);
  const { handleDrawerToggle } = useContext(drawerContext);
  const { setMeetingModal } = useMeetingStore();
  const [instantMeeting, setInstantMeeting] = useState(false);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyle();
  const pclasses = puseStyle();
  const [isLocked, setIsLocked] = useState(false);
  useEffect(() => {
    if (user) setIsLocked(handleIsLock(user));
  }, [user]);

  const navigate = useNavigate();

  /**
   * Calculates the GMT offset for the client's timezone
   * @return {string} - the timezone offset from -12 to +14
   */
  const handleOffset = () => {
    function z(n) {
      return (n < 10 ? "0" : "") + n;
    }
    var offset = new Date().getTimezoneOffset();
    var sign = offset < 0 ? "+" : "-";
    offset = Math.abs(offset);
    return sign + " " + offset / 60 + ":" + z(offset % 60);
  };

  /**
   * Calculates the timezone abbreviation for the client's timezone
   * @returns {string} - timezone abreviation
   */
  const handleTimeAbbreviation = () => {
    var date = new Date().toLocaleString("en-US", {
      timeZoneName: "long",
      hour12: false,
    });
    var abvArray = date.match(/[A-Z]/g);
    return abvArray.join("");
  };

  /**
   * @returns {string} - the timezone with the locale, GMT offset and timezone abbreviation
   */
  const handleTimeZone = () => {
    var locale = Intl.DateTimeFormat().resolvedOptions().timeZone;
    var firstLocale = locale.split("/")[0];
    const timeZone = ` ${firstLocale} (GMT ${handleOffset()}) [${handleTimeAbbreviation()}]`;
    return timeZone;
  };

  /**
   * Makes a request to the backend for the value input into the "Search for events" bar
   */
  const handleSearch = async () => {
    if (search !== "") {
      setLoading(true);

      const response = await serverGET(`/search?q=${search}&start=0`);
      if (response && response.constructor !== String) {
        console.log(response.data);
        setLoading(false);
        navigate("/search-events", {
          state: { search, events: response?.data?.results },
        });
      }
    }
  };

  /**
   * Makes a search request when the enter key is pressed
   * @param {keydown} e - key press event
   */
  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      handleSearch();
    }
  };

  return small ? (
    <AppBar
      position="static"
      sx={{
        backgroundColor: theme.palette.background.default,
        backgroundImage: "none",
        color: "#252733",
        boxShadow: 0,
        width: "100%",
        marginTop: "8px",
      }}
    >
      <Grid
        container
        alignItems="center"
        sx={{ pb: "15px" }}
        rowSpacing={3}
        justifyContent="center"
      >
        <Grid container item xs={12} alignItems="center">
          <Grid container item xs={4} justifyContent="flex-start">
            <DrawerButton handleDrawerToggle={handleDrawerToggle} />
          </Grid>
          <Grid
            container
            item
            xs={4}
            justifyContent="center"
            alignItems="center"
          >
            <SkedingBetaIcon
              width={"75%"}
              onClick={() => navigate("/dashboard")}

              // height={"15%"}
            />
          </Grid>
          <Grid item xs={4}>
            <Info user={user} small={small} />
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: 5 }}>
          <Box display={"flex"} gap={3} alignItems={"center"}>
            <Typography variant="body2" align="left">
              {t("timezone_title")}: {handleTimeZone()}
            </Typography>

            <Languages small={small} />
          </Box>
        </Grid>

        {/* BR-1 */}
        <Grid container item xs={12} style={{ paddingTop: small ? 15 : 24 }}>
          <Box
            display="flex"
            justifyContent="space-between"
            style={{ width: "100%" }}
          >
            <EventsSearchbar
              handleSearch={handleSearch}
              handleKeyPress={handleKeyPress}
              setSearch={setSearch}
              isPaid={!isLocked}
            />
            <InviteCollaboratorsButton />
          </Box>
          <InstantMeeting
            instantMeeting={instantMeeting}
            setInstantMeeting={setInstantMeeting}
          />
          <Backdrop
            sx={{
              color: colors.primary,
              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Grid>
      </Grid>
    </AppBar>
  ) : (
    <AppBar
      position="static"
      sx={{
        backgroundColor: theme.palette.background.default,
        backgroundImage: "none",
        color: "#252733",
        boxShadow: 0,
        width: "100%",
        marginTop: "20px",
        marginBottom: "25px",
      }}
    >
      <Grid container alignItems="center" sx={{ width: "100%" }}>
        <DrawerButton handleDrawerToggle={handleDrawerToggle} />
        <Grid item md={5}>
          <Box display={"flex"} gap={3} alignItems={"center"}>
            <Typography variant="h4" fontWeight="400" align="left">
              {t("timezone_title")}: {handleTimeZone()}
            </Typography>

            <Languages />
          </Box>
        </Grid>

        <Grid item md={7}>
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Button
              variant="outlined"
              onClick={() => navigate("/payment-services")}
              disabled={user?.package?.paid}
              className={pclasses.subscribedBtn}
              disableElevation
            >
              {user?.package?.paid ? t("subscribed_msg") : t("get_premium")}
            </Button>
            <ToolTip title={t("create_meeting_tooltip")} placement="bottom">
              <Button
                variant="submit"
                onClick={() => setMeetingModal(true)}
                className={pclasses.creatBtn}
                disableElevation
              >
                <PostAddIcon style={{ height: "18px", marginRight: "4px" }} />
                {t("create")}
              </Button>
            </ToolTip>
            <InviteCollaboratorsButton />
            <EventsSearchbar
              handleSearch={handleSearch}
              handleKeyPress={handleKeyPress}
              setSearch={setSearch}
              isPaid={!isLocked}
            />
            <Backdrop
              sx={{
                color: colors.primary,
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <Info user={user} />
          </Box>
        </Grid>
      </Grid>
    </AppBar>
  );
}
