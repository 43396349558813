import {
  Box,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import TourIcon from "@mui/icons-material/Tour";
import { useStyles } from "../../styles/navigationButtonStyles.js";
import { useStyle } from "styles/tooltip";
import { useContext, useEffect, useState } from "react";
import { tourContext } from "../../context/Tour.js";
import CreateMeetingTour from "../signup/CreateMeetingTour.jsx";

export default function TourButton({ t }) {
  const classes = useStyles();
  // const { setRun, run } = useContext(tourContext);
  const [run, setRun] = useState(false);

  const handleClose = () => {
    setRun(false);
  };

  return (
    <>
      {run ? (
        <CreateMeetingTour tourDialog={run} handleClose={handleClose} />
      ) : null}
      <Box className={classes.inactive} onClick={() => setRun(true)}>
        <ListItemButton alignItems="center" className={classes.inactiveButton}>
          <ListItemIcon className={classes.icon}>
            <TourIcon className={classes.iconSize} />
          </ListItemIcon>
          <ListItemText
            className={classes.text}
            disableTypography={true}
            primary={
              <Typography align="left" variant="h4" color={"white"}>
                {t("Start Tour")}
              </Typography>
            }
          />
        </ListItemButton>
      </Box>
    </>
  );
}
