import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import PlanFeatures from "./PlanFeatures";
import { PREMIUM_PLAN } from "../../Utils/Constants";
import { useNavigate } from "react-router-dom";

export default function FreeTrialDialog({
  dialogTitle,
  dialogDesc,
  onClick,
  hideBtn,
  buttonText,
}: {
  dialogTitle: string;
  dialogDesc: string;
  onClick: () => void;
  hideBtn?: boolean;
  buttonText?: string;
}) {
  return (
    <Grid container direction={"column"} gap={2} p={2}>
      <Typography variant="h2">{dialogTitle}</Typography>
      <Typography variant="h3" fontWeight={400} lineHeight={"25.6px"}>
        {dialogDesc}
        <Grid item my={2}>
          <PlanFeatures planType={PREMIUM_PLAN} />
        </Grid>

        {hideBtn ? null : (
          <Button variant="contained" onClick={onClick}>
            {buttonText ? buttonText : dialogTitle}
          </Button>
        )}
      </Typography>
    </Grid>
  );
}
