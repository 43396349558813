import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import {
  DesktopDatePicker,
  MobileDateTimePicker,
  DesktopDateTimePicker,
} from "@mui/x-date-pickers";
import moment from "moment";
import useStyle from "../../styles/formStyles";
import { useTranslation } from "react-i18next";

export default function SingleSlot({
  singleSlot,
  handleCheck,
  formDate,
  handleDateChange,
}: {
  singleSlot: boolean;
  handleCheck: () => void;
  formDate: moment.Moment;
  handleDateChange: (date: any) => void;
}) {
  const [calendarOpen, setCalendarOpen] = useState(false);
  const classes = useStyle();
  const theme = useTheme();
  const { t } = useTranslation();
  // const mobile = useMediaQuery(theme.breakpoints.down("md"));

  // Get the current date and time
  const currentDate = moment();

  // Set the minimum date to the current date
  const minDate = currentDate;

  const minTimeCheck = formDate.isSame(moment(), "day") ? moment() : null;
  // Set the minimum time to the current time
  const minTime = moment().set({
    hour: currentDate.hours(),
    minute: currentDate.minutes(),
    second: 0,
  });
  return (
    <>
      <FormGroup>
        <FormControlLabel
          name=""
          control={
            <Checkbox
              size="small"
              checked={singleSlot}
              onChange={handleCheck}
              sx={{
                color: theme.palette.text.primary,
                "&.Mui-checked": {
                  color: "#F7F7F9",
                  stroke: theme.palette.text.primary,
                  strokeWidth: "1.5px",
                  strokeLineJoin: "round",
                },
              }}
            />
          }
          label={
            <Typography variant="h3" fontWeight="400">
              {t("single_slot_desc")}
            </Typography>
          }
        />
      </FormGroup>

      {/* date time picker */}
      {singleSlot && (
        <Grid container alignItems={"center"}>
          <Grid item xs={12} md={1.5}>
            <InputLabel htmlFor="title">
              <Typography variant="h4">{t("Date")}:</Typography>
            </InputLabel>
          </Grid>
          <Grid item xs={11} md={3}>
            <Button disableRipple>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                {/* {mobile ? (
                  <MobileDateTimePicker
                    value={formDate}
                    onChange={(newValue: any) => setFormDate(newValue)}
                    renderInput={(props) => (
                      <TextField {...props} variant="standard" />
                    )}
                  />
                ) : ( */}
                <DesktopDateTimePicker
                  open={calendarOpen}
                  onOpen={() => setCalendarOpen(true)}
                  onClose={() => setCalendarOpen(false)}
                  value={formDate}
                  disablePast={true}
                  minDate={minDate}
                  minTime={minTimeCheck ? minTime : null}
                  onChange={(newValue: any) => {
                    handleDateChange(newValue);
                  }}
                  PaperProps={{
                    sx: {
                      span: { color: "black" },
                      "& .MuiPickersDay-root": {
                        color: "black",
                        "&.Mui-selected": {
                          color: "white !important",
                          backgroundColor: "#479AE9 !important",
                          "&:hover": {
                            opacity: 0.8,
                          },
                        },
                      },
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      onClick={(e) => setCalendarOpen((prev) => !prev)}
                      onKeyDown={(e) => e.preventDefault()}
                      className={classes.dateTime}
                      //   style={{ width: "120px" }}
                      InputProps={{
                        //not applying font weight idk why
                        disableUnderline: true,
                        style: { fontWeight: "600" },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
}
