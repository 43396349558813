import React, { useState, useEffect } from "react";

import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import {
  Button,
  Card,
  Stack,
  Typography,
  Grid,
  Select,
  useTheme,
  useMediaQuery,
  MenuItem,
  Box,
} from "@mui/material";

import { serverGET } from "Utils/HttpFunctions";
import { useContext } from "react";
import { authContext } from "context/Auth";
import { convertUTCMillisecondsToDate, dayHeader } from "Utils/CommonFunctions";
import {
  ONE_NONSKEDING_MULTIPLE_SKEDING_USERS,
  ONE_NONSKEDING_ONE_SKEDING_USER,
} from "Utils/Constants";
import ScrollButton from "components/ScrollButton";
import { useTranslation } from "react-i18next";

/**
 * Component that renders the time selected by an initiator onto a calendar
 */
export default function TimeCalendar(props) {
  const {
    calendarRef,
    sharedAvailableTimes,
    setSharedAvailableTimes,
    events,
    available: availableTimes,
    eventsLoaded,
    participants,
    unavailability,
    selectedSlot,
  } = props;

  const [navLink, setNavLink] = useState(false);
  const [unavailable, setUnavailable] = useState([]);
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [userMeetings, setUserMeetings] = useState([]);
  const [available, setAvailable] = useState([]);
  const [maxSelection, setMaxSelection] = useState(1);
  const [selectedBtn, setSelectedBtn] = useState(2);
  const { t } = useTranslation();
  const { isLogged } = useContext(authContext);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  /**
   * Fetches user's existing meetings to mark spots as unavailable on the calendar
   */
  useEffect(() => {
    const abortController = new AbortController();
    if (isLogged) {
      getUserMeetings(abortController);
    }
    return () => {
      abortController.abort();
    };
  }, [calendarRef, isLogged, events]);

  /**
   * Sets the view variable conditionally for the component that the calendar's being rendered for
   */
  useEffect(() => {
    if (eventsLoaded) {
      if (events?.length > 0) {
        setCalendarView("new-event", events);
      } else if (availableTimes?.length > 0) {
        setCalendarView("meeting-poll", availableTimes);
      }
    }
  }, [eventsLoaded]);

  /**
   * Sets available times when nonskeding user opts to manually choose a time
   */
  useEffect(() => {
    if (participants?.length > 0 && props?.timeInterval !== "") {
      //when non skeding schedule manually then to show available slots
      let parseResponse;
      if (props.eventCase === "NonSignUpOneSelect") {
        parseResponse = props?.available?.map((item) => {
          return {
            start: convertUTCMillisecondsToDate(item.startTime),
            end: convertUTCMillisecondsToDate(item.endTime),
          };
        });
      }
      //--end--
      setAvailable({
        events:
          props.eventCase === "NonSignUpOneSelect" ? parseResponse : events,
        backgroundColor: "rgba(150, 220, 255, 0.5)",
      });
    } else {
      setAvailable([]);
    }
  }, [events, participants]);

  /**
   * Sets the unavailable time slots to be displayed on the calendar
   */
  useEffect(() => {
    if (unavailability) {
      handleUnavailability();
    }
  }, [unavailability]);

  /**
   * Sets the selected times to be shown on the calendar
   */
  useEffect(() => {
    renderSelectedTimes();
  }, [selectedSlot]);

  /**
   * Sets the maximum number of time slots that can be selected for:
   * ONE_NONSKEDING_ONE_SKEDING_USER, ONE_NONSKEDING_MULTIPLE_SKEDING_USERS and NonSignUp cases
   */
  useEffect(() => {
    if (
      (props.eventCase === ONE_NONSKEDING_ONE_SKEDING_USER ||
        props.eventCase === ONE_NONSKEDING_MULTIPLE_SKEDING_USERS ||
        props.eventCase === "NonSignUp") &&
      !props.qrMeeting
    ) {
      setMaxSelection(5);
    }
  }, [props.eventCase]);

  /**
   * Fetches a user's planned meetings from the backend
   * @param {AbortController} abortController
   * @async
   */
  const getUserMeetings = async (abortController) => {
    const response = await serverGET("/meeting", abortController.signal);
    if (response && response.constructor !== String) {
      var temp = response.data.map((meeting) => {
        return {
          start: new Date(meeting.start_datetime),
          end: new Date(meeting.end_datetime),
        };
      });
      setUserMeetings({
        events: temp,
        backgroundColor: "rgba(218, 210, 229, 0.6)",
      });
    } else {
      return response?.data?.message;
    }
  };

  const sortTimesByStartHour = (time) => {
    var temp = [...events];

    switch (time === "min") {
      case true:
        temp.sort((a, b) => a.start.getHours() - b.start.getHours());
        break;
      case false:
        temp.sort((a, b) => b.end.getHours() - a.end.getHours());
        break;
    }

    return temp;
  };

  const getMinMaxTime = (setting) => {
    var temp;
    var sortedAvailability = sortTimesByStartHour(setting);

    switch (sortedAvailability.length === 0) {
      case true:
        switch (setting === "min") {
          case true:
            return "00";
          case false:
            return "24";
        }
      case false:
        if (setting === "min") {
          temp = sortedAvailability[0]?.start?.getHours();
        } else {
          temp = sortedAvailability[0]?.end?.getHours() + 1;
        }

        if (temp < 10) {
          return `0${temp?.toString()}`;
        } else {
          return temp?.toString();
        }
    }
  };

  const sortTimesByDate = (timeDirection, data, displayedIn) => {
    let temp = [];
    if (displayedIn === "meeting-poll") {
      temp = data.map((availableTime) => {
        return {
          start: new Date(availableTime.startTime),
          end: new Date(availableTime.endTime),
          _id: availableTime._id,
        };
      });
    } else {
      temp = [...data];
    }

    switch (timeDirection === "earliest") {
      case true:
        temp.sort((a, b) => a.start.getTime() - b.start.getTime());
        break;
      case false:
        temp.sort((a, b) => b.end.getTime() - a.end.getTime());
        break;
    }

    return temp;
  };

  /**
   * Moves the calendar view to display the earliest time slot
   * @param {string} displayedIn - the component which the TimeCalendar is being displayed in
   * @param {object[]} data - the available time slots
   */
  const setCalendarView = (displayedIn, data) => {
    let sortedTimes = sortTimesByDate("earliest", data, displayedIn);

    let firstAvailableTime = sortedTimes[0];

    checkIfFirstAvailableInView(firstAvailableTime);
  };

  /**
   * Checks if the first available timeslot is being displayed in the calendar view
   * @param {object} firstAvailableTime
   */
  const checkIfFirstAvailableInView = (firstAvailableTime) => {
    var viewStart = calendarRef?.current?.getApi()?.view.activeStart;
    var viewEnd = calendarRef?.current?.getApi()?.view.activeEnd;

    switch (
      firstAvailableTime.start.getTime() >= viewStart.getTime() &&
      firstAvailableTime.start.getTime() <= viewEnd.getTime()
    ) {
      case false:
        calendarRef?.current
          ?.getApi()
          ?.changeView("timeGridWeek", firstAvailableTime.start);
        break;
      case true:
        break;
    }
  };

  /**
   * Creates events that can be input into the FullCalendar component using the preferences that the user has set
   */
  const handleUnavailability = async () => {
    //current day is either the actual current day on first load or the first day of the week if the user has clicked next month
    var currentDay = calendarRef?.current.getApi()?.getDate()?.getDay();
    var currentDate = calendarRef?.current?.getApi()?.getDate();

    if (currentDay > 0) {
      currentDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() - currentDay
      );
    }
    let arrayDays = [];

    var unavailabilityAsDates = unavailability.map((unavailable) => {
      const endDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() + unavailable.end_day,
        unavailable.end_hours,
        unavailable.end_minutes
      );
      const startDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() + unavailable.start_day,
        unavailable.start_hours,
        unavailable.start_minutes
      );
      const startDate1 = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() + unavailable.start_day,
        unavailable.start_hours,
        unavailable.start_minutes
      );
      let satEnd;
      if (endDate.getDay() === 0 && startDate.getDay() === 6) {
        startDate.setHours(23);
        startDate.setMinutes(59);
        startDate.setSeconds(59);
        satEnd = new Date(startDate);
        const temp = {
          start: startDate1,
          end: satEnd,
        };
        arrayDays.push(temp);
      }

      return {
        start: new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate() + unavailable.start_day,
          unavailable.start_hours,
          unavailable.start_minutes
        ),
        end: new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate() + unavailable.end_day,
          unavailable.end_hours,
          unavailable.end_minutes
        ),
      };
    });

    setUnavailable({
      events: unavailabilityAsDates.concat(arrayDays),
      backgroundColor: "rgba(218, 210, 229, 0.6)",
    });
  };

  /**
   * Creates events that can be input into the FullCalendar component
   * to be displayed on the calendar from times selected by clicking on the chips
   */
  const renderSelectedTimes = () => {
    var temp =
      props?.selectedSlot?.length > 0 &&
      selectedSlot.map((selected) => {
        return selected.value;
      });

    setSelectedSlots({
      events: temp,
      backgroundColor: "#72B0EE",
    });
  };

  /**
   * Adds a time slot that's been clicked on the calendar to the selectedSlot array
   * @param {object} selection
   */
  const handleSingleSelect = (selection) => {
    var selectionStart = selection.start.getTime();
    var selectionEnd = selection.end.getTime();
    var temp = [];

    var selectionInAvailable = findSelectionInAvailableCopy(
      selectionStart,
      selectionEnd
    );

    temp.push(...selectionInAvailable);
    if (selectionInAvailable.length > 0 && selectedSlot?.length < 1) {
      const tempAll = [...sharedAvailableTimes];
      tempAll.splice(selectionInAvailable[0].originalIndex, 1);
      setSharedAvailableTimes(tempAll);
      props.setSelectedSlot(selectionInAvailable);
    }

    calendarRef?.current?.getApi()?.unselect();
  };

  /**
   * Handles adding multiple time slots that have been selected on calendar by clicking and dragging to the selectedSlot array
   * @param {*} selection
   */
  const handleSelect = (selection) => {
    var selectionStart = selection.start.getTime();
    var selectionEnd = selection.end.getTime();

    var selectedTimesInSelection = [];
    selectedSlot.forEach((alreadySelected) => {
      var alreadySelectedStart = alreadySelected.value.start.getTime();
      var alreadySelectedEnd = alreadySelected.value.end.getTime();

      //case for selection fully within one already selected time
      if (
        alreadySelectedStart <= selectionStart &&
        alreadySelectedStart <= selectionEnd &&
        alreadySelectedEnd >= selectionStart &&
        alreadySelectedEnd >= selectionEnd
      ) {
        selectedTimesInSelection.push(alreadySelectedStart);
      }
      //case for selections within more than one already selected time
      else if (
        alreadySelectedStart >= selectionStart &&
        alreadySelectedStart <= selectionEnd &&
        alreadySelectedEnd >= selectionStart &&
        alreadySelectedEnd <= selectionEnd
      ) {
        selectedTimesInSelection.push(alreadySelectedStart);
      }
    });
    // console.log("selectedTimesInSelection", selectedTimesInSelection);
    //checks if selection contains already selected times
    //if not add all new selections
    //if yes overwrite existing selections with new selections

    //if selections aren't overlapping this should add to selection up to length 6
    switch (selectedTimesInSelection.length === 0) {
      case true:
        var temp = [...selectedSlot];
        var selectionInAvailable = findSelectionInAvailableCopy(
          selectionStart,
          selectionEnd
        );
        // console.log("--selectionInAvailable", selectionInAvailable);
        temp.push(...selectionInAvailable);
        if (props.eventCase === "NonSignUp") {
          if (temp.length <= 3) {
            const tempAll = [...sharedAvailableTimes];
            tempAll.splice(selectionInAvailable[0].originalIndex, 1);
            setSharedAvailableTimes(tempAll);
            props.setSelectedSlot(temp);
          } else {
            props.setErrorAmount(t("five_slot_err"));
          }
        } else if (temp.length <= 5) {
          const tempAll = [...sharedAvailableTimes];
          tempAll.splice(selectionInAvailable[0]?.originalIndex, 1);
          setSharedAvailableTimes(tempAll);
          props.setSelectedSlot(temp);
        } else {
          props.setErrorAmount(t("five_slot_err"));
        }

        break;
      //remove slot
      case false:
        var temp = [...selectedSlot];
        var selectionInAvailable = findSelectionInAvailable(
          selectionStart,
          selectionEnd
        );

        // console.log("selectionInAvailable", selectionInAvailable);
        selectionInAvailable.forEach((availableTime) => {
          var alreadySelectedInAvailable = selectedSlot.findIndex(
            (alreadySelected) =>
              alreadySelected.value.start.getTime() ===
              availableTime.value.start.getTime()
          );
          if (alreadySelectedInAvailable === -1) {
            temp.push(availableTime);
          }
          //if selection is already in selected, remove it
          else {
            if (props?.errorAmount) {
              props.setErrorAmount("");
            }

            const tempAllSlots = [...sharedAvailableTimes];
            tempAllSlots.splice(
              selectionInAvailable[0].originalIndex,
              0,
              selectionInAvailable[0].value
            );
            setSharedAvailableTimes(tempAllSlots);
            temp.splice(alreadySelectedInAvailable, 1);
          }
        });
        if (temp.length < 5) {
          props.setSelectedSlot(temp);
        } else {
          props.setErrorAmount(t("five_slot_err"));
        }

        break;
    }

    calendarRef?.current?.getApi()?.unselect();
  };

  /**
   * Verifies if the selection made by a user on the calendar is within the range of any of the available timeslots
   * @param {number} selectionStart
   * @param {number} selectionEnd
   * @param {number} index
   * @returns - array of the available time slots in the range that the user selected
   */
  const findSelectionInAvailable = (selectionStart, selectionEnd) => {
    var availableTimeRange = [];

    available.events.forEach((availableTime, i) => {
      //handles selections not within one available time
      if (
        (availableTime.start.getTime() > selectionStart &&
          availableTime.start.getTime() < selectionEnd) ||
        (availableTime.end.getTime() > selectionStart &&
          availableTime.end.getTime() < selectionEnd)
      ) {
        availableTimeRange.push({
          originalIndex: i,
          value: { start: availableTime.start, end: availableTime.end },
        });
      }
      //handles selections within one available time
      if (
        availableTime.start.getTime() <= selectionStart &&
        availableTime.end.getTime() >= selectionEnd
      ) {
        availableTimeRange.push({
          originalIndex: i,
          value: { start: availableTime.start, end: availableTime.end },
        });
      }
    });

    return availableTimeRange;
  };

  const findSelectionInAvailableCopy = (selectionStart, selectionEnd) => {
    var availableTimeRange = [];

    sharedAvailableTimes.forEach((availableTime, i) => {
      if (
        (availableTime.start.getTime() > selectionStart &&
          availableTime.start.getTime() < selectionEnd) ||
        (availableTime.end.getTime() > selectionStart &&
          availableTime.end.getTime() < selectionEnd)
      ) {
        availableTimeRange.push({
          originalIndex: i,
          value: { start: availableTime.start, end: availableTime.end },
        });
      }
      //handles selections within one available time
      if (
        availableTime.start.getTime() <= selectionStart &&
        availableTime.end.getTime() >= selectionEnd
      ) {
        availableTimeRange.push({
          originalIndex: i,
          value: { start: availableTime.start, end: availableTime.end },
        });
      }
    });

    return availableTimeRange;
  };

  /**
   * Sets the view to show only a single day when the user clicks on a day in month or week view
   * @param {object} dateClicked
   */
  const handleDateClick = (dateClicked) => {
    const view = calendarRef?.current?.getApi().view.type;
    if (view === "dayGridMonth") {
      calendarRef.current.getApi().changeView("timeGridDay", dateClicked.date);
      setSelectedBtn(1);
    }
    props.handleDateChange(dateClicked);
  };

  return (
    <>
      <Grid container>
        {/* <Grid item xs={props.qrMeeting ? 11 : 12} md={props.qrMeeting ? 9 : 6}> */}
        <Grid item xs={12}>
          <Card
            sx={{
              p: mobile ? 0 : "15px",
              boxShadow: "0",
              borderRadius: "10px",
              backgroundColor: theme.palette.secondary.lighter,
              marginLeft: mobile ? 0 : "20px",
            }}
            id="create-calendar"
          >
            <Stack direction="column" justifyContent="center" spacing="2px">
              <Navigation
                calendarRef={calendarRef}
                setNavLink={setNavLink}
                handleUnavailability={handleUnavailability}
                mobile={mobile}
                selectedBtn={selectedBtn}
                setSelectedBtn={setSelectedBtn}
                t={t}
              />
              {/* <DateSelector
                calendarRef={calendarRef}
                handleUnavailability={handleUnavailability}
                dateObject={dateObject}
                mobile={mobile}
                selectedDate={selectedDate}
              /> */}
              <Box
                sx={{
                  overFlow: "auto",
                  height: "66vh",
                  //  maxHeight: "720px"
                }}
              >
                <FullCalendar
                  // firstDay={1}
                  plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin]}
                  id="create-calendar-arrows create-calendar-date"
                  initialView="timeGridWeek"
                  scrollTime={"9:00:00"}
                  allDaySlot={false}
                  slotDuration={"00:15:00"}
                  slotMinTime={`${getMinMaxTime("min")}:00:00`}
                  slotMaxTime={`${getMinMaxTime("max")}:00:00`}
                  expandRows={true}
                  headerToolbar={false}
                  datesSet={props.onCalendarMonthChange}
                  slotLabelFormat={{
                    hour: "numeric",
                    hour12: true,
                  }}
                  height={"100%"}
                  slotLabelInterval={"01:00"}
                  eventSources={[
                    available ?? [],
                    unavailable ?? [],
                    selectedSlots ?? [],
                    userMeetings ?? [],
                  ]}
                  eventTextColor="#3C4242"
                  eventDisplay="background"
                  eventOrderStrict={true}
                  slotEventOverlap={false}
                  displayEventTime={false}
                  views={{
                    dayGridMonth: {
                      dayHeaderFormat: { weekday: "short" },
                    },
                    timeGridWeek: {
                      dayHeaderContent: (date) => dayHeader(date, "week"),
                    },
                    timeGridDay: {
                      dayHeaderContent: (date) => dayHeader(date, "day"),
                    },
                  }}
                  // dayHeaderContent={(date) => dayHeader(date)}
                  navLinks={navLink}
                  navLinkDayClick={(date) => handleDateClick(date)}
                  selectable={true}
                  longPressDelay={500}
                  selectLongPressDelay={500}
                  select={
                    maxSelection === 5 ? handleSelect : handleSingleSelect
                  }
                  ref={calendarRef}
                />
              </Box>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

function Navigation({ calendarRef, mobile, handleUnavailability, t }) {
  const [title, setTitle] = useState("");

  useEffect(() => {
    handleMonthHeading();
  }, [
    calendarRef?.current?.getApi()?.currentDataManager?.state?.dateProfile
      .activeRange,
  ]);

  const handleMonthHeading = () => {
    const viewTitle =
      calendarRef?.current?.getApi()?.currentDataManager?.data?.viewTitle;
    setTitle(viewTitle);
  };

  return (
    <Grid
      container
      alignItems="flex-start"
      justifyContent="center"
      style={{ padding: mobile ? 16 : 0, marginBottom: mobile ? 0 : "20px" }}
    >
      <Grid
        container
        item
        xs={12}
        justifyContent="center"
        alignItems="flex-end"
        style={{ position: "relative", marginBottom: mobile ? 0 : "16px" }}
      >
        {mobile ? (
          <Box style={{ display: "flex" }}>
            <Typography variant="h2">{title}</Typography>
          </Box>
        ) : (
          <Box
            style={{
              position: "absolute",
              left: "4px",
              top: "8px",
              display: "flex",
            }}
          >
            <Stack justifyContent="flex-start" direction="row">
              <Typography variant="h2">{title}</Typography>
            </Stack>
            <Stack
              justifyContent="flex-start"
              direction="row"
              style={{ marginLeft: "12px" }}
            >
              <Button
                style={{
                  marginTop: "-4px",
                  color: "#363B53",
                  border: "1px solid #363B53",
                  padding: 0,
                  fontWeight: 500,
                }}
                onClick={() => {
                  calendarRef?.current?.getApi()?.today();
                  handleMonthHeading();
                  handleUnavailability();
                }}
              >
                {t("today")}
              </Button>
            </Stack>
          </Box>
        )}

        <Box
          style={{
            position: mobile ? "relative" : "absolute",
            right: 0,
            top: mobile ? "2px" : "-8px",
            width: mobile ? "100%" : "auto",
          }}
        >
          <Stack
            justifyContent={mobile ? "space-between" : "flex-end"}
            alignItems="center"
            direction="row"
          >
            <ScrollButton
              direction="prev"
              calendarRef={calendarRef}
              callback={handleUnavailability}
              homePage={true}
            />
            {mobile && (
              <Stack
                justifyContent="flex-start"
                direction="row"
                style={{ marginLeft: "12px" }}
              >
                <Button
                  style={{
                    marginTop: "-4px",
                    color: "#363B53",
                    border: "1px solid #363B53",
                    padding: 0,
                    fontWeight: 500,
                  }}
                  onClick={() => {
                    calendarRef?.current?.getApi()?.today();
                    handleMonthHeading();
                    handleUnavailability();
                  }}
                >
                  {t("today")}
                </Button>
              </Stack>
            )}

            <ScrollButton
              direction="next"
              calendarRef={calendarRef}
              callback={handleUnavailability}
              homePage={true}
            />
          </Stack>
        </Box>
        {/* )} */}
      </Grid>
    </Grid>
  );
}

function DateSelector(props) {
  const {
    calendarRef,
    handleUnavailability,
    dateObject,
    mobile,
    selectedDate,
  } = props;
  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing="5px"
      pt="2%"
    >
      {mobile ? (
        <ScrollButton
          direction="prev"
          calendarRef={calendarRef}
          callback={handleUnavailability}
        />
      ) : null}
      <Typography variant="body2" fontWeight="bold">
        {selectedDate}
      </Typography>
      <CurrentDate dateObject={dateObject} />
      <Month dateObject={dateObject} />
      <Year calendarRef={calendarRef} dateObject={dateObject} />
      {mobile ? (
        <ScrollButton
          direction="next"
          calendarRef={calendarRef}
          callback={handleUnavailability}
        />
      ) : null}
    </Stack>
  );
}

function CurrentDate({ dateObject: dateData }) {
  //need to add a method that creates number of days based on month
  const dates = [...Array(32).keys()];
  dates.shift(); //removes 0 at the beginning of array

  const handleChange = (event) => {
    dateData.setDate(event.target.value);
  };

  return (
    <Select
      id="choose-date"
      value={dateData?.date}
      defaultValue={dateData?.date}
      onChange={handleChange}
      variant="standard"
      // sx={{
      //   "& .input": {
      //     borderWidth: "0px",
      //   },
      // }}
      sx={{ fontWeight: "bold" }}
    >
      {dates.map((number, i) => (
        <MenuItem key={i} value={number}>
          {number}
        </MenuItem>
      ))}
    </Select>
  );
}

function Month({ dateObject: monthData }) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleChange = (event) => {
    monthData.setMonth(event.target.value);
    monthData.setMonthInt(
      months.findIndex((month) => month === event.target.value)
    );
  };

  return (
    <Select
      id="choose-month"
      value={monthData.month}
      label="Month"
      defaultValue={monthData.month}
      onChange={handleChange}
      variant="standard"
      sx={{ fontWeight: "bold" }}
      //sx={{"&:MuiSelect": {root: {borderBottom: "0px"}}}}
    >
      {months.map((month, i) => (
        <MenuItem key={i} value={month}>
          {month}
        </MenuItem>
      ))}
    </Select>
  );
}

function Year({ calendarRef, dateObject: yearData }) {
  const [year, setYear] = useState(parseInt(yearData?.year)); // used for tracking current year
  const [nextYear, setNextYear] = useState(parseInt(yearData?.year) + 1);
  const [selected, setSelected] = useState(parseInt(yearData?.year)); // used for tracking the selected year, defaults to current year

  useEffect(() => {
    setYear(calendarRef?.current?.getApi()?.getDate()?.getFullYear());
    setNextYear(year + 1);
    setSelected(yearData?.year);
  }, [calendarRef, year, yearData.year]);

  const handleChange = (event) => {
    yearData.setYear(event.target.value); //sets year in the main component
    setSelected(event.target.value);
  };

  return (
    <Select
      id="choose-year"
      value={selected}
      label="Month"
      defaultValue={year}
      onChange={handleChange}
      variant="standard"
      sx={{ fontWeight: "bold" }}
    >
      <MenuItem value={year}>{year}</MenuItem>
      <MenuItem value={nextYear}>{nextYear}</MenuItem>
    </Select>
  );
}
