import React, { useEffect, useState } from "react";

import { useTheme, useMediaQuery, Box, Typography } from "@mui/material";

import { GroupProps } from "../../interfaces";
import TeamContacts from "./TeamContacts";
import TeamDetails from "./TeamDetails";
import TeamBreadCrumbs from "./TeamBreadCrumbs";
import { useTranslation } from "react-i18next";

/**
 * Displays all the groups belonging to a user
 */
export default function Teams({
  groups,
  tab,
  groupsFetched,
  contacts,
  deleteGroupContact,
  palette,
  selectedGroup,
  setDeleteGroupContact,
  setSelectedGroup,
  getContacts,
  getGroups,
}: GroupProps) {
  const [editTeam, setEditTeam] = useState(false);
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("lg"));
  const { t } = useTranslation();
  /**
   * Returns to the team list view if a team is deleted by deleting all the contacts in it
   */
  useEffect(() => {
    if (groups.length < 1) {
      setEditTeam(false);
      setSelectedGroup({});
    }
  }, [groups]);

  return (
    <Box
      sx={
        desktop
          ? {
              m: "20px 0 30px 0",
              p: "20px 14px",
              backgroundColor: "rgba(150, 220, 255, 0.25)",
              height: "33vh",
              overflow: "auto",
              borderRadius: "8px",
            }
          : null
      }
    >
      {desktop ? (
        <Box display={"flex"} alignItems="center" justifyContent={"flex-start"}>
          {/* <Box ml={1}>
            <HelpComponent msg={GROUP_TEXT} position={"bottom"} />
          </Box> */}
          {editTeam ? (
            <TeamBreadCrumbs
              editTeam={editTeam}
              setEditTeam={setEditTeam}
              selectedGroup={selectedGroup}
              setSelectedGroup={setSelectedGroup}
            />
          ) : (
            <Typography variant="h3" marginLeft="14px">
              Teams
            </Typography>
          )}
        </Box>
      ) : null}

      {groupsFetched === false ? (
        //loads the group skeletons
        <>
          <TeamContacts
            tab={tab}
            groups={groups}
            groupsFetched={groupsFetched}
            getGroups={getGroups}
            selectedGroup={selectedGroup}
            setSelectedGroup={setSelectedGroup}
            palette={palette}
            setEditTeam={null}
          />
        </>
      ) : groupsFetched && groups.length > 0 ? (
        editTeam ? (
          <TeamDetails
            palette={palette}
            getGroups={getGroups}
            groups={groups}
            selectedGroup={selectedGroup}
            setSelectedGroup={setSelectedGroup}
            setEditTeam={setEditTeam}
          />
        ) : (
          <TeamContacts
            tab={tab}
            groups={groups}
            groupsFetched={groupsFetched}
            getGroups={getGroups}
            selectedGroup={selectedGroup}
            setSelectedGroup={setSelectedGroup}
            setEditTeam={setEditTeam}
            palette={palette}
          />
        )
      ) : (
        <Box display="flex" justifyContent="center" pt="25%">
          <Typography align="center">{t("no_teams")}</Typography>
        </Box>
      )}
    </Box>
  );
}
