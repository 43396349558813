import makeStyles from "@mui/styles/makeStyles";

const useStyle = makeStyles((theme) => ({
  meetingDetailsText: {
    textAlign: "center",
    marginTop: 10,
    marginBottom: 15,
  },

  pickSlot: {
    fontWeight: 500,
    marginTop: 16,
  },

  buttonContainer: {
    justifyContent: "center",
    gap: "40px",
    paddingTop: "15px",
  },

  loadingContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: 400,
  },

  topSkeleton: {
    height: 40,
    width: "100%",
  },

  topSkeletonMargin: {
    marginTop: "2rem",
    marginBottom: "2rem",
  },

  loadingContainerBottom: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  bottomSkeleton: {
    height: 60,
    width: 100,
    marginLeft: "1rem",
  },
}));

export default useStyle;
