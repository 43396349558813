import React, { useState } from "react";

import {
  TextField,
  Button,
  Chip,
  Box,
  Badge,
  useTheme,
  IconButton,
  Typography,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

import "styles/newEvent.css";
import DocPopup from "components/NewEvent/DocPopup";
import useStyles from "../../styles/formStyles";
import { useStyle } from "styles/tooltip";
import { useTranslation } from "react-i18next";
import puseStyle from "./styles";
import ToolTip from "../Global/ToolTip";
/**
 * TextField component for adding a description to a meeting when it's created
 */
function Description({
  setDescription,
  description,
  showFile,
  fileName,
  dark,
  showTootltip,

  isMobile,
  files,
  fileNames,
  setFileNames,
  setFiles,
  isQR,
}) {
  const classes = useStyles();
  const pclasses = puseStyle();
  const [docModal, setDocModal] = useState(false);
  const [invisible, setInvisible] = useState(true);
  const [error, setError] = useState("");
  const { t } = useTranslation();
  const theme = useTheme();

  const handleFile = (e) => {
    setError("");
    const maxSize = 2 * 1024 * 1024; // 2MB in bytes

    const files1 = [...e.target?.files];
    const tempF = [];
    const tempFName = [];

    if (files1?.length + files?.length > 4) {
      setError(t("You can only add 4 attachments"));
      return;
    }

    files1?.map((file) => {
      if (file?.size > maxSize) {
        setError(t("file_size"));
        return;
      }
      tempF.push(file);
      tempFName.push(file.name);
    });

    setFiles([...files, ...tempF]);
    setFileNames([...fileNames, ...tempFName]);

    setDocModal(false);
  };

  const removeFile = (fileName, i) => {
    setFileNames(fileNames?.filter((x) => x !== fileName));
    setFiles(files?.filter((x, ind) => ind !== i));
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleClose = () => {
    setDocModal(false);
    if (error) {
      setError("");
    }
  };

  return (
    <>
      <Wrapper showTootltip={showTootltip} t={t}>
        <TextField
          id="event-description"
          placeholder={t("add_desc")}
          variant="standard"
          multiline
          maxRows={3}
          style={{ marginTop: 10 }}
          value={description}
          sx={{ width: "100%" }}
          onChange={handleDescriptionChange}
          InputProps={{
            disableUnderline: true,
            style: {
              color: dark ? "#000" : theme.palette.text.disabled,
            },

            classes: { input: classes.input },
          }}
        />
      </Wrapper>
      {showFile && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          mt={isQR ? 0 : 2}
        >
          {isMobile ? (
            <Typography variant="h4" mb={2}>
              {t("attachment")}:
            </Typography>
          ) : (
            <></>
          )}
          <Box
            variant="text"
            style={{
              color: "rgb(74 43 43 / 82%)",
              display: "flex",
              flexDirection: "row",
              gap: 8,
              width: "100%",
              height: isQR ? "80px" : "",
              alignItems: "center",
              overflowX: "auto",
            }}
          >
            <Button
              variant="text"
              style={{
                display: fileName ? "none" : "flex",
                minWidth: "150px",
              }}
              className={pclasses.descBtn}
              disabled={files?.length === 4}
              onClick={() => setDocModal(true)}
            >
              {t("add_attachment")}
            </Button>

            {fileNames?.map((fileName, i) => {
              return (
                <Badge
                  color="primary"
                  badgeContent={
                    <IconButton onClick={() => removeFile(fileName, i)}>
                      <CancelIcon className={pclasses.chipColor} />
                    </IconButton>
                  }
                  invisible={invisible}
                  onMouseOver={() => setInvisible(false)}
                  onMouseOut={() => setInvisible(true)}
                  className={pclasses.file}
                >
                  <Chip
                    variant="filled"
                    label={fileName}
                    className={pclasses.fileChip}
                  />
                </Badge>
              );
            })}
          </Box>
        </Box>
      )}
      <DocPopup
        open={docModal}
        handleClose={handleClose}
        handleFile={handleFile}
        error={error}
      />
    </>
  );
}

export default React.memo(Description);

function Wrapper({ showTootltip, t, children }) {
  const tooltipStyle = useStyle();

  return showTootltip ? (
    <ToolTip title={t("BOOKING_DESC")} placement="top">
      {children}
    </ToolTip>
  ) : (
    <>{children}</>
  );
}
