import { useState } from "react";
import useStyle from "styles/formStyles";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import { Box, DialogTitle, IconButton } from "@mui/material";
import { useTheme } from "@mui/material";
import { serverPUT } from "Utils/HttpFunctions";
import loggingInDev from "loggingInDev";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

export default function UserName({
  userNameDialog,
  setUserNameDialog,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  setPictureDialog,
}) {
  const [firstErrorMessage, setFirstErrorMessage] = useState("");
  const [lastErrorMessage, setLastErrorMessage] = useState("");
  const [disabled, setDisabled] = useState(false);
  const theme = useTheme();
  const classes = useStyle();
  const { t } = useTranslation();
  const handleNextClick = () => {
    var formDataVerified = verifyFormData();
    switch (formDataVerified) {
      case true:
        postUsername();
        break;
      case false:
        break;
    }
  };

  const verifyFormData = () => {
    switch (firstName.length !== 0 && lastName.length !== 0) {
      case true:
        setFirstErrorMessage("");
        setLastErrorMessage("");
        return true;
      case false:
        if (firstName.length === 0) {
          setFirstErrorMessage(t("fname_err"));
        } else {
          setLastErrorMessage(t("lname_err"));
        }
        return false;
    }
  };

  const handleChange = (e, setName) => {
    var letters = /^[a-zA-Z ]*$/;
    if (e.target.value.match(letters)) {
      setName(e.target.value);
    }
  };

  const postUsername = async () => {
    setDisabled(true);
    const response = await serverPUT("/auth/verify-email", {
      first_name: firstName,
      last_name: lastName,
    });
    if (response && response.constructor !== String) {
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("refresh_token", response.data.refresh_token);
      localStorage.setItem("first_name", response.data.first_name);
      localStorage.setItem("last_name", response.data.last_name);
      setUserNameDialog(false);
      setPictureDialog(true);
      setDisabled(false);
      console.log("response: ", response);
    } else {
      loggingInDev(response);
      setDisabled(false);
    }
  };

  return (
    <Dialog
      open={userNameDialog}
      aria-labelledby="alert-set-username"
      sx={{ justifyContent: "center" }}
      PaperProps={{
        style: {
          borderRadius: "8px",
          padding: "17.5px 20px",
          boxSizing: "border-box",
          width: "528px",
        },
      }}
    >
      <Grid container flex justifyContent={"center"}>
        <Box display="flex" justifyContent="flex-end" style={{ width: "100%" }}>
          <IconButton
            sx={{ justifyContent: "flex-end" }}
            onClick={() => setUserNameDialog(false)}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogTitle display={"flex"} align="center" fontSize="16px">
          {t("enter_username")}
        </DialogTitle>
        <Grid display="flex" item xs={12} sx={{ pb: "10px" }}>
          <Typography
            variant="h4"
            width={"90px"}
            mr={1}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {t("first_name") + ":"}
          </Typography>
          <TextField
            variant="standard"
            autoComplete="off"
            sx={{ width: "100%" }}
            autoFocus
            InputProps={{
              disableUnderline: true,
              style: {
                fontSize: "16px",
                fontWeight: 400,
                color: "#3C4242",
                backgroundColor: theme.palette.secondary.lighter,
                borderRadius: "6px",
                paddingLeft: "8px",
              },
              classes: { input: classes.input },
            }}
            id="first-name"
            onChange={(e) => handleChange(e, setFirstName)}
            placeholder={t("enter_first_name")}
            fullWidth
            value={firstName}
            error={!!firstErrorMessage}
            helperText={firstErrorMessage}
          />
        </Grid>
        <Grid item display={"flex"} xs={12} style={{ marginTop: "12px" }}>
          <Typography
            variant="h4"
            width={"90px"}
            mr={1}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {t("last_name") + ":"}
          </Typography>
          <TextField
            id="last-name"
            placeholder={t("enter_last_name")}
            variant="standard"
            fullWidth
            value={lastName}
            autoComplete="off"
            onChange={(e) => handleChange(e, setLastName)}
            InputProps={{
              disableUnderline: true,
              style: {
                fontSize: "16px",
                fontWeight: 400,
                color: "#3C4242",
                backgroundColor: theme.palette.secondary.lighter,
                borderRadius: "6px",
                paddingLeft: "8px",
              },
              classes: { input: classes.input },
            }}
            error={!!lastErrorMessage}
            helperText={lastErrorMessage}
          />
        </Grid>
      </Grid>
      <DialogActions sx={{ justifyContent: "center", marginTop: 2 }}>
        <Button
          onClick={handleNextClick}
          variant="submit"
          disabled={disabled}
          style={{
            padding: "4px 27px",
          }}
        >
          {t("cont")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
