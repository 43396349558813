import React from "react";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import {
  Button,
  ClickAwayListener,
  Fade,
  Grow,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

export default function HelpComponent({ position, msg, fullWidth }) {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen((prev) => !prev);
  };
  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          TransitionComponent={Grow}
          TransitionProps={{ timeout: 600 }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={
            <Typography
              variant="h6"
              style={{ color: "#70757A" }}
              align="center"
            >
              {t(msg) ??
                "lorem ispum sasda kljasd asdasjkdas asdljasdshdad asdjasldhla"}
            </Typography>
          }
          placement={position ?? "right-start"}
          arrow
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: "common.white",
                color: "primary.main",
                padding: "16px 12px",
                maxWidth: fullWidth ? "100%" : "220px",
                borderRadius: "4px",
                boxShadow:
                  "0 3px 4px -2px rgba(9, 30, 66, 0.25), 0 0 1px rgba(9, 30, 66, 0.31)",
                "& .MuiTooltip-arrow": {
                  color: "common.white",
                  "&::before": {
                    boxShadow:
                      "0 3px 4px -2px rgba(9, 30, 66, 0.25), 0 0 1px rgba(9, 30, 66, 0.31)",
                  },
                },
              },
            },
          }}
        >
          <IconButton
            aria-label="tooltip"
            onClick={handleTooltipOpen}
            style={{ padding: 0, marginTop: -4 }}
          >
            <HelpOutlineOutlinedIcon
              style={{ width: "16px", height: "16px" }}
            />
          </IconButton>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
}
