import { useState, useEffect } from "react";
import { serverGET } from "Utils/HttpFunctions";
import loggingInDev from "loggingInDev";
import { GET_ALL_USER_MEETINGS_PATH } from "Utils/ApiPaths";
import {
  convertUTCMillisecondsToDate,
  checkLocation,
} from "Utils/CommonFunctions";
import { PLANNED_EVENTS_COLOR } from "Utils/Constants";

export default function UseConfirmMeetings() {
  const [plannedEvents, setPlannedEvents] = useState([]);

  useEffect(() => {
    const abortController = new AbortController();
    getPlannedEvents(abortController);
    return () => {
      abortController.abort();
    };
  }, []);

  const getPlannedEvents = async (abortController) => {
    const response = await serverGET(
      GET_ALL_USER_MEETINGS_PATH,
      abortController.signal
    );

    if (response && response.constructor !== String) {
      const formattedEvents = [...response.data];
      loggingInDev("formattedEvents ===> ", formattedEvents);
      let tempPlannedEvents = formattedEvents.map((event) => {
        return {
          id: event.id,
          title: event.title,
          start: convertUTCMillisecondsToDate(event.start_datetime),
          end: convertUTCMillisecondsToDate(event.end_datetime),
          description: event.description,
          case: event.case,
          location: checkLocation(event?.location),
          skedingParticipants: event.skeding_participants,
          nonskedingParticipants: event.non_skeding_participants,
          totalAccepted: event.total_accepted,
          nonskedingParticipantPollingStatus:
            event.non_skeding_participants_polling_status,
          totalResponded: event.total_responded,
          fileName: event.attachment_file_name,
          durationMinutes: event.duration_minutes,
          nonskedingParticipantsNotResponded:
            event?.non_skeding_participants_not_responded,
          editable: false,
          initiator: event?.initiator,
          initiatorEmail: event?.initiator_email,
          backgroundColor: PLANNED_EVENTS_COLOR,
          borderColor: PLANNED_EVENTS_COLOR,
        };
      });

      // loggingInDev("tempPlannedEvents ===> ", tempPlannedEvents);
      setPlannedEvents(tempPlannedEvents);
    }
  };

  return {
    plannedEvents,
    getPlannedEvents,
    setPlannedEvents,
  };
}
