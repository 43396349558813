import { useState } from "react";
import {
  Box,
  Button,
  FormGroup,
  FormControlLabel,
  CircularProgress,
  Typography,
  Popover,
  Checkbox,
  useTheme,
} from "@mui/material";
import useSuggestionsStore from "../../Utils/suggestionsStore";
import { CheckLabel } from "./ShowTimes";
import useStyle from "./styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DisabledByDefaultRoundedIcon from "@mui/icons-material/DisabledByDefaultRounded";
export const TimeslotPreferences = ({
  duration,
  participants,
  t,
  participantLabels,
  allUnavailableSlots,
}) => {
  const {
    preferencesCheck,
    startTimeCheck,
    durationCheck,
    setPreferecesCheck,
    setStartTimeCheck,
    setDurationCheck,
    loadData,
    blockedParticipants,
    setBlockedParticipants,
  } = useSuggestionsStore();

  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const classes = useStyle();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCheck = (e, m) => {
    var tempSelected = [...blockedParticipants];
    switch (e.target.checked) {
      case true:
        tempSelected.push({
          userId: m.user_id,
          email: m.email,
        });
        setBlockedParticipants(tempSelected);
        break;
      case false:
        var removeIndex = tempSelected.findIndex(
          (removeId) => removeId.userId === m.user_id
        );
        tempSelected.splice(removeIndex, 1);
        setBlockedParticipants(tempSelected);
        break;
    }
  };

  function countOccurrences(email) {
    let count = 0;

    allUnavailableSlots &&
      allUnavailableSlots?.forEach((obj) => {
        if (obj.unavailableParticipantsData.length > 0) {
          count += obj.unavailableParticipantsData.filter(
            (item) => item.email === email
          ).length;
        }
      });

    return count;
  }

  return (
    <Box>
      {loading ? (
        <div className={classes.timeslotPrefLoaderContainer}>
          <CircularProgress color="primary" />
        </div>
      ) : (
        <Box className={classes.timeslotPrefContainer}>
          <FormGroup className={classes.timeslotPrefContainer}>
            <Box>
              <CheckLabel
                label={t("avoid_pref")}
                checked={preferencesCheck}
                onChange={setPreferecesCheck}
                desc={t("avoid_pref_desc")}
              />
            </Box>

            <Box>
              <CheckLabel
                label={t("diff_start_time")}
                checked={startTimeCheck}
                onChange={setStartTimeCheck}
                desc={t("diff_start_time_desc")}
              />
            </Box>
            <Box>
              <CheckLabel
                label={t("reduce_duration")}
                checked={durationCheck}
                onChange={setDurationCheck}
                desc={t("reduce_duration_desc")}
              />
            </Box>

            {/* <Typography variant="h4">
                      {Object.keys(suggestedSlots).length > 0 &&
                        `${suggestedSlots?.total} slots are available`}
                    </Typography> */}
          </FormGroup>
          <Box className={classes.blockedParticipant} onClick={handleClick}>
            <Box
              style={{
                display: "block",
              }}
            >
              <Typography variant="h3" fontWeight="500">
                {t("exclude_time")}
              </Typography>
              <Typography variant="h4" fontWeight="400">
                {t("exclude_time_desc")}
              </Typography>
            </Box>
            <ExpandMoreIcon
              style={{
                cursor: "pointer",
              }}
            />
          </Box>

          <Button
            variant="contained"
            style={{
              marginTop: 16,
            }}
            onClick={async () => {
              setLoading(true);
              const data = await loadData(
                duration,
                participants,
                blockedParticipants
              );
              setLoading(false);
            }}
          >
            {t("find_slots")}
          </Button>
        </Box>
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        style={{
          borderRadius: "6px 6px 0px 0px",
          boxShadow: "inset 2px 2px 2px 2px #00000026",
        }}
        PaperProps={{
          style: { width: anchorEl ? anchorEl.clientWidth : "auto" },
        }}
      >
        <Box>
          {participantLabels.map((m) => {
            let isChecked = blockedParticipants.some(
              (b) => b.userId === m.user_id
            );

            if (!m.status) {
              return (
                <FormGroup
                  sx={{ width: "100%", marginTop: "8px", marginBottom: "8px" }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          marginLeft: 2,
                          "& .MuiSvgIcon-root": { fontSize: 20 },
                        }}
                        onChange={(e) => handleCheck(e, m)}
                        checkedIcon={
                          <DisabledByDefaultRoundedIcon
                            style={{ color: "red" }}
                          />
                        }
                        checked={isChecked}
                        disableRipple={true}
                      />
                    }
                    label={
                      <Box
                        gap={0.5}
                        display={"flex"}
                        alignItems="center"
                        sx={{ flexDirection: { xs: "column", md: "row" } }}
                      >
                        <Typography
                          variant="h4"
                          fontWeight="400"
                          style={{
                            textDecoration: isChecked ? "line-through" : "none",
                          }}
                        >
                          {`${m.email} is blocking ${countOccurrences(
                            m.email
                          )} slots for week shown above`}
                        </Typography>
                        {/* <Typography variant="h5" fontWeight="400">
                          (Blocking slots: {countOccurrences(m.email)})
                        </Typography> */}
                      </Box>
                    }
                  />
                </FormGroup>
              );
            }
          })}
        </Box>
      </Popover>
    </Box>
  );
};
