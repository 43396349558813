import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

export default function DrawerButton({ handleDrawerToggle }) {
    return (
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{ display: { md: "none" }, margin: 0, padding: 0, mr: 2 }}
      >
        <MenuIcon style={{ fontSize: "24px" }} />
      </IconButton>
    );
  }