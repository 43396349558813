import React, { useState, SetStateAction } from "react";

import {
  Button,
  Grid,
  Box,
  Typography,
  useMediaQuery,
  Theme,
} from "@mui/material";

import { GROUP_TEXT } from "../../Utils/Constants";
import { Contact, Group, NewGroupMember } from "../../interfaces";
import HelpComponent from "../../components/Global/Help";
import ContactSearchBar from "./ContactSearchBar";
import CreateContactDialog from "./CreateContactDialog";
import InviteContactsButton from "./InviteContactsButton";
import CreateTeamButton from "./CreateTeamButton";
import { useTranslation } from "react-i18next";
import useStyle from "./styles";

type ContactsNavigationProps = {
  setTab: string;
  desktop: boolean;
  groupView: boolean;
  invalidTeam: boolean;
  newGroupName: string;
  groups?: Group[];
  theme: Theme;

  getGroups: (abortController?: AbortController) => void;
  getContacts: (page?: number, abortController?: AbortController) => void;
  handleGroupView: () => void;
  setAddContact: React.Dispatch<SetStateAction<boolean>>;
  setNewGroupName: React.Dispatch<SetStateAction<string>>;
  setInvalidTeam: React.Dispatch<SetStateAction<boolean>>;
  setInviteSent: React.Dispatch<SetStateAction<boolean>>;
  setTeamCreated: React.Dispatch<SetStateAction<boolean>>;
};

/**
 * The top component of the Contacts page with buttons and search components
 */
export default function ContactsNavigation({
  groups,
  setTeamCreated,
  desktop,
  theme,
  newGroupName,
  setNewGroupName,
  getGroups,
  getContacts,
  setAddContact,
  handleGroupView,
  groupView,
  setInvalidTeam,
  invalidTeam,
  setInviteSent,
}: ContactsNavigationProps) {
  const [createContactDialog, setCreateContactDialog] = useState(false);
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();
  const classes = useStyle();
  const handleCreateContactDialog = () => {
    setCreateContactDialog(!createContactDialog);
  };

  return (
    <Grid container item xs={12} className={classes.centerBetween}>
      <Grid
        item
        container
        sx={{
          pr: desktop ? "30px" : null,
        }}
        className={classes.centerBaseline}
        xs={12}
        md={4}
      >
        <Typography align="left" variant="h2">
          {t("my_contacts")}
        </Typography>
        {desktop ? null : (
          <>
            <Button
              onClick={handleCreateContactDialog}
              className={classes.textBtn}
            >
              {t("add_new_contact")}
            </Button>
            <CreateContactDialog
              handleCreateContactDialog={handleCreateContactDialog}
              createContactDialog={createContactDialog}
              setAddContact={setAddContact}
              theme={theme}
              getContacts={getContacts}
              mobile={mobile}
            />
          </>
        )}
      </Grid>
      {desktop ? (
        <>
          <Grid
            container
            item
            xs={12}
            md={8}
            alignItems={"center"}
            justifyContent={"flex-end"}
            columnGap={"12px"}
          >
            <InviteContactsButton setInviteSent={setInviteSent} />
            <CreateTeamButton
              theme={theme}
              newGroupName={newGroupName}
              setNewGroupName={setNewGroupName}
              getGroups={getGroups}
              getContacts={getContacts}
              groups={groups}
              setInvalidTeam={setInvalidTeam}
              setTeamCreated={setTeamCreated}
              mobile={mobile}
            />
          </Grid>
          <Box
            display="flex"
            justifyContent="flex-start"
            width="100%"
            pt="20px"
          >
            <ContactSearchBar invalidTeam={invalidTeam} />
          </Box>
        </>
      ) : groupView ? (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
          width="100%"
          // style={{ marginLeft: "5%" }}
        >
          <Box display={"flex"} alignItems="center" justifyContent={"center"}>
            <Typography fontSize="16px" fontWeight="500">
              {t("teams")}
            </Typography>
            <Box ml={1}>
              <HelpComponent
                msg={GROUP_TEXT}
                position={"bottom"}
                fullWidth={undefined}
              />
            </Box>
          </Box>

          <Button onClick={handleGroupView} className={classes.textBtn}>
            {t("view_contacts")}
          </Button>
        </Box>
      ) : (
        <>
          <CreateTeamButton
            theme={theme}
            newGroupName={newGroupName}
            setNewGroupName={setNewGroupName}
            getGroups={getGroups}
            getContacts={getContacts}
            groups={groups}
            mobile={mobile}
            setInvalidTeam={setInvalidTeam}
            setTeamCreated={setTeamCreated}
          />

          <Button onClick={handleGroupView} className={classes.textBtn}>
            {t("view_teams")}
          </Button>
        </>
      )}
    </Grid>
  );
}
