import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  AvatarGroup,
  Avatar,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import moment from "moment";
import { PASTEL_COLORS, VIBRANT_COLORS } from "Utils/Constants";
import { useContext } from "react";
import { accountContext } from "context/user";
import { stringToColor } from "Utils/CommonFunctions";
import { useTranslation } from "react-i18next";
import Counter from "components/NewEvent/Counter";
import { handleIsLock } from "../../Utils/CommonFunctions";

export default function ResponsiveWrapper({
  children,
  meetingDetailExpand,
  setMeetingDetailExpand,
  title,
  location,
  timeInterval,
  participants,
  description,
  customTime,
  closeDetail,
}) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const { user } = useContext(accountContext);
  const { t } = useTranslation();

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(
          name,
          user?.event_theme === 0 ? PASTEL_COLORS : VIBRANT_COLORS
        ),
        // textTransform: "capitalize",
      },
      children: `${name[0].toUpperCase()}${name[1].toUpperCase()}`,
    };
  }

  return (
    <Accordion
      elevation={0}
      expanded={meetingDetailExpand}
      disableGutters
      onChange={() => setMeetingDetailExpand((prev) => !prev)}
      style={{ background: "none", width: "100%" }}
      sx={{
        boxSizing: "border-box",
        border: "1px solid #E0E2E7",
        padding: mobile ? "12px" : "20px",
        marginBottom: "16px",
        borderRadius: "12px",
        "&:before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary
        style={{
          padding: 0,
          display: "flex",
          justifyContent: "flex-start",
          // marginBottom: 14,
        }}
        expandIcon={
          meetingDetailExpand ? (
            <Typography
              color="primary"
              variant="h3"
              style={{ transform: "rotate(180deg)" }}
            >
              <KeyboardArrowUpIcon style={{ color: "#B3B5B6" }} />
            </Typography>
          ) : (
            <Typography
              color="primary"
              variant="h3"
              display={"flex"}
              alignItems={"center"}
            >
              {t("edit")}
              <KeyboardArrowDownIcon style={{ color: "#B3B5B6" }} />
            </Typography>
          )
        }
        aria-controls="integration-content"
        id="integration-header"
      >
        {meetingDetailExpand ? (
          <Typography
            align="left"
            variant="h3"
            style={{ width: "fit-content" }}
          >
            {t("meeting_details")}
          </Typography>
        ) : (
          <Box
            display="flex"
            justifyContent={"space-between"}
            style={{ width: mobile ? "100%" : "85%", rowGap: "8px" }}
            flexWrap="wrap"
          >
            <Box style={{ width: mobile ? "50%" : "auto" }}>
              <Typography
                align="left"
                variant="h4"
                style={{
                  width: "fit-content",
                  fontWeight: 500,
                  color: "#70757A",
                }}
              >
                {t("title")}
              </Typography>
              <Typography
                align="left"
                variant="h3"
                style={{
                  // overflow: "hidden",
                  // textOverflow: "ellipses",
                  // width: mobile ? "100px" : "fit-content",
                  // // width: "fit-content",
                  // fontWeight: 400,
                  maxWidth: mobile ? "100px" : "160px",
                  height: "20px",
                  overflow: "hidden",
                  textOverflow: "ellipses",
                  fontWeight: 400,
                }}
              >
                {title ? title : t("none")}
              </Typography>
            </Box>
            <Box style={{ width: mobile ? "50%" : "auto" }}>
              <Typography
                align="left"
                variant="h4"
                style={{
                  width: "fit-content",
                  fontWeight: 500,
                  color: "#70757A",
                }}
              >
                {t("duration")}
              </Typography>
              <Typography
                align="left"
                variant="h3"
                style={{
                  width: "fit-content",
                  fontWeight: 400,
                  backgroundColor: theme.palette.primary.chipBg,
                  padding: "2px 4px",
                  borderRadius: 4,
                }}
              >
                {timeInterval === "custom"
                  ? moment.duration(customTime).asMinutes()
                  : moment.duration(timeInterval).asMinutes()}{" "}
                {t("mins")}
              </Typography>
            </Box>

            <Box style={{ width: mobile ? "50%" : "auto" }}>
              <Typography
                align="left"
                variant="h4"
                style={{
                  width: "fit-content",
                  fontWeight: 500,
                  color: "#70757A",
                }}
              >
                {t("participants")}
              </Typography>
              <Typography
                align="left"
                variant="h3"
                style={{ width: "fit-content", fontWeight: 400 }}
              >
                <AvatarGroup
                  max={4}
                  // style={{
                  //   width: 14,
                  //   height: 14,
                  //   fontSize: 12,
                  // }}
                  sx={{
                    "& .MuiAvatar-root-MuiAvatarGroup-avatar": {
                      bgcolor: "green",
                    },
                  }}
                >
                  {participants.map((p) => (
                    <Avatar
                      key={p}
                      {...stringAvatar(p)}
                      sx={{
                        // width: 24,
                        // height: 24,
                        // fontSize: 10,
                        bgcolor: stringToColor(
                          p,
                          user?.event_theme === 0
                            ? PASTEL_COLORS
                            : VIBRANT_COLORS
                        ),
                      }}
                    />
                  ))}
                </AvatarGroup>
              </Typography>
            </Box>

            <Box style={{ width: mobile ? "50%" : "auto" }}>
              <Typography
                align="left"
                variant="h4"
                style={{
                  width: "fit-content",
                  fontWeight: 500,
                  color: "#70757A",
                }}
              >
                {t("location")}
              </Typography>
              <Typography
                align="left"
                variant="h3"
                style={{ width: "fit-content", fontWeight: 400 }}
              >
                {location}
              </Typography>
            </Box>

            {mobile ? null : (
              <Box>
                <Typography
                  align="left"
                  variant="h4"
                  style={{
                    // width: "fit-content",
                    fontWeight: 500,
                    color: "#70757A",
                  }}
                >
                  {t("desc")}
                </Typography>
                <Typography
                  align="left"
                  variant="h3"
                  style={{
                    maxWidth: "160px",
                    height: "20px",
                    overflow: "hidden",
                    textOverflow: "ellipses",
                    fontWeight: 400,
                  }}
                >
                  {description ? description : t("none")}
                </Typography>
              </Box>
            )}
          </Box>
        )}

        {/* {isWebsite ? "website appointment details" : "Meeting Details"} */}
      </AccordionSummary>
      <AccordionDetails style={{ padding: 0 }}>
        {
          <>
            {handleIsLock(user) && (
              <Counter handleClose={() => closeDetail(false)} />
            )}
            {children}
          </>
        }
      </AccordionDetails>
    </Accordion>
  );
}
