import { useState } from "react";

import { useNavigate } from "react-router-dom";
import {
  Grid,
  Dialog,
  Typography,
  Box,
  Button,
  IconButton,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ListIcon from "@mui/icons-material/List";
import CloseIcon from "@mui/icons-material/Close";

import {
  MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS,
  ONE_SKEDING_MULTIPLE_NONSKEDING_USERS,
  RECURRING_EVENT,
} from "Utils/Constants";
import ShowTimes from "components/NewEvent/ShowTimes";
import MeetingDetailsComponent from "components/NewEvent/MeetingDetailsComponent";
import TimeCalendar from "components/NewEvent/TimeCalendar";
import SnackbarComponent from "components/Global/SnackbarComponent";
import MultiNonSignUp from "pages/MultiNonSignUp";
import AllSkedingMeetingChip from "./AllSkedingMeetingChip";
import DialogBox from "components/DialogBox";
import RejectComment from "components/RejectComment";
import AcceptComment from "components/AcceptComment";
import { useTranslation } from "react-i18next";
import { SINGLE_SLOT_MEETING } from "../../Utils/Constants";

/**
 * Component that renders the times selected by the initiator for a meeting
 * when a nonskeding user signs up
 */
export default function UserAgreesToSignup({
  theme,
  meetingData,
  setSelectedSlot,
  selectedSlot,
  errorAmount,
  setErrorAmount,
  events,
  handleSubmit,
  handleReject,
  disabled,
  handleDownload,
  calView,
  setCalView,
  calendarRef,
  sharedAvailableTimes,
  setSharedAvailableTimes,
  successModal,
  handleSuccessClose,
  open,
  modal,
  handleClose: handleModal,
}) {
  const navigate = useNavigate();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const [rejectConfirm, setRejectConfirm] = useState(false);
  const [acceptConfirm, setAcceptConfirm] = useState(false);
  const { t } = useTranslation();

  const handleRejectConfirm = () => {
    setRejectConfirm((prev) => !prev);
  };

  const handleAcceptConfirm = () => {
    setAcceptConfirm((prev) => !prev);
  };

  const handleClose = () => {
    navigate("/dashboard");
  };

  if (modal) {
    return (
      <DialogBox
        modal={modal}
        handleClose={handleModal}
        msg={t("already_responded")}
        close={false}
        nonsignup={true}
      />
    );
  }

  return meetingData === null ? null : meetingData.case ===
    MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS ||
    meetingData.case === ONE_SKEDING_MULTIPLE_NONSKEDING_USERS ? (
    <MultiNonSignUp meetingData={meetingData} events={events} />
  ) : (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          padding: mobile ? "16px" : "28px",
          boxSizing: "border-box",
          position: mobile ? "absolute" : "relative",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          margin: 0,
          width: "100%",
          maxHeight: "100%",
          borderRadius: mobile ? 0 : 8,
        },
      }}
    >
      <SnackbarComponent
        open={successModal}
        handleClose={handleSuccessClose}
        message={"You response is recorded successfully"}
        vertical={"bottom"}
        horizontal={"center"}
      />
      <Box display="flex" justifyContent="flex-end">
        <IconButton sx={{ justifyContent: "flex-end" }} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Typography
        variant="h2"
        align="center"
        style={{ marginTop: 10, marginBottom: 15 }}
      >
        {t("meeting_invite")}
      </Typography>
      <Grid container>
        <Grid item xs={12} md={events?.length > 0 ? 5 : 6}>
          <MeetingDetailsComponent
            theme={theme}
            meetingData={meetingData}
            handleDownload={handleDownload}
          />
          {meetingData?.case === RECURRING_EVENT ||
            (meetingData?.case === SINGLE_SLOT_MEETING && (
              <Box
                mt={2}
                display="flex"
                flexDirection={"column"}
                alignItems="flex-start"
              >
                <Typography
                  variant="h2"
                  style={{ marginBottom: 16 }}
                  align="left"
                  fontWeight={500}
                >
                  {t("chosen_time")}
                </Typography>
                <AllSkedingMeetingChip meetingData={meetingData} />
              </Box>
            ))}
        </Grid>
        <Grid item xs={12} md={events?.length > 0 ? 7 : 6}>
          {events?.length > 0 ? (
            <>
              <Box style={{ textAlign: "left", marginTop: mobile ? 5 : 0 }}>
                <Button
                  variant="text"
                  style={{ fontSize: 14 }}
                  startIcon={calView ? <ListIcon /> : <CalendarMonthIcon />}
                  onClick={() => setCalView((prev) => !prev)}
                >
                  {calView ? t("list_view") : t("cal_view")}
                </Button>
              </Box>
              <Box
                style={{
                  display: calView ? "block" : "none",
                  height: "400px",
                  overflow: "hidden",
                }}
              >
                <TimeCalendar
                  available={
                    events?.length > 0
                      ? events
                      : meetingData?.proposed_timeslots
                  }
                  events={events}
                  eventCase={
                    events?.length > 0 ? "NonSignUp" : "NonSignUpOneSelect"
                  }
                  selectedSlot={selectedSlot}
                  setSelectedSlot={setSelectedSlot}
                  errorAmount={errorAmount}
                  setErrorAmount={setErrorAmount}
                  calendarRef={calendarRef}
                  participants={["user"]}
                  timeInterval="not empty"
                  sharedAvailableTimes={sharedAvailableTimes}
                  setSharedAvailableTimes={setSharedAvailableTimes}
                  unavailability={[]}
                />
              </Box>
            </>
          ) : null}

          <Box style={{ display: calView ? "none" : "block" }}>
            {events?.length > 0 ? (
              <>
                <Typography
                  variant="h2"
                  style={{ marginTop: 16 }}
                  align="left"
                  fontWeight={500}
                >
                  {t("new_slots_msg")}
                </Typography>
                <Typography
                  variant="h4"
                  align="left"
                  sx={{
                    fontWeight: 400,
                    color: theme.palette.info.light,
                    marginTop: "5px !important",
                  }}
                >
                  {t("no_slots_avail")}
                </Typography>
                <ShowTimes
                  setSelectedSlot={setSelectedSlot}
                  selectedSlot={selectedSlot}
                  events={
                    events?.length > 0
                      ? events
                      : meetingData?.proposed_timeslots
                  }
                  errorAmount={errorAmount}
                  setErrorAmount={setErrorAmount}
                  sharedAvailableTimes={sharedAvailableTimes}
                  setSharedAvailableTimes={setSharedAvailableTimes}
                  eventCase="NonSignUp"
                />
              </>
            ) : (
              <>
                {meetingData?.proposed_timeslots?.length === 1 ? (
                  <Box
                    mt={2}
                    display="flex"
                    flexDirection={"column"}
                    alignItems="flex-start"
                  >
                    <Typography
                      variant="h2"
                      style={{ marginBottom: 4 }}
                      align="left"
                      fontWeight={500}
                    >
                      {t("chosen_time")}
                    </Typography>
                    <AllSkedingMeetingChip
                      meetingData={{
                        start_datetime: meetingData?.proposed_timeslots[0]
                          ?.startTime
                          ? meetingData?.proposed_timeslots[0]?.startTime
                          : meetingData?.proposed_timeslots[0]?.start,

                        end_datetime: meetingData?.proposed_timeslots[0]
                          ?.endTime
                          ? meetingData?.proposed_timeslots[0]?.endTime
                          : meetingData?.proposed_timeslots[0]?.end,
                      }}
                      hideTitle={true}
                    />
                  </Box>
                ) : meetingData?.proposed_timeslots?.length > 0 ? (
                  <>
                    <Typography
                      variant="h2"
                      fontWeight={500}
                      style={{ marginTop: 16 }}
                    >
                      {t("pick_slot")}
                    </Typography>

                    <ShowTimes
                      setSelectedSlot={setSelectedSlot}
                      selectedSlot={selectedSlot}
                      events={meetingData?.proposed_timeslots}
                      errorAmount={errorAmount}
                      setErrorAmount={setErrorAmount}
                      eventCase="NonSignUpOneSelect"
                      sharedAvailableTimes={sharedAvailableTimes}
                      setSharedAvailableTimes={setSharedAvailableTimes}
                    />
                  </>
                ) : null}
              </>
            )}
          </Box>
        </Grid>
      </Grid>
      <Box
        display="flex"
        justifyContent={"space-around"}
        alignSelf="center"
        mt={4}
      >
        <Button onClick={handleRejectConfirm} variant="outlined">
          {t("reject")}
        </Button>
        <Box mr={"30px"} />
        <Button
          // onClick={handleSubmit}
          onClick={handleAcceptConfirm}
          variant="contained"
          disabled={
            meetingData?.case === RECURRING_EVENT ||
              meetingData?.case === SINGLE_SLOT_MEETING
              ? false
              : selectedSlot.length === 0 || disabled
          }
        >
          {disabled ? (
            <CircularProgress style={{ color: "white" }} size={20} />
          ) : (
            t("accept")
          )}
        </Button>
      </Box>
      <RejectComment
        rejectConfirm={rejectConfirm}
        handleRejectConfirm={handleRejectConfirm}
        handleReject={handleReject}
        loading={disabled}

      />
      <AcceptComment
        acceptConfirm={acceptConfirm}
        handleAcceptConfirm={handleAcceptConfirm}
        handleAccept={handleSubmit}
        loading={disabled}
      />
    </Dialog>
  );
}
