import React, { useRef, useState, useEffect, useContext } from "react";
import { useLocation, useSearchParams, useNavigate } from "react-router-dom";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import {
  Typography,
  Grid,
  Box,
  useTheme,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import { motion } from "framer-motion";
import frLocale from "@fullcalendar/core/locales/fr";
import DialogWithCloseIcon from "components/DialogWithCloseIcon";
import FreeTrialDialog from "components/payment/FreeTrialDialog";
import "styles/picker.css";
import {
  convertDateToUTCMilliseconds,
  convertUTCMillisecondsToDate,
  dayHeader,
  removeOffsetFromDate,
} from "Utils/CommonFunctions";
import { serverPUT } from "Utils/HttpFunctions";
import { authContext } from "context/Auth";
import { themeContext } from "context/ThemeColorProvider";
import { RECURRING_EVENT } from "Utils/Constants";
import { accountContext } from "context/user";
import loggingInDev from "loggingInDev";
import MeetingDetails from "components/Dashboard/MeetingDetails";
import SignupDialogs from "components/signup/SignupDialogs";
import ScrollButton from "components/ScrollButton";
import Todos from "components/Dashboard/Todos";
import Style from "style-it";
import Upcoming from "components/Dashboard/Upcoming";
import moment from "moment";
import MobileTodos from "components/Dashboard/MobileTodos";
import Feedback from "components/Feedback/Feedback";
import CalendarHeader from "components/Dashboard/CalendarHeader";
import CalendarViewButtons from "components/Dashboard/CalendarViewButtons";
import SnackbarComponent from "components/Global/SnackbarComponent";
import { useTranslation } from "react-i18next";
import DesktopFlow from "../../components/NewEvent/DesktopFlow";
import useEventsStore from "../../Utils/eventStore";
import useMeetingStore from "../../Utils/createMeetingStore";
import useStyle from "./styles";
import Dropdown from "../../components/Global/Dropdown";
import Filters from "../../components/Dashboard/Filters";
import { GoogleSwitch, OutlookSwich, SwitchDialogue } from "../Integrations";
import { integrationContext } from "context/Integrations";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
/**
 * The Calendar rendered on a user's dashboard on the "My Calendar" page
 */

export default function Calendar() {
  const {
    plannedEvents,
    setPlannedEvents,
    draftEvents,
    calendarTasks,
    setCalendarTasks,
    getPlannedEvents,
    getTodos,
    getDraftEvents,
    loadingPlannedEvents,
    optionsSelected,
    setOptionsSelected,
    filteredData,
  } = useEventsStore();
  const { isLogged } = useContext(authContext);
  const { mode } = useContext(themeContext);
  const { user, getUser } = useContext(accountContext);
  const { meetingModal } = useMeetingStore();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const calendarRef = useRef(null);
  const location = useLocation();
  const [freeTrialDialog, setFreeTrialDialog] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(true);
  const [title, setTitle] = useState([]);
  const [todayDate, setTodayDate] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);
  const [confirmPopup, setConfirmPopup] = useState(false);
  const [emailVerifed, setEmailVerified] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [cancelAll, setCancelAll] = useState("0");
  const navigate = useNavigate();
  const [clickedEvent, setClickedEvent] = useState({
    start: {},
    participants: [],
  });
  const [selectedBtn, setSelectedBtn] = useState(2);
  const [searchParams] = useSearchParams();
  const [userSignup, setUserSignup] = useState(() =>
    searchParams.get("userSignup")
  );
  const [calendarSignup, setCalendarSignup] = useState(() =>
    searchParams.get("isCalendar")
  );
  const [modalDate, setModalDate] = useState(null);
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const { user: integrateUser } = useContext(integrationContext);
  const [switchModal, setSwitchModal] = useState(false);
  const currentLanguage = i18n.language;
  const classes = useStyle();
  const confirmMeetingScheduled = searchParams.get("scheduledMeeting");
  const [expanded, setExpanded] = useState(false);

  /**
   * Determines if user is logging in for the first time and should be shown the welcome flow
   */
  useEffect(() => {
    if (searchParams.get("userSignup")) {
      setUserSignup(searchParams.get("userSignup"));
    }
    if (searchParams.get("isCalendar")) {
      setCalendarSignup(searchParams.get("isCalendar"));
    }
  }, [searchParams, calendarSignup]);

  /**
   * Sets the title for the calendar on the first render in the format <Month>, <Year>
   * Blocks the user from returning to the a previous unauthorized page after they have authenticated
   * Fetches all of a user's scheduled events from the backend
   */
  useEffect(() => {
    getTitle();

    window.history.pushState(null, null, location.href);
    window.addEventListener("popstate", function () {
      window.history.go(1);
    });

    const abortController = new AbortController();
    getPlannedEvents(abortController);
    getTodos(abortController);
    getDraftEvents();

    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    const hasSeenPopup = localStorage.getItem("hasSeenTrialPopup");

    if (
      !hasSeenPopup &&
      !user?.free_plan &&
      (user?.package?.payment_status === "unpaid" || user?.package?.paid === 0)
    ) {
      setFreeTrialDialog(true);
      localStorage.setItem("hasSeenTrialPopup", "true");
    }
  }, []);

  useEffect(() => {
    // Function to run when the component becomes focused
    const handleFocus = () => {
      if (user) {
        let currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const updateTimezone = async () => {
          const response = await serverPUT(`/account`, {
            time_zone: currentTimezone,
          });
          if (response && response.constructor !== String) {
            console.log("added");
            getUser();
          } else {
            console.log("error");
          }
        };
        if (user.timezone === currentTimezone) {
          console.log("same");
        } else {
          // update in db
          updateTimezone();
          console.log("different");
        }
      }
      // Add your logic here
    };

    // Attach the 'focus' event listeners to the window or a specific element
    window.addEventListener("focus", handleFocus);

    // Clean up the event listeners when the component unmounts
    return () => {
      window.removeEventListener("focus", handleFocus);
    };
  }, [user]);

  /**
   * Sets the title for the calendar whenever the calendar view changes in the format <Month>, <Year>
   */
  useEffect(() => {
    getTitle();
  }, [calendarRef, mobile]);

  /**
   * Sets the calendar view to default to a monthly view if the user is on mobile
   * Sets the calendar view to default to a weekly view if the user is on desktop
   */
  useEffect(() => {
    if (mobile) {
      calendarRef.current.getApi().changeView("dayGridMonth");
    } else {
      calendarRef.current.getApi().changeView("timeGridWeek");
    }
  }, [mobile]);

  /**
   * Changes the calendar view to day if a user clicks a day on the calendar in month view
   * @param {object} dateClickInfo - an object with the date parameters from the FullCalendar API
   */
  const handleDateClick = (dateClickInfo) => {
    setClickedEvent({
      start: {},
      participants: [],
    });
    const currentDateTime = new Date();
    // setMeetingModal(true);
    if (dateClickInfo.date < currentDateTime) {
      return;
    }
    setModalDate(dateClickInfo.date);
    // const queryParams = { startDate: dateClickInfo.date };
    // navigate(`/dashboard?${new URLSearchParams(queryParams)}`);

    //changes to day view in case of week view or month
    // calendarRef.current.getApi().changeView("timeGridDay", dateClickInfo.date);
    // setSelectedBtn(1);
  };

  /**
   * Sets the selected date when the user clicks on a date on the calendar in mobile view
   * @param {MouseEvent} e - event object that contains the date clicked
   */
  const handleMobileDateClick = (e) => {
    setSelectedDate(e.dateStr);
  };

  /**
   * Opens a dialogue box with the info for the clicked event from the calendar
   * @param {MouseEvent} eventClickInfo
   */
  const handleEventClick = async (eventClickInfo) => {
    var eventId = eventClickInfo.event._def.publicId;
    var isDraft =
      eventClickInfo.event._def?.extendedProps.eventType === "draft";
    setIsOpen(true);
    let event = await plannedEvents.find((event) => event.id === eventId);

    if (isDraft) {
      let draft = await draftEvents.find((event) => event.id === eventId);
      setClickedEvent({ event: draft, source: "draft" });
      setModalDate(draft.start);
    } else if (event) {
      setClickedEvent({ event: event, source: "event" });
    } else {
      let todo = await calendarTasks.find((todo) => {
        return todo.id === eventId;
      });
      console.log("🚀 ~ handleEventClick ~ todo:", todo);
      setClickedEvent({ event: todo, source: "todo" });
    }
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const onConfirmClose = () => {
    setConfirmPopup(false);
  };

  const handleCancelClick = async () => {
    setIsOpen(false);
    setConfirmPopup(true);
  };

  const handleSwitchModal = () => {
    setSwitchModal((prev) => !prev);
  };

  // const handleCofirmCancel = () => {
  //   setEmailVerified(false);
  //   navigate("/dashboard");
  // };

  /**
   * Makes a request to the backend to confirm a meeting
   * @param {string} comment - an optional reason that a user may add for why they are cancelling a meeting
   * @async
   */
  const handleConfirmClick = async (comment) => {
    //cancel all recurring meetings
    let response;
    setDisabled(true);
    const temp = [...plannedEvents];

    if (cancelAll == "1" && clickedEvent?.event?.case === RECURRING_EVENT) {
      response = await serverPUT(
        `/meeting/recurring/${clickedEvent?.event?.id}`,
        {
          to_cancel: true,
          cancel_reason: !!comment.trim() ? comment : undefined,
        }
      );
      if (response && response.constructor !== String) {
        if (response.data && response.code === 200) {
          const meeting = temp.filter(
            (event) => event.recurringId === clickedEvent?.event?.recurringId
          );
          if (meeting.length > 0) {
            meeting.map((m) => {
              const index = temp.indexOf(m);
              if (index !== -1) {
                temp.splice(index, 1);
              }
            });
            setPlannedEvents(temp);
            onClose();
            onConfirmClose();
            setDisabled(false);
          }
        }
      }
    } else {
      response = await serverPUT(`/meeting/${clickedEvent?.event?.id}`, {
        to_cancel: true,
        cancel_reason: !!comment.trim() ? comment : undefined,
      });
      if (response && response.constructor !== String) {
        if (response.data && response.code === 200) {
          const meeting = temp.find(
            (event) => event.id === clickedEvent?.event?.id
          );
          const index = temp.indexOf(meeting);
          temp.splice(index, 1);
          setPlannedEvents(temp);
          onClose();
          onConfirmClose();
          setDisabled(false);
        }
      }
    }
  };

  /**
   * Gets the title from the current month and year of the calnedar using the reference to the FullCalendar component
   */
  const getTitle = () => {
    if (mobile) {
      setTitle(
        calendarRef.current?.getApi()?.getDate().toLocaleDateString("default", {
          month: "long",
          day: "numeric",
          year: "numeric",
        })
      );
    } else {
      setTitle(
        calendarRef.current?.getApi()?.getDate().toLocaleDateString("default", {
          month: "long",
          day: "numeric",
          year: "numeric",
        })
      );
    }
  };

  /**
   * Updates the attributes of a todo event when it's dragged onto the calendar
   * sets the start and end time
   * @param {string} id - the id of the task to be updated
   * @param {object} body - contains the start and end time of the task on the calendar
   * @param {boolean} isFirst - indicates if the task is the first in the list of todo items
   * @async
   */
  const updateTodoStatus = async (id, body, isFirst) => {
    if (typeof body.start_datetime == "string") {
      return;
    }
    if (isFirst) {
      const allevents = plannedEvents.slice().reverse();
      const found = await allevents.find(
        (e) =>
          body.start_datetime >= e?.start?.getTime() &&
          body.start_datetime <= e?.end?.getTime()
      );
      if (found) {
        return;
      }
    }
    const response = await serverPUT(`/todo/${id}`, body);

    var temp = [...calendarTasks];

    if (response && response.constructor !== String) {
      const item = temp.find((e) => e.id == response.data._id);
      const index = temp.indexOf(item);
      if (index !== -1) {
        temp[index].start = convertUTCMillisecondsToDate(
          response.data.start_datetime
        );
        temp[index].end = convertUTCMillisecondsToDate(
          response.data.end_datetime
        );
      }
      setCalendarTasks(temp);
      loggingInDev("updated todo");
    } else {
      loggingInDev("error in updating todo");
    }
  };

  /**
   * Removes a todo from the user's list and calendar without marking it as done
   * @async
   */
  const cancelTodo = async () => {
    setDisabled(true);
    const temp = [...calendarTasks];
    //temp logic changed
    // const response = await serverDELETE(`/todo/${clickedEvent?.event?.id}`);
    // if (response && response.constructor !== String) {
    // if (response.code === 200) {
    const todo = temp.find((td) => td.id === clickedEvent?.event?.id);
    const index = temp.indexOf(todo);
    if (index !== -1) {
      // temp.splice(index, 1);
      temp[index].start = undefined;
      temp[index].end = undefined;
    }
    setCalendarTasks(temp);
    onClose();
    onConfirmClose();
    // }
    // }
    setDisabled(false);
  };

  /**
   * Changes the status of a todo event when it is marked as done from it's dialogue box
   * @param {object} event - the event object for the todo task on the calendar
   */
  const markTodo = async (event) => {
    const body = {
      is_done: true,
    };
    const response = await serverPUT(`/todo/${event.id}`, body);
    if (response && response.constructor !== String) {
      const temp = [...calendarTasks];

      loggingInDev("updated todo");
      const todo = temp.find((td) => td.id === event.id);
      const index = temp.indexOf(todo);
      if (index !== -1) {
        temp.splice(index, 1);
      }

      setCalendarTasks(temp);
    } else {
      loggingInDev("error in updating todo");
    }
    setIsOpen(false);
  };

  const handleFreeTrialDialogClose = () => {
    setFreeTrialDialog(false);
  };

  const googleConnect = () => {
    window.open(process.env.REACT_APP_GOOGLE_INTEGRATION, "_self");
  };

  const outlookConnect = () => {
    window.open(process.env.REACT_APP_OUTLOOK_INTEGRATION, "_self");
  };
  // console.log("hfdf", clickedEvent);
  return (
    isLogged && (
      <Style>
        {`
        #calendar {
          max-width: 900px;
          margin: 0 auto;
          cursor: pointer;

        }
        .fc .fc-toolbar-title {
          font-size: 20px;
          font-weight: 500;
          color: ${mode === "light" ? "#363B53" : "#ffffff"};
          margin: 0;
          
        }
        
        .fc .fc-toolbar.fc-header-toolbar {
          margin-bottom: 1em;
          margin-top: 1em;
          
        }
        
        thead {
          border-width: 0;
        }
        
        .fc .fc-daygrid-day.fc-day-today {
          background-color: inherit;
        }        
        
        .fc .fc-timegrid-col.fc-day-today {
          background-color: inherit;
        }

        .fc-day-today {
          color: #fff ;
          border-radius:4px;
          background-color: #479AE9;
        }
        
        .fc-daygrid-event {
          position: relative;
          white-space: nowrap;
          border-radius: 3px;
          font-size: 0.85em;
          font-size: var(--fc-small-font-size, 0.85em);
        }
        
        .fc-timegrid-event,
        .fc-timegrid-more-link {
          font-size: 0.85em;
          font-size: var(--fc-small-font-size, 0.85em);
          border-radius: 3px;
          background-color: transparent !important
        }

        .fc-timegrid-event {
          border-radius: 6px,
          border: 0px
        }

        .fc-timegrid-event:hover {
          cursor: pointer
        }
        
        .fc .fc-button-primary {
          color: inherit;
          background-color: #F7F7F9;
          border-color: #F7F7F9;
        }
        
        .fc .fc-button-primary:hover {
          background-color: #F7F7F9;
          color: #2c3e50;
          border: 0;
        }
        
        .fc .fc-button-primary:not(:disabled):active,
        .fc .fc-button-primary:not(:disabled).fc-button-active {
          color: inherit;
          background-color: #F7F7F9;
        }
        
        thead {
          border-width: 0;
          font-size: 14px;
        }
        
        table {
          border-color: #F7F7F9;
        }
        
        .fc-theme-standard .fc-scrollgrid {
          border: 0px solid var(--fc-border-color, #ddd);
        }

        .fc-col-header-cell-cushion {
          font-size: ${mobile ? "14px !important" : "16px !important"};
          font-weight: ${mobile ? "400 !important" : "500 !important"};
          // color: ${mode === "light" ? "#363B53" : "#ffffff"};
          padding-bottom:${mobile ? "14px" : ""}
        }
        
        thead .fc-scroller {
          overflow: hidden !important;
        }
        
        .fc-col-header-cell .fc-day {
          border-width: 0;
        }
        
        .fc-theme-standard td,
        .fc-theme-standard th {
          border: 0px solid var(--fc-border-color, #ddd);
        }
        //when change line height also of times
        // .fc-theme-standard td {
        //   height: ${mobile ? "auto" : "50px !important"}; 
        // }
        
        .fc .fc-daygrid-day-top {
          display: flex;
          flex-direction: row-reverse;
          justify-content: center;
        }
        
        .fc .fc-daygrid-body {
          position: relative;
          z-index: 1;
          border: ${
            mobile ? "none" : "1px solid var(--fc-border-color, #ddd)"
          }; 
        }
        
        .fc .fc-daygrid-day-frame {
          position: relative;
          min-height: 100%;
          border: ${
            mobile ? "none" : "1px solid var(--fc-border-color, #ddd)"
          }; 
        }
        
        .fc .fc-daygrid-day-number {
          font-size: ${mobile ? "16px" : "18px"};
          font-weight: ${mobile ? "400" : "600"}
          color: ${mode === "light" ? "#363B53" : "#ffffff"};
          cursor: pointer
        }
        
        .fc .fc-timegrid-col-frame {
          border: 1px solid var(--fc-border-color, #ddd);
          border-left: 0px;
        }
        
        .fc .fc-timegrid-slot {
          border-bottom: 1px solid var(--fc-border-color, #ddd);
        }
        
        .fc .fc-timegrid-slot-label {
          border-bottom: 0px !important;
          border-top: 0px !important;
          font-size: 11px;
          font-weight: 500;
          color: ${mode === "light" ? "#363B53" : "#ffffff"};
          vertical-align:top;
          line-height: 50px;
        }
        
        .fc-timegrid-col-frame .fc-timegrid-now-indicator-container {
          border-top: 0px !important;
        }
        
        .fc .fc-bg-event {
          opacity: 1;
        }
        .fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-number {
          font-size: ${mobile ? "16px" : "18px"};
          font-weight: ${mobile ? "400" : "600"};
          border-radius: 50% !important;
          background-color: #479AE9;
          min-width:28px;
          min-height:28px;
          display:flex;
          justify-content:center;
          align-items:center;
        }
        .fc-event-title{
          font-size: ${mobile ? "9px" : "inherit"}; 
        }
       
 
      `}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="home"
          style={{
            marginTop: mobile ? 0 : "-30px",
            display: mobile ? "block" : "flex",
          }}
        >
          <Box className={classes.mainContainer}>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: mobile ? "end" : "space-between",
              }}
            >
              {!mobile && (
                <IconButton
                  disableRipple
                  onClick={() => setExpanded((prev) => !prev)}
                  style={{ border: "1px solid #479AE9", borderRadius: "8px" }}
                >
                  <Typography
                    align="left"
                    variant="h3"
                    style={{ width: "fit-content" }}
                  >
                    {"Filters"}
                  </Typography>
                  <Typography
                    color="primary"
                    variant="h3"
                    style={{
                      transform: !expanded ? "rotate(180deg)" : "rotate(0deg)",
                      transition: "all 0.2s",
                    }}
                  >
                    <KeyboardArrowUpIcon style={{ color: "#B3B5B6" }} />
                  </Typography>
                </IconButton>
              )}

              <Box>
                <GoogleSwitch
                  user={integrateUser}
                  handleSwitchModal={handleSwitchModal}
                />
                <OutlookSwich
                  user={integrateUser}
                  handleSwitchModal={handleSwitchModal}
                />
              </Box>
            </Box>
            {expanded && <Box>{!mobile && <Filters />}</Box>}
            <Grid container sx={{ alignItems: "baseline" }}>
              {confirmMeetingScheduled ? (
                <SnackbarComponent
                  open={openSuccessDialog}
                  handleClose={() => setOpenSuccessDialog(false)}
                  message={t("snackbar_messages.5")}
                  vertical={"bottom"}
                  horizontal={"center"}
                />
              ) : null}
              {userSignup ? (
                <SignupDialogs isCalendar={calendarSignup} />
              ) : null}

              <Grid
                container
                item
                xs={12}
                md={8}
                sx={{
                  justifyContent: { xs: "center", md: "flex-start" },
                  alignItems: "baseline",
                }}
              >
                {mobile ? (
                  <Box className={classes.calendarMobileTitle}>
                    <ScrollButton
                      direction="prev"
                      calendarRef={calendarRef}
                      callback={getTitle}
                      homePage={true}
                    />
                    <Typography
                      color={theme.palette.primary.main}
                      variant="h2"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        calendarRef?.current?.getApi()?.gotoDate(new Date())
                      }
                    >
                      {title}
                    </Typography>
                    <ScrollButton
                      direction="next"
                      calendarRef={calendarRef}
                      callback={getTitle}
                      homePage={true}
                    />
                  </Box>
                ) : (
                  <Box
                    className={classes.calendarTitle}
                    onClick={() => {
                      calendarRef?.current?.getApi()?.gotoDate(todayDate);
                      getTitle();
                    }}
                  >
                    <Typography color={theme.palette.primary.main} variant="h2">
                      {t("today")}&nbsp;
                    </Typography>
                    <Typography color={theme.palette.primary.main} variant="h2">
                      {todayDate.toLocaleString(
                        currentLanguage === "en" ? "default" : "fr-FR",
                        { month: "long" }
                      )}{" "}
                      {todayDate.getDate()} {todayDate.getFullYear()}
                    </Typography>
                  </Box>
                )}
              </Grid>
              <Box sx={{ display: { xs: "block", md: "none" }, mt: 5 }} />
              {!mobile && (
                <Grid
                  container
                  item
                  xs={12}
                  md={4}
                  className={classes.calendarButtons}
                >
                  <CalendarViewButtons
                    calendarRef={calendarRef}
                    setSelectedBtn={setSelectedBtn}
                    selectedBtn={selectedBtn}
                  />
                </Grid>
              )}
            </Grid>
            <MeetingDetails
              isOpen={isOpen}
              onClose={onClose}
              source={clickedEvent?.source}
              event={clickedEvent?.event}
              handleCancelClick={handleCancelClick}
              confirmPopup={confirmPopup}
              onConfirmClose={onConfirmClose}
              handleConfirmClick={handleConfirmClick}
              disabled={disabled}
              cancelTodo={cancelTodo}
              cancelAll={cancelAll}
              setCancelAll={setCancelAll}
              markTodo={markTodo}
            />
            <Box
              component="div"
              className={classes.calendarHeader}
              sx={{
                display: meetingModal ? "none" : "block",
              }}
            >
              <CalendarHeader
                calendarRef={calendarRef}
                mobile={mobile}
                user={user}
                callback={getTitle}
                title={title}
              />
              <FullCalendar
                firstDay={1}
                plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
                // locales={[en, fr]}
                locale={currentLanguage}
                initialView={"timeGridWeek"}
                scrollTime={"9:00:00"}
                themeSystem="bootstrap5"
                ref={calendarRef}
                height={mobile ? "270px" : "90%"}
                allDaySlot={true}
                nowIndicator={true}
                headerToolbar={false}
                eventSources={
                  mobile
                    ? []
                    : optionsSelected.duration.length > 0 ||
                      optionsSelected.date ||
                      optionsSelected.endDate ||
                      optionsSelected.title ||
                      optionsSelected.case.length > 0 ||
                      optionsSelected.participants.length > 0
                    ? [filteredData, calendarTasks, draftEvents]
                    : [plannedEvents, calendarTasks, draftEvents]
                }
                eventColor="#ABE0FB"
                eventContent={renderEventContent}
                eventTextColor="#3C4242"
                eventClick={handleEventClick}
                eventDisplay="block"
                eventOrderStrict={true}
                slotEventOverlap={false}
                eventOverlap={false}
                displayEventTime={false}
                slotLabelInterval={"01:00"}
                views={{
                  timeGridWeek: {
                    dayHeaderContent: (date) => dayHeader(date, "week"),
                  },
                  timeGridDay: {
                    dayHeaderContent: (date) => dayHeader(date, "day"),
                  },
                }}
                titleFormat={{
                  month: "long",
                  year: "numeric",
                }}
                slotDuration={"01:00:00"}
                snapDuration={"00:15:00"}
                expandRows={true}
                slotLabelFormat={{
                  hour: "numeric",
                  hour12: true,
                }}
                slotMinTime={"00:00:00"}
                slotMaxTime={"24:00:00"}
                dateClick={mobile ? handleMobileDateClick : handleDateClick}
                eventDurationEditable={false}
                eventStartEditable={true}
                editable={true}
                droppable={true}
                drop={function (info) {
                  updateTodoStatus(
                    info.draggedEl.getAttribute("data"),
                    {
                      start_datetime: info.draggedEl.getAttribute("start")
                        ? info.draggedEl.getAttribute("start")
                        : convertDateToUTCMilliseconds(info.date),
                      end_datetime: info.draggedEl.getAttribute("end")
                        ? info.draggedEl.getAttribute("end")
                        : convertDateToUTCMilliseconds(
                            moment(info.date)
                              .add(
                                parseInt(
                                  info.draggedEl.getAttribute("duration")
                                ),
                                "minutes"
                              )
                              .toDate()
                          ),
                    },
                    true
                  );
                  // info?.draggedEl?.parentNode?.removeChild(info.draggedEl);
                }}
                eventReceive={function (info) {
                  // updateTodoStatus(
                  //   info,
                  //   {
                  //     start_datetime: convertDateToUTCMilliseconds(
                  //       removeOffsetFromDate(info.event._instance.range.start)
                  //     ),
                  //     end_datetime: convertDateToUTCMilliseconds(
                  //       removeOffsetFromDate(info.event._instance.range.end)
                  //     ),
                  //     // is_done: true,
                  //   },
                  //   true
                  // );
                }}
                eventDrop={function (eventDropInfo) {
                  updateTodoStatus(
                    eventDropInfo.event._def.publicId,
                    {
                      start_datetime: convertDateToUTCMilliseconds(
                        removeOffsetFromDate(
                          eventDropInfo.event._instance.range.start
                        )
                      ),
                      end_datetime: convertDateToUTCMilliseconds(
                        removeOffsetFromDate(
                          eventDropInfo.event._instance.range.end
                        )
                      ),
                      // is_done: true,
                    },
                    false
                  );
                }}
              />
            </Box>
          </Box>

          <Box className={classes.calendarUpcoming}>
            <Upcoming
              plannedEvents={plannedEvents}
              loadingPlannedEvents={loadingPlannedEvents}
              clickedEvent={clickedEvent}
              setClickedEvent={setClickedEvent}
              setIsOpen={setIsOpen}
              selectedDate={selectedDate}
            />
            <Feedback />
            {mobile ? <MobileTodos /> : <Todos />}
          </Box>
          {!user?.package?.paid && (
            <DialogWithCloseIcon
              open={freeTrialDialog}
              handleClose={handleFreeTrialDialogClose}
              dialogSize={"sm"}
            >
              <FreeTrialDialog
                dialogTitle={t("free_trial_expired")}
                dialogDesc={t("free_trial_expired_desc")}
                buttonText={t("get_premium")}
                onClick={() => navigate("/payment-services")}
              />
            </DialogWithCloseIcon>
          )}

          <SwitchDialogue
            switchModal={switchModal}
            handleClose={handleSwitchModal}
            googleConnect={googleConnect}
            outlookConnect={outlookConnect}
            t={t}
          />

          {/* not setting globally becasue for that case need to send data via query params */}
          {modalDate && (
            <DesktopFlow
              modalDate={modalDate}
              setModalDate={setModalDate}
              clickedEvent={clickedEvent}
            />
          )}
        </motion.div>
      </Style>
    )
  );
}

function renderEventContent(eventInfo) {
  const { bgColor, meetingColor } = eventInfo?.event?.extendedProps;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        background: `linear-gradient(to right, ${meetingColor} 5%, ${bgColor} 0)`,
        border: `1px solid ${bgColor}`,
        padding: "2px",
      }}
    >
      <span
        style={{
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        }}
      >
        {eventInfo.event.title}
      </span>
    </div>
  );
}
