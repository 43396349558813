import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import HttpApi from "i18next-http-backend";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// Sentry.init({
//   dsn: "https://74b30deafa144f42b5baeb43c8fa32b1@o1418445.ingest.sentry.io/6761866",
//   integrations: [new BrowserTracing()],
//   environment: process.env.REACT_APP_SENTRY,
//   debug: true,
//   tracesSampleRate: 1.0,
//   // logLevel: 3,
// });

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ["en", "fr", "es", "de", "it", "nl"],
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    detection: {
      order: ["localStorage", "cookie", "htmlTag", "path", "subdomain"],
      caches: ["localStorage", "cookie"],
    },
    backend: {
      loadPath: "/assets/locales/{{lng}}/translation.json",
    },
    react: { useSuspense: false },
  });

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
