import React from "react";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import {
  IconButton,
  SnackbarContent,
  Typography,
  useTheme,
} from "@mui/material";

export default function ErrorSnackbar({
  vertical,
  horizontal,
  handleClose,
  open,
  message,
  duration,
}) {
  const theme = useTheme();
  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        message={message}
        key={vertical + horizontal}
        autoHideDuration={duration ? duration : 4000}
        sx={{
          "& .MuiSnackbarContent-root	": {
            boxShadow: "none",
          },
        }}
      >
        <SnackbarContent
          style={{
            backgroundColor: "#ed4337",
            maxWidth: "600px",
          }}
          message={
            <Typography
              variant="h3"
              id="client-snackbar"
              style={{
                color: theme.palette.common.white,
                whiteSpace: "pre-wrap",
                paddingRight: 8,
                paddingLeft: 8,
              }}
              align="center"
              noWrap
            >
              {message}
            </Typography>
          }
          action={
            <IconButton aria-label="clear" onClick={handleClose}>
              <CloseIcon style={{ width: 16, height: 16 }} />
            </IconButton>
          }
          sx={{
            "& .MuiSnackbarContent-action": {
              position: "absolute",
              right: "10px",
            },
            "& .MuiSnackbarContent-message": {
              flexGrow: 1,
              paddingRight: "8px",
            },
          }}
        />
      </Snackbar>
    </div>
  );
}
