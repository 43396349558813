import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  Button,
  CircularProgress,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import Comment from "./meetings/Comment";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

export default function AcceptComment({
  acceptConfirm,
  handleAcceptConfirm,
  handleAccept,
  isInitiator,
  loading,
}) {
  const [comment, setComment] = useState("");
  const { t } = useTranslation();

  return (
    <Dialog
      open={acceptConfirm}
      onClose={handleAcceptConfirm}
      sx={{ justifyContent: "center" }}
      maxWidth="xs"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "8px",
          padding: "24px",
          boxSizing: "border-box",
        },
      }}
    >
      <Box display="flex" justifyContent="flex-end">
        <IconButton onClick={handleAcceptConfirm}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box>
        <Typography variant="h2" align="center" sx={{ marginBottom: "8px" }}>
          {t("add_notes")}
        </Typography>
        <Typography variant="h3">{t("notes")}:</Typography>
      </Box>

      <Comment
        comment={comment}
        setComment={setComment}
        placeholder={t("notes_desc")}
      />

      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          variant="instant"
          onClick={() => handleAccept(comment)}
          style={{ marginLeft: 10, fontSize: "14px", fontWeight: "500" }}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress style={{ color: "white" }} size={20} />
          ) : (
            t("proceed")
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
