import { useState } from "react";

import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
  CircularProgress,
  useTheme,
} from "@mui/material";

import { serverDELETE } from "Utils/HttpFunctions";
import loggingInDev from "loggingInDev";
import SnackbarComponent from "components/Global/SnackbarComponent";
import { useTranslation } from "react-i18next";

export default function DeleteConfirmation({
  deleteConfirm,
  setDeleteConfirm,
  refreshFunction,
  type,
  selected,
  setSelected,
}) {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [deleteUnsuccessful, setDeleteUnsuccessful] = useState(false);
  const [error, setError] = useState("");
  const theme = useTheme();
  const { t } = useTranslation();

  const handleClose = () => {
    setDeleteConfirm(false);
  };

  const handleDelete = () => {
    setDisabled(true);
    deleteContactRequest();
    setDeleteConfirm(false);
  };

  /**
   * Makes a request to the backend to delete a contact from a team,
   * delete an entire team or delete a contact from my contacts list
   */
  const deleteContactRequest = async () => {
    let data;
    let response;

    if (type === "group") {
      data = {
        groupId: selected._id,
      };
      response = await serverDELETE("/contact/group/delete", data);
    } else if (type === "contact-group") {
      data = {
        contactId: selected._id,
        groupId: selected.groupId,
      };
      response = await serverDELETE("/contact/groupcontact", data);
    } else if (type === "contact") {
      data = {
        contacts: selected,
      };
      response = await serverDELETE("/contact", data);
    }

    if (response && response.constructor !== String) {
      loggingInDev("accepted response =>", response);
      setDeleteUnsuccessful(false);
      setError("");
      refreshFunction();
    } else {
      loggingInDev("error =>", response);
      setDeleteUnsuccessful(true);
      setError(response);
    }

    setOpenSnackbar(true);
    setDisabled(false);
    if (setSelected) {
      setSelected({});
    }
  };

  return (
    <>
      <Dialog
        open={deleteConfirm}
        onClose={handleClose}
        aria-labelledby="alert-confirm-delete"
        aria-describedby="alert-delete-contact-permanently"
        sx={{ justifyContent: "center" }}
        PaperProps={{ sx: { padding: 3, boxSizing: "border-box" } }}
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
          {type === "group" ? (
            <Typography variant="h3">
              Are you sure you want to delete this team?
            </Typography>
          ) : type === "contact-group" ? (
            <Typography variant="h3">
              Are you sure you want to delete this contact from this group?
            </Typography>
          ) : (
            <>
              <Typography variant="h2" style={{ paddingBottom: "8px" }}>
                Delete Contact
              </Typography>
              <Typography variant="h3" fontWeight={400}>
                Are you sure you want to delete this contact permanently?
              </Typography>
            </>
          )}
        </DialogTitle>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            onClick={handleClose}
            variant="outlined"
            style={{
              marginRight: "20px",
            }}
          >
            Cancel
          </Button>
          {/* <span style={{ width: "40px" }} /> */}
          <Button onClick={handleDelete} variant="submit" disabled={disabled}>
            {disabled ? (
              <CircularProgress style={{ color: "white" }} size={20} />
            ) : (
              "Delete"
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <SnackbarComponent
        open={openSnackbar}
        handleClose={() => setOpenSnackbar(false)}
        message={
          deleteUnsuccessful
            ? error
            : type === "group"
            ? t("snackbar_messages.0")
            : t("snackbar_messages.1")
        }
        deleteUnsuccessful={deleteUnsuccessful}
        vertical={"bottom"}
        horizontal={"center"}
      />
    </>
  );
}
