import makeStyles from "@mui/styles/makeStyles";
const useStyle = makeStyles((theme) => ({
  main: {
    minHeight: "100vh",
    overflow: "hidden",
    background: theme.palette.secondary.main,
    [theme.breakpoints.up("md")]: {
      background: "linear-gradient(130.26deg, #479AE9 43.2%, #97EEDE 106.18%)",
    },
  },
  welcomeMsg: {
    fontSize: "32px",
    fontWeight: "500",
  },

  mobileWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    rowGap: "15px",
    padding: "2% 0 2% 0",
  },
  desktopWrapper: {
    background: "#FEFEFE",
    display: "flex",
    flexDirection: "column",
    width: "45%",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    rowGap: "15px",
    position: "relative",
  },
  bubbleWrapper: {
    position: "absolute",
    overflow: "hidden",
    maxHeight: "105vh",
    right: "-120px",
  },
}));

export default useStyle;
