import React from "react";
import {
  Badge,
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

export function CreateEmailTemplate({
  pclasses,
  subject,
  setSubject,
  handleFormData,
  body,
  setBody,
  signature,
  setSignature,
  handleFile,
  fileNames,
  removeFile,
  files,
  invisible,
  setInvisible,
}) {
  return (
    <Box>
      <Typography variant="h3" pb={0}>
        Content
      </Typography>
      <Box className={pclasses.preview}>
        <TextField
          placeholder={"Enter Subject"}
          variant="standard"
          value={subject}
          sx={{
            width: "100%",
          }}
          InputProps={{
            disableUnderline: true,
            classes: { input: ` ${pclasses.createInput}` },
          }}
          onChange={(e) => handleFormData(e, setSubject)}
        />

        <textarea
          name="body"
          id="body"
          rows={10}
          className={`${pclasses.textarea} ${pclasses.createInput}`}
          placeholder="Enter body"
          value={body}
          onChange={(e) => handleFormData(e, setBody)}
        />

        <TextField
          placeholder={"Enter signature text"}
          variant="standard"
          value={signature}
          sx={{
            width: "100%",
          }}
          InputProps={{
            disableUnderline: true,
            classes: { input: ` ${pclasses.createInput}` },
          }}
          onChange={(e) => handleFormData(e, setSignature)}
        />
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 8,
            width: "100%",
            alignItems: "center",
          }}
        >
          <Button
            variant="text"
            style={{
              display: "flex",
              minWidth: "150px",
            }}
            className={pclasses.descBtn}
            component="label"
          >
            <input
              type="file"
              hidden
              accept=".png, .jpg, .jpeg"
              onChange={handleFile}
            />
            Add Signature Image
          </Button>
          {fileNames?.map((fileName, i) => {
            return (
              <Badge
                color="primary"
                badgeContent={
                  <IconButton onClick={() => removeFile(fileName, i)}>
                    <CancelIcon className={pclasses.chipColor} />
                  </IconButton>
                }
                invisible={invisible}
                onMouseOver={() => setInvisible(false)}
                onMouseOut={() => setInvisible(true)}
                className={pclasses.file}
              >
                <img
                  src={files[i]}
                  alt={fileName}
                  className={pclasses.fileChip}
                />
                {/* <Chip
                                variant="filled"
                                label={fileName}
                                className={pclasses.fileChip}
                              /> */}
              </Badge>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}
