import moment from "moment";
import { create } from "zustand";
import { serverGET } from "./HttpFunctions";
//meeting store to have organizer meetings
const useMeetingStore = create((set) => ({
  meetingData: null,
  meetingModal: false,
  organizerMeetings: [],
  agendas: [],
  setAgendas: (agenda) => set({ agendas: agenda }),
  setMeetingData: (data) => set({ meetingData: data }),
  setMeetingModal: (modalState) => set({ meetingModal: modalState }),

  /**
   *
   * @param {Array<{startTime, endTime, duration_minutes}>} meetings
   */
  setOrganizerMeetings: (meetings = []) => {
    // let groups;
    // for (let i = 0; i < meetings.length; i++) {
    let groups = meetings.reduce((groups, meeting) => {
      let date = moment(meeting.startTime).format("DD-MM-YYYY");
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(meeting);
      return groups;
    }, {});
    // }
    //setting groups in state - {key: date, value: array of meeting}
    set({ organizerMeetings: groups });
  },

  remainingMeetings: 0,
  isFetched: false,
  getUserRemainingMeetings: async () => {
    const response = await serverGET(`/account/countmeetings`);
    if (response && response.constructor !== String) {
      set({ remainingMeetings: response.data, isFetched: true });
    } else {
      set({ remainingMeetings: 0, isFetched: true });
    }
  },

  refetchData: async () => {
    set({ isFetched: false }); // Reset the fetched flag
    // await useMeetingStore.getState().fetchRemainingMeetings(); // Trigger the fetch
  },
}));

export default useMeetingStore;
