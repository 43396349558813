import React from "react";
import ChooseService from "../ChooseService";
import useStyle from "../styles";
import useWebsiteStore from "../../../Utils/websiteStore";
import { useContext } from "react";
import { accountContext } from "../../../context/user";

export default function ServiceOptions({ client }) {
  const classes = useStyle();
  const services = useWebsiteStore((store) => store.services);
  const details = useWebsiteStore((store) => store.details);
  const { user } = useContext(accountContext);
  return (
    <div className={classes.servicePreview}>
      <ChooseService
        services={services}
        title={details.title}
        description={details.description}
        email={"websitelocation@xyz.com"}
        meeting_room=""
        category={details.category}
        phone_number={"+123 34567890"}
        location={"xyz"}
        skeding_user_id={{
          first_name: user?.name.split(" ")[0],
          last_name: user?.name.split(" ")[1],
          picture: details.picture,
        }}
        client={false}
      />
    </div>
  );
}
