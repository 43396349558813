import { useState, useRef, useEffect } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
  Box,
  Avatar,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useTranslation } from "react-i18next";
import useStyle from "./styles";

/**
 * Dialog box to change a user's profile picture
 */
export default function ChangeProfilePicture({
  handleImageClose,
  handleImage,
  existingImage,
  imageOpen,
  uploadError,
}) {
  const [image, setImage] = useState("");
  const imageRef = useRef(null);
  const max_file_size = 2 * 1024 * 1024;
  const { t } = useTranslation();
  const handleInput = (e) => {
    setImage(e.target.files[0]);
  };
  const classes = useStyle();

  /**
   * Loads the image when the user uploads a new image
   */
  useEffect(() => {
    if (image && parseInt(image?.size) < max_file_size) {
      const reader = new FileReader();
      const { current } = imageRef;
      current.file = image;
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        current.src = e.target.result;
      };
    }
  }, [image]);

  const saveImage = () => {
    handleImageClose();
    handleImage(image);
    setImage("");
  };

  return (
    <Dialog
      open={imageOpen}
      onClose={() => {
        handleImageClose();
        setImage("");
      }}
      aria-labelledby="cancel-image-alert"
      aria-describedby="box-to-cancel-image"
      maxWidth="xs"
      fullWidth={true}
      PaperProps={{
        sx: { padding: 3, boxSizing: "border-box", borderRadius: "16px" },
      }}
    >
      <DialogContent>
        <>
          <Typography align="center" variant="h2" fontWeight={500}>
            {t("edit_pic")}
          </Typography>
          {image?.size > max_file_size ? (
            <Typography
              align="center"
              variant="h4"
              style={{ color: "red", fontStyle: "italic" }}
              mt={1}
              fontWeight={400}
            >
              Please upload an image below 2 mb
            </Typography>
          ) : (
            <></>
          )}

          <Typography align="center" color="red" pb="24px">
            {uploadError}
          </Typography>
          <Box display="flex" justifyContent="center">
            {existingImage ? (
              <img src={existingImage} className={classes.img} ref={imageRef} />
            ) : image !== "" ? (
              <img ref={imageRef} className={classes.img} />
            ) : (
              <Avatar className={classes.img} />
            )}
          </Box>
        </>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }} spacing="90">
        <Stack direction="column" justifyContent="center">
          <Button
            variant="contained"
            component="label"
            startIcon={<CloudUploadIcon />}
          >
            {t("upload_title")}
            <input
              type="file"
              accept="image/*"
              hidden
              name="image"
              onChange={handleInput}
            />
          </Button>
          <Box display="flex" justifyContent="center" pt="16px">
            <Button
              variant="outlined"
              onClick={() => {
                handleImageClose();
                setImage("");
              }}
            >
              {t("cancel")}
            </Button>
            {image && parseInt(image?.size) < max_file_size ? (
              <Button
                variant="contained"
                onClick={saveImage}
                style={{ marginLeft: 12 }}
              >
                {t("save")}
              </Button>
            ) : (
              <></>
            )}
          </Box>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
