import makeStyles from "@mui/styles/makeStyles";

const useStyle = makeStyles((theme) => ({
  topContainer: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    overflow: "auto",
    height: "80vh",
  },

  genericContainer: {
    flexDirection: "column",
    alignItems: "flex-start",
  },

  boxContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },

  showTimes: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginTop: "8px",
  },

  availability: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },

  workingHrDesc: {
    textAlign: "left",
    fontWeight: 400,
    color: theme.palette.info.light,
  },

  timeContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: "16px",
    marginBottom: "15px",
    gap: 8,
  },

  time: {
    marginRight: "10px",
    fontWeight: "400",
    textAlign: "left",
  },

  timePicker: {
    position: "relative",
    fontSize: "14px",
    fontWeight: "400",
  },

  buttonContainer: {
    width: "100%",

    [theme.breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "center",
      margin: "32px 0",
      width: "auto",
    },
  },

  cancelButton: {
    marginRight: "20px",
    width: "107px",
    padding: "2px 0",
  },

  lockedBox: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1,
  },
  copyContent: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    width: "95%",
    margin: "auto",
  },

  copyInput: {
    borderWidth: "0px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "8px",
    paddingRight: "8px",
    width: "fit-content",
    borderRadius: "8px",
    backgroundColor: "white",
  },
  link: {
    textOverflow: "ellipsis",
    color: "#70757A",
    fontWeight: "500",
    overflow: "hidden",
  },
  copyDialog: {
    border: "1px solid #479AE9",
    borderRadius: "8px",
    padding: "5px",
    columnGap: "5px",
    width: "fit-content",
    display: "flex",
  },
}));

export default useStyle;
