import React, {
  SetStateAction,
  useState,
  useEffect,
  useContext,
  useRef,
  useMemo,
} from "react";
import "styles/styles.module.css";

import {
  Typography,
  Box,
  useTheme,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import moment from "moment";
import { useTranslation, WithTranslation } from "react-i18next";
import { accountContext } from "../../context/user";
import { dashboardMeetingsLoader } from "../meetingsLoader";
import { sortByProperty } from "../../Utils/CommonFunctions";
import {
  MEETING_INVITES_COLOR,
  PENDING_MEETINGS_COLOR,
  WEBSITE_BOOKINGS_COLOR,
} from "../../Utils/Constants";
import { PlannedEvent } from "../../interfaces";
import Pastel from "../EventTheme/Pastel";
import Vibrant from "../EventTheme/Vibrant";
import UpcomingEvent from "./UpcomingEvent";
import ColorIndicator from "../meetings/ColorIndicator";
import useStyle from "./styles";

type UpcomingProps = {
  plannedEvents: PlannedEvent[];
  loadingPlannedEvents: boolean;
  selectedDate: string;
  myMeetings?: boolean;
  pendingMeetings?: any[];
  setClickedEvent: React.Dispatch<SetStateAction<object>>;
  setIsOpen: React.Dispatch<SetStateAction<boolean>>;
};

/**
 * Renders the list of upcoming events on the My Calendar page
 */
export default function Upcoming({
  setClickedEvent,
  setIsOpen,
  plannedEvents,
  loadingPlannedEvents,
  pendingMeetings,
  selectedDate,
  myMeetings,
}: UpcomingProps) {
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [themeModal, setThemeModal] = useState(false);
  const { user } = useContext(accountContext) as any;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const ref: any = useRef(null);
  const { t } = useTranslation();
  const classes = useStyle();
  /**
   * Scrolls a selected upcoming event into view when it's selected from the calendar
   */
  useEffect(() => {
    if (selectedDate && ref) {
      ref.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [selectedDate]);

  useEffect(() => {
    getEvents();
  }, [plannedEvents]);

  /**
   * Fetches the user's upcoming events starting after the current time
   * @async
   */
  const getEvents = async () => {
    if (plannedEvents) {
      let today = new Date();

      let filtered: any = await plannedEvents.filter(
        (event) => event?.start > today.getTime()
      );
      filtered = filtered.sort(sortByProperty("start"));
      setUpcomingEvents(filtered);
    }
  };

  /**
   * Sorts the upcoming events from present to future
   */
  const sortUpcomingEvents = () => {
    const temp: any = [...upcomingEvents];
    temp.sort((a, b) => {
      return a?.start.getTime() - b?.start.getTime();
    });
    return temp;
  };

  const onEventClick = async (event) => {
    setClickedEvent({ event: event, source: "event" });
    setIsOpen(true);
  };

  /**
   * Creates a card for each upcoming event with dates separating events with different start times
   */
  const upcomingEventsWithDateTitles = useMemo(() => {
    const sortedUpcomingEvents = sortUpcomingEvents();

    const events = sortedUpcomingEvents.map((event, index) => {
      const dateTitle = mobile
        ? moment(sortedUpcomingEvents[index].start).format("ddd, D")
        : moment(sortedUpcomingEvents[index].start).format("ddd, MMM D");
      if (index > 0) {
        if (
          event.start.getDate() !==
          sortedUpcomingEvents[index - 1].start.getDate()
        ) {
          return (
            <Box
              key={index}
              width="100%"
              display="flex"
              flexDirection={mobile ? "row" : "column"}
              style={{ boxSizing: "border-box" }}
              pl="4px"
              ref={
                new Date(selectedDate).toISOString().substring(0, 10) ==
                moment(event.start).format("YYYY-MM-DD")
                  ? ref
                  : null
              }
            >
              {mobile ? (
                <Box
                  display="flex"
                  justifyContent={"center"}
                  alignItems="center"
                  flexDirection={"column"}
                  style={{
                    background: index === 0 ? "#72B0EE" : "transparent",
                  }}
                  className={classes.mobileUpcoming}
                >
                  <Typography
                    variant="h4"
                    fontWeight={400}
                    sx={{
                      color:
                        index === 0
                          ? theme.palette.common.white
                          : theme.palette.common.black,
                    }}
                  >
                    {dateTitle?.split(",")[0]}
                  </Typography>
                  <Typography
                    variant="h3"
                    sx={{
                      color:
                        index === 0
                          ? theme.palette.common.white
                          : theme.palette.common.black,
                    }}
                  >
                    {dateTitle?.split(",")[1]}
                  </Typography>
                </Box>
              ) : (
                <Typography
                  variant="body2"
                  color="#3C4242"
                  sx={{ opacity: "0.5" }}
                >
                  {dateTitle}
                </Typography>
              )}
              <UpcomingEvent
                event={event}
                key={index}
                onEventClick={onEventClick}
                mobile={mobile}
              />
            </Box>
          );
        } else {
          return (
            <Box
              key={index}
              width="100%"
              display="flex"
              flexDirection={mobile ? "row" : "column"}
              style={{ boxSizing: "border-box" }}
              pl="4px"
            >
              <Box className={classes.upcomingEvent} />
              <UpcomingEvent
                event={event}
                onEventClick={onEventClick}
                mobile={mobile}
              />
            </Box>
          );
        }
      } else {
        return (
          <Box
            key={index}
            width="100%"
            display="flex"
            flexDirection={mobile ? "row" : "column"}
            pt="10px"
            style={{ boxSizing: "border-box" }}
            pl="4px"
          >
            {mobile ? (
              <Box
                display="flex"
                justifyContent={"center"}
                alignItems="center"
                flexDirection={"column"}
                style={{
                  background: index === 0 ? "#72B0EE" : "transparent",
                }}
                className={classes.eventContainer}
              >
                <Typography
                  variant="body1"
                  sx={{
                    color:
                      index === 0
                        ? theme.palette.common.white
                        : theme.palette.common.black,
                    fontWeight: "400",
                  }}
                >
                  {dateTitle?.split(",")[0]}
                </Typography>
                <Typography
                  variant="h3"
                  sx={{
                    color:
                      index === 0
                        ? theme.palette.common.white
                        : theme.palette.common.black,
                  }}
                  style={{
                    lineHeight: "1",
                  }}
                >
                  {dateTitle?.split(",")[1]}
                </Typography>
              </Box>
            ) : (
              <Typography
                variant="body2"
                color="#3C4242"
                sx={{ opacity: "0.5" }}
              >
                {dateTitle}
              </Typography>
            )}

            <UpcomingEvent
              event={event}
              key={index}
              onEventClick={onEventClick}
              mobile={mobile}
            />
          </Box>
        );
      }
    });
    return events;
  }, [upcomingEvents, mobile, selectedDate]);

  return (
    <Box style={{ flexGrow: 1, overflow: "auto", order: mobile ? 2 : 1 }}>
      {pendingMeetings ? null : (
        <>
          {mobile ? (
            <Box display="flex" flexWrap="wrap">
              <Accordion
                elevation={0}
                expanded={themeModal}
                disableGutters
                onChange={() => setThemeModal((prev) => !prev)}
                className={classes.accordion}
              >
                <AccordionSummary
                  style={{ padding: 0, marginBottom: "-16px" }}
                  expandIcon={
                    <Typography
                      color="primary"
                      variant="h3"
                      style={{
                        transform: themeModal
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                      }}
                    >
                      {t("theme")}
                    </Typography>
                  }
                  aria-controls="integration-content"
                  id="integration-header"
                >
                  <Typography
                    align="left"
                    variant="h2"
                    style={{ width: "fit-content" }}
                  >
                    {myMeetings ? t("pending_events") : t("upcoming_events")}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetail}>
                  {myMeetings ? (
                    <Box display="flex" flexWrap="wrap" mt={2} mb={-2}>
                      <ColorIndicator
                        text={t("pending_meetings")}
                        color={PENDING_MEETINGS_COLOR}
                        border={null}
                      />
                      <ColorIndicator
                        text={t("invite_meetings")}
                        color={MEETING_INVITES_COLOR}
                        border={null}
                      />
                      <ColorIndicator
                        text={t("website_bookings")}
                        color={WEBSITE_BOOKINGS_COLOR}
                        border={null}
                      />
                      {/* <ColorIndicator
                          text={"Confirmed meetings"}
                          color={PLANNED_EVENTS_COLOR}
                        /> */}
                      {/* BR-1 */}
                      {/* <ColorIndicator text={"Events"} color={"#E5F0F8"} /> */}
                      {/* <ColorIndicator text={"Tasks"} color={"#CAE5DF"} /> */}
                    </Box>
                  ) : user && user.event_theme === 0 ? (
                    <Pastel />
                  ) : (
                    <Vibrant />
                  )}
                </AccordionDetails>
              </Accordion>
            </Box>
          ) : (
            <Typography variant="h3" align="center">
              {myMeetings ? t("pending_events") : t("upcoming_events")}
            </Typography>
          )}
        </>
      )}

      <Box
        display="flex"
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
        flexDirection={"column"}
        className={`upper ${classes.upcomingHeight}`}
        ref={ref}
      >
        {loadingPlannedEvents ? (
          dashboardMeetingsLoader
        ) : upcomingEvents.length === 0 ? (
          <Typography
            variant="h4"
            align="center"
            fontWeight={400}
            className={classes.noMsg}
          >
            {myMeetings ? t("no_pending_msg") : t("no_upcoming_msg")}
          </Typography>
        ) : (
          upcomingEventsWithDateTitles
        )}
      </Box>
    </Box>
  );
}
