import { Grid, Box, Typography, Button } from "@mui/material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import moment from "moment";
import { useTranslation } from "react-i18next";

export default function EventInfo({
  clickedEvent,
  theme,
  setLoading,
  addToCalendar,
  openWebsite,
  tab,
}) {
  const { t } = useTranslation();
  return (
    <Grid item xs={0} sm={12} style={{ position: "sticky", top: 60 }}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{
          background: tab ? "transparent" : theme.palette.secondary.lighter,
          borderRadius: "16px",
          height: tab ? "auto" : "85vh",
          boxSizing: "border-box",
          overflow: "auto",
          padding: tab ? "16px" : "24px 0px 0px 0px",
        }}
      >
        <Typography variant="h2">{t("event_details")}</Typography>
        <Box
          style={{
            width: "93%",
            padding: tab ? 0 : "20px",
            boxSizing: "border-box",
            backgroundColor: tab ? "transparent" : "rgba(150, 220, 255, 0.25)",
            borderRadius: "8px",
            marginTop: tab ? 10 : 20,
          }}
          display="flex"
          flexDirection={"column"}
          justifyContent="center"
          alignItems="flex-start"
        >
          <Box style={{ width: "100%" }}>
            <Typography align="left" variant="h3">
              {clickedEvent?.title}
            </Typography>
          </Box>
          <Box style={{ width: "100%" }} m="14px 0px">
            <Typography
              align="left"
              variant="h4"
              fontWeight="400"
              style={{
                color: "#565656",
                wordWrap: "break-word",
              }}
            >
              {clickedEvent?.description}
            </Typography>
          </Box>
          <Box mt={0.5} mb={0.5} display="flex" alignItems="center">
            <AccessTimeIcon
              style={{ color: theme.palette.text.primary, width: "18px" }}
            />

            <Typography
              align="left"
              style={{ marginLeft: 10 }}
              variant="h3"
              fontWeight={400}
            >
              {clickedEvent?.dates?.startDatetime
                ? moment(clickedEvent?.dates?.startDatetime).format(
                    "ddd, DD MMM YYYY, h:mm A"
                  )
                : clickedEvent?.dates?.start_date}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <LocationOnOutlinedIcon
              style={{ color: theme.palette.text.primary, width: "18px" }}
            />
            <Typography
              align="left"
              style={{ marginLeft: 10 }}
              variant="h3"
              fontWeight={400}
            >
              {clickedEvent?.location[0]}
            </Typography>
          </Box>
          {/* {eventOverlaps ? ( */}
          {clickedEvent?.dates?.overlapping ? (
            <Box
              display="flex"
              alignItems="center"
              columnGap="4px"
              m="15px 0 0 0"
            >
              <div
                style={{
                  borderRadius: "50%",
                  backgroundColor: "#EA4335",
                  width: "4px",
                  height: "4px",
                }}
              ></div>
              <Typography variant="subtitle1" color="#EA4335">
                {t("overlapping")}
              </Typography>
            </Box>
          ) : null}
          <Box
            mt="20px"
            display="flex"
            justifyContent="center"
            width="100%"
            columnGap={"20px"}
          >
            <Button
              // style={{
              //   padding: "5px 15px",
              // height: "28px",
              // }}
              disableRipple
              disableElevation
              variant="outlined"
              onClick={() => openWebsite(clickedEvent.link)}
            >
              {t("go_to")}
            </Button>
            {clickedEvent?.dates?.isSlotPossible ? (
              <Button
                // style={{
                // padding: "5px 15px",
                // height: "28px",
                // }}
                disableRipple
                disableElevation
                variant="contained"
                onClick={() => {
                  setLoading(true);
                  addToCalendar(clickedEvent);
                }}
                disabled={clickedEvent.selected}
              >
                {t("add_calendar")}
              </Button>
            ) : null}
          </Box>
        </Box>
      </Box>
    </Grid>
  );
}
