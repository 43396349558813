import React from "react";
import { Box, Grid, TextField, Typography, useTheme } from "@mui/material";

import { ReactComponent as EyeVisible } from "assets/eye_visible.svg";
import { ReactComponent as EyeHidden } from "assets/eye_hidden.svg";
import useStyle from "./styles";

export function SocialLink({
  socialName,
  SocialLogo,
  handleChange,
  formName,
  formData,
  handleHideShow,
  isHidden,
}) {
  const classes = useStyle();

  function setFormName() {
    if (socialName === "facebook") return formData.fb_link.value;
    if (socialName === "linkedIn") return formData.linkedin_link.value;
    if (socialName === "instagram") return formData.insta_link.value;
    if (socialName === "twitter") return formData.twitter_link.value;
    if (socialName === "youtube") return formData.youtube_link.value;
    if (socialName === "snapchat") return formData.snapchat_link.value;
    if (socialName === "tiktok") return formData.tiktok_link.value;
    if (socialName === "shopify") return formData.shopify_link.value;
  }

  return (
    <Box className={classes.socialTextFieldContainer}>
      <Box className={classes.logoNameContainer}>
        <img src={SocialLogo} alt="facebook" />
        <Typography
          variant="h3"
          style={{ textTransform: "capitalize" }}
          width={"20%"}
        >
          {socialName}
        </Typography>
      </Box>
      <Box className={classes.textFieldHideContainer}>
        <TextField
          name={formName}
          value={setFormName()}
          onChange={handleChange}
          variant="standard"
          sx={{
            width: "100%",
            marginTop: "4px",
          }}
          disabled={isHidden}
          type={"url"}
          placeholder="e.g http://social-media.com"
          InputProps={{
            disableUnderline: true,
            classes: { input: classes.textfield },
          }}
        />
        {isHidden ? (
          <EyeHidden
            className={classes.eyeIcon}
            onClick={() => handleHideShow(formName)}
          />
        ) : (
          <EyeVisible
            className={classes.eyeIcon}
            onClick={() => handleHideShow(formName)}
          />
        )}
      </Box>
    </Box>
  );
}
