import React, { useEffect, useState } from "react";
import {
  Button,
  Box,
  Chip,
  Badge,
  IconButton,
  Typography,
  Divider,
  Grid,
  TextField,
  useTheme,
  Checkbox,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import useStyle from "./styles";
import CancelIcon from "@mui/icons-material/Cancel";
import { serverPOST, serverPUT } from "../../Utils/HttpFunctions";
import { generateDoc } from "./CreateSummary";
import {
  deleteFileAttachment,
  uploadFileAttachment,
} from "../../Utils/CommonFunctions";
import DialogWithCloseIcon from "../DialogWithCloseIcon";
import { ReactComponent as TranscriptLogo } from "../../assets/transcript-logo.svg";
import { ReactComponent as Download } from "../../assets/download.svg";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import SnackbarComponent from "../../components/Global/SnackbarComponent";
import { saveAs } from "file-saver";
import useEventsStore from "../../Utils/eventStore";
import PizZip from "pizzip";
import { DOMParser } from "@xmldom/xmldom";
import SendNotes from "./SendNotes";

export default function UploadTranscript({
  meeting,
  onClose,
  t,
  isOrganizer,
  transcriptFile,
  handleDownload,
  handleNoteClick,
}) {
  const theme = useTheme();
  const classes = useStyle();
  const [file, setFile] = useState<any>(null);
  const [fileName, setFileName] = useState("");
  const [openSummarize, setOpenSummarize] = useState(false);
  const [invisible, setInvisible] = useState(true);
  const [generatedText, setGeneratedText] = useState("");
  const [transcript, setTranscript] = useState("");
  const [sendTranscriptEmail, setSendTranscriptEmail] = useState(false);
  const [message, setMessage] = useState("");
  const plannedEvents = useEventsStore((state) => state.plannedEvents);
  const setPlannedEvents = useEventsStore((state) => state.setPlannedEvents);
  const [error, setError] = useState("");
  const { id: meetingId } = meeting;
  const [loading, setLoading] = useState(false);

  // fetching from drive and displaying here - not using this for now
  // useEffect(() => {
  //   const fetchTranscript = async () => {
  //     const response = await serverPOST("/meeting/hello");
  //     if (response && response.constructor !== String) {
  //       const fileContent = response.data.fileContent;
  //       // Create a Blob with the file content
  //       const blob = new Blob([fileContent], {
  //         type: "text/plain",
  //       });
  //       //  saveAs(blob, "example.txt");
  //       const file = new File([blob], "Transcript.txt", { type: blob.type });
  //       setFile(file);
  //       setFileName("Transcript.txt");
  //     }
  //   };

  //   fetchTranscript();
  // }, []);

  //  fetching from drive and displaying here - not using this for now

  const handleFile = (e) => {
    const maxSize = 2 * 1024 * 1024; // 2MB in bytes
    if (e.target?.files[0] > maxSize) {
      return;
    }
    setFile(e.target?.files[0]);
    setFileName(e.target?.files[0]?.name);
  };

  const removeFile = () => {
    setFileName("");
    setFile(null);
    setError("");
  };

  const handleOpenSummarize = () => {
    onClose();
    setOpenSummarize(!openSummarize);
  };

  const handleSummarize = async (transcript) => {
    const response = await serverPUT(`/meeting/summarize/${meetingId}`, {
      text: transcript,
    });
    if (response && response.constructor !== String) {
      // response gets summary, now combine transcript and summary to one doc file
      // onClose();
      setOpenSummarize(!openSummarize);
      setTranscript(transcript);
      setGeneratedText(response.data.generatedText);
    }
  };

  const handleSaveDownload = async (transcript: string) => {
    let fname = fileName.split(".")[0] + ".docx";
    if (transcript) {
      // response gets summary, now combine transcript and summary to one doc file
      const genDoc = await generateDoc(transcript, generatedText, meeting, t);
      try {
        await uploadFileAttachment(`Summary-${fname}`, genDoc, meetingId);
        saveAs(genDoc, `Summary-${fname}`);

        const response = await serverPUT(
          `/meeting/summarize/${meetingId}?saveCheck=true`,
          {
            file_name: `Summary-${fname}`,
            send_transcript_email: sendTranscriptEmail,
          }
        );
        if (response && response.constructor !== String) {
          let temp = [...plannedEvents];
          const meeting = temp.find((event) => event.id === meetingId);
          const index = temp.indexOf(meeting);
          temp[index].transcript_summary = `Summary-${fname}`;
          setPlannedEvents(temp);

          setMessage(t("transcript_save"));
        }
      } catch (err) {
        console.error(err);
      }
    }
  };

  const openFile = (summarize) => {
    setError("");

    if (file) {
      const extension = file.name.split(".").pop().toLowerCase();

      switch (extension) {
        case "docx":
          readDocxFile(summarize);
          break;

        case "txt":
          readTextFile(summarize);
          break;
        default:
          setError(t("transcript_err"));
          console.error("Unsupported file type, docx and txt allowed");
          break;
      }
    }
  };

  function readTextFile(summarize) {
    if (file) {
      const fileReader = new FileReader();
      fileReader.readAsText(file, "UTF-8");
      fileReader.onload = async (e: any) => {
        if (e?.target?.result) {
          if (summarize) {
            await handleSummarize(e?.target?.result);
          } else {
            await handleSaveDownload(e?.target?.result);
          }
          setLoading(false);
        }
      };
    }
  }

  function str2xml(str) {
    if (str.charCodeAt(0) === 65279) {
      str = str.substr(1);
    }
    return new DOMParser().parseFromString(str, "text/xml");
  }

  function getParagraphs(content) {
    const zip = new PizZip(content);
    const xml = str2xml(zip.files["word/document.xml"].asText());
    const paragraphsXml = xml.getElementsByTagName("w:p");
    const paragraphs: any = [];

    for (let i = 0, len = paragraphsXml.length; i < len; i++) {
      let fullText = "";
      const textsXml = paragraphsXml[i].getElementsByTagName("w:t");
      for (let j = 0, len2 = textsXml.length; j < len2; j++) {
        const textXml = textsXml[j];
        if (textXml.childNodes) {
          fullText += textXml.childNodes[0].nodeValue;
        }
      }
      if (fullText) {
        paragraphs.push(fullText);
      }
    }
    return paragraphs;
  }

  const readDocxFile = async (summarize) => {
    const reader = new FileReader();

    reader.onload = async (e: any) => {
      const content = e.target.result;
      const paragraphs = getParagraphs(content);
      const text = paragraphs.join("\n");

      if (summarize) {
        await handleSummarize(text);
      } else {
        await handleSaveDownload(paragraphs);
      }
      setLoading(false);
    };

    reader.onerror = (err) => console.error(err);

    reader.readAsBinaryString(file);
  };

  const deleteTranscript = async () => {
    const response = await deleteFileAttachment(transcriptFile, meetingId);
    if (response && response.constructor !== String) {
      let temp = [...plannedEvents];
      const meeting = temp.find((event) => event.id === meetingId);
      const index = temp.indexOf(meeting);
      temp[index].transcript_summary = undefined;
      setPlannedEvents(temp);

      setMessage("Your summary and transcript have been deleted");
    }
  };

  return (
    <>
      {!!error && (
        <Typography variant="h5" style={{ color: "red" }}>
          {error}
        </Typography>
      )}
      {isOrganizer ? (
        <>
          {transcriptFile ? (
            <Box
              style={{
                display: "flex",
                gap: 8,
                alignItems: "center",
              }}
            >
              <Button
                variant="text"
                className={classes.attachement}
                onClick={() => handleDownload(transcriptFile)}
              >
                <Typography
                  variant="h3"
                  fontWeight="400"
                  className={classes.dottedText}
                >
                  {transcriptFile}
                </Typography>
              </Button>

              <DeleteOutlineRoundedIcon
                style={{
                  color: "red",
                  cursor: "pointer",
                  fontSize: "16px",
                }}
                onClick={deleteTranscript}
              />
            </Box>
          ) : (
            <Box display="flex" justifyContent="center" gap={2}>
              {fileName && (
                <Badge
                  color="primary"
                  badgeContent={
                    <IconButton onClick={removeFile}>
                      <CancelIcon className={classes.chipColor} />
                    </IconButton>
                  }
                  invisible={invisible}
                  onMouseOver={() => setInvisible(false)}
                  onMouseOut={() => setInvisible(true)}
                  className={classes.file}
                >
                  <Chip
                    variant="filled"
                    label={fileName}
                    className={classes.fileChip}
                  />
                </Badge>
              )}
              {file ? (
                <Button
                  variant="outlined"
                  className={classes.uploadBtn}
                  onClick={() => {
                    setLoading(true);
                    openFile(true);
                  }}
                >
                  {loading ? (
                    <CircularProgress style={{ color: "#95C3F2" }} size={20} />
                  ) : (
                    t("summary")
                  )}
                </Button>
              ) : (
                <Box display={"flex"} gap={1} alignItems="flex-end">
                  <Box>
                    <Typography
                      variant="h5"
                      color={"#70757A"}
                      style={{
                        fontWeight: 400,
                        marginBottom: "6px",
                        textAlign: "center",
                      }}
                    >
                      {t("upload_warn")}
                    </Typography>
                    <Button
                      variant="outlined"
                      className={classes.uploadBtn}
                      component="label"
                      startIcon={<CloudUploadOutlinedIcon />}
                    >
                      {t("upload_title") + " " + t("transcript")}
                      <input
                        type="file"
                        hidden
                        onChange={handleFile}
                        accept=".docx,.txt,application/msword,text/plain"
                      />
                    </Button>
                  </Box>

                  <Button
                    variant="outlined"
                    className={classes.uploadBtn}
                    onClick={handleNoteClick}
                  >
                    {meeting?.meeting_notes ? "View notes" : "Send Notes"}
                  </Button>
                </Box>
              )}
            </Box>
          )}
        </>
      ) : (
        <>
          {transcriptFile && (
            <Box
              style={{
                display: "flex",
                gap: 20,
                alignItems: "center",
              }}
            >
              <TranscriptLogo />
              <Typography
                onClick={() => handleDownload(transcriptFile)}
                variant="h4"
                style={{
                  fontWeight: 400,
                  cursor: "pointer",
                  backgroundColor: theme.palette.secondary.light,
                  padding: 8,
                  borderRadius: 8,
                }}
              >
                {transcriptFile}
                <Download
                  style={{
                    marginLeft: 8,
                  }}
                />
              </Typography>
            </Box>
          )}
        </>
      )}
      <MeetingMinutes
        openSummarize={openSummarize}
        openFile={openFile}
        handleOpenSummarize={handleOpenSummarize}
        transcript={transcript}
        generatedText={generatedText}
        setGeneratedText={setGeneratedText}
        t={t}
        isOrganizer={isOrganizer}
        sendTranscriptEmail={sendTranscriptEmail}
        setSendTranscriptEmail={setSendTranscriptEmail}
        loading={loading}
        setLoading={setLoading}
      />

      <SnackbarComponent
        open={!!message}
        handleClose={() => {
          onClose();
          setMessage("");
        }}
        message={message}
        vertical={"bottom"}
        horizontal={"center"}
      />
    </>
  );
}

function MeetingMinutes({
  openSummarize,
  handleOpenSummarize,
  transcript,
  openFile,
  generatedText,
  setGeneratedText,
  t,
  isOrganizer,
  sendTranscriptEmail,
  setSendTranscriptEmail,
  loading,
  setLoading,
}) {
  const theme = useTheme();
  const classes = useStyle();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <DialogWithCloseIcon
      open={openSummarize}
      handleClose={handleOpenSummarize}
      dialogSize={"lg"}
    >
      <Box className={classes.mainMeetingMinutesContainer}>
        <Typography variant="h2">{t("meeting_minutes")}</Typography>
        <Grid container className={classes.meetingMinutesContainer}>
          {!mobile ? (
            <></>
          ) : (
            <MeetingMinutesSummary
              classes={classes}
              generatedText={generatedText}
              isOrganizer={isOrganizer}
              setGeneratedText={setGeneratedText}
              t={t}
              transcript={transcript}
            />
          )}
          <Grid item className={classes.transcriptContainer} xs={12} md={6}>
            <Typography variant="h3">{t("transcript")}</Typography>

            <TextField
              id="outlined-multiline-flexible"
              multiline
              InputProps={{
                style: {
                  minHeight: 300,
                  fontSize: 14,
                  color: "#70757A",
                  display: "flex",
                  alignItems: "baseline",
                },
              }}
              maxRows={12}
              value={transcript}
              disabled
            />
          </Grid>
          {mobile ? (
            <></>
          ) : (
            <MeetingMinutesSummary
              classes={classes}
              generatedText={generatedText}
              isOrganizer={isOrganizer}
              setGeneratedText={setGeneratedText}
              t={t}
              transcript={transcript}
            />
          )}
        </Grid>
        <Box className={classes.transcriptButton}>
          <Box>
            <Button
              variant="contained"
              onClick={() => {
                setLoading(true);
                openFile(false);
              }}
            >
              {loading ? (
                <CircularProgress style={{ color: "white " }} size={20} />
              ) : (
                t("save") + " & " + t("download")
              )}
            </Button>
          </Box>
          {isOrganizer && (
            <Box className={classes.sendEmail}>
              <Checkbox
                checked={sendTranscriptEmail}
                size="small"
                onChange={(e) => {
                  setSendTranscriptEmail(e.target.checked);
                }}
              />
              <Typography
                variant="h4"
                color={"#70757A"}
                fontWeight={400}
                textAlign={"left"}
              >
                {t("send_minutes")}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </DialogWithCloseIcon>
  );
}

function MeetingMinutesSummary({
  isOrganizer,
  transcript,
  classes,
  generatedText,
  setGeneratedText,
  t,
}) {
  return (
    <Grid item xs={12} md={6} className={classes.summaryContainer}>
      <Typography variant="h3">{t("meeting_minutes")}</Typography>
      {!isOrganizer ? (
        <Typography
          variant="h4"
          fontWeight={400}
          style={{
            color: "#70757A",
          }}
        >
          {transcript}
        </Typography>
      ) : (
        <TextField
          id="outlined-multiline-flexible"
          multiline
          InputProps={{
            className: classes.summaryTextfield,
          }}
          maxRows={12}
          value={generatedText}
          onChange={(e) => setGeneratedText(e.target.value)}
        />
      )}
    </Grid>
  );
}
