import React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { YEARLY, MONTHLY } from "../../Utils/Constants";

export default function Toggler({
  billingType,
  setBillingType,
}: {
  billingType: string | boolean;
  setBillingType: any;
}) {
  const { t } = useTranslation();
  return (
    <Box
      display={"flex"}
      mt={0.5}
      justifyContent={"center"}
      px={0.5}
      alignItems={"center"}
      py={0.5}
      style={{
        border: "1px solid #DAD2E5",
        borderRadius: "8px",
        width: "fit-content",
        margin: "auto",
      }}
    >
      <Typography
        onClick={() => {
          setBillingType(YEARLY);
          // setSelectedPlan(true);
        }}
        variant={"h4"}
        sx={{
          color: billingType === MONTHLY ? "#479AE9" : "#F1F1F1",
          backgroundColor: billingType === MONTHLY ? "" : "#479AE9",
          cursor: "pointer",
        }}
        px={2}
        py={0.5}
        borderRadius={2}
      >
        {t("billed_yearly")}
      </Typography>
      <Typography
        onClick={() => {
          setBillingType(MONTHLY);
          // setSelectedPlan(true);
        }}
        variant={"h4"}
        sx={{
          color: billingType === YEARLY ? "#479AE9" : "#F1F1F1",
          backgroundColor: billingType === YEARLY ? "" : "#479AE9",
          cursor: "pointer",
        }}
        px={2}
        py={0.5}
        borderRadius={2}
      >
        {t("billed_monthly")}
      </Typography>
    </Box>
  );
}
