import { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";

import { getCalendarTitle } from "Utils/CommonFunctions";
import {
  MEETING_INVITES_COLOR,
  PENDING_MEETINGS_COLOR,
  WEBSITE_BOOKINGS_COLOR,
} from "Utils/Constants";
import ColorIndicator from "./ColorIndicator";
import ScrollButton from "components/ScrollButton";
import CalendarViewButtons from "../Dashboard/CalendarViewButtons";

/**
 * The navigation bar above the calendar on the Pending Meetings page
 */
export default function MeetingsCalendarNavigation({
  calendarRef,
  setSelectedBtn,
  selectedBtn,
  t,
}) {
  const [title, setTitle] = useState("");
  const theme = useTheme();
  const tablet = useMediaQuery(theme.breakpoints.down("lg"));

  useEffect(() => {
    getCalendarTitle(calendarRef, setTitle);
  }, []);

  const handleTodayClick = () => {
    calendarRef?.current?.getApi()?.today();
  };

  return (
    <Grid container marginBottom="12px">
      <Grid container item xs={3} rowGap="8px">
        <Typography variant="h3" display="flex" alignItems="center">
          {t(title?.split(" ")[0]) + " " + title?.split(" ")[1]}
        </Typography>
        <Button
          variant="outlined"
          onClick={handleTodayClick}
          sx={{
            marginRight: "90px",
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
            height: "24px",
            "&:hover": {
              backgroundColor: "#FBFBFF",
            },
          }}
        >
          {t("today")}
        </Button>
      </Grid>
      <Grid
        container
        item
        xs={7}
        rowGap="8px"
        alignItems="center"
        flexDirection="column"
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent={"center"}
          alignItems="center"
          sx={{ mt: { xs: 2, md: 0 } }}
        >
          <CalendarViewButtons
            calendarRef={calendarRef}
            setSelectedBtn={setSelectedBtn}
            selectedBtn={selectedBtn}
          />
        </Box>
        {tablet ? null : (
          <Box
            display="flex"
            justifyContent="center"
            mb={1}
            style={{
              width: "400px",
            }}
          >
            <ColorIndicator
              text={t("pending_meetings")}
              color={PENDING_MEETINGS_COLOR}
            />
            <ColorIndicator
              text={t("invite_meetings")}
              color={MEETING_INVITES_COLOR}
            />
            <ColorIndicator
              text={t("website_bookings")}
              color={WEBSITE_BOOKINGS_COLOR}
            />
            {/* <ColorIndicator text={"Confirmed meetings"} color={"#9FA3F6"} /> */}
            {/* BR-1 */}
            {/* <ColorIndicator text={"Events"} color={"#E5F0F8"} /> */}
            {/* <ColorIndicator text={"Tasks"} color={"#CAE5DF"} /> */}
          </Box>
        )}
      </Grid>
      <Grid
        container
        item
        xs={2}
        rowGap="8px"
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <Box display="flex" columnGap="6px">
          <ScrollButton
            direction="prev"
            calendarRef={calendarRef}
            callback={() => getCalendarTitle(calendarRef, setTitle)}
            homePage={true}
            background={"#F7F7F9"}
          />
          <ScrollButton
            direction="next"
            calendarRef={calendarRef}
            callback={() => getCalendarTitle(calendarRef, setTitle)}
            homePage={true}
            background={"#F7F7F9"}
          />
        </Box>
      </Grid>
    </Grid>
  );
}
