import { Stack, Typography } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useTranslation } from "react-i18next";

export default function MatchingErrorMessage() {
  const { t } = useTranslation();

  return (
    <Stack direction="row" alignItems="center" columnGap="2px">
      <ErrorOutlineIcon sx={{ fontSize: "20px" }} />
      <Typography variant="h5" sx={{ color: "#d32f2f" }}>
        {t("passwords_must_match")}
      </Typography>
    </Stack>
  );
}
