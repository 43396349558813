import { Box } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import PublicCalendarComponent from "components/AddToWebsite/PublicCalendarComponent";
import { useNavigate, useSearchParams } from "react-router-dom";
import { serverGET, serverPOSTWithToken } from "Utils/HttpFunctions";
import loggingInDev from "loggingInDev";
import { convertDateToUTCMilliseconds } from "Utils/CommonFunctions";
import { MEETING_THROUGH_WEB_INTEGRATION_CALENDAR } from "Utils/Constants";
import DialogBox from "components/DialogBox";
import { useCookies } from "react-cookie";
import ErrorSnackbar from "components/ErrorSnackbar";
import { useTranslation } from "react-i18next";
import useWebsiteClientStore from "../../Utils/websiteClientStore";
import CreateShareableMeetingDialog from "../../components/MeetingTemplates/CreateShareableMeetingDialog";
import ChooseService from "../../components/AddToWebsite/ChooseService";
import Spinner from "../../components/Global/Spinner";
import { httpGetUnsecure, httpPOSTUnsecure } from "../../Utils/HttpFunctions";
import SnackbarComponent from "../../components/Global/SnackbarComponent";
import ChooseLocation from "../../components/AddToWebsite/ChooseLocation";

export default function PublicCalendar() {
  const [infoModal, setInfoModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [phone, setPhone] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("userId");
  const meetingId = searchParams.get("meetingId");
  const sessionId = searchParams.get("session_id");
  const successUrl = searchParams.get("success");
  const [publicData, setPublicData] = useState();
  const [selectedSlot, setSelectedSlot] = useState();
  const [error, setError] = useState("");
  const [cookies, setCookie] = useCookies(["name"]);
  const [loading, setLoading] = useState(true);
  const [websiteData, setWebsiteData] = useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [meetingData, setMeetingData] = useState(null);
  const [rescheduleSuccess, setRescheduleSuccess] = useState("");
  const [indicator, setIndicator] = useState(new Date());

  const selectedService = useWebsiteClientStore(
    (state) => state.selectedService
  );
  const selectedLocation = useWebsiteClientStore(
    (state) => state.selectedLocation
  );

  useEffect(() => {
    if (userId && !sessionId && selectedService && selectedLocation) {
      getMeetingPublicData(
        `service_duration=${selectedService?.duration_minutes}`
      );
    }
  }, [userId, selectedService]);

  // to bring services
  useEffect(() => {
    const getWebsiteData = async () => {
      setLoading(true);
      let url = `/web-integration/${userId}?offset=${new Date().getTimezoneOffset()}&services=true`;
      const response = await serverGET(url);
      if (response && response.constructor !== String) {
        setWebsiteData(response.data);
      } else {
        console.log("unable to get meeting");
        setError(response);
      }
      setLoading(false);
    };
    getWebsiteData();
  }, []);

  useEffect(() => {
    // console.log("sesssionId", sessionId);
    if (sessionId && successUrl == "true") {
      loadDataAndCreateMeeting();
    } else if (!sessionId && successUrl == "false") {
      setError("Invalid session");
    }
  }, [successUrl]);

  useEffect(() => {
    if (meetingId) {
      const getMeeting = async () => {
        // setLoading(true);
        const response = await httpGetUnsecure(
          `/meeting/${meetingId}?userId=${userId}`
        );
        if (response && response.constructor !== String) {
          setMeetingData(response.data);
        } else {
          // setFetchError(t("snackbar_messages.6"));
          // setTimeout(() => navigate("/dashboard"), 2000);
          console.log("Oops something went wrong");
        }
        // setLoading(false);
      };
      getMeeting();
    }
  }, []);

  useEffect(() => {
    if (meetingData) {
      getMeetingPublicData(`service_duration=${meetingData?.duration_minutes}`);
    }
  }, [meetingData]);

  const getMeetingPublicData = async (params, date) => {
    setLoading(true);
    let url = `/web-integration/${userId}?offset=${new Date().getTimezoneOffset()}`;
    if (params) {
      url = url + `&${params}`;
    }
    const response = await serverGET(url, undefined, undefined, date);
    if (response && response.constructor !== String) {
      loggingInDev("getting data", response.data);
      setPublicData(response.data);
    } else {
      console.log("unable to get meeting");
      setError(response);
    }
    setLoading(false);
  };

  const handlePublicCreate = async () => {
    if (meetingId) {
      const data = {
        start_datetime: convertDateToUTCMilliseconds(selectedSlot.start),
        end_datetime: convertDateToUTCMilliseconds(selectedSlot.end),
        time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        utc_offset: new Date().getTimezoneOffset(),
      };

      const response = await httpPOSTUnsecure(
        `/meeting/reschedule/${meetingId}`,
        data
      );
      if (response && response.constructor !== String) {
        setRescheduleSuccess(t("reschedule_success"));
        setTimeout(() => navigate("/login"), 3000);
      } else {
        loggingInDev("error =>", response);
      }
    } else {
      setInfoModal(true);
    }
  };

  const createMeeting = async (name, email, question_answers) => {
    setDisabled(true);
    const data = {
      initiator_user_id:
        publicData?.userWebCalendarPreferences?.skeding_user_id.id,
      title:
        selectedService?.name || publicData?.userWebCalendarPreferences?.title,
      duration_minutes: selectedService?.duration_minutes,
      start_datetime: convertDateToUTCMilliseconds(selectedSlot.start),
      end_datetime: convertDateToUTCMilliseconds(selectedSlot.end),
      participants: [email],
      time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      utc_offset: new Date().getTimezoneOffset(),
      location: selectedLocation?.locationType,
      agenda:
        publicData?.userWebCalendarPreferences?.description ?? "No Description",
      case: MEETING_THROUGH_WEB_INTEGRATION_CALENDAR,
      non_skeding_participant_data: {
        participant_name: name,
        participant_email: email,
      },
      phone_number: phone,
      question_answers,
      service: selectedService.name,
      room: selectedService?.meeting_room
        ? selectedService?.meeting_room
        : undefined,
    };

    const response = await serverPOSTWithToken("/meeting/shareable", data);
    if (response && response.constructor !== String) {
      setSuccess(true);
      setDisabled(false);
    } else {
      loggingInDev("error =>", response);
      setDisabled(false);
    }
  };

  const loadDataAndCreateMeeting = async () => {
    if (cookies.meeting_data) {
      let meetingData = cookies.meeting_data;
      meetingData.stripe_session_id = sessionId;

      const response = await serverPOSTWithToken(
        "/meeting/shareable",
        meetingData
      );
      if (response && response.constructor !== String) {
        setSuccess(true);
        setDisabled(false);
        setLoading(false);
      } else {
        loggingInDev("error =>", response);
        setDisabled(false);
      }
    }
  };

  if (websiteData?.userWebCalendarPreferences === null) {
    return <h1>User Web Calendar does not exist</h1>;
  }

  if (
    websiteData?.userWebCalendarPreferences &&
    websiteData?.userWebCalendarPreferences?.websiteData?.length === 0
  ) {
    return <h1>No service exists yet</h1>;
  }
  if (error) {
    return (
      <ErrorSnackbar
        open={!!error}
        handleClose={() => navigate("/login")}
        message={error}
        vertical={"bottom"}
        horizontal={"center"}
      />
    );
  }

  if (!selectedLocation && !meetingId) {
    if (loading) {
      return <Spinner />;
    }
    return (
      <ChooseLocation
        {...websiteData?.userWebCalendarPreferences}
        client={true}
        skeding_user_id={{
          first_name:
            websiteData?.userWebCalendarPreferences?.skeding_user_id.first_name,
          last_name:
            websiteData?.userWebCalendarPreferences?.skeding_user_id.last_name,
          picture: websiteData?.userWebCalendarPreferences.picture,
        }}
      />
    );
  }

  return !selectedService && !meetingId ? (
    <ChooseService
      {...websiteData?.userWebCalendarPreferences}
      client={true}
      skeding_user_id={{
        first_name:
          websiteData?.userWebCalendarPreferences?.skeding_user_id.first_name,
        last_name:
          websiteData?.userWebCalendarPreferences?.skeding_user_id.last_name,
        picture: websiteData?.userWebCalendarPreferences.picture,
      }}
    />
  ) : (
    <Box>
      <PublicCalendarComponent
        handlePublicCreate={handlePublicCreate}
        publicData={publicData}
        selectedSlot={selectedSlot}
        setSelectedSlot={setSelectedSlot}
        responseModal={success}
        setResponseModal={setSuccess}
        loading={loading}
        meetingId={meetingId}
        userId={userId}
        meetingData={meetingData}
        getMeetingPublicData={getMeetingPublicData}
        indicator={indicator}
        setIndicator={setIndicator}
      />

      <CreateShareableMeetingDialog
        initiatorEmail={
          publicData?.userWebCalendarPreferences?.skeding_user_id?.email
        }
        popup={infoModal}
        setPopup={setInfoModal}
        createMeeting={createMeeting}
        disabled={disabled}
        askUser={publicData?.userWebCalendarPreferences?.ask_user}
        service={selectedService}
      />

      <SnackbarComponent
        open={rescheduleSuccess}
        handleClose={() => setRescheduleSuccess("")}
        message={rescheduleSuccess}
        vertical={"bottom"}
        horizontal={"center"}
      />
    </Box>
  );
}
