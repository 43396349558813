import React from "react";
import {
  Button,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import PlanFeatures from "./PlanFeatures";
import { FREE_PLAN, PREMIUM_PLAN } from "../../Utils/Constants";

export default function CancelSubscriptionDialog({
  handleMainDialogCancel,
  openReasonDialog,
}: {
  openReasonDialog: () => void;
  handleMainDialogCancel: () => void;
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Grid container direction={"column"}>
        <Typography variant="h2">{t("cancel_subscription")}</Typography>
        <Typography variant="h3" my={2} fontWeight={400}>
          {t("cancel_desc")}
        </Typography>
        <Grid
          container
          direction="row"
          display={"flex"}
          flexWrap={"nowrap"}
          flexDirection={mobile ? "column" : "row"}
          gap={1}
        >
          <Grid
            item
            xs={12}
            lg={6}
            p={2}
            display={"flex"}
            flexDirection={"column"}
            gap={2}
          >
            <Typography variant="h3" textAlign={"left"}>
              Skeding Free
            </Typography>
            <Divider />
            <PlanFeatures planType={FREE_PLAN} />
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            p={2}
            gap={2}
            display={"flex"}
            flexDirection={"column"}
            sx={{
              border: "1px #479AE9 solid",
              borderRadius: "8px",
            }}
          >
            <Typography variant="h3" textAlign={"left"}>
              Skeding Premium
            </Typography>
            <Divider />
            <PlanFeatures planType={PREMIUM_PLAN} />
          </Grid>
        </Grid>
        <Grid
          item
          display="flex"
          gap={3}
          justifyContent={"center"}
          flexDirection={mobile ? "column" : "row"}
          my={2}
        >
          <Button
            variant="outlined"
            onClick={() => {
              openReasonDialog();
              handleMainDialogCancel();
            }}
          >
            {t("cancel_subscription")}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleMainDialogCancel();
            }}
          >
            No, keep premium plan
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
