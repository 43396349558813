import moment from "moment";
import { create } from "zustand";
import { convertDateToUTCMilliseconds } from "./CommonFunctions";
import { serverGETPOST } from "./HttpFunctions";

const initialState = {
  preferencesCheck: false,
  startTimeCheck: false,
  durationCheck: false,
  twoWeekCheck: false,
  populateEvents: false,
  suggestedSlots: {},
  startTime: getInitialStartTime(),
  formDate: moment(),
  blockedParticipants: [],
};

const useSuggestionsStore = create((set) => ({
  ...initialState,

  setPreferecesCheck: (bool) => {
    set({ preferencesCheck: bool });
  },
  setStartTimeCheck: (bool) => {
    set({ startTimeCheck: bool });
  },
  setDurationCheck: (bool) => {
    set({ durationCheck: bool });
  },
  setTwoWeekCheck: (bool) => {
    set({ twoWeekCheck: bool });
  },
  setSuggestedSlots: (slots) => {
    set({ suggestedSlots: slots });
  },
  setStartTime: (value) => {
    set({ startTime: value, startTimeCheck: false });
  },
  setFormDate: (value) => {
    set({ formDate: value });
  },
  setBlockedParticipants: (value) => {
    set({ blockedParticipants: value });
  },
  loadData: async (duration_minutes, participants) => {
    const slots = await fetchSlots(duration_minutes, participants);
    if (slots) {
      set({ suggestedSlots: Object.assign({}, slots), populateEvents: true });
    }
  },
  resetStore: () => {
    set(initialState);
  },
}));

export function getInitialStartTime() {
  const currentDate = new Date();
  const currentMinutes = currentDate.getMinutes();

  let nearestQuarter = Math.ceil(currentMinutes / 15) * 15;
  if (nearestQuarter === 60) {
    nearestQuarter = "00";
  }
  return nearestQuarter.toString();
}

function calculateNearestQuarter(currentMinutes) {
  return (15 - (currentMinutes % 15) + currentMinutes) % 60;
}

const fetchSlots = async (duration_minutes, participants) => {
  let params = `?offset=${new Date().getTimezoneOffset()}`;
  const blockPreferences = useSuggestionsStore.getState().preferencesCheck;
  const startTimeFlag = useSuggestionsStore.getState().startTimeCheck;
  let response = useSuggestionsStore.getState().suggestedSlots;

  // if (!startTimeFlag) {
  //   let nearestQuarter = getInitialStartTime();
  //   useSuggestionsStore.setState({
  //     startTime: nearestQuarter,
  //   });
  // }

  if (blockPreferences && !("blockedPreferences" in response)) {
    params = `?offset=${new Date().getTimezoneOffset()}`;
    params = params + `&preferences=0`;
  }
  if (
    useSuggestionsStore.getState().durationCheck &&
    !("duration" in response) &&
    duration_minutes > 15
  ) {
    duration_minutes = duration_minutes - 15;
  }
  if (startTimeFlag && !("startTime" in response)) {
    let count = 0;

    const formDate = useSuggestionsStore.getState().formDate;
    const currentDate = new Date(formDate.toDate());
    if (
      !moment().isSame(formDate, "day") &&
      !moment().isSame(formDate, "month")
    ) {
      // otherwise start from 0, 0
      currentDate.setHours(0);
      currentDate.setMinutes(0);
      currentDate.setSeconds(0);
      currentDate.setMilliseconds(0);
    }

    currentDate.setMinutes(useSuggestionsStore.getState().startTime);
    const currentMinutes = currentDate.getMinutes();
    let nearestQuarter = calculateNearestQuarter(currentMinutes);

    const res = await findStartTimeSlots(
      duration_minutes,
      participants,
      params,
      currentDate,
      nearestQuarter,
      count
    );
    response = res.data;
  } else {
    const res = await getSlots(
      duration_minutes,
      participants,
      params,
      useSuggestionsStore.getState().formDate.toDate()
    );
    response = res.data;
  }
  if (response && response.constructor !== String) {
    return response;
  }
};

const findStartTimeSlots = async (
  duration_minutes,
  participants,
  params,
  currentDate,
  nearestQuarter,
  count
) => {
  if (nearestQuarter === 0) {
    nearestQuarter = 59;
  }
  currentDate.setMinutes(nearestQuarter);
  if (currentDate < new Date()) {
    let hours = currentDate.getHours();
    currentDate.setHours(hours + 1);
  }

  const res = await getSlots(
    duration_minutes,
    participants,
    params,
    currentDate
  );
  useSuggestionsStore.setState({
    startTime: nearestQuarter === 59 ? 0 : nearestQuarter,
  });
  if (res.data.total > 0 || count === 3) {
    return res;
  } else {
    nearestQuarter = (nearestQuarter + 15) % 60;
    count++;

    return findStartTimeSlots(
      duration_minutes,
      participants,
      params,
      currentDate,
      nearestQuarter,
      count
    );
  }
};
const getSlots = async (
  duration_minutes,
  participants,
  params,
  currentTime = new Date()
) => {
  let blockedParticipants = useSuggestionsStore.getState().blockedParticipants;

  const data = {
    current_date: convertDateToUTCMilliseconds(currentTime),
    duration_minutes,
    participants: participants,
    blocked_participants: blockedParticipants,
  };

  const response = await serverGETPOST(`/meeting/suggestions${params}`, data);
  return response;
};

export default useSuggestionsStore;
