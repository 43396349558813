import makeStyles from "@mui/styles/makeStyles";
const useStyle = makeStyles((theme) => ({
  qrCard: {
    aspectRatio: "1 / 1",
    borderRadius: "8px",
    background: "none",
    width: "80%",
  },
  img: {
    width: "164px",
    height: "164px",
    borderRadius: 16,
  },

  delDialogContent: {
    textAlign: "center",
    paddingBottom: "10px",
    color: theme.palette.text.primary,
    marginTop: "8px",
  },
  dialogTitle: {
    textAlign: "center",
    color: "#363B53",
    fontSize: "16px",
    fontWeight: 500,
  },
  dialogLabel: {
    fontSize: "16px",
    fontWeight: 400,
  },
  badge: {
    "& .MuiBadge-badge": {
      backgroundColor: "#F7F7F9",
      width: "11%",
    },
  },
  badgeIcon: {
    background: "#96DCFF",
    width: "18px",
    height: "18px",
  },
  profileLoader: {
    height: "120px",
    width: "120px",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#B3B5B6",
  },
  avatar: {
    height: "120px",
    width: "120px",
  },

  textInput: {
    fontSize: "16px",
    fontWeight: 400,
    color: theme.palette.text.primary,
    border: "1px solid #DAD2E5",
    borderRadius: "6px",
    paddingLeft: "8px",
  },
}));

export default useStyle;
