import { useContext } from "react";

import { Box, Typography, Stack, Avatar, useTheme } from "@mui/material";
import moment from "moment";

import { stringToColor, checkLocation } from "Utils/CommonFunctions";
import { accountContext } from "context/user";
import { PASTEL_COLORS, VIBRANT_COLORS } from "Utils/Constants";
import useStyle from "./styles";
import ToolTip from "../Global/ToolTip";

/**
 * Card that renders the information for an upcoming event
 */
export default function UpcomingEvent({ event, onEventClick, mobile }) {
  const theme = useTheme();
  const { user } = useContext(accountContext);
  const classes = useStyle();

  /**
   * Checks if an event lasts all day for rendering the start and end time
   * @param {Date} start - the start date for the event
   * @param {Date} end - the end date for the event
   * @returns - "All Day" if the start and end date difference isn't 24hrs, returns the start and end dates as strings otherwise
   */
  const checkIfAllDay = (start, end) => {
    if (end - start === 86400000) {
      return "All Day";
    } else {
      return (
        moment(start).format("LT") +
        " to " +
        moment(end).format("LT") +
        ` (${event.durationMinutes} minutes)`
      );
    }
  };

  return (
    <Box
      onClick={() => onEventClick(event)}
      style={{
        backgroundColor: mobile ? event.bgColor : event.bgColor + "33",
        boxShadow: `-4px 0px 0px ${event.bgColor}`,
      }}
      className={classes.upcomingContainer}
    >
      <Stack
        direction={mobile ? "row" : "column"}
        alignItems={mobile ? "center" : "flex-start"}
        spacing={"3px"}
      >
        <Box>
          <Typography variant="h3" fontWeight="400" align="left">
            {event.title}
          </Typography>
          {mobile ? (
            <Typography variant="h4" align="left" style={{ marginRight: 10 }}>
              ({moment(event?.start).format("LT")} to{" "}
              {moment(event?.end).format("LT")} )
            </Typography>
          ) : (
            <Typography variant="h4" align="left" fontWeight="400" p="5px 0px">
              {checkIfAllDay(event?.start, event?.end)}
            </Typography>
          )}
        </Box>

        <Typography
          variant="h4"
          align="left"
          noWrap={true}
          style={{
            color:
              checkLocation(event.location) === null
                ? theme.palette.info.light
                : theme.palette.primary.main,
          }}
          className={classes.upcomingText}
        >
          {event.location}
        </Typography>
        <Box className={classes.participants}>
          {event?.skedingParticipants?.map((participant, i) => {
            return (
              <ToolTip title={participant.email} key={i} placement="bottom">
                <Avatar
                  sx={{
                    bgcolor: stringToColor(
                      participant.email,
                      user?.event_theme === 0 ? PASTEL_COLORS : VIBRANT_COLORS
                    ),
                  }}
                  className={classes.avatar}
                >
                  {participant?.email[0]}
                  {participant?.email[1]}
                </Avatar>
              </ToolTip>
            );
          })}
          {event?.nonskedingParticipants?.map((participant, i) => {
            return (
              <ToolTip title={participant} placement="bottom" key={i}>
                <Avatar
                  sx={{
                    bgcolor: stringToColor(
                      participant,
                      user?.event_theme === 0 ? PASTEL_COLORS : VIBRANT_COLORS
                    ),
                  }}
                  className={classes.avatar}
                >
                  {participant[0]}
                  {participant[1]}
                </Avatar>
              </ToolTip>
            );
          })}
        </Box>
      </Stack>
    </Box>
  );
}
