const handleOffset = () => {
  function z(n) {
    return (n < 10 ? "0" : "") + n;
  }
  var offset = new Date().getTimezoneOffset();
  var sign = offset < 0 ? "+" : "-";
  offset = Math.abs(offset);
  return sign + " " + offset / 60 + ":" + z(offset % 60);
};

const handleTimeAbbreviation = () => {
  var date = new Date().toLocaleString("en-US", {
    timeZoneName: "long",
    hour12: false,
  });
  var abvArray = date.match(/[A-Z]/g);
  return abvArray.join("");
};

export const handleTimeZone = () => {
  //var locale = Intl.DateTimeFormat().resolvedOptions().locale.substring(3);
  const timeZone = `(GMT ${handleOffset()}) [${handleTimeAbbreviation()}]`;
  return timeZone;
};
