import React from "react";
import { Grid, Chip, Typography, useMediaQuery, useTheme } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { useStyles } from "../../styles/participantChip";
import ToolTip from "../Global/ToolTip";

export default function SkedingParticipants({ participants, total, onClick }) {
  const classes = useStyles();

  return participants.map((participant, j) => {
    return (
      <ToolTip
        title={participant?.email ? participant.email : participant.user_id}
        placement="top"
      >
        <Chip
          className={total > 1 ? classes.chip : classes.fullChip}
          key={j}
          onClick={onClick ? onClick(participant) : console.log("")}
          icon={
            participant.is_invitation_accepted === 0 ? (
              <CancelIcon />
            ) : participant.is_invitation_accepted === 1 ? (
              <CheckCircleIcon />
            ) : (
              <NotificationsNoneIcon style={{ color: "red" }} />
            )
          }
          label={
            <Typography
              variant="h4"
              fontWeight={400}
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {participant?.email ? participant.email : participant.user_id}
            </Typography>
          }
        />
      </ToolTip>
    );
  });
}

export function NonSkedingParticipants({
  participants,
  total,
  getIcon,
  onClick,
}) {
  const classes = useStyles();

  return participants.map((participant, j) => {
    return (
      <ToolTip title={participant} placement="top">
        <Chip
          className={total > 1 ? classes.chip : classes.fullChip}
          key={j}
          icon={getIcon(participant, j)}
          onClick={onClick ? onClick(participant) : console.log("")}
          label={
            <Typography
              variant="h4"
              fontWeight={400}
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {participant}
            </Typography>
          }
        />
      </ToolTip>
    );
  });
}
