import {
  Dialog,
  DialogActions,
  Button,
  DialogTitle,
  CircularProgress,
  IconButton,
  Box,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { serverPUT } from "Utils/HttpFunctions";
import loggingInDev from "loggingInDev";
import { useContext, useState } from "react";
import SnackbarComponent from "components/Global/SnackbarComponent";
import { meetingsContext } from "context/meetings";
import {
  MEETING_INVITES_COLOR,
  PENDING_MEETINGS_COLOR,
  PLANNED_EVENTS_COLOR,
  WEBSITE_BOOKINGS_COLOR,
} from "Utils/Constants";
import { integrationContext } from "context/Integrations";
import Comment from "./Comment";
import { useTranslation } from "react-i18next";

export default function CancelDialog({
  cancelDialog,
  setCancelDialog,
  meetingId,
  event,
  setIsOpen,
  setPlannedEvents,
  plannedEvents,
  handleClose: handleMeetingsPopup,
  setCancelSuccess,
  isCal,
}) {
  const { user } = useContext(integrationContext);
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);
  const [comment, setComment] = useState("");

  const {
    setPendingMeetings,
    pendingMeetings,
    setWebsiteBookings,
    websiteBookings,
    setMeetingInvites,
    meetingInvites,
  } = useContext(meetingsContext);

  const handleClose = () => {
    setCancelDialog(false);
  };

  const handleProceed = async () => {
    setDisabled(true);
    const response = await serverPUT(`/meeting/${meetingId}`, {
      to_cancel: true,
      cancel_reason: !!comment.trim() ? comment : undefined,
    });
    if (response && response.constructor !== String) {
      loggingInDev(response.message);
      setCancelDialog(false);
      setCancelSuccess(true);
      // setMeetingDeleted(true);
      if (event?.backgroundColor === PENDING_MEETINGS_COLOR) {
        const temp = [...pendingMeetings];
        const index = temp.indexOf(event);
        if (index !== -1) {
          temp.splice(index, 1);
          setPendingMeetings(temp);
        }
      } else if (event?.backgroundColor === MEETING_INVITES_COLOR) {
        const temp = [...meetingInvites];
        const index = temp.indexOf(event);
        if (index !== -1) {
          temp.splice(index, 1);
          setMeetingInvites(temp);
        }
      } else if (event?.backgroundColor === WEBSITE_BOOKINGS_COLOR) {
        const temp = [...websiteBookings];
        const index = temp.indexOf(event);
        if (index !== -1) {
          temp.splice(index, 1);
          setWebsiteBookings(temp);
        }
      } else if (event?.backgroundColor === PLANNED_EVENTS_COLOR) {
        const temp = [...plannedEvents];
        const index = temp.indexOf(event);
        if (index !== -1) {
          temp.splice(index, 1);
          setPlannedEvents(temp);
        }
      }
      if (setIsOpen) {
        setIsOpen(false);
      }
      if (handleMeetingsPopup) {
        handleMeetingsPopup();
      }
    } else {
      loggingInDev(response.message);
    }
    setDisabled(false);
  };

  return (
    <>
      <Dialog
        open={cancelDialog}
        onClose={handleClose}
        sx={{ justifyContent: "center" }}
        maxWidth="xs"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: "8px",
            padding: "24px",
            boxSizing: "border-box",
          },
        }}
      >
        <Box display="flex" justifyContent="flex-end">
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box>
          <Typography variant="h2" align="center" sx={{ marginBottom: "8px" }}>
            {t("meeting_cancel")}
          </Typography>
          <Typography variant="h3" align="center" fontWeight={400}>
            {t("cancel_confirm")}
          </Typography>
        </Box>
        {isCal ? (
          event?.initiatorEmail === user?.email ? (
            <Comment comment={comment} setComment={setComment} />
          ) : null
        ) : (
          <Comment comment={comment} setComment={setComment} />
        )}

        <DialogActions sx={{ justifyContent: "center" }}>
          {/* <Button
            onClick={handleClose}
            variant="outlined"
            style={{
              padding: "4px 20px",
              fontSize: "10px",
              marginRight: 10,
            }}
          >
            Cancel
          </Button> */}

          <Button
            variant="instant"
            onClick={handleProceed}
            disabled={disabled}
            style={{ marginLeft: 10, fontSize: 14 }}
          >
            {disabled ? (
              <CircularProgress style={{ color: "white" }} size={20} />
            ) : (
              t("proceed")
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
