import { Box, IconButton } from "@mui/material";
import React, { useState } from "react";
import { FaMinus, FaPlus } from "react-icons/fa";

const CounterBox = ({ count, handleIncrement, handleDecrement, disable }) => {
  return (
    <Box display="flex" alignItems={"center"}>
      <IconButton onClick={handleDecrement} disabled={disable}>
        <FaMinus size={12} />
      </IconButton>
      <Box
        style={{
          margin: "0 4px",
          border: "1px solid",
          padding: "4px 20px",
          borderRadius: "8px",
          color: "#3C4242",
        }}
      >
        {count}
      </Box>
      <IconButton onClick={handleIncrement} disabled={disable}>
        <FaPlus size={12} />
      </IconButton>
    </Box>
  );
};

export default CounterBox;
