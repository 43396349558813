import { useMemo, useState } from "react";

import {
  Badge,
  Box,
  IconButton,
  Chip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import moment from "moment/moment";
import useMeetingStore from "../../Utils/createMeetingStore";
import {
  BUSY_SLOTS_COLOR,
  MODERATE_SLOTS_COLOR,
  OPEN_SLOTS_COLOR,
} from "../../Utils/Constants";
import useStyle from "./styles";

/**
 * Chips that render an x icon in the top right when hovered over to allow a user to deselect a given time
 */
export default function BadgedChip(props) {
  const { createMeeting } = props;
  const [invisible, setInvisible] = useState(true);
  const theme = useTheme();
  const classes = useStyle();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  var startTime = moment(props?.selected.value?.start).format("hh:mm a");
  var endTime = moment(props?.selected.value?.end).format("hh:mm a");
  var startDate = moment(props?.selected.value?.start).format("ddd, MMM D");
  const { organizerMeetings } = useMeetingStore((state) => state);
  // identifier to compare date only
  var startToCompare = moment(props?.selected.value?.start).format(
    "DD-MM-YYYY"
  );
  const [backgroundColor, borderColor] = useMemo(() => {
    let totalMeetings = 0;
    let totalDuration = 0;

    if (organizerMeetings && organizerMeetings[startToCompare]) {
      // total meetings of oraganizer
      totalMeetings = organizerMeetings[startToCompare]?.length;
      //total time
      totalDuration = organizerMeetings[startToCompare].reduce(
        (total, a) =>
          a.duration_minutes ? total + a.duration_minutes : total + 0,
        0
      );
    }

    let backgroundColor = !createMeeting
      ? "#72B0EE80"
      : totalMeetings > 5 || totalDuration > 240
      ? BUSY_SLOTS_COLOR
      : totalMeetings > 3 || totalDuration > 120
      ? MODERATE_SLOTS_COLOR
      : OPEN_SLOTS_COLOR;

    let borderColor =
      backgroundColor === BUSY_SLOTS_COLOR
        ? "#F32D2D"
        : backgroundColor === MODERATE_SLOTS_COLOR
        ? "#FFB545"
        : "#249F5D";
    return [backgroundColor, borderColor];
  }, [organizerMeetings, startToCompare]);

  return (
    <Badge
      color="primary"
      badgeContent={
        <IconButton onClick={() => props.handleRemoveClick(props.id)}>
          <CancelIcon className={classes.chipColor} />
        </IconButton>
      }
      invisible={invisible}
      onMouseOver={() => setInvisible(false)}
      onMouseOut={() => setInvisible(true)}
      className={classes.badge}
    >
      <Box className={classes.flexCenter}>
        <Chip
          label={
            <div>
              <Typography
                className={classes.chipDate}
                variant="h5"
                fontWeight={400}
              >
                {startDate}
              </Typography>
              <Typography
                style={{ color: theme.palette.text.primary }}
                variant="h4"
                fontWeight={400}
              >
                {mobile ? startTime : startTime + " - " + endTime}
              </Typography>{" "}
            </div>
          }
          sx={{
            background: backgroundColor,
            border: !createMeeting
              ? "1px solid #72B0FF"
              : `1px solid ${borderColor}`,
            // background: "#72B0EE80",
            p: { xs: "20px 8px", md: "20px 0px 25px 0px" },
          }}
          className={classes.chip}
          onMouseOut={() => setInvisible(true)}
          onMouseOver={() => setInvisible(false)}
        />
      </Box>
    </Badge>
  );
}
