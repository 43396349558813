import { Grid, Button, CircularProgress } from "@mui/material";
import DialogWithSuccessMessage from "components/DialogWithSuccessMessage";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS,
  ONE_SKEDING_MULTIPLE_NONSKEDING_USERS,
} from "Utils/Constants";
import useStyle from "./styles";

export default function Buttons({
  createMeeting,
  clearForm,
  cancel,
  setCancel,
  disabled,
  selectedSlot,
  mobile,
  eventCase,
  closeModal,
}) {
  const { t } = useTranslation();
  const message = "Are you sure you want to cancel scheduling this meeting?";
  const successMessage = t("cancel_msg");
  const classes = useStyle();
  const handleCancel = () => {
    closeModal();
  };

  const className =
    mobile &&
    (eventCase === MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS ||
      eventCase === ONE_SKEDING_MULTIPLE_NONSKEDING_USERS)
      ? classes.pollingBtn
      : classes.btns;
  return (
    <Grid container justifyContent={"center"} className={className}>
      <Button variant="outlined" sx={{ mr: "40px" }} onClick={handleCancel}>
        {t("cancel")}
      </Button>
      <Button
        variant="contained"
        onClick={() => {
          createMeeting();
        }}
        disabled={selectedSlot.length < 1 || disabled}
        style={{
          padding: "6px 27px",
        }}
      >
        {disabled ? (
          <CircularProgress style={{ color: "white" }} size={20} />
        ) : (
          t("create_meeting_sm")
        )}
      </Button>
      <DialogWithSuccessMessage
        open={cancel}
        setOpen={setCancel}
        operation={clearForm}
        message={message}
        successMessage={successMessage}
      />
    </Grid>
  );
}
