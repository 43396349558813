import React from "react";
import { Grid, Chip, Typography, useMediaQuery, useTheme } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";

import scrollbar from "styles/scrollBar.module.css";
import { useStyles } from "styles/participantChip";
import {
  MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS,
  ONE_NONSKEDING_MULTIPLE_SKEDING_USERS,
  ONE_NONSKEDING_ONE_SKEDING_USER,
  ONE_SKEDING_MULTIPLE_NONSKEDING_USERS,
} from "Utils/Constants";
import { SINGLE_SLOT_MEETING } from "../../Utils/Constants";
import ToolTip from "../Global/ToolTip";
import SkedingParticipants, {
  NonSkedingParticipants,
} from "../Global/Participants";

/**
 * Renders the participants in a meeting invitation
 */
export default function MeetingInvitesParticipants({ event, isPopup = false }) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("lg"));
  const classes = useStyles();

  const particpantsLength =
    event?.nonskedingParticipants?.length + event?.skedingParticipants?.length;

  const getIcon = (participant, index) => {
    const isInvalidDate =
      Object.prototype.toString.call(event?.start) === "[object Date]" &&
      isNaN(event?.start);
    const isNonSkedingOneSkedingUser =
      event?.case === ONE_NONSKEDING_ONE_SKEDING_USER;
    const isNonSkedingMultipleSkedingUsers =
      event?.case === ONE_NONSKEDING_MULTIPLE_SKEDING_USERS;
    const isMultipleSkedingMultipleNonSkedingUsers =
      event?.case === MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS;
    const isOneSkedingMultipleNonSkedingUsers =
      event?.case === ONE_SKEDING_MULTIPLE_NONSKEDING_USERS;
    const isSingleSlotMeeting = event?.case === SINGLE_SLOT_MEETING;

    if (isNonSkedingOneSkedingUser || isNonSkedingMultipleSkedingUsers) {
      return isInvalidDate ? (
        <NotificationsNoneIcon style={{ color: "red" }} />
      ) : event?.nonskedingParticipantsNotResponded?.length === 1 ? (
        <CheckCircleIcon />
      ) : (
        <CancelIcon />
      );
    } else if (
      isMultipleSkedingMultipleNonSkedingUsers ||
      isOneSkedingMultipleNonSkedingUsers
    ) {
      const status = event?.nonskedingParticipantPollingStatus[index];
      return status === 1 ? (
        <CheckCircleIcon />
      ) : status === 0 ? (
        <CancelIcon />
      ) : (
        <NotificationsNoneIcon style={{ color: "red" }} />
      );
    } else if (isSingleSlotMeeting) {
      return !event?.nonskedingParticipantsNotResponded?.includes(
        participant
      ) ? (
        event.nonSkedingParticipantsRejected.includes(participant) ? (
          <CancelIcon />
        ) : (
          <CheckCircleIcon />
        )
      ) : (
        <NotificationsNoneIcon style={{ color: "red" }} />
      );
    } else if (
      event?.nonskedingParticipantsNotResponded?.includes(participant)
    ) {
      return <NotificationsNoneIcon style={{ color: "red" }} />;
    } else {
      return <CheckCircleIcon />;
    }
  };

  return (
    <Grid
      container
      item
      justifyContent={"flex-start"}
      style={{
        overflow: isPopup || mobile ? "auto" : "hidden",
        display: isPopup || mobile ? "flex" : "inline-block",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        flexWrap: isPopup || mobile ? "nowrap" : "wrap",
        textAlign: "start",
      }}
      className={scrollbar.root}
    >
      <SkedingParticipants
        participants={event.skedingParticipants}
        total={particpantsLength}
      />

      <NonSkedingParticipants
        participants={event.nonskedingParticipants}
        total={particpantsLength}
        getIcon={(participant, index) => getIcon(participant, index)}
      />
    </Grid>
  );
}
