import {
  Box,
  Dialog,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import CreateMeeting from "./CreateMeeting";
import Progress from "./Progress";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

/** The final page in the create new meeting flow that displays a calendar
 *  and allows the user to select suggested time slots
 */
export default function Details({
  detailModal,
  closeDetail,
  handleMeetingCreate,
  closeDetailMobile,
  isMobile,
}) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();
  return (
    <ResponsiveWrapper detailModal={detailModal} mobile={mobile}>
      {!mobile && (
        <Box display="flex" justifyContent="flex-end">
          <IconButton sx={{ justifyContent: "flex-end" }} onClick={closeDetail}>
            <CloseIcon />
          </IconButton>
        </Box>
      )}

      <Box>
        <Typography variant="h2" align="center">
          {t("create_meeting")}
        </Typography>
      </Box>
      <Box mt={2} mb={2}>
        <Progress stage={2} t={t} />
      </Box>
      <CreateMeeting
        closeDetail={closeDetail}
        handleMeetingCreate={handleMeetingCreate}
        closeDetailMobile={closeDetailMobile}
        isMobile={isMobile}
      />
    </ResponsiveWrapper>
  );
}

function ResponsiveWrapper({ children, detailModal, mobile }) {
  const location = useLocation();

  return !mobile ? (
    <Dialog
      open={detailModal}
      maxWidth={"lg"}
      fullWidth
      PaperProps={{ sx: { padding: 3, borderRadius: "16px" } }}
    >
      {children}
    </Dialog>
  ) : location.pathname === "/createmeeting" ? (
    <Box>{children}</Box>
  ) : null;
}
