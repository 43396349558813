import { Typography, Box, useTheme } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useTranslation } from "react-i18next";
import useStyle from "./styles";

export const returnToViewAllTeams = (setEditTeam, setSelectedGroup) => {
  setEditTeam(false);
  setSelectedGroup({});
};

// takes a teamName as a prop and links back to the all teams section by clicking the team button
// if no teamName is passed to it it just displays text that says team
export default function TeamBreadCrumbs({
  setEditTeam,
  editTeam,
  selectedGroup,
  setSelectedGroup,
}) {
  const { t } = useTranslation();
  const classes = useStyle();

  return (
    <Box
      display="flex"
      alignItems="center"
      style={{
        marginBottom: "14px",
      }}
    >
      <Typography
        variant="h4"
        className={classes.textCrumb}
        onClick={() => returnToViewAllTeams(setEditTeam, setSelectedGroup)}
      >
        {t("Teams")}
      </Typography>
      {editTeam ? (
        <Box display="flex" alignItems="center">
          <ChevronRightIcon sx={{ color: "#6F727A" }} />
          <Typography variant="h4" className={classes.groupName}>
            {selectedGroup.group_name}
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
}
