import makeStyles from "@mui/styles/makeStyles";

const useStyle = makeStyles((theme) => ({
  checkbox: {
    color: theme.palette.text.primary,
    "&.Mui-checked": {
      color: "#F7F7F9",
      stroke: theme.palette.text.primary,
      strokeWidth: "2px",
      strokeLineJoin: "round",
    },
  },
  cientSideContainer: {
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: "40px",
    [theme.breakpoints.up("md")]: {
      marginTop: "auto",
    },
  },

  servicePreview: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    borderRadius: 16,
    backgroundColor: theme.palette.common.white,
    border: "1px solid #DAD2E5",
    paddingBottom: "3rem",
    paddingTop: 0,
    marginBottom: 16,
    [theme.breakpoints.up("md")]: {
      marginTop: "auto",
      paddingTop: "3rem",
    },
  },

  slotsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "space-between",
    marginTop: 24,
    marginBottom: 24,
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  secondaryText: {
    marginTop: 10,
    color: theme.palette.text.disabled,
  },

  grayText: {
    color: theme.palette.text.disabled,
    textTransform: "uppercase",
  },

  circle: {
    width: 12,
    height: 12,
    border: "1px solid",
    borderRadius: "50%",
    marginRight: 10,
    cursor: "pointer",
  },
  mt10: {
    marginTop: 10,
  },

  removeIcon: {
    color: "red",
    cursor: "pointer",
  },

  serviceBox: {
    background: "#F7F7F9",
    width: "100%",
    textAlign: "left",
    padding: "6px 8px 6px 8px",
    borderRadius: 6,
    gap: 4,
    cursor: "pointer",
    [theme.breakpoints.up("md")]: {
      border: "1px solid #DAD2E5",
      backgroundColor: "transparent",
    },
  },

  borderInput: {
    fontSize: "16px",
    fontWeight: 400,
    color: theme.palette.text.primary,
    border: "1px solid #DAD2E5",
    borderRadius: "6px",
    paddingLeft: "8px",
  },

  checkbox: {
    color: theme.palette.text.primary,
    "& .MuiSvgIcon-root": { fontSize: 12 },
    "&.Mui-checked": {
      color: "#F2F3FF",
      stroke: theme.palette.text.primary,
      strokeWidth: "2px",
      strokeLineJoin: "round",
    },
  },
  questionsBox: {
    backgroundColor: theme.palette.secondary.light,
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    padding: "24px",
    borderRadius: 6,
    // border: "1px solid #DAD2E5",
  },

  typeBox: {
    backgroundColor: theme.palette.secondary.light,
    padding: "6px 8px 6px 8px",
    borderRadius: 6,
    textAlign: "left",
    cursor: "pointer",
  },

  serviceMainContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    alignItems: "flex-start",
  },

  // serviceBox: {
  //   // backgroundColor: theme.palette.common.white,
  //   padding: "6px 8px 6px 8px",
  //   borderRadius: 6,
  //   textAlign: "left",
  //   border: "1px solid #DAD2E5",
  //   width: "100%",
  // },

  bookingPreview: {
    backgroundColor: theme.palette.common.white,
    padding: "12px",
    [theme.breakpoints.up("md")]: {
      maxWidth: "60%",
      margin: "auto",
      borderRadius: 6,
    },
  },
  serviceContainer: {
    width: "80%",
    [theme.breakpoints.up("md")]: {
      width: "600px",
    },
  },

  addServiceContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
  },
  iconButton: {
    border: "none",
    color: "#479AE9",
  },

  topContainer: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    overflow: "auto",
    height: "80vh",
  },

  genericContainer: {
    flexDirection: "column",
    alignItems: "flex-start",
  },

  boxContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },

  showTimes: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginTop: "8px",
  },

  availability: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },

  workingHrDesc: {
    textAlign: "left",
    fontWeight: 400,
    color: theme.palette.info.light,
  },

  timeContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: "16px",
    marginBottom: "15px",
    gap: 8,
  },

  time: {
    marginRight: "10px",
    fontWeight: "400",
    textAlign: "left",
  },

  timePicker: {
    position: "relative",
    fontSize: "14px",
    fontWeight: "400",
  },

  buttonContainer: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "center",
      margin: "32px 0",
      width: "auto",
    },
  },

  cancelButton: {
    marginRight: "20px",
    width: "107px",
    padding: "2px 0",
  },

  lockedBox: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1,
  },

  addServiceQuestionContainer: {
    borderRadius: 15,
    border: "1px solid #E0E2E7",
    padding: 16,
    display: "flex",
    justifyContent: "left",
    alignItems: "flex-start",
    flexDirection: "column",
    gap: 16,
  },

  questionOption: {
    backgroundColor: "#F7F7F9",
    display: "flex",
    borderRadius: 6,
    padding: "12px 8px",
    gap: 8,
    alignItems: "center",
    width: "95%",
    cursor: "pointer",
  },

  checkboxContainer: {
    display: "flex",
    fontWeight: 400,
    alignItems: "center",
  },

  buttonGroup: {
    display: "flex",
    justifyContent: "center",
    gap: 16,
  },
  w100: {
    width: "100%",
  },

  locationContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
    marginTop: 16,
  },
  profilePic: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #E0E2E7",
    padding: 16,
    borderRadius: "50%",
    cursor: "pointer",
  },
  label: {
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
  },
  img: {
    width: "80px",
    height: "80px",
    borderRadius: "50%",
  },

  badge: {
    "& .MuiBadge-badge": {
      backgroundColor: "#F7F7F9",
      width: "11%",
    },
  },
  badgeIcon: {
    background: "#96DCFF",
    width: "18px",
    height: "18px",
  },
  questionBox: {
    backgroundColor: "#F7F7F9",
    display: "flex",
    flexDirection: "column",
    borderRadius: 6,
    padding: "4px 8px",
    gap: 8,
    alignItems: "flex-start",
    width: "95%",
    cursor: "pointer",
    textAlign: "left",
  },
  questionBoxRow: {
    backgroundColor: "#F7F7F9",
    display: "flex",
    borderRadius: 6,
    padding: "4px 8px",
    gap: 6,
    alignItems: "flex-start",
    width: "95%",
    cursor: "pointer",
    textAlign: "left",
  },
  locationCard: {
    backgroundColor: "#96DCFF40",
    borderRadius: 8,
    minHeight: "140px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    gap: 12,
    paddingTop: "12px",
    paddingBottom: "12px",
  },

  iconTextBox: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },

  textWrap: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginLeft: 5,
    fontWeight: "400",
    whiteSpace: "nowrap",
    textAlign: "left",
  },

  createInput: {
    fontSize: "16px",
    fontWeight: 400,
    color: theme.palette.text.primary,
    border: "1px solid #DAD2E5",
    borderRadius: "6px",
    paddingLeft: "8px",
  },
  textarea: {
    width: "100%",
    padding: "12px",
    boxSizing: "border-box",
    outline: "none",
    border: "1px solid #DAD2E5",
    background: "transparent",
    "&:focus": {
      outline: "none",
      borderColor: "#DAD2E5",
    },
    "&::placeholder": {
      color: "#b6b8b8",
    },
  },
}));

export default useStyle;
