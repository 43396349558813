import React from "react";
import {
  Box,
  Button,
  Typography,
  Dialog,
  CircularProgress,
} from "@mui/material";
import { useState } from "react";
import { serverGET } from "Utils/HttpFunctions";
import ErrorSnackbar from "components/ErrorSnackbar";
import SnackbarComponent from "../Global/SnackbarComponent";
import { useTranslation } from "react-i18next";

/**
 * Dialog for importing a user's contact from one of the OAuth providers (Google/Micorosoft)
 */
export default function ImportDialogue({
  importModal,
  setImportModal,
  getContacts,
  handleCreateContactDialog,
}) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { t } = useTranslation();
  const [snackbar, setSnackbar] = useState(false);
  const [message, setMessage] = useState("");
  const handleClose = () => {
    setImportModal((prev) => !prev);
  };
  const handleProceed = async () => {
    setLoading(true);
    setError("");
    const response = await serverGET(`/contact/external`);
    if (response && response.constructor !== String) {
      setTimeout(async () => {
        await getContacts(0, undefined);
        setLoading(false);
        handleClose();
        handleCreateContactDialog();
        setSnackbar(true);
        setMessage(t("snackbar_messages.2"));
      }, 5000);
    } else {
      setError("Something went wrong while importing contacts");
      setLoading(false);
      handleClose();
    }
  };

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={importModal}
        scroll="body"
        maxWidth="xs"
        fullWidth
        PaperProps={{
          style: {
            borderRadius: 8,
            textAlign: "center",
            padding: 16,
            boxSizing: "border-box",
          },
        }}
      >
        <ErrorSnackbar
          open={!!error}
          handleClose={() => setError("")}
          message={error}
          vertical={"bottom"}
          horizontal={"center"}
        />
        <Typography
          variant="h2"
          style={{ paddingTop: "20px", paddingBottom: "8px" }}
        >
          Update Contact
        </Typography>
        <Typography
          variant="h3"
          fontWeight={400}
          style={{ paddingBottom: "20px" }}
        >
          This will import all your calendar contacts with valid emails
        </Typography>
        <Box mb={"10px"}>
          <Button
            style={{
              marginRight: 20,
            }}
            variant="outlined"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            style={{
              marginLeft: 20,
            }}
            onClick={handleProceed}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress style={{ color: "white" }} size={20} />
            ) : (
              "Proceed"
            )}
          </Button>
        </Box>
      </Dialog>
      <SnackbarComponent
        open={snackbar}
        handleClose={() => setSnackbar(false)}
        message={message}
        vertical={"bottom"}
        horizontal={"center"}
        // deleteUnsuccessful={unsuccessful}
      />
    </>
  );
}
