import { Grid, Button, Box, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import useStyle from "./styles";
/**
 * Buttons for changing the view on any of the FullCalendar components between day/week/month
 */

export default function CalendarViewButtons({
  calendarRef,
  setSelectedBtn,
  selectedBtn,
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const classes = useStyle();
  /**
   * Moves the calendar to the day clicked on the calendar navigation buttons
   */
  const handleDayView = () => {
    calendarRef?.current?.getApi()?.changeView("timeGridDay");
    setSelectedBtn(1);
  };

  /**
   * Moves the calendar to the week clicked on the calendar navigation buttons
   */
  const handleWeekView = () => {
    calendarRef?.current?.getApi()?.changeView("timeGridWeek");
    setSelectedBtn(2);
  };

  /**
   * Moves the calendar to the month clicked on the calendar navigation buttons
   */
  const handleMonthView = () => {
    calendarRef?.current?.getApi()?.changeView("dayGridMonth");
    setSelectedBtn(3);
  };

  return (
    <Grid
      container
      item
      xs={9}
      sx={{
        justifyContent: { xs: "center", md: "flex-end" },
      }}
    >
      <Box className={classes.calBtnBox}>
        <Button
          className={classes.calBtn}
          onClick={handleDayView}
          variant={selectedBtn === 1 ? "selected" : "unselected"}
        >
          {t("day")}
        </Button>
        <Button
          className={classes.calBtn}
          onClick={handleWeekView}
          variant={selectedBtn === 2 ? "selected" : "unselected"}
          id="calendar-week"
        >
          {t("week")}
        </Button>
        <Button
          className={classes.calBtn}
          onClick={handleMonthView}
          variant={selectedBtn === 3 ? "selected" : "unselected"}
        >
          {t("month")}
        </Button>
      </Box>
    </Grid>
  );
}
