import React, { useContext, useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  IconButton,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Facebook from "assets/facebook.svg";
import LinkedIn from "assets/linkedin.svg";
import Instagram from "assets/instagram.svg";
import Twitter from "assets/twitter.svg";
import Youtube from "assets/youtube.svg";
import Snapchat from "assets/snapchat.svg";
import Tiktok from "assets/tiktok.svg";
import Shopify from "assets/shopify.svg";
import { serverPOST, serverGET, serverPUT } from "Utils/HttpFunctions";
import { accountContext } from "context/user";
import EditProfilePicture from "./EditProfilePicture";
import { SocialLink } from "./SocialLink";
import { QRInput } from "./QRInput";
import Phone from "../NewEvent/Phone";
import { ReactComponent as EyeVisible } from "assets/eye_visible.svg";
import { ReactComponent as EyeHidden } from "assets/eye_hidden.svg";
import { AiOutlinePlusCircle } from "react-icons/ai";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { isValidPhoneNumber } from "react-phone-number-input";
import ErrorSnackbar from "components/ErrorSnackbar";
import useStyle from "./styles";

export default function QRCodeInformation({ open, handleClose, t, setQROpen }) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const { user } = useContext(accountContext);
  const [selectedSocials, setSelectedSocials] = useState([]);
  const [phoneNumberList, setPhoneNumberList] = useState([
    { value: "", is_hidden: false },
  ]);
  const [emailList, setEmailList] = useState([]);
  const [formData, setFormData] = useState(() => {
    setEmailList([{ value: user?.email, is_hidden: false }]);

    return {
      name: user?.name,
      email: [
        {
          value: user?.email,
          is_hidden: false,
        },
      ],
      mobile_phone: [
        {
          value: "",
          is_hidden: false,
        },
      ],
      personal_website: {
        value: "",
        is_hidden: false,
      },
      street: {
        value: "",
        is_hidden: false,
      },
      city: {
        value: "",
        is_hidden: false,
      },
      state: {
        value: "",
        is_hidden: false,
      },
      postal_code: {
        value: "",
        is_hidden: false,
      },
      country: {
        value: "",
        is_hidden: false,
      },
      company_name: {
        value: "",
        is_hidden: false,
      },
      company_role: {
        value: "",
        is_hidden: false,
      },
      fb_link: {
        value: "",
        is_hidden: false,
      },
      linkedin_link: {
        value: "",
        is_hidden: false,
      },
      insta_link: {
        value: "",
        is_hidden: false,
      },
      twitter_link: {
        value: "",
        is_hidden: false,
      },
      youtube_link: {
        value: "",
        is_hidden: false,
      },
      snapchat_link: {
        value: "",
        is_hidden: false,
      },
      tiktok_link: {
        value: "",
        is_hidden: false,
      },
      shopify_link: {
        value: "",
        is_hidden: false,
      },
      user_id: {
        _id: "",
        picture: "",
      },
    };
  });
  const [error, setError] = useState("");
  const classes = useStyle();

  useEffect(() => {
    const getQRCodeInfo = async () => {
      const response = await serverGET(`/qrCodeInfo`);

      if (response && response.constructor !== String) {
        if (response.code === 200) {
          const data = response.data;
          const socials = [];
          setFormData(data);
          let tempPhones = [];

          data.mobile_phone.map((mp) => {
            tempPhones.push({ value: mp.value, is_hidden: mp.is_hidden });
          });
          setPhoneNumberList(tempPhones);

          let tempEmails = [];

          data.email.map((em) => {
            tempEmails.push({ value: em.value, is_hidden: em.is_hidden });
          });
          setEmailList(tempEmails);

          if (data.fb_link.value)
            socials.push({
              name: "facebook",
              logo: Facebook,
              formName: "fb_link",
            });
          if (data.linkedin_link.value)
            socials.push({
              name: "linkedIn",
              logo: LinkedIn,
              formName: "linkedin_link",
            });
          if (data.insta_link.value)
            socials.push({
              name: "instagram",
              logo: Instagram,
              formName: "insta_link",
            });
          if (data.twitter_link.value)
            socials.push({
              name: "twitter",
              logo: Twitter,
              formName: "twitter_link",
            });

          if (data.youtube_link.value)
            socials.push({
              name: "youtube",
              logo: Youtube,
              formName: "youtube_link",
            });
          if (data.snapchat_link.value)
            socials.push({
              name: "snapchat",
              logo: Snapchat,
              formName: "snapchat_link",
            });
          if (data.tiktok_link.value)
            socials.push({
              name: "tiktok",
              logo: Tiktok,
              formName: "tiktok_link",
            });
          if (data.shopify_link.value)
            socials.push({
              name: "shopify",
              logo: Shopify,
              formName: "shopify_link",
            });
          setSelectedSocials(socials);
        } else {
          setEmailList([{ value: user?.email, is_hidden: false }]);
          setFormData({
            ...formData,
            email: { ...formData.email, value: user?.email },
            name: user?.name,
          });
        }
      }
    };
    getQRCodeInfo();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      setFormData({
        ...formData,
        [name]: value,
      });
    } else {
      setFormData({
        ...formData,
        [name]: { ...formData[name], value: value },
      });
    }
  };

  const handleQRSocialClick = (socialName, Logo, formName) => {
    if (selectedSocials.find((item) => item.formName === formName)) {
      setSelectedSocials(
        selectedSocials.filter((item) => item.formName !== formName)
      );
      // setFormData({ ...formData, [formName]: "" });
      setFormData({
        ...formData,
        [formName]: { ...formData[formName], value: "" },
      });
    } else {
      setSelectedSocials([
        ...selectedSocials,
        {
          name: socialName,
          logo: Logo,
          formName: formName,
        },
      ]);
    }
  };

  const handleHideShow = (name) => {
    if (name === "location") {
      const oldVal = formData.street.is_hidden;
      setFormData({
        ...formData,
        street: { ...formData.street, is_hidden: !oldVal },
        city: { ...formData.city, is_hidden: !oldVal },
        state: { ...formData.state, is_hidden: !oldVal },
        postal_code: { ...formData.postal_code, is_hidden: !oldVal },
        country: { ...formData.country, is_hidden: !oldVal },
      });
    } else {
      const oldVal = formData[name]?.is_hidden;
      setFormData({
        ...formData,
        [name]: { ...formData[name], is_hidden: !oldVal },
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isInvalidNumber = false;
    phoneNumberList.map((phone) => {
      if (!isValidPhoneNumber(phone.value)) {
        setError(t("phone_err"));
        isInvalidNumber = true;
      }
    });
    if (isInvalidNumber) return;

    if (formData.user_id._id === "") {
      delete formData["user_id"];
      const response = await serverPOST("/qrCodeInfo", {
        ...formData,
        mobile_phone: phoneNumberList,
        email: emailList.filter((x) => x.value !== ""),
      });
      if (response && response.constructor !== String) {
        setQROpen(true);
        handleClose();
      }
    } else {
      const codeId = formData.user_id._id;
      delete formData["user_id"];
      const response = await serverPUT(`/qrCodeInfo/${codeId}`, {
        ...formData,
        mobile_phone: phoneNumberList,
        email: emailList.filter((x) => x.value !== ""),
      });
      if (response && response.constructor !== String) {
        setQROpen(true);
        handleClose();
      }
    }
  };

  const handlePhoneNumberList = (val, index, colName) => {
    let temp_list = [...phoneNumberList];
    let current_number = temp_list[index];
    current_number[colName] = val;

    setPhoneNumberList(temp_list);
  };

  const handleEmailList = (e, index) => {
    let temp_list = [...emailList];
    let current_email = temp_list[index];
    current_email.value = e.target?.value;
    setEmailList(temp_list);
  };

  const handleEmailDelete = (i) => {
    let emailListTemp = [...emailList];
    emailListTemp.splice(i, 1);
    setEmailList(emailListTemp);
  };

  const handlePhoneDelete = (i) => {
    let phoneListTemp = [...phoneNumberList];
    phoneListTemp.splice(i, 1);
    setPhoneNumberList(phoneListTemp);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{ justifyContent: "center" }}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: {
            padding: "40px 30px",
            overflow: "auto",
          },
        }}
      >
        <Box display="flex" justifyContent="flex-end" width="100%">
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogActions
          sx={{
            justifyContent: "flex-end",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Grid
            item
            display={"flex"}
            direction="column"
            alignItems={"center"}
            textAlign={"center"}
            gap={1}
            mb={4}
          >
            <Typography variant="h2">{t("add_details")}</Typography>
            <Typography variant="h4" fontWeight={400} color="#70757A">
              {t("add_details_desc")}
            </Typography>
          </Grid>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              display={"flex"}
              direction="column"
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Typography variant="h3">{t("personal_information")}</Typography>
              <Box
                display={"flex"}
                style={{ width: "100%" }}
                justifyContent="space-between"
                alignItems="center"
                mb={4}
                gap={1}
              >
                <Grid container gap={2} direction={"column"}>
                  <QRInput
                    title={t("name") + "*"}
                    value={formData.name}
                    name={"name"}
                    handleChange={handleChange}
                    isRequired={true}
                    placeholder={"Enter name"}
                  />

                  <Grid item display={"flex"} direction={"column"} gap={1}>
                    {emailList.map((email, index) => {
                      return (
                        <Box
                          display="flex"
                          alignItems="flex-end"
                          style={{
                            width: "100%",
                          }}
                        >
                          <QRInput
                            title={`${t("email")}${index === 0 ? "*" : ""}`}
                            value={email}
                            name={"email"}
                            handleChange={handleEmailList}
                            isRequired={index === 0 ? true : false}
                            placeholder={"Enter email"}
                            type={"email"}
                            index={index}
                          />
                          {index === 0 ? null : (
                            <IconButton
                              aria-label="delete"
                              onClick={() => handleEmailDelete(index)}
                            >
                              <DeleteOutlineOutlinedIcon
                                className={classes.delIcon}
                              />
                            </IconButton>
                          )}
                        </Box>
                      );
                    })}
                    <Typography
                      variant="h3"
                      color={"#479AE9"}
                      className={classes.addText}
                      onClick={() =>
                        setEmailList([
                          ...emailList,
                          { value: "", is_hidden: false },
                        ])
                      }
                    >
                      <AiOutlinePlusCircle style={{ marginRight: "5px" }} />
                      {t("add_email")}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container xs={12} pl={0} justifyContent="flex-end">
                  <EditProfilePicture mobile={mobile} />
                </Grid>
              </Box>
            </Grid>

            <Grid
              item
              display={"flex"}
              direction="column"
              alignItems={"center"}
              justifyContent={"center"}
              gap={1}
              mb={4}
              width="100%"
            >
              <Typography variant="h3">{t("contact_details")}</Typography>
              <Grid container gap={1} justifyContent={"space-between"}>
                {/* Add phone numbers array here */}
                <Grid item display={"flex"} direction={"column"} gap={1}>
                  {phoneNumberList.map((phoneNumber, index) => {
                    return (
                      <Grid item display="flex" direction={"column"} gap={0.5}>
                        <Typography variant="h4" width={"60%"}>
                          {t("phone_number")}
                        </Typography>
                        <Grid
                          item
                          display="flex"
                          alignItems="center"
                          justifyContent={"center"}
                          width={index === 0 ? "255px" : "265px"}
                        >
                          <Phone
                            showBorder={true}
                            phone={phoneNumber.value}
                            setPhone={(e) =>
                              handlePhoneNumberList(e, index, "value")
                            }
                            isDisabled={phoneNumber.is_hidden}
                          />
                          {phoneNumber.is_hidden ? (
                            <EyeHidden
                              style={{
                                marginLeft: index === 0 ? "" : "8px",
                              }}
                              className={classes.eyeIcon}
                              onClick={() =>
                                handlePhoneNumberList(false, index, "is_hidden")
                              }
                            />
                          ) : (
                            <EyeVisible
                              style={{
                                paddingLeft: index === 0 ? "" : "10px",
                              }}
                              className={classes.eyeIcon}
                              onClick={() =>
                                handlePhoneNumberList(true, index, "is_hidden")
                              }
                            />
                          )}
                          {index === 0 ? null : (
                            <IconButton
                              aria-label="delete"
                              onClick={() => handlePhoneDelete(index)}
                            >
                              <DeleteOutlineOutlinedIcon
                                className={classes.delIcon}
                              />
                            </IconButton>
                          )}
                        </Grid>
                      </Grid>
                    );
                  })}
                  <Typography
                    variant="h3"
                    color={"#479AE9"}
                    className={classes.addText}
                    onClick={() =>
                      setPhoneNumberList([
                        ...phoneNumberList,
                        { value: "", is_hidden: false },
                      ])
                    }
                  >
                    <AiOutlinePlusCircle style={{ marginRight: "5px" }} />
                    {t("add_phone")}
                  </Typography>
                </Grid>

                <QRInput
                  title={t("personal_website")}
                  value={formData.personal_website}
                  name={"personal_website"}
                  handleChange={handleChange}
                  placeholder="https://www.mywebiste.com"
                  width={"60%"}
                  type={"url"}
                  handleHideShow={handleHideShow}
                />
              </Grid>
            </Grid>

            <Grid
              item
              display={"flex"}
              direction="column"
              alignItems={"center"}
              justifyContent={"center"}
              gap={1}
              mb={4}
              width="100%"
            >
              <Grid item display={"flex"} alignItems={"center"} gap={1}>
                <Typography variant="h3">{t("address")}</Typography>
                {formData.street?.is_hidden ? (
                  <EyeHidden
                    className={classes.eyeIcon}
                    onClick={() => handleHideShow("location")}
                  />
                ) : (
                  <EyeVisible
                    className={classes.eyeIcon}
                    onClick={() => handleHideShow("location")}
                  />
                )}
              </Grid>
              <Grid container justifyContent={"space-between"} gap={0.5}>
                <Grid item xs={7.5}>
                  <QRInput
                    title={t("address_line")}
                    value={formData.street}
                    name={"street"}
                    handleChange={handleChange}
                    placeholder={t("enter_location")}
                    handleHideShow={handleHideShow}
                  />
                </Grid>
                <Grid item xs={4}>
                  <QRInput
                    title={t("city")}
                    value={formData.city}
                    name={"city"}
                    handleChange={handleChange}
                    placeholder="e.g New York City"
                    handleHideShow={handleHideShow}
                  />
                </Grid>
              </Grid>

              <Grid container gap={2} flexWrap={"nowrap"}>
                <QRInput
                  title={t("state")}
                  value={formData.state}
                  name={"state"}
                  handleChange={handleChange}
                  placeholder="e.g New York"
                  handleHideShow={handleHideShow}
                />

                <QRInput
                  title={t("postal_code")}
                  value={formData.postal_code}
                  name={"postal_code"}
                  handleChange={handleChange}
                  placeholder="e.g 10004"
                  width={"50%"}
                  handleHideShow={handleHideShow}
                />

                <QRInput
                  title={t("country")}
                  value={formData.country}
                  name={"country"}
                  handleChange={handleChange}
                  placeholder="e.g United States"
                  handleHideShow={handleHideShow}
                />
              </Grid>
            </Grid>

            <Grid
              item
              display={"flex"}
              direction="column"
              alignItems={"center"}
              justifyContent={"center"}
              gap={1}
              mb={4}
              width="100%"
            >
              <Typography variant="h3">{t("company_details")}</Typography>
              <Grid container gap={2} justifyContent={"space-between"}>
                <QRInput
                  title={t("name")}
                  value={formData.company_name}
                  name={"company_name"}
                  handleChange={handleChange}
                  placeholder="My Company"
                  width={"60%"}
                  handleHideShow={handleHideShow}
                />

                <QRInput
                  title={t("designation")}
                  value={formData.company_role}
                  name={"company_role"}
                  handleChange={handleChange}
                  placeholder="Editor"
                  handleHideShow={handleHideShow}
                />
              </Grid>
            </Grid>

            <Grid
              item
              display={"flex"}
              direction="column"
              alignItems={"center"}
              justifyContent={"center"}
              gap={1}
              mb={4}
              width="100%"
            >
              <Typography variant="h3">{t("social_networks")}</Typography>
              <Grid
                item
                display={"flex"}
                direction={"row"}
                alignItems={"center"}
                justifyContent={"center"}
                width={"100%"}
                gap={2}
              >
                <img
                  src={Facebook}
                  alt="facebook"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    handleQRSocialClick("facebook", Facebook, "fb_link")
                  }
                />
                <img
                  src={LinkedIn}
                  alt="linkedIn"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    handleQRSocialClick("linkedIn", LinkedIn, "linkedin_link")
                  }
                />
                <img
                  src={Instagram}
                  alt="instagram"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    handleQRSocialClick("instagram", Instagram, "insta_link")
                  }
                />
                <img
                  src={Twitter}
                  alt="twitter"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    handleQRSocialClick("twitter", Twitter, "twitter_link")
                  }
                />

                <img
                  src={Youtube}
                  alt="youtube"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    handleQRSocialClick("youtube", Youtube, "youtube_link")
                  }
                />
                <img
                  src={Snapchat}
                  alt="snapchat"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    handleQRSocialClick("snapchat", Snapchat, "snapchat_link")
                  }
                />
                <img
                  src={Tiktok}
                  alt="tiktok"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    handleQRSocialClick("tiktok", Tiktok, "tiktok_link")
                  }
                />
                <img
                  src={Shopify}
                  alt="shopify"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    handleQRSocialClick("shopify", Shopify, "shopify_link")
                  }
                />
              </Grid>
            </Grid>

            {selectedSocials.map((social) => {
              return (
                <Box marginBottom={"8px"}>
                  <SocialLink
                    SocialLogo={social.logo}
                    socialName={social.name}
                    handleChange={handleChange}
                    formName={social.formName}
                    formData={formData}
                    handleHideShow={handleHideShow}
                    isHidden={formData[social.formName].is_hidden}
                  />
                </Box>
              );
            })}

            <Grid container gap={2} justifyContent={"center"}>
              <Button
                onClick={handleClose}
                variant="cancel"
                sx={{ mt: "30px" }}
              >
                {t("close")}
              </Button>
              <Button type="submit" variant="contained" sx={{ mt: "30px" }}>
                {t("generate_qr_code")}
              </Button>
            </Grid>
          </form>
        </DialogActions>
        <ErrorSnackbar
          open={!!error}
          handleClose={() => setError("")}
          message={error}
          vertical={"bottom"}
          horizontal={"center"}
        />
      </Dialog>
    </>
  );
}
