import React, { useEffect, useState } from "react";

import {
  Box,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import { useTranslation } from "react-i18next";

/**
 * Component for using a custom time interval in a meeting creation
 */
export default function CustomInterval({
  setCustomTime,
  customTime,
  showDropDown,
  notNewMeeting,
  isTodo,
  updateTodo,
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const [customHours, setCustomHours] = useState("01");
  const [customMinutes, setCustomMinutes] = useState("00");
  const [isUpdate, setIsUpdate] = useState(true);
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (customTime) {
      setCustomHours(customTime.split(":")[0]);
      setCustomMinutes(customTime.split(":")[1]);
    }
  }, [customTime]);

  const handleDone = () => {
    setIsUpdate(false);
    if (
      (!customHours && !customMinutes) ||
      (!customHours && customMinutes === "00") ||
      (!customHours && customMinutes === "0") ||
      (!customMinutes && customHours === "00") ||
      (!customMinutes && customHours === "0") ||
      (customMinutes === "00" && customHours === "00") ||
      (customMinutes === "0" && customHours === "0") ||
      (customMinutes === "00" && customHours === "0") ||
      (customMinutes === "0" && customHours === "00")
    ) {
      setCustomHours("01");
      setCustomMinutes("00");
      setCustomTime("01:00:00");
      return;
    }
    if (!customHours) {
      setCustomTime("00:" + customMinutes + ":" + "00");
      return;
    }
    if (!customMinutes) {
      setCustomTime(customHours + ":" + "00:" + "00");
      return;
    }

    setCustomTime(customHours + ":" + customMinutes + ":" + "00");
    if (isTodo) {
      updateTodo(customHours + ":" + customMinutes + ":" + "00");
    }
  };

  const handleClear = () => {
    setCustomTime("");
    setCustomHours("");
    setCustomMinutes("");
  };

  const handleHourChange = (e) => {
    const regex = /^[0-9\b]+$/;
    let constraint = !!parseInt(e.target.value);

    if (
      (e.target.value == "" || regex.test(e.target.value)) &&
      e.target.value.length < 3
    ) {
      setIsUpdate(true);
      if (constraint) {
        if (parseInt(e.target.value) < 12) {
          setCustomHours(e.target.value);
        }
      } else {
        setCustomHours(e.target.value);
      }
    }
  };

  const handleMinuteChange = (e) => {
    const regex = /^[0-9\b]+$/;
    let constraint = !!parseInt(e.target.value);
    if (
      (e.target.value == "" || regex.test(e.target.value)) &&
      e.target.value.length < 3
    ) {
      setIsUpdate(true);
      if (constraint) {
        if (parseInt(e.target.value) < 60) {
          setCustomMinutes(e.target.value);
        }
      } else {
        setCustomMinutes(e.target.value);
      }
    }
  };

  return (
    <Box style={{ marginTop: 10 }} display="flex" alignItems={"center"}>
      <TextField
        placeholder="00"
        variant="standard"
        autoComplete="off"
        value={customHours}
        sx={{
          width: notNewMeeting ? "100%" : "34px",
        }}
        autoFocus
        inputProps={{
          maxLength: 2,
        }}
        InputProps={{
          disableUnderline: true,
          style: {
            color: theme.palette.text.primary,
            fontSize: isTodo && "10px",
          },
        }}
        onChange={handleHourChange}
        id="create-form"
      />
      <Typography variant="h5" style={{ margin: "0px 16px 0px 8px" }}>
        {t("hr")}
      </Typography>
      {/* {!showDropDown ? (
        <> */}
      <TextField
        placeholder="00"
        variant="standard"
        autoComplete="off"
        value={customMinutes}
        sx={{ width: "34px" }}
        autoFocus
        inputProps={{
          maxLength: 2,
        }}
        InputProps={{
          disableUnderline: true,
          style: {
            // fontSize: "12px",
            fontWeight: 400,
            color: theme.palette.text.primary,
            fontSize: isTodo && "10px",
          },
        }}
        onChange={handleMinuteChange}
        id="create-form"
      />
      <Typography
        variant="h5"
        style={{ margin: mobile ? "0px 0px 0px 4px" : "0px 16px 0px 8px" }}
      >
        {t("min")}
      </Typography>

      {isUpdate && (
        <Box>
          <IconButton aria-label="done" onClick={handleDone} size="small">
            <DoneIcon
              fontSize="inherit"
              style={{
                fontSize: isTodo && "10px",
              }}
            />
          </IconButton>

          <IconButton size="small" aria-label="clear" onClick={handleClear}>
            <ClearIcon
              fontSize="inherit"
              style={{
                fontSize: isTodo && "10px",
              }}
            />
          </IconButton>
        </Box>
      )}
    </Box>
  );
}
