import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { httpGetUnsecure, serverPUT } from "../../Utils/HttpFunctions";
import { authContext } from "../../context/Auth";
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useTheme,
} from "@mui/material";
import Invitee from "../../components/meetings/Invitee";
import moment from "moment";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useTranslation } from "react-i18next";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import useStyle from "./styles";
import RejectComment from "../../components/RejectComment";
import loggingInDev from "../../loggingInDev";
import Languages from "../../components/Account/Languages";
import Spinner from "../../components/Global/Spinner";
import DialogBox from "../../components/DialogBox";
import SkedingBanner from "../../components/SkedingBanner";
import SnackbarComponent from "../../components/Global/SnackbarComponent";

export default function WebsiteBookingParticipant() {
  const [searchParams] = useSearchParams();
  const meetingId = searchParams.get("meetingId");
  const userId = searchParams.get("userId");
  const [meetingData, setMeetingData] = useState<any>(null);
  const { isLogged } = useContext(authContext);
  const theme = useTheme();
  const [rejectConfirm, setRejectConfirm] = useState(false);
  const [cancelMeeting, setCancelMeeting] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const locationColor = meetingData?.location.includes("http")
    ? "#479AE9"
    : theme.palette.text.primary;
  const { t } = useTranslation();
  const classes = useStyle();
  const [fetchError, setFetchError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    getMeeting();
  }, [isLogged]);

  const handleRejectConfirm = () => {
    setRejectConfirm((prev) => !prev);
  };

  const handleRejectClick = async (comment) => {
    setCancelMeeting(true);
    handleRejectConfirmation(!!comment.trim() ? comment : undefined);
  };

  const handleRejectConfirmation = async (comment) => {
    setError("");
    setDisabled(true);
    const data = {
      meeting_id: meetingData._id,
      response: 0,
      to_cancel: true,
      time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      utc_offset: new Date().getTimezoneOffset(),
    };
    const response = await serverPUT(
      `/meeting/${meetingData._id}?userId=${userId}`,
      data
    );
    if (response && response.constructor !== String) {
      loggingInDev("rejected ==>", response);
    } else {
      loggingInDev("rejected else ==>", response);
    }

    if (!error) {
      setSuccess("Your meeting has been cancelled");
    }
    setDisabled(false);
  };

  const getMeeting = async () => {
    setLoading(true);
    const response = await httpGetUnsecure(
      `/meeting/${meetingId}?userId=${userId}`
    );
    if (response && response.constructor !== String) {
      setMeetingData(response.data);
    } else {
      setFetchError(t("snackbar_messages.6"));
      console.log("Oops something went wrong");
    }
    setLoading(false);
  };

  if (!!fetchError) {
    return (
      <DialogBox
        modal={!!fetchError}
        handleClose={() => setFetchError("")}
        close={false}
        msg={fetchError}
        closeMsg={undefined}
        nonsignup={false}
      />
    );
  }

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Box className={classes.mainContainer}>
            <Box className={classes.meetingContainer}>
              <Typography variant="h2" textAlign={"center"}>
                {t("meeting_details")}
              </Typography>
              <Typography variant="h3" sx={{ textTransform: "capitalize" }}>
                {meetingData?.title}
              </Typography>

              <Box className={classes.iconBox}>
                <Box>
                  <AccessTimeIcon className={classes.timeIcon} />
                </Box>
                <Box>
                  <Box style={{ color: theme.palette.text.primary }}>
                    <Typography variant="h3" fontWeight={400}>
                      {meetingData?.start_datetime
                        ? moment(meetingData?.start_datetime).format(
                            "ddd, DD MMM, h:mm A"
                          ) +
                          " " +
                          meetingData?.duration_minutes +
                          " minutes "
                        : moment(meetingData?.createdAt).format("LT")}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box className={classes.iconBox}>
                <Box>
                  <LocationOnOutlinedIcon className={classes.timeIcon} />
                </Box>
                <Typography
                  variant="h3"
                  fontWeight={400}
                  sx={{
                    color: locationColor,
                    "&:hover": {
                      cursor: "pointer",
                    },
                  }}
                  style={{ wordWrap: "break-word" }}
                >
                  {locationColor !== "#479AE9" ? (
                    meetingData?.location
                  ) : (
                    <Link to={meetingData?.location} target="_blank">
                      {meetingData?.location}
                    </Link>
                  )}
                </Typography>

                {meetingData?.meeting_room && (
                  <Typography variant="h4" fontWeight={400}>
                    <span>{t("room")}: </span>
                    {meetingData?.meeting_room}
                  </Typography>
                )}
              </Box>

              <Invitee event={meetingData} />
              <Box className={classes.boxContainer}>
                <Button variant="outlined" onClick={handleRejectConfirm}>
                  {t("cancel_meeting")}
                </Button>
                <Button
                  variant="contained"
                  onClick={() =>
                    navigate(
                      `/public-calendar?meetingId=${meetingId}&userId=${userId}`
                    )
                  }
                >
                  {t("reschedule")}
                </Button>
              </Box>
            </Box>

            <RejectComment
              rejectConfirm={rejectConfirm}
              handleRejectConfirm={handleRejectConfirm}
              handleReject={handleRejectClick}
              isInitiator={true}
              loading={disabled}
            />
          </Box>
          <Box className={classes.languageContainer}>
            <SkedingBanner removePadding={true} />

            <Languages small={undefined} showStyles={undefined} />
          </Box>
        </>
      )}
      <SnackbarComponent
        open={!!success}
        handleClose={() => {
          setSuccess("");
          navigate("/login");
        }}
        message={success}
        vertical={"bottom"}
        horizontal={"center"}
      />
    </>
  );
}
