import makeStyles from "@mui/styles/makeStyles";
const useStyle = makeStyles((theme) => ({
  textInput: {
    fontSize: "16px",
    fontWeight: 400,
    color: "#3C4242",
    backgroundColor: theme.palette.secondary.lighter,
    borderRadius: "6px",
    paddingLeft: "8px",
  },

  copyDialog: {
    border: "1px solid #479AE9",
    borderRadius: "8px",
    padding: "5px",
    columnGap: "5px",
    width: "95%",
    height: "30px",
    display: "flex",
  },

  copyContent: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    rowGap: "20px",
  },
  copyInput: {
    borderWidth: "0px",
    width: "68%",
    textOverflow: "ellipsis",
    color: "#70757A",
    fontWeight: "500",
  },
  radio: {
    color: "#479AE9",
    justifyContent: "top",
    "& .MuiSvgIcon-root": {
      fontSize: "14px",
    },
  },

  detailsQuestionDisplay: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
  },
}));

export default useStyle;
