import { useEffect, useState } from "react";

import { Avatar, Box, IconButton, Typography, useTheme } from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

import { getContactInitials, stringToColor } from "Utils/CommonFunctions";
import { returnToViewAllTeams } from "./TeamBreadCrumbs";
import DeleteConfirmation from "./DeleteConfirmation";
import useStyle from "./styles";

/**
 * Displays the contacts for a selected team
 */
export default function TeamDetails({
  selectedGroup,
  palette,
  getGroups,
  groups,
  setSelectedGroup,
  setEditTeam,
}) {
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [selectedTeamMember, setSelectedTeamMember] = useState({});
  const classes = useStyle();
  const theme = useTheme();

  /**
   *  Fetches the new selectedGroup members after one is deleted
   */
  useEffect(() => {
    const updatedSelectedTeam = groups.find(
      (newTeam) => newTeam.group_name === selectedGroup.group_name
    );
    if (!updatedSelectedTeam) {
      returnToViewAllTeams(setEditTeam, setSelectedGroup);
    }
    setSelectedGroup(updatedSelectedTeam);
  }, [groups]);

  const handleDeleteContactFromTeam = (teamMember, groupId) => {
    teamMember.groupId = groupId;
    setSelectedTeamMember(teamMember);
    setDeleteConfirm(true);
  };

  return (
    <>
      <Box display="flex" flexDirection="column" rowGap="20px">
        <Typography className={classes.groupNameTeam} align="left">
          {selectedGroup.group_name}
        </Typography>
        {selectedGroup.contacts.map((teamMember) => {
          const teamMemberInitials = getContactInitials(teamMember);
          return (
            <Box
              display="flex"
              alignItems="center"
              style={{ marginLeft: "5%" }}
            >
              <Avatar
                style={{
                  backgroundColor: stringToColor(
                    teamMember.contact_name,
                    palette
                  ),
                }}
                className={classes.smallAvatar}
              >
                {teamMemberInitials[0]}
                {teamMemberInitials.length > 1 ? teamMemberInitials[1] : null}
              </Avatar>
              <Box
                display="flex"
                justifyContent="space-between"
                width="100%"
                alignItems="center"
              >
                <Typography variant="h3">{teamMember.contact_name}</Typography>
                <IconButton
                  onClick={() =>
                    handleDeleteContactFromTeam(teamMember, selectedGroup._id)
                  }
                >
                  <DeleteOutlinedIcon />
                </IconButton>
              </Box>
            </Box>
          );
        })}
      </Box>

      <DeleteConfirmation
        type={"contact-group"}
        selected={selectedTeamMember}
        setSelected={setSelectedTeamMember}
        deleteConfirm={deleteConfirm}
        setDeleteConfirm={setDeleteConfirm}
        refreshFunction={getGroups}
      />
    </>
  );
}
