import { Box, Chip, Typography, useMediaQuery, useTheme } from "@mui/material";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  MEETING_THROUGH_QR_CODE,
  MEETING_THROUGH_WEB_INTEGRATION_CALENDAR,
} from "../../Utils/Constants";

/**
 * Chips that render the selected times for an all skeding meeting case
 */
export default function AllSkedingMeetingChip({ meetingData, hideTitle }) {
  var startTime = moment(meetingData?.start_datetime).format("hh:mm a");
  var endTime = moment(meetingData?.end_datetime).format("hh:mm a");
  var startDate = moment(meetingData?.start_datetime).format("ddd, MMM D");
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column" rowGap="14px">
      <Box
        display="flex"
        flexDirection="column"
        rowGap="4px"
        alignItems={"flex-start"}
      >
        {hideTitle ? null : (
          <Typography variant="h3">{t("selected_time")}</Typography>
        )}
        <Typography
          variant="h4"
          sx={{
            color: theme.palette.text.disabled,
            fontWeight: "400",
          }}
          align="left"
          width={mobile ? "300px" : "270px"}
        >
          {t("the")}{" "}
          {meetingData.case === MEETING_THROUGH_QR_CODE ||
          meetingData.case === MEETING_THROUGH_WEB_INTEGRATION_CALENDAR
            ? t("participant")
            : t("organizer")}{" "}
          {t("single_selected")}
        </Typography>
      </Box>
      <Chip
        label={
          <div style={{ textAlign: "center" }}>
            <Typography
              style={{ color: theme.palette.info.light }}
              variant="h4"
              fontWeight={300}
            >
              {startDate}
            </Typography>
            <Typography
              style={{ color: theme.palette.text.primary }}
              variant="h3"
              fontWeight={400}
            >
              {startTime + " - " + endTime}
            </Typography>{" "}
          </div>
        }
        sx={{
          background: "#F7F7F9",
          borderRadius: "8px",
          p: { xs: 0, md: "24px 6px 24px 6px" },
          width: "max-content",
        }}
        // clickable={true}
      />
    </Box>
  );
}
