import { Avatar } from "@mui/material";
export default function ProfileAvatar(props) {
  return (
    <Avatar
      sx={{ height: props.height, width: props.width }}
      alt="profile picture"
      src={props.user?.picture}
    />
  );
}
