import React, { ReactNode } from "react";
import { Grid, Box, useTheme, useMediaQuery } from "@mui/material";

type LeftLayoutProps = {
  children: ReactNode;
  scrollable?: boolean;
  showFull?: boolean;
  inverted?: boolean;
  lgSize?: number;
};
export function LeftLayout({
  children,
  scrollable,
  showFull,
  inverted,
  lgSize,
}: LeftLayoutProps) {
  const theme = useTheme();
  const tab = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Grid item xs={12} lg={showFull ? 12 : lgSize ? lgSize : 8}>
      <Box
        style={{
          background: tab ? "transparent" : theme.palette.secondary.light,
          borderRadius: "16px",
          height: !scrollable ? "85vh" : "auto",
          boxSizing: "border-box",
          marginRight: tab || inverted ? "0px" : "16px",
          marginLeft: inverted ? "16px" : "inherit",
          padding: tab ? "8px" : lgSize ? "16px" : "24px 20px",
          marginBottom: "0px",
          overflow: "auto",
        }}
      >
        {children}
      </Box>
    </Grid>
  );
}

type RightLayoutProps = {
  children: ReactNode;
  scrollable?: boolean;
  lgSize?: number;
};
export function RightLayout({
  children,
  scrollable,
  lgSize,
}: RightLayoutProps) {
  const theme = useTheme();
  const tab = useMediaQuery(theme.breakpoints.down("lg"));
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid item xs={12} lg={lgSize ? lgSize : 4}>
      <Box
        style={{
          background: tab ? "transparent" : theme.palette.secondary.light,
          borderRadius: "16px",
          height: "85vh",
          overflow: scrollable ? "auto" : "visible",
          boxSizing: "border-box",
          padding: mobile ? "" : lgSize ? "16px" : "24px",
        }}
      >
        {children}
      </Box>
    </Grid>
  );
}
