import {
  Dialog,
  DialogContent,
  DialogActions,
  Box,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useMeetingStore from "../../Utils/createMeetingStore";

export default function NoCalendar({ modal, handleToggle }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setMeetingModal } = useMeetingStore();

  return (
    <Dialog
      open={modal}
      onClose={() => handleToggle((prev) => !prev)}
      sx={{ justifyContent: "center" }}
      PaperProps={{
        sx: { padding: 3, borderRadius: "8px" },
      }}
    >
      <DialogContent style={{ minWidth: "280px" }}>
        <Typography variant="h2" align="center" fontWeight={500}>
          {t("connect_cal")} <br /> a {t("meet")}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", padding: "10px" }}>
        <Button
          variant="outlined"
          onClick={() => handleToggle((prev) => !prev)}
        >
          {t("cancel")}
        </Button>
        <Box mr={1} ml={1} />
        <Button
          variant="contained"
          onClick={() => {
            navigate("/preferences");
            setTimeout(() => setMeetingModal(false), 1000);
          }}
        >
          {t("proceed")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
