import { Grid, Button, CircularProgress } from "@mui/material";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { checkUrl } from "Utils/CommonFunctions";
import { meetingsContext } from "../../../context/meetings";
import { serverPOST } from "../../../Utils/HttpFunctions";
import DialogWithSuccessMessage from "../../DialogWithSuccessMessage";
import { useNavigate } from "react-router-dom";

export default function WebsiteBookingsButton({ event, onClose }) {
  const { websiteBookings, setWebsiteBookings } = useContext(meetingsContext);
  const [rejectMeeting, setRejectMeeting] = useState(false);
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);

  var mainRejectMessage = t("meeting_reject");
  var rejectMessage = t("reject_confirm");
  var rejectSuccess = t("reject_msg");
  const handleResponse = async (responseValue, comment) => {
    setDisabled(true);

    const temp = [...websiteBookings];

    var data = {
      meeting_id: event.id,
      response: responseValue,
      time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      utc_offset: new Date().getTimezoneOffset(),
    };
    if (!responseValue) {
      data = {
        ...data,
        comment: !!comment ? comment.trim() : undefined,
      };
    }

    const response = await serverPOST(`/meeting/initiatorresponse`, data);

    if (response && response.constructor !== String) {
      console.log("accepted response =>", response);
      const meeting = await temp.find((e) => e.id === event?.id);
      const index = temp.indexOf(meeting);
      if (index !== -1) {
        temp.splice(index, 1);
        setWebsiteBookings(temp);
        if (onClose) onClose();
      }
    } else {
      console.log("error =>", response);
    }
    setDisabled(false);
  };

  const handleReject = () => {
    setRejectMeeting(true);
  };
  const handleJoin = () => {
    window.open(event.location);
  };
  return (
    <Grid container pt="8px" justifyContent={"center"}>
      {checkUrl(event?.location) ? (
        <Button variant="contained" onClick={handleJoin}>
          {t("join")}
        </Button>
      ) : (
        <>
          <Button variant="outlined" sx={{ ml: "20px" }} onClick={handleReject}>
            {t("reject")}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleResponse(1);
            }}
            sx={{ ml: "20px" }}
            disabled={disabled}
          >
            {disabled ? (
              <CircularProgress style={{ color: "white" }} size={20} />
            ) : (
              t("accept")
            )}
          </Button>
        </>
      )}
      <DialogWithSuccessMessage
        open={rejectMeeting}
        setOpen={setRejectMeeting}
        mainRejectMessage={mainRejectMessage}
        message={rejectMessage}
        secondaryMessage={rejectSuccess}
        operation={handleResponse}
        disabled={disabled}
        showComment={true}
      />
    </Grid>
  );
}
