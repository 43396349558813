import { Button } from "@mui/material";
import React, { useState } from "react";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import Choice from "./Choice";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import GenerateTodos from "../../components/Dashboard/GenerateTodos";

const useStyles = makeStyles({
  button: {
    background: "#479AE9",
    color: "#fff",
    "&:hover": {
      background: "#2587E3",
      color: "#fff",
    },
  },
});
export default function Feedback() {
  const [choice, setChoice] = useState(false);

  // todo
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      <div
        style={{
          position: "absolute",
          top: "50%",
          right: "-29px",
          transform: "rotate(-90deg)",
          zIndex: "9",
        }}
      >
        <Button
          className={classes.button}
          onClick={() => setChoice((prev) => !prev)}
          endIcon={
            <ThumbUpIcon
              style={{ fontSize: "12px", padding: 0, marginRight: 4 }}
            />
          }
        >
          {t("feedback")}
        </Button>
      </div>
      {/* <Button
        className={classes.button}
        onClick={() => setOpen((prev) => !prev)}
      >
        {t("Generate Todos")}
      </Button> */}

      {/* openai generate todo list */}
      {/* <GenerateTodos open={open} handleClose={() => setOpen((prev) => !prev)} /> */}

      <Choice choice={choice} setChoice={setChoice} />
    </div>
  );
}
