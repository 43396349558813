import React, { useState, useContext } from "react";
import {
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
  Button,
  Box,
  useTheme,
  useMediaQuery,
  Divider,
  Dialog,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker, TimePicker } from "@mui/x-date-pickers";
import ClearIcon from "@mui/icons-material/Clear";
import moment from "moment";

import { serverPOST, serverPUT } from "Utils/HttpFunctions";
import styles from "styles/styles.module.css";
import useStyle from "styles/formStyles";
import EditTodo from "./EditTodo";
import { useTranslation } from "react-i18next";
import useEventsStore from "../../Utils/eventStore";
import puseStyle from "./styles";
function MobileTodos() {
  const { calendarTasks, setCalendarTasks } = useEventsStore();

  const { t } = useTranslation();
  const classes = useStyle();
  const pclasses = puseStyle();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  const [taskName, setTaskName] = useState("");
  const [duration, setDuration] = useState(60);
  const [error, setError] = useState("");
  const [mobileModal, setMobileModal] = useState(false);
  const [todosModal, setTodosModal] = useState(false);
  const [todoDate, setTodoDate] = useState(moment());
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [timeOpen, setTimeOpen] = useState(false);
  const [editTodo, setEditTodo] = useState(null);
  const [indicator, setIndicator] = useState({
    isDateSelected: false,
    isTimeSelected: false,
  });

  const handleRadioClick = async (todo) => {
    const temp = [...calendarTasks];
    const obj = temp.find((task) => task.id === todo.id);

    obj.is_done = true;
    const response = await serverPUT(`/todo/${todo.id}`, {
      is_done: true,
    });
    if (response && response.constructor !== String) {
      const index = temp.indexOf(obj);
      temp.splice(index, 1);
      setCalendarTasks(temp);
    }
  };

  const addMobile = () => {
    setMobileModal(true);
  };

  const addTodoMobile = async () => {
    if (taskName.trim() === "") {
      setError("invlaid todo name");
      return;
    }
    if (!duration) {
      return;
    }

    let data = {
      title: taskName,
      duration_minutes: duration,
    };

    if (todoDate.isValid() && indicator.isDateSelected) {
      data.start_datetime = todoDate.toDate().getTime();
      data.end_datetime = moment(data.start_datetime)
        .add(duration, "minutes")
        .toDate()
        .getTime();
    }

    const response = await serverPOST(`/todo`, data);
    if (response && response.constructor !== String) {
      const newTodo = {
        id: response.data._id,
        title: response.data.title,
        duration: response.data.duration_minutes,
        start: response.data.start_datetime
          ? response.data.start_datetime
          : undefined,
        end: response.data.end_datetime
          ? response.data.end_datetime
          : undefined,
        is_done: false,
        backgroundColor: "#96DCFF",
      };
      setCalendarTasks([newTodo, ...calendarTasks]);
    }
    setMobileModal(false);
    setTaskName("");
    setDuration(60);
    setIndicator({
      isDateSelected: false,
      isTimeSelected: false,
    });
  };

  return (
    <>
      <Box pt={1} style={{ order: mobile ? 1 : 2 }}>
        <Accordion
          elevation={0}
          expanded={todosModal}
          disableGutters
          onChange={() => setTodosModal((prev) => !prev)}
          className={pclasses.accordion}
        >
          <AccordionSummary
            style={{ padding: 0, marginBottom: "-16px" }}
            expandIcon={
              todosModal ? (
                <Typography
                  color="primary"
                  variant="h3"
                  style={{
                    transform: todosModal ? "rotate(180deg)" : "rotate(0deg)",
                  }}
                >
                  {t("close")}
                </Typography>
              ) : (
                <Typography
                  color="primary"
                  variant="h3"
                  style={{
                    transform: todosModal ? "rotate(180deg)" : "rotate(0deg)",
                  }}
                >
                  {t("view")}
                </Typography>
              )
            }
            aria-controls="integration-content"
            id="integration-header"
          >
            <Typography variant={mobile ? "h3" : "h2"} align="left">
              {t("todo_list")}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={pclasses.accordionDetail}>
            <>
              <Box
                display="flex"
                flexDirection={"column"}
                justifyContent={"space-between"}
                alignItems="flex-start"
                className={`${styles.upper} ${pclasses.todoContainer}`}
              >
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  style={{ width: "100%" }}
                >
                  <Box style={{ width: "100%" }}>
                    {calendarTasks.length > 0 &&
                      calendarTasks.map((event) => (
                        <Box
                          key={event.id}
                          width="100%"
                          display="flex"
                          flexDirection={"row"}
                          style={{ boxSizing: "border-box", cursor: "pointer" }}
                          pl="4px"
                        >
                          <Box
                            display="flex"
                            justifyContent={"center"}
                            alignItems="center"
                            flexDirection={"column"}
                            className={classes.circleContainer}
                          >
                            <Box
                              style={{
                                borderColor: event.start
                                  ? "#479AE9"
                                  : "inherit",
                              }}
                              className={classes.circle}
                              onClick={() => handleRadioClick(event)}
                            />
                          </Box>
                          <Box
                            display="flex"
                            flexDirection={"column"}
                            className={classes.editTodo}
                            onClick={() => setEditTodo(event)}
                          >
                            <Typography
                              variant="h3"
                              align="left"
                              fontWeight={400}
                            >
                              {event.title}
                            </Typography>
                            <Typography
                              variant="h4"
                              align="left"
                              fontWeight={400}
                              style={{ marginTop: 4 }}
                            >
                              {event.start ? (
                                <>
                                  {moment(event?.start).format("LT")} to{" "}
                                  {moment(event?.end).format("LT")} (
                                  {moment(event?.end).diff(
                                    event?.start,
                                    "minutes"
                                  )}{" "}
                                  minutes)
                                </>
                              ) : (
                                event.duration + " minutes "
                              )}
                            </Typography>
                            {event.start && (
                              <Typography
                                variant="h4"
                                align="left"
                                fontWeight={400}
                                style={{ marginTop: 4 }}
                              >
                                {moment(event?.start).format(
                                  "dddd, MMMM DD, YYYY"
                                )}
                              </Typography>
                            )}
                          </Box>
                        </Box>
                      ))}
                  </Box>
                </Box>
              </Box>
              <Box
                display={"flex"}
                justifyContent="center"
                alignItems={"center"}
                style={{ width: "100%" }}
              >
                <Button
                  disableRipple
                  onClick={addMobile}
                  style={{
                    fontSize: "16px",
                  }}
                >
                  {t("add_task")}
                </Button>
              </Box>
            </>
          </AccordionDetails>
        </Accordion>

        <Divider />
        <Dialog
          open={mobileModal}
          onClose={() => setMobileModal(false)}
          maxWidth={"xs"}
          PaperProps={{ sx: { padding: 2, borderRadius: "8px" } }}
        >
          <Grid
            container
            rowSpacing={1}
            style={{
              margin: "auto",
            }}
            className={classes.root}
          >
            <Grid item xs={12}>
              <Typography variant="h3" align="center">
                {t("Add task")}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                placeholder={t("title")}
                variant="standard"
                autoComplete="off"
                value={taskName}
                style={{ marginTop: 10 }}
                error={!!error}
                helperText={error}
                sx={{ width: "100%" }}
                autoFocus
                InputProps={{
                  disableUnderline: true,

                  classes: { input: `${classes.input} ${pclasses.todoInput}` },
                }}
                onChange={(e) => {
                  if (error) {
                    setError("");
                  }
                  setTaskName(e.target.value);
                }}
                id="create-form"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="standard" sx={{ width: "100%" }}>
                <Select
                  labelId="time-interval-label"
                  id="event-time-interval"
                  defaultValue={60}
                  disableUnderline={true}
                  className={classes.todoInput}
                  value={duration}
                  onChange={(e) => setDuration(e.target.value)}
                >
                  <MenuItem value={15}>15 minutes</MenuItem>
                  <MenuItem value={30}>30 minutes</MenuItem>
                  <MenuItem value={45}>45 minutes</MenuItem>
                  <MenuItem value={60}>1 hour (60 minutes)</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Box
                display={"flex"}
                justifyContent="space-between"
                alignItems={"center"}
              >
                <Typography className={classes.todoInput}>
                  {t("set_date")}{" "}
                  <span className={classes.grayMsg}> ({t("optional")})</span>
                </Typography>
                <Button
                  variant="text"
                  style={{
                    width: indicator.isDateSelected ? "112px" : "100px",
                    padding: 0,
                  }}
                  disableRipple={true}
                  onClick={() => {
                    if (!indicator.isDateSelected) {
                      setCalendarOpen(true);
                      setIndicator({
                        ...indicator,
                        isDateSelected: true,
                      });
                    }
                  }}
                >
                  {indicator.isDateSelected ? (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileDatePicker
                        open={calendarOpen}
                        onOpen={() => setCalendarOpen(true)}
                        onClose={() => setCalendarOpen(false)}
                        value={todoDate}
                        showToolbar={false}
                        onChange={(newValue) => {
                          setTodoDate(newValue);
                        }}
                        className={classes.dateContainer}
                        minDate={new Date()}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            onClick={(e) => setCalendarOpen((prev) => !prev)}
                            onKeyDown={(e) => e.preventDefault()}
                            className={classes.dateTime}
                            InputProps={{
                              disableUnderline: true,
                              style: { fontSize: "12px" },
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="calendar"
                                    onClick={() =>
                                      setIndicator({
                                        ...indicator,
                                        isDateSelected: false,
                                      })
                                    }
                                  >
                                    <ClearIcon
                                      sx={{
                                        fontSize: "16px",
                                        color: "#363B53",
                                      }}
                                    />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  ) : (
                    t("select_date")
                  )}
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                display={"flex"}
                justifyContent="space-between"
                alignItems={"center"}
                width="100%"
              >
                <Typography className={classes.todoInput}>
                  {t("set_time")}{" "}
                  <span className={classes.grayMsg}> ({t("optional")})</span>
                </Typography>
                <Button
                  variant="text"
                  style={{ width: "100px", padding: 0 }}
                  disableRipple={true}
                  onClick={() => {
                    if (!indicator.isTimeSelected) {
                      setTimeOpen(true);
                      setIndicator({
                        ...indicator,
                        isTimeSelected: true,
                      });
                    }
                  }}
                >
                  {indicator.isTimeSelected ? (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        open={timeOpen}
                        onOpen={() => setTimeOpen(true)}
                        onClose={() => setTimeOpen(false)}
                        value={todoDate}
                        disablePast={true}
                        minutesStep={15}
                        onChange={(newValue) => {
                          setTodoDate(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="standard"
                            onClick={(e) => setTimeOpen((prev) => !prev)}
                            onKeyDown={(e) => e.preventDefault()}
                            className={classes.dateTime}
                            InputProps={{
                              disableUnderline: true,
                              style: { fontSize: "12px" },
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="calendar"
                                    onClick={() =>
                                      setIndicator({
                                        ...indicator,
                                        isTimeSelected: false,
                                      })
                                    }
                                  >
                                    <ClearIcon
                                      sx={{
                                        fontSize: "16px",
                                        color: "#363B53",
                                      }}
                                    />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  ) : (
                    t("select_time")
                  )}
                </Button>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              container
              justifyContent={"center"}
              columnSpacing={4}
              sx={{ mt: 2 }}
            >
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={() => setMobileModal(false)}
                >
                  {t("cancel")}
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" onClick={addTodoMobile}>
                  {t("done")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Dialog>
        <EditTodo event={editTodo} handleEdit={() => setEditTodo(null)} />
      </Box>
    </>
  );
}

export default React.memo(MobileTodos);
