import { useState } from "react";
import { Grid, Button } from "@mui/material";

import { checkUrl } from "Utils/CommonFunctions";
import CancelDialog from "../CancelDialog";
import { useTranslation } from "react-i18next";

export default function MyMeetingsButtons({
  event,
  meetingId,
  setMeetingDeleted,
  setIsOpen,
  setPlannedEvents,
  plannedEvents,
  setCancelSuccess,
  handleClose,
  isCal,
}) {
  const [cancelDialog, setCancelDialog] = useState(false);
  const { t } = useTranslation();
  let eventStartSet = true;
  let physicalEvent = false;

  if (Number.isNaN(event.start.getTime())) {
    eventStartSet = false;
  }

  if (event.location !== null && event.location.search(/http/) === -1) {
    physicalEvent = true;
  }

  const handleJoin = () => {
    if (checkUrl(event.location)) {
      window.open(event.location);
    }
  };

  const handleCancel = () => {
    setCancelDialog(true);
  };

  return (
    <Grid container pt="16px" justifyContent={"center"}>
      <Button onClick={handleCancel} variant="outlined">
        {t("cancel")}
      </Button>
      {eventStartSet && checkUrl(event?.location) ? (
        <Button variant="contained" sx={{ ml: "20px" }} onClick={handleJoin}>
          {t("join")}
        </Button>
      ) : null}

      <CancelDialog
        meetingId={meetingId}
        event={event}
        cancelDialog={cancelDialog}
        setCancelDialog={setCancelDialog}
        setMeetingDeleted={setMeetingDeleted}
        setIsOpen={setIsOpen}
        setPlannedEvents={setPlannedEvents}
        plannedEvents={plannedEvents}
        setCancelSuccess={setCancelSuccess}
        handleClose={handleClose}
        isCal={isCal}
      />
    </Grid>
  );
}
