import React from "react";
import { Grid, Typography } from "@mui/material";
import moment from "moment";
import { useTranslation } from "react-i18next";

type PaymentDetailsType = {
  userPackage: any;
};
export default function PaymentDetails({ userPackage }: PaymentDetailsType) {
  const { t } = useTranslation();
  return (
    <Grid container direction={"column"}>
      <Grid item display={"flex"} mt={2}>
        <Typography variant="h4">{t("current_package_plan")}</Typography>
        <Typography
          variant="h4"
          sx={{
            marginLeft: "auto",
          }}
        >
          {userPackage?.title}
        </Typography>
      </Grid>

      {userPackage?.renewal_date && userPackage?.paid ? (
        <>
          <Grid item display={"flex"} mb={2}>
            <Grid
              item
              mt={2}
              sx={{
                textAlign: "left",
              }}
            >
              <Typography variant="h4">{t("member_start")}</Typography>
              <Typography
                variant="h4"
                fontWeight={400}
                mt={1}
                sx={{
                  marginLeft: "auto",
                }}
              >
                {moment(userPackage?.subscription_date).format("DD/MM/YYYY")}
              </Typography>
            </Grid>

            <Grid
              item
              mt={2}
              sx={{
                marginLeft: "auto",
                textAlign: "right",
              }}
            >
              <Typography variant="h4">{t("next_invoice")}</Typography>
              <Typography
                variant="h4"
                fontWeight={400}
                mt={1}
                sx={{
                  marginLeft: "auto",
                }}
              >
                {/* Billed annually 09/21/2021 */}

                {moment(userPackage?.renewal_date).format("DD/MM/YYYY")}
              </Typography>
            </Grid>
          </Grid>

          <Grid item display={"flex"} mb={2}>
            <Grid
              item
              mt={2}
              sx={{
                textAlign: "left",
              }}
            >
              <Typography variant="h4">{t("no_of_seats")}</Typography>
              <Typography
                variant="h4"
                fontWeight={400}
                mt={1}
                sx={{
                  marginLeft: "auto",
                }}
              >
                {userPackage?.quantity}
              </Typography>
            </Grid>

            <Grid
              item
              mt={2}
              sx={{
                marginLeft: "auto",
                textAlign: "right",
              }}
            >
              <Typography variant="h4">{t("assigned_seats")}</Typography>
              <Typography
                variant="h4"
                fontWeight={400}
                mt={1}
                sx={{
                  marginLeft: "auto",
                }}
              >
                {/* Billed annually 09/21/2021 */}

                {userPackage.available_seats === undefined ||
                userPackage.available_seats === null
                  ? 0
                  : userPackage?.quantity - userPackage.available_seats}
              </Typography>
            </Grid>
          </Grid>
        </>
      ) : null}
    </Grid>
  );
}
