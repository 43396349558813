import { IconButton, InputAdornment, TextField, useTheme } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import moment from "moment";
import Calendar from "react-calendar";
import "styles/smallCalendar.css";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
export default function SearchByDate({
  searchDate,
  setSearchDate,
  mobile,
  openCalendar,
  setOpenCalendar,
  handleSearchDate,
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const componentRef = useRef(null);

  const handleIconClick = () => {
    if (searchDate) {
      setSearchDate(null);
    }
    handleSearchDate(null);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        setOpenCalendar(false);
      }
    };

    // Attach the event listener on component mount
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={componentRef}>
      <TextField
        id="search-date"
        placeholder={t("search_by_date")}
        variant="standard"
        value={searchDate ? moment(searchDate).format("ddd, DD MMM") : ""}
        onChange={(e) => setSearchDate(null)}
        defaultValue=""
        onClick={() => setOpenCalendar((prev) => !prev)}
        sx={{
          "& .MuiInput-root .MuiInput-input": {
            padding: "6px 4px 4px 0",
          },
        }}
        InputProps={{
          disableUnderline: true,
          style: {
            borderRadius: "6px",
            width: mobile ? 130 : 160,
            height: "30px",
            backgroundColor: "transparent",
            border: "1px solid #DAD2E5",
            padding: "0px 9px",
            marginLeft: "4px",
            fontSize: 14,
          },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="search"
                onClick={handleIconClick}
                disableRipple
              >
                {/* <CalendarTodayIcon
                  sx={{
                    color: theme.palette.text.disabled,
                    width: "14px",
                    height: "14px",
                    marginRight: "4px",
                  }}
                /> */}

                <ClearOutlinedIcon
                  sx={{
                    color: theme.palette.text.disabled,
                    width: "14px",
                    height: "14px",
                  }}
                />
              </IconButton>
            </InputAdornment>
          ),
        }}
        style={{
          marginRight: 10,
        }}
      />
      {openCalendar && (
        <Calendar
          //   onChange={(e) => setSearchDate(new Date(e))}
          onChange={(e) => handleSearchDate(e)}
          value={searchDate}
          className="calendar-style"
        />
      )}
    </div>
  );
}
