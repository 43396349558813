export const shareableCalendarStyles = (isOrganizer) => `
.fc-timegrid-event {
  border-radius: 6px,
  border: 0px
}
thead {
  border-width: 0;
  font-size: 14px;
}  
thead .fc-scroller {
  overflow: hidden !important;
}
.fc-col-header-cell .fc-day {
  border-width: 0;
}

.fc .fc-daygrid-body {
  position: relative;
  z-index: 1;
  border: none; 
}

.fc .fc-daygrid-day-frame {
  position: relative;
  min-height: 100%;
  border: none; 
  cursor:pointer;
}
.fc-timegrid-col-frame .fc-timegrid-now-indicator-container {
  border-top: 0px !important;
}
.fc .fc-bg-event {
  background: rgba(150, 220, 255, 0.5);
  border-radius: 50%;
  opacity: 1;
  margin: 5px;
}
.fc .fc-daygrid-day-number {
  font-size: ${isOrganizer ? "14px" : "18px"};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
`;
