import React from "react";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import TimePicker from "rc-time-picker";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import "rc-time-picker/assets/index.css";
import "styles/picker.css";
import moment from "moment";
import {
  Box,
  useMediaQuery,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  useTheme,
  IconButton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import useStyle from "./styles";
import { convertDateToUTCMilliseconds } from "../../Utils/CommonFunctions";

export default function Repeated({
  startTime,
  endTime,
  label,
  checked,
  slot,
  calInfo,
  setCalInfo,
}) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();
  const classes = useStyle();

  /**
   * Toggles the selection of a day as unavailable
   * @param {string} name - the day of the week being toggled
   * @param {boolean} value - determines whether the day is selected
   */
  const handleDayCheck = (name, value) => {
    const temp = [...calInfo];
    const newState = temp.map((obj) => {
      if (obj.label === name) {
        return { ...obj, isActive: value };
      }
      return obj;
    });
    setCalInfo(newState);
  };

  /**
   * Handles the start time of the selected day
   * @param {string} name - day of the week
   * @param {object} value - a moment date object of the selected time
   * @param {object} slot - an object with the information of the selected time slot
   * @param {number} index - index of the selectedDate slot to add
   */
  const handleStartDaySlot = (name, value, slot, index) => {
    const temp = [...calInfo];
    const selectedDate = temp.find((item) => item.label === name);

    const date = new Date(convertDateToUTCMilliseconds(value._d));
    const slots = {
      start_day: slot.start_day,
      start_hours: date.getHours(),
      start_minutes: date.getMinutes(),
      end_day: selectedDate.slot[index].end_day,
      end_hours: selectedDate.slot[index].end_hours,
      end_minutes: selectedDate.slot[index].end_minutes,
    };

    const newState = temp.map((obj) => {
      if (obj.label === name) {
        return {
          ...obj,
          slot: obj.slot.map((s, i) => {
            if (i === index) {
              return slots;
            }
            return s;
          }),
        };
      }
      return obj;
    });
    setCalInfo(newState);
  };

  /**
   * Handles the end time of the selected day
   * @param {string} name - day of the week
   * @param {object} value - a moment date object of the selected time
   * @param {object} slot - an object with the information of the selected time slot
   * @param {number} index - index of the selectedDate slot to add
   */
  const handleEndDaySlot = (name, value, slot, index) => {
    const temp = [...calInfo];
    const selectedDate = temp.find((item) => item.label === name);
    const date = new Date(convertDateToUTCMilliseconds(value._d));
    const slots = {
      start_day: selectedDate.slot[index].start_day,
      start_hours: selectedDate.slot[index].start_hours,
      start_minutes: selectedDate.slot[index].start_minutes,
      end_day: slot.start_day,
      end_hours: date.getHours(),
      end_minutes: date.getMinutes(),
    };

    const newState = temp.map((obj) => {
      if (obj.label === name) {
        return {
          ...obj,
          slot: obj.slot.map((s, i) => {
            if (i === index) {
              return slots;
            }
            return s;
          }),
        };
      }
      return obj;
    });

    setCalInfo(newState);
  };

  /**
   * Adds a new unavailable slot
   * @param {string} label - day of the week being added to
   * @param {number} length - the number of unavailable slots that have been added
   */
  const addNewSlot = (label, length) => {
    const temp = [...calInfo];
    const selectedDay = temp.find((item) => item.label === label);
    const index = temp.indexOf(selectedDay);
    const newSlot = {
      start_day: selectedDay.slot[length - 1].start_day,
      start_hours: selectedDay.slot[length - 1].end_hours,
      start_minutes: selectedDay.slot[length - 1].end_minutes,
      end_day: selectedDay.slot[length - 1].end_day,
      end_hours: 0,
      end_minutes: 0,
    };
    temp[index].slot.push(newSlot);
    setCalInfo(temp);
  };

  /**
   * Deletes additional availability slots that were added to a day
   * @param {string} label - day of the week being added to
   * @param {number} slotIndex - index of the slot to be removed
   */
  const deleteNewSlot = (label, slotIndex) => {
    const temp = [...calInfo];
    const selectedDay = temp.find((item) => item.label === label);
    const index = temp.indexOf(selectedDay);
    temp[index].slot.splice(slotIndex, 1);

    setCalInfo(temp);
  };

  return (
    <Box
      display="flex"
      sx={{
        flexDirection: { xs: "row", sm: "row" },
        width: "60px",
      }}
    >
      <Box className={classes.repeatedWrapper}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                size="14px"
                checked={checked}
                onChange={(event) =>
                  handleDayCheck(label, event.target.checked)
                }
                className={classes.checkbox}
              />
            }
            label={
              <Typography variant={mobile ? "h5" : "h3"} fontWeight={400}>
                {t(label)}
              </Typography>
            }
          />
        </FormGroup>
      </Box>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent={"flex-start"}
        flexWrap="wrap"
      >
        {slot.map((s, index) => (
          <Box
            style={{ marginRight: 16 }}
            display="flex"
            alignItems="center"
            mt={"4px"}
            justifyContent={"flex-start"}
            key={index}
          >
            <TimePicker
              style={{
                position: "relative",
                fontSize: "14px",
              }}
              defaultValue={moment(startTime.split(" ")[0] + ":00:00", "HH A")}
              value={moment(
                s.start_hours + ":" + s.start_minutes + ":00",
                "HH mm A"
              )}
              focusOnOpen
              showSecond={false}
              showMinute={true}
              minuteStep={30}
              clearIcon={<></>}
              use12Hours
              disabled={!checked}
              onChange={(value) => handleStartDaySlot(label, value, s, index)}
              className={
                mobile ? "custom-time-picker-mobile" : "custom-time-picker"
              }
            />
            <Box
              style={{ width: "12px", height: "1px", background: "#000" }}
              ml={1}
              mr={1}
            />
            <TimePicker
              style={{
                position: "relative",
              }}
              defaultValue={moment(endTime.split(" ")[0] + ":00:00", "HH A")}
              value={moment(
                s.end_hours + ":" + s.end_minutes + ":00",
                "HH mm A"
              )}
              focusOnOpen
              showSecond={false}
              // showMinute={s.end_hours === 0 ? false : true}
              showMinute={true}
              minuteStep={30}
              use12Hours
              clearIcon={<></>}
              disabled={!checked}
              onChange={(value) => handleEndDaySlot(label, value, s, index)}
              className={
                mobile ? "custom-time-picker-mobile" : "custom-time-picker"
              }
            />
            {slot.length > 1 && (
              <IconButton
                aria-label="plus"
                onClick={() => deleteNewSlot(label, index)}
                style={{ marginLeft: 8 }}
                disabled={!checked}
              >
                <DeleteOutlineOutlinedIcon
                  style={{
                    color: !checked ? "#B3B5B6" : "#363B53",
                  }}
                  className={classes.addIcon}
                />
              </IconButton>
            )}
            {mobile &&
              index === slot.length - 1 &&
              slot.start_hours !== 0 &&
              slot.end_hours !== 0 && (
                <IconButton
                  aria-label="plus"
                  onClick={() => addNewSlot(label, slot.length)}
                  style={{ marginLeft: 4 }}
                  disabled={!checked}
                >
                  <AddCircleOutlineOutlinedIcon
                    style={{
                      color: !checked ? "#B3B5B6" : "#363B53",
                    }}
                    className={classes.addIcon}
                  />
                </IconButton>
              )}
          </Box>
        ))}
      </Box>
      {!mobile && slot.start_hours !== 0 && slot.end_hours !== 0 && (
        <IconButton
          aria-label="plus"
          onClick={() => addNewSlot(label, slot.length)}
          style={{ marginLeft: -8, marginTop: 4 }}
          disabled={!checked}
          disableFocusRipple
          disableRipple
        >
          <AddCircleOutlineOutlinedIcon
            style={{
              color: !checked ? "#B3B5B6" : "#363B53",
            }}
            className={classes.addIcon}
          />
        </IconButton>
      )}
    </Box>
  );
}
