import React from "react";

import { TextField, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

import useStyle from "styles/formStyles";

export const RoomNo = React.memo(({ setRoom, room }) => {
  const classes = useStyle();
  const handleRoomChange = (event) => {
    setRoom(event.target.value);
  };
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <TextField
      placeholder={t("add_room_no")}
      variant="standard"
      autoComplete="off"
      value={room}
      style={{ marginTop: 10 }}
      sx={{ width: "100%" }}
      autoFocus
      InputProps={{
        disableUnderline: true,
        style: {
          fontSize: "14px",
          fontWeight: 400,
          color: theme.palette.text.primary,
        },
        classes: { input: classes.input },
      }}
      onChange={handleRoomChange}
      id="create-form"
    />
  );
});
