import { useNavigate, useSearchParams } from "react-router-dom";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

import {
  Typography,
  Box,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Player } from "@lottiefiles/react-lottie-player";

import Animate from "assets/error-page-animation.json";

export default function ErrorPage() {
  const [searchParams] = useSearchParams();
  const errorCode = parseInt(searchParams.get("code"));
  const navigate = useNavigate();
  const ref = useRef("");
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();

  const errorMessages = [
    {
      code: 400,
      title: "Bad Request",
      subtitle: "Your request resulted in an error.",
    },
    {
      code: 401,
      title: "Authorization Required",
      subtitle: "Sorry! Your request could not be processed.",
    },
    {
      code: 403,
      title: "Forbidden",
      subtitle: "You are not authorized to access this resource.",
    },
    {
      code: 404,
      title: "Page Not Found",
      subtitle:
        "Oops! The page you are looking for does not exist. It might have been moved or deleted.",
    },
  ];

  const message = errorMessages.find((message) => message.code === errorCode);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      width="100vw"
      columnGap="120px"
      sx={{
        background:
          "linear-gradient(107.63deg, #D66AF4 11.85%, #479AE9 39.5%, #479AE9 63.89%, #1FC9B8 94.02%)",
        margin: "0px",
        flexDirection: mobile ? "column" : "row",
      }}
    >
      {console.log("mobile: ", mobile)}
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        height="100vh"
        order={mobile ? 1 : 0}
      >
        <Typography
          sx={{
            fontWeight: "400",
            fontSize: mobile ? "120px" : "200px",
            lineHeight: "239px",
            color: "#FFFFFF",
            "-webkitTextStrokeWidth": "1px",
            "-webkitTextStrokeColor": "black",
          }}
        >
          {errorCode}
        </Typography>
        <Typography
          sx={{
            fontWeight: "600",
            fontSize: mobile ? "36px" : "42px",
            color: "#FFFFFF",
          }}
        >
          {message.title}
        </Typography>
        <Typography
          sx={{
            fontWeight: "400",
            fontSize: "14px",
            color: "#FFFFFF",
            mt: "24px",
          }}
        >
          {message.subtitle}
        </Typography>
        <Box display="flex" justifyContent="center">
          <Button
            sx={{
              border: "1px solid #000000",
              borderRadius: "12px",
              background: "#FFFFFF",
              color: "#07135D",
              fontSize: "16px",
              fontWeight: "500",
              mt: "50px",
              width: "270px",
            }}
            // endIcon={<CallMadeIcon />}
            onClick={() => navigate("/dashboard")}
          >
            {t("back_to_home")}
          </Button>
        </Box>
      </Box>
      <div>
        <Player
          ref={ref} // set the ref to your class instance
          autoplay={true}
          loop={true}
          controls={true}
          src={Animate}
          speed={1}
          // style={{
          //     width: mobile ? "100%" : "100%",
          //     height: mobile ? "21vh" : null,
          //     marginTop: "30px",
          // }}
        ></Player>
      </div>
    </Box>
  );
}
