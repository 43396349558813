import { useNavigate, useLocation } from "react-router-dom";

import {
  Box,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useContext } from "react";
import { useStyles } from "../../styles/navigationButtonStyles.js";
import { useStyle } from "styles/tooltip";
import { useTranslation } from "react-i18next";
import useMeetingStore from "../../Utils/createMeetingStore.js";
import ToolTip from "../Global/ToolTip";
/**
 * Renders the buttons used in the left-hand side navigation drawer
 */
export default function NavigationButton({
  name,
  link,
  isMobile,
  handleDrawerToggle,
  classProp,
  icon,
  meetingPopup,
  isInstantPopup,
  instantMeetingPopup,
  setInstantMeetingPopup,
}) {
  const navigate = useNavigate();
  const classes = useStyles();
  const tooltipStyle = useStyle();
  const { pathname } = useLocation();
  const { setMeetingModal } = useMeetingStore();
  const { t } = useTranslation();
  const handleMenuButtonClick = () => {
    if (isInstantPopup) {
      setInstantMeetingPopup(true);
      return;
    }

    if (meetingPopup) {
      setMeetingModal(true);
      return;
    }
    if (name === "FAQs") {
      window.open("https://skeding-new-site.webflow.io/faqs");
    } else {
      navigate(link);
    }

    if (isMobile) {
      handleDrawerToggle();
    }
  };

  /**
   * Returns a description for each button to be used by the tooltip
   * @returns - title to be used in the tooltip
   */
  const handleTooltipTitle = () => {
    switch (link) {
      case "/dashboard":
        return t("dashboard_tooltip");
      case "/instant-meeting":
        return t("instant_tooltip");
      case "/createmeeting":
        return t("create_meeting_tooltip");
      case "/meetings":
        return t("meetings_tooltip");
      case "/shareable-meeting":
        return t("shareable_tooltip");
      case "/preferences":
        return t("preferences_tooltip");
      case "/contacts":
        return t("contacts_tooltip");
      case "/add-to-website":
        return t("website_tooltip");
      case "https://skeding-new-site.webflow.io/faqs":
        return t("faqs_tooltip");
      default:
        break;
    }
  };

  return (
    <ToolTip title={handleTooltipTitle()} placement="right">
      <Box
        className={pathname === link ? classes.active : classes.inactive}
        onClick={handleMenuButtonClick}
        id={classProp}
      >
        <ListItemButton
          alignItems="center"
          disableRipple
          className={
            pathname === link ? classes.activeButton : classes.inactiveButton
          }
        >
          <ListItemIcon sx={{ minWidth: "0px" }}>{icon}</ListItemIcon>
          <ListItemText
            className={classes.text}
            primary={
              <Typography align="left" variant="h4" color={"white"}>
                {name}
              </Typography>
            }
          />
        </ListItemButton>
      </Box>
    </ToolTip>
  );
}
