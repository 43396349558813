import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
  IconButton,
  Badge,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { serverPOST } from "../../Utils/HttpFunctions";
import useWebsiteStore from "../../Utils/websiteStore";
import * as yup from "yup";
import Textfield from "./Textfield";
import ErrorSnackbar from "../../components/ErrorSnackbar";
import useStyle from "./styles";
import SnackbarComponent from "../Global/SnackbarComponent";
import AddDetailsPreview from "./Preview/AddDetailsPreview";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ClearIcon from "@mui/icons-material/Clear";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import { convertBase64 } from "../../Utils/CommonFunctions";
import { PreviewButtonGroup } from "./Availability";
import Categories from "./Categories";
import { useTranslation } from "react-i18next";

export const schema = yup
  .object({
    title: yup.string().required("Title is a required field"),
  })
  .required();

export default function AddDetails({ t, categoryData }) {
  const calendarData = useWebsiteStore((state) => state.calendarData);
  const setCalendarData = useWebsiteStore((state) => state.setCalendarData);
  const [message, setMessage] = useState("");
  const details = useWebsiteStore((state) => state.details);
  const setDetails = useWebsiteStore((state) => state.setDetails);
  const setWebsiteLocations = useWebsiteStore(
    (state) => state.setWebsiteLocations
  );
  const classes = useStyle();
  const [saveMessage, setSaveMessage] = useState("");
  const [showPreview, setShowPreview] = useState(false);
  const max_file_size = 2 * 1024 * 1024;
  const [loading, setLoading] = useState(false);
  const category = useWebsiteStore((state) => state.category);
  const setCategory = useWebsiteStore((state) => state.setCategory);

  useEffect(() => {
    setDetails({
      title: calendarData?.title ? calendarData?.title : "",
      description: calendarData?.description ? calendarData?.description : "",
      picture: calendarData?.picture ? calendarData?.picture : "",
      category: calendarData?.category ? calendarData?.category : "",
    });
    setWebsiteLocations(calendarData.locations);
    if (calendarData.category) {
      setCategory(calendarData.category);
    }
  }, [calendarData]);

  const handleChange = (e) => {
    let val = typeof e === "string" ? e : e?.target?.value;
    const name =
      typeof e === "string" || e === undefined
        ? "phone_number"
        : e?.target?.name;
    setDetails({
      ...details,
      [name]: val ? val : "",
    });
  };

  const addDetails = async () => {
    const response = await serverPOST(
      `/web-integration?offset=${new Date().getTimezoneOffset()}`,
      { ...details, category: category }
    );
    if (response && response.constructor !== String) {
      setCalendarData(response.data.calendarData);
      setSaveMessage("Details have been updated");
    }
  };

  const handleAddDetails = async () => {
    setLoading(true);
    try {
      // if (!isValidPhoneNumber(details.phone_number) && details.phone_number) {
      //   console.log("num", details.phone_number);
      //   setMessage("Please enter correct number");
      //   return;
      // }
      const errors = await schema.validate(details);
      await addDetails();
    } catch (errors) {
      console.log("err", errors);
      setMessage(errors?.errors[0]);
    } finally {
      setLoading(false);
    }
  };

  const handleImage = async (e) => {
    if (e.target.files[0]) {
      if (e.target.files[0]?.size > max_file_size) {
        return;
      }
      const base64 = await convertBase64(e.target.files[0]);
      setDetails({ ...details, picture: base64 });
    }
  };

  const handleDel = () => {
    setDetails({ ...details, picture: "" });
  };

  return (
    <>
      {showPreview ? (
        <AddDetailsPreview />
      ) : (
        <Box display="flex" flexDirection={"column"} gap={3}>
          <Box className={classes.label}>
            <Typography variant="h4">Select your business category</Typography>
            <Categories />
          </Box>

          <Box display="flex" alignItems={"flex-end"} gap={2}>
            {details.picture ? (
              <Box>
                <Badge
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  className={classes.badge}
                  badgeContent={
                    <IconButton
                      onClick={handleDel}
                      className={classes.badgeIcon}
                    >
                      <ClearIcon
                        sx={{ color: "text.primary", width: "12px" }}
                        fontSize="small"
                      />
                    </IconButton>
                  }
                >
                  <img src={details.picture} className={classes.img} />
                </Badge>
              </Box>
            ) : (
              <IconButton className={classes.profilePic} component="label">
                <input
                  type="file"
                  accept="image/*"
                  hidden
                  name="image"
                  onChange={handleImage}
                />
                <AddPhotoAlternateOutlinedIcon className={classes.grayText} />
              </IconButton>
            )}

            <Box flex={1}>
              <Textfield
                label={t("title") + "*"}
                value={details.title}
                handleChange={handleChange}
                placeholder={t("add_title")}
                isBorder={true}
                name={"title"}
              />
            </Box>
          </Box>

          <Textfield
            label={t("about_company")}
            value={details.description}
            handleChange={handleChange}
            placeholder={t("about_placeholder")}
            isBorder={true}
            name={"description"}
            multiline={true}
          />

          <ErrorSnackbar
            open={!!message}
            handleClose={() => setMessage("")}
            message={message}
            vertical={"bottom"}
            horizontal={"center"}
            duration={3000}
          />
          <SnackbarComponent
            open={!!saveMessage}
            handleClose={() => setSaveMessage("")}
            message={saveMessage}
            vertical={"bottom"}
            horizontal={"center"}
          />
        </Box>
      )}
      <PreviewButtonGroup
        onSave={handleAddDetails}
        loading={loading}
        showPreview={showPreview}
        setShowPreview={setShowPreview}
      />
    </>
  );
}

export function ShiftTabs({ showPreview, onSave, loading }) {
  const theme = useTheme();
  const tabIndex = useWebsiteStore((state) => state.tabIndex);
  const setTabIndex = useWebsiteStore((state) => state.setTabIndex);
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();

  let prevTab = tabIndex - 1;
  let nextTab = tabIndex + 1;
  return (
    <>
      {mobile && showPreview ? (
        <></>
      ) : (
        <Button
          variant="contained"
          onClick={async () => {
            await onSave();
            setTimeout(() => {
              if (nextTab <= 5) setTabIndex(nextTab);
            }, 3000);
          }}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress style={{ color: "white" }} size={20} />
          ) : (
            t("save_continue")
          )}
        </Button>
      )}
      <Box
        style={{
          marginLeft: "auto",
        }}
      >
        <ArrowBackIosIcon
          fontSize="small"
          style={{
            cursor: prevTab >= 0 ? "pointer" : "",
            color: prevTab >= 0 ? "" : "lightgray",
          }}
          onClick={() => {
            if (prevTab >= 0) setTabIndex(prevTab);
          }}
        />
        <ArrowForwardIosIcon
          fontSize="small"
          style={{
            cursor: nextTab <= 5 ? "pointer" : "",
            color: nextTab <= 5 ? "" : "lightgray",
          }}
          onClick={() => {
            if (nextTab <= 5) setTabIndex(nextTab);
          }}
        />
      </Box>
    </>
  );
}
