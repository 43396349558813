import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
  useContext,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { isValidPhoneNumber } from "react-phone-number-input";
import {
  Typography,
  Button,
  Grid,
  Box,
  useTheme,
  useMediaQuery,
  CircularProgress,
  Backdrop,
  Fade,
  InputLabel,
} from "@mui/material";
import ListIcon from "@mui/icons-material/List";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import moment from "moment";
import Style from "style-it";

import "styles/newEvent.css";
import { serverPOST, serverGETPOST, serverGET } from "Utils/HttpFunctions";
import {
  checkUrl,
  convertDateToUTCMilliseconds,
  convertUTCMillisecondsToDate,
} from "Utils/CommonFunctions";
import {
  ALL_SKEDING_USERS,
  MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS,
  NEW_MEETINGS_RECURRING,
  NEW_MEETINGS_START_TIME,
  ONE_NONSKEDING_MULTIPLE_SKEDING_USERS,
  ONE_NONSKEDING_ONE_SKEDING_USER,
  ONE_SKEDING_MULTIPLE_NONSKEDING_USERS,
  RECURRING_EVENT,
} from "Utils/Constants";
import { integrationContext } from "context/Integrations";
import { useLocation } from "react-router-dom";
import { Title } from "./Title";
import { RoomNo } from "./RoomNo";
import loggingInDev from "loggingInDev";
import NoCalendar from "components/NewEvent/NoCalendar";
import ShowTimes from "components/NewEvent/ShowTimes";
import SnackbarComponent from "components/Global/SnackbarComponent";
import TimeCalendarTemp from "pages/temp/time";
import FreeSlotCalendarTemp from "components/NewEvent/FreeSlotCalendarTemp";
import HelpComponent from "components/Global/Help";
import PhysicalLocation from "components/NewEvent/PhysicalLocation";
import PollingTimes from "components/NewEvent/PollingTimes";
import Description from "components/NewEvent/Description";
import useStyle from "styles/formStyles";
import Location from "components/NewEvent/Location";
import TimeInterval from "components/NewEvent/TimeInterval";
import RecurringMeetingCheck from "components/NewEvent/RecurringMeeting";
import StartTime from "components/NewEvent/StartTime";
import ErrorSnackbar from "components/ErrorSnackbar";
import CustomInterval from "components/NewEvent/CustomInterval";
import Polling from "components/NewEvent/Polling";
import Phone from "components/NewEvent/Phone";
import VirtualLink from "components/NewEvent/Link";
import ResponsiveWrapper from "./ResponsiveWrapper";
import Participants from "./Participants";
import Buttons from "./Buttons";
import useMeetingStore from "../../Utils/createMeetingStore";
import { useTranslation } from "react-i18next";
import ColorIndicator from "../meetings/ColorIndicator";
import { MeetingAgenda } from "./MeetingAgenda";
import { generateDoc } from "../CreateDoc";
import useSuggestionsStore from "../../Utils/suggestionsStore";
import SchedularNotificationPopup from "./SchedularNotificationPopup";
import useDelegatedAcess from "../../Utils/SwtichAccount";
import {
  findColor,
  uploadMultipleFileAttachments,
} from "../../Utils/CommonFunctions";
import MeetingTypeColor from "./MeetingTypeColor";
import useContactGroups from "../../Utils/hooks/useContactGroups";

/**
 * The primary component rendered in the Create Meeting dialog
 */
export default function CreateMeeting(props) {
  const classes = useStyle();
  const locationProps = useLocation();
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();
  const navigate = useNavigate();
  const previousURL = window.history.state?.usr?.previousURL || null;

  //refs
  const firstUpdate = useRef(true);
  const firstUpdate2 = useRef(true);
  const firstUpdate3 = useRef(true);

  const calendarRef = useRef(null);

  //contexts
  const { user } = useContext(integrationContext);
  const {
    setOrganizerMeetings,
    refetchData,
    meetingData,
    agendas,
    setAgendas,
  } = useMeetingStore((state) => state);

  //states
  const [timeInterval, setTimeInterval] = useState(
    () => meetingData?.timeInterval ?? ""
  );
  const [title, setTitle] = useState(() => meetingData?.title ?? "");
  const [description, setDescription] = useState(
    () => meetingData?.description ?? ""
  );

  const [location, setLocation] = useState(() =>
    meetingData?.locationKey
      ? meetingData?.locationKey
      : user && user.is_google_synced
      ? "Google Meet"
      : ""
  );
  const [participants, setParticipants] = useState(
    () => meetingData?.participants ?? []
  );

  const [meetingColorType, setMeetingColorType] = useState(
    () => meetingData?.meeting_type ?? undefined
  );

  // const [meetingTitle, setMeetingTitle] = useState(
  //   () => meetingData?.meetingTitle ?? "Create Meeting"
  // );
  const [eventCase, setEventCase] = useState(() => meetingData?.case ?? "");
  const [fileName, setFileName] = useState(
    () => meetingData?.attachment_file_name ?? ""
  );
  const [fileNames, setFileNames] = useState(
    () => meetingData?.attachment_file_names ?? []
  );
  const [events, setEvents] = useState(() => meetingData?.slots ?? []);
  const [pU, setPU] = useState(() => meetingData?.unavailability ?? []);
  const [room, setRoom] = useState(() => meetingData?.meeting_room ?? "");
  const [address, setAddress] = useState(() =>
    location === "Other" ? meetingData?.address : ""
  );
  const [meetingType, setMeetingType] = useState(
    () => meetingData?.meetingType ?? ""
  );
  const [customTime, setCustomTime] = useState(() =>
    timeInterval === "custom" ? meetingData?.customTime : ""
  );
  const [phone, setPhone] = useState(() =>
    location === "Phone" ? meetingData?.location : ""
  );
  const [link, setLink] = useState(() =>
    location === "Link"
      ? meetingData?.location
      : user && user.virtual_link
      ? user.virtual_link
      : ""
  );
  const [participantLabels, setParticipantLabels] = useState(
    () => meetingData?.participantLabels ?? []
  );
  const [selectedTimes, setSelectedTimes] = useState({ events: [] });
  const [qrMeetingParticipant, setQrMeetingParticipant] = useState("");
  const [error, setError] = useState("");
  const [modal, setModal] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState([]);
  const [errorAmount, setErrorAmount] = useState("");
  const [meetingCreated, setMeetingCreated] = useState(false);
  const [unavailability, setUnavailability] = useState([]);
  const [file, setFile] = useState(() => meetingData?.file ?? "");
  const [files, setFiles] = useState(() => meetingData?.files ?? []);
  const [months, setMonths] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);
  const [maxDays, setMaxDays] = useState([]);
  const [reset, setReset] = useState(true);
  const [createTemplate, setCreateTemplate] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [noCalendarModal, setNoCalendarModal] = useState(false);
  const [recommendedDate, setRecommendedDate] = useState("");
  const [date, setDate] = useState("1");
  const [month, setMonth] = useState("January");
  const [year, setYear] = useState("2022");
  const [monthInt, setMonthInt] = useState(3);
  const [selectedDate, setSelectedDate] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [sharedAvailableTimes, setSharedAvailableTimes] = useState([]);
  const [eventsLoaded, setEventsLoaded] = useState(false);
  const [meetingDetailExpand, setMeetingDetailExpand] = useState(false);
  const [mobileShowTimes, setMobileShowTimes] = useState(true);
  const [calView, setCalView] = useState(false);
  const [suggesstionsLoading, setSuggesstionsLoading] = useState(false);
  const [eventCal, setEventCal] = useState([]);
  const [contacts, groups, minimizedContacts] = useContactGroups(participants);

  // const [overlapCheck, setOverlapCheck] = useState(false);
  const delegatedMode = useDelegatedAcess((state) => state.delegatedMode);

  const {
    suggestedSlots,
    populateEvents,
    durationCheck,
    preferencesCheck,
    startTimeCheck,
    resetStore,
    startTime,
    setStartTime,
    setDurationCheck,
    formDate,
    setFormDate,
    blockedParticipants,
  } = useSuggestionsStore();

  const dateObject = {
    recommendedDate,
    setRecommendedDate,
    setDate,
    setMonth,
    setMonthInt,
    setYear,
    date,
    month,
    year,
  };

  /**
   * Sets the view to calendar view in mobile if the event is
   * multiple skeding : multiple nonskeding or one skeding : multiple nonskeding
   */
  useEffect(() => {
    if (
      eventCase == MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS ||
      eventCase == ONE_SKEDING_MULTIPLE_NONSKEDING_USERS
    ) {
      setCalView(true);
    }
  }, [eventCase]);

  /**
   * Sets state variables used to track the currently displayed date in the FullCalendar component
   * whenever the currently displayed date changes
   */
  useEffect(() => {
    if (calendarRef?.current) {
      setDate(calendarRef?.current?.getApi()?.getDate()?.getDate());

      setYear(
        calendarRef.current
          .getApi()
          .getDate()
          .toLocaleString("default", { year: "numeric" })
      );

      setMonth(
        calendarRef.current
          .getApi()
          .getDate()
          .toLocaleString("default", { month: "long" })
      );

      setMonthInt(calendarRef.current.getApi().getDate().getMonth());
    }
  }, [calendarRef]);

  /**
   * Moves the calendar to a clicked date, sets selectedDate and recommendedDate variables with the date that the calendar moves to
   * setDate is called when a time on the calendar is clicked
   * setMonthInt is called when the date on the calendar changes
   * setYear is called when the calendar date changes and when a time on the calendar is clicked
   */
  useEffect(() => {
    const monthString = handleMonth();
    const dateString = handleDate();

    if (calendarRef?.current) {
      calendarRef.current
        .getApi()
        .gotoDate(`${year.toString()}-${monthString}-${dateString}`);
      setSelectedDate(
        calendarRef.current
          .getApi()
          .getDate()
          .toLocaleString("default", { weekday: "long" })
      );
      setRecommendedDate(
        calendarRef.current.getApi().getDate().toLocaleDateString()
      );
    }
  }, [date, monthInt, year]);

  /**
   * Fetches a user's contacts, preferences and groups (teams) before a meeting is created
   */
  useEffect(() => {
    const abortController = new AbortController();

    getUserPreferences(abortController);

    return () => {
      abortController.abort();
    };
  }, []);

  /**
   * Fetches a user's contacts, preferences and groups (teams) after a meeting is created
   */
  useEffect(() => {
    const abortController = new AbortController();

    if (meetingCreated) {
      getUserPreferences(abortController);
    }

    return () => {
      abortController.abort();
    };
  }, [meetingCreated]);

  /**
   * show red color for non skeding users
   */
  useEffect(() => {
    getParticipantStatus();
  }, [participants]);

  // as it is in global state so everytime popup opens need to set it here to make minutes accurate
  useEffect(() => {
    setFormDate(moment());
  }, []);

  useEffect(() => {
    if (populateEvents && Object.keys(suggestedSlots).length > 0) {
      let parseResponse = [];
      const showData = async () => {
        if (
          suggestedSlots.case === MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS ||
          suggestedSlots.case === ONE_SKEDING_MULTIPLE_NONSKEDING_USERS
        ) {
          let result = await suggestedSlots.slotsData.map((item) => {
            return {
              start: convertUTCMillisecondsToDate(item.slotStart),
              end: convertUTCMillisecondsToDate(item.slotEnd),
              availableParticipants: item.availableParticipantsData,
              unAvailableParticipants: item.unavailableParticipantsData,
            };
          });
          parseResponse = [...parseResponse, ...result];
        } else {
          let result = await suggestedSlots.allAvailbleSlots.map((item) => {
            return {
              start: convertUTCMillisecondsToDate(item.startTime),
              end: convertUTCMillisecondsToDate(item.endTime),
            };
          });
          parseResponse = [...parseResponse, ...result];
          setOrganizerMeetings(suggestedSlots.organizerMeetings);
        }
        setEvents(parseResponse);
        setPU(suggestedSlots?.Participant_unavailibility);
        setEventCal([]);
        setReset((prev) => !prev);

        if (durationCheck) {
          let durationMins = moment.duration(timeInterval).asMinutes();
          if (durationMins > 15) {
            var minutes = durationMins - 15;
            setTimeInterval(`00:${minutes}:00`);
          }
        }
      };
      showData();
    }
  }, [populateEvents, suggestedSlots]);

  const getParticipantStatus = async () => {
    if (participants.length <= participantLabels.length) {
      const temp = [...participantLabels];
      const filteredRecords = temp.filter((record) =>
        participants.includes(record.email)
      );
      setParticipantLabels(filteredRecords);
    } else {
      if (participants.length > 0) {
        // const [lastEmail] = participants.slice(-1);

        //change color of participants
        const response = await serverPOST(`/contact/skedingstatus`, {
          participants,
        });
        if (response && response.constructor !== String) {
          // const temp = [...participantLabels, response.data];
          // setParticipantLabels(temp);
          setParticipantLabels(response.data);
        }
      }
    }
  };

  /**
   * Finds the available slots when the participants or time interval for the meeting are set
   */
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    checkSlots();
  }, [participants, customTime]);

  /**
   * Finds the available slots when the participants or time interval for the meeting are set
   */
  useLayoutEffect(() => {
    if (firstUpdate3.current) {
      firstUpdate3.current = false;
      return;
    }
    if (!durationCheck) checkSlots();
  }, [timeInterval]);

  /**
   * Fetches the available slots that match a given start time condition selected by the user
   */
  useLayoutEffect(() => {
    if (firstUpdate2.current) {
      firstUpdate2.current = false;
      return;
    }
    checkStartTimeSlots();
  }, [startTime]);

  /**
   * Sets the participant if the meeting is being created through a qr code
   */
  useLayoutEffect(() => {
    const participant = searchParams.get("participant");
    if (participant) {
      setQrMeetingParticipant(participant);
      setParticipants([participant?.toLowerCase()]);
    }
  }, []);

  const handleIntervalChange = (event) => {
    if (durationCheck) {
      setDurationCheck(false);
    }
    setTimeInterval(event.target.value);
  };

  /**
   * @returns {string} - the month formatted with a 0 appended if the value is < 10
   */
  const handleMonth = () => {
    const monthIncremented = monthInt + 1;
    switch (monthIncremented < 10) {
      case true:
        return "0" + monthIncremented.toString();
      default:
        return monthIncremented.toString();
    }
  };

  /**
   * @returns {string} - the date formatted with a 0 appended if the value is < 10
   */
  const handleDate = () => {
    switch (date < 10) {
      case true:
        return "0" + date.toString();
      default:
        return date.toString();
    }
  };

  const handleStartTime = (value) => {
    // setStartTime(event.target.value);
    setStartTime(value);
  };

  const handleClose = () => {
    setModal(false);
    setMeetingCreated(false);
    if (mobile) {
      props.closeDetailMobile();
    } else {
      props.closeDetail();
    }
    if (previousURL === "/shareable-meeting") {
      navigate(previousURL);
    } else {
      navigate("/meetings");
      return;
    }
  };

  const parseSuggestionResponse = async (data) => {
    let parseResponse;
    if (
      data.case === MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS ||
      data.case === ONE_SKEDING_MULTIPLE_NONSKEDING_USERS
    ) {
      parseResponse = await data.slotsData.map((item) => {
        return {
          start: convertUTCMillisecondsToDate(item.slotStart),
          end: convertUTCMillisecondsToDate(item.slotEnd),
          availableParticipants: item.availableParticipantsData,
          unAvailableParticipants: item.unavailableParticipantsData,
        };
      });
    } else {
      parseResponse = await data.allAvailbleSlots.map((item) => {
        return {
          start: convertUTCMillisecondsToDate(item.startTime),
          end: convertUTCMillisecondsToDate(item.endTime),
        };
      });

      setOrganizerMeetings(data.organizerMeetings);
    }
    return parseResponse;
  };

  //when old logic (syncing recommened slots with calendar )
  // const onCalendarMonthChange = async (dateInfo) => {
  //   if (calendarRef && timeInterval && participants.length > 0) {
  //     //calendar week start dates
  //     const startDate = moment(dateInfo?.start).startOf("day");
  //     const endDate = moment(dateInfo?.end).startOf("day");
  //     // const eventStartDate =
  //     //   events.length > 0 && moment(events[0]?.start).startOf("day");
  //     // const eventEndDate =
  //     //   events.length > 0 &&
  //     //   moment(events[events.length - 1]?.start).startOf("day");
  //     const recDate = moment(
  //       `${dateObject.date}/${dateObject.month}/${dateObject.year}`
  //     ).startOf("day");
  //     const currentDate = new Date();
  //     console.log(recDate, startDate, endDate);
  //     // if (recDate.isBetween(startDate, endDate, "days", "[]")) {
  //     loggingInDev("is between");
  //     // } else {
  //     calendarRef?.current?.getApi()?.removeAllEvents();
  //     loggingInDev("not betweenn");
  //     if (endDate < currentDate) {
  //       //do nothing
  //     } else {
  //       if (currentDate > startDate) {
  //         appendSlots(currentDate);
  //       } else {
  //         appendSlots(startDate);
  //       }
  //     }
  //     // }
  //   }
  // };

  /**
   * Fetches more available time slots if no time slots have been fetched for the current month in view
   * @param {object} dateInfo - calendar date object
   */
  const onCalendarMonthChange = (dateInfo) => {
    const lastValue = events.length > 0 && events[events.length - 1];
    const currentMonth = new Date(lastValue.start).getMonth();
    let calendarMonth;

    if (dateInfo.view.type === "timeGridWeek") {
      let newDate = new Date(dateInfo.end).getMonth();
      calendarMonth = newDate + 1;
    } else {
      calendarMonth = new Date(dateInfo.end).getMonth();
    }

    if (calendarMonth > currentMonth && months.includes(calendarMonth)) {
      appendSlots(new Date(lastValue.end));
      const index = months.indexOf(calendarMonth);
      if (index > -1) {
        months.splice(index, 1);
      }
      loggingInDev("months", months);
    } else {
      loggingInDev("past month");
    }
  };

  /**
   * Clears the form data after creating a meeting
   */
  const clearForm = () => {
    setSelectedSlot([]);
    setTimeInterval("01:00:00");
    setCustomTime("");
    setParticipants([]);
    setTitle("");
    setDescription("");
    setEvents([]);
    setMeetingColorType("");
    setLocation(
      locationProps?.state?.location
        ? locationProps?.state?.location
        : user && user.is_google_synced
        ? "Google Meet"
        : // : user && user.is_microsoft_synced
          // ? "Outlook"
          ""
    );
    setEventCase("");
    setDisabled(false);
    setCreateTemplate(false);
    setFileName("");
    setFileNames([]);
    setMonths([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);
    setEventCal([]);
    // setMeetingTitle("Create A New Meeting");
    setAddress("");
    setLink("");
    setPhone("");
    setMeetingType("");
    setRoom("");
    setAgendas([]);
    setPU([]);
    resetStore();
    firstUpdate.current = true;
    firstUpdate2.current = true;
    firstUpdate3.current = true;

    if (!meetingDetailExpand) {
      setMeetingDetailExpand(true);
    }
    if (mobileShowTimes) {
      setMobileShowTimes(false);
    }
    return true;
  };

  /**
   * Adds suggested time slots to the events array
   * @param {string} currentDate
   * @async
   */
  const appendSlots = async (currentDate) => {
    setSuggesstionsLoading(true);
    if (timeInterval === "custom" && customTime === "") {
      return;
    }
    const current_date = parseStartTime(currentDate);

    let duration_minutes =
      timeInterval === "custom"
        ? moment.duration(customTime).asMinutes()
        : moment.duration(timeInterval).asMinutes();

    if (participants.length > 0 && timeInterval !== "") {
      let params = `?offset=${new Date().getTimezoneOffset()}`;
      if (preferencesCheck) {
        params = params + `&preferences=0`;
      }

      const data = {
        current_date: convertDateToUTCMilliseconds(current_date),
        duration_minutes,
        participants: participants,
        blocked_participants: blockedParticipants,
      };

      const response = await serverGETPOST(
        `/meeting/suggestions${params}`,
        data
      );
      if (response && response.constructor !== String) {
        const parseResponse = await parseSuggestionResponse(response.data);
        if (
          eventCase === ONE_SKEDING_MULTIPLE_NONSKEDING_USERS ||
          eventCase === MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS
        ) {
          //polling case just append in previous
          setEvents(events.concat(parseResponse));
        } else {
          setEvents(parseResponse);
          setPU(response?.data?.Participant_unavailibility);
        }
        setEventsLoaded(true);
        setSuggesstionsLoading(false);
        // loggingInDev("appended Slots ==>", events);
      } else {
        loggingInDev("error in appending");
        setSuggesstionsLoading(false);
      }
    }
  };

  /**
   * Checks if slots are still valid when user is responding
   * @async
   */
  const checkSlots = async () => {
    if (
      participants.length === 0 ||
      timeInterval === "" ||
      (timeInterval === "custom" && customTime === "")
    ) {
      setEvents([]);
      setEventCase("");
      setEventCal([]);
      setSelectedSlot([]);
      setPU([]);
      return;
    }

    const current_date = parseStartTime(formDate.toDate());

    if (timeInterval === "custom" && customTime === "") {
      return;
    }
    if (participants.length > 0 && timeInterval !== "") {
      setSuggesstionsLoading(true);

      let duration_minutes =
        timeInterval === "custom"
          ? moment.duration(customTime).asMinutes()
          : moment.duration(timeInterval).asMinutes();

      let params = `?offset=${new Date().getTimezoneOffset()}`;
      if (preferencesCheck) {
        params = params + `&preferences=0`;
      }

      const data = {
        current_date: convertDateToUTCMilliseconds(current_date),
        duration_minutes,
        participants: participants,
        blocked_participants: blockedParticipants,
      };

      const response = await serverGETPOST(
        `/meeting/suggestions${params}`,
        data
      );
      if (response && response.constructor !== String) {
        const parseResponse = await parseSuggestionResponse(response.data);

        const index = months.indexOf(
          new Date(parseResponse[0]?.start).getMonth() + 1
        );
        if (index > -1) {
          months.splice(index, 1);
        }
        setPU(response.data.Participant_unavailibility);
        setEvents(parseResponse);
        setEventCase(response.data.case);
        setSelectedSlot([]);
        setEventCal([]);
        setReset((prev) => !prev);
        setEventsLoaded(true);
        setSuggesstionsLoading(false);
      } else {
        setSuggesstionsLoading(false);
        setError(response.data.message);
      }
    } else {
      setEvents([]);
    }
  };

  const parseStartTime = (currentDate) => {
    const current_date = currentDate ? new Date(currentDate) : new Date();
    if (startTime) {
      let start = startTime;
      if (start == "00") {
        start = "59";
      }

      current_date.setMinutes(parseInt(start));
      if (current_date < new Date()) {
        let hours = current_date.getHours();
        current_date.setHours(hours + 1);
      }
    }
    return current_date;
  };
  /**
   * Fetches the time slots for a given start time selected by the user
   * (starting on the hour or in in 15 minute increments from the start of the hour)
   */
  const checkStartTimeSlots = async () => {
    // no need to run if slots are to be fetched from fecth button click
    if (startTimeCheck) return;

    const current_date = parseStartTime(formDate.toDate());

    if (timeInterval === "custom" && customTime === "") {
      return;
    }
    let duration_minutes =
      timeInterval === "custom"
        ? moment.duration(customTime).asMinutes()
        : moment.duration(timeInterval).asMinutes();

    if (participants.length > 0 && timeInterval !== "") {
      setSuggesstionsLoading(true);

      let params = `?offset=${new Date().getTimezoneOffset()}`;
      if (preferencesCheck) {
        params = params + `&preferences=0`;
      }

      const data = {
        current_date: convertDateToUTCMilliseconds(current_date),
        duration_minutes,
        participants: participants,
        blocked_participants: blockedParticipants,
      };

      const response = await serverGETPOST(
        `/meeting/suggestions${params}`,
        data
      );

      if (response && response.constructor !== String) {
        const parseResponse = await parseSuggestionResponse(response.data);

        const index = months.indexOf(
          new Date(parseResponse[0]?.start).getMonth() + 1
        );

        if (index > -1) {
          months.splice(index, 1);
        }

        setEvents(parseResponse);
        setEventCase(response.data.case);
        setPU(response.data.Participant_unavailibility);
        setEventsLoaded(true);
        setSuggesstionsLoading(false);
      } else {
        setSuggesstionsLoading(false);
        setError(response.data.message);
      }
    } else {
      setEvents([]);
    }
  };

  /**
   * Fetches the preferences that the user has set on the preferences page
   * @param {AbortController} abortController
   * @returns - user preferences object
   * @async
   */
  const getUserPreferences = async (abortController) => {
    try {
      if (meetingData?.organizer_preferences) {
        setUnavailability(meetingData?.organizer_preferences);
        return;
      }

      const response = await serverGET("/preference", abortController.signal);
      if (response && response.constructor !== String) {
        const parsedUnavailability = response.data.blocked_timeslots;
        setUnavailability(parsedUnavailability);
      } else {
        return response.data.message;
      }
    } catch (e) {
      loggingInDev(e);
    }
  };

  /**
   * Makes a request to the backend to create a meeting
   * @async
   */
  const createMeeting = async () => {
    setDisabled(true);
    setError("");

    if (!user?.is_google_synced) {
      if (!user?.is_microsoft_synced) {
        setNoCalendarModal((prev) => !prev);
        setDisabled(false);
        return;
      }
    }
    if (
      timeInterval === "" ||
      participants.length < 1 ||
      location === "" ||
      (timeInterval === "custom" && customTime === "")
    ) {
      setError(t("mandatory_field_error"));
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setDisabled(false);
      return;
    }
    if (
      (location === "Other" && address === "") ||
      (location === "Phone" && phone === "") ||
      (location === "Link" && link === "")
    ) {
      setError(t("empty_error", { field: "location" }));
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setDisabled(false);
      return;
    }
    if (location === "Phone" && !isValidPhoneNumber(phone)) {
      setError(t("phone_err"));
      setDisabled(false);
      return;
    }
    if (location === "Link" && !checkUrl(link)) {
      setError(t("link_err"));
      setDisabled(false);

      return;
    }
    setMaxDays([]);

    let recurStart;
    let duration_minutes =
      timeInterval === "custom"
        ? moment.duration(customTime).asMinutes()
        : moment.duration(timeInterval).asMinutes();

    if (
      eventCase === ONE_SKEDING_MULTIPLE_NONSKEDING_USERS ||
      eventCase === MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS
    ) {
      recurStart = selectedSlot[0]?.start;
    } else {
      recurStart = selectedSlot[0]?.value?.start;
    }
    let data;
    var loc =
      location === "Other"
        ? address
        : location === "Phone"
        ? phone
        : location === "Link"
        ? link
        : location;

    let start_datetime;
    if (meetingType) {
      start_datetime =
        typeof recurStart === "number"
          ? recurStart
          : convertDateToUTCMilliseconds(recurStart);
    } else {
      if (eventCase === ALL_SKEDING_USERS) {
        start_datetime =
          typeof selectedSlot[0]?.value?.start === "number"
            ? selectedSlot[0]?.value?.start
            : convertDateToUTCMilliseconds(selectedSlot[0]?.value?.start);
      }
    }

    let fileCopies = files;
    let fileNameCopies = fileNames;
    let agendaDoc = null;
    if (agendas.length > 0) {
      const emptyAgendas = agendas.filter((a) => a.topic.trim() === "");
      if (emptyAgendas.length > 0) {
        setError(t("agenda_error"));
        setDisabled(false);

        return;
      }
      agendaDoc = await generateDoc(agendas, {
        start_datetime,
        duration_minutes,
      });

      fileCopies = [...files, agendaDoc];
      fileNameCopies = [
        ...fileNames,
        title ? `Agenda: ${title}.docx` : "Agenda.docx",
      ];
    }

    if (meetingType) {
      data = {
        title: title ? title : undefined,
        start_datetime,
        duration_minutes,
        participants: participants,
        time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        utc_offset: new Date().getTimezoneOffset(),
        location: loc,
        description: description ? description : undefined,
        case: RECURRING_EVENT,
        // attachment_file_name: fileNameCopy ? fileNameCopy : undefined,
        attachment_file_name: fileNameCopies ? fileNameCopies : undefined,
        meeting_type: {
          ...meetingColorType,
          bgColor: findColor(RECURRING_EVENT, participants, contacts),
        },

        meeting_room:
          location === "Other" ? (room ? room : undefined) : undefined,
        recurring_details: {
          freq: meetingType,
        },
      };
    } else {
      if (eventCase === ALL_SKEDING_USERS) {
        data = {
          title: title ? title : undefined,
          start_datetime,
          duration_minutes,
          participants: participants,
          time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          utc_offset: new Date().getTimezoneOffset(),
          location: loc,
          description: description ? description : undefined,
          case: eventCase,
          meeting_type: {
            ...meetingColorType,
            bgColor: findColor(eventCase, participants, contacts),
          },
          // attachment_file_name: fileNameCopy ? fileNameCopy : undefined,
          attachment_file_name: fileNameCopies ? fileNameCopies : undefined,
          meeting_room:
            location === "Other" ? (room ? room : undefined) : undefined,
        };
      } else if (
        eventCase === ONE_NONSKEDING_ONE_SKEDING_USER ||
        eventCase === ONE_NONSKEDING_MULTIPLE_SKEDING_USERS ||
        eventCase === MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS ||
        eventCase === ONE_SKEDING_MULTIPLE_NONSKEDING_USERS
      ) {
        if (
          eventCase === MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS ||
          eventCase === ONE_SKEDING_MULTIPLE_NONSKEDING_USERS
        ) {
          selectedSlot?.length > 0 &&
            selectedSlot?.map((d) => {
              if (!maxDays.includes(moment(d.start).format("DD/MM/YYYY"))) {
                maxDays.push(moment(d.start).format("DD/MM/YYYY"));
              }
            });
          if (maxDays.length < 3 || maxDays.length > 15) {
            setErrorAmount(
              "You need to select a minimum of three or a maximum of fifteen days with available times"
            );
            setDisabled(false);
            return;
          }
        } else {
          if (selectedSlot.length < 1) {
            setErrorAmount(
              "We recommend choosing several timeslots for the convenience of your participants"
            );
            setDisabled(false);
            return;
          }
        }

        let parsedSlots;
        if (
          eventCase === MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS ||
          eventCase === ONE_SKEDING_MULTIPLE_NONSKEDING_USERS
        ) {
          parsedSlots =
            selectedSlot &&
            selectedSlot.map((slot) => {
              return {
                startTime:
                  typeof slot.start === "number"
                    ? slot.start
                    : convertDateToUTCMilliseconds(slot.start),

                endTime:
                  typeof slot.end === "number"
                    ? slot.end
                    : convertDateToUTCMilliseconds(slot.end),
              };
            });
        } else {
          parsedSlots =
            selectedSlot &&
            selectedSlot.map((slot) => {
              return {
                startTime:
                  typeof slot.value.start === "number"
                    ? slot.value.start
                    : convertDateToUTCMilliseconds(slot.value.start),
                endTime:
                  typeof slot.value.end === "number"
                    ? slot.value.end
                    : convertDateToUTCMilliseconds(slot.value.end),
              };
            });
        }

        data = {
          title: title ? title : undefined,
          proposed_timeslots: parsedSlots,
          duration_minutes,
          participants: participants,
          time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          utc_offset: new Date().getTimezoneOffset(),
          location: loc,
          description: description ? description : undefined,
          case: eventCase,
          meeting_type: {
            ...meetingColorType,
            bgColor: findColor(eventCase, participants, contacts),
          },
          // attachment_file_name: fileNameCopy ? fileNameCopy : undefined,
          attachment_file_name: fileNameCopies ? fileNameCopies : undefined,
          meeting_room:
            location === "Other" ? (room ? room : undefined) : undefined,
          draft_id: meetingData.draft_id,
        };
      }
    }

    const response = await serverPOST("/meeting", data);

    if (response && response.constructor !== String) {
      if (fileNameCopies.length > 0) {
        await uploadMultipleFileAttachments(
          fileNameCopies,
          fileCopies,
          response.data._id
        );
      }
      if (createTemplate) {
        let data = {
          title,
          agenda: description,
          location,
          duration: duration_minutes,
        };
        const response = await serverPOST("/meeting-template", data);
        if (response && response.constructor !== String) {
          setDisabled(false);
        } else {
          setDisabled(false);
          console.log("create template failed");
        }
      }
      setModal(true);
      refetchData();
      clearForm();
      // if (previousURL === "/shareable-meeting") {
      //   setTimeout(() => {
      //     navigate(previousURL);
      //   }, 4000);
      // } else {
      //   setTimeout(() => {
      //     navigate("/meetings");
      //   }, 3000);
      //   return;
      // }
    } else {
      setDisabled(false);
      setError(response);
    }
  };

  /**
   * Makes a request to the backend to create a nonskeding : skeding meeting
   * @async
   */
  const createNonToSkedingMeeting = async () => {
    setDisabled(true);
    const { meetingData } = props;

    const data = {
      title: meetingData.title,
      start_datetime: convertDateToUTCMilliseconds(
        selectedSlot[0]?.value.start
      ),
      duration_minutes: meetingData.duration_minutes,
      participants: meetingData.non_skeding_participants,
      time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      utc_offset: new Date().getTimezoneOffset(),
      location: meetingData.location,
      description: meetingData.description,
      case: eventCase,
      draft_id: meetingData?.draft_id,
      meeting_type: {
        ...meetingColorType,
        bgColor: findColor(
          eventCase,
          meetingData.non_skeding_participants,
          contacts
        ),
      },
    };

    const response = await serverPOST("/meeting", data);
    if (response && response.constructor !== String) {
      setModal(true);
      setMeetingCreated(true);
      setDisabled(false);
      clearForm();
      if (mobile) {
        props.closeDetailMobile();
      }
    } else {
      setDisabled(false);
      setError(response);
    }
  };

  const handleMeetingTypeChange = (selectedMeeting) => {
    setMeetingColorType(selectedMeeting);
  };

  return (
    <Style>
      {`
          .fc-timegrid-event-harness-inset {
            left: -2px !important;
            right: -2px !important;
          }
          .fc .fc-timegrid-slot{
            background-color: ${
              eventCase === MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS ||
              eventCase === ONE_SKEDING_MULTIPLE_NONSKEDING_USERS
                ? "rgba(218, 210, 229)"
                : "#c4e7ffb0"
            };
            border-bottom: 1px solid rgba(200, 200, 201, 0.60);
          }
         
          .fc .fc-timegrid-slot-label{
            background-color:inherit;
          }
          .fc-timegrid-event-harness-inset .fc-timegrid-event, .fc-timegrid-event.fc-event-mirror, .fc-timegrid-more-link{
            box-shadow: 0px 0px 1px 0px var(--fc-page-bg-color, #fff)
          }
          .fc-today-button {
            background-color: #479ae9 !important;
            color:#fff;  
          }
      `}
      <div id={"calender"}>
        <SnackbarComponent
          open={modal}
          handleClose={handleClose}
          message={t("invite_sent")}
          vertical={"bottom"}
          horizontal={"center"}
        />
        <Backdrop
          sx={{
            color: theme.palette.primary.main,
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={suggesstionsLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <ErrorSnackbar
          open={!!error}
          handleClose={() => setError("")}
          message={error}
          vertical={"bottom"}
          horizontal={"center"}
        />

        {props?.isNonSkeding ? null : (
          <>
            <ResponsiveWrapper
              meetingDetailExpand={meetingDetailExpand}
              setMeetingDetailExpand={setMeetingDetailExpand}
              closeDetail={props.closeDetail}
              mobileShowTimes={mobileShowTimes}
              title={title}
              location={location}
              timeInterval={timeInterval}
              participants={participants}
              description={description}
              customTime={customTime}
            >
              <Grid container alignItems="center" className={classes.root}>
                <Grid item xs={12} md={1.5}>
                  <InputLabel htmlFor="title" style={{ marginTop: "10px" }}>
                    <Typography variant="h4" align="left">
                      {t("title") + ":"}
                    </Typography>
                  </InputLabel>
                </Grid>
                <Grid item xs={11} md={10.5}>
                  <Title setTitle={setTitle} title={title} />
                </Grid>
                {qrMeetingParticipant.length === 0 ? (
                  <>
                    <Grid item xs={11} md={1.5}>
                      <InputLabel htmlFor="title" style={{ marginTop: "10px" }}>
                        <Typography variant="h4" width={"100px"}>
                          {t("participants") + ":"}
                        </Typography>
                      </InputLabel>
                    </Grid>
                    <Grid
                      item
                      xs={11}
                      md={10.5}
                      className={classes.participant}
                    >
                      <Participants
                        setParticipants={setParticipants}
                        participants={participants}
                        contacts={contacts}
                        groups={groups}
                        minimizedContacts={minimizedContacts}
                        user={user}
                        participantLabels={participantLabels}
                      />
                      <Grid display={"flex"} alignItems={"center"}>
                        <ColorIndicator text={t("member")} color={"#B0E4FF"} />
                        <ColorIndicator
                          text={t("non_member")}
                          color={"#D5D6D7"}
                        />
                      </Grid>
                    </Grid>
                  </>
                ) : null}
                {props.isMobile ? (
                  <>
                    <Grid item xs={12} md={1}>
                      <InputLabel htmlFor="title" style={{ marginTop: "10px" }}>
                        <Typography variant="h4" align="left">
                          {t("duration") + ":"}
                        </Typography>
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12} md={11} align="left">
                      <TimeInterval
                        timeInterval={timeInterval}
                        handleIntervalChange={handleIntervalChange}
                      />
                    </Grid>

                    {/* <Grid item xs={12} md={1}>
                      <InputLabel htmlFor="title" style={{ marginTop: "10px" }}>
                        <Typography variant="h4" align="left">
                          Start Time:
                        </Typography>
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12} md={11}>
                      <Box
                        display={"flex"}
                        alignItems="center"
                        justifyContent={"flex-start"}
                      >
                        <StartTime
                          startTime={startTime}
                          handleStartTime={handleStartTime}
                          timeInterval={timeInterval}
                          customTime={customTime}
                        />
                        <Box ml={1} mt={"10px"}>
                          <HelpComponent
                            msg={NEW_MEETINGS_START_TIME}
                            position={"bottom"}
                          />
                        </Box>
                      </Box>
                    </Grid> */}
                  </>
                ) : (
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                    alignItems={"center"}
                  >
                    <Grid item xs={12} md={1.5}>
                      <InputLabel htmlFor="title" style={{ marginTop: "10px" }}>
                        <Typography variant="h4">
                          {t("duration") + ":"}
                        </Typography>
                      </InputLabel>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={12}
                      md={10.5}
                      alignItems={"center"}
                      style={{ justifyContent: "space-between" }}
                    >
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        width="100%"
                        alignItems={"center"}
                      >
                        <TimeInterval
                          timeInterval={timeInterval}
                          handleIntervalChange={handleIntervalChange}
                        />
                        <Box display="flex" alignItems={"center"} flex={0.8}>
                          <MeetingTypeColor
                            meetingType={meetingColorType}
                            handleMeetingTypeChange={handleMeetingTypeChange}
                          />
                        </Box>
                      </Box>

                      {/* <Box display={"flex"} alignItems="center">
                        <InputLabel
                          htmlFor="title"
                          style={{ marginTop: "10px", marginRight: "12px" }}
                        >
                          <Typography variant="h4">Start Time:</Typography>
                        </InputLabel>
                        <Box
                          display={"flex"}
                          alignItems="center"
                          justifyContent={"flex-start"}
                        >
                          <StartTime
                            startTime={startTime}
                            handleStartTime={handleStartTime}
                            timeInterval={timeInterval}
                            customTime={customTime}
                          />
                          <Box ml={1} mt={"10px"}>
                            <HelpComponent
                              msg={NEW_MEETINGS_START_TIME}
                              position={"bottom"}
                            />
                          </Box>
                        </Box>
                      </Box> */}
                    </Grid>
                  </Box>
                )}
                {timeInterval === "custom" ? (
                  <Grid container item xs={12} alignItems="center">
                    <Grid item xs={12} md={1.5}>
                      <InputLabel htmlFor="title" style={{ marginTop: "10px" }}>
                        <Typography variant="h4" align="left">
                          {t("custom") + ":"}
                        </Typography>
                      </InputLabel>
                    </Grid>
                    <Grid item xs={10.5}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        width="100%"
                      >
                        <CustomInterval
                          setCustomTime={setCustomTime}
                          customTime={customTime}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                ) : null}
                <Grid item xs={12} md={1.5}>
                  <InputLabel htmlFor="title" style={{ marginTop: "10px" }}>
                    <Typography variant="h4" align="left">
                      {t("location") + ":"}
                    </Typography>
                  </InputLabel>
                </Grid>
                <Grid container item xs={11} md={10.5}>
                  <Location
                    setLocation={setLocation}
                    location={location}
                    user={user}
                  />
                </Grid>
                {location === "Other" && (
                  <>
                    <Grid container item xs={12} alignItems="center">
                      <Grid item xs={12} md={1.5}>
                        <InputLabel
                          htmlFor="title"
                          style={{ marginTop: "10px" }}
                        >
                          <Typography variant="h4" align="left">
                            {t("address") + ":"}
                          </Typography>
                        </InputLabel>
                      </Grid>
                      <Grid item xs={10.5}>
                        <PhysicalLocation
                          setAddress={setAddress}
                          address={address}
                        />
                      </Grid>
                    </Grid>

                    <Grid container item xs={12} alignItems="center">
                      <Grid item xs={12} md={1.5}>
                        <InputLabel
                          htmlFor="title"
                          style={{ marginTop: "10px" }}
                        >
                          <Typography variant="h4" align="left">
                            {t("room") + ":"}
                          </Typography>
                        </InputLabel>
                      </Grid>
                      <Grid item xs={11} md={10.5}>
                        <RoomNo room={room} setRoom={setRoom} />
                      </Grid>
                    </Grid>
                  </>
                )}
                {location === "Phone" && (
                  <>
                    <Grid container item xs={12} alignItems="center">
                      <Grid item xs={12} md={1.5}>
                        <InputLabel
                          htmlFor="title"
                          style={{ marginTop: "10px" }}
                        >
                          <Typography variant="h4" align="left">
                            {t("phone_number") + ":"}
                          </Typography>
                        </InputLabel>
                      </Grid>
                      <Grid item xs={10.5}>
                        <Phone setPhone={setPhone} phone={phone} />
                      </Grid>
                    </Grid>
                  </>
                )}
                {location === "Link" && (
                  <>
                    <Grid container item xs={12} alignItems="center">
                      <Grid item xs={12} md={1.5}>
                        <InputLabel
                          htmlFor="title"
                          style={{ marginTop: "10px" }}
                        >
                          <Typography variant="h4" align="left">
                            {t("virtual") + ":"}
                          </Typography>
                        </InputLabel>
                      </Grid>
                      <Grid item xs={11} md={10.5}>
                        <VirtualLink setLink={setLink} link={link} />
                      </Grid>
                    </Grid>
                  </>
                )}
                <Grid container item xs={12}>
                  <Grid
                    item
                    xs={12}
                    md={1.5}
                    justifyContent={"space-between"}
                    display={"flex"}
                    direction={"column"}
                  >
                    <InputLabel htmlFor="title" style={{ marginTop: "22px" }}>
                      <Typography variant="h4" align="left">
                        {t("desc") + ":"}
                      </Typography>
                    </InputLabel>
                    {mobile ? (
                      <></>
                    ) : (
                      <Typography variant="h4"> {t("attachment")}:</Typography>
                    )}
                  </Grid>
                  <Grid item xs={11} md={10.5}>
                    <Description
                      setDescription={setDescription}
                      description={description}
                      showFile={true}
                      file={file}
                      setFile={setFile}
                      fileName={fileName}
                      setFileName={setFileName}
                      files={files}
                      setFiles={setFiles}
                      fileNames={fileNames}
                      setFileNames={setFileNames}
                      isMobile={mobile}
                    />
                  </Grid>
                </Grid>
                <MeetingAgenda
                  participants={participants}
                  maxInterval={timeInterval}
                />
              </Grid>
            </ResponsiveWrapper>

            {timeInterval === "" ||
            (timeInterval === "custom" && customTime === "") ||
            participants.length < 1 ||
            [
              MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS,
              ONE_SKEDING_MULTIPLE_NONSKEDING_USERS,
            ].includes(eventCase) ? null : (
              <Grid container>
                <Grid item xs={11}>
                  <Box
                    sx={{
                      marginTop: 1,
                      flexGrow: 1,
                      // display: { xs: "none", md: "block" },
                      marginTop: "10px",
                    }}
                    display={"flex"}
                    alignItems="center"
                    justifyContent={"flex-start"}
                  >
                    <RecurringMeetingCheck
                      meetingType={meetingType}
                      setMeetingType={setMeetingType}
                    />

                    <Box ml={-1} mt={"16px"}>
                      <HelpComponent
                        msg={NEW_MEETINGS_RECURRING}
                        position={"bottom"}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            )}

            {timeInterval === "" ||
            (timeInterval === "custom" && customTime === "") ||
            participants.length < 1
              ? null
              : mobile &&
                mobileShowTimes && (
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    style={{ width: "100%" }}
                  >
                    <Button
                      variant="text"
                      style={{ fontSize: 14 }}
                      startIcon={calView ? <ListIcon /> : <CalendarMonthIcon />}
                      sx={{
                        display:
                          eventCase ==
                            MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS ||
                          eventCase == ONE_SKEDING_MULTIPLE_NONSKEDING_USERS
                            ? "none"
                            : "flex",
                      }}
                      onClick={() => setCalView((prev) => !prev)}
                    >
                      {calView ? t("list_view") : t("cal_view")}
                    </Button>
                  </Box>
                )}
            <Grid container className={classes.root}>
              <Grid
                item
                xs={12}
                md={5}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  // height: "75vh",
                  overflow: "auto",
                }}
              >
                {mobile ? (
                  <>
                    <Box
                      mt={2}
                      display="flex"
                      justifyContent={"center"}
                      style={{ width: "100%" }}
                      sx={{ display: mobileShowTimes ? "none" : "block" }}
                    >
                      <Button
                        variant="contained"
                        style={{ minWidth: 100 }}
                        disabled={
                          participants.length < 1 ||
                          timeInterval === "" ||
                          (timeInterval === "custom" && customTime === "")
                        }
                        onClick={() => {
                          setMobileShowTimes(true);
                          setMeetingDetailExpand(false);
                        }}
                      >
                        {t("next")}
                      </Button>
                    </Box>
                  </>
                ) : (
                  <Grid
                    item
                    xs={12}
                    style={{
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    {timeInterval === "" ||
                    (timeInterval === "custom" && customTime === "") ||
                    participants.length < 1 ? null : eventCase ===
                        MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS ||
                      eventCase === ONE_SKEDING_MULTIPLE_NONSKEDING_USERS ? (
                      <PollingTimes
                        eventCase={eventCase}
                        selectedSlot={selectedSlot}
                        setSelectedSlot={setSelectedSlot}
                        errorAmount={errorAmount}
                        setErrorAmount={setErrorAmount}
                        meetingCreated={meetingCreated}
                        selectedTimes={selectedTimes}
                        setSelectedTimes={setSelectedTimes}
                        meetingType={meetingType}
                      />
                    ) : (
                      <>
                        {![
                          MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS,
                          ONE_SKEDING_MULTIPLE_NONSKEDING_USERS,
                        ].includes(eventCase) && (
                          <Box
                            display={"flex"}
                            justifyContent="flex-start"
                            alignItems={"center"}
                            // mt={"-10px"}
                          >
                            <InputLabel
                              htmlFor="title"
                              // style={{ marginTop: "10px" }}
                            >
                              <Typography variant="h4">
                                {t("start_time") + ": "}{" "}
                              </Typography>
                            </InputLabel>
                            <StartTime
                              startTime={startTime}
                              handleStartTime={handleStartTime}
                              timeInterval={timeInterval}
                              customTime={customTime}
                            />

                            <Box ml={1} mt={"10px"}>
                              <HelpComponent
                                msg={NEW_MEETINGS_START_TIME}
                                position={"bottom"}
                              />
                            </Box>
                          </Box>
                        )}
                        <ShowTimes
                          eventCal={eventCal}
                          setEventCal={setEventCal}
                          formDate={formDate}
                          setFormDate={setFormDate}
                          events={events}
                          eventCase={eventCase}
                          sharedAvailableTimes={sharedAvailableTimes}
                          setSharedAvailableTimes={setSharedAvailableTimes}
                          selectedSlot={selectedSlot}
                          setSelectedSlot={setSelectedSlot}
                          errorAmount={errorAmount}
                          setErrorAmount={setErrorAmount}
                          meetingCreated={meetingCreated}
                          calendarRef={calendarRef}
                          dateObject={dateObject}
                          selectedDate={selectedDate}
                          error={errorMsg}
                          // qrMeeting={qrMeeting}
                          appendSlots={appendSlots}
                          months={months}
                          meetingType={meetingType}
                          isPopup={true}
                          createMeeting={true}
                          participants={participants}
                          participantLabels={participantLabels}
                          allUnavailableSlots={pU?.allUnavailableSlots}
                          duration={
                            timeInterval === "custom"
                              ? moment.duration(customTime).asMinutes()
                              : moment.duration(timeInterval).asMinutes()
                          }
                        />
                      </>
                    )}
                  </Grid>
                )}
              </Grid>
              {mobile && mobileShowTimes && !calView && (
                <>
                  {!meetingDetailExpand &&
                    ![
                      MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS,
                      ONE_SKEDING_MULTIPLE_NONSKEDING_USERS,
                    ].includes(eventCase) && (
                      <Box
                        display={"flex"}
                        justifyContent="flex-start"
                        alignItems={"center"}
                        // mt={"-10px"}
                        // mb={"20px"}
                        style={{ width: "100%" }}
                      >
                        <InputLabel
                          htmlFor="title"
                          // style={{ marginTop: "10px" }}
                        >
                          <Typography variant="h4" mr={1}>
                            {t("start_at") + ":"}
                          </Typography>
                        </InputLabel>
                        <StartTime
                          startTime={startTime}
                          handleStartTime={handleStartTime}
                          timeInterval={timeInterval}
                          customTime={customTime}
                        />
                      </Box>
                    )}

                  <Fade in={mobileShowTimes && !calView} timeout={1000}>
                    <Grid
                      item
                      xs={12}
                      style={{
                        position: "relative",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        display:
                          eventCase ===
                            MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS ||
                          eventCase === ONE_SKEDING_MULTIPLE_NONSKEDING_USERS
                            ? "none"
                            : "block",
                      }}
                    >
                      {timeInterval === "" ||
                      (timeInterval === "custom" && customTime === "") ||
                      participants.length < 1 ? null : (
                        <ShowTimes
                          eventCal={eventCal}
                          setEventCal={setEventCal}
                          events={events}
                          eventCase={eventCase}
                          formDate={formDate}
                          setFormDate={setFormDate}
                          sharedAvailableTimes={sharedAvailableTimes}
                          setSharedAvailableTimes={setSharedAvailableTimes}
                          selectedSlot={selectedSlot}
                          setSelectedSlot={setSelectedSlot}
                          errorAmount={errorAmount}
                          setErrorAmount={setErrorAmount}
                          meetingCreated={meetingCreated}
                          month={month}
                          dateObject={dateObject}
                          error={errorMsg}
                          // qrMeeting={qrMeeting}
                          appendSlots={appendSlots}
                          months={months}
                          meetingType={meetingType}
                          isPopup={true}
                          createMeeting={true}
                          participants={participants}
                          participantLabels={participantLabels}
                          allUnavailableSlots={pU.allUnavailableSlots}
                          duration={
                            timeInterval === "custom"
                              ? moment.duration(customTime).asMinutes()
                              : moment.duration(timeInterval).asMinutes()
                          }
                        />
                      )}
                    </Grid>
                  </Fade>
                </>
              )}

              <Grid
                item
                xs={12}
                md={7}
                style={{
                  display: mobile ? (calView ? "block" : "none") : "block",
                  marginBottom: mobile ? 30 : 0,
                }}
              >
                {eventCase &&
                (eventCase === MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS ||
                  eventCase === ONE_SKEDING_MULTIPLE_NONSKEDING_USERS) &&
                participants.length > 0 &&
                timeInterval !== "" ? (
                  mobile ? (
                    mobileShowTimes ? (
                      <Polling
                        timeInterval={
                          timeInterval === "custom" && customTime
                            ? customTime
                            : timeInterval
                        }
                        events={events && events}
                        eventCase={eventCase}
                        selectedSlot={selectedSlot}
                        setSelectedSlot={setSelectedSlot}
                        participants={participants}
                        errorAmount={errorAmount}
                        setErrorAmount={setErrorAmount}
                        onCalendarMonthChange={onCalendarMonthChange}
                        contacts={contacts}
                        allNonSkedingResponded={false}
                        maxDays={maxDays}
                        reset={reset}
                        popover={true}
                        initiatorResponse={false}
                        selectedTimes={selectedTimes}
                        setSelectedTimes={setSelectedTimes}
                        meetingType={meetingType}
                        appendSlots={appendSlots}
                        newMeeting={true}
                        showMsg={true}
                      />
                    ) : null
                  ) : (
                    <>
                      <Box
                        style={{
                          marginLeft: mobile ? 0 : 20,
                          paddingTop: mobile && meetingDetailExpand ? 20 : 8,
                          marginBottom: 14,
                        }}
                      >
                        <Box display="flex" alignItems="center">
                          <Typography
                            variant="h3"
                            align="left"
                            fontWeight="600"
                          >
                            {t("pick_time")}
                          </Typography>
                          {mobile && (
                            <Box ml={1}>
                              <HelpComponent
                                msg={`tap_msg`}
                                position={"bottom"}
                              />
                            </Box>
                          )}
                        </Box>
                        <Typography
                          variant="h4"
                          align="left"
                          style={{ color: "#70757A", fontWeight: 400 }}
                        >
                          {t("polling_slots_select")}
                        </Typography>
                      </Box>

                      <Box>
                        <FreeSlotCalendarTemp
                          events={events && events}
                          setSelectedSlot={setSelectedSlot}
                          participants={participants}
                          errorAmount={errorAmount}
                          setErrorAmount={setErrorAmount}
                          onCalendarMonthChange={onCalendarMonthChange}
                          reset={reset}
                          popover={true}
                          initiatorResponse={false}
                          selectedTimes={selectedTimes}
                          setSelectedTimes={setSelectedTimes}
                          meetingType={meetingType}
                          createMeeting={true}
                        />
                      </Box>
                    </>
                  )
                ) : (
                  <>
                    {(mobile && mobileShowTimes) || !mobile ? (
                      <>
                        <Box
                          style={{
                            marginLeft: mobile ? 0 : 20,
                            marginBottom: 14,
                          }}
                          mt={1}
                        >
                          <Box display="flex" alignItems="center">
                            <Typography
                              variant="h3"
                              align="left"
                              fontWeight="600"
                            >
                              {t("pick_time")}
                            </Typography>
                            {mobile && (
                              <Box ml={1}>
                                <HelpComponent
                                  msg={`tap_msg2`}
                                  position={"bottom"}
                                />
                              </Box>
                            )}
                          </Box>
                          <Typography
                            variant="h4"
                            fontWeight={400}
                            style={{ color: "#70757A" }}
                          >
                            {t("blue_msg")}
                          </Typography>
                        </Box>
                        <Box>
                          <TimeCalendarTemp
                            timeInterval={
                              timeInterval === "custom" && customTime
                                ? customTime
                                : timeInterval
                            }
                            events={events}
                            sharedAvailableTimes={sharedAvailableTimes}
                            setSharedAvailableTimes={setSharedAvailableTimes}
                            eventCase={eventCase}
                            selectedSlot={selectedSlot}
                            setSelectedSlot={setSelectedSlot}
                            participants={participants}
                            errorAmount={errorAmount}
                            setErrorAmount={setErrorAmount}
                            meetingCreated={meetingCreated}
                            setEventCal={setEventCal}
                            eventCal={eventCal}
                            onCalendarMonthChange={onCalendarMonthChange}
                            unavailability={unavailability}
                            createTemplate={createTemplate}
                            setCreateTemplate={setCreateTemplate}
                            calendarRef={calendarRef}
                            error={errorMsg}
                            setError={setErrorMsg}
                            eventsLoaded={eventsLoaded}
                            meetingType={meetingType}
                            pU={pU}
                            // overlapCheck={overlapCheck}
                            // setOverlapCheck={setOverlapCheck}
                            setSelectedDate={setSelectedDate}
                            setDate={setDate}
                            setMonth={setMonth}
                            setYear={setYear}
                            setRecommendedDate={setRecommendedDate}
                          />
                        </Box>
                      </>
                    ) : null}
                  </>
                )}
              </Grid>
              {mobile &&
                mobileShowTimes &&
                (props?.meetingData?.case === ONE_NONSKEDING_ONE_SKEDING_USER &&
                eventCase === ALL_SKEDING_USERS ? (
                  <Buttons
                    createMeeting={createNonToSkedingMeeting}
                    clearForm={clearForm}
                    cancel={cancel}
                    setCancel={setCancel}
                    disabled={disabled}
                    selectedSlot={selectedSlot}
                    mobile={mobile}
                    closeModal={props.closeDetail}
                  />
                ) : (
                  <Buttons
                    createMeeting={createMeeting}
                    clearForm={clearForm}
                    cancel={cancel}
                    setCancel={setCancel}
                    disabled={disabled}
                    selectedSlot={selectedSlot}
                    mobile={mobile}
                    closeModal={props.closeDetail}
                  />
                ))}
              {!mobile &&
                (props?.meetingData?.case === ONE_NONSKEDING_ONE_SKEDING_USER &&
                eventCase === ALL_SKEDING_USERS ? (
                  <Buttons
                    createMeeting={createNonToSkedingMeeting}
                    clearForm={clearForm}
                    cancel={cancel}
                    setCancel={setCancel}
                    disabled={disabled}
                    selectedSlot={selectedSlot}
                    mobile={mobile}
                    eventCase={eventCase}
                    closeModal={props.closeDetail}
                  />
                ) : (
                  <Buttons
                    createMeeting={createMeeting}
                    clearForm={clearForm}
                    cancel={cancel}
                    setCancel={setCancel}
                    disabled={disabled}
                    selectedSlot={selectedSlot}
                    mobile={mobile}
                    eventCase={eventCase}
                    closeModal={props.closeDetail}
                  />
                ))}
            </Grid>
          </>
        )}

        <NoCalendar modal={noCalendarModal} handleToggle={setNoCalendarModal} />
        {delegatedMode && <SchedularNotificationPopup />}
      </div>
    </Style>
  );
}
