import makeStyles from "@mui/styles/makeStyles";
const useStyle = makeStyles((theme) => ({
  createInput: {
    fontSize: "16px",
    fontWeight: 400,
    color: theme.palette.text.primary,
    border: "1px solid #DAD2E5",
    borderRadius: "6px",
    paddingLeft: "8px",
    backgroundColor: "transparent",
  },
  preview: {
    // backgroundColor: "white",
    padding: "12px",
    borderRadius: "8px",
    marginTop: "8px",
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    textAlign: "left",
  },
  textarea: {
    width: "100%",
    padding: "12px",
    boxSizing: "border-box",
    outline: "none",
    border: "1px solid #DAD2E5",
    "&:focus": {
      outline: "none",
      borderColor: "#DAD2E5",
    },
    "&::placeholder": {
      color: "#b6b8b8",
    },
  },

  descBtn: {
    color: theme.palette.text.primary,
    background: theme.palette.secondary.light,
    borderRadius: "8px",
    fontSize: 14,
    fontWeight: 400,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "4px",
    padding: "2px 4px",
  },
  chipColor: {
    color: "#96DCFF",
  },

  file: {
    display: "flex",
    justifyContent: "flex-end",
    "& .MuiBadge-badge": {
      maxWidth: "0",
      maxHeight: "35%",
    },
  },
  fileChip: {
    background: theme.palette.secondary.light,
    overflow: "hidden",
    textOverflow: "ellipses",
    width: "160px",
    borderRadius: "4px",
    height: "auto",
  },
}));

export default useStyle;
