import { useContext, useMemo, useState } from "react";

import {
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Box,
  Dialog,
  Stack,
  IconButton,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import GradingIcon from "@mui/icons-material/Grading";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";

import {
  MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS,
  ONE_NONSKEDING_MULTIPLE_SKEDING_USERS,
  ONE_SKEDING_MULTIPLE_NONSKEDING_USERS,
} from "Utils/Constants";
import { meetingsContext } from "context/meetings";
import MeetingInvitesButtons from "./meeting buttons/MeetingInvitesButtons";
import MyMeetingsButtons from "./meeting buttons/MyMeetingsButtons";
import WebsiteBookingsButton from "./meeting buttons/WebsiteBookingsButton";
import Duration from "./Duration";
import Invitee from "./Invitee";
import Initiator from "./Initiator";
import Accepted from "./Accepted";
import Location from "./Location";
import PendingMeetingParticipants from "./PendingMeetingParticipants";
import MeetingInvitesParticipants from "./MeetingInvitesParticipants";
import SnackbarComponent from "components/Global/SnackbarComponent";
import { useTranslation } from "react-i18next";
import { accountContext } from "context/user";
import { MEETING_THROUGH_QR_CODE } from "../../Utils/Constants";
import { ContentContainer } from "../NewEvent/MeetingDetailsComponent";
import Attachement from "../Global/Attachement";

/**
 * Renders the meeting details dialogue box on the Pending Meetings page
 */
export default function MeetingDetailsMyMeetings({
  title,
  open,
  handleClose,
  tab,
  event,
  handleDownload,
  setIsOpen,
  setPlannedEvents,
  plannedEvents,
}) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const { setMeetingDeleted, setMeetingResponded } =
    useContext(meetingsContext);
  const { t } = useTranslation();
  const { user } = useContext(accountContext);

  const [cancelSuccess, setCancelSuccess] = useState(false);

  /**
   * Displays the selected time to the initiator
   */
  const allowInitiator = useMemo(() => {
    if (event === null) {
      return;
    }
    if (
      Object.prototype.toString.call(event?.start) === "[object Date]" &&
      !isNaN(event?.start)
    ) {
      return;
    }
    if (
      event.case === ONE_NONSKEDING_MULTIPLE_SKEDING_USERS ||
      ([
        ONE_SKEDING_MULTIPLE_NONSKEDING_USERS,
        MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS,
      ].includes(event.case) &&
        event?.nonskedingParticipantsNotResponded.length !== 0)
    ) {
      if (event.proposedTimeslots.length > 0) {
        const start = event.proposedTimeslots[0].start
          ? event.proposedTimeslots[0].start
          : event.proposedTimeslots[0].startTime;
        const firstSlot = moment(start);
        const today = moment();
        const created = moment(event.createdTime);
        const checkDate = moment(created);
        const difference = firstSlot.diff(created, "days");
        let daysToAdd = Math.ceil(difference / 2);
        checkDate.add(daysToAdd, "d");

        if (new Date(checkDate._d).getTime() <= today.toDate().getTime()) {
          return (
            <Box>
              <Link
                to={`/initiator-select?meetingId=${event?.id}`}
                style={{
                  textDecoration: "none",
                }}
              >
                <Typography
                  variant="h4"
                  style={{ color: "#479AE9" }}
                  fontWeight={500}
                >
                  {t("choose_time")}
                </Typography>
              </Link>
            </Box>
          );
        } else {
          const diff = checkDate.diff(today, "days");
          return (
            <Box>
              <Typography variant="h5">
                {t("waiting_time") + ":"} {diff} {t("days_approx")}
              </Typography>
            </Box>
          );
        }
      }
    }
  }, [event]);

  /**
   * Displays the timeslots for users other than the initiator in the dialogue
   */
  const SelectTimeSlot = useMemo(() => {
    if (event === null) {
      return;
    }
    if (
      Object.prototype.toString.call(event?.start) === "[object Date]" &&
      !isNaN(event?.start)
    ) {
      return;
    }
    if (
      event.case === ONE_SKEDING_MULTIPLE_NONSKEDING_USERS ||
      event.case === MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS
    ) {
      if (event?.nonskedingParticipantsNotResponded.length === 0) {
        return (
          <Box>
            <Link
              to={`/meeting-poll/results?meetingId=${event?.id}`}
              style={{
                textDecoration: "none",
              }}
            >
              <Typography
                variant="h4"
                style={{ color: "#479AE9" }}
                fontWeight={500}
              >
                {t("select_time_slot")}
              </Typography>
            </Link>
          </Box>
        );
      } else {
        return <></>;
      }
    }
  }, [event]);

  return (
    event && (
      <>
        <SnackbarComponent
          open={cancelSuccess}
          handleClose={() => setCancelSuccess(false)}
          message={t("cancel_msg")}
          vertical={"bottom"}
          horizontal={"center"}
        />
        <Dialog
          open={open}
          maxWidth="xs"
          onClose={handleClose}
          fullWidth
          PaperProps={{
            sx: {
              padding: 3,
              paddingTop: 1,
              borderRadius: "16px",
            },
          }}
        >
          <Box display="flex" justifyContent="flex-end">
            <IconButton
              sx={{ justifyContent: "flex-end" }}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography
            variant="h2"
            align="center"
            style={{
              marginTop: 10,
              marginBottom: 16,
              textTransform: "capitalize",
            }}
          >
            {title}
          </Typography>
          <Typography
            align="left"
            variant={"h3"}
            fontWeight="600"
            style={{ textTransform: "capitalize" }}
          >
            {event.title}
          </Typography>

          {tab === 0 || event.case == MEETING_THROUGH_QR_CODE ? (
            <Typography
              align="left"
              variant={"h3"}
              fontWeight="400"
              style={{ textTransform: "capitalize" }}
              mt={1}
            >
              {user?.name + " " + t("organizer")}
            </Typography>
          ) : null}

          {tab === 1 || tab === 0 ? (
            <Box mt={"10px"}>
              <Initiator event={event} />
            </Box>
          ) : null}

          <Duration event={event} />
          <Grid container alignItems="center">
            {event?.description === "No Description" ||
            event?.description === "" ? null : (
              <>
                <Grid item xs={1} md={0.7} container>
                  <GradingIcon
                    style={{ width: "14px", color: theme.palette.text.primary }}
                  />
                </Grid>
                <Grid item xs={11}>
                  <Typography variant="h3" align="left" fontWeight="400">
                    {event?.description}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
          <Grid
            container
            item
            xs={12}
            style={{ marginTop: 10 }}
            sx={{
              display: event?.fileName?.length > 0 ? "flex" : "none",
              gap: 1,
              alignItems: "center",
            }}
          >
            {/* <ContentContainer align={true}> */}
            <AttachFileIcon sx={{ color: "#323232", fontSize: "14px" }} />
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 8,
              }}
            >
              {event.fileName.map((filename) => (
                <Attachement filename={filename} meetingId={event.id} />
              ))}
            </Box>
            {/* </ContentContainer> */}
          </Grid>
          {tab === 2 && event?.payment_amount ? (
            <Box mt={"10px"}>
              <Grid container spacing={1} alignItems="center">
                <Grid
                  item
                  xs={1}
                  md={0.7}
                  container
                  justifyContent="flex-start"
                >
                  <LocalAtmIcon
                    style={{ width: "14px", color: theme.palette.text.primary }}
                  />
                </Grid>
                <Grid item container xs={11} alignItems="center">
                  <Typography variant="h3" align="left" fontWeight={400}>
                    {event?.payment_amount}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          ) : null}

          {/* {tab !== 1 ? (
          <Box mt={"10px"}>
            <Location event={event} />{" "}
          </Box>
        ) : null} */}
          <Box mt={"10px"}>
            <Location event={event} />{" "}
          </Box>
          {tab !== 2 ? (
            <Box>
              <Accepted event={event} />
            </Box>
          ) : null}
          {tab !== 2 && (
            <Grid
              container
              item
              style={{ alignItems: "center", marginTop: 10, marginBottom: 32 }}
            >
              {tab === 0 ? (
                <PendingMeetingParticipants event={event} isPopup={true} />
              ) : (
                <MeetingInvitesParticipants event={event} isPopup={true} />
              )}
            </Grid>
          )}
          {tab === 0 ? (
            <Box display="flex" justifyContent="space-between">
              {allowInitiator}
              {SelectTimeSlot}
            </Box>
          ) : null}

          {tab === 2 ? (
            <Box mt={"10px"}>
              <Invitee event={event} />{" "}
            </Box>
          ) : null}

          {tab === 0 ? (
            <MyMeetingsButtons
              event={event}
              meetingId={event.id}
              mobile={mobile}
              setMeetingDeleted={setMeetingDeleted}
              setIsOpen={setIsOpen}
              handleClose={handleClose}
              setCancelSuccess={setCancelSuccess}
              setPlannedEvents={setPlannedEvents}
              plannedEvents={plannedEvents}
              isCal={true}
            />
          ) : tab === 1 ? (
            <MeetingInvitesButtons
              mobile={mobile}
              event={event}
              setMeetingResponded={setMeetingResponded}
              onClose={handleClose}
            />
          ) : (
            <WebsiteBookingsButton
              mobile={mobile}
              event={event}
              setMeetingResponded={setMeetingResponded}
              onClose={handleClose}
            />
          )}
        </Dialog>
      </>
    )
  );
}
