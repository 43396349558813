import React, { useContext } from "react";

import {
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  ALL_SKEDING_USERS,
  ONE_NONSKEDING_MULTIPLE_SKEDING_USERS,
  ONE_NONSKEDING_ONE_SKEDING_USER,
  RECURRING_EVENT,
} from "Utils/Constants";
import Progress from "./Progress";
import { useLocation } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import useMeetingStore from "../../Utils/createMeetingStore";

export default function Message({
  messageModal,
  handleContinue,
  handleBack,
  closeMessageInput,
}) {
  const { meetingData } = useMeetingStore();
  const mobile = useMediaQuery(useTheme().breakpoints.down("md"));
  const { t } = useTranslation();
  return (
    <ResponsiveWrapper messageModal={messageModal} mobile={mobile}>
      {!mobile && (
        <Box display="flex" justifyContent="flex-end">
          <IconButton
            sx={{ justifyContent: "flex-end" }}
            onClick={closeMessageInput}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      )}
      <Box>
        <Typography variant="h2" align="center">
          {t("progress2")}
        </Typography>
      </Box>
      <Box mt={2} mb={2}>
        <Progress stage={1} t={t} />
      </Box>

      <Box
        mt={2}
        mb={2}
        style={{
          backgroundColor: "#96DCFF40",
          borderRadius: "6px",
          width: "fit-content",
          margin: "auto",
          padding: "8px 28px",
          marginBottom: "14px",
        }}
      >
        <Typography
          variant="h3"
          align="center"
          fontWeight={400}
          style={{ color: "#70757A" }}
        >
          {t("type_of_meeting")}
        </Typography>
        <Typography variant="h3" align="center" fontWeight={500}>
          {t(meetingData?.meetingTitle)}
        </Typography>
      </Box>

      <Box pl={mobile ? 1 : 4} pr={mobile ? 1 : 4}>
        <Typography
          variant="h3"
          align="left"
          fontWeight={400}
          lineHeight={"25.6px"}
        >
          {(meetingData?.meetingTitle === "all_memeber_meeting" &&
            meetingData?.case === ALL_SKEDING_USERS &&
            meetingData?.participants?.length === 1) ||
          meetingData?.case === RECURRING_EVENT ? (
            <ul>
              <li>{t("msg1")}</li>
              <li>{t("msg2")}</li>
              <li>{t("msg3")}</li>
            </ul>
          ) : // "You have selected a 1:1 Meeting. Choose a time based on your invitee’s availability from the recommended time slots or click and drag your preferred time to your calendar."
          meetingData?.meetingTitle === "non_member_meeting" &&
            meetingData?.case === ONE_NONSKEDING_ONE_SKEDING_USER ? (
            <ul>
              <li>{t("msg4")}</li>
              <li>{t("msg5")}</li>
              <li>{t("msg6")}</li>
            </ul>
          ) : // "You have selected a 1:1 Meeting. You can select up to 5 time slots for your invitee to choose from by clicking on the recommended slots or clicking and dragging them to the calendar. Your invitee will receive an email with the options and they will select a time that works best for them and confirm the meeting."
          meetingData?.meetingTitle === "all_memeber_meeting" &&
            meetingData?.case === ALL_SKEDING_USERS ? (
            <ul>
              <li>{t("msg7")}</li>
              <li>{t("msg8")}</li>
              <li>{t("msg9")}</li>
            </ul>
          ) : // "This is a group meeting. Please choose one time slot that work for everyone. You can either select from the recommended time slots or simply click and drag in the calendar to select the mutually available slot."
          meetingData?.meetingTitle === "non_member_meeting" &&
            meetingData?.case === ONE_NONSKEDING_MULTIPLE_SKEDING_USERS ? (
            <ul>
              <li>{t("msg10")}</li>
              <li>{t("msg11")}</li>
              <li>{t("msg12")}</li>
            </ul>
          ) : // "This is a group meeting. Since there is a non-Skeding participant, please choose 3-5 time slots that work for everyone. You can either select from the recommended time slots or simply click and drag in the calendar to select the mutually available slots."
          meetingData?.meetingTitle === "multi_non_member" ? (
            <ul>
              <li>{t("msg13")}</li>
              <li>{t("msg14")}</li>
              <li>{t("msg15")}</li>
              <li>{t("msg16")}</li>
            </ul>
          ) : (
            // "You have selected a Group Polling Meeting. Because you have invited more than one non-Skeding user, you can select recommended time slots from at least 3 to 15 days for your meeting. Your invitee will receive an email with the options and they will select a time that works best for them and confirm the meeting."
            ""
          )}
        </Typography>
      </Box>
      <Grid
        item
        container
        xs={12}
        justifyContent={"center"}
        columnSpacing={4}
        sx={{ mt: 4 }}
      >
        <Grid item>
          <Button
            variant="outlined"
            onClick={handleBack}
            style={{
              padding: "4px 29px",
            }}
          >
            {t("back")}
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            onClick={handleContinue}
            style={{
              padding: "5px 27px",
            }}
          >
            {t("cont")}
          </Button>
        </Grid>
      </Grid>
    </ResponsiveWrapper>
  );
}

function ResponsiveWrapper({ children, messageModal, mobile }) {
  const location = useLocation();

  return !mobile ? (
    <Dialog
      open={messageModal}
      maxWidth={"sm"}
      fullWidth
      PaperProps={{ sx: { padding: 3, borderRadius: "16px" } }}
    >
      {children}
    </Dialog>
  ) : location.pathname === "/createmeeting" ? (
    <Box>{children}</Box>
  ) : null;
}
