import { useState, useEffect, useContext } from "react";

import { Grid, useTheme, useMediaQuery, Box } from "@mui/material";

import { serverGET } from "Utils/HttpFunctions";
import { LeftLayout, RightLayout } from "../../components/TwoSectionLayout";
import { PASTEL_COLORS, VIBRANT_COLORS } from "../../Utils/Constants";
import { accountContext } from "../../context/user";
import loggingInDev from "loggingInDev";
import ContactsNavigation from "components/Contacts/ContactsNavigation";
import Teams from "components/Contacts/Teams";
import AllContacts from "components/Contacts/AllContacts";
import NewContactForm from "components/Contacts/NewContactForm";
import useContactStore from "../../Utils/contactStore";

export default function Contacts() {
  const [tab, setTab] = useState("");
  const [deleteGroupContact, setDeleteGroupContact] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("");
  const [newGroupName, setNewGroupName] = useState("");
  const [groupsFetched, setGroupsFetched] = useState(false);
  const [groupView, setGroupView] = useState(false);
  const [palette, setPalette] = useState({});
  const [index, setIndex] = useState(0);
  const [invalidTeam, setInvalidTeam] = useState(false);
  const [inviteSent, setInviteSent] = useState(false);
  const [teamCreated, setTeamCreated] = useState(false);
  const { user } = useContext(accountContext);

  const {
    contacts,
    setContacts,
    groups,
    setGroups,
    setFetchingContacts,
    refreshContacts,
    setRefreshContacts,
  } = useContactStore();

  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("lg"));

  const CONTACTS_FETCH = {
    notFound: "Contacts could not be found",
  };

  /**
   * Sets the contacts and groups on the first render
   * Sets the palette to be used in the contact name Avatars
   */
  useEffect(() => {
    const abortController = new AbortController();
    getContacts(0, abortController);
    getGroups(abortController);
    setColorsForAvatars();
    return () => {
      abortController.abort();
    };
  }, []);

  /**
   * Refreshes contacts when they are deleted or added to
   */
  useEffect(() => {
    const abortController = new AbortController();
    getContacts(0, abortController);

    return () => {
      abortController.abort();
    };
  }, [refreshContacts]);

  /**
   * Calls appendData when user reaches the end of the existing list of contacts
   */
  useEffect(() => {
    const abortController = new AbortController();
    if (index !== 0) {
      appendData(abortController);
    }
    return () => {
      abortController.abort();
    };
  }, [index]);

  /**
   * Syncs groups with backend when a contact is deleted or added
   */
  useEffect(() => {
    const abortController = new AbortController();
    getGroups(abortController);
    return () => {
      abortController.abort();
    };
  }, [contacts]);

  /**
   * Fetches user's contacts
   * @param {number} page
   * @param {object} abortController
   */

  const getContacts = async (page, abortController) => {
    let response;
    setFetchingContacts(true);

    try {
      if (abortController) {
        response = await serverGET(
          `/contact?page=${page}`,
          abortController.signal
        );
      } else {
        response = await serverGET(`/contact?page=${page}`);
      }
      if (response && response.constructor !== String) {
        loggingInDev("contacts", response.data);
        setContacts(response.data);
        setFetchingContacts(false);
      } else if (response === CONTACTS_FETCH.notFound) {
        loggingInDev(response);
        setContacts([]);
        setFetchingContacts(false);
      } else {
        loggingInDev(response);
        setFetchingContacts(false);
        return undefined;
      }

      setRefreshContacts(false);
    } catch (e) {
      loggingInDev(e);
    }
  };

  /**
   * Appends contacts to the existing list when a user reaches the end of the existing list
   * Minimizes size of returned data for users with a large amount of contacts
   */
  const appendData = async (abortController) => {
    const response = await serverGET(
      `/contact?page=${index}`,
      abortController.signal
    );
    if (response && response.constructor !== String) {
      const temp = [...contacts];
      const loadedData = response.data;
      const appended = temp.concat(loadedData);
      setContacts(appended);
    }
  };

  /**
   * Sets the page index to be fetched when the user reaches the bottom of the page
   */
  const fetchData = async () => {
    let newVal = index + 1;
    setIndex(newVal);
  };

  /**
   * Gets the user's contact groups from backend
   * @param {object} abortController
   */
  const getGroups = async (abortController) => {
    setTeamCreated(false); // tracks when a team had been created to clear checkboxes
    let response;

    try {
      if (abortController) {
        response = await serverGET("/contact/group", abortController.signal);
      } else {
        response = await serverGET("/contact/group");
      }

      if (response && response.constructor !== String) {
        loggingInDev("groups: ", response.data);
        setGroups(response.data);
        setTab(response.data[0].group_name.toUpperCase());
        setGroupsFetched(true);
      } else {
        loggingInDev("groups: ", response);
        setGroups([]);
        setGroupsFetched(true);
        return undefined;
      }
    } catch (e) {
      loggingInDev(e);
    }
  };

  /**
   * Handles the changing of tabs in mobile view
   */
  const handleTabChange = (e, newValue) => {
    setTab(newValue);
  };

  /**
   * Handles viewing of groups in mobile view
   */
  const handleGroupView = () => {
    setGroupView(!groupView);
  };

  const setColorsForAvatars = () => {
    if (user?.event_theme === 0) {
      setPalette(PASTEL_COLORS);
    } else {
      setPalette(VIBRANT_COLORS);
    }
  };

  return (
    <Grid container>
      <LeftLayout scrollable={true}>
        <ContactsNavigation
          theme={theme}
          desktop={desktop}
          tab={tab}
          groups={groups}
          newGroupName={newGroupName}
          setNewGroupName={setNewGroupName}
          groupsFetched={groupsFetched}
          getGroups={getGroups}
          getContacts={getContacts}
          handleGroupView={handleGroupView}
          groupView={groupView}
          setInvalidTeam={setInvalidTeam}
          invalidTeam={invalidTeam}
          setInviteSent={setInviteSent}
          setTeamCreated={setTeamCreated}
        />
        {groupView ? (
          <Teams
            groups={groups}
            tab={tab}
            handleTabChange={handleTabChange}
            groupsFetched={groupsFetched}
            desktop={desktop}
            contacts={contacts}
            getContacts={getContacts}
            deleteGroupContact={deleteGroupContact}
            setDeleteGroupContact={setDeleteGroupContact}
            getGroups={getGroups}
            selectedGroup={selectedGroup}
            setSelectedGroup={setSelectedGroup}
            palette={palette}
          />
        ) : (
          <AllContacts
            contacts={contacts}
            fetchData={fetchData}
            groups={groups}
            getContacts={getContacts}
            setRefreshContacts={setRefreshContacts}
            palette={palette}
            invalidTeam={invalidTeam}
            setInvalidTeam={setInvalidTeam}
            inviteSent={inviteSent}
            groupsFetched={groupsFetched}
            teamCreated={teamCreated}
          />
        )}
      </LeftLayout>
      {desktop ? (
        <RightLayout scrollable={true}>
          <Box display="flex" flexDirection="column">
            <NewContactForm
              theme={theme}
              getContacts={getContacts}
              handleCreateContactDialog={() => console.log("heyyyyy")}
            />
            <Teams
              groups={groups}
              tab={tab}
              handleTabChange={handleTabChange}
              groupsFetched={groupsFetched}
              desktop={desktop}
              contacts={contacts}
              getContacts={getContacts}
              deleteGroupContact={deleteGroupContact}
              setDeleteGroupContact={setDeleteGroupContact}
              getGroups={getGroups}
              selectedGroup={selectedGroup}
              setSelectedGroup={setSelectedGroup}
              palette={palette}
            />
          </Box>
        </RightLayout>
      ) : null}
    </Grid>
  );
}
