import React from "react";

import { TextField, useTheme } from "@mui/material";

import "styles/newEvent.css";
import useStyle from "../../styles/formStyles";

function VirtualLink({ setLink, link, isService }) {
  const classes = useStyle();

  const theme = useTheme();

  const handlelinkChange = (event) => {
    setLink(event.target.value);
  };

  return (
    <>
      <TextField
        id="event-link"
        placeholder="https://..."
        variant="standard"
        multiline
        maxRows={3}
        value={link}
        sx={{ width: "100%" }}
        onChange={handlelinkChange}
        InputProps={{
          disableUnderline: true,
          style: {
            fontWeight: 400,
            color: theme.palette.text.disabled,
            // borderBottom: "1px solid #B3B5B6",
            backgroundColor: isService ? "#F7F7F9" : "",
            borderRadius: 6,
            paddingLeft: "8px",
            marginTop: isService ? "" : 10,
          },

          classes: { input: classes.input },
        }}
      />
    </>
  );
}

export default React.memo(VirtualLink);
