import { Box, CircularProgress, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import i18next from "i18next";
import { FormControl, Select, MenuItem } from "@mui/material";
import { useCookies } from "react-cookie";
import { serverPUT } from "../../Utils/HttpFunctions";
import { useContext } from "react";
import { accountContext } from "../../context/user";

const lngs = [
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
  {
    code: "fr",
    name: "Francais",
    country_code: "fr",
  },
  {
    code: "es",
    name: "Spanish",
    country_code: "es",
  },
  {
    code: "de",
    name: "German",
    country_code: "de",
  },
  {
    code: "it",
    name: "Italian",
    country_code: "it",
  },
  {
    code: "nl",
    name: "Dutch",
    country_code: "nl",
  },
];
export default function Languages({ small, showStyles }) {
  // const [cookies] = useCookies(["i18next"]);
  const { user } = useContext(accountContext);
  const [loading, setLoading] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("en");

  useEffect(() => {
    if (user) {
      setSelectedLanguage(user?.prefered_language);
      i18next.changeLanguage(user?.prefered_language);
    }
  }, [user]);

  const theme = useTheme();
  const changeLng = async (e) => {
    setLoading(true);
    const response = await serverPUT(`/account`, {
      prefered_language: e.target.value,
    });
    if (response && response.constructor !== String) {
      console.log("added");
    } else {
      console.log("error");
    }

    i18next.changeLanguage(e.target.value);
    setSelectedLanguage(e.target.value);
    setLoading(false);
  };
  if (loading) {
    return (
      <CircularProgress color="primary" style={{ width: 20, height: 20 }} />
    );
  }
  return (
    <Box>
      <FormControl
        variant="standard"
        style={{ width: "80%", borderRadius: "6px" }}
      >
        <Select
          labelId="language-label"
          id="language-select"
          value={selectedLanguage}
          onChange={changeLng}
          style={{
            fontSize: small ? "12px" : "14px",
            fontWeight: 400,
            backgroundColor: showStyles
              ? theme.palette.secondary.main
              : "fit-content",
            width: showStyles ? "100%" : "fit-content",
            padding: "2px 6px",
          }}
          disableUnderline
        >
          {lngs.map((lng) => (
            <MenuItem
              value={lng.code}
              key={lng.country_code}
              disabled={selectedLanguage === lng.code}
            >
              {lng.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
