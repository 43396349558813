import React, { useState, useEffect } from "react";
import axios from "axios";

export const drawerContext = React.createContext();

export default function DrawerProvider({ children }) {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <drawerContext.Provider value={{ mobileOpen, handleDrawerToggle }}>
      {children}
    </drawerContext.Provider>
  );
}
