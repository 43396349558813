import {
  Box,
  Typography,
  Button,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import useWebsiteClientStore from "../../Utils/websiteClientStore";
import BookingDetails from "../PublicCalendar/BookingDetails";
import SkedingBanner from "../SkedingBanner";
import BreadCrumb from "./BreadCrumb";
import useStyle from "./styles";

export default function ChooseService({
  services,
  title,
  description,
  email,
  skeding_user_id,
  meeting_room,
  phone_number,
  location,
  client,
  category,
}) {
  const { picture } = skeding_user_id;
  const { t } = useTranslation();
  const classes = useStyle();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  const setSelectedService = useWebsiteClientStore(
    (state) => state.setSelectedService
  );
  const selectedLocation = useWebsiteClientStore(
    (state) => state.selectedLocation
  );

  const servicesFiltered = !client
    ? services
    : services?.filter((service) => service.location === selectedLocation?._id);

  return (
    <Box
      className={classes.cientSideContainer}
      style={{ minHeight: client ? "100vh" : "100%", width: "90%" }}
    >
      <Grid container columnSpacing={3}>
        <Grid
          item
          xs={12}
          md={4}
          style={{
            backgroundColor: mobile ? "" : "#F7F7F9",
            borderRadius: 6,
            // height: "100vh",
          }}
          pb={2}
        >
          <BookingDetails
            heading={t("book_appointment")}
            firstName={""}
            lastName={""}
            phone={!client ? phone_number : selectedLocation?.phone_number}
            email={!client ? email : selectedLocation?.email}
            title={title}
            duration={null}
            location={!client ? location : selectedLocation?.locationType}
            agenda={description}
            profile={picture}
            roomNo={meeting_room}
            showLng={true}
            polling={false}
            fileName={undefined}
            eventId={undefined}
            participants={[]}
            isOrganizer={!client}
            isAddToWebsite={true}
            category={category}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <Box className={classes.questionsBox}>
            <Box mb={2}>
              <BreadCrumb selected={1} isClient={client} />
            </Box>
            <Typography variant={client ? "h3" : "h5"} align="left">
              {t("choose_service")}
            </Typography>
            {servicesFiltered?.length > 0 ? (
              servicesFiltered.map((service) => (
                <Box
                  display={"flex"}
                  justifyContent="space-between"
                  alignItems={"center"}
                  style={{
                    textAlign: "left",
                    padding: "6px 8px 6px 8px",
                    borderRadius: 6,
                    backgroundColor: "#96DCFF40",
                    gap: 4,
                  }}
                >
                  <Box key={service.name}>
                    <Typography variant={client ? "h3" : "h5"} fontWeight={400}>
                      {service.name}
                    </Typography>
                    <Typography
                      variant={client ? "h4" : "h6"}
                      fontWeight={400}
                      className={classes.grayText}
                    >
                      {`${service.duration_minutes}` + " " + t("minutes")}
                    </Typography>
                  </Box>
                  <Button
                    variant="contained"
                    style={{ fontSize: !client ? 10 : "" }}
                    onClick={() => setSelectedService(service)}
                  >
                    {t("book")}
                  </Button>
                </Box>
              ))
            ) : (
              <Typography variant="h5" fontWeight={400}>
                {t("no_service")}
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>
      <SkedingBanner removePadding={false} />
    </Box>
  );
}
