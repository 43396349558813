import React from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function CreateMeetingTour({ tourDialog, handleClose }) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();

  return (
    <Dialog
      open={tourDialog}
      aria-labelledby="alert-welcome-to-skeding"
      sx={{ justifyContent: "center" }}
      maxWidth={mobile ? "xs" : "md"}
      fullWidth
      PaperProps={{
        style: {
          borderRadius: "8px",
          padding: "14px 24px",
          boxSizing: "border-box",
        },
      }}
    >
      <DialogContent style={{ padding: "0" }}>
        <Grid container>
          <Box
            display="flex"
            justifyContent="flex-end"
            style={{ width: "100%" }}
          >
            <IconButton
              sx={{ justifyContent: "flex-end" }}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Grid item xs={12} sx={{ pb: "10px" }}>
            <Typography align="center" variant="h2">
              {t("create_meeting")}
            </Typography>
          </Grid>

          <div style={{ width: "100%" }}>
            {mobile ? (
              <iframe
                width="100%"
                height="315"
                style={{ borderRadius: "20px" }}
                src="https://www.youtube.com/embed/tVeDIpQEOUM"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            ) : (
              <iframe
                width="100%"
                height="400"
                src="https://www.youtube.com/embed/kZMbRvIbNiA"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
                style={{ borderRadius: "20px" }}
              ></iframe>
            )}
          </div>
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{ justifyContent: "center" }}
        style={{ padding: "24px 0 0 0 " }}
      >
        <Button
          onClick={handleClose}
          variant="outlined"
          style={{
            padding: "4px 28px",
            marginBottom: "24px",
            fontSize: "14px",
            fontWeight: 400,
          }}
        >
          {t("skip_tour")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
