import React from "react";
import { Skeleton, Box, Avatar } from "@mui/material";

/**
 * Renders the skeletons for contacts when they're being fetched from the backend
 * @param {string} component - determines for which component the skeletons are being rendered, modifies how many skeletons are rendered and their layout
 */
export default function ContactsSkeletons(component) {
  let skeletonArray: any = [];
  let max = 5;
  let groups;

  if (component === "groups") {
    max = 2;
    groups = true;
  }

  {
    for (let i = 0; i < max; i++) {
      skeletonArray.push(
        <Box
          alignItems={"center"}
          sx={{ p: "20px 0", display: "flex", justifyContent: "space-between" }}
        >
          <Skeleton variant="rounded" sx={{ width: "12px", height: "12px" }} />
          <Skeleton
            variant="circular"
            sx={{ marginLeft: groups ? null : "-100px" }}
          >
            <Avatar />
          </Skeleton>
          <Skeleton
            sx={{ width: "80px", marginLeft: groups ? null : "-100px" }}
          />
          <Box>
            <Skeleton sx={{ width: "150px" }} />
            <Skeleton sx={{ width: "55px", height: "14px" }} />
          </Box>
          <Skeleton sx={{ width: "20px" }} />
        </Box>
      );
    }
  }

  return skeletonArray;
}
