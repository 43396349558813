import React, { useState, useEffect, useContext } from "react";
import {
  Typography,
  Button,
  Grid,
  Box,
  Container,
  useTheme,
  useMediaQuery,
  Dialog,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DialogWithCloseIcon from "components/DialogWithCloseIcon";
import FreeTrialDialog from "components/payment/FreeTrialDialog";
import "styles/newEvent.css";
import styles from "styles/scrollBar.module.css";
import { serverGET } from "Utils/HttpFunctions";
import { SHAREABLE_MEETINGS } from "Utils/Constants";
import { LeftLayout, RightLayout } from "../../components/TwoSectionLayout";
import TemplateComponent from "components/MeetingTemplates/TemplateComponent";
import TemplateSkeleton from "components/MeetingTemplates/TemplateSkeleton";
import CreateTemplate from "../CreateTemplate/CreateTemplate";
import SnackbarComponent from "components/Global/SnackbarComponent";
import HelpComponent from "components/Global/Help";
import loggingInDev from "loggingInDev";
import { useTranslation } from "react-i18next";
import { accountContext } from "../../context/user";
import { handleIsLock } from "../../Utils/CommonFunctions";
import { useNavigate } from "react-router-dom";
import useStyles from "./styles";

export default function ShareableMeetings() {
  const [templates, setTemplates] = useState([]);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState({
    open: false,
    msg: "",
  });
  const [createModal, setCreateModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const theme = useTheme();
  const tab = useMediaQuery(theme.breakpoints.down("lg"));
  const { user } = useContext(accountContext);
  const [dialog, setDialog] = useState(false);
  const navigate = useNavigate();
  const classes = useStyles();

  const openDialog = () => {
    setDialog(true);
  };

  const handleDialogClose = () => {
    setDialog(false);
  };

  /**
   * Closes the snackbar component
   */
  const handleClose = () => {
    setModal({ open: false, msg: "" });
  };

  /**
   * Fetches meeting templates on refresh
   */
  useEffect(() => {
    const abortController = new AbortController();
    getMeetingTemplates(abortController);
    return () => {
      abortController.abort();
    };
  }, [refresh]);

  /**
   * Fetches meeting templates from the backend
   * @async
   */
  const getMeetingTemplates = async (abortController) => {
    try {
      const response = await serverGET(
        "/meeting-template",
        abortController.signal
      );
      if (response && response.constructor !== String) {
        if (response.code === 200) {
          setTemplates(response.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    } catch (e) {
      loggingInDev(e);
    }
  };

  return !loading ? (
    <Container maxWidth={"xl"} className={classes.container}>
      <SnackbarComponent
        open={modal.open}
        handleClose={handleClose}
        message={modal.msg}
        vertical={"bottom"}
        horizontal={"center"}
      />
      <Grid container>
        <LeftLayout>
          <Box mb={5}>
            <Box
              display={"flex"}
              alignItems="center"
              justifyContent={"space-between"}
              style={{ flexGrow: 1 }}
            >
              <Box display={"flex"} alignItems="center">
                <Typography variant="h2" align="left">
                  {t("shareable_heading")}
                </Typography>
                <Box ml={1}>
                  <HelpComponent msg={SHAREABLE_MEETINGS} position={"right"} />
                </Box>
              </Box>

              {tab && (
                <Button variant="h5" style={{ padding: 0 }}>
                  <Typography
                    variant="h4"
                    align="right"
                    color="primary"
                    onClick={() => {
                      if (user?.free_plan || user?.package?.paid) {
                        setCreateModal(true);
                      } else {
                        openDialog();
                      }
                    }}
                  >
                    {tab ? t("create") : t("create_shareable_title")}
                  </Typography>
                </Button>
              )}
            </Box>
            <Box
              style={{
                marginTop: "20px",
                overflowY: "auto",
              }}
              className={styles.root}
            >
              {templates.length > 0 ? (
                <Grid
                  container
                  rowSpacing={tab ? 2 : "20px"}
                  columnSpacing={"20px"}
                >
                  {templates.map((template) => {
                    return (
                      <Grid item xs={12} sm={6} key={template._id}>
                        <TemplateComponent
                          id={template._id}
                          title={template.title}
                          agenda={template.agenda}
                          location={template.location}
                          duration={template.duration}
                          organizer_preferences={
                            template.organizer_blocked_timeslots_utc
                          }
                          isLocked={template.is_locked}
                          participants={template.skeding_participants.map(
                            (p) => p.email
                          )}
                          setRefresh={setRefresh}
                          setModal={setModal}
                          templates={templates}
                          setTemplates={setTemplates}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              ) : (
                <Typography
                  variant="h3"
                  align="center"
                  className={classes.noMsg}
                >
                  {t("emp_shareable")}
                </Typography>
              )}
            </Box>
          </Box>
        </LeftLayout>

        {tab ? (
          createModal ? (
            <Dialog
              open={createModal}
              onClose={() => setCreateModal(false)}
              maxWidth={"xs"}
              PaperProps={{ sx: { padding: 2, borderRadius: "8px" } }}
            >
              <Box width="100%" display="flex" justifyContent="flex-end">
                <IconButton onClick={() => setCreateModal(false)}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <CreateTemplate
                modal={modal}
                setModal={setModal}
                setRefresh={setRefresh}
                tab={tab}
                dialogue={createModal}
                closeDialogue={() => setCreateModal(false)}
              />
            </Dialog>
          ) : null
        ) : (
          <RightLayout scrollable={true}>
            <CreateTemplate
              modal={modal}
              setModal={setModal}
              setRefresh={setRefresh}
              tab={tab}
              totalTemplates={!user?.package?.paid ? 0 : templates.length}
              isLocked={templates.length > 0 && handleIsLock(user)}
            />
          </RightLayout>
        )}
      </Grid>
      <DialogWithCloseIcon
        open={dialog}
        handleClose={handleDialogClose}
        dialogSize={"sm"}
      >
        <FreeTrialDialog
          dialogTitle={t("upgrade_premium")}
          dialogDesc={t("free_limit_reached")}
          onClick={() => {
            navigate("/payment-services");
            handleDialogClose();
          }}
        />
      </DialogWithCloseIcon>
    </Container>
  ) : (
    <Grid container spacing={5}>
      {[...Array(4)].map((e, i) => (
        <Grid item xs={12} sm={6} key={i}>
          <TemplateSkeleton />
        </Grid>
      ))}
    </Grid>
  );
}
