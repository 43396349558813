import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import useWebsiteStore from "../../Utils/websiteStore";
import DialogWithCloseIcon from "../DialogWithCloseIcon";
import { AddQuestionDialog } from "./AddQuestionDialog";
import {
  Question,
  Service,
  AddServiceQuestions,
  ButtonGroup,
  ServiceQuestion,
} from "./Services";
import useStyle from "./styles";
import TimeInterval from "../NewEvent/TimeInterval";
import CustomInterval from "..//NewEvent/CustomInterval";
import * as yup from "yup";
import SnackbarComponent from "../Global/SnackbarComponent";
import Textfield from "./Textfield";

import ErrorSnackbar from "../ErrorSnackbar";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";

export const schema = yup
  .object({
    name: yup.string().required("Service name is required"),
    duration_minutes: yup.string().required("Please add a valid duration"),
    location: yup.string().required("Please select a location"),
  })
  .required();

export function AddAService({
  addServiceDialog,
  handleAddServiceDialog,
  t,
  editService,
  suggestedService,
  editIndex,
}) {
  const [addQuestion, setAddQuestion] = useState(false);
  const services = useWebsiteStore((store) => store.services);
  const setServices = useWebsiteStore((store) => store.setServices);
  const [currentQuestion, setCurrentQuestion] = useState({} as Question);
  const classes = useStyle();
  const [message, setMessage] = useState("");
  const [addServiceMessage, setAddServiceMessage] = useState("");
  const [addQuestionType, setAddQuestionType] = useState("");
  const [questionIndex, setQuestionIndex] = useState(undefined);
  const [suggestedQuestions, setSuggestedQuestions] = useState([]);
  const websiteLocations = useWebsiteStore((store) => store.websiteLocations);
  const [selectedLocation, setSelectedLocation] = useState("");

  const [service, setService] = useState({
    duration_minutes: 60,
  } as Service);
  const [timeInterval, setTimeInterval] = useState("");
  const [customTime, setCustomTime] = useState("");

  useEffect(() => {
    if (editService) {
      setService(editService);
      setSelectedLocation(editService.location);
      setTimeInterval(parseDuration(editService.duration_minutes));
    } else {
      setService({
        duration_minutes: 60,
      } as Service);
      setTimeInterval("01:00:00");
      setSelectedLocation("");
    }
  }, [editService]);

  useEffect(() => {
    if (suggestedService) {
      setService({ ...service, name: suggestedService?.title });
      setSuggestedQuestions(suggestedService.questions);
    }
  }, [suggestedService]);

  const handleDeleteQuestionInService = (ind: number) => {
    const temp = { ...service };
    if (temp.questions) {
      temp?.questions.splice(ind, 1);
      setService(temp);
    }
  };

  const handleAddQuestion = () => {
    setAddQuestion(!addQuestion);
  };

  const handleAddQuestionType = (type?, question?, index?) => {
    let qType = type;
    if (question) {
      setCurrentQuestion(question);
      setQuestionIndex(index);
      qType = question.type_of_question;
    } else {
      if (qType === "long") {
        setCurrentQuestion({
          ...currentQuestion,
          type_of_question: "long",
          is_required: false,
        });
      } else if (qType === "number") {
        setCurrentQuestion({
          ...currentQuestion,
          type_of_question: "number",
          is_required: false,
        });
      } else if (qType === "checkbox") {
        setCurrentQuestion({
          ...currentQuestion,
          type_of_question: "checkbox",
          is_required: false,
          options: ["Option 1", "Option 2", "Option 3", "Option 4"],
        });
      }
    }

    setAddQuestionType(() => {
      const newVal = addQuestionType ? "" : qType;
      if (!newVal) setCurrentQuestion({} as Question);
      return newVal;
    });
  };

  const handleServiceDialog = () => {
    setAddServiceMessage(
      editIndex === undefined
        ? "Serivce has been added"
        : "Service has been updated"
    );
    handleCloseServiceDialog();
  };

  const handleCloseServiceDialog = () => {
    setService({ duration_minutes: 60 } as Service);
    setSuggestedQuestions([]);
    setSelectedLocation("");
    handleAddServiceDialog();
  };

  const handleSave = () => {
    if (service.questions) {
      if (questionIndex !== undefined) {
        const temp = [...service?.questions];
        temp[questionIndex] = currentQuestion;
        setService({ ...service, questions: temp });
      } else {
        setService({
          ...service,
          questions: [...service?.questions, currentQuestion],
        });
      }
    } else {
      setService({
        ...service,
        questions: [currentQuestion],
      });
    }
    setQuestionIndex(undefined);
  };

  const handleServiceSave = async () => {
    try {
      const newService = { ...service, location: selectedLocation };
      const errors = await schema.validate(newService);
      if (timeInterval === "custom" && customTime) {
        const totalMinutes = moment.duration(customTime).asMinutes();
        newService.duration_minutes = Number.isNaN(totalMinutes)
          ? undefined
          : totalMinutes;
      }

      if (editService) {
        let temp = [...services];
        temp[editIndex] = newService;
        setServices(temp);
      } else {
        let temp = [...services, newService];
        setServices(temp);
      }
      handleServiceDialog();
    } catch (errors) {
      setMessage(errors?.errors[0]);
    }
  };

  const parseDuration = (duration) => {
    if (duration === 60) {
      return (
        "0" +
        Math.floor(duration / 60) +
        ":0" +
        ((duration % 60) + ":" + "00").toString()
      );
    } else {
      return (
        "0" +
        Math.floor(duration / 60) +
        ":" +
        (
          (duration % 60 == 0 ? (duration % 60) + "0" : duration % 60) +
          ":" +
          "00"
        ).toString()
      );
    }
  };

  const handleTimeChange = (e) => {
    if (e.target.value === "custom") {
      setTimeInterval(e.target.value);
      return;
    }
    setTimeInterval(e.target.value);
    const timeArray = e.target.value.split(":");

    const hours = parseInt(timeArray[0], 10);
    const minutes = parseInt(timeArray[1], 10);
    const seconds = parseInt(timeArray[2], 10);
    const totalMinutes = hours * 60 + minutes + seconds / 60;
    setService({
      ...service,
      duration_minutes: Number.isNaN(totalMinutes) ? undefined : totalMinutes,
    });
  };

  return (
    <>
      <DialogWithCloseIcon
        open={addServiceDialog}
        handleClose={handleCloseServiceDialog}
        dialogSize={"xs"}
      >
        <Box className={classes.addServiceContainer}>
          <Typography variant="h2">
            {editIndex !== undefined ? t("edit_service") : t("add_service")}
          </Typography>

          <Textfield
            label={t("name")}
            placeholder={t("service_title_desc")}
            value={service.name}
            handleChange={(e) => {
              setService({ ...service, name: e.target.value });
            }}
          />

          <Box>
            <Typography variant="h4" align="left">
              {t("duration")}
            </Typography>
            <TimeInterval
              isService={true}
              // timeInterval={parseDuration(service.duration_minutes)}
              timeInterval={timeInterval}
              handleIntervalChange={(e) => handleTimeChange(e)}
              isBorder={true}
              notNewMeeting={true}
              maxInterval={undefined}
              // hideCustom={true}
            />
          </Box>
          {timeInterval === "custom" ? (
            <Box>
              <CustomInterval
                setCustomTime={setCustomTime}
                customTime={customTime}
                showDropDown={false}
                notNewMeeting={false}
                isTodo={false}
              />
            </Box>
          ) : null}
          <Box>
            <Typography variant="h4" align="left">
              {t("location")}
            </Typography>
            <FormControl
              variant="standard"
              sx={{
                width: "100%",
                marginTop: "10px",
                textAlign: "left",
                borderRadius: "6px",
                backgroundColor: "#F7F7F9",
                marginRight: 2,
              }}
            >
              <Select
                label={"Locations"}
                variant="standard"
                value={selectedLocation}
                onChange={(e) => {
                  console.log("hey", e);
                  setSelectedLocation(e.target.value);
                }}
                disableUnderline={true}
                style={{
                  textAlign: "left",
                  fontSize: 14,
                  marginLeft: 4,
                }}
                // displayEmpty
                // renderValue={(selected) => {
                //   console.log("Sel", selected);
                //   if (!selected) {
                //     return <Typography>Placeholder</Typography>;
                //   }
                //   return selected;
                // }}
              >
                {websiteLocations?.map((option, i) => {
                  return (
                    <MenuItem key={i} value={option._id}>
                      {option.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>

          {service?.questions && (
            <Box
              style={{
                display: "center",
                textAlign: "left",
                gap: 2,
              }}
            >
              <Typography variant="h3">{t("questions")}</Typography>
              <ServiceQuestion
                classes={classes}
                service={service}
                isQuestion={true}
                handleQuestion={handleAddQuestionType}
                suggestedService={suggestedService}
                suggestedQuestions={suggestedQuestions}
                setSuggestedQuestions={setSuggestedQuestions}
                handleDeleteQuestionInService={handleDeleteQuestionInService}
              />
            </Box>
          )}
          <Button
            variant="outlined"
            style={{
              border: "none",
              color: "#479AE9",
              justifyContent: "left",
              alignItems: "flex-start",
              width: "fit-content",
            }}
            startIcon={
              addQuestion ? (
                <RemoveCircleOutlineIcon />
              ) : (
                <AiOutlinePlusCircle />
              )
            }
            onClick={handleAddQuestion}
          >
            {t("add_question")}
          </Button>
          {!addQuestion ? null : (
            <Box className={classes.addServiceQuestionContainer}>
              <Typography variant="h5" fontWeight={500}>
                {t("questions")}
              </Typography>
              <AddServiceQuestions
                label={t("long_answer")}
                desc={t("long_answer_desc")}
                onClick={() => handleAddQuestionType("long")}
              />
              <AddServiceQuestions
                label={t("number")}
                desc={t("number_desc")}
                onClick={() => handleAddQuestionType("number")}
              />
              <AddServiceQuestions
                label={t("checkbox")}
                desc={t("checkbox_desc")}
                onClick={() => handleAddQuestionType("checkbox")}
              />
            </Box>
          )}

          {suggestedQuestions.length > 0 && (
            <Box className={classes.addServiceQuestionContainer}>
              <Typography variant="h5" fontWeight={500}>
                {t("suggested_questions")}
              </Typography>
              {suggestedQuestions?.map((s: any, i) => {
                return (
                  <Box className={classes.questionBoxRow}>
                    <Typography
                      variant="h4"
                      fontWeight={400}
                      onClick={() => {
                        let tempQs = service?.questions
                          ? service.questions
                          : [];

                        s.index = i;
                        tempQs = [...tempQs, s];
                        setSuggestedQuestions(
                          suggestedQuestions.filter((q, ind) => ind !== i)
                        );

                        setService({
                          ...service,
                          questions: tempQs,
                        });
                      }}
                    >
                      <AddIcon
                        style={{
                          width: 16,
                          height: 16,
                          marginRight: 8,
                        }}
                      />

                      {s?.label}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          )}

          <ButtonGroup
            handleCancel={handleCloseServiceDialog}
            classes={classes}
            handleSave={handleServiceSave}
            t={t}
          />
        </Box>
      </DialogWithCloseIcon>

      <AddQuestionDialog
        addQuestion={addQuestionType}
        handleAddQuestion={handleAddQuestionType}
        question={currentQuestion}
        setQuestion={setCurrentQuestion}
        questionType={"long"}
        questionHeading={t("add_long_question")}
        handleSave={handleSave}
      />

      <AddQuestionDialog
        addQuestion={addQuestionType}
        handleAddQuestion={handleAddQuestionType}
        question={currentQuestion}
        setQuestion={setCurrentQuestion}
        questionType={"number"}
        questionHeading={t("add_number")}
        handleSave={handleSave}
      />

      <AddQuestionDialog
        addQuestion={addQuestionType}
        handleAddQuestion={handleAddQuestionType}
        question={currentQuestion}
        setQuestion={setCurrentQuestion}
        questionType={"checkbox"}
        questionHeading={t("add_checkbox")}
        handleSave={handleSave}
      />
      <ErrorSnackbar
        open={!!message}
        handleClose={() => setMessage("")}
        message={message}
        vertical={"bottom"}
        duration={3000}
        horizontal={"center"}
      />
      <SnackbarComponent
        open={!!addServiceMessage}
        handleClose={() => setAddServiceMessage("")}
        message={addServiceMessage}
        vertical={"bottom"}
        horizontal={"center"}
      />
    </>
  );
}
