import { Stack, Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function PasswordRequirements() {
  const { t } = useTranslation();

  return (
    <Box
      display={"flex"}
      flexDirection="column"
      style={{ width: 360, margin: "auto", marginTop: 10 }}
    >
      <Typography align="left" variant="h3">
        {t("password_contains")}
      </Typography>
      <Typography
        variant="h3"
        style={{ whiteSpace: "break-spaces", fontWeight: 400 }}
        align="left"
      >
        <ul align="left" style={{ lineHeight: "2", paddingTop: "0px" }}>
          <li>{t("8_characters")}</li>
          <li>{t("1_uppercase")}</li>
          <li>{t("1_lowercase")}</li>
          <li>{t("1_number")}</li>
          <li>{t("1_special_char")}</li>
          <li>
            {t("you_cannot_use")} {"<"} {t("or")} {">"} {t("in_your_password")}
          </li>
        </ul>
      </Typography>
    </Box>
  );
}
