import axios from "axios";
import { get, put, post } from "axios";
import Axios from "axios";
import {
  convertDateToUTCMilliseconds,
  getOnlyAccessToken,
  getToken,
} from "./CommonFunctions";
import loggingInDev from "loggingInDev";
/**
 *
 * @param {string} path
 * @param {abortController} signal
 * @param {optional boolean to indicate use access token in case of scheduler} use_access
 * @returns
 */
const serverGET = async (
  path,
  signal,
  use_access,
  current_date = new Date()
) => {
  let response;
  if (signal) {
    response = await get(process.env.REACT_APP_HOST + path, {
      crossDomain: true,
      mode: "no-cors",
      headers: { token: use_access ? getOnlyAccessToken() : getToken() },
      params: { currentTime: convertDateToUTCMilliseconds(current_date) },
      signal: signal,
    });
  } else {
    response = await get(process.env.REACT_APP_HOST + path, {
      crossDomain: true,
      mode: "no-cors",
      headers: { token: use_access ? getOnlyAccessToken() : getToken() },
      params: { currentTime: convertDateToUTCMilliseconds(current_date) },
    });
  }

  // loggingInDev("serverGET response ===> ", response);
  try {
    if (response) {
      if (response.data.code === 200) {
        return response.data;
      } else {
        return response.data.message;
      }
    } else {
      loggingInDev("Error occured in serverGET");
      throw new Error("Error occured in serverGET");
    }
  } catch (error) {
    if (error.name === "AbortError") {
      loggingInDev("fetch aborted");
      // throw new Error("fetch aborted");
    } else {
      loggingInDev(error);
      throw new Error(error);
    }
  }
};

const serverGETWithToken = async (path, token) => {
  const response = await get(process.env.REACT_APP_HOST + path, {
    crossDomain: true,
    mode: "no-cors",
    headers: { token: token },
    params: { currentTime: convertDateToUTCMilliseconds(new Date()) },
  });
  // loggingInDev("serverGET response ===> ", response);
  // console.log("serverGET response ===> ", response);

  if (response) {
    if (response.data.code === 200) {
      return response.data;
    } else {
      return response.data.message;
    }
  } else {
    loggingInDev("Error occured in serverGET");
    return undefined;
  }
};

const httpGetUnsecure = async (path) => {
  try {
    const response = await get(process.env.REACT_APP_HOST + path, {
      crossDomain: true,
      mode: "no-cors",
    });
    loggingInDev("response ===> ", response);

    if (response) {
      loggingInDev("response is received");
      if (response.data.code === 200) {
        return response.data;
      } else if (response.data.code === 302) {
        loggingInDev("302 =>", response.data);
        return response;
      } else {
        loggingInDev("response ===> ", response);
        return undefined;
      }
    } else {
      loggingInDev("Error occured in httpGetUnsecure");
    }
  } catch (e) {
    loggingInDev("custom", e);
  }
};

const serverPUT = async (path, body) => {
  const response = await put(process.env.REACT_APP_HOST + path, body, {
    crossDomain: true,
    headers: { token: getToken() },
  });
  loggingInDev("response ===> ", response);

  if (response) {
    if (response.data.code === 200) {
      return response.data;
    } else {
      return response.data.message;
    }
  } else {
    loggingInDev("Error occured in serverPUT");
    return undefined;
  }
};
const serverPUTWithToken = async (path, token, body) => {
  const response = await put(process.env.REACT_APP_HOST + path, body, {
    crossDomain: true,
    headers: { token: token },
  });
  loggingInDev("response ===> ", response);

  if (response) {
    if (response.data.code === 200) {
      return response.data;
    } else {
      return response.data.message;
    }
  } else {
    loggingInDev("Error occured in serverPUT");
    return undefined;
  }
};

const serverPOST = async (path, body) => {
  const response = await post(process.env.REACT_APP_HOST + path, body, {
    crossDomain: true,
    headers: { token: getToken() },
  });
  // loggingInDev("response ===> ", response);
  if (response) {
    if (
      response.data.code === 201 ||
      response.data.code === 204 ||
      response.data.code === 200
    ) {
      return response.data;
    } else {
      return response.data.message;
    }
  } else {
    loggingInDev("Error occured in serverPOST");
    return undefined;
  }
};
const serverPOSTWithToken = async (path, body, token) => {
  const response = await post(process.env.REACT_APP_HOST + path, body, {
    crossDomain: true,
    headers: { token: token },
  });
  loggingInDev("response ===> ", response);
  if (response) {
    if (response.data.code === 201 || response.data.code === 204) {
      return response.data;
    } else {
      return response.data.message;
    }
  } else {
    loggingInDev("Error occured in serverPOST");
    return undefined;
  }
};

const serverGETPOST = async (path, body) => {
  const response = await post(process.env.REACT_APP_HOST + path, body, {
    crossDomain: true,
    headers: { token: getToken() },
  });
  // loggingInDev("response ===> ", response);
  if (response) {
    if (response.data.code === 200) {
      return response.data;
    } else {
      return response.data.message;
    }
  } else {
    loggingInDev("Error occured in serverPOST");
    return undefined;
  }
};

const httpPOSTUnsecure = async (path, body) => {
  const response = await post(process.env.REACT_APP_HOST + path, body, {
    crossDomain: true,
    mode: "no-cors",
  });
  loggingInDev("response ===> ", response);
  if (response) {
    if (response.data.code === 201 || response.data.code === 200) {
      return response.data;
    } else {
      return response.data.message;
    }
  } else {
    loggingInDev("Error occured in serverPOST");
    return undefined;
  }
};

const serverGETPOSTWithToken = async (path, token, body) => {
  const response = await post(process.env.REACT_APP_HOST + path, body, {
    crossDomain: true,
    headers: { token: token },
  });
  loggingInDev("response ===> ", response);
  if (response) {
    if (response.data.code === 200) {
      return response.data;
    } else {
      return response.data.message;
    }
  } else {
    loggingInDev("Error occured in serverPOST");
    return undefined;
  }
};

const serverDELETE = async (path, body) => {
  const response = await axios.delete(process.env.REACT_APP_HOST + path, {
    crossDomain: true,
    mode: "no-cors",
    headers: { token: getToken() },
    params: { currentTime: convertDateToUTCMilliseconds(new Date()) },
    data: body,
  });

  loggingInDev("response ===> ", response);
  if (response) {
    if (response.data.code === 200) {
      return response.data;
    } else {
      return response.data.message;
    }
  } else {
    loggingInDev("Error occured in serverDELETE");
    return undefined;
  }
};

export {
  serverGET,
  httpGetUnsecure,
  httpPOSTUnsecure,
  serverPUT,
  serverGETWithToken,
  serverPUTWithToken,
  serverPOST,
  serverGETPOSTWithToken,
  serverGETPOST,
  serverPOSTWithToken,
  serverDELETE,
};
