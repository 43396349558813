import React, { useState, useEffect, useContext } from "react";
import {
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
  Button,
  Box,
  useTheme,
  Dialog,
  Grid,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker, TimePicker } from "@mui/x-date-pickers";
import moment from "moment";

import useStyle from "styles/formStyles";
import { serverPUT } from "Utils/HttpFunctions";
import { convertUTCMillisecondsToDate } from "Utils/CommonFunctions";
import { useTranslation } from "react-i18next";
import useEventsStore from "../../Utils/eventStore";

function EditTodo({ event, handleEdit }) {
  const { calendarTasks, setCalendarTasks } = useEventsStore();
  const [taskName, setTaskName] = useState("");
  const [duration, setDuration] = useState(60);
  const [error, setError] = useState("");
  const theme = useTheme();
  const classes = useStyle();
  const [todoDate, setTodoDate] = useState(moment());
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [timeOpen, setTimeOpen] = useState(false);
  const [indicator, setIndicator] = useState({
    isDateSelected: false,
    isTimeSelected: false,
  });
  const { t } = useTranslation();

  useEffect(() => {
    if (event) {
      setTaskName(event.title);
      setDuration(event.duration);
      if (event.start) {
        setTodoDate(event.start);
        setIndicator({
          isDateSelected: true,
          isTimeSelected: true,
        });
      }
    }
  }, [event]);

  /**
   * Makes request to the backend to update the todo item
   * @async
   */
  const handleUpdate = async () => {
    if (taskName.trim() === "") {
      setError("invlaid todo name");
      return;
    }
    if (!duration) {
      return;
    }

    let data = {
      title: taskName,
      duration_minutes: duration,
    };

    if (indicator.isDateSelected) {
      const d = new Date(todoDate);
      const mom = moment(d);
      data.start_datetime = mom.toDate().getTime();
      data.end_datetime = moment(data.start_datetime)
        .add(duration, "minutes")
        .toDate()
        .getTime();
    }

    const response = await serverPUT(`/todo/${event.id}`, data);
    if (response && response.constructor !== String) {
      const temp = [...calendarTasks];
      const index = temp.indexOf(event);
      if (index !== -1) {
        temp[index].title = data.title;
        temp[index].duration = data.duration_minutes;
        temp[index].start = data.start_datetime
          ? convertUTCMillisecondsToDate(data?.start_datetime)
          : undefined;
        temp[index].end = data.start_datetime
          ? convertUTCMillisecondsToDate(data?.end_datetime)
          : undefined;
      }
      setCalendarTasks(temp);

      handleEdit();
    }
  };

  return event ? (
    <Dialog
      open={!!event}
      onClose={handleEdit}
      maxWidth={"xs"}
      PaperProps={{ sx: { padding: "20px", borderRadius: "8px" } }}
    >
      <Grid
        container
        rowSpacing={1}
        style={{
          margin: "auto",
        }}
        className={classes.root}
      >
        <Grid item xs={12}>
          <Typography variant="h2" fontWeight={500} align="center">
            {t("edit_task")}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField
            placeholder={t("title")}
            variant="standard"
            autoComplete="off"
            style={{ marginTop: 10 }}
            error={!!error}
            value={taskName ?? ""}
            helperText={error}
            sx={{ width: "100%" }}
            autoFocus
            InputProps={{
              disableUnderline: true,
              classes: { input: `${classes.input} ${classes.setTime}` },
            }}
            onChange={(e) => {
              if (error) {
                setError("");
              }
              setTaskName(e.target.value);
            }}
            id="create-form"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="standard" sx={{ width: "100%" }}>
            <Select
              labelId="time-interval-label"
              id="event-time-interval"
              defaultValue={60}
              disableUnderline={true}
              className={classes.setTime}
              value={duration ?? 60}
              onChange={(e) => setDuration(e.target.value)}
            >
              <MenuItem value={15}>15 minutes</MenuItem>
              <MenuItem value={30}>30 minutes</MenuItem>
              <MenuItem value={45}>45 minutes</MenuItem>
              <MenuItem value={60}>1 hour (60 minutes)</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Box
            display={"flex"}
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Typography className={classes.setTime}>
              {t("set_date")}{" "}
              <span className={classes.grayMsg}> ({t("optional")})</span>
            </Typography>
            <Button
              variant="text"
              style={{
                width: indicator.isDateSelected ? "112px" : "100px",
                padding: 0,
              }}
              disableRipple={true}
              onClick={() => {
                if (!indicator.isDateSelected) {
                  setCalendarOpen(true);
                  setIndicator({
                    ...indicator,
                    isDateSelected: true,
                  });
                }
              }}
            >
              {indicator.isDateSelected ? (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDatePicker
                    open={calendarOpen}
                    onOpen={() => setCalendarOpen(true)}
                    onClose={() => setCalendarOpen(false)}
                    value={todoDate}
                    disablePast={true}
                    showToolbar={false}
                    onChange={(newValue) => {
                      setTodoDate(newValue);
                    }}
                    minDate={new Date()}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        onClick={(e) => setCalendarOpen((prev) => !prev)}
                        onKeyDown={(e) => e.preventDefault()}
                        className={classes.dateTime}
                        InputProps={{
                          disableUnderline: true,
                          style: { fontSize: "16px", width: "120px" },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="calendar"
                                onClick={() =>
                                  setIndicator({
                                    ...indicator,
                                    isDateSelected: false,
                                  })
                                }
                              ></IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              ) : (
                t("select_date")
              )}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            display={"flex"}
            justifyContent="space-between"
            alignItems={"center"}
            width="100%"
          >
            <Typography className={classes.setTime}>
              {t("set_time")}{" "}
              <span className={classes.grayMsg}> ({t("optional")})</span>
            </Typography>
            <Button
              variant="text"
              style={{ width: "100px", padding: 0 }}
              disableRipple={true}
              onClick={() => {
                if (!indicator.isTimeSelected) {
                  setTimeOpen(true);
                  setIndicator({
                    ...indicator,
                    isTimeSelected: true,
                  });
                }
              }}
            >
              {indicator.isTimeSelected ? (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    open={timeOpen}
                    onOpen={() => setTimeOpen(true)}
                    onClose={() => setTimeOpen(false)}
                    value={todoDate}
                    disablePast={true}
                    minutesStep={15}
                    onChange={(newValue) => {
                      setTodoDate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        onClick={(e) => setTimeOpen((prev) => !prev)}
                        onKeyDown={(e) => e.preventDefault()}
                        className={classes.dateTime}
                        InputProps={{
                          disableUnderline: true,
                          style: { fontSize: "16px", width: "120px" },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="calendar"
                                onClick={() =>
                                  setIndicator({
                                    ...indicator,
                                    isTimeSelected: false,
                                  })
                                }
                              ></IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              ) : (
                t("select_time")
              )}
            </Button>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          container
          justifyContent={"center"}
          columnSpacing={4}
          sx={{ mt: 2 }}
        >
          <Grid item>
            <Button variant="outlined" onClick={handleEdit}>
              {t("cancel")}
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={handleUpdate}>
              {t("done")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  ) : (
    <></>
  );
}

export default React.memo(EditTodo);
