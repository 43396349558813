import { Box } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  BUSY_SLOTS_COLOR,
  MODERATE_SLOTS_COLOR,
  OPEN_SLOTS_COLOR,
} from "../../Utils/Constants";
import ColorIndicator from "../meetings/ColorIndicator";
import { useStyle } from "../../styles/tooltip";
import ToolTip from "../Global/ToolTip";

export default function MeetingLabels() {
  const { t } = useTranslation();
  const tooltipStyle = useStyle();
  return (
    <Box display={"flex"} columnGap="10px">
      <ToolTip title={t("optimal_msg")} placement="bottom">
        <Box>
          <ColorIndicator
            text={t("optimal_title")}
            color={OPEN_SLOTS_COLOR}
            border="#249F5D"
          />
        </Box>
      </ToolTip>
      <ToolTip title={t("moderate_msg")} placement="bottom">
        <Box>
          <ColorIndicator
            text={t("moderate_title")}
            color={MODERATE_SLOTS_COLOR}
            border="#FFB545"
          />
        </Box>
      </ToolTip>
      <ToolTip title={t("busy_msg")} placement="bottom">
        <Box>
          <ColorIndicator
            text={t("busy_title")}
            color={BUSY_SLOTS_COLOR}
            border="#F32D2D"
          />
        </Box>
      </ToolTip>
    </Box>
  );
}
