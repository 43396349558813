import React from "react";

import { FormControl, MenuItem, Select, useTheme } from "@mui/material";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import { useTranslation } from "react-i18next";

/**
 * A dropdown that allows a user to create a recurring meeting from the new meeting page
 */
export default function RecurringMeetingCheck({ meetingType, setMeetingType }) {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <>
      <FormControl variant="standard">
        <Select
          labelId="meeting-label"
          id="event-meeting-interval"
          disableUnderline={true}
          value={meetingType}
          IconComponent={() => (
            <EventRepeatIcon sx={{ paddingRight: "10px", fontSize: "16px" }} />
          )}
          onChange={(e) => setMeetingType(e.target.value)}
          style={{
            textAlign: "left",
            fontSize: 14,
            fontWeight: 400,
            color: theme.palette.text.primary,
            paddingRight: "8px",
          }}
          SelectDisplayProps={{
            sx: {
              order: "2",
              display: "flex",
              alignItems: "center",
              paddingLeft: "10px",
            },
          }}
          inputProps={{
            fontSize: "14px",
          }}
          sx={{
            paddingTop: "7px",
            fontWeight: "500",
          }}
          displayEmpty={true}
        >
          <MenuItem value={""}>{t("no_repeat")}</MenuItem>
          <MenuItem value={"daily"}>
            {meetingType === "daily" ? t("repeat_daily") : t("daily")}
          </MenuItem>
          <MenuItem value={"weekly"}>
            {meetingType === "weekly" ? t("repeat_weekly") : t("weekly")}
          </MenuItem>
          <MenuItem value={"monthly"}>
            {meetingType === "monthly" ? t("repeat_monthly") : t("monthly")}
          </MenuItem>
          <MenuItem value={"yearly"}>
            {meetingType === "yearly" ? t("repeat_yearly") : t("yearly")}
          </MenuItem>
          <MenuItem value={"weekday"}>
            {meetingType === "weekday" ? t("repeat_weekday") : t("weekday")}
          </MenuItem>
        </Select>
      </FormControl>
    </>
  );
}
