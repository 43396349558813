import { useEffect, useState, useRef, useContext } from "react";

import Card from "@mui/material/Card";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import {
  Tooltip,
  Box,
  Typography,
  Grid,
  Stack,
  IconButton,
  useTheme,
  useMediaQuery,
  Button,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import moment from "moment";
import { dayHeader, removeOffsetFromDate } from "Utils/CommonFunctions";
import "styles/freeSlotCalendar.css";
import ErrorSnackbar from "components/ErrorSnackbar";
import { accountContext } from "context/user";
import { useTranslation } from "react-i18next";

export default function FreeSlotCalendarTemp({
  setErrorAmount,
  errorAmount,
  onCalendarMonthChange,
  setSelectedSlot,
  events: totalEvents,
  participants,
  reset,
  popover,
  initiatorResponse,
  isLogged,
  selectedTimes,
  setSelectedTimes,
  meetingType,
  createMeeting,
}) {
  const [availableGaps, setAvailableGaps] = useState();
  const [freeSlots, setFreeSlots] = useState({ events: [] });
  const [eventTooltips, setEventTooltips] = useState([]);
  const [unavailError, setUnavailError] = useState("");
  const { user } = useContext(accountContext);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const calendarRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    setSelectedSlot(selectedTimes.events);
  }, [selectedTimes]);

  useEffect(() => {
    if (selectedTimes.events.length > 0) {
      setSelectedTimes({ events: [] });
    }
  }, [reset]);

  useEffect(() => {
    if (calendarRef) {
      setCalendarView();
    }
  }, [calendarRef]);

  useEffect(() => {
    var userUnavailableFiltered = filterAvailableTimes();
    setEventBackgrounds(userUnavailableFiltered);
  }, [totalEvents]);

  useEffect(() => {
    if (freeSlots !== null) {
      selectConstraints();
      createTooltip();
    }
  }, [freeSlots]);

  const filterAvailableTimes = () => {
    var currentUserEmail = user?.email;
    var currentUserRemoved = totalEvents?.filter(
      (availableTime) =>
        availableTime?.unAvailableParticipants?.find(
          (unavailableParticipant) =>
            unavailableParticipant?.email === currentUserEmail
        ) === undefined
    );
    return currentUserRemoved;
  };

  const setCalendarView = () => {
    var sortedTimes = sortTimes("earliest");
    var firstAvailableTime = sortedTimes[0];

    // checkIfFirstAvailableInView(firstAvailableTime);
  };

  const checkIfFirstAvailableInView = (firstAvailableTime) => {
    var viewStart =
      calendarRef && calendarRef.current.getApi().view.activeStart;
    var viewEnd = calendarRef && calendarRef.current.getApi().view.activeEnd;

    switch (
      firstAvailableTime?.start?.getTime() >= viewStart?.getTime() &&
      firstAvailableTime?.start?.getTime() <= viewEnd?.getTime()
    ) {
      case false:
        calendarRef.current
          .getApi()
          .changeView("timeGridWeek", firstAvailableTime.start);
        break;
      case true:
        break;
    }
  };

  const setEventBackgrounds = (totalEvents) => {
    var slotsWithBackground =
      totalEvents?.length > 0
        ? totalEvents.map((availableTime) => {
            return {
              start: availableTime.start,
              end: availableTime.end,
              availableParticipants: availableTime.availableParticipants,
              unavailableParticipants: availableTime.unAvailableParticipants,
              backgroundColor: colorWithOpacity(
                availableTime?.availableParticipants?.length
              ),
            };
          })
        : [];

    if (Array.isArray(slotsWithBackground)) {
      setFreeSlots({
        events: [...slotsWithBackground],
        display: "background",
      });
    }
  };

  const colorWithOpacity = (numberOfAvailable) => {
    var opacity = numberOfAvailable / participants.length;
    // const c = `rgba(80, 188, 128, ${opacity})`;
    const c = `rgba(150, 220, 255, ${opacity})`;
    return c;
  };

  const findGaps = (sortedAvailability, gaps) => {
    sortedAvailability.forEach((available, i) => {
      if (i >= 1) {
        if (
          sortedAvailability[i - 1].end.getTime() < available.start.getTime()
        ) {
          gaps.push({
            start: sortedAvailability[i - 1].end,
            end: available.start,
          });
        }
      }
    });
  };

  const selectConstraints = () => {
    var sortedAvailability = [...freeSlots.events];

    sortedAvailability.sort((a, b) => a.start.getTime() - b.start.getTime());
    var gaps = [];

    findGaps(sortedAvailability, gaps);

    setAvailableGaps(gaps);
  };

  const handleWeekdayClick = async (date) => {
    var timesInDay = [];
    freeSlots.events.forEach((availableTime) => {
      if (
        (availableTime.start.getDate() === date.getDate() &&
          availableTime.start.getMonth() === date.getMonth()) ||
        (availableTime.end.getDate() === date.getDate() &&
          availableTime.end.getMonth() === date.getMonth())
      ) {
        timesInDay.push({
          id: Math.random().toString(36).substr(2, 4),
          start: availableTime.start,
          end: availableTime.end,
        });
      }
    });

    var timesSelectedFromDate = [];
    var clearTimesInDate = false;

    timesInDay.forEach((availableTime) => {
      if (
        selectedTimes.events.find(
          (selectedTime) => selectedTime.start === availableTime.start
        ) !== undefined
      ) {
        timesSelectedFromDate.push(availableTime);
      }
    });

    if (timesSelectedFromDate.length === timesInDay.length) {
      clearTimesInDate = true;
    }

    var tempSelected = selectedTimes.events.filter(
      (alreadySelected) =>
        timesInDay.find(
          (availableTime) => availableTime.start === alreadySelected.start
        ) === undefined
    );

    if (clearTimesInDate) {
      setSelectedTimes({
        events: [...tempSelected],
        backgroundColor: "rgba(114, 176, 238, 0.5)",
        display: "block",
      });
    } else {
      setSelectedTimes({
        events: [...tempSelected, ...timesInDay],
        backgroundColor: "rgba(114, 176, 238, 0.5)",
        display: "block",
      });
    }
  };

  const findSelectionInAvailable = (selectionStart, selectionEnd) => {
    var availableTimeRange = [];

    freeSlots.events.forEach((availableTime) => {
      //handles selections not within one available time
      if (
        (availableTime.start.getTime() > selectionStart &&
          availableTime.start.getTime() < selectionEnd) ||
        (availableTime.end.getTime() > selectionStart &&
          availableTime.end.getTime() < selectionEnd)
      ) {
        availableTimeRange.push({
          id: Math.random().toString(36).substr(2, 4),
          start: availableTime.start,
          end: availableTime.end,
        });
        //handles selections within one available time
      } else if (
        availableTime.start.getTime() <= selectionStart &&
        availableTime.end.getTime() >= selectionEnd
      ) {
        availableTimeRange.push({
          id: Math.random().toString(36).substr(2, 4),
          start: availableTime.start,
          end: availableTime.end,
        });
      }
    });
    return availableTimeRange;
  };

  const findStartsInAvailable = (selectionStart, selectionEnd) => {
    var availableTimeRange = [];

    freeSlots.events.forEach((availableTime) => {
      //handles selections not within one available time
      if (
        (availableTime.start.getTime() > selectionStart &&
          availableTime.start.getTime() < selectionEnd) ||
        (availableTime.end.getTime() > selectionStart &&
          availableTime.end.getTime() < selectionEnd)
      ) {
        availableTimeRange.push(availableTime.start.getTime());
        //handles selections within one available time
      } else if (
        availableTime.start.getTime() <= selectionStart &&
        availableTime.end.getTime() >= selectionStart &&
        availableTime.start.getTime() <= selectionEnd &&
        availableTime.end.getTime() >= selectionEnd
      ) {
        availableTimeRange.push(availableTime.start.getTime());
      }
    });
    return availableTimeRange;
  };

  const handleSingleSelect = (selection) => {
    var selectionStart = selection.start.getTime();
    var selectionEnd = selection.end.getTime();
    var temp = [];

    var selectionInAvailable = findSelectionInAvailable(
      selectionStart,
      selectionEnd
    );
    if (selectionInAvailable.length > 0) {
      temp.push(...selectionInAvailable);

      setSelectedTimes({
        events: [...temp],
        backgroundColor: "rgba(114, 176, 238, 0.5)",
        display: "block",
      });
      calendarRef.current.getApi().unselect();
    } else {
      setUnavailError(
        "You can only select available slots that are highlighted in blue"
      );
    }
  };

  const handleSelect = (selection) => {
    if (errorAmount) {
      setErrorAmount("");
    }
    var selectionStart = selection.start.getTime();
    var selectionEnd = selection.end.getTime();

    var selectedTimesInSelection = [];
    selectedTimes.events.forEach((alreadySelected) => {
      //case for selection fully within one time
      if (
        alreadySelected.start.getTime() <= selectionStart &&
        alreadySelected.start.getTime() <= selectionEnd &&
        alreadySelected.end.getTime() >= selectionStart &&
        alreadySelected.end.getTime() >= selectionEnd
      ) {
        selectedTimesInSelection.push(alreadySelected.start.getTime());
      }
      //case for selections within more than one available time
      else if (
        alreadySelected.start.getTime() >= selectionStart &&
        alreadySelected.start.getTime() <= selectionEnd &&
        alreadySelected.end.getTime() >= selectionStart &&
        alreadySelected.end.getTime() <= selectionEnd
      ) {
        selectedTimesInSelection.push(alreadySelected.start.getTime());
      }
    });

    //check if selection contains already selected times
    switch (selectedTimesInSelection.length === 0) {
      case true:
        var temp = [...selectedTimes.events];
        var selectionInAvailable = findSelectionInAvailable(
          selectionStart,
          selectionEnd
        );
        if (selectionInAvailable.length > 0) {
          temp.push(...selectionInAvailable);
        } else {
          setUnavailError(
            "You can only select available slots that are highlighted in blue"
          );
        }

        break;
      case false:
        var temp = [];
        var selectionInAvailable = findStartsInAvailable(
          selectionStart,
          selectionEnd
        );
        selectedTimes.events.forEach((alreadySelected) => {
          if (
            selectionInAvailable.includes(alreadySelected.start.getTime()) ===
            false
          ) {
            temp.push({
              id: Math.random().toString(36).substr(2, 4),
              start: alreadySelected.start,
              end: alreadySelected.end,
            });
          }
        });
        break;
    }

    setSelectedTimes({
      events: [...temp],
      backgroundColor: "rgba(114, 176, 238, 0.5)",
      display: "block",
    });
    calendarRef.current.getApi().unselect();
  };

  const getParticipantsArrays = (availability) => {
    return freeSlots.events.map((availableTime) => {
      if (availability === "available") {
        return availableTime?.availableParticipants;
      } else {
        return availableTime?.unavailableParticipants;
      }
    });
  };

  const collectParticipantNames = (participants) => {
    // if (participants?.length === 0) {
    //   return [];
    // }
    // console.log("participants ==>", participants);
    var participantNames = [];
    participants?.map((participantArray) => {
      var temp = [];

      participantArray?.map((participant) => {
        temp.push(participant.email);
      });
      participantNames.push(temp);
    });

    return participantNames;
  };

  const participantElements = (available, unavailable) => {
    var availableElements = available?.map((participantArray, i) => {
      return (
        participantArray.length &&
        participantArray.map((participant, j) => {
          // if (
          //   participants.find(
          //     (allParticipants) => allParticipants === participant
          //   ) !== undefined
          // ) {
          return (
            <Grid container key={j} sx={{ mt: "8px", mr: "5px" }}>
              <Typography
                sx={{
                  mr: "5px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                variant="h5"
                fontWeight={400}
              >
                {user?.email === participant ? "You" : participant}
              </Typography>
            </Grid>
          );
          // }
        })
      );
    });

    var unavailableElements = unavailable.map((participantArray, i) => {
      return (
        participantArray.length > 0 &&
        participantArray.map((participant, j) => {
          return (
            <Grid container key={j} sx={{ mt: "8px", mr: "5px" }}>
              <Typography
                variant="h5"
                sx={{
                  textDecoration: "line-through",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {participant}
              </Typography>
            </Grid>
          );
        })
      );
    });

    return freeSlots.events.map((available, i) => {
      return (
        <Grid
          container
          item
          flexDirection={"column"}
          xs={6}
          sx={{ pr: "10px", pl: "8px" }}
        >
          <Grid item>
            <Typography variant="h5" fontWeight={500}>
              {t("available")}
            </Typography>
          </Grid>
          {availableElements[i]}
          {unavailableElements[i]}
        </Grid>
      );
    });
  };

  const popupTitle = (available) => {
    var fractionAvailable =
      available.length > 0 &&
      available.map((participantArray, i) => {
        var participantsWithoutInitiator = participantArray?.length - 1;

        return (
          <Grid item xs={12} style={{ padding: 8 }}>
            <Typography key={i} variant="h3" sx={{ fontWeight: "700" }}>
              {participantsWithoutInitiator.toString()}/
              {participants?.length.toString()} Members
            </Typography>
          </Grid>
        );
      });

    return fractionAvailable;
  };

  const pendingParticipantElements = (available, unavailable) => {
    var respondedParticipants = freeSlots.events.map((availableTime, i) => {
      var temp = available[i];
      temp.push(...unavailable[i]);
      return temp;
    });

    var pending = respondedParticipants.map((respondedParticipant, i) => {
      return participants.map((participant) => {
        if (
          respondedParticipant.find(
            (respondedParticipant) => respondedParticipant === participant
          ) === undefined
        ) {
          return participant;
        } else {
          return;
        }
      });
    });

    var cleanedPending = pending.map((pendingParticipants) => {
      return pendingParticipants.filter(
        (participant) => participant !== undefined
      );
    });

    var pendingElements = cleanedPending.map((pendingParticipantsArray, i) => {
      return (
        <Grid item flexDirection={"column"} xs={6} sx={{ ml: "5px" }}>
          <Grid item>
            <Typography variant="h5" fontWeight={500}>
              {createMeeting ? t("unknown_avail") : t("pending_avail")}
            </Typography>
          </Grid>
          {pendingParticipantsArray.map((pendingParticipant, i) => {
            return (
              <Typography
                variant="h5"
                key={i}
                sx={{
                  pt: "8px",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
                fontWeight={400}
              >
                {pendingParticipant}
              </Typography>
            );
          })}
        </Grid>
      );
    });

    return pendingElements;
  };

  const getDateTime = () => {
    var startAndEndSame = true;

    return freeSlots.events.map((availableTime, i) => {
      if (availableTime.start.getDate() !== availableTime.end.getDate()) {
        startAndEndSame = false;
      } else {
        startAndEndSame = true;
      }

      if (startAndEndSame) {
        return (
          <Grid item key={i} xs={12} sx={{ pb: "10px", pl: "8px" }}>
            <Typography variant="h4">
              {availableTime.start.toLocaleDateString()} (
              {availableTime.start.toLocaleTimeString(undefined, {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              })}{" "}
              -{" "}
              {availableTime.end.toLocaleTimeString(undefined, {
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              })}
              )
            </Typography>
          </Grid>
        );
      } else {
        return (
          <Grid item key={i} xs={12} sx={{ pb: "10px", pl: "8px" }}>
            <Typography variant="h4">
              {availableTime.start.toLocaleDateString()} (
              {availableTime.start.toLocaleTimeString()}) -{" "}
              {availableTime.end.toLocaleDateString()} (
              {availableTime.end.toLocaleTimeString()})
            </Typography>
          </Grid>
        );
      }
    });
  };

  const toolTipTitleElement = (available, pending, title, dateTime) => {
    return freeSlots.events.map((availableTime, i) => {
      return (
        <Grid
          container
          style={{
            backgroundColor: "white",
            width: "100%",
            padding: 5,
            borderRadius: 10,
            // display: "-webkit-box",
          }}
          key={i}
          datastart={availableTime.start}
          dataend={availableTime.end}
        >
          <Box style={{ width: "100%" }}>
            <Typography variant="h3" fontWeight={600}>
              {title[i]}
            </Typography>
          </Box>
          <Box>
            <Typography variant="h4">{dateTime[i]}</Typography>
          </Box>
          <Box style={{ width: "100%", display: "flex" }}>
            {available[i]}
            {pending[i]}
          </Box>
        </Grid>
      );
    });
  };

  const createTooltipTitles = async () => {
    var availableParticipants = await getParticipantsArrays("available");
    var unavailableParticipants = await getParticipantsArrays("unavailable");

    var availableParticipantNames = collectParticipantNames(
      availableParticipants
    );
    var unavailableParticipantNames = collectParticipantNames(
      unavailableParticipants
    );

    var availableParticipantElements = participantElements(
      availableParticipantNames,
      unavailableParticipantNames
    );
    var pendingElements = pendingParticipantElements(
      availableParticipantNames,
      unavailableParticipantNames
    );
    var title = popupTitle(availableParticipants);
    var dateTimeElements = getDateTime();
    var toolTipTitle = toolTipTitleElement(
      availableParticipantElements,
      pendingElements,
      title,
      dateTimeElements
    );

    return toolTipTitle;
  };

  const createTooltip = async () => {
    var tooltipTitles = await createTooltipTitles();

    var eventTooltips = tooltipTitles?.map((participants, i) => {
      return (
        <Tooltip
          key={i}
          enterDelay={0}
          enterTouchDelay={0}
          leaveTouchDelay={5000}
          title={participants}
          placement={mobile ? "top-start" : "right-start"}
          datastart={participants.props.datastart}
          dataend={participants.props.dataend}
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: "transparent",
                padding: 0,
                wordWrap: "normal",
              },
            },
          }}
        >
          <div
            style={{
              minWidth: "fit-content",
              height: "100%",
            }}
          ></div>
        </Tooltip>
      );
    });

    setEventTooltips(eventTooltips);
  };

  const injectTooltip = (arg) => {
    var start = arg.event._instance.range.start;
    var end = arg.event._instance.range.end;
    var offset = start.getTimezoneOffset() / 60;

    var localStart = new Date(
      start?.getFullYear(),
      start?.getMonth(),
      start?.getDate(),
      start?.getHours() + offset,
      start?.getMinutes()
    );
    var localEnd = new Date(
      end?.getFullYear(),
      end?.getMonth(),
      end?.getDate(),
      end?.getHours() + offset,
      end?.getMinutes()
    );

    var tooltip = eventTooltips.find(
      (eventTooltip) =>
        eventTooltip.props.datastart.getTime() === localStart.getTime() &&
        eventTooltip.props.dataend.getTime() === localEnd.getTime()
    );

    return tooltip;
  };

  const sortTimes = (time) => {
    var userUnavailableFiltered = filterAvailableTimes(totalEvents);

    var temp = [...userUnavailableFiltered];
    switch (time === "earliest") {
      case true:
        temp.sort((a, b) => a.start.getHours() - b.start.getHours());
        break;
      case false:
        temp.sort((a, b) => b.end.getHours() - a.end.getHours());
        break;
    }

    return temp;
  };

  // useEffect(()=>{
  //   getMinMaxTime("min")
  //   getMinMaxTime("max")
  // },[freeSlots])

  const getMinMaxTime = (setting) => {
    var sortedAvailability;
    var temp;

    if (setting === "min") {
      sortedAvailability = sortTimes("earliest");
      temp = sortedAvailability[0]?.start?.getHours();
    } else {
      sortedAvailability = sortTimes("latest");
      temp = sortedAvailability[0]?.end?.getHours() + 1;
    }

    if (temp < 10) {
      return `0${temp?.toString()}`;
      // if(setting == "min"){
      //   setCalendarMinTime(`0${temp?.toString()}:00:00`)
      // }
      // else{
      //   setCalendarMaxTime(`0${temp?.toString()}:00:00`)
      // }
    } else {
      return temp?.toString();
      // if(setting == "min"){
      //   setCalendarMinTime(`${temp?.toString()}:00:00`)
      // }
      // else{
      //   setCalendarMaxTime(`${temp?.toString()}:00:00`)
      // }
    }
  };

  const eventClicked = async (info) => {
    const temp = [...selectedTimes?.events];
    var eventId = info.event._def.publicId;
    let event = temp?.find((event) => event.id === eventId);
    const index = temp?.indexOf(event);
    if (index !== -1) {
      temp?.splice(index, 1);
      setSelectedTimes({
        events: [...temp],
        backgroundColor: "rgba(114, 176, 238, 0.5)",
        display: "block",
      });
      // calendarRef.current.getApi().unselect();
    }
  };

  const updateEventDrop = async (eventInfo, body) => {
    var selectionStart = body.start.getTime();
    var selectionEnd = body.end.getTime();
    const temp = [...selectedTimes?.events];
    var selectionInAvailable = findSelectionInAvailable(
      selectionStart,
      selectionEnd
    );

    if (selectionInAvailable?.length < 1) {
      setSelectedTimes({
        events: [...temp],
        backgroundColor: "rgba(114, 176, 238, 0.5)",
        display: "block",
      });

      return;
    }
    //checking is slot in outside bounds

    const singleDay = freeSlots.events.filter(
      (e) =>
        new Date(e.start).getDate() === new Date(body.start).getDate() &&
        new Date(e.start).getMonth() === new Date(body.start).getMonth()
    );

    if (singleDay.length === 1) {
      if (
        new Date(body.start) < singleDay[0].start ||
        new Date(body.end) > singleDay[0].end
      ) {
        setSelectedTimes({ ...selectedTimes, events: [...temp] });
        return;
      }
    }
    if (singleDay.length > 1) {
      if (
        new Date(body.start) < singleDay[0].start ||
        new Date(body.end) > singleDay[singleDay.length - 1].end
      ) {
        setSelectedTimes({ ...selectedTimes, events: [...temp] });
        return;
      }
    }

    //checking if slot overlaps existing selected slot
    const dayEvent = selectedTimes.events.filter(
      (e) =>
        new Date(e.start).getDate() === new Date(body.start).getDate() &&
        new Date(e.start).getMonth() === new Date(body.start).getMonth()
    );

    const eventTest = dayEvent.filter(
      (e) => new Date(e.start).getTime() === new Date(body.start).getTime()
    );
    if (eventTest.length > 0) {
      setSelectedTimes({ ...selectedTimes, events: [...temp] });
      return;
    }

    let eventId = eventInfo.event._def.publicId;
    let event = temp?.find((event) => event.id === eventId);
    const index = temp.indexOf(event);
    temp[index].start = body.start;
    temp[index].end = body.end;
    temp[index].title =
      moment(body.start).format("LT") + " - " + moment(body.end).format("LT");
    setSelectedTimes({
      events: [...temp],
      backgroundColor: "rgba(114, 176, 238, 0.5)",
      display: "block",
    });
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card
          sx={{
            p: mobile ? "0" : "15px",
            boxShadow: "0",
            borderRadius: "10px",
            backgroundColor: theme.palette.secondary.lighter,
            marginLeft: mobile ? 0 : "20px",
          }}
        >
          <ErrorSnackbar
            open={!!unavailError}
            handleClose={() => setUnavailError("")}
            message={unavailError}
            vertical={"bottom"}
            horizontal={"center"}
          />
          <Navigation calendarRef={calendarRef} mobile={mobile} />
          <Box
            sx={{
              overFlow: "auto",
              height: "66vh",
              // maxHeight: "720px"
            }}
          >
            {mobile ? (
              <FullCalendar
                // firstDay={1}
                plugins={[timeGridPlugin, interactionPlugin]}
                eventContent={popover ? (arg) => injectTooltip(arg) : null}
                initialView="timeGridWeek"
                editable={true}
                eventDurationEditable={false}
                eventStartEditable={true}
                allDaySlot={false}
                slotDuration={"00:15:00"}
                //right
                // slotMinTime={`${getMinMaxTime("min")}:00:00`}
                // slotMaxTime={`${getMinMaxTime("max")}:00:00`}

                // slotMinTime={calendarMinTime === "" ? '00:00:00' : calendarMinTime}
                // slotMaxTime={calendarMaxTime === "" ? '24:00:00': calendarMaxTime}
                expandRows={true}
                height={600}
                headerToolbar={false}
                slotLabelFormat={{
                  hour: "numeric",
                  minute: "2-digit",
                  // omitZeroMinute: true,
                  hour12: true,
                }}
                eventSources={[
                  Object.keys(freeSlots).length > 0 ? freeSlots : [],
                  Object.keys(selectedTimes).length > 0 ? selectedTimes : [],
                ]}
                eventOrderStrict={true}
                slotEventOverlap={false}
                displayEventTime={false}
                views={{
                  timeGridWeek: {
                    dayHeaderContent: (date) => dayHeader(date, "week"),
                  },
                }}
                selectable={isLogged ? false : true}
                datesSet={onCalendarMonthChange}
                // longPressDelay={500}
                selectLongPressDelay={500}
                select={
                  initiatorResponse || meetingType
                    ? handleSingleSelect
                    : handleSelect
                }
                navLinks={!(initiatorResponse || meetingType)}
                navLinkDayClick={(date) => handleWeekdayClick(date)}
                ref={calendarRef}
                nowIndicator={true}
                eventClick={eventClicked}
                eventDrop={function (eventDropInfo) {
                  updateEventDrop(eventDropInfo, {
                    start: removeOffsetFromDate(
                      eventDropInfo.event._instance.range.start
                    ),
                    end: removeOffsetFromDate(
                      eventDropInfo.event._instance.range.end
                    ),
                  });
                }}
              />
            ) : (
              <FullCalendar
                // firstDay={1}
                plugins={[timeGridPlugin, interactionPlugin]}
                eventContent={(arg) => injectTooltip(arg)}
                initialView="timeGridWeek"
                editable={true}
                eventDurationEditable={false}
                eventStartEditable={true}
                allDaySlot={false}
                slotDuration={"00:15:00"}
                //right
                // slotMinTime={`${getMinMaxTime("min")}:00:00`}
                // slotMaxTime={`${getMinMaxTime("max")}:00:00`}

                // slotMinTime={calendarMinTime === "" ? '00:00:00' : calendarMinTime}
                // slotMaxTime={calendarMaxTime === "" ? '24:00:00': calendarMaxTime}
                expandRows={true}
                height={"100%"}
                headerToolbar={false}
                slotLabelFormat={{
                  hour: "numeric",
                  minute: "2-digit",
                  // omitZeroMinute: true,
                  hour12: true,
                }}
                eventSources={[
                  Object.keys(freeSlots).length > 0 ? freeSlots : [],
                  Object.keys(selectedTimes).length > 0 ? selectedTimes : [],
                ]}
                eventOrderStrict={true}
                slotEventOverlap={false}
                displayEventTime={false}
                views={{
                  timeGridWeek: {
                    dayHeaderContent: (date) => dayHeader(date, "week"),
                  },
                }}
                selectable={isLogged ? false : true}
                datesSet={onCalendarMonthChange}
                select={
                  initiatorResponse || meetingType
                    ? handleSingleSelect
                    : handleSelect
                }
                navLinks={!(initiatorResponse || meetingType)}
                navLinkDayClick={(date) => handleWeekdayClick(date)}
                ref={calendarRef}
                nowIndicator={true}
                slotLabelInterval={"01:00"}
                eventClick={eventClicked}
                eventDrop={function (eventDropInfo) {
                  updateEventDrop(eventDropInfo, {
                    start: removeOffsetFromDate(
                      eventDropInfo.event._instance.range.start
                    ),
                    end: removeOffsetFromDate(
                      eventDropInfo.event._instance.range.end
                    ),
                  });
                }}
              />
            )}
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
}

function Navigation({ calendarRef, mobile }) {
  const [title, setTitle] = useState("");

  useEffect(() => {
    handleMonthHeading();
  }, [
    calendarRef?.current?.getApi()?.currentDataManager?.state?.dateProfile
      .activeRange,
  ]);

  const handleMonthHeading = () => {
    const viewTitle =
      calendarRef?.current?.getApi()?.currentDataManager?.data?.viewTitle;
    setTitle(viewTitle);
  };

  return (
    <Grid container alignItems="flex-start" justifyContent="center" mb="40px">
      <Grid
        container
        item
        xs={12}
        justifyContent="center"
        alignItems="flex-end"
        style={{ position: "relative", margin: mobile ? "10px" : 0 }}
      >
        {/* {mobile ? null : ( */}
        <Box
          style={{
            position: "absolute",
            left: "4px",
            top: "8px",
            display: "flex",
          }}
        >
          <Stack justifyContent="flex-start" direction="row">
            <Typography variant="h3">{title}</Typography>
          </Stack>
          <Stack
            justifyContent="flex-start"
            direction="row"
            style={{ marginLeft: "12px" }}
          >
            <Button
              variant="outlined"
              style={{
                marginTop: "-4px",
                color: "#363B53",
                padding: 0,
                fontWeight: 500,
              }}
              onClick={() => {
                calendarRef?.current?.getApi()?.today();
              }}
            >
              Today
            </Button>
          </Stack>
        </Box>
        {/* )} */}
        {/* {mobile ? null : ( */}
        <Box style={{ position: "absolute", right: 0, top: "-10px" }}>
          <Stack justifyContent="flex-end" direction="row">
            <ScrollButton
              direction="prev"
              calendarRef={calendarRef}
              setTitle={setTitle}
            />
            <ScrollButton
              direction="next"
              calendarRef={calendarRef}
              setTitle={setTitle}
            />
          </Stack>
        </Box>
        {/* )} */}
      </Grid>
    </Grid>
  );
}

function ScrollButton({ direction, calendarRef, setTitle }) {
  const theme = useTheme();
  const handleClick = () => {
    switch (direction === "prev") {
      case true:
        setTitle(calendarRef?.current?.getApi()?.prev());
        break;
      case false:
        setTitle(calendarRef?.current?.getApi()?.next());
        break;
    }
  };

  return (
    <IconButton onClick={handleClick} pt="0">
      {direction === "prev" ? (
        <ChevronLeftIcon
          fontSize="large"
          style={{ color: theme.palette.text.primary }}
        />
      ) : (
        <ChevronRightIcon
          fontSize="large"
          style={{ color: theme.palette.text.primary }}
        />
      )}
    </IconButton>
  );
}
