import { Box } from "@mui/material";

import BadgedChip from "./BadgedChip";

/**
 * Renders the times selected from the SelectTimes component on the NewMeeting page
 */
export default function SelectedTimes(props) {
  const renderSelected = () => {
    if (props?.selectedSlot !== undefined) {
      const selected =
        props.selectedSlot &&
        props.selectedSlot.map((selected, i) => (
          <BadgedChip
            key={i}
            selected={selected}
            id={i}
            handleRemoveClick={props.handleRemoveClick}
            setSelectedSlot={props.setSelectedSlot}
            createMeeting={props?.createMeeting}
          />
        ));
      return selected;
    }
  };

  const selectedComponents = renderSelected();

  return (
    <Box
      spacing="8px"
      display="flex"
      justifyContent={"flex-start"}
      ref={props.selectRef}
      sx={{
        // overflowX: { xs: "scroll", md: "hidden" },
        overflow: "visible",
        flexWrap: "wrap",
        // width: { xs: "100%", md: "98%" },
        width: { xs: "100%", md: "100%" },
        // marginLeft: "40px",
      }}
    >
      {selectedComponents}
    </Box>
  );
}
