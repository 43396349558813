import { useState, useEffect } from "react";

import { Stack, Typography, useTheme } from "@mui/material";

import SelectedTimeChip from "./SelectedTimeChip";
import { useTranslation } from "react-i18next";

export default function PollingTimes({
  eventCase,
  selectedSlot,
  setSelectedSlot,
  errorAmount,
  setErrorAmount,
  meetingCreated,
  selectedTimes,
  meetingType,
  setSelectedTimes,
  isNonSkedin,
}) {
  const [error, setError] = useState("");
  const theme = useTheme();
  const { t } = useTranslation();

  useEffect(() => {
    if (meetingCreated) setSelectedSlot("");
  }, [meetingCreated]);

  const handleRemoveClick = async (index) => {
    setError("");
    setErrorAmount("");
    const tempAllSlots = selectedTimes;
    const tempEvents = tempAllSlots.events;

    tempEvents.splice(index, 1);
    setSelectedTimes({
      events: [...tempEvents],
      backgroundColor: "rgba(114, 176, 238, 0.5)",
      display: "block",
    });
  };

  return (
    <Stack
      spacing="10px"
      style={{ marginTop: 15, height: isNonSkedin ? "auto" : "66vh" }}
    >
      {error && !errorAmount && (
        <Typography style={{ color: "red" }} variant="body2" align="left">
          {error}
        </Typography>
      )}
      {errorAmount && (
        <Typography
          style={{ color: "red", marginTop: 2 }}
          variant="body2"
          align="left"
        >
          {errorAmount}
        </Typography>
      )}
      {selectedSlot?.length > 0 ? (
        <>
          <Typography
            variant="h2"
            align="left"
            sx={{
              fontWeight: 500,
              marginTop: "2px",
            }}
          >
            {t("selected_timeslots")}
          </Typography>

          {meetingType && (
            <>
              {selectedSlot?.length > 1 && (
                <Typography
                  variant="body2"
                  align="left"
                  style={{
                    color: "red",
                    marginBottom: 4,
                    fontSize: 9,
                  }}
                >
                  {t("recur_msg")}
                </Typography>
              )}
              <Typography
                variant="h4"
                align="left"
                sx={{
                  fontWeight: 400,
                  color: theme.palette.info.light,
                  marginTop: "3px !important",
                }}
              >
                {t("select_single_slot")}
              </Typography>
            </>
          )}

          <SelectedTimeChip
            selectedSlot={selectedSlot}
            setSelectedSlot={setSelectedSlot}
            handleRemoveClick={handleRemoveClick}
            eventCase={eventCase}
          />
        </>
      ) : null}
    </Stack>
  );
}
