import {
  Dialog,
  DialogContent,
  DialogActions,
  Box,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import DialogWithCloseIcon from "../DialogWithCloseIcon";

/**
 * Dialog box for adding an attachement to a meeting
 */
export default function DocPopup({ open, handleClose, handleFile, error }) {
  const { t } = useTranslation();
  return (
    <DialogWithCloseIcon handleClose={handleClose} open={open}>
      <DialogContent sx={{ padding: "0px" }}>
        <Typography
          variant="h2"
          align="center"
          sx={{
            color: "#363B53",
          }}
        >
          {t("add_attachment")}
        </Typography>
        {error && (
          <Typography
            variant="h5"
            align="center"
            sx={{
              color: "red",
              mb: 2,
            }}
          >
            {error}
          </Typography>
        )}

        <Typography
          variant="h5"
          align="center"
          sx={{
            color: "#70757A",
            mb: 2,
          }}
        >
          {t("attach_msg")}
        </Typography>
        <Box display="flex" justifyContent="center">
          <Button
            variant="contained"
            component="label"
            display="flex"
            width="100%"
          >
            {t("browse")}
            <input type="file" multiple hidden onChange={handleFile} />
          </Button>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button variant="outlined" onClick={handleClose}>
          {t("cancel")}
        </Button>
      </DialogActions>
    </DialogWithCloseIcon>
  );
}
