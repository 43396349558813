import { Box, Button, Container, Typography, useTheme } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { serverGET } from "../../Utils/HttpFunctions";

export default function History() {
  const [history, setHistory] = useState<any>([]);
  const theme = useTheme();

  useEffect(() => {
    fetchTemplates();
  }, []);

  const fetchTemplates = async () => {
    const response = await serverGET(`/email/`, undefined, undefined);
    if (response && response.constructor !== String) {
      setHistory(response.data.emails);
    }
  };

  return (
    <Box
      display={"flex"}
      flexDirection="column"
      gap={2}
      style={{ width: "100%" }}
    >
      {history.length === 0 ? (
        <Typography variant="h3">No report found</Typography>
      ) : (
        history.map((h) => {
          if (h.template != null) {
            return (
              <Container
                maxWidth="xs"
                style={{
                  backgroundColor: theme.palette.info.contrastText,
                  borderRadius: 8,
                  height: "140px",
                  display: "flex",
                  gap: "8px",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Box
                  display={"flex"}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography
                    variant="h3"
                    fontWeight="500"
                    style={{ textTransform: "capitalize" }}
                    align="left"
                  >
                    {h?.template?.subject}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <Typography
                    variant="h4"
                    align="left"
                    style={{ fontWeight: "400" }}
                  >
                    <span style={{ fontWeight: 500 }}>Delivered on:</span>{" "}
                    {moment(h?.trigger?.startTime).format(
                      "MMMM ddd Do,  YYYY h:mm a"
                    )}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <Typography
                    variant="h4"
                    align="left"
                    style={{ fontWeight: "400" }}
                  >
                    <span style={{ fontWeight: 500 }}>Sender:</span>{" "}
                    {h?.template?.email}
                  </Typography>
                </Box>
              </Container>
            );
          }
        })
      )}
    </Box>
  );
}
