import React, { useState, SetStateAction } from "react";

import { Grid, Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import GradingIcon from "@mui/icons-material/Grading";

import Duration from "../../components/meetings/Duration";
import Invitee from "./Invitee";
import Initiator from "./Initiator";
import Accepted from "./Accepted";
import Location from "./Location";
import MeetingInvitesButtons from "./meeting buttons/MeetingInvitesButtons";
import MyMeetingsButtons from "./meeting buttons/MyMeetingsButtons";
import WebsiteBookingsButton from "./meeting buttons/WebsiteBookingsButton";
import {
  MEETING_INVITES_COLOR,
  PENDING_MEETINGS_COLOR,
  WEBSITE_BOOKINGS_COLOR,
} from "../../Utils/Constants";
import { Event } from "../../interfaces";
import MeetingInvitesParticipants from "../../components/meetings/MeetingInvitesParticipants";
import PendingMeetingParticipants from "../../components/meetings/PendingMeetingParticipants";
import Attachement from "../Global/Attachement";

type MeeetingComponentProps = {
  event: Event;
  tab: number;
  calendarRef: any;

  handleDownload: (fileName: string, eventId: string) => void;
  setMeetingDeleted: React.Dispatch<SetStateAction<boolean>>;
  setMeetingResponded: React.Dispatch<SetStateAction<boolean>>;
  setMeetingDetailPopup: React.Dispatch<SetStateAction<object>>;
  setCancelSuccess: React.Dispatch<SetStateAction<boolean>>;
  setAcceptDialog: React.Dispatch<SetStateAction<boolean>>;
  setSecondaryOpen: React.Dispatch<SetStateAction<boolean>>;
  t?: any;
};

/**
 * Renders the card for each meeting type on the pending meetings page
 */
export default function MeetingComponent({
  event,
  tab,
  calendarRef,
  setMeetingDeleted,
  setMeetingDetailPopup,
  setCancelSuccess,
  setAcceptDialog,
  setSecondaryOpen,
  t,
}: MeeetingComponentProps) {
  const [meetingDetailMobile, setMeetingDetailMobile] = useState(false);
  const theme = useTheme();
  const tablet = useMediaQuery(theme.breakpoints.down("lg"));

  /**
   * Moves the calendar to the date on the card when the card is clicked
   */
  const handleCardClick = async () => {
    const t = await calendarRef?.current
      ?.getApi()
      ?.gotoDate(event?.start?.getTime());
    if (t) {
      calendarRef?.current?.getApi()?.scrollToTime(event?.start?.getTime());
    }
  };

  return (
    <Grid
      container
      key={event.id}
      sx={{
        background: "rgba(150, 220, 255, 0.15)",
        borderLeft:
          tab === 0
            ? `4px solid ${PENDING_MEETINGS_COLOR}`
            : tab === 1
            ? `4px solid ${MEETING_INVITES_COLOR}`
            : tab === 2
            ? `4px solid ${WEBSITE_BOOKINGS_COLOR}`
            : "none",
        marginTop: tablet ? "10px" : "14px",
        marginBottom: "14px",
        borderRadius: "8px",
        cursor: "pointer",
        padding: "20px",
      }}
      onClick={
        !tablet ? (event?.start ? handleCardClick : undefined) : undefined
      }
    >
      <Grid item container>
        <Grid
          item
          container
          xs={12}
          justifyContent="flex-start"
          alignItems="center"
        >
          <Box display="flex" flexGrow={1} alignItems="center" key={event.id}>
            <Box
              style={{
                width: 12,
                height: 12,
                marginRight: 8,
                borderRadius: "2px",
                backgroundColor:
                  tab === 0
                    ? PENDING_MEETINGS_COLOR
                    : tab === 1
                    ? MEETING_INVITES_COLOR
                    : tab === 2
                    ? WEBSITE_BOOKINGS_COLOR
                    : "none",
              }}
            />
            <Typography
              align="left"
              variant="h3"
              fontWeight="500"
              style={{ textTransform: "capitalize" }}
            >
              {event.title}
            </Typography>
          </Box>
          <Box
            onClick={
              tablet
                ? () => setMeetingDetailMobile((prev) => !prev)
                : () => setMeetingDetailPopup({ type: "open", meeting: event })
            }
          >
            {" "}
            <Typography
              variant="h4"
              fontWeight="500"
              style={{
                color: theme.palette.primary.main,
                cursor: "pointer",
              }}
            >
              {tablet
                ? meetingDetailMobile
                  ? t("close_details")
                  : t("view_details")
                : t("view_details")}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        {tab === 1 ? <Initiator event={event} /> : null}
        <Duration event={event} />
        <Location event={event} />

        {/* mobile screen detail */}
        {tablet && meetingDetailMobile && (
          <>
            <Grid container alignItems="center" pb="10px">
              {event?.description?.toString().toLowerCase() ===
              "no description" ? null : (
                <>
                  <Grid item xs={1} md={0.7} container>
                    <GradingIcon
                      style={{
                        width: "14px",
                        color: theme.palette.text.primary,
                      }}
                    />
                  </Grid>
                  <Grid item xs={11}>
                    <Typography variant="h3" align="left" fontWeight="400">
                      {event?.description}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
            {event.fileName?.length > 0 && (
              <Box pb={"10px"} display={"flex"} alignItems={"center"} gap={1}>
                <AttachFileIcon sx={{ color: "#323232", fontSize: "14px" }} />
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 8,
                  }}
                >
                  {event.fileName?.map((filename) => {
                    return (
                      <Attachement filename={filename} meetingId={event.id} />
                    );
                  })}
                </Box>
              </Box>
            )}
            {/* <Location event={event} /> */}
            {tab === 2 ? <Invitee event={event} /> : null}
          </>
        )}

        {tab !== 2 ? <Accepted event={event} /> : null}

        {tab !== 2 && (
          <Grid container item style={{ alignItems: "center" }}>
            {tab === 0 ? (
              <PendingMeetingParticipants event={event} />
            ) : (
              <MeetingInvitesParticipants event={event} />
            )}
          </Grid>
        )}

        {tab === 0 ? (
          <MyMeetingsButtons
            event={event}
            meetingId={event.id}
            setMeetingDeleted={setMeetingDeleted}
            setCancelSuccess={setCancelSuccess}
            handleClose={undefined}
            isCal={undefined}
            setIsOpen={undefined}
            setPlannedEvents={undefined}
            plannedEvents={undefined}
          />
        ) : tab === 1 ? (
          <MeetingInvitesButtons
            event={event}
            setAcceptDialog={setAcceptDialog}
            setSecondaryOpen={setSecondaryOpen}
            onClose={undefined}
          />
        ) : tab === 2 ? ( // BR-1
          <WebsiteBookingsButton
            // mobile={tablet}
            event={event}
            onClose={undefined}
            // setMeetingResponded={setMeetingResponded}
          />
        ) : null}
      </Grid>
    </Grid>
  );
}
