import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
  useContext,
} from "react";
import { useSearchParams } from "react-router-dom";
import {
  TextField,
  Typography,
  Button,
  Autocomplete,
  Chip,
  Grid,
  Box,
  useTheme,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
} from "@mui/material";
import moment from "moment";
import Style from "style-it";
import "styles/newEvent.css";
import { serverGETPOST, serverGET } from "Utils/HttpFunctions";
import {
  convertDateToUTCMilliseconds,
  convertUTCMillisecondsToDate,
} from "Utils/CommonFunctions";
import {
  ALL_SKEDING_USERS,
  MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS,
  ONE_NONSKEDING_MULTIPLE_SKEDING_USERS,
  ONE_NONSKEDING_ONE_SKEDING_USER,
  ONE_SKEDING_MULTIPLE_NONSKEDING_USERS,
} from "Utils/Constants";
import { integrationContext } from "context/Integrations";
import loggingInDev from "loggingInDev";
import { useLocation } from "react-router-dom";
import DialogWithSuccessMessage from "components/DialogWithSuccessMessage";
import DesktopFlow from "components/NewEvent/DesktopFlow";
import useStyle from "styles/formStyles";
import QRCodeCard from "./QrClient/QRCodeCard";
import { useTranslation } from "react-i18next";

export const renderChips = (value, getTagProps, participantLabels) => {
  if (value === null) return;

  var chips = value?.map((contactName, index) => {
    return (
      <Chip
        key={index}
        variant="filled"
        label={contactName}
        {...getTagProps({ index })}
        style={{
          background:
            participantLabels !== undefined
              ? participantLabels[index]?.status
                ? "#B3B5B680"
                : "#B0E4FF"
              : "#B0E4FF",
          borderRadius: "4px",
          fontSize: "14px",
          color: "#3C4242",
          margin: "4px",
        }}
      />
    );
  });
  return chips;
};

export default function NewEvent(props) {
  const locationProps = useLocation();
  const { user } = useContext(integrationContext);

  const [timeInterval, setTimeInterval] = useState(
    locationProps?.state?.duration ? locationProps?.state?.duration : "01:00:00"
  );

  const calendarRef = useRef(null);
  const [participants, setParticipants] = useState([]);
  const [searchParams] = useSearchParams();
  const [qrMeetingParticipant, setQrMeetingParticipant] = useState("");
  const [events, setEvents] = useState([]);
  const [error, setError] = useState("");
  const [eventCase, setEventCase] = useState("");
  const [selectedSlot, setSelectedSlot] = useState([]);
  const [meetingCreated, setMeetingCreated] = useState(false);
  const [unavailability, setUnavailability] = useState([]);
  const [months, setMonths] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);
  const [maxDays, setMaxDays] = useState([]);
  const [reset, setReset] = useState(true);

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const [recommendedDate, setRecommendedDate] = useState("");
  const [date, setDate] = useState("1");
  const [month, setMonth] = useState("January");
  const [year, setYear] = useState("2022");
  const [monthInt, setMonthInt] = useState(3);
  const [selectedDate, setSelectedDate] = useState("");
  const [eventsLoaded, setEventsLoaded] = useState(false);
  const [calView, setCalView] = useState(false);
  const [suggesstionsLoading, setSuggesstionsLoading] = useState(false);
  const [eventCal, setEventCal] = useState([]);
  const [meetingTitle, setMeetingTitle] = useState("Create a New Meeting");
  const [room, setRoom] = useState("");
  const [startTime, setStartTime] = useState("");
  const [pU, setPU] = useState([]);
  const [customTime, setCustomTime] = useState("");
  const [phone, setPhone] = useState("");
  const [link, setLink] = useState(() =>
    user && user.virtual_link ? user.virtual_link : ""
  );
  const [scheduleMeeting, setScheduleMeeting] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (
      eventCase == MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS ||
      eventCase == ONE_SKEDING_MULTIPLE_NONSKEDING_USERS
    ) {
      setCalView(true);
    }
  }, [eventCase]);

  useEffect(() => {
    if (calendarRef?.current) {
      setDate(calendarRef?.current?.getApi()?.getDate()?.getDate());

      setYear(
        calendarRef.current
          .getApi()
          .getDate()
          .toLocaleString("default", { year: "numeric" })
      );

      setMonth(
        calendarRef.current
          .getApi()
          .getDate()
          .toLocaleString("default", { month: "long" })
      );

      setMonthInt(calendarRef.current.getApi().getDate().getMonth());
    }
  }, [calendarRef]);

  useEffect(() => {
    const monthString = handleMonth();
    const dateString = handleDate();
    if (calendarRef?.current) {
      calendarRef.current
        .getApi()
        .gotoDate(`${year.toString()}-${monthString}-${dateString}`);
      setSelectedDate(
        calendarRef.current
          .getApi()
          .getDate()
          .toLocaleString("default", { weekday: "long" })
      );
      setRecommendedDate(
        calendarRef.current.getApi().getDate().toLocaleDateString()
      );
    }
  }, [calendarRef, date, monthInt, year]);

  useEffect(() => {
    const abortController = new AbortController();
    getUserPreferences(abortController);
    return () => {
      abortController.abort();
    };
  }, [meetingCreated]);

  useEffect(() => {
    checkSlots();
  }, [participants, timeInterval, customTime]);

  useEffect(() => {
    if (startTime) {
      checkStartTimeSlots();
    }
  }, [startTime]);

  useLayoutEffect(() => {
    const participant = searchParams.get("participant");
    if (participant) {
      setQrMeetingParticipant(participant);
      setParticipants([participant?.toLowerCase()]);
    }
  }, []);

  const handleMonth = () => {
    const monthIncremented = monthInt + 1;
    switch (monthIncremented < 10) {
      case true:
        return "0" + monthIncremented.toString();
      default:
        return monthIncremented.toString();
    }
  };

  const handleDate = () => {
    switch (date < 10) {
      case true:
        return "0" + date.toString();
      default:
        return date.toString();
    }
  };

  //when old logic (syncing recommened slots with calendar )
  // const onCalendarMonthChange = async (dateInfo) => {
  //   if (calendarRef && timeInterval && participants.length > 0) {
  //     //calendar week start dates
  //     const startDate = moment(dateInfo?.start).startOf("day");
  //     const endDate = moment(dateInfo?.end).startOf("day");

  //     // const eventStartDate =
  //     //   events.length > 0 && moment(events[0]?.start).startOf("day");
  //     // const eventEndDate =
  //     //   events.length > 0 &&
  //     //   moment(events[events.length - 1]?.start).startOf("day");

  //     const recDate = moment(
  //       `${dateObject.date}/${dateObject.month}/${dateObject.year}`
  //     ).startOf("day");

  //     const currentDate = new Date();
  //     console.log(recDate, startDate, endDate);
  //     // if (recDate.isBetween(startDate, endDate, "days", "[]")) {
  //     loggingInDev("is between");
  //     // } else {
  //     calendarRef?.current?.getApi()?.removeAllEvents();
  //     loggingInDev("not betweenn");
  //     if (endDate < currentDate) {
  //       //do nothing
  //     } else {
  //       if (currentDate > startDate) {
  //         appendSlots(currentDate);
  //       } else {
  //         appendSlots(startDate);
  //       }
  //     }

  //     // }
  //   }
  // };

  const checkSlots = async () => {
    // when non skeding becomes skeding
    if (props?.isNonSkeding) {
      if (props?.meetingData.proposed_timeslots.length < 1) {
        setSuggesstionsLoading(true);
        const data = {
          current_date: convertDateToUTCMilliseconds(new Date()),
          duration_minutes: props?.meetingData.duration_minutes,
          participants: props?.meetingData.non_skeding_participants,
        };
        const response = await serverGETPOST(
          `/meeting/suggestions?offset=${new Date().getTimezoneOffset()}`,
          data
        );
        if (response && response.constructor !== String) {
          const parseResponse = await response.data.allAvailbleSlots.map(
            (item) => {
              return {
                start: convertUTCMillisecondsToDate(item.startTime),
                end: convertUTCMillisecondsToDate(item.endTime),
              };
            }
          );

          setEvents(parseResponse);
          setEventCase(ALL_SKEDING_USERS);
          setSelectedSlot([]);
          setEventsLoaded(true);
          setSuggesstionsLoading(false);
          // loggingInDev("Slots ==>", parseResponse);
        } else {
          setError(response.data.message);
          setSuggesstionsLoading(false);
        }
      }
    }
    // for other cases
    else {
      if (
        participants.length === 0 ||
        timeInterval === "" ||
        (timeInterval === "custom" && customTime === "")
      ) {
        setEvents([]);
        setEventCase("");
        setEventCal([]);
        setMeetingTitle("Create A New Meeting");
        setSelectedSlot([]);
        setPU([]);
        return;
      }

      const current_date = new Date();
      if (startTime) {
        let start = startTime;
        if (start == "00") {
          start = "59";
        }

        current_date.setMinutes(parseInt(start));
        if (current_date < new Date()) {
          let hours = current_date.getHours();
          current_date.setHours(hours + 1);
        }
      }

      if (timeInterval === "custom" && customTime === "") {
        return;
      }
      if (participants.length > 0 && timeInterval !== "") {
        setSuggesstionsLoading(true);

        const duration_minutes =
          timeInterval === "custom"
            ? moment.duration(customTime).asMinutes()
            : moment.duration(timeInterval).asMinutes();

        const data = {
          current_date: convertDateToUTCMilliseconds(current_date),
          duration_minutes,
          participants: participants,
        };

        const response = await serverGETPOST(
          `/meeting/suggestions?offset=${new Date().getTimezoneOffset()}`,
          data
        );
        if (response && response.constructor !== String) {
          let parseResponse;
          if (
            response.data.case === MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS ||
            response.data.case === ONE_SKEDING_MULTIPLE_NONSKEDING_USERS
          ) {
            parseResponse = await response.data.slotsData.map((item) => {
              return {
                start: convertUTCMillisecondsToDate(item.slotStart),
                end: convertUTCMillisecondsToDate(item.slotEnd),
                availableParticipants: item.availableParticipantsData,
                unAvailableParticipants: item.unavailableParticipantsData,
              };
            });
          } else {
            parseResponse = await response.data.allAvailbleSlots.map((item) => {
              return {
                start: convertUTCMillisecondsToDate(item.startTime),
                end: convertUTCMillisecondsToDate(item.endTime),
              };
            });
          }
          const index = months.indexOf(
            new Date(parseResponse[0]?.start).getMonth() + 1
          );
          if (index > -1) {
            months.splice(index, 1);
          }
          setPU(response.data.Participant_unavailibility);
          setEvents(parseResponse);
          setEventCase(response.data.case);
          setSelectedSlot([]);
          setEventCal([]);
          setReset((prev) => !prev);
          setEventsLoaded(true);
          setSuggesstionsLoading(false);

          if (
            (response.data.case === ALL_SKEDING_USERS &&
              participants.length === 1) ||
            response.data.case === ONE_NONSKEDING_ONE_SKEDING_USER
          ) {
            setMeetingTitle("One on One Meeting");
          } else if (
            (response.data.case === ALL_SKEDING_USERS &&
              participants.length > 1) ||
            response.data.case === ONE_NONSKEDING_MULTIPLE_SKEDING_USERS
          ) {
            setMeetingTitle("Multiple User Meeting");
          } else if (
            response.data.case === MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS ||
            response.data.case === ONE_SKEDING_MULTIPLE_NONSKEDING_USERS
          ) {
            setMeetingTitle("Polling Meeting");
          }

          // loggingInDev("Slots ==>", parseResponse);
        } else {
          setSuggesstionsLoading(false);
          setError(response.data.message);
        }
      } else {
        setEvents([]);
      }
    }
  };

  const checkStartTimeSlots = async () => {
    const current_date = new Date();
    if (startTime) {
      let start = startTime;
      if (start == "00") {
        start = "59";
      }

      current_date.setMinutes(parseInt(start));
      if (current_date < new Date()) {
        let hours = current_date.getHours();
        current_date.setHours(hours + 1);
      }
    }

    if (timeInterval === "custom" && customTime === "") {
      return;
    }
    const duration_minutes =
      timeInterval === "custom"
        ? moment.duration(customTime).asMinutes()
        : moment.duration(timeInterval).asMinutes();

    if (participants.length > 0 && timeInterval !== "") {
      setSuggesstionsLoading(true);

      const data = {
        current_date: convertDateToUTCMilliseconds(current_date),
        duration_minutes,
        participants: participants,
      };

      const response = await serverGETPOST(
        `/meeting/suggestions?offset=${new Date().getTimezoneOffset()}`,
        data
      );
      if (response && response.constructor !== String) {
        let parseResponse;
        if (
          response.data.case === MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS ||
          response.data.case === ONE_SKEDING_MULTIPLE_NONSKEDING_USERS
        ) {
          parseResponse = await response.data.slotsData.map((item) => {
            return {
              start: convertUTCMillisecondsToDate(item.slotStart),
              end: convertUTCMillisecondsToDate(item.slotEnd),
              availableParticipants: item.availableParticipantsData,
              unAvailableParticipants: item.unavailableParticipantsData,
            };
          });
        } else {
          parseResponse = await response.data.allAvailbleSlots.map((item) => {
            return {
              start: convertUTCMillisecondsToDate(item.startTime),
              end: convertUTCMillisecondsToDate(item.endTime),
            };
          });
        }
        const index = months.indexOf(
          new Date(parseResponse[0]?.start).getMonth() + 1
        );
        if (index > -1) {
          months.splice(index, 1);
        }
        setEvents(parseResponse);
        setEventCase(response.data.case);
        setEventsLoaded(true);
        setSuggesstionsLoading(false);
      } else {
        setSuggesstionsLoading(false);
        setError(response.data.message);
      }
    } else {
      setEvents([]);
    }
  };

  const getUserPreferences = async (abortController) => {
    try {
      const response = await serverGET("/preference", abortController.signal);
      if (response && response.constructor !== String) {
        const parsedUnavailability = response.data.blocked_timeslots;
        setUnavailability(parsedUnavailability);
      } else {
        return response.data.message;
      }
    } catch (e) {
      loggingInDev(e);
    }
  };

  const handleScheduleMeeting = () => {
    setScheduleMeeting(true);
  };

  return (
    <Style>
      {`
          .fc-timegrid-event-harness-inset {
            left: -2px !important;
            right: -2px !important;
          }
          .fc .fc-timegrid-slot{
            background-color: ${
              eventCase === MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS ||
              eventCase === ONE_SKEDING_MULTIPLE_NONSKEDING_USERS
                ? "rgba(218, 210, 229)"
                : "#c4e7ffb0"
            };
            border-bottom: 1px solid rgba(200, 200, 201, 0.60);
          }
         
          .fc .fc-timegrid-slot-label{
            background-color:inherit;
          }
          .fc-timegrid-event-harness-inset .fc-timegrid-event, .fc-timegrid-event.fc-event-mirror, .fc-timegrid-more-link{
            box-shadow: 0px 0px 1px 0px var(--fc-page-bg-color, #fff)
          }
          .fc-today-button {
            background-color: #479ae9 !important;
            color:#fff;  
          }
      `}
      <div>
        <>
          {!scheduleMeeting &&
          ["/qrmeeting"].includes(window.location.pathname) ? (
            <QRCodeCard handleScheduleMeeting={handleScheduleMeeting} />
          ) : (
            <DesktopFlow isMobile={true} />
          )}
          {/* <SnackbarComponent
          open={modal}
          handleClose={handleClose}
          message={"Meeting invite has been sent successfully"}
          vertical={"bottom"}
          horizontal={"center"}
        />
        <Backdrop
          sx={{
            color: theme.palette.primary.main,
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={suggesstionsLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <ErrorSnackbar
          open={!!error}
          handleClose={() => setError("")}
          message={error}
          vertical={"bottom"}
          horizontal={"center"}
        />
        {timeInterval === "" ||
        (timeInterval === "custom" && customTime === "") ||
        participants.length < 1
          ? null
          : mobile &&
            mobileShowTimes && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                style={{ width: "100%" }}
              >
                <Box
                  display={"flex"}
                  alignItems="center"
                  justifyContent={"flex-start"}
                >
                  <Typography variant="h2" align="left">
                    {meetingTitle}
                  </Typography>
                  <Box ml={1}>
                    <HelpComponent
                      msg={NEW_MEETINGS_HEADING}
                      position={"bottom"}
                    />
                  </Box>
                </Box>

                <Button
                  variant="text"
                  style={{ fontSize: 12 }}
                  startIcon={calView ? <ListIcon /> : <CalendarMonthIcon />}
                  sx={{
                    display:
                      eventCase == MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS ||
                      eventCase == ONE_SKEDING_MULTIPLE_NONSKEDING_USERS
                        ? "none"
                        : "flex",
                  }}
                  onClick={() => setCalView((prev) => !prev)}
                >
                  {calView ? "List view" : "Calendar view"}
                </Button>
              </Box>
            )}
        {props?.isNonSkeding ? null : (
          <>
            <Grid container className={classes.root}>
              <Grid
                item
                xs={12}
                md={4}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                }}
              >
                <ResponsiveWrapper
                  meetingDetailExpand={meetingDetailExpand}
                  setMeetingDetailExpand={setMeetingDetailExpand}
                  mobileShowTimes={mobileShowTimes}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={11}
                      sx={{ display: { xs: "none", md: "block" } }}
                    >
                      <Box
                        display={"flex"}
                        alignItems="center"
                        justifyContent={"flex-start"}
                      >
                        <Typography variant="h2" align="left">
                          {meetingTitle}
                        </Typography>
                        <Box ml={1}>
                          <HelpComponent
                            msg={NEW_MEETINGS_HEADING}
                            position={"bottom"}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={11}>
                      <Title setTitle={setTitle} title={title} />
                    </Grid>
                    {qrMeetingParticipant.length === 0 ? (
                      <Grid item xs={11} className={classes.participant}>
                        <Participants
                          setParticipants={setParticipants}
                          participants={participants}
                          contacts={contacts}
                          groups={groups}
                          setGroups={setGroups}
                          minimizedContacts={minimizedContacts}
                          user={user}
                        />
                      </Grid>
                    ) : null}
                    <Grid container item xs={11}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        width="100%"
                      >
                        <TimeInterval
                          timeInterval={timeInterval}
                          handleIntervalChange={handleIntervalChange}
                        />

                        {![
                          MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS,
                          ONE_SKEDING_MULTIPLE_NONSKEDING_USERS,
                        ].includes(eventCase) ? (
                          <Box
                            display={"flex"}
                            alignItems="center"
                            justifyContent={"flex-start"}
                          >
                            <StartTime
                              startTime={startTime}
                              handleStartTime={handleStartTime}
                              timeInterval={timeInterval}
                              customTime={customTime}
                            />
                            <Box ml={1} mt={"10px"}>
                              <HelpComponent
                                msg={NEW_MEETINGS_START_TIME}
                                position={"bottom"}
                              />
                            </Box>
                          </Box>
                        ) : null}
                      </Box>
                    </Grid>
                    {timeInterval === "custom" ? (
                      <Grid container item xs={11}>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          width="100%"
                        >
                          <CustomInterval setCustomTime={setCustomTime} />
                        </Box>
                      </Grid>
                    ) : null}

                    <Grid container item xs={11}>
                      <Location
                        setLocation={setLocation}
                        location={location}
                        user={user}
                      />
                    </Grid>
                    {location === "Other" && (
                      <>
                        <PhysicalLocation
                          setAddress={setAddress}
                          address={address}
                        />
                        <Grid item xs={11}>
                          <RoomNo room={room} setRoom={setRoom} />
                        </Grid>
                      </>
                    )}
                    {location === "Phone" && (
                      <>
                        <Phone setPhone={setPhone} phone={phone} />
                      </>
                    )}
                    {location === "Link" && (
                      <>
                        <Grid item xs={11}>
                          <VirtualLink setLink={setLink} link={link} />
                        </Grid>
                      </>
                    )}

                    <Grid item xs={11}>
                      <Description
                        setDescription={setDescription}
                        description={description}
                        showFile={true}
                        file={file}
                        setFile={setFile}
                        fileName={fileName}
                        setFileName={setFileName}
                      />
                    </Grid>
                    {timeInterval === "" ||
                    (timeInterval === "custom" && customTime === "") ||
                    participants.length < 1 ? null : (
                      <Grid container>
                        <Grid item xs={11}>
                          <Box
                            sx={{
                              marginTop: 1,
                              flexGrow: 1,
                              // display: { xs: "none", md: "block" },
                            }}
                            display={"flex"}
                            alignItems="center"
                            justifyContent={"flex-start"}
                          >
                            <RecurringMeetingCheck
                              meetingType={meetingType}
                              setMeetingType={setMeetingType}
                            />

                            <Box ml={-1} mt={"16px"}>
                              <HelpComponent
                                msg={NEW_MEETINGS_RECURRING}
                                position={"bottom"}
                              />
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    )}
                    {mobile ? (
                      <>
                        <Box
                          mt={2}
                          display="flex"
                          justifyContent={"center"}
                          style={{ width: "100%" }}
                          sx={{ display: mobileShowTimes ? "none" : "block" }}
                        >
                          <Button
                            variant="contained"
                            style={{ minWidth: 100 }}
                            disabled={
                              participants.length < 1 ||
                              timeInterval === "" ||
                              (timeInterval === "custom" && customTime === "")
                            }
                            onClick={() => {
                              setMobileShowTimes(true);
                              setMeetingDetailExpand(false);
                            }}
                          >
                            Next
                          </Button>
                        </Box>
                      </>
                    ) : (
                      <Grid
                        item
                        xs={12}
                        style={{
                          position: "relative",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                        }}
                      >
                        {timeInterval === "" ||
                        (timeInterval === "custom" && customTime === "") ||
                        participants.length < 1 ? null : eventCase ===
                            MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS ||
                          eventCase ===
                            ONE_SKEDING_MULTIPLE_NONSKEDING_USERS ? (
                          <PollingTimes
                            eventCal={eventCal}
                            setEventCal={setEventCal}
                            eventCase={eventCase}
                            selectedSlot={selectedSlot}
                            setSelectedSlot={setSelectedSlot}
                            errorAmount={errorAmount}
                            setErrorAmount={setErrorAmount}
                            meetingCreated={meetingCreated}
                            selectedTimes={selectedTimes}
                            setSelectedTimes={setSelectedTimes}
                            meetingType={meetingType}
                          />
                        ) : (
                          <ShowTimes
                            eventCal={eventCal}
                            setEventCal={setEventCal}
                            formDate={formDate}
                            setFormDate={setFormDate}
                            events={events}
                            eventCase={eventCase}
                            sharedAvailableTimes={sharedAvailableTimes}
                            setSharedAvailableTimes={setSharedAvailableTimes}
                            selectedSlot={selectedSlot}
                            setSelectedSlot={setSelectedSlot}
                            errorAmount={errorAmount}
                            setErrorAmount={setErrorAmount}
                            meetingCreated={meetingCreated}
                            calendarRef={calendarRef}
                            date={date}
                            setDate={setDate}
                            month={month}
                            setMonth={setMonth}
                            year={year}
                            setYear={setYear}
                            monthInt={monthInt}
                            setMonthInt={setMonthInt}
                            dateObject={dateObject}
                            selectedDate={selectedDate}
                            error={errorMsg}
                            // qrMeeting={qrMeeting}
                            appendSlots={appendSlots}
                            months={months}
                            meetingType={meetingType}
                          />
                        )}
                      </Grid>
                    )}
                  </Grid>
                </ResponsiveWrapper>
                {mobile && mobileShowTimes && !calView && (
                  <>
                    {!meetingDetailExpand &&
                      ![
                        MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS,
                        ONE_SKEDING_MULTIPLE_NONSKEDING_USERS,
                      ].includes(eventCase) && (
                        <Box
                          display={"flex"}
                          justifyContent="flex-start"
                          mt={"-10px"}
                        >
                          <StartTime
                            startTime={startTime}
                            handleStartTime={handleStartTime}
                            timeInterval={timeInterval}
                            customTime={customTime}
                          />
                        </Box>
                      )}

                    <Fade in={mobileShowTimes && !calView} timeout={1000}>
                      <Grid
                        item
                        xs={12}
                        style={{
                          position: "relative",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          display:
                            eventCase ===
                              MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS ||
                            eventCase === ONE_SKEDING_MULTIPLE_NONSKEDING_USERS
                              ? "none"
                              : "block",
                        }}
                      >
                        {timeInterval === "" ||
                        (timeInterval === "custom" && customTime === "") ||
                        participants.length < 1 ? null : (
                          <ShowTimes
                            eventCal={eventCal}
                            setEventCal={setEventCal}
                            events={events}
                            eventCase={eventCase}
                            formDate={formDate}
                            setFormDate={setFormDate}
                            sharedAvailableTimes={sharedAvailableTimes}
                            setSharedAvailableTimes={setSharedAvailableTimes}
                            selectedSlot={selectedSlot}
                            setSelectedSlot={setSelectedSlot}
                            errorAmount={errorAmount}
                            setErrorAmount={setErrorAmount}
                            meetingCreated={meetingCreated}
                            calendarRef={calendarRef}
                            date={date}
                            setDate={setDate}
                            month={month}
                            setMonth={setMonth}
                            year={year}
                            setYear={setYear}
                            monthInt={monthInt}
                            setMonthInt={setMonthInt}
                            dateObject={dateObject}
                            selectedDate={selectedDate}
                            error={errorMsg}
                            // qrMeeting={qrMeeting}
                            appendSlots={appendSlots}
                            months={months}
                            meetingType={meetingType}
                          />
                        )}
                      </Grid>
                    </Fade>
                  </>
                )}

                {!mobile &&
                  (props?.meetingData?.case ===
                    ONE_NONSKEDING_ONE_SKEDING_USER &&
                  eventCase === ALL_SKEDING_USERS ? (
                    <Buttons
                      createMeeting={createNonToSkedingMeeting}
                      clearForm={clearForm}
                      cancel={cancel}
                      setCancel={setCancel}
                      disabled={disabled}
                      selectedSlot={selectedSlot}
                      mobile={mobile}
                      eventCase={eventCase}
                    />
                  ) : (
                    <Buttons
                      createMeeting={createMeeting}
                      clearForm={clearForm}
                      cancel={cancel}
                      setCancel={setCancel}
                      disabled={disabled}
                      selectedSlot={selectedSlot}
                      mobile={mobile}
                      eventCase={eventCase}
                    />
                  ))}
              </Grid>

              <Grid
                item
                xs={12}
                md={8}
                style={{
                  display: mobile ? (calView ? "block" : "none") : "block",
                  marginBottom: mobile ? 30 : 0,
                }}
              >
                {eventCase &&
                (eventCase === MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS ||
                  eventCase === ONE_SKEDING_MULTIPLE_NONSKEDING_USERS) &&
                participants.length > 0 &&
                timeInterval !== "" ? (
                  mobile ? (
                    mobileShowTimes ? (
                      <Polling
                        timeInterval={
                          timeInterval === "custom" && customTime
                            ? customTime
                            : timeInterval
                        }
                        events={events && events}
                        eventCase={eventCase}
                        selectedSlot={selectedSlot}
                        setSelectedSlot={setSelectedSlot}
                        participants={participants}
                        errorAmount={errorAmount}
                        setErrorAmount={setErrorAmount}
                        onCalendarMonthChange={onCalendarMonthChange}
                        contacts={contacts}
                        allNonSkedingResponded={false}
                        maxDays={maxDays}
                        reset={reset}
                        popover={true}
                        initiatorResponse={false}
                        selectedTimes={selectedTimes}
                        setSelectedTimes={setSelectedTimes}
                        meetingType={meetingType}
                        appendSlots={appendSlots}
                        newMeeting={true}
                        showMsg={true}
                      />
                    ) : null
                  ) : (
                    <>
                      <Box
                        style={{
                          marginLeft: mobile ? 0 : 20,
                          paddingTop: mobile && meetingDetailExpand ? 20 : 8,
                          marginBottom: 14,
                        }}
                      >
                        <Box display="flex" alignItems="center">
                          <Typography
                            variant="h2"
                            align="left"
                            fontWeight="500"
                          >
                            Pick a Date & Time
                          </Typography>
                          {mobile && (
                            <Box ml={1}>
                              <HelpComponent
                                msg={`Tap the desired timeslot for more than a second to
                        select it or tap the date at the top to select the
                        entire day`}
                                position={"bottom"}
                              />
                            </Box>
                          )}
                        </Box>
                        <Typography
                          variant="h6"
                          align="left"
                          style={{ color: "#70757A" }}
                        >
                          Select timeslots from 3-15 days
                        </Typography>
                      </Box>

                      <Box>
                        <FreeSlotCalendarTemp
                          timeInterval={
                            timeInterval === "custom" && customTime
                              ? customTime
                              : timeInterval
                          }
                          events={events && events}
                          eventCase={eventCase}
                          selectedSlot={selectedSlot}
                          setSelectedSlot={setSelectedSlot}
                          participants={participants}
                          errorAmount={errorAmount}
                          setErrorAmount={setErrorAmount}
                          onCalendarMonthChange={onCalendarMonthChange}
                          contacts={contacts}
                          allNonSkedingResponded={false}
                          maxDays={maxDays}
                          reset={reset}
                          popover={true}
                          initiatorResponse={false}
                          selectedTimes={selectedTimes}
                          setSelectedTimes={setSelectedTimes}
                          meetingType={meetingType}
                        />
                      </Box>
                    </>
                  )
                ) : (
                  <>
                    {(mobile && mobileShowTimes) || !mobile ? (
                      <>
                        <Box
                          style={{
                            marginLeft: mobile ? 0 : 20,
                            marginBottom: 14,
                          }}
                        >
                          <Box display="flex" alignItems="center">
                            <Typography
                              variant="h2"
                              align="left"
                              fontWeight="500"
                            >
                              Pick a Date & Time
                            </Typography>
                            {mobile && (
                              <Box ml={1}>
                                <HelpComponent
                                  msg={`Tap the desired timeslot for more than a second to
                        select it `}
                                  position={"bottom"}
                                />
                              </Box>
                            )}
                          </Box>
                          <Typography
                            variant="h6"
                            align="left"
                            style={{ color: "#70757A" }}
                          >
                            Recommended slots are highlighted in blue.
                          </Typography>
                        </Box>
                        <Box>
                          <TimeCalendarTemp
                            timeInterval={
                              timeInterval === "custom" && customTime
                                ? customTime
                                : timeInterval
                            }
                            events={events}
                            sharedAvailableTimes={sharedAvailableTimes}
                            setSharedAvailableTimes={setSharedAvailableTimes}
                            eventCase={eventCase}
                            selectedSlot={selectedSlot}
                            setSelectedSlot={setSelectedSlot}
                            participants={participants}
                            errorAmount={errorAmount}
                            setErrorAmount={setErrorAmount}
                            meetingCreated={meetingCreated}
                            setEventCal={setEventCal}
                            eventCal={eventCal}
                            onCalendarMonthChange={onCalendarMonthChange}
                            unavailability={unavailability}
                            createTemplate={createTemplate}
                            setCreateTemplate={setCreateTemplate}
                            calendarRef={calendarRef}
                            handleDateChange={handleDateChange}
                            error={errorMsg}
                            setError={setErrorMsg}
                            eventsLoaded={eventsLoaded}
                            meetingType={meetingType}
                            pU={pU}
                          />
                        </Box>
                      </>
                    ) : null}
                  </>
                )}
              </Grid>
              {mobile &&
                mobileShowTimes &&
                (props?.meetingData?.case === ONE_NONSKEDING_ONE_SKEDING_USER &&
                eventCase === ALL_SKEDING_USERS ? (
                  <Buttons
                    createMeeting={createNonToSkedingMeeting}
                    clearForm={clearForm}
                    cancel={cancel}
                    setCancel={setCancel}
                    disabled={disabled}
                    selectedSlot={selectedSlot}
                    mobile={mobile}
                  />
                ) : (
                  <Buttons
                    createMeeting={createMeeting}
                    clearForm={clearForm}
                    cancel={cancel}
                    setCancel={setCancel}
                    disabled={disabled}
                    selectedSlot={selectedSlot}
                    mobile={mobile}
                  />
                ))}
            </Grid>
          </>
        )}

        <NoCalendar modal={noCalendarModal} handleToggle={setNoCalendarModal} /> */}
        </>
      </div>
    </Style>
  );
}

export const Title = React.memo(({ setTitle, title }) => {
  const classes = useStyle();
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <TextField
      placeholder={t("add_title") + "*"}
      variant="standard"
      autoComplete="off"
      value={title}
      style={{ marginTop: 10 }}
      sx={{ width: "100%" }}
      autoFocus
      InputProps={{
        disableUnderline: true,
        style: {
          color: theme.palette.text.primary,
        },
        classes: { input: classes.input },
      }}
      onChange={handleTitleChange}
      id="create-form"
    />
  );
});

function Buttons({
  createMeeting,
  clearForm,
  cancel,
  setCancel,
  disabled,
  selectedSlot,
  mobile,
  eventCase,
}) {
  const message = "Are you sure you want to cancel scheduling this meeting?";
  const successMessage = "Meeting has been cancelled!";
  const { t } = useTranslation();

  const btnStyle =
    mobile &&
    (eventCase === MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS ||
      eventCase === ONE_SKEDING_MULTIPLE_NONSKEDING_USERS)
      ? {
          position: mobile ? "fixed" : "relative",
          left: 0,
          bottom: 0,
          zIndex: 99999,
          paddingBottom: 30,
          paddingTop: 10,
          backgroundColor: "#ffffff7a",
        }
      : {
          marginTop: 20,
        };
  return (
    <Grid
      container
      justifyContent={mobile ? "center" : "flex-start"}
      style={btnStyle}
    >
      {/* <Button variant="outlined" sx={{ mr: "40px" }} onClick={handleCancel}>
          Cancel
        </Button> */}
      <Button
        variant="contained"
        onClick={createMeeting}
        disabled={selectedSlot.length < 1 || disabled}
      >
        {disabled ? (
          <CircularProgress style={{ color: "white" }} size={20} />
        ) : (
          t("create_meeting_sm")
        )}
      </Button>
      <DialogWithSuccessMessage
        open={cancel}
        setOpen={setCancel}
        operation={clearForm}
        message={message}
        successMessage={successMessage}
      />
    </Grid>
  );
}

export function ResponsiveWrapper({
  children,
  meetingDetailExpand,
  setMeetingDetailExpand,
  mobileShowTimes,
  isWebsite = false,
}) {
  const mobile = useMediaQuery(useTheme().breakpoints.down("md"));
  const { t } = useTranslation();

  return mobile ? (
    <Accordion
      elevation={0}
      expanded={meetingDetailExpand}
      disableGutters
      onChange={
        mobileShowTimes ? () => setMeetingDetailExpand((prev) => !prev) : null
      }
      style={{ background: "none", width: "95%" }}
      sx={{
        "&:before": {
          display: "none",
        },
      }}
    >
      {isWebsite ? (
        <></>
      ) : (
        <AccordionSummary
          style={{ padding: 0 }}
          expandIcon={
            mobileShowTimes ? (
              meetingDetailExpand ? (
                <Typography
                  color="primary"
                  variant="h3"
                  style={{ transform: "rotate(180deg)" }}
                >
                  {t("close")}
                </Typography>
              ) : (
                <Typography color="primary" variant="h3">
                  {t("edit")}
                </Typography>
              )
            ) : null
          }
          aria-controls="integration-content"
          id="integration-header"
        >
          <Typography
            align="left"
            variant="h2"
            style={{ width: "fit-content" }}
          >
            {t("meeting_details")}
          </Typography>
        </AccordionSummary>
      )}
      <AccordionDetails style={{ padding: 0 }}>{children}</AccordionDetails>
    </Accordion>
  ) : (
    <Box
      style={{
        height: isWebsite ? "fit-content" : "75vh",
        overflowY: isWebsite ? null : "auto",
      }}
    >
      {children}
    </Box>
  );
}
