import { Grid, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { RECURRING_EVENT } from "../../Utils/Constants";
import RecurringTag from "../Global/RecurringTag";

export default function Initiator({ event }) {
  const theme = useTheme();
  const { t } = useTranslation();
  return event?.initiator ? (
    <>
      <Grid item container sx={{ pt: "8px", alignItems: "baseline" }}>
        <Typography variant="h3" fontWeight="400">
          {event?.initiator}
        </Typography>
        <Typography
          variant="h4"
          fontWeight={400}
          style={{ color: theme.palette.info.light, paddingLeft: "3px" }}
        >
          {t("organizer")}
        </Typography>
      </Grid>
      <Typography variant="h3" fontWeight={400} mt={1}>
        {event?.initiatorEmail}
      </Typography>
      {event?.case === RECURRING_EVENT && <RecurringTag />}
      {/* <Typography variant="body1">{event?.initiatorEmail}</Typography> */}
    </>
  ) : null;
}
