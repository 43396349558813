import { Box, TextField } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export default function Comment({
  comment,
  setComment,
  placeholder = "reject_placeholder",
  disabled,
}) {
  const { t } = useTranslation();
  return (
    <Box
      style={{
        background: "#FBFBFF",
        border: "1px solid #F7F7F9",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
      }}
      mt={2}
      mb={1}
    >
      <TextField
        id="comment"
        variant="standard"
        multiline
        disabled={disabled ? disabled : false}
        style={{ width: "100%" }}
        placeholder={t(placeholder)}
        rows={6}
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        InputProps={{
          disableUnderline: true,
          style: {
            fontSize: "16px",
            fontWeight: 400,
            padding: "10px",
          },
        }}
      />
    </Box>
  );
}
