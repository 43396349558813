import {
  Box,
  Button,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import DialogWithCloseIcon from "../DialogWithCloseIcon";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import { ButtonGroup, Question } from "./Services";
import useStyle from "./styles";
import * as yup from "yup";
import Textfield from "./Textfield";
import ErrorSnackbar from "../ErrorSnackbar";
import { useTranslation } from "react-i18next";

export const longSchema = yup
  .object({
    label: yup.string().required("Label is required"),
    max_characters: yup
      .string()
      .required("Please enter the max length of question"),
  })
  .required();

export const numberSchema = yup
  .object({
    label: yup.string().required("Label is required"),
  })
  .required();

export const checkboxSchema = yup
  .object({
    label: yup.string().required("Label is required"),
    options: yup
      .array()
      .of(yup.string().required("Option cannot be empty"))
      .min(1, "Options must have at least one element")
      .required("Options is required"),
  })
  .required();

export function AddQuestionDialog({
  addQuestion,
  handleAddQuestion,
  question,
  setQuestion,
  questionType,
  questionHeading,
  handleSave,
}) {
  const [options, setOptions] = useState(question?.options);
  const classes = useStyle();
  const [message, setMessage] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    setOptions(question?.options);
  }, [question]);
  const handleAddOption = () => {
    setOptions([...options, "New Option"]);
  };

  const handleRemoveOption = (i) => {
    const opts = options.filter((_, index) => index !== i);
    setOptions(opts);
    setQuestion({ ...question, options: opts });
  };

  const handleQuestionDialog = () => {
    handleAddQuestion(questionType);
    if (questionType === "checkbox")
      setOptions(["Option 1", "Option 2", "Option 3", "Option 4"]);
    setQuestion({} as Question);
  };

  const handleQuesionSave = async () => {
    setQuestion({
      ...question,
      options: questionType === "checkbox" ? options : [],
    });

    try {
      if (questionType === "long") {
        const errors = await longSchema.validate(question);
      } else if (questionType === "number" || questionType === "address") {
        const errors = await numberSchema.validate(question);
      } else {
        const errors = await checkboxSchema.validate(question);
      }
      handleSave();
      handleQuestionDialog();
    } catch (errors) {
      setMessage(errors.errors[0]);
    }

    return;
  };

  return (
    <>
      <DialogWithCloseIcon
        open={
          addQuestion !== undefined
            ? addQuestion === questionType
            : questionType
        }
        handleClose={handleQuestionDialog}
        dialogSize={"xs"}
      >
        <Box className={classes.addServiceContainer}>
          <Typography variant="h2">{questionHeading}</Typography>

          <Textfield
            label={t("label")}
            placeholder={t("label_desc")}
            value={question.label}
            handleChange={(e) => {
              setQuestion({ ...question, label: e.target.value });
            }}
          />
          {questionType !== "long" ? (
            <></>
          ) : (
            <Textfield
              label={t("number_of_characters")}
              placeholder={t("number_of_characters_desc")}
              value={question.max_characters}
              handleChange={(e) => {
                const numericValue = e.target.value.replace(/[^0-9]/g, "");
                setQuestion({ ...question, max_characters: numericValue });
              }}
            />
          )}
          <>
            {questionType !== "checkbox" ? (
              <></>
            ) : (
              <Box className={classes.addServiceContainer}>
                <Typography variant="h4" fontWeight={500}>
                  {t("options")}
                </Typography>
                {options?.map((op, i) => {
                  return (
                    <Box className={classes.questionOption}>
                      <Textfield
                        placeholder={t("option_desc")}
                        value={op}
                        handleChange={(e) => {
                          let tempOps = question?.options;
                          tempOps[i] = e.target.value;
                          setQuestion({ ...question, options: tempOps });
                        }}
                      />
                      <DeleteOutlineRoundedIcon
                        style={{
                          marginLeft: "auto",
                          cursor: "pointer",
                        }}
                        onClick={() => handleRemoveOption(i)}
                      />
                    </Box>
                  );
                })}
                <Button
                  variant="outlined"
                  style={{
                    border: "none",
                    color: "#479AE9",
                    justifyContent: "left",
                    alignItems: "flex-start",
                    width: "fit-content",
                  }}
                  startIcon={<AiOutlinePlusCircle />}
                  onClick={handleAddOption}
                >
                  {t("add_option")}
                </Button>
              </Box>
            )}
          </>

          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={question.is_required}
                onChange={(e) => {
                  setQuestion({ ...question, is_required: e.target.checked });
                }}
              />
            }
            label={
              <Typography variant="h4" fontWeight={400}>
                {t("required_field")}
              </Typography>
            }
          />

          <ButtonGroup
            classes={classes}
            handleCancel={handleQuestionDialog}
            handleSave={handleQuesionSave}
            t={t}
          />
        </Box>
      </DialogWithCloseIcon>
      <ErrorSnackbar
        open={!!message}
        handleClose={() => setMessage("")}
        message={message}
        vertical={"bottom"}
        horizontal={"center"}
        duration={3000}
      />
    </>
  );
}
