import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  popOverElement: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(114, 176, 238, 0.5)",
    },
  },
}));
