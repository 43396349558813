import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  IconButton,
  SnackbarContent,
  Typography,
  Snackbar,
  useTheme,
} from "@mui/material";

interface Props {
  handleClose: () => void;
  open: boolean;
  message: string;
  deleteUnsuccessful?: boolean;
  error?: boolean | string;
  vertical: string;
  horizontal: string;
}
/**
 * Reusable Snackbar component that extends the MUI snackbar with added functionality
 */
export default function SnackbarComponent({
  vertical,
  horizontal,
  handleClose,
  open,
  message,
  deleteUnsuccessful: unsuccessful,
  error,
}: Props) {
  const theme = useTheme();
  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        message={message}
        key={vertical + horizontal}
        autoHideDuration={3000}
        sx={{
          backgroundColor: error ? "red" : "inherit",
          "& .MuiSnackbarContent-root	": {
            boxShadow: "none",
          },
        }}
      >
        <SnackbarContent
          style={{
            backgroundColor: unsuccessful ? "red" : "rgb(150, 220, 255)",
            color: unsuccessful ? undefined : "white",
            width: "400px",
          }}
          message={
            <Typography
              variant="h3"
              id="client-snackbar"
              style={{ color: theme.palette.text.primary }}
              align="center"
            >
              {message}
            </Typography>
          }
          action={
            <IconButton aria-label="clear" onClick={handleClose}>
              <CloseIcon style={{ width: 16, height: 16 }} />
            </IconButton>
          }
          sx={{
            "& .MuiSnackbarContent-action": {
              position: "absolute",
              right: "10px",
            },
            "& .MuiSnackbarContent-message": {
              flexGrow: 1,
              paddingRight: "8px",
            },
          }}
        />
      </Snackbar>
    </div>
  );
}
