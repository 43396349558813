import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import useEventsStore from "../../Utils/eventStore";
import {
  MEETING_THROUGH_WEB_INTEGRATION_CALENDAR,
  MEETING_THROUGH_SHAREABLE_CALENDAR,
  MEETING_THROUGH_QR_CODE,
  MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS,
  ONE_SKEDING_MULTIPLE_NONSKEDING_USERS,
  ONE_NONSKEDING_MULTIPLE_SKEDING_USERS,
  ALL_SKEDING_USERS,
  ONE_NONSKEDING_ONE_SKEDING_USER,
} from "../../Utils/Constants";
import { default as ReactSelect, components } from "react-select";
import ReactDatePicker from "react-datepicker";
import "./picker.css";
import "react-datepicker/dist/react-datepicker.css";
import Textfield from "../AddToWebsite/Textfield";
import { useDebouncedCallback } from "use-debounce";
import Participants from "../NewEvent/Participants";
import useStyle from "../../styles/formStyles";
import puseStyle from "./styles";

export default function Filters() {
  const {
    plannedEvents,
    setFilteredData,
    optionsSelected,
    setOptionsSelected,
  } = useEventsStore();
  const classes = useStyle();
  const pclasses = puseStyle();
  const theme = useTheme();

  const durations = [
    { value: "15", label: "15" },
    { value: "30", label: "30" },
    { value: "45", label: "45" },
    { value: "60", label: "60" },
  ];
  const meetingTypes = [
    {
      value: MEETING_THROUGH_WEB_INTEGRATION_CALENDAR,
      label: "Website Bookings",
    },
    { value: MEETING_THROUGH_SHAREABLE_CALENDAR, label: "Shareable Meetings" },
    { value: MEETING_THROUGH_QR_CODE, label: "QR Meetings" },
    { value: "groupMeetings", label: "Group Meetings" },
    { value: "oneOnOneMeetings", label: "One on One Meetings" },
  ];

  useEffect(() => {
    let temp = [...plannedEvents];

    if (optionsSelected.duration.length > 0) {
      temp = temp.filter((e: any) =>
        optionsSelected.duration.includes(e.durationMinutes?.toString())
      );
    }

    if (optionsSelected.case.length > 0) {
      temp = temp.filter((e: any) => {
        if (optionsSelected.case.includes(e.case)) {
          return true;
        }
        if (
          (optionsSelected.case.includes("groupMeetings") &&
            (e.case === MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS ||
              e.case === ONE_SKEDING_MULTIPLE_NONSKEDING_USERS ||
              e.case === ONE_NONSKEDING_MULTIPLE_SKEDING_USERS ||
              (e.case === ALL_SKEDING_USERS &&
                e.skedingParticipants.length > 1))) ||
          (optionsSelected.case.includes("oneOnOneMeetings") &&
            ((e.case === ALL_SKEDING_USERS &&
              e.skedingParticipants.length === 1) ||
              e.case === ONE_NONSKEDING_ONE_SKEDING_USER))
        ) {
          // If either of the conditions is true, return true
          return true;
        }
      });
    }

    if (optionsSelected.date) {
      temp = temp.filter((e) => {
        if (optionsSelected.endDate) {
          return (
            e.start.getTime() >= optionsSelected.date.getTime() &&
            e.end.getTime() <= optionsSelected.endDate.getTime()
          );
        } else {
          return e.start.getTime() >= optionsSelected.date.getTime();
        }
      });
    }

    if (optionsSelected.title) {
      const regex =
        optionsSelected.title.length > 1
          ? new RegExp(optionsSelected.title.toLowerCase(), "g")
          : null;
      // temp =
      //   optionsSelected.title.length < 2
      //     ? temp
      //     : temp &&
      //       temp.filter((e) => {
      //         return e.title.toLowerCase().search(regex) > -1;
      //       });

      temp =
        optionsSelected.title.length < 2
          ? temp
          : temp &&
            temp.filter((e) => {
              for (const key in e) {
                if (["title", "description", "location"].includes(key)) {
                  if (
                    e[key]
                      ?.toString()
                      ?.toLowerCase()
                      ?.includes(optionsSelected.title.toLowerCase())
                  ) {
                    return true; // Return true if a match is found
                  }
                }
                if (key === "skedingParticipants") {
                  return e[key].some((participant) => {
                    return participant.email.includes(
                      optionsSelected.title.toLowerCase()
                    );
                  });
                }

                if (key === "nonskedingParticipants") {
                  e[key].some((participant) => {
                    return participant?.includes(
                      optionsSelected.title.toLowerCase()
                    );
                  });
                }
              }
              return false; // Return false if no match is found
            });
    }

    if (optionsSelected.participants.length > 0) {
      temp = temp.filter((e: any) => {
        return (
          e.skedingParticipants.some((participant) => {
            return optionsSelected.participants.includes(participant.email);
          }) ||
          e.nonskedingParticipants.some((participant) => {
            return optionsSelected.participants.includes(participant);
          })
        );
      });
    }

    setFilteredData(temp);
  }, [optionsSelected]);

  const debounce = useDebouncedCallback((value) => {
    setOptionsSelected({ ...optionsSelected, title: value });
  }, 1000);

  const populateParticipants: any = useMemo(() => {
    // Create a Set to store unique email addresses
    let emailsSet = new Set();

    plannedEvents?.forEach((event) => {
      event?.skedingParticipants?.forEach((item) => {
        emailsSet.add(item.email);
      });
      event?.nonskedingParticipants?.forEach((email) => {
        emailsSet.add(email);
      });
    });

    // Convert Set back to an array
    let mergedEmails = Array.from(emailsSet, (email) => ({
      value: email,
      label: email,
    }));

    return mergedEmails;
  }, [plannedEvents]);

  return (
    <Box
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: 8,
        border: "1px solid #E0E2E7",
        borderRadius: "8 px",
        padding: "12px",
      }}
    >
      <TextField
        placeholder={"Search by title, description, location or participants"}
        variant="standard"
        autoComplete="off"
        defaultValue={optionsSelected.title}
        autoFocus
        name={"filter"}
        style={{ width: "420px" }}
        InputProps={{
          disableUnderline: true,
          style: {
            color: theme.palette.text.primary,
          },
          classes: {
            input: `${classes.input} ${pclasses.borderInput}`,
          },
        }}
        onChange={(e) => debounce(e.target.value)}
      />

      <Box
        // flex={1}
        style={{
          padding: "0px 4px",
          zIndex: 999,
          width: "240px",
        }}
      >
        <Select
          label={"participants"}
          options={populateParticipants}
          setOptionsSelected={setOptionsSelected}
          optionsSelected={optionsSelected}
        />
      </Box>

      <Select
        label={"duration"}
        options={durations}
        setOptionsSelected={setOptionsSelected}
        optionsSelected={optionsSelected}
      />

      <Select
        label={"case"}
        options={meetingTypes}
        setOptionsSelected={setOptionsSelected}
        optionsSelected={optionsSelected}
      />

      <ReactDatePicker
        selectsRange={true}
        className="customPicker"
        placeholderText="Select date range"
        onChange={(dates) => {
          const [start, end] = dates;
          if (end) end.setHours(23, 59, 0, 0);
          setOptionsSelected({
            ...optionsSelected,
            date: start,
            endDate: end,
          });
        }}
        startDate={optionsSelected.date}
        endDate={optionsSelected.endDate}
        isClearable={true}
      />
    </Box>
  );
}

export type FiltersModel = {
  title?: string;
  duration: string[];
  case: string[];
  participants?: string[];
  date?: null | Date;
  endDate?: null | Date;
};

const Option = (props) => {
  return (
      <components.Option {...props}>
        <div style={{display:'flex', alignItems:'center',}}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />
        <label>{props.label}</label>
        </div>
      </components.Option>
  );
};

type SelectProps = {
  setOptionsSelected: React.Dispatch<React.SetStateAction<any[] | any>>;
  optionsSelected: FiltersModel;
  options: { value: string; label: string }[];
  label: string;
};
export function Select({
  options,
  setOptionsSelected,
  optionsSelected,
  label,
}: SelectProps) {
  const handleChange = (selected) => {
    if (Array.isArray(selected)) {
      setOptionsSelected({
        ...optionsSelected,
        [label]: selected.map((opt) => opt.value),
      });
    }
  };
  return (
    <span style={{ textAlign: "left", zIndex: 10 }}>
      <ReactSelect
        options={options}
        theme={(theme) => ({
          ...theme,
          borderRadius: 6,
          background: "pink",
          colors: {
            ...theme.colors,
            primary25: "#F7F7F9",
            primary: "#E0E2E7",
            neutral50: "#B3B5B6",
          },
        })}
        styles={{
          placeholder: (styles) => ({
            ...styles,
            // textTransform: "capitalize",
            marginBottom: "4px",
          }),

          control: (styles) => ({
            ...styles,
            width: "100%",
            minWidth: "9rem",
            minHeight: "35px",
            height: "35px",
            fontSize: "16px",
          }),
          multiValue: (styles, { data }) => ({
            ...styles,
            // width: "100px",
            minWidth: "40px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }),
          valueContainer: (provided, state) => ({
            ...provided,
            textOverflow: "ellipsis",
            // maxWidth: "140px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            display: "flex",
            flexWrap: "nowrap",
          }),
          option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
              ...styles,
              whiteSpace: "nowrap",
                       };
          }
        }}
        defaultValue={optionsSelected[label]}
        isMulti
        closeMenuOnSelect={false}
        controlShouldRenderValue={true}
        hideSelectedOptions={false}
        components={{
          Option,
        }}
        placeholder={`Search by ${label}`}
        onChange={handleChange}
      />
    </span>
  );
}
