import React, { useState, useMemo } from "react";
import { GlobalStyles } from "@mui/material";
import getDesignTokens from "theme";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import { serverGET } from "../Utils/HttpFunctions";
import { GET_USER_PREFERENCES_PATH } from "Utils/ApiPaths";

export const themeContext = React.createContext();

export default function ThemeColorProvider({ children }) {
  const [mode, setMode] = useState("light");
  const [eventTheme, setEventTheme] = useState(0);
  const theme = useMemo(() => createTheme(getDesignTokens(mode)), [mode]);
  // const colorMode = React.useMemo(
  //   () => ({
  //     toggleColorMode: () => {
  //       setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
  //     },
  //   }),
  //   [mode]
  // );

  // const getEventTheme = async () => {
  //   try {
  //     const response = await serverGET(GET_USER_PREFERENCES_PATH);
  //     if (response) {
  //       if (response.code === 200) {
  //         const data = response.data;
  //         setEventTheme(data.event_theme);
  //       }
  //     }
  //   } catch (e) {
  //     loggingInDev(e);
  //   }
  // };

  // working but jwt malformed error so commenting for now
  // const getEventTheme = async () => {
  //   try {
  //     const response = await serverGET(GET_USER_PREFERENCES_PATH);
  //     if (response) {
  //       if (response.code === 200) {
  //         const data = response.data;
  //         setEventTheme(data.event_theme);
  //         return data.event_theme;
  //       }
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  const colorMode = React.useMemo(
    async () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    [mode, eventTheme]
  );

  return (
    <themeContext.Provider value={{ colorMode, mode }}>
      <GlobalStyles
        styles={{
          body: { backgroundColor: mode === "light" ? "#fff" : "#3C4242" },
        }}
      />
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </themeContext.Provider>
  );
}
