import {
  MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS,
  ONE_SKEDING_MULTIPLE_NONSKEDING_USERS,
} from "../Utils/Constants";

export const createMeetingCalendarStyles = (eventCase) =>
  `
.fc-timegrid-event-harness-inset {
  left: -2px !important;
  right: -2px !important;
}
.fc .fc-timegrid-slot{
  background-color: ${
    eventCase === MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS ||
    eventCase === ONE_SKEDING_MULTIPLE_NONSKEDING_USERS
      ? "rgba(218, 210, 229)"
      : "#c4e7ffb0"
  };
  border-bottom: 1px solid rgba(200, 200, 201, 0.60);
}

.fc .fc-timegrid-slot-label{
  background-color:inherit;
}
.fc-timegrid-event-harness-inset .fc-timegrid-event, .fc-timegrid-event.fc-event-mirror, .fc-timegrid-more-link{
  box-shadow: 0px 0px 1px 0px var(--fc-page-bg-color, #fff)
}
.fc-today-button {
  background-color: #479ae9 !important;
  color:#fff;  
}
`;
