import React, { useMemo, useState } from "react";

import {
  useMediaQuery,
  useTheme,
  Box,
  Typography,
  Button,
} from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import { Contact, AllContactsProps } from "../../interfaces";
import AllContactsRow from "./AllContactsRow";
import EditContactsDialog from "./EditContactsDialog";
import DeleteConfirmation from "./DeleteConfirmation";
import SearchedContactsRow from "./SearchedContactsRow";
import contactsSkeletons from "./ContactsSkeletons";
import useContactStore from "../../Utils/contactStore";
import AccordionComponent from "./Accordion";
import scrollbar from "styles/scrollBar.module.css";

/**
 * Renders a users contacts by iterating through the contact list and rendering the AllContactsRow component for each
 */
export default function AllContacts({
  palette,
  invalidTeam,
  inviteSent,
  teamCreated,
  getContacts,
  fetchData,
  setInvalidTeam,
}: AllContactsProps) {
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("md"));

  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [contactMenu, setContactMenu] = useState(false);
  const [selectedContact, setSelectedContact] = useState({});
  const [editContactDialog, setEditContactDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const {
    contacts,
    groups,
    fetchingContacts,
    setRefreshContacts,
    searchedContacts,
    newGroupMembers,
  } = useContactStore();

  const groupedContacts = useMemo(() => {
    const groupContactsByTeamMembership = (contacts) => {
      const groupedContacts: any = {
        teamMembers: [],
        nonTeamMembers: [],
      };

      contacts.forEach((contact) => {
        if (contact.is_contact_team_member) {
          groupedContacts.teamMembers.push(contact);
        } else {
          groupedContacts.nonTeamMembers.push(contact);
        }
      });

      return groupedContacts;
    };

    return groupContactsByTeamMembership(contacts);
  }, [contacts]);

  const handleMoreClick = (e, contact: Contact) => {
    setAnchorEl(e.currentTarget);
    setContactMenu(!contactMenu);
    setSelectedContact(contact);
  };

  const handleDelete = () => {
    setContactMenu(!contactMenu);
    setDeleteConfirm(true);
  };

  const handleEdit = () => {
    setEditContactDialog(true);
  };

  return (
    <Box
      className={scrollbar.root}
      style={{
        height: "67vh",
        overflow: "auto",
      }}
      id="scrollableDiv"
    >
      <InfiniteScroll
        dataLength={contacts && contacts.length > 0 ? contacts.length : 10} //This is important field to render the next data
        next={fetchData}
        hasMore={true}
        scrollableTarget="scrollableDiv"
        style={{ marginBottom: 16 }}
        loader={<></>}
      >
        {fetchingContacts ? (
          contactsSkeletons("all")
        ) : searchedContacts && searchedContacts.length > 0 ? (
          searchedContacts?.map((contact, j) => {
            let isInNewGroupMembers;
            if (
              newGroupMembers.find(
                (groupMember) => groupMember.id === contact._id
              ) !== undefined
            ) {
              isInNewGroupMembers = true;
            } else isInNewGroupMembers = false;
            /**
             * Have to render this component instead of AllContactsRow because
             * checked state variable doesn't update otherwise when rendering a new checkbox
             */
            return (
              <SearchedContactsRow
                contact={contact}
                small={small}
                contactMenu={contactMenu}
                setContactMenu={setContactMenu}
                handleDelete={handleDelete}
                handleMoreClick={handleMoreClick}
                groups={groups}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                handleEdit={handleEdit}
                palette={palette}
                invalidTeam={invalidTeam}
                setInvalidTeam={setInvalidTeam}
                inviteSent={inviteSent}
                isInNewGroupMembers={isInNewGroupMembers}
                key={j}
              />
            );
          })
        ) : searchedContacts === null ? (
          <Typography mt="30%">No contacts found</Typography>
        ) : groupedContacts.teamMembers.length > 0 ||
          groupedContacts.nonTeamMembers.length ? (
          <>
            <Box mt={2} />
            {groupedContacts.teamMembers.length > 0 && (
              <AccordionComponent title="Team Members">
                {groupedContacts.teamMembers.map((contact, i) => {
                  let isInNewGroupMembers;
                  if (
                    newGroupMembers.find(
                      (groupMember) => groupMember.id === contact._id
                    ) !== undefined
                  ) {
                    isInNewGroupMembers = true;
                  } else isInNewGroupMembers = false;
                  return (
                    <>
                      <AllContactsRow
                        contact={contact}
                        small={small}
                        contactMenu={contactMenu}
                        setContactMenu={setContactMenu}
                        handleDelete={handleDelete}
                        handleMoreClick={handleMoreClick}
                        groups={groups}
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                        handleEdit={handleEdit}
                        palette={palette}
                        invalidTeam={invalidTeam}
                        setInvalidTeam={setInvalidTeam}
                        inviteSent={inviteSent}
                        isInNewGroupMembers={isInNewGroupMembers}
                        teamCreated={teamCreated}
                        key={i}
                      />
                    </>
                  );
                })}
              </AccordionComponent>
            )}
            {groupedContacts.nonTeamMembers.length && (
              <AccordionComponent title="Other Contacts">
                {groupedContacts.nonTeamMembers.map((contact, i) => {
                  let isInNewGroupMembers;
                  if (
                    newGroupMembers.find(
                      (groupMember) => groupMember.id === contact._id
                    ) !== undefined
                  ) {
                    isInNewGroupMembers = true;
                  } else isInNewGroupMembers = false;
                  return (
                    <AllContactsRow
                      contact={contact}
                      small={small}
                      contactMenu={contactMenu}
                      setContactMenu={setContactMenu}
                      handleDelete={handleDelete}
                      handleMoreClick={handleMoreClick}
                      groups={groups}
                      anchorEl={anchorEl}
                      setAnchorEl={setAnchorEl}
                      handleEdit={handleEdit}
                      palette={palette}
                      invalidTeam={invalidTeam}
                      setInvalidTeam={setInvalidTeam}
                      inviteSent={inviteSent}
                      isInNewGroupMembers={isInNewGroupMembers}
                      teamCreated={teamCreated}
                      key={i}
                    />
                  );
                })}
              </AccordionComponent>
            )}
          </>
        ) : (
          <Typography pt="40%">No contacts created</Typography>
        )}
      </InfiniteScroll>
      <DeleteConfirmation
        deleteConfirm={deleteConfirm}
        setDeleteConfirm={setDeleteConfirm}
        selected={[selectedContact]}
        setSelected={setSelectedContact}
        refreshFunction={getContacts}
        type="contact"
      />
      <EditContactsDialog
        dialog={editContactDialog}
        setDialog={setEditContactDialog}
        selectedContact={selectedContact}
        setRefreshContacts={setRefreshContacts}
        setContactMenu={setContactMenu}
      />
    </Box>
  );
}
