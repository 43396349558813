import { Box } from "@mui/material";
import React from "react";
import useWebsiteStore from "../../../Utils/websiteStore";
import { BookingBody } from "../../MeetingTemplates/CreateShareableMeetingDialog";
import useStyle from "../styles";

export default function Booking() {
  const askUser = useWebsiteStore((state) => state.askUser);
  const classes = useStyle();
  return (
    <Box className={classes.bookingPreview}>
      <BookingBody
        createMeeting={() => console.log("create")}
        disabled={false}
        initiatorEmail={""}
        handleClose={() => console.log("close")}
        askUser={askUser}
        service={undefined}
        readOnly={true}
      />
    </Box>
  );
}
