import { Grid, Typography, useTheme, Box, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function Invitee({ event }) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  const { t } = useTranslation();
  return (
    <Box mt={"20px"}>
      <Typography
        align="left"
        variant={"h3"}
        fontWeight="600"
        pb={"14px"}
        style={{ textTransform: "capitalize" }}
      >
        {"Invitee Details"}
      </Typography>
      <Grid container spacing={1} rowSpacing={"14px"}>
        <Grid item xs={12}>
          <Typography variant="h3" align="left" pb={0.5}>
            {"Full Name"}
          </Typography>
          <Typography variant="h3" align="left" fontWeight={400}>
            {event?.non_skeding_participant_data?.participant_name}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h3" align="left" pb={0.5}>
            {"Email"}
          </Typography>
          <Typography
            variant="h3"
            align="left"
            fontWeight={400}
            style={{
              wordWrap: "break-word",
            }}
          >
            {event?.non_skeding_participant_data?.participant_email}
          </Typography>
        </Grid>
        {event?.non_skeding_participant_data?.question_answers?.map((qa) => (
          <Grid
            item
            xs={12}
            style={{
              width: !mobile && "500px",
            }}
          >
            <Typography variant="h3" align="left" pb={0.5}>
              {qa.question}
            </Typography>
            <Typography
              variant="h3"
              align="left"
              fontWeight={400}
              style={{
                wordWrap: "break-word",
              }}
            >
              {qa.answer ? qa.answer : t("not_answered")}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
    // <>
    //   <Grid item container pt="14px" alignItems="baseline">
    //     <Typography variant="h3" fontWeight="400">
    //       {event?.non_skeding_participant_data?.participant_name}{" "}
    //     </Typography>
    //     <Typography
    //       variant="h4"
    //       fontWeight={400}
    //       style={{ color: theme.palette.info.light, paddingLeft: "3px" }}
    //     >
    //       {t("invitee")}
    //     </Typography>
    //   </Grid>
    //   <Grid container pt="10px">
    //     <Grid
    //       item
    //       xs={1}
    //       md={0.7}
    //       justifyContent="flex-start"
    //       style={{ display: "flex", alignItems: "center" }}
    //     >
    //       <CardImage
    //         style={{
    //           fontSize: "14px",
    //           color: theme.palette.text.primary,
    //         }}
    //       />
    //     </Grid>
    //     <Grid item xs={11}>
    //       <Typography variant="h3" align="left" fontWeight="400">
    //         {event?.non_skeding_participant_data?.participant_email}{" "}
    //       </Typography>
    //       <Typography variant="h3" align="left" fontWeight="400">
    //         {event?.non_skeding_participant_data?.participant_phone_number}{" "}
    //       </Typography>
    //     </Grid>
    //   </Grid>
    // </>
  );
}
