import { CircularProgress } from "@mui/material";
import React from "react";

export default function Spinner() {
  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
      }}
    >
      <CircularProgress color="primary" />
    </div>
  );
}
