import React, { useContext, useEffect } from "react";
import { serverPOST } from "Utils/HttpFunctions";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { authContext } from "context/Auth";
import loggingInDev from "loggingInDev";
import { integrationContext } from "context/Integrations";
import useStyle from "./styles";

export default function ZoomSignin() {
  const navigate = useNavigate();
  const { isLogged } = useContext(authContext);
  const { getUser } = useContext(integrationContext);
  const classes = useStyle();

  useEffect(() => {
    getAuth();
  }, [isLogged]);

  const getAuth = async () => {
    if (isLogged === true) {
      loggingInDev("window location ==>", window.location);
      const path = `/zoom/callback${window.location.search}`;
      const response = await serverPOST(path);
      if (response && response.constructor !== String) {
        loggingInDev("response inside component ===>", response);
        await getUser();

        navigate("/preferences", { replace: true });
      } else {
        navigate("/dashboard");
      }
    }
  };

  return (
    <div className={classes.centerLoader}>
      <CircularProgress />
    </div>
  );
}
