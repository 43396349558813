import ColorIndicator from "../meetings/ColorIndicator";
import React from "react";

export default function NewTheme() {
  return (
    <>
      <ColorIndicator text={"Internal meetings"} color={"#95C3F2"} />
      <ColorIndicator text={"External meetings"} color={"#CBADF8"} />
      <ColorIndicator text={"Website bookings"} color={"#DBF27E"} />
      <ColorIndicator text={"Tasks"} color={"#B0E4FF"} />
      <ColorIndicator text={"Drafts"} color={"#c9e6f5"} />
      <ColorIndicator text={"External events"} color={"#F8E08C"} />
    </>
  );
}
