import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Dialog,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { serverPOST } from "../../Utils/HttpFunctions";
import useDelegatedAcess from "../../Utils/SwtichAccount";
import DialogWithCloseIcon from "../DialogWithCloseIcon";
import { useNavigate } from "react-router-dom";

const SwitchAccount = ({ switchAccount, setSwitchAccount, members }) => {
  const [selectedAccount, setSelectedAccount] = useState<any>(null);
  const setDelegatedMode = useDelegatedAcess((state) => state.setDelegatedMode);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = async () => {
    if (selectedAccount) {
      const response = await serverPOST("/account/generatedelegatedtokens", {
        email: selectedAccount?.email,
      });
      if (response && response.constructor !== String) {
        if (response.data?.access?.token) {
          //store token in localstorage and global state
          setDelegatedMode(response.data?.access?.token);
          navigate("/dashboard");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      }
    }
  };
  const handleCheck = (e, m) => {
    switch (e.target.checked) {
      case true:
        setSelectedAccount(m);
        break;
      case false:
        setSelectedAccount(null);
        break;
    }
  };
  return (
    <DialogWithCloseIcon
      handleClose={() => setSwitchAccount(false)}
      open={!!switchAccount}
      dialogSize={undefined}
    >
      <Typography variant="h2" mb={1}>
        {t("switch_account")}
      </Typography>
      <Box></Box>
      {members.map((m) => {
        return (
          <Box
            display="flex"
            alignItems={"center"}
            mt={2}
            py={1}
            style={{
              borderBottom: "1px solid #F1F1F1",
            }}
          >
            <Checkbox
              onChange={(e) => handleCheck(e, m)}
              checked={!!selectedAccount && m.email === selectedAccount.email}
              disableRipple={true}
            />
            <Avatar
              src=""
              sx={{
                height: "40px",
                width: "40px",
                mr: "16px",
                fontSize: "16px",
              }}
            />

            <Box gap={0.5} display={"flex"} flexDirection={"column"}>
              <Typography variant="h3" align="left" width="50%">
                {m.user_id.first_name} {m.user_id.last_name}
              </Typography>
              <Typography variant="h3" fontWeight="400">
                {m.email}
              </Typography>
            </Box>
          </Box>
        );
      })}

      <Box mt={2}>
        <Button variant="contained" onClick={handleClick}>
          {t("switch_account")}
        </Button>
      </Box>
    </DialogWithCloseIcon>
  );
};

export default SwitchAccount;
