import makeStyles from "@mui/styles/makeStyles";
const useStyle = makeStyles((theme) => ({
  attachement: {
    color: theme.palette.text.primary,
    background: theme.palette.secondary.main,
    borderRadius: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "25px",
    maxWidth: "150px",
    minWidth: "150px",
  },
  dottedText: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  recurring: {
    backgroundColor: "#E5F2FB",
    borderRadius: 4,
    padding: "4px 8px",
    display: "flex",
    width: "fit-content",
    marginTop: "16px",
  },
}));

export default useStyle;
