import React, { useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
export const authContext = React.createContext();

export default function AuthProvider({ children }) {
  const [isLogged, setIsLogged] = useState(null);
  const [alreadyLoggedIn, setAlreadyLoggedIn] = useState(null);

  //gets value of session storage token to determine if user is already logged in on another tab
  //if yes they are routed to the url they hit
  //if no they are routed to login page
  const getStorage = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        let decodedToken = jwt_decode(token);
        if (
          "id" in decodedToken &&
          "firstName" in decodedToken &&
          "lastName" in decodedToken &&
          "email" in decodedToken &&
          "type" in decodedToken
        ) {
          setIsLogged(true);
        } else {
          setIsLogged(false);
          localStorage.clear();
        }
      } catch (e) {
        setIsLogged(false);
      }
    } else {
      setIsLogged(false);
      localStorage.clear();
    }
  };

  useEffect(() => {
    getStorage();

    //listens for if storage is changed
    //if openpages variable is added user has no other tabs open and isn't logged in
    //if page_available variable is added user is already logged in
    window.addEventListener(
      "storage",
      (e) => {
        if (e.key == "openpages") {
          localStorage.page_available = Date.now();
          setAlreadyLoggedIn(false);
        } else if (e.key == "page_available") {
          // sessionStorage.setItem("loggedIn", true);
          setAlreadyLoggedIn(true);
        }
      },
      false
    );

    return () => {
      window.addEventListener(
        "storage",
        (e) => {
          if (e.key == "openpages") {
            localStorage.page_available = Date.now();
            setAlreadyLoggedIn(false);
          } else if (e.key == "page_available") {
            sessionStorage.setItem("loggedIn", true);
            setAlreadyLoggedIn(true);
            getStorage();
          }
        },
        false
      );
    };
  }, []);

  // useEffect(() => {
  //     //checks if user has any other pages open
  //     localStorage.openpages = Date.now();
  //     console.log('local storage is set');
  // }, [])

  useEffect(() => {
    // await checkIfOtherTabsOpen();
    if (alreadyLoggedIn !== null) {
      console.log("sessionStorage set");
      getStorage();
    }
  }, [alreadyLoggedIn]);

  //need to add a check to verify user is already logged in
  //adds the session storage variable loggedIn if there is another tab running the app that is logged in
  // const checkIfOtherTabsOpen = () => {
  //   // if(localStorage.getItem("token")) {
  //     localStorage.openpages = Date.now();
  //     window.addEventListener('storage', (e) => {
  //       if(e.key == "openpages") {
  //         localStorage.page_available = Date.now();
  //       } else if(e.key == "page_available") {
  //         sessionStorage.setItem("loggedIn", true);
  //       }
  //     }, false)
  //   // }
  // }

  return (
    <authContext.Provider value={{ isLogged, setIsLogged }}>
      {children}
    </authContext.Provider>
  );
}
