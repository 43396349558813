import { Grid, Typography, useTheme, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { ReactComponent as SkedingLogo } from "assets/logo.svg";
import { ReactComponent as SkedingBetaLogo } from "../assets/beta-logo-gradient.svg";
import TermsConditons from "./TermsConditions/TermsConditons";
import { ReactComponent as Bubbles } from "assets/bubbles.svg";
import BubblesImage from "assets/bubbles.png";
import { Player } from "@lottiefiles/react-lottie-player";
import Animate from "assets/login.json";
import { useRef } from "react";

export default function LoginBoilerPlate(props) {
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("md"));
  const ref = useRef("");

  return props?.showTerms ? (
    <TermsConditons isInternal={true} setShowTerms={props.setShowTerms} />
  ) : (
    <Grid
      container
      alignItems="center"
      style={{
        minHeight: "100vh",
        background: small
          ? theme.palette.secondary.main
          : "linear-gradient(130.26deg, #479AE9 43.2%, #97EEDE 106.18%)",
      }}
    >
      <Grid
        item
        xs={12}
        md={5.5}
        p="2% 0 2% 0"
        style={{
          marginLeft: "auto",
          background: small ? "inherit" : "#FEFEFE",
          overflowY: small ? "inherit" : "clip",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          position: "relative",
        }}
      >
        {!small && (
          <Box
            style={{
              position: "absolute",
              backgroundImage: `url(${BubblesImage})`,
              right: "-100px",
              width: "215px",
              height: "100%",
              backgroundRepeat: "round",
              backgroundSize: "contain",
              padding: "10px 0px",
            }}
          >
            {/* <Bubbles
              style={
                {
                  // height: "105vh",
                }
              }
            /> */}
          </Box>
        )}
        <Grid container item alignItems="center" flexDirection="column">
          <Grid item container alignItems="center" justifyContent="center">
            <SkedingBetaLogo height={"90%"} style={{ paddingRight: "0" }} />
          </Grid>
          {props.title && (
            <Typography
              variant="h1"
              style={{
                marginTop: "20px",
              }}
              align={"center"}
            >
              {props.title}
            </Typography>
          )}
        </Grid>
        {props.form}
      </Grid>
      {props.image ? (
        <Grid
          container
          item
          md={6.5}
          sx={{ display: small ? "none" : "flex" }}
          style={{
            display: small ? "none" : "flex",
            marginRight: "auto",
            // maxWidth: "1536px",

            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          {/* <img src={props.image} /> */}
          <div>
            <Player
              ref={ref} // set the ref to your class instance
              autoplay={true}
              loop={true}
              controls={true}
              src={Animate}
              speed={1}
              style={{
                width: small ? "100%" : "100%",
                height: small ? "21vh" : null,
                marginTop: "30px",
              }}
            ></Player>
          </div>
        </Grid>
      ) : null}
    </Grid>
  );
}
