import { useState } from "react";

import {
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";

/**
 * Dialog for setting the expiration date of the template meeting link
 */
export default function LinkExpirationDialog({
  linkExpirationDialog,
  setLinkExpirationDialog,
  copyLinkRequest,
  identifier,
  disabled,
}) {
  const [expirationTime, setExpirationTime] = useState();
  const theme = useTheme();
  const { t } = useTranslation();
  const handleClose = () => {
    setLinkExpirationDialog(false);
  };

  const handleButtonDone = () => {
    setLinkExpirationDialog(false);
    if (expirationTime) {
      copyLinkRequest(expirationTime, identifier);
    }
  };

  return (
    <Dialog
      open={linkExpirationDialog}
      onClose={handleClose}
      sx={{ justifyContent: "center" }}
      PaperProps={{
        sx: {
          padding: { xs: "12px 32px", md: "20px 40px" },
          boxSizing: "border-box",
        },
      }}
      maxWidth="xs"
    >
      <DialogTitle
        sx={{
          textAlign: "center",
          color: theme.palette.text.primary,
          paddingBottom: "4px",
        }}
      >
        <Typography variant="h2">{t("expire_title")}</Typography>
      </DialogTitle>

      <DialogTitle
        id="alert-set-link-expiration"
        sx={{
          textAlign: "center",
          color: theme.palette.text.primary,
          paddingTop: 0,
        }}
      >
        <Typography variant="h3" fontWeight={400}>
          {identifier === 0 ? t("link_expire") : t("qr_expire")}
        </Typography>
      </DialogTitle>

      <DialogActions sx={{ flexDirection: "column" }}>
        <Grid container flexDirection="column" spacing={2}>
          <Grid item container justifyContent="center">
            <LinkExpirationButton
              days={7}
              expirationTime={expirationTime}
              setExpirationTime={setExpirationTime}
            />
          </Grid>
          <Grid item container justifyContent="center">
            <LinkExpirationButton
              days={14}
              expirationTime={expirationTime}
              setExpirationTime={setExpirationTime}
            />
          </Grid>
          <Grid item container justifyContent="center">
            <LinkExpirationButton
              days={30}
              expirationTime={expirationTime}
              setExpirationTime={setExpirationTime}
            />
          </Grid>
          <Grid item container justifyContent="center">
            <LinkExpirationButton
              days={"never"}
              expirationTime={expirationTime}
              setExpirationTime={setExpirationTime}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent={"center"} sx={{ mt: 4 }}>
          <Grid item>
            <Button
              variant="outlined"
              onClick={handleClose}
              style={{ marginRight: 8 }}
            >
              {t("cancel")}
            </Button>
          </Grid>
          <Grid item>
            <Button
              style={{ marginLeft: 8 }}
              // sx={{ width: { xs: "inherit", md: "120px" } }}
              onClick={handleButtonDone}
              variant="small-submit"
              disabled={disabled}
            >
              {t("done")}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

function LinkExpirationButton({ days, expirationTime, setExpirationTime }) {
  const theme = useTheme();
  const { t } = useTranslation();
  const handleClick = () => {
    setExpirationTime(days);
  };

  return (
    <Button
      onClick={handleClick}
      style={{
        backgroundColor:
          expirationTime === days ? "rgba(114, 176, 238, 0.5)" : "transparent",
        color: theme.palette.text.primary,
        borderRadius: 8,
      }}
      sx={{ width: "98%" }}
    >
      {days === "never" ? (
        <Typography variant="h4">{t("no_expire")}</Typography>
      ) : (
        <Typography variant="h4">
          {t("expires_in")} {days} {t("days")}
        </Typography>
      )}
    </Button>
  );
}
