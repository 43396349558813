import React, { useState, useEffect, useRef, useContext } from "react";

import {
  Badge,
  Box,
  Button,
  Chip,
  Dialog,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import InsertInvitationOutlinedIcon from "@mui/icons-material/InsertInvitationOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";
import moment, { isDate } from "moment";

import { convertUTCMillisecondsToDate } from "Utils/CommonFunctions";
import useStyle from "../../styles/formStyles";
import { useTranslation } from "react-i18next";
import { accountContext } from "../../context/user";

export default function Polling({
  events: totalEvents,
  participants,
  selectedSlot,
  setSelectedSlot,
  showMsg,
  appendSlots,
  initiatorResponse,
  newMeeting,
  errorAmount,
  setErrorAmount,
  meetingType,
}) {
  const classes = useStyle();
  const theme = useTheme();
  const scollToRef = useRef();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();
  const [eventDays, setEventDays] = useState([]);
  const [daySlots, setDaySlots] = useState([]);
  const [sections, setSections] = useState({});
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [formDate, setFormDate] = useState(moment());
  const [events, setEvents] = useState([]);
  const { user } = useContext(accountContext);

  useEffect(() => {
    if (totalEvents) {
      setEvents(totalEvents);
    }
  }, [totalEvents]);

  useEffect(() => {
    if (eventDays?.length > 0) {
      const s = eventDays[eventDays.length - 31]
        ? eventDays[eventDays.length - 31]
        : eventDays[0];
      setFormDate(moment(s?.start));
    }
  }, [eventDays]);

  useEffect(() => {
    if (events) {
      getUniqueDays();
    }
  }, [events]);

  const getUniqueDays = async () => {
    const parsedDays = await events.map((slot) => {
      let start = convertUTCMillisecondsToDate(slot.start);
      return {
        start:
          start.getFullYear() +
          "-" +
          (start.getMonth() + 1 < 10
            ? "0" + (start.getMonth() + 1)
            : start.getMonth() + 1) +
          "-" +
          (start.getDate() < 10 ? "0" + start.getDate() : start.getDate()),
      };
    });
    const uniqueDays = parsedDays.filter(
      (v, i, a) => a.findIndex((v2) => v2.start === v.start) === i
    );
    setEventDays(uniqueDays);
  };

  useEffect(() => {
    if (events) {
      getRenderChips();
    }
  }, [events]);

  const getRenderChips = async () => {
    const daySlots = await renderChips(new Date(events[0]?.start));
    setDaySlots(daySlots);
  };

  const handleDateChange = async (newValue, calCheck) => {
    const time = newValue.toDate();
    if (calCheck) {
      const eventStartDate =
        events.length > 0 && moment(events[0]?.start).startOf("day");
      const eventEndDate =
        events.length > 0 &&
        moment(events[events.length - 1]?.start).startOf("day");

      time.setHours(0);
      time.setMinutes(0);
      time.setSeconds(0);
      time.setMilliseconds(0);

      if (newValue.isBetween(eventStartDate, eventEndDate, "[]")) {
        if (isDate(time)) {
          const daySlots = await renderChips(time);
          if (daySlots.length > 0) {
            setDaySlots(daySlots);
          }
        }
      } else {
        appendSlots(time);
      }
    } else {
      if (isDate(time)) {
        const daySlots = await renderChips(time);
        if (daySlots.length > 0) {
          setDaySlots(daySlots);
        }
      }
    }
  };

  const renderChips = async (selectedDate) => {
    const parsedSlots = await events.map((slot) => {
      let start = convertUTCMillisecondsToDate(slot.start);
      let end = convertUTCMillisecondsToDate(slot.end);
      return {
        start,
        end,
        availableParticipants: slot.availableParticipants,
        unAvailableParticipants: slot.unAvailableParticipants,
      };
    });
    const daySlots = await parsedSlots.filter((v) => {
      let result = compareDateWithoutTime(v, selectedDate);
      if (result) {
        return v;
      }
    });
    return daySlots;
  };

  const compareDateWithoutTime = (dateA, dateB) => {
    const date1 = new Date(dateA.start);
    date1.setHours(0, 0, 0, 0);
    const date2 = new Date(dateB);
    date2.setHours(0, 0, 0, 0);
    if (date1 > date2) {
      return false;
    } else if (date1 < date2) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    let result = groupBy(daySlots, (d) => d.availableParticipants.length);
    setSections(result);
    if (newMeeting) {
      scollToRef?.current?.scrollIntoView();
    }
  }, [daySlots]);

  function groupBy(xs, f) {
    return xs.reduce(
      (r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r),
      {}
    );
  }

  useEffect(() => {
    if (totalEvents.length > 0 && newMeeting) {
      var userUnavailableFiltered = filterAvailableTimes();
      var slotsWithBackground =
        userUnavailableFiltered?.length > 0 &&
        userUnavailableFiltered.map((availableTime) => {
          return {
            start: availableTime.start,
            end: availableTime.end,
            availableParticipants: availableTime.availableParticipants,
            unavailableParticipants: availableTime.unAvailableParticipants,
          };
        });

      setEvents(slotsWithBackground);
    }
  }, [totalEvents]);

  const filterAvailableTimes = () => {
    var currentUserEmail = user?.email;
    var currentUserRemoved = totalEvents?.filter(
      (availableTime) =>
        availableTime?.unAvailableParticipants?.find(
          (unavailableParticipant) =>
            unavailableParticipant?.email === currentUserEmail
        ) === undefined
    );
    return currentUserRemoved;
  };

  return (
    <div>
      <Box style={{ marginBottom: -30 }}>
        <Box display={"flex"} alignItems="center">
          <Typography
            variant="h3"
            align="left"
            style={{ marginLeft: "0px", fontWeight: 500 }}
          >
            {showMsg ? t("pick_for") : t("choose_for")}
          </Typography>
          <Button
            variant="text"
            style={{ width: mobile ? "160px" : "120px" }}
            disableRipple={true}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                open={calendarOpen}
                onOpen={() => setCalendarOpen(true)}
                onClose={() => setCalendarOpen(false)}
                value={formDate}
                disablePast={true}
                onChange={(newValue) => {
                  setFormDate(newValue);
                  handleDateChange(newValue, true);
                }}
                minDate={new Date()}
                PaperProps={{
                  sx: {
                    span: { color: "black" },
                    "& .MuiPickersDay-root": {
                      color: "black",
                      "&.Mui-selected": {
                        color: "white !important",
                        backgroundColor: "#479AE9 !important",
                        "&:hover": {
                          opacity: 0.8,
                        },
                      },
                    },
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    onClick={
                      window.location.pathname === "/createmeeting"
                        ? (e) => setCalendarOpen((prev) => !prev)
                        : null
                    }
                    onKeyDown={(e) => e.preventDefault()}
                    className={classes.dateTime}
                    InputProps={{
                      endAdornment: window.location.pathname ===
                        "/createmeeting" && (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="calendar"
                            onClick={() => setCalendarOpen((prev) => !prev)}
                          >
                            <InsertInvitationOutlinedIcon
                              onClick={() => setCalendarOpen((prev) => !prev)}
                              sx={{ fontSize: "16px", color: "#363B53" }}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                      disableUnderline: true,
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Button>
        </Box>
        {showMsg && (
          <>
            <Typography
              variant="h3"
              align="left"
              style={{
                marginLeft: "0px",
                color: theme.palette.info.light,
                marginTop: 2,
                marginBottom: "14px",
              }}
            >
              {t("three_fifteen")}
            </Typography>
            {meetingType && selectedSlot?.length > 1 ? (
              <Typography
                variant="body1"
                align="left"
                style={{
                  color: "red",
                  marginBottom: 4,
                  fontSize: 9,
                }}
              >
                {t("recur_msg")}
              </Typography>
            ) : null}
          </>
        )}

        <Box
          display={"flex"}
          style={{ width: "100%", overflowX: "auto", paddingBottom: "12px" }}
          mb={1}
          mt={1}
        >
          {eventDays.map((date, i) => (
            <Box
              key={i}
              ml={i ? 2 : 0}
              ref={
                formDate.valueOf() === moment(date.start).valueOf()
                  ? scollToRef
                  : null
              }
              style={{
                minWidth: "46px",
                height: "46px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                background:
                  formDate.valueOf() === moment(date.start).valueOf()
                    ? "#72B0EE"
                    : "#F7F7F9",
                borderRadius: "50%",
                cursor: "pointer",
              }}
              onClick={() => {
                setFormDate(moment(date.start));
                handleDateChange(moment(date.start), false);
              }}
            >
              <Typography variant="h4" align="center" fontWeight={"500"}>
                {moment(date.start).format("dddd").substring(0, 3)}
                <br />
                {moment(date.start).date()}
              </Typography>
            </Box>
          ))}
        </Box>
        <Box
          display="flex"
          flexWrap="wrap"
          style={{
            overflowY: "auto",
            display: "block",
            overflowX: "clip",
            marginBottom: 40,
          }}
        >
          {Object.keys(sections).length > 0 ? (
            Object.keys(sections)
              .reverse()
              .map((s) => {
                return s == 0 && Object.keys(sections).length == 1 ? (
                  <Typography
                    key={s}
                    variant="h4"
                    align="left"
                    fontWeight={"500"}
                  >
                    {t("no_avail_slots")}
                  </Typography>
                ) : s == 0 ? null : (
                  <Box mt={2} key={s}>
                    <Typography variant="h3" align="left" fontWeight={"500"}>
                      {s} / {participants.length + 1} available
                    </Typography>
                    <Box
                      display={"flex"}
                      flexWrap="wrap"
                      justifyContent={"flex-start"}
                    >
                      {sections[s].map((s, index) => (
                        <BadgedChip
                          key={index}
                          slot={s}
                          selectedSlot={selectedSlot}
                          setSelectedSlot={setSelectedSlot}
                          participants={participants}
                          initiatorResponse={initiatorResponse}
                          setErrorAmount={setErrorAmount}
                          meetingType={meetingType}
                          t={t}
                        />
                      ))}
                    </Box>
                  </Box>
                );
              })
          ) : (
            <Typography variant="h4" align="left" fontWeight={"500"}>
              {t("no_avail_slots")}
            </Typography>
          )}
          {selectedSlot?.length > 0 ? (
            <Box mt={2}>
              <Typography
                variant="h3"
                align="left"
                sx={{
                  fontWeight: 500,
                }}
              >
                {t("selected_timeslots")}
              </Typography>
              {errorAmount && (
                <Typography
                  style={{ color: "red" }}
                  variant="body1"
                  align="left"
                >
                  {errorAmount}
                </Typography>
              )}
              <SelectedTimes
                selectedSlot={selectedSlot}
                setSelectedSlot={setSelectedSlot}
                t={t}
              />
            </Box>
          ) : null}
        </Box>
      </Box>
    </div>
  );
}

function SelectedTimes({ selectedSlot, setSelectedSlot, t }) {
  const [sections, setSections] = useState({});
  useEffect(() => {
    let result = groupBy(selectedSlot, (s) =>
      moment(s.start).format("YYYY-MM-DD")
    );
    setSections(result);
  }, [selectedSlot]);

  function groupBy(xs, f) {
    return xs.reduce(
      (r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r),
      {}
    );
  }

  return (
    <Box>
      {Object.keys(sections).length > 0 &&
        Object.keys(sections).map((s, index) => {
          return (
            <Box mt={2} key={index}>
              <Typography variant="h4" align="left" fontWeight={"500"}>
                {s}
              </Typography>
              <Box
                display={"flex"}
                flexWrap="wrap"
                justifyContent={"flex-start"}
              >
                {sections[s].map((s, index) => (
                  <SelectedChip
                    key={index}
                    slot={s}
                    selectedSlot={selectedSlot}
                    setSelectedSlot={setSelectedSlot}
                    t={t}
                  />
                ))}
              </Box>
            </Box>
          );
        })}
    </Box>
  );
}
function SelectedChip({ slot, setSelectedSlot, selectedSlot, t }) {
  var startTime = moment(slot?.start).format("hh:mm a");
  const [invisible, setInvisible] = useState(true);

  const handleRemoveClick = (slot) => {
    const temp = [...selectedSlot];
    if (temp.includes(slot)) {
      const index = temp.indexOf(slot);
      if (index !== -1) {
        temp.splice(index, 1);
        setSelectedSlot(temp);
      }
    }
  };

  return slot ? (
    <Badge
      color="primary"
      badgeContent={
        <IconButton onClick={() => handleRemoveClick(slot)}>
          <CancelIcon sx={{ color: "#96DCFF" }} />
        </IconButton>
      }
      invisible={invisible}
      onMouseOver={() => setInvisible(false)}
      onMouseOut={() => setInvisible(true)}
      sx={{
        "& .MuiBadge-badge": { maxWidth: "0", maxHeight: "35%" },
        marginTop: "10px",
        marginRight: "5px",
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Chip
          label={
            <Typography variant="h3" fontWeight={500} style={{ color: "#000" }}>
              {startTime}
            </Typography>
          }
          sx={{
            background: selectedSlot.includes(slot)
              ? "#72B0EE"
              : "rgba(150, 220, 255, 0.75)",
            borderRadius: "8px",
            p: "20px 8.5px 20px 8.5px",
            "&&:hover": {
              backgroundColor: "#72B0EE",
            },
            "&&:focus": {
              backgroundColor: "rgba(150, 220, 255, 0.75)",
            },
          }}
          onMouseOut={() => setInvisible(true)}
          onMouseOver={() => setInvisible(false)}
        />
      </Box>
    </Badge>
  ) : (
    <Typography>{t("no_mutual_day")}</Typography>
  );
}

function BadgedChip({
  slot,
  setSelectedSlot,
  selectedSlot,
  participants,
  initiatorResponse,
  setErrorAmount,
  meetingType,
  t,
}) {
  var startTime = moment(slot?.start).format("hh:mm a");
  const [pending, setPending] = useState([]);
  const [modal, setModal] = useState(false);
  let singleClickTimer = "";
  let clickCount = 0;

  const getParticipantsArrays = (availability) => {
    if (availability === "available") {
      return slot?.availableParticipants;
    } else {
      return slot?.unAvailableParticipants;
    }
  };

  const collectParticipantNames = (participants) => {
    var participantNames = [];
    participants?.map((p) => {
      var temp = [];
      temp.push(p?.email);
      participantNames.push(temp);
    });

    return participantNames;
  };
  useEffect(() => {
    if (modal) {
      var availableParticipants = getParticipantsArrays("available");
      var unavailableParticipants = getParticipantsArrays("unavailable");

      var availableParticipantNames = collectParticipantNames(
        availableParticipants
      );
      var unavailableParticipantNames = collectParticipantNames(
        unavailableParticipants
      );

      let total = [
        [...availableParticipantNames, ...unavailableParticipantNames].flat(1),
      ];

      var pending = total?.map((respondedParticipant, i) => {
        return participants?.map((participant) => {
          if (
            respondedParticipant.find(
              (respondedParticipant) => respondedParticipant === participant
            ) === undefined
          ) {
            return participant;
          } else {
            return;
          }
        });
      });
      var cleanedPending = pending?.map((pendingParticipants) => {
        return pendingParticipants?.filter(
          (participant) => participant !== undefined
        );
      });
    }

    setPending(cleanedPending);
  }, [modal]);

  const handleSelect = (e, slot) => {
    if (e.detail === 1) {
      setErrorAmount("");
      singleClickTimer = setTimeout(function () {
        clickCount = 0;
        const temp = [...selectedSlot];

        if (temp?.find((x) => x.start.toString() === slot.start.toString())) {
          const index = temp.indexOf(slot);
          console.log("index of", index);
          if (initiatorResponse || meetingType) {
            setSelectedSlot([]);
          } else {
            if (index !== -1) {
              temp.splice(index, 1);
              setSelectedSlot(temp);
            }
          }
        } else {
          if (initiatorResponse || meetingType) {
            setSelectedSlot([slot]);
          } else {
            setSelectedSlot([...temp, slot]);
          }
        }
      }, 300);
    } else if (e.detail === 2) {
      clearTimeout(singleClickTimer);
      clickCount = 0;
      setModal(true);
    }
  };

  // console.log("selected slot", selectedSlot);
  // console.log("slot", slot);
  // console.log("date slot", new Date(slot.start).getTime());
  // console.log(
  //   "includes",
  //   selectedSlot?.find((x) => x.start.toString() === slot.start.toString())
  // );
  // console.log(
  //   "slot",
  //   selectedSlot?.map(
  //     (s) => new Date(s.start).getTime() === new Date(slot.start).getTime()
  //   )
  // );

  return slot ? (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        alignItems: "flex-start",
      }}
      ml={1}
      mt={2}
    >
      <Chip
        // {...backspaceLongPress}
        label={
          <Typography variant="h3" fontWeight={500} style={{ color: "#000" }}>
            {startTime}
          </Typography>
        }
        sx={{
          background: selectedSlot?.find(
            (x) => x.start.toString() === slot.start.toString()
          )
            ? "#72B0EE"
            : "rgba(150, 220, 255, 0.75)",
          borderRadius: "8px",
          p: "20px 8.5px 20px 8.5px",
          "&&:hover": {
            backgroundColor: "#72B0EE",
          },
        }}
        disableRipple={true}
        clickable={true}
        onClick={(e) => handleSelect(e, slot)}
      />

      <AvailModal
        slot={slot}
        modal={modal}
        setModal={setModal}
        totalParticipants={participants.length}
        pending={pending}
        t={t}
      />
    </Box>
  ) : (
    <Typography>{t("no_mutual_day")}</Typography>
  );
}

function AvailModal({ slot, modal, setModal, totalParticipants, pending, t }) {
  return (
    <Dialog
      open={modal}
      onClose={() => setModal(false)}
      PaperProps={{
        sx: { padding: 3, borderRadius: "16px", boxSizing: "border-box" },
      }}
    >
      <Box display="flex" justifyContent="flex-end" style={{ width: "100%" }}>
        <IconButton
          style={{ padding: 0 }}
          sx={{ justifyContent: "flex-end" }}
          onClick={() => setModal(false)}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Box style={{ width: "100%" }}>
        <Typography variant="h3" fontWeight={600}>
          {slot.availableParticipants.length}/{totalParticipants + 1}{" "}
          {t("members_avail")}
        </Typography>
      </Box>
      <Box style={{ width: "100%", display: "flex", flex: 1 }} mt={2}>
        <Box style={{ flex: "0 0 50%", padding: 0 }}>
          <Typography variant="h3" fontWeight={400}>
            {t("available")}
          </Typography>
          {slot?.availableParticipants?.map((a, i) => (
            <Typography variant="body2" fontWeight={300} key={i}>
              {a?.email}
            </Typography>
          ))}
          {slot?.unAvailableParticipants?.map((a, i) => (
            <Typography
              key={i}
              variant="body2"
              fontWeight={300}
              sx={{ textDecoration: "line-through" }}
            >
              {a?.email ? a.email : a}
            </Typography>
          ))}
        </Box>
        <Box style={{ flex: "0 0 50%", paddingLeft: "8px" }}>
          <Typography variant="h3" fontWeight={400}>
            {t("pending")}
          </Typography>
          {pending &&
            pending?.map((p) => {
              return p?.map((participant) => {
                return (
                  <Box key={participant}>
                    <Typography variant="body2" fontWeight={300}>
                      {participant}
                    </Typography>
                  </Box>
                );
              });
            })}
        </Box>
      </Box>
    </Dialog>
  );
}
