import React, { useContext, useEffect, useRef } from "react";

import { Drawer, useTheme, useMediaQuery } from "@mui/material";

import { drawerContext } from "context/Drawer";
import { useStyles } from "styles/navigationDrawer";
import DrawerContent from "./DrawerContent";

/**
 * Left-hand side primary navigation menu
 * Changes to hamburger menu in mobile view
 */
export default function NavigationDrawer(props) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();
  const { mobileOpen, handleDrawerToggle } = useContext(drawerContext);
  const drawerRef = useRef(null);

  /**
   * Closes the drawer when a user taps off of it in mobile view
   */
  useEffect(() => {
    if (mobileOpen) {
      function handleClickOutside(event) {
        if (
          drawerRef.current &&
          event.target.getAttribute("data-id") !== "arrow-button"
        ) {
          handleDrawerToggle();
        }
      }
      document.addEventListener("touchstart", handleClickOutside);

      return () => {
        document.removeEventListener("touchstart", handleClickOutside);
      };
    }
  }, [drawerRef]);

  return (
      <Drawer
        variant={mobile ? "temporary" : "permanent"}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ref={drawerRef}
        ModalProps={{
          keepMounted: true,
        }}
        classes={{
          root: classes.drawer,
          paper: classes.paperDrawer,
        }}
        sx={{
          display: "block",
        }}
      >
        <DrawerContent
          props={props}
          handleDrawerToggle={handleDrawerToggle}
          isMobile={mobile}
          classes={classes}
        />
      </Drawer>
  );
}
