export const colors = {
  primary: "#479AE9",
};
//code 0
export const pastelTheme = {
  allSkedingUsers: "#CBADF8", //many users
  oneNonskedingMultipleSkedingUser: "#CBADF8",
  oneSkedingMultipleNonskedingUsers: "#CBADF8",
  multipleSkedingMultipleNonskedingUsers: "#CBADF8",
  singleSlotMeeting: "#CBADF8",

  oneNonskedingOneSkedingUser: "#95C3F2", //also for all skeding single user
  meetingThroughShareableCalendar: "#95C3F2",
  meetingThroughQrCode: "#95C3F2",

  meetingThroughWebIntegrationCalendar: "#DBF27E",
  todos: "#B0E4FF",
  recurringEvent: "#CBADF8",
  googleCalendarEvent: "#B1F2E6",
  outlookCalendarEvent: "#B1F2E6",
  searchEvent: "#F8E08C",
};
//code 1
export const vibrantTheme = {
  allSkedingUsers: "#B488F6", //many users
  oneNonskedingMultipleSkedingUser: "#B488F6",
  oneSkedingMultipleNonskedingUsers: "#B488F6",
  multipleSkedingMultipleNonskedingUsers: "#B488F6",
  singleSlotMeeting: "#B488F6",

  oneNonskedingOneSkedingUser: "#72B0EE", //also for all skeding single user
  meetingThroughShareableCalendar: "#72B0EE",
  meetingThroughQrCode: "#72B0EE",

  meetingThroughWebIntegrationCalendar: "#CFEE54",
  todos: "#96DCFF",
  recurringEvent: "#CBADF8",
  googleCalendarEvent: "#97EEDE",
  outlookCalendarEvent: "#97EEDE",
  searchEvent: "#F5D04F",
};

export const newTheme = {
  websiteBooking: "#DBF27E",
  tasks: "#B0E4FF",
  internalMeetings: "#95C3F2",
  externalMeetings: "#CBADF8",
  externalEvents: "#F8E08C",
  draft: "#c9e6f5",
};
