import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Box,
  Dialog,
  DialogActions,
  CircularProgress,
  IconButton,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Phone from "../../components/NewEvent/Phone";
import { isValidPhoneNumber } from "react-phone-number-input";
import ErrorSnackbar from "components/ErrorSnackbar";
import { useTranslation } from "react-i18next";
import puseStyle from "./styles";
import Textfield from "../AddToWebsite/Textfield";
import PhysicalLocation from "../NewEvent/PhysicalLocation";
import { accountContext } from "../../context/user";
/**
 * Allows someone creating a meeting through a Shareable meeting link to input their contact information
 */
export default function CreateShareableMeetingDialog({
  popup,
  setPopup,
  createMeeting,
  disabled,
  initiatorEmail,
  askUser,
  service,
}) {
  const handleClose = () => {
    setPopup(false);
  };

  return (
    <Dialog
      open={popup}
      onClose={handleClose}
      aria-labelledby="alert-sharebale"
      aria-describedby="alert-sharebale"
      PaperProps={{
        style: {
          borderRadius: "16px",
          padding: "16px 20px",
          boxSizing: "border-box",
          width: "490px",
        },
      }}
    >
      <BookingBody
        createMeeting={createMeeting}
        disabled={disabled}
        initiatorEmail={initiatorEmail}
        handleClose={handleClose}
        askUser={askUser}
        service={service}
      />
    </Dialog>
  );
}

export function BookingBody({
  createMeeting,
  disabled,
  initiatorEmail,
  handleClose,
  askUser,
  service,
  readOnly,
}) {
  const { t } = useTranslation();
  const [nameErrorMessage, setNameErrorMessage] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [emailMatched, setEmailMatched] = useState("");
  const [userData, setUserData] = useState({});
  const { user } = useContext(accountContext);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  console.log(" u sir", name);
  useEffect(() => {
    setEmail(!!user ? user.email : "");
    setName(!!user ? user.name : "");
  }, [user]);

  useEffect(() => {
    if (askUser && Object.keys(askUser).length > 0) {
      let data = { ...askUser };
      if (service?.questions?.length > 0) {
        const quest = [...service.questions];
        if (quest.length > 0) {
          setUserData({ ...data, questions: data?.questions.concat(quest) });
        }
      } else {
        setUserData(data);
      }
    }
  }, [askUser]);

  const handleChange = (e, index, maxLength) => {
    const val = typeof e === "string" ? e : e?.target?.value;
    let temp = [...userData?.questions];
    console.log("val", val);
    if (temp.length > 0) {
      if (val?.length > maxLength) {
        return;
      }
      temp[index].answer = val;
      setUserData({
        ...userData,
        questions: temp,
      });
    }
  };

  /**
   * Handles the form validation for the name and email of the person creating the meeting
   */
  const handleSubmit = async () => {
    switch (name.length !== 0 && email.length !== 0) {
      case true:
        setNameErrorMessage("");
        setEmailErrorMessage("");
      case false:
        if (name.length === 0) {
          setNameErrorMessage(t("enter_name"));
          return;
        }
        if (email.length === 0) {
          setEmailErrorMessage(t("enter_email"));
          return;
        }
    }

    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!regex.test(email)) {
      setEmailErrorMessage(t("invalid_email"));
      return;
    }
    if (email.toLowerCase() === initiatorEmail) {
      setEmailMatched(t("same_org_err"));
      return;
    }
    let questions = [];
    if (userData) {
      for (let i = 0; i < userData?.questions?.length; i++) {
        let question = userData?.questions[i];
        if (
          question.type_of_question === "number" &&
          !isValidPhoneNumber(question?.answer ? question.answer : "") &&
          question.is_required
        ) {
          setEmailMatched("Please enter correct number");
          return;
        }
        if (question.is_required) {
          if (!question.answer?.trim()) {
            setEmailMatched("Please fill mandatory questions");
            return;
          }

          questions.push({ question: question.label, answer: question.answer });

          continue;
        }
        if (!question.answer?.trim()) {
          questions.push({ question: question.label, answer: question.answer });
        }
        if (question.answer?.trim()) {
          questions.push({ question: question.label, answer: question.answer });
        }
      }
      createMeeting(name, email, questions);
    } else {
      createMeeting(name, email);
    }
    setName("");
    setEmail("");
  };

  const handleName = (e) => {
    setName(e.target.value);
    setNameErrorMessage("");
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
    setEmailErrorMessage("");
  };

  return (
    <>
      <Box>
        <Box display="flex" justifyContent="flex-end" style={{ width: "100%" }}>
          <IconButton sx={{ justifyContent: "flex-end" }} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography
          variant={readOnly ? "h3" : "h2"}
          textAlign={"center"}
          mb={1}
        >
          {t("add_details")}
        </Typography>
        <Box display="flex" flexDirection={"column"} gap={2}>
          <Textfield
            label={t("name")}
            value={name}
            handleChange={handleName}
            placeholder={t("enter_name")}
            isBorder={false}
            disabled={!!user}
            name={"name"}
            error={nameErrorMessage}
            readOnly={readOnly}
          />

          <Textfield
            label={t("email")}
            value={email}
            handleChange={handleEmail}
            placeholder={t("enter_email")}
            isBorder={false}
            name={"email"}
            error={emailErrorMessage}
            readOnly={readOnly}
            disabled={!!user}
          />
          {/* {askUser && askUser?.phone_number && ( */}
          {/* <Textfield
            label={t("phone")}
            value={email}
            handleChange={handleEmail}
            placeholder={t("enter_phone")}
            isBorder={false}
            name={"phone"}
            error={emailErrorMessage}
            readOnly={readOnly}
          /> */}
          {/* )} */}
          {userData &&
            userData?.questions?.length > 0 &&
            userData?.questions?.map((question, index) => (
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  width: "100%",
                }}
              >
                <QuestionAnswerBox
                  key={index}
                  title={question.label}
                  type={`${question.type_of_question.trim()}`}
                  checked={question.is_required}
                  name="questions"
                  value={question.answer}
                  options={question.options}
                  handleChange={(e) =>
                    handleChange(e, index, question.max_characters)
                  }
                />
              </Box>
            ))}
        </Box>
      </Box>
      <DialogActions
        sx={{
          flexDirection: "column",
        }}
      >
        <Box sx={{ display: "flex", mt: "20px" }}>
          <Button onClick={handleClose} variant="outlined">
            {t("cancel")}
          </Button>
          <span style={{ width: "40px" }} />
          <Button
            variant="contained"
            onClick={() => {
              if (!readOnly) handleSubmit();
            }}
            disabled={disabled}
          >
            {disabled ? (
              <CircularProgress style={{ color: "white" }} size={20} />
            ) : (
              t("proceed")
            )}
          </Button>
        </Box>
      </DialogActions>
      <ErrorSnackbar
        open={!!emailMatched}
        handleClose={() => setEmailMatched("")}
        message={emailMatched}
        vertical={"bottom"}
        horizontal={"center"}
      />
    </>
  );
}

function QuestionAnswerBox({ title, type, value, options, handleChange }) {
  const classes = puseStyle();
  const [phone, setPhone] = useState("");

  return type === "long" ? (
    <Box
      style={{
        width: "100%",
      }}
    >
      <Textfield
        label={title}
        value={value}
        handleChange={handleChange}
        placeholder={"Write your answer here"}
        isBorder={false}
        name={"label"}
        readOnly={false}
      />
    </Box>
  ) : type === "checkbox" ? (
    <FormControl>
      <Typography variant="h4" align="left">
        {title}
      </Typography>
      <RadioGroup
        aria-labelledby="radio-buttons-group"
        name="calendars"
        value={value}
        onChange={handleChange}
      >
        {options &&
          options.map((option) => (
            <FormControlLabel
              value={option}
              control={<Radio className={classes.radio} />}
              label={
                <Box alignItems="center">
                  <Typography
                    variant="h3"
                    fontWeight="400"
                    className={classes.themeText}
                  >
                    {option}
                  </Typography>
                </Box>
              }
            />
          ))}
      </RadioGroup>
    </FormControl>
  ) : type === "address" ? (
    <Box className={classes.detailsQuestionDisplay}>
      <Typography variant="h4">{title}</Typography>
      <PhysicalLocation address={value} setAddress={handleChange} />
    </Box>
  ) : type === "number" ? (
    <Box className={classes.detailsQuestionDisplay}>
      <Typography variant="h4">{title}</Typography>
      <Phone
        setPhone={handleChange}
        phone={phone}
        isDisabled={undefined}
        showBorder={false}
      />
    </Box>
  ) : null;
}
