import React from "react";
import useStyle from "../styles";
import { Box, Typography, useTheme } from "@mui/material";

export default function EmailPreview({ body, subject, signature, files }) {
  const classes = useStyle();
  const theme = useTheme();

  return (
    <div className={classes.servicePreview}>
      <Box style={{ width: "90%", textAlign: "left" }}>
        <Typography variant="h2" align="center">
          {subject}
        </Typography>
        <Typography
          variant="h3"
          align="left"
          paragraph
          style={{ whiteSpace: "pre-line", marginTop: "8px" }}
        >
          {`${body}`}
        </Typography>

        <Typography variant="h3" align="left" style={{ marginTop: "8px" }}>
          {signature}
        </Typography>

        {files?.map((file, i) => {
          return (
            <img
              src={file}
              style={{
                background: theme.palette.secondary.light,
                overflow: "hidden",
                textOverflow: "ellipses",
                width: "160px",
                borderRadius: "4px",
                height: "auto",
              }}
            />
          );
        })}
      </Box>
    </div>
  );
}
