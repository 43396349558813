import React from "react";
import { Grid, TextField, Typography, useTheme } from "@mui/material";
import useStyle from "../../styles/formStyles";
import puseStyle from "./styles";

export default function Textfield({
  label,
  value,
  handleChange,
  placeholder,
  isBorder,
  name,
  error,
  readOnly,
  multiline,
  disabled,
}: {
  label?: string;
  value: string | number;
  handleChange?: any;
  placeholder: string;
  isBorder?: boolean;
  name?: string;
  error?: string;
  type?: string;
  readOnly?: string;
  multiline?: boolean;
  disabled?: boolean;
}) {
  const theme = useTheme();
  const classes = useStyle();
  const pclasses = puseStyle();

  return (
    <div className={isBorder ? "" : classes.root}>
      <Grid container rowGap={1}>
        {label && (
          <Grid item xs={12}>
            <Typography variant="h4" align="left" style={{}}>
              {label}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <TextField
            placeholder={placeholder}
            variant="standard"
            autoComplete="off"
            value={value}
            sx={{ width: "100%" }}
            autoFocus
            name={name}
            disabled={disabled}
            InputProps={{
              disableUnderline: true,
              readOnly: !!readOnly,
              style: {
                color: theme.palette.text.primary,
              },
              classes: {
                input: isBorder
                  ? `${classes.input} ${pclasses.borderInput}`
                  : `${classes.input}`,
              },
            }}
            onChange={handleChange}
            error={!!error}
            helperText={error}
            multiline={multiline}
            rows={multiline ? 5 : undefined}
          />
        </Grid>
      </Grid>
    </div>
  );
}
