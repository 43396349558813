//DASHBOARD
module.exports.GET_ALL_USER_MEETINGS_PATH = "/meeting";

//PREFERENCES
module.exports.GET_USER_PREFERENCES_PATH = "/preference";
module.exports.UPDATE_USER_PREFERENCES_PATH = "/preference";

//Login and registration
//fraz-test
// module.exports.GOOGLE_LOGIN_URL = "https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?access_type=offline&prompt=consent&response_type=code&redirect_uri=http%3A%2F%2Fskeding-fraz-test.s3-website-us-east-1.amazonaws.com%2Fgoogle%2Fcallback&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.events&client_id=691898293376-393kc1db3hm2gblt2qgh5mp3mcofmbdp.apps.googleusercontent.com&flowName=GeneralOAuthFlow"
//http skeding.xyz
// module.exports.GOOGLE_LOGIN_URL = "https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?access_type=offline&prompt=consent&response_type=code&redirect_uri=http%3A%2F%2Fskeding.xyz%2Fgoogle%2Fcallback&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.events&client_id=691898293376-393kc1db3hm2gblt2qgh5mp3mcofmbdp.apps.googleusercontent.com&flowName=GeneralOAuthFlow"
//https skeding.xyz
// module.exports.GOOGLE_LOGIN_URL =
// "https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?access_type=offline&prompt=consent&response_type=code&redirect_uri=https%3A%2F%2Fskeding.xyz%2Fgoogle%2Fcallback&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.events&client_id=691898293376-393kc1db3hm2gblt2qgh5mp3mcofmbdp.apps.googleusercontent.com&flowName=GeneralOAuthFlow";
//localhost 3000
// module.exports.GOOGLE_LOGIN_URL =
//   "https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?access_type=offline&prompt=consent&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fgoogle%2Fcallback&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.events&client_id=691898293376-393kc1db3hm2gblt2qgh5mp3mcofmbdp.apps.googleusercontent.com&flowName=GeneralOAuthFlow";

const secretId = "df7bd0d1-a8ee-432c-9181-798a4a62eaf0";
const key = "qiy8Q~xDG2uXCNjlBLm4m1Qgwyykd3xw8Yeelbp9";

//localhost 3000
// module.exports.OUTLOOK_LOGIN_URL =
// "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?response_type=code&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Foutlook%2Fcallback&client_id=b911a6b2-5429-4ec2-98f3-628970f635f4&scope=user.read%20calendars.ReadWrite%20offline_access";
//http skeding.xyz
// module.exports.OUTLOOK_LOGIN_URL = "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?response_type=code&redirect_uri=http%3A%2F%2Fskeding.xyz%2Foutlook%2Fcallback&client_id=96009f13-e497-4f0d-85cc-2202556b4ca2&scope=user.read%20calendars.ReadWrite%20offline_access"
//https skeding.xyz
// module.exports.OUTLOOK_LOGIN_URL ="https://login.microsoftonline.com/common/oauth2/v2.0/authorize?response_type=code&redirect_uri=https%3A%2F%2Fskeding.xyz%2Foutlook%2Fcallback&client_id=b911a6b2-5429-4ec2-98f3-628970f635f4&scope=user.read%20calendars.ReadWrite%20offline_access";
//fraz-test
// module.exports.OUTLOOK_LOGIN_URL = "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?response_type=code&redirect_uri=http%3A%2F%2Fskeding-fraz-test.s3-website-us-east-1.amazonaws.com%2Foutlook%2Fcallback&client_id=96009f13-e497-4f0d-85cc-2202556b4ca2&scope=user.read%20calendars.ReadWrite%20offline_access"

//localhost 3000
// module.exports.ZOOM_LOGIN_URL =
// "https://zoom.us/oauth/authorize?client_id=3NNQnZ7hTcSm5o3Oqk5vbA&response_type=code&redirect_uri=https%3A%2F%2F04ff-180-178-190-107.in.ngrok.io%2Fzoom%2Fcallback";
//https:skeding.xyz
// module.exports.ZOOM_LOGIN_URL ="https://zoom.us/oauth/authorize?client_id=3NNQnZ7hTcSm5o3Oqk5vbA&response_type=code&redirect_uri=https%3A%2F%2Fskeding.xyz%2Fzoom%2Fcallback";

//localhost 3000
// module.exports.TEAMS_LOGIN_URL =
// "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?response_type=code&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Foutlook%2Fteams%2Fcallback&client_id=1326f7b7-6dfc-498b-b1c2-a0ddedf5390c&scope=user.read%20calendars.ReadWrite%20offline_access";
//https:skeding.xyz
// module.exports.TEAMS_LOGIN_URL ="https://login.microsoftonline.com/common/oauth2/v2.0/authorize?response_type=code&redirect_uri=https%3A%2F%2Fskeding.xyz%2Foutlook%2Fteams%2Fcallback&client_id=1326f7b7-6dfc-498b-b1c2-a0ddedf5390c&scope=user.read%20calendars.ReadWrite%20offline_access";

//https skeding.eu
// module.exports.APPLE_LOGIN_URL ="https://appleid.apple.com/auth/authorize?response_type=code%20id_token&response_mode=form_post&client_id=com.awsservice.skeding&redirect_uri=https://skeding.eu/api/v1/apple/callback&scope=name%20email";
//local 5000
// module.exports.APPLE_LOGIN_URL = "https://appleid.apple.com/auth/authorize?response_type=code%20id_token&response_mode=form_post&client_id=com.awsservice.skeding&redirect_uri=https://68ee-180-178-190-108.in.ngrok.io/api/v1/apple/callback&scope=name%20email";

//socket
//localhost
// module.exports.SOCKET_URL = "http://localhost:5000";
//skeding.eu
// module.exports.SOCKET_URL = "https://skeding.eu";
