import React, { useCallback } from "react";

import {
  FormControl,
  MenuItem,
  Select,
  useTheme,
  Slider,
  Box,
} from "@mui/material";
import moment from "moment";
import { useTranslation } from "react-i18next";
import useSuggestionsStore from "../../Utils/suggestionsStore";

/**
 * The dropdown for selecting the start time offset on the new meeting page
 */

const valueToLable = {
  33: "15",
  66: "30",
  100: "45",
  0: "00",
};

const labelToValue = {
  15: "33",
  30: "66",
  45: "100",
  0: "0",
};

function StartTime(props) {
  const theme = useTheme();
  const { t } = useTranslation();
  const { startTime } = props;
  // const startTime = useSuggestionsStore((state) => state.startTime);

  function valuetext(value) {
    return `${value}`;
  }
  const checkTime = useCallback(
    (minutes) => {
      const current_date = new Date();
      if (props.timeInterval === "custom" && props.customTime !== "") {
        current_date.setMinutes(parseInt(minutes));
        if (current_date < new Date()) {
          let hours = current_date.getHours();
          current_date.setHours(hours + 1);
        }
        return (
          moment(current_date, "hh").format("HH:mm") +
          " - " +
          moment(current_date, "hh")
            .add(moment.duration(props.customTime).asMinutes(), "minutes")
            .format("HH:mm")
        );
      } else if (props.timeInterval && props.timeInterval !== "custom") {
        current_date.setMinutes(parseInt(minutes));
        if (current_date < new Date()) {
          let hours = current_date.getHours();
          current_date.setHours(hours + 1);
        }
        return (
          moment(current_date, "hh").format("HH:mm") +
          " - " +
          moment(current_date, "hh")
            .add(moment.duration(props.timeInterval).asMinutes(), "minutes")
            .format("HH:mm")
        );
      }
    },
    [props.timeInterval, props.customTime]
  );

  const marks = [
    {
      value: 0,
      label: `00`,
    },
    {
      value: 33,
      label: "15",
    },
    {
      value: 66,
      label: "30",
    },
    {
      value: 100,
      label: "45",
    },
  ];

  return (
    <Slider
      aria-label="Custom marks"
      value={labelToValue[startTime]}
      getAriaValueText={valuetext}
      step={null}
      valueLabelDisplay="off"
      marks={marks}
      size="small"
      style={{ marginLeft: 20, width: "75%", marginRight: 20 }}
      onChange={(e) => props.handleStartTime(valueToLable[e.target.value])}
    />

    // <FormControl
    //   variant="standard"
    //   // sx={{ width: "100%" }}
    //   style={{ marginTop: 10 }}
    // >
    //   <Select
    //     labelId="start-interval-label"
    //     id="start-time-interval"
    //     disableUnderline={true}
    //     value={props.startTime}
    //     // IconComponent={"div"}
    //     onChange={props.handleStartTime}
    //     style={{
    //       textAlign: "left",
    //       fontSize: 16,
    //       fontWeight: 400,
    //       color: theme.palette.text.primary,
    //     }}
    //     displayEmpty={true}
    //   >
    //     <MenuItem value="">{t("start_time")}</MenuItem>
    //     <MenuItem value={"00"}>
    //       00{" "}
    //       <span
    //         style={{ color: "#a3a1a1", marginLeft: "8px", fontSize: "10px" }}
    //       >
    //         {checkTime("00")}
    //       </span>
    //     </MenuItem>
    //     <MenuItem value={"15"}>
    //       15
    //       <span
    //         style={{ color: "#a3a1a1", marginLeft: "8px", fontSize: "10px" }}
    //       >
    //         {" "}
    //         {checkTime("15")}
    //       </span>
    //     </MenuItem>
    //     <MenuItem value={"30"}>
    //       30
    //       <span
    //         style={{ color: "#a3a1a1", marginLeft: "8px", fontSize: "10px" }}
    //       >
    //         {checkTime("30")}
    //       </span>{" "}
    //     </MenuItem>
    //     <MenuItem value={"45"}>
    //       45{" "}
    //       <span
    //         style={{ color: "#a3a1a1", marginLeft: "8px", fontSize: "10px" }}
    //       >
    //         {checkTime("45")}
    //       </span>
    //     </MenuItem>
    //   </Select>
    // </FormControl>
  );
}

export default React.memo(StartTime);
