import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import BookingDetails from "../PublicCalendar/BookingDetails";
import SkedingBanner from "../SkedingBanner";
import useStyle from "./styles";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import BreadCrumb from "./BreadCrumb";
import useWebsiteClientStore from "../../Utils/websiteClientStore";

export default function ChooseLocation({
  locations,
  client = true,
  description,
  skeding_user_id,
  title,
  category,
}) {
  const { picture } = skeding_user_id;
  const { t } = useTranslation();
  const classes = useStyle();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  const setSelectedLocation = useWebsiteClientStore(
    (state) => state.setSelectedLocation
  );

  return (
    <Box
      className={classes.cientSideContainer}
      style={{ minHeight: client ? "100vh" : "100%", width: "90%" }}
    >
      <Grid container columnSpacing={3}>
        <Grid
          item
          xs={12}
          md={4}
          style={{
            backgroundColor: mobile ? "" : "#F7F7F9",
            borderRadius: 6,
            // height: "100vh",
          }}
          pb={2}
        >
          <BookingDetails
            heading={t("book_appointment")}
            firstName={""}
            lastName={""}
            phone={undefined}
            email={undefined}
            title={title}
            duration={null}
            location={undefined}
            agenda={description}
            profile={picture}
            roomNo={undefined}
            showLng={true}
            polling={false}
            fileName={undefined}
            eventId={undefined}
            participants={[]}
            isOrganizer={!client}
            isAddToWebsite={true}
            category={category}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <Box className={classes.questionsBox}>
            <Box mb={2}>
              <BreadCrumb selected={0} isClient={client} />
            </Box>
            <Typography variant={client ? "h3" : "h5"} align="left">
              {t("choose_location")}
            </Typography>
            {locations?.length > 0 ? (
              <Grid container rowSpacing={"20px"} columnSpacing={"20px"}>
                {locations.map((location) => (
                  <Grid item xs={12} sm={6} key={location._id}>
                    <Container maxWidth="xs" className={classes.locationCard}>
                      <Box display={"flex"}>
                        <Typography
                          variant={client ? "h3" : "h5"}
                          fontWeight="500"
                          align="left"
                          style={{
                            textTransform: "capitalize",
                          }}
                        >
                          {location.name}
                        </Typography>
                      </Box>
                      <Box className={classes.iconTextBox}>
                        <LocationOnOutlinedIcon
                          style={{
                            width: "14px",
                            color: theme.palette.text.primary,
                          }}
                        />
                        <Typography
                          variant={client ? "h3" : "h5"}
                          className={classes.textWrap}
                        >
                          {location.locationType}
                        </Typography>
                      </Box>

                      <Box className={classes.iconTextBox}>
                        <PhoneOutlinedIcon
                          style={{
                            width: "14px",
                            color: theme.palette.text.primary,
                          }}
                        />
                        <Typography
                          variant={client ? "h3" : "h5"}
                          className={classes.textWrap}
                        >
                          {location.phone_number}
                        </Typography>
                      </Box>
                      <Box className={classes.iconTextBox}>
                        <EmailOutlinedIcon
                          style={{
                            width: "14px",
                            color: theme.palette.text.primary,
                          }}
                        />
                        <Typography
                          variant={client ? "h3" : "h5"}
                          className={classes.textWrap}
                        >
                          {location.email}
                        </Typography>
                      </Box>

                      <Box>
                        <Button
                          variant="contained"
                          style={{ fontSize: !client ? 10 : "" }}
                          onClick={() => setSelectedLocation(location)}
                        >
                          {t("select")}
                        </Button>
                      </Box>
                    </Container>
                  </Grid>
                ))}{" "}
              </Grid>
            ) : (
              <Typography variant="h5" fontWeight={400}>
                {t("no_location")}
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>
      <SkedingBanner removePadding={false} />
    </Box>
  );
}
