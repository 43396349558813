import { useState, useContext, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import {
  Button,
  Grid,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { handleTimeZone } from "timeZone";
import DialogBox from "components/DialogBox";
import { authContext } from "context/Auth";
import { serverPOSTWithToken } from "Utils/HttpFunctions";
import {
  convertDateToUTCMilliseconds,
  convertUTCMillisecondsToDate,
} from "Utils/CommonFunctions";
import { ParticipantsComponent } from "components/NewEvent/EventData";
import FreeSlotCalendar from "components/NewEvent/FreeSlotCalendar";
import loggingInDev from "loggingInDev";
import { useRef } from "react";
import BookingDetails from "components/PublicCalendar/BookingDetails";
import Polling from "components/NewEvent/Polling";
import moment from "moment";
import RejectComment from "components/RejectComment";
import AcceptComment from "components/AcceptComment";
import SnackbarComponent from "../components/Global/SnackbarComponent";
import PollingTimes from "../components/NewEvent/PollingTimes";
import { useTranslation } from "react-i18next";
import ErrorSnackbar from "../components/ErrorSnackbar";

export default function MultiNonSignUp({ meetingData }) {
  const { duration_minutes, slots_data } = meetingData;
  const [events, setEvents] = useState([]);
  const { isLogged } = useContext(authContext);
  const [searchParams] = useSearchParams();
  const [errorAmount, setErrorAmount] = useState("");
  const [selectedSlot, setSelectedSlot] = useState([]);
  const token = searchParams.get("token");
  const [modal, setModal] = useState(true);
  const [respondedModal, setRespondedModal] = useState(false);
  const [skedingUsers, setSkedingUsers] = useState([]);
  const [messageModal, setMessageModal] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate();
  const calendarRef = useRef(null);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const [eventDays, setEventDays] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [rejectModal, setRejectModal] = useState(false);
  const [acceptModal, setAcceptModal] = useState(false);
  const [selectedTimes, setSelectedTimes] = useState({ events: [] });
  const ref = useRef();
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    changeSlots();
  }, [meetingData]);

  useEffect(() => {
    if (selectedSlot.length > 0 && !mobile) {
      ref.current.scrollIntoView({ inline: "start", behavior: "smooth" });
    }
  }, [selectedSlot]);

  useEffect(() => {
    if (meetingData) {
      var res = []
        .concat(...meetingData.skeding_participants)
        .map((user) => user?.email);
    }
    setSkedingUsers(res);
  }, [events]);

  useEffect(() => {
    if (token) {
      const email = meetingData.current_email;
      if (
        (!meetingData.non_skeding_participants_not_responded.includes(email) &&
          meetingData?.non_skeding_participants.includes(email)) ||
        meetingData?.start_datetime
      ) {
        setRespondedModal(true);
        setModal(false);
      }
    }
  }, [meetingData]);

  useEffect(() => {
    if (isLogged) {
      setModal(true);
    }
  }, [isLogged]);

  useEffect(() => {
    if (events) {
      getUniqueDays();
    }
  }, [events]);

  const changeSlots = async () => {
    const parseResponse = await slots_data.map((item) => {
      return {
        start: convertUTCMillisecondsToDate(item.slotStart),
        end: convertUTCMillisecondsToDate(item.slotEnd),
        availableParticipants: item.availableParticipantsData,
        unAvailableParticipants: item.unavailableParticipantsData,
      };
    });

    setEvents(parseResponse);
  };

  const getUniqueDays = async () => {
    const parsedDays = await events.map((slot) => {
      let start = convertUTCMillisecondsToDate(slot.start);
      return {
        start:
          start.getFullYear() +
          "-" +
          (start.getMonth() + 1 < 10
            ? "0" + (start.getMonth() + 1)
            : start.getMonth() + 1) +
          "-" +
          (start.getDate() < 10 ? "0" + start.getDate() : start.getDate()),
      };
    });
    const uniqueDays = parsedDays.filter(
      (v, i, a) => a.findIndex((v2) => v2.start === v.start) === i
    );
    setSelectedDate(uniqueDays.length > 0 ? uniqueDays[0]?.start : "");
    setEventDays(
      uniqueDays.length > 0
        ? uniqueDays.sort((a, b) => new Date(a.start) - new Date(b.start))
        : []
    );
  };

  const handleClose = () => {
    setModal(false);
  };

  const updateMeetingPoll = async (notes) => {
    if (selectedSlot.length < 1) {
      setErrorAmount("You need to select at least one slot");
      setErrorMessage("You need to select at least one slot");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return;
    }
    setDisabled(true);

    const preferred_timeslots =
      selectedSlot &&
      selectedSlot.map((slot) => {
        return {
          startTime: convertDateToUTCMilliseconds(slot.start),
          endTime: convertDateToUTCMilliseconds(slot.end),
        };
      });

    if (preferred_timeslots.length > 0) {
      const response = await serverPOSTWithToken(
        "/meeting/meetingpoll",
        { preferred_timeslots: preferred_timeslots, notes: notes },
        token
      );
      if (response && response.constructor !== String) {
        // clearForm();
        loggingInDev("updated multi case");
        setMessageModal(true);
      } else {
        loggingInDev(response);
      }
    }

    setDisabled(false);
  };

  const rejectMeetingPoll = async (comment) => {
    setDisabled(true);

    const response = await serverPOSTWithToken(
      "/meeting/meetingpoll",
      {
        preferred_timeslots: [],
        comment: comment.trim() ? comment : undefined,
      },
      token
    );
    if (response && response.constructor !== String) {
      loggingInDev("Response Reject");
      setMessageModal(true);
      setDisabled(false);
    } else {
      loggingInDev(response);
      setDisabled(false);
    }
  };

  const handleRejectConfirm = () => {
    setRejectModal((prev) => !prev);
  };

  const handleAcceptConfirm = () => {
    setAcceptModal((prev) => !prev);
  };

  return (
    <Grid
      container
      justifyContent="center"
      style={{
        margin: "auto",
        width: isLogged ? "100%" : "90%",
        height: "100vh",
        maxHeight: "1200px",
        display: "flex",
        justifyContent: "center",
        alignItems: isLogged ? "flex-start" : "center",
      }}
    >
      {modal && !isLogged && (
        <DialogBox
          modal={modal}
          handleClose={handleClose}
          closeMsg={"Okay"}
          msg={t("polling_choice")}
          close={true}
        />
      )}
      {isLogged && (
        <SnackbarComponent
          open={modal}
          handleClose={handleClose}
          message={t("loggedin_polling")}
          vertical={"bottom"}
          horizontal={"center"}
        />
      )}
      {respondedModal && (
        <DialogBox
          modal={respondedModal}
          handleClose={handleClose}
          msg={t("already_responded")}
          close={false}
          nonsignup={true}
        />
      )}
      {messageModal && (
        <SnackbarComponent
          open={messageModal}
          handleClose={() => navigate("/register")}
          message={t("response_recorded")}
          vertical={"bottom"}
          horizontal={"center"}
        />
      )}
      <Grid container>
        {/* <SkedingBanner /> */}
        <Grid
          item
          xs={12}
          md={4}
          style={{
            display: "flex",
            flexDirection: "column",
            maxHeight: "85vh",
            overflow: "auto",
          }}
        >
          <Box flexGrow={1} style={{ overflow: "auto" }}>
            <BookingDetails
              heading={t("polling_meeting_invite")}
              firstName={meetingData?.initiator_firstname}
              lastName={meetingData?.initiator_lastname}
              email={meetingData?.initiator_email}
              title={meetingData?.title}
              duration={meetingData?.duration_minutes}
              location={meetingData?.location}
              agenda={meetingData?.description}
              fileName={meetingData?.attachment_file_name}
              eventId={meetingData?._id}
              participants={skedingUsers.concat(
                meetingData.non_skeding_participants
              )}
              profile={meetingData?.initiator_profile_pic}
              roomNo={meetingData?.meeting_room}
              polling={true}
              showLng={true}
            />

            {!mobile && (
              <Box mb={1} mt={3} ref={ref}>
                <Typography
                  align="left"
                  variant="h2"
                  style={{ fontWeight: 500 }}
                >
                  {t("avail_dates")}
                </Typography>
                <Typography
                  variant="h4"
                  align="left"
                  fontWeight={400}
                  style={{ color: "#70757A" }}
                >
                  {t("avail_dates_desc")}
                </Typography>

                <Box
                  display={"flex"}
                  style={{
                    width: "100%",
                    overflowX: "auto",
                    paddingBottom: "12px",
                  }}
                  mt={1}
                >
                  {eventDays.map((date, i) => (
                    <Box
                      key={i}
                      ml={i ? 2 : 0}
                      style={{
                        minWidth: "48px",
                        height: "48px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "50%",
                        cursor: "pointer",
                        background:
                          selectedDate ===
                            moment(date.start).format("YYYY-MM-DD")
                            ? "#72B0EE"
                            : "#F7F7F9",
                      }}
                      onClick={() => setSelectedDate(date.start)}
                    >
                      <Typography
                        variant="h4"
                        align="center"
                        fontWeight={"500"}
                      >
                        {moment(date.start).format("dddd").substring(0, 3)}
                        <br />
                        {moment(date.start).date()}
                      </Typography>
                    </Box>
                  ))}
                </Box>

                <PollingTimes
                  eventCase={meetingData?.case}
                  selectedSlot={selectedSlot}
                  setSelectedSlot={setSelectedSlot}
                  errorAmount={errorAmount}
                  setErrorAmount={setErrorAmount}
                  selectedTimes={selectedTimes}
                  setSelectedTimes={setSelectedTimes}
                  isNonSkedin={true}
                />
              </Box>
            )}

            {/* <Grid
              item
              xs={12}
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              {events && (
                <ShowTimes
                  events={events}
                  eventCase={meetingData?.case}
                  selectedSlot={selectedSlot}
                  setSelectedSlot={setSelectedSlot}
                  calendarRef={calendarRef}
                  // date={date}
                  // setDate={setDate}
                  // month={month}
                  // setMonth={setMonth}
                  // year={year}
                  // setYear={setYear}
                  // monthInt={monthInt}
                  // setMonthInt={setMonthInt}
                  // dateObject={dateObject}
                  // selectedDate={selectedDate}

                  // qrMeeting={qrMeeting}
                />
              )}
            </Grid> */}
          </Box>

          <Grid
            container
            justifyContent="flex-start"
            style={{ marginBottom: 10, marginTop: 30 }}
            sx={{ display: { xs: "none", md: "flex" } }}
          >
            {isLogged ? null : (
              <Button
                variant="outlined"
                sx={{ mr: "20px" }}
                onClick={handleRejectConfirm}
              >
                {t("cancel")}
              </Button>
            )}

            <Button
              variant="contained"
              onClick={
                isLogged ? () => navigate("/dashboard") : handleAcceptConfirm
              }
              disabled={disabled}
            >
              {t("proceed")}
            </Button>
          </Grid>
        </Grid>

        <Grid item xs={12} md={8} sx={{ marginTop: { xs: "24px", md: 0 } }}>
          <>
            {events.length > 0 && !mobile && (
              <Box style={{ marginLeft: 20, marginBottom: "14px" }}>
                <Typography variant="h2" align="left" fontWeight="500">
                  {t("recommended_timslots_title")}
                </Typography>
                <Typography variant="h4" align="left" fontWeight={400}>
                  {t("recommended_timslots_desc")}
                </Typography>
                <Typography
                  variant="body2"
                  align="left"
                  sx={{ display: { xs: "block", md: "none" } }}
                  style={{ color: "#70757A" }}
                >
                  {t("tap_msg")}
                </Typography>
                {errorAmount && (
                  <Typography
                    style={{ color: "red" }}
                    variant="body2"
                    align="left"
                  >
                    {errorAmount}
                  </Typography>
                )}
              </Box>
            )}
            {events.length > 0 ? (
              mobile ? (
                <Polling
                  timeInterval={
                    Math.floor(duration_minutes / 60) +
                    ":" +
                    ((duration_minutes % 60) + ":" + "00").toString()
                  }
                  events={events && events}
                  eventCase={meetingData?.case}
                  selectedSlot={selectedSlot}
                  setSelectedSlot={setSelectedSlot}
                  participants={skedingUsers.concat(
                    meetingData.non_skeding_participants
                  )}
                  errorAmount={errorAmount}
                  setErrorAmount={setErrorAmount}
                  showMsg={false}
                  newMeeting={false}
                />
              ) : (
                <FreeSlotCalendar
                  timeInterval={
                    Math.floor(duration_minutes / 60) +
                    ":" +
                    ((duration_minutes % 60) + ":" + "00").toString()
                  }
                  events={events}
                  eventCase={meetingData?.case}
                  selectedSlot={selectedSlot}
                  setSelectedSlot={setSelectedSlot}
                  participants={skedingUsers.concat(
                    meetingData.non_skeding_participants
                  )}
                  popover={false}
                  initiatorResponse={false}
                  isLogged={isLogged}
                  calendarRef={calendarRef}
                  selectedDate={selectedDate}
                  selectedTimes={selectedTimes}
                  setSelectedTimes={setSelectedTimes}
                />
              )
            ) : null}
          </>
        </Grid>

        <Grid
          container
          justifyContent="center"
          style={{ marginBottom: 30, marginTop: 30 }}
          sx={{ display: { xs: "flex", md: "none" } }}
        >
          <Button
            variant="outlined"
            sx={{ mr: "20px" }}
            onClick={handleRejectConfirm}
          >
            {t("cancel")}
          </Button>

          <Button
            variant="contained"
            onClick={
              isLogged ? () => navigate("/dashboard") : handleAcceptConfirm
            }
            disabled={disabled}
          >
            {t("proceed")}
          </Button>
        </Grid>
        <RejectComment
          rejectConfirm={rejectModal}
          handleRejectConfirm={handleRejectConfirm}
          handleReject={rejectMeetingPoll}
          loading={disabled}

        />
        <AcceptComment
          acceptConfirm={acceptModal}
          handleAcceptConfirm={handleAcceptConfirm}
          handleAccept={updateMeetingPoll}
          loading={disabled}
        />
      </Grid>
      <ErrorSnackbar
        open={!!errorMessage}
        handleClose={() => setErrorMessage("")}
        message={errorMessage}
        vertical={"bottom"}
        duration={3000}
        horizontal={"center"}
      />
    </Grid>
  );
}
