import { isValidPhoneNumber } from "react-phone-number-input";
import { create } from "zustand";
import { serverPOST, serverPUT } from "./HttpFunctions";

const useQRCodeStore = create((set) => ({
  formData: {
    name: "",
    email: [{ value: "", is_hidden: false }],
    mobile_phone: [{ value: "", is_hidden: false }],
    personal_website: { value: "", is_hidden: false },
    street: { value: "", is_hidden: false },
    city: { value: "", is_hidden: false },
    state: { value: "", is_hidden: false },
    postal_code: { value: "", is_hidden: false },
    country: { value: "", is_hidden: false },
    company_name: { value: "", is_hidden: false },
    company_role: { value: "", is_hidden: false },
    fb_link: { value: "", is_hidden: false },
    linkedin_link: { value: "", is_hidden: false },
    insta_link: { value: "", is_hidden: false },
    twitter_link: { value: "", is_hidden: false },
    youtube_link: { value: "", is_hidden: false },
    snapchat_link: { value: "", is_hidden: false },
    tiktok_link: { value: "", is_hidden: false },
    shopify_link: { value: "", is_hidden: false },
    user_id: { _id: "", picture: "" },
  },
  setFormData: (newFormData) => set({ formData: newFormData }),
  emailList: [],
  setEmailList: (emailList) => set({ emailList }),
  phoneNumberList: [],
  setPhoneNumberList: (phoneNumberList) => set({ phoneNumberList }),
  selectedSocials: [],
  setSelectedSocials: (selectedSocials) => set({ selectedSocials }),
  error: "",
  setError: (error) => set({ error }),
  linkCopiedSuccess: "",
  setLinkCopiedSuccess: (linkCopiedSuccess) => set({ linkCopiedSuccess }),
  saveMessage: "",
  setSaveMessage: (setSaveMessage) => set({ setSaveMessage }),

  handleChange: (e) => {
    set((state) => {
      const { name, value } = e.target;

      if (name === "name") {
        return { formData: { ...state.formData, [name]: value } };
      } else {
        return {
          formData: {
            ...state.formData,
            [name]: { ...state.formData[name], value: value },
          },
        };
      }
    });
  },

  handleEmailList: (e, index) => {
    set((state) => {
      const temp_list = [...state.emailList];
      const current_email = temp_list[index];
      current_email.value = e.target?.value;
      return { emailList: temp_list };
    });
  },

  handleEmailDelete: (i) => {
    set((state) => {
      let emailListTemp = [...state.emailList];
      emailListTemp.splice(i, 1);
      return { emailList: emailListTemp };
    });
  },

  handlePhoneNumberList: (val, index, colName) => {
    set((state) => {
      let temp_list = [...state.phoneNumberList];
      let current_number = temp_list[index];
      current_number[colName] = val;
      return { phoneNumberList: temp_list };
    });
  },

  handleSnackbar: (i) => {
    set((state) => {
      return { saveMessage: "Your data has been saved" };
    });
  },

  handleClearSnackbar: (i) => {
    set((state) => {
      return { saveMessage: "" };
    });
  },

  handlePhoneDelete: (i) => {
    set((state) => {
      let phoneListTemp = [...state.phoneNumberList];
      phoneListTemp.splice(i, 1);
      return { phoneNumberList: phoneListTemp };
    });
  },

  handleHideShow: (name) => {
    set((state) => {
      if (name === "location") {
        const oldVal = state.formData.street.is_hidden;
        return {
          formData: {
            ...state.formData,
            street: { ...state.formData.street, is_hidden: !oldVal },
            city: { ...state.formData.city, is_hidden: !oldVal },
            state: { ...state.formData.state, is_hidden: !oldVal },
            postal_code: { ...state.formData.postal_code, is_hidden: !oldVal },
            country: { ...state.formData.country, is_hidden: !oldVal },
          },
        };
      } else {
        const oldVal = state.formData[name]?.is_hidden;

        return {
          formData: {
            ...state.formData,
            [name]: { ...state.formData[name], is_hidden: !oldVal },
          },
        };
      }
    });
  },

  handleQRSocialClick: (socialName, Logo, formName) => {
    set((state) => {
      if (state.selectedSocials.find((item) => item.formName === formName)) {
        return {
          formData: {
            ...state.formData,
            [formName]: { ...state.formData[formName], value: "" },
          },
          selectedSocials: state.selectedSocials.filter(
            (item) => item.formName !== formName
          ),
        };
      } else {
        return {
          selectedSocials: [
            ...state.selectedSocials,
            {
              name: socialName,
              logo: Logo,
              formName: formName,
            },
          ],
        };
      }
    });
  },

  handleCreateContactClick: () => {
    set((state) => {
      // create a vcard file
      const cardData = {
        name: state.formData.name ? state.formData.name : "",
        email: state.formData?.email,
        mobile_phone: state.formData.mobile_phone,
        company_name:
          state.formData.company_name?.value &&
          !state.formData.company_name?.is_hidden
            ? state.formData.company_name?.value
            : "",
      };
      var vcard =
        "BEGIN:VCARD\nVERSION:4.0\nN:" +
        cardData.name +
        "\nFN:" +
        cardData.name +
        "\n";
      for (const mobilePhone of cardData.mobile_phone) {
        if (mobilePhone.is_hidden) continue;
        vcard += "TEL:" + mobilePhone.value + "\n";
      }

      for (const email of cardData.email) {
        if (email.is_hidden) continue;
        vcard += "EMAIL:" + email.value + "\n";
      }

      vcard += "ORG:" + cardData.company_name + "\n";
      vcard += "END:VCARD";

      var blob = new Blob([vcard], { type: "text/vcard" });

      if (window.navigator.msSaveOrOpenBlob) {
        // For Internet Explorer and Microsoft Edge
        window.navigator.msSaveOrOpenBlob(blob, state.formData.name + ".vcf");
      } else {
        // For other browsers, including Safari
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = state.formData.name + ".vcf";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    });
  },

  handleSubmit: (e) => {
    set(async (state) => {
      e.preventDefault();
      let isInvalidNumber = false;
      state.phoneNumberList.map((phone) => {
        if (!isValidPhoneNumber(phone.value)) {
          state.setError("Phone number is invalid");
          isInvalidNumber = true;
        }
      });
      if (isInvalidNumber) return;
      if (state.formData.user_id?._id === "") {
        delete state.formData["user_id"];
        const response = await serverPOST("/qrCodeInfo", {
          ...state.formData,
          mobile_phone: state.phoneNumberList,
          email: state.emailList.filter((x) => x.value !== ""),
        });
        if (response && response.constructor !== String) {
          state.handleSnackbar();
        }
      } else {
        const codeId = state.formData?.user_id?._id;
        delete state.formData["user_id"];
        const response = await serverPUT(`/qrCodeInfo/${codeId}`, {
          ...state.formData,
          mobile_phone: state.phoneNumberList,
          email: state.emailList.filter((x) => x.value !== ""),
        });
        if (response && response.constructor !== String) {
          state.handleSnackbar();
        }
      }
    });
  },
}));

export default useQRCodeStore;
