import makeStyles from "@mui/styles/makeStyles";
const useStyle = makeStyles((theme) => ({
  chipColor: {
    color: "#96DCFF",
  },
  badge: {
    "& .MuiBadge-badge": {
      maxWidth: "0",
      maxHeight: "35%",
    },
    marginTop: "4px",
    marginRight: "4px",
    padding: 0,
  },

  flexCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  chipDate: {
    color: theme.palette.text.primary,
    marginTop: 5,
    minWidth: "75px",
  },
  chip: {
    borderRadius: "8px",
    minWidth: "94px",

    "& .MuiChip-label": {
      paddingLeft: "6px",
      paddingRight: "6px",
    },
  },
  pollingBtn: {
    position: "fixed",
    left: 0,
    bottom: 0,
    zIndex: 99999,
    paddingBottom: 30,
    paddingTop: 10,
    backgroundColor: "#ffffff7a",
    [theme.breakpoints.up("md")]: {
      position: "relative",
    },
  },
  btns: {
    marginTop: 20,
    marginBottom: 20,
  },

  descBtn: {
    color: theme.palette.text.primary,
    background: theme.palette.secondary.light,
    borderRadius: "8px",
    fontSize: 16,
    fontWeight: 400,
    justifyContent: "center",
    alignItems: "center",
    height: "30px",
    borderRadius: "4px",
    padding: "12px 5px",
  },
  file: {
    display: "flex",
    justifyContent: "flex-end",
    "& .MuiBadge-badge": {
      maxWidth: "0",
      maxHeight: "35%",
    },
  },
  fileChip: {
    background: theme.palette.secondary.light,
    overflow: "hidden",
    textOverflow: "ellipses",
    width: "200px",
    borderRadius: "4px",
  },

  partPollingBox: {
    borderBottom: "1px solid",
    width: "100%",
    textAlign: "start",
  },
  participantPolling: {
    background: "#F7F7F9",
    borderRadius: "8px",
    fontWeight: 600,
    marginRight: "8px",
  },

  inputsBox: {
    border: "1px solid #E0E2E7",
    borderRadius: "12px",
  },
  inputsInnerBox: {
    paddingTop: 20,
    boxSizing: "border-box",
    width: "95%",
    margin: "auto",
  },
  inputsContent: {
    padding: 10,
    boxSizing: "border-box",
    width: "95%",
    margin: "auto",
    textAlign: "left",
  },
  mt10: {
    marginTop: "10px",
  },

  timeslotPrefLoaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100px",
  },

  timeslotPrefContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    alignItems: "baseline",
    textAlign: "left",
  },

  blockedParticipant: {
    display: "flex",
    border: "1px solid",
    borderColor: theme.palette.secondary.gray,
    borderRadius: 8,
    padding: 8,
    position: "relative",
    cursor: "pointer",
  },

  checkboxRoot: {
    transition: "color 0.3s ease-in-out",
    // Add a smooth transition for the color change
    "&:hover": {
      backgroundColor: "red",
      cursor: "pointer",
    },
  },
}));

export default useStyle;
