import { useContext, useState } from "react";

import { Grid, Button, CircularProgress, useTheme } from "@mui/material";

import DialogWithSuccessMessage from "components/DialogWithSuccessMessage";
import loggingInDev from "loggingInDev";
import { serverPUT, serverPOST } from "Utils/HttpFunctions";
import { RECURRING_EVENT } from "Utils/Constants";
import { meetingsContext } from "context/meetings";
import { useTranslation } from "react-i18next";
import SnackbarComponent from "../../Global/SnackbarComponent";

export default function MeetingInvitesButtons({
  event,
  onClose,
  setAcceptDialog,
  setSecondaryOpen,
}) {
  const { setMeetingInvites, meetingInvites } = useContext(meetingsContext);
  const [rejectMeeting, setRejectMeeting] = useState(false);
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);
  const [success, setSuccess] = useState("");
  var timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  var offset = new Date().getTimezoneOffset();

  var mainRejectMessage = t("meeting_reject");
  var rejectMessage = t("reject_confirm");
  var rejectSuccess = t("reject_msg");

  const handleResponse = async (responseValue, comment) => {
    // setDisabled(true);
    const temp = [...meetingInvites];
    var acceptBody = {
      meeting_id: event.id,
      response: responseValue,
      time_zone: timeZone,
      utc_offset: offset,
    };
    if (!responseValue) {
      acceptBody = {
        ...acceptBody,
        comment: !!comment ? comment.trim() : undefined,
      };
    }

    let response;
    if (event.case === RECURRING_EVENT) {
      response = await serverPUT(`/meeting/recurring/${event.id}`, {
        to_cancel: !responseValue,
        comment: !!comment ? comment.trim() : undefined,
      });
    } else {
      response = await serverPOST(`/meeting/invitationresponse`, acceptBody);
    }

    if (response && response.constructor !== String) {
      loggingInDev("accepted response =>", response);
      if (setAcceptDialog && setSecondaryOpen) {
        responseValue === 1 ? setAcceptDialog(true) : setSecondaryOpen(true);
      } else {
        setSuccess(t("response_recorded"));
      }
      const meeting = await temp.find((e) => e.id === event?.id);
      const index = temp.indexOf(meeting);
      if (index !== -1) {
        temp.splice(index, 1);
        setMeetingInvites(temp);
      }
      // setMeetingResponded(true);
      if (onClose) {
        setTimeout(() => {
          onClose();
        }, 3000);
      }
    } else {
      loggingInDev("error =>", response);
    }
    setDisabled(false);
  };

  const handleReject = () => {
    setRejectMeeting(true);
  };

  return (
    <Grid container pt="16px" justifyContent={"center"}>
      <Button variant="outlined" sx={{ ml: "20px" }} onClick={handleReject}>
        {t("reject")}
      </Button>
      <Button
        variant="contained"
        onClick={() => {
          handleResponse(1);
        }}
        sx={{ ml: "20px" }}
        disabled={disabled}
      >
        {disabled ? (
          <CircularProgress style={{ color: "white" }} size={20} />
        ) : (
          t("accept")
        )}
      </Button>
      <DialogWithSuccessMessage
        open={rejectMeeting}
        setOpen={setRejectMeeting}
        mainRejectMessage={mainRejectMessage}
        message={rejectMessage}
        secondaryMessage={rejectSuccess}
        operation={handleResponse}
        disabled={disabled}
        showComment={true}
      />
      <SnackbarComponent
        open={success}
        handleClose={() => setSuccess("false")}
        message={success}
        vertical={"bottom"}
        horizontal={"center"}
      />
    </Grid>
  );
}
