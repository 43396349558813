import React from "react";
import { useState } from "react";

import {
  Button,
  Box,
  useTheme,
  Typography,
  CircularProgress,
  Grid,
  InputLabel,
  TextField,
} from "@mui/material";
import DialogWithCloseIcon from "../DialogWithCloseIcon";
import useStyle from "../../styles/formStyles";

export default function TestEmail({ onSave, onClose }) {
  const [email, setEmail] = useState(null);
  const [loading, setLoading] = useState(false);
  const classes = useStyle();
  var validator = require("validator");

  const handleTitleChange = (event) => {
    setEmail(event.target.value);
  };
  const theme = useTheme();

  const sendTestEmail = async () => {
    if (validator.isEmail(email)) {
      setLoading(true);
      await onSave(email);
      setLoading(false);
    }
  };

  return (
    <DialogWithCloseIcon
      open={true}
      handleClose={onClose}
      dialogSize={undefined}
    >
      <Box flexDirection="column" style={{ width: "100%", height: "100%" }}>
        <Box
          display="flex"
          flexDirection="column"
          style={{ width: "100%", height: "100%" }}
          justifyContent="center"
          alignItems="center"
          className={classes.root}
        >
          {/* <ErrorSnackbar
          open={!!error}
          handleClose={() => setError("")}
          message={error}
          vertical={"bottom"}
          horizontal={"center"}s
        /> */}

          <Box>
            <Typography
              variant="h2"
              align="center"
              sx={{ marginBottom: "8px" }}
            >
              {"Send a test email"}
            </Typography>
          </Box>
          <Grid display="flex" width="100%" alignItems={"left"}>
            <Grid item xs={12} className={classes.participant} width={"100%"}>
              <InputLabel htmlFor="title">
                <Typography variant="h4" width={"100px"}>
                  {"Email address" + ":"}
                </Typography>
              </InputLabel>

              <TextField
                placeholder={"Enter your email address"}
                variant="standard"
                autoComplete="off"
                value={email}
                style={{ marginTop: 10 }}
                sx={{ width: "100%" }}
                autoFocus
                InputProps={{
                  disableUnderline: true,
                  style: {
                    fontSize: "16px",
                    fontWeight: 400,
                    color: theme.palette.text.primary,
                  },
                  classes: { input: classes.input },
                }}
                onChange={handleTitleChange}
                id="create-form"
              />

              <Box sx={{ display: "flex", mt: 3 }} justifyContent="center">
                <Button
                  onClick={onClose}
                  variant="outlined"
                  style={{
                    marginRight: "16px",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={sendTestEmail}
                  disabled={!!!email || loading}
                >
                  {loading ? (
                    <CircularProgress style={{ color: "white" }} size={20} />
                  ) : (
                    "Send"
                  )}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </DialogWithCloseIcon>
  );
}
