import { useRef, useEffect, useState, useContext } from "react";
import { TextField, Autocomplete, Chip, useTheme } from "@mui/material";
import useStyle from "styles/formStyles";
import { serverPOST } from "../../Utils/HttpFunctions";
import { useTranslation } from "react-i18next";
import { accountContext } from "../../context/user";

export default function Participants({
  setParticipants,
  participants,
  contacts,
  groups: allGroups,
  isRequired = true,
  minimizedContacts,
  participantLabels,
  checkOnlySkeding,
  showBorders = false,
}) {
  const { t } = useTranslation();
  const participantsRef = useRef(null);
  const autoCompleteRef = useRef(null);

  const classes = useStyle();
  const [error, setError] = useState(false);
  const [groups, setGroups] = useState([]);
  const [participantsFocused, setParticipantsFocused] = useState(false);
  const [placeholder, setPlaceholder] = useState(t("skeding_memebers"));
  const [errorMessage, setErrorMessage] = useState("");
  const { user } = useContext(accountContext);

  const errorMessages = {
    INVALID_EMAIL: t("invalid_email"),
    CURRENT_USER: t("current_user"),
    ONLY_SKEDING_MEMBERS: t("only_skeding"),
  };

  useEffect(() => {
    setGroups(allGroups);
  }, [allGroups]);

  useEffect(() => {
    handlePlaceholder();
  }, [participantsFocused]);

  const handleEnter = (event) => {
    return event.key === "Enter";
  };

  const handleBlur = (event, value) => {
    const validator = require("validator");
    const atReg = /@/;
    const email = value.current.value;

    setError(false);
    setParticipantsFocused(false);
    setPlaceholder(t("skeding_memebers"));

    if (participants.includes(email)) {
      setError(true);
      return;
    }
    if (atReg.test(email)) {
      switch (validator.isEmail(email)) {
        case true:
          setParticipants([...participants, email.toLowerCase()]);
          setError(false);
          break;
        case false:
          setError(true);
          setErrorMessage(errorMessages.INVALID_EMAIL);
          break;
      }
    } else {
      if (email !== "") {
        setError(true);
        setErrorMessage(errorMessages.INVALID_EMAIL);
      }
    }
  };

  const checkContactStatus = async (contact) => {
    const response = await serverPOST(`/contact/skedingstatus`, {
      participants: [contact],
    });
    if (response && response.constructor !== String) {
      return response?.data[0]?.status;
    }
  };

  const handleAutoCompleteOnChange = async (event, value, reason) => {
    const validator = require("validator");
    const atReg = /@/;

    setError(false);

    const selectedGroup = groups.find(
      (group) => group.group_name + " " + "group" === value.slice(-1)[0]
    );

    if (reason === "selectOption" && !selectedGroup) {
      const index = value.length > 0 ? value.length - 1 : 0;
      value[index] = value[index]?.split(":")[1]?.trim()?.toLowerCase();
    }

    if (event?.key == "Enter" || event?.key == "Space") {
      if (selectedGroup) {
        const emails = selectedGroup.contacts.map((contact) =>
          contact.email?.toLowerCase()
        );
        const combined = participants.concat(emails);
        setParticipants(
          combined?.filter((item, pos) => {
            return combined.indexOf(item) == pos;
          })
        );
        return;
      }

      switch (contacts.findIndex((contact) => contact.email === value) !== -1) {
        case true:
          setParticipants(value);
          return;
        case false:
          if (
            value.findIndex((email) => email?.toLowerCase() === user?.email) !==
            -1
          ) {
            setError(true);
            setErrorMessage(errorMessages.CURRENT_USER);
          } else if (atReg.test(value)) {
            value = value.map((c) => c.toLowerCase());
            switch (validator.isEmail(value.slice(-1)[0])) {
              case true:
                if (checkOnlySkeding) {
                  const status = await checkContactStatus(value.slice(-1)[0]);
                  if (status) {
                    setError(true);
                    setErrorMessage(errorMessages.ONLY_SKEDING_MEMBERS);
                    return;
                  }
                }
                setParticipants(value);
                setError(false);
                break;
              case false:
                setError(true);
                setErrorMessage(errorMessages.INVALID_EMAIL);
                break;
            }
          } else {
            setError(true);
            setErrorMessage(errorMessages.INVALID_EMAIL);
            break;
          }
          break;
      }
      return;
    }

    if (selectedGroup) {
      const emails = selectedGroup.contacts.map((contact) =>
        contact.email?.toLowerCase()
      );
      const combined = participants.concat(emails);
      setParticipants(
        combined?.filter((item, pos) => {
          return combined.indexOf(item) == pos;
        })
      );
      return;
    }

    await setParticipants(value);
    if (reason === "removeOption") {
      //temporarily commented (it restores group)
      // const tempGroups = [];
      // allGroups.filter((group) => {
      //   const c = group.contacts.some(
      //     (item) => item.email === value.slice(-1)[0]
      //   );
      //   if (!c) {
      //     tempGroups.push(group);
      //   }
      // });
      // setGroups(groups.concat(tempGroups));
      setError(false);
    }
  };

  const renderChips = (value, getTagProps, participantLabels) => {
    if (value === null) return;

    var chips = value.map((contactName, index) => {
      return (
        <Chip
          key={index}
          variant="filled"
          label={contactName}
          {...getTagProps({ index })}
          style={{
            background:
              participantLabels !== undefined
                ? participantLabels[index]?.status
                  ? "#B3B5B680"
                  : "#B0E4FF"
                : "#B0E4FF",
            borderRadius: "4px",
            fontSize: "14px",
            color: "#3C4242",
            margin: "4px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            maxWidth: "260px",
          }}
        />
      );
    });
    return chips;
  };

  const handleParticipantsFocus = () => {
    setParticipantsFocused(true);
    setPlaceholder(t("press"));
  };

  const handlePlaceholder = () => {
    if (participants?.length > 0) {
      setPlaceholder(
        isRequired
          ? t("add_participants") + " (Email) *"
          : t("add_participants") + " (Email)"
      );
    }
  };

  return (
    <Autocomplete
      freeSolo
      filterSelectedOptions
      multiple
      style={{ marginTop: 10 }}
      id="participants"
      sx={{ width: "100%" }}
      options={minimizedContacts
        ?.map((contact) => contact.name + " : " + contact.email)
        .concat(groups?.map((group) => group?.group_name + " " + "group"))}
      value={participants}
      renderTags={(value, getTagProps) =>
        renderChips(value, getTagProps, participantLabels)
      }
      onChange={handleAutoCompleteOnChange}
      ref={autoCompleteRef}
      disableClearable
      clearOnBlur={!error}
      className={classes.participantParent}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          error={error}
          helperText={
            // error ? "please enter a valid contact name or email address" : ""
            error ? errorMessage : ""
          }
          placeholder={placeholder}
          inputRef={participantsRef}
          className={classes.participant}
          onBlur={(e) => handleBlur(e, participantsRef)}
          onFocus={handleParticipantsFocus}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            style: {
              fontSize: 16,
              fontWeight: 400,
              // flexWrap: "nowrap",
              overflowX: "auto",
              border: showBorders ? "1px solid #DAD2E5" : "none",
              paddingLeft: showBorders ? 8 : "inherit",
              paddingRight: showBorders ? 8 : "inherit",
            },
          }}
        />
      )}
    />
  );
}
