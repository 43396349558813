import {
  Badge,
  IconButton,
  Box,
  Avatar,
  CircularProgress,
} from "@mui/material";
import EditIcon from "@mui/icons-material/EditOutlined";

import ChangeProfilePicture from "./ChangeProfilePicture";
import useStyle from "./styles";

export default function ProfilePicture({
  user,
  handleImageOpen,
  handleImageClose,
  imageOpen,
  handleImage,
  disabledImage,
  uploadError,
}) {
  const classes = useStyle();
  return (
    <Badge
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      className={classes.badge}
      badgeContent={
        <IconButton onClick={handleImageOpen} className={classes.badgeIcon}>
          <EditIcon
            sx={{ color: "text.primary", width: "12px" }}
            fontSize="small"
          />
        </IconButton>
      }
    >
      {disabledImage ? (
        <Box className={classes.profileLoader}>
          <CircularProgress />
        </Box>
      ) : (
        <Avatar
          className={classes.avatar}
          alt="profile picture"
          src={user?.picture}
        />
      )}
      <ChangeProfilePicture
        handleImageClose={handleImageClose}
        imageOpen={imageOpen}
        handleImage={handleImage}
        existingImage={user?.picture}
        uploadError={uploadError}
      />
    </Badge>
  );
}
