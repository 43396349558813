import {
  Box,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import useContactGroups from "../../Utils/hooks/useContactGroups";
import TimeInterval from "../../components/NewEvent/TimeInterval";
import useMeetingStore from "../../Utils/createMeetingStore";
import useStyle from "../../styles/formStyles";
import { useTranslation } from "react-i18next";
import Participants from "./Participants";
import { accountContext } from "../../context/user";

export default function AgendaForm({
  participants,
  agenda,
  handleAddAgenda,
  index,
  maxInterval,
}) {
  const [contacts] = useContactGroups([]);
  const { agendas, setAgendas } = useMeetingStore();
  const theme = useTheme();
  const classes = useStyle();
  const [minimizedContacts, setMinimizedContacts] = useState([]);
  const { user } = useContext(accountContext);
  const [filteredContacts, setFilteredContacts] = useState<any>([]);
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();

  useEffect(() => {
    if (agenda?.participants?.length > 0) {
      const temp = [...agenda.participants];
      const remaining = filteredContacts.filter(
        (c: any) => !temp.some((e) => c.email === e)
      );
      setMinimizedContacts(remaining);
    } else {
      setMinimizedContacts(filteredContacts);
    }
  }, [agenda?.participants, filteredContacts]);

  useEffect(() => {
    let organizer = {};
    let filtered = [] as any;
    filtered = contacts.filter((c: any) => participants.includes(c.email));
    console.log("🚀 ~ useEffect ~ filtered:", filtered);

    if (filteredContacts.length === 0 && user) {
      organizer = {
        email: user.email,
        isSkeding: true,
        name: user.name,
        _id: user.id,
      };
      filtered.push(organizer);
    }
    setFilteredContacts([...filteredContacts, ...filtered]);
  }, [participants, contacts, user]);

  const handleIntervalChange = (event) => {
    let agendaTemp = [...agendas];
    let agenda = agendaTemp[index];
    agenda.time = event.target.value;
    setAgendas(agendaTemp);
  };

  const handleAssignees = (value) => {
    let agendaTemp = [...agendas];
    let agenda = agendaTemp[index];
    agenda.participants = value;
    setAgendas(agendaTemp);
  };

  return (
    <Box my={2} style={{ flex: 1 }}>
      <Grid container direction={"column"}>
        <Grid
          item
          display={"flex"}
          gap={mobile ? 1 : 4}
          direction={mobile ? "column" : "row"}
        >
          <Grid
            item
            display={"flex"}
            direction={mobile ? "column" : "row"}
            alignItems={mobile ? "left" : "center"}
            gap={mobile ? 1 : 2}
            style={{ marginTop: "10px" }}
          >
            <Typography variant="h4">{t("topic")}:</Typography>
            <TextField
              defaultValue={agenda.topic}
              variant="filled"
              placeholder={t("enter") + " " + t("topic")}
              onChange={(e) => handleAddAgenda(e, index)}
              name="topic"
              required
              InputProps={{
                disableUnderline: true,
                style: {
                  color: theme.palette.text.primary,
                },
                classes: { input: classes.input },
              }}
            />
          </Grid>

          <Grid
            item
            display={"flex"}
            gap={mobile ? 1 : 2}
            direction={mobile ? "column" : "row"}
            alignItems={mobile ? "left" : "center"}
          >
            <Typography variant="h4" style={{ marginTop: "10px" }}>
              {t("duration")}:
            </Typography>
            <TimeInterval
              notNewMeeting={undefined}
              timeInterval={agenda.time}
              handleIntervalChange={handleIntervalChange}
              hideCustom={true}
              maxInterval={maxInterval}
              isBorder={undefined}
              isService={undefined}
            />
          </Grid>

          <Grid
            item
            display={"flex"}
            gap={mobile ? 1 : 2}
            direction={mobile ? "column" : "row"}
            alignItems={mobile ? "left" : "center"}
            width={mobile ? "100%" : "60%"}
          >
            <Typography variant="h4" style={{ marginTop: "10px" }}>
              {t("assignees")}:
            </Typography>
            <Participants
              setParticipants={handleAssignees}
              participants={agenda.participants}
              participantLabels={[]}
              contacts={filteredContacts}
              groups={[]}
              minimizedContacts={minimizedContacts}
              checkOnlySkeding={undefined}
            />
          </Grid>
        </Grid>
        <Grid
          item
          display={"flex"}
          gap={1}
          direction={mobile ? "column" : "row"}
          alignItems={mobile ? "left" : "center"}
          mt={2}
        >
          <Typography variant="h4">{t("keynotes")}:</Typography>
          <TextField
            variant="filled"
            placeholder={t("enter") + " " + t("keynotes")}
            defaultValue={agenda.keypoints}
            style={{ width: "100%", borderRadius: "16px" }}
            name="keypoints"
            onChange={(e) => handleAddAgenda(e, index)}
            InputProps={{
              disableUnderline: true,
              style: {
                color: theme.palette.text.primary,
              },
              classes: { input: classes.input },
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
