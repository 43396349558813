import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import {
  convertBase64,
  handleEmailValidation,
} from "../../Utils/CommonFunctions";
import Participants from "../NewEvent/Participants";
import {
  DesktopDateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import RecurringMeetingCheck from "../NewEvent/RecurringMeeting";
import { useTranslation } from "react-i18next";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import * as XLSX from "xlsx";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import { serverPOST } from "../../Utils/HttpFunctions";
import useBulkEmailStore from "../../Utils/bulkEmailStore";
import useContactGroups from "../../Utils/hooks/useContactGroups";
import TestEmail from "../Email/TestEmail";
import SnackbarComponent from "../Global/SnackbarComponent";
import ErrorSnackbar from "../ErrorSnackbar";
import { CreateEmailTemplate } from "./CreateEmailTemplate";

const dropdowns = [
  { name: "Gmail", value: "smtp.gmail.com", port: 465 },
  { name: "Yahoo", value: "smtp.mail.yahoo.com", port: 465 },
  { name: "Outlook/Office 365", value: "smtp.office365.com", port: 587 },
  { name: "Hotmail", value: "smtp.live.com", port: 587 },
  { name: "Yahoo Mail Plus", value: "plus.smtp.mail.yahoo.com", port: 465 },
  { name: "Zoho Mail", value: "smtp.zoho.com", port: 465 },
  { name: "Mail.com", value: "smtp.mail.com", port: 587 },
  { name: "ProtonMail", value: "smtp.protonmail.com", port: 465 },
];

export function CreateBulkEmail({
  classes,
  pclasses,
  handleFormData,
  clearForm,
  fetchTemplates,
}) {
  const theme = useTheme();
  const inputRef = useRef();
  const [emailError, setEmailError] = useState(false);
  const [emailHelperText, setEmailHelperText] = useState("");
  const { t } = useTranslation();
  const [isFuture, setIsFuture] = useState(true);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [emailType, setEmailType] = useState("");
  const [formDate, setFormDate] = useState(moment());
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [dateError, setDateError] = useState("");
  const editorRef: any = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [success, setSuccess] = useState("");
  const [invisible, setInvisible] = useState(true);
  const password = useBulkEmailStore((store) => store.password);
  const subject = useBulkEmailStore((store) => store.subject);
  const setPassword = useBulkEmailStore((store) => store.setPassword);
  const contactEmail = useBulkEmailStore((store) => store.contactEmail);
  const setContactEmail = useBulkEmailStore((store) => store.setContactEmail);
  const templateId = useBulkEmailStore((store) => store.templateId);
  const selectedHost = useBulkEmailStore((store) => store.selectedHost);
  const setSelectedHost = useBulkEmailStore((store) => store.setSelectedHost);
  const setSubject = useBulkEmailStore((store) => store.setSubject);
  const body = useBulkEmailStore((store) => store.body);
  const setBody = useBulkEmailStore((store) => store.setBody);
  const files = useBulkEmailStore((store) => store.files);
  const setFiles = useBulkEmailStore((store) => store.setFiles);
  const fileNames = useBulkEmailStore((store) => store.fileNames);
  const setFileNames = useBulkEmailStore((store) => store.setFileNames);
  const signature = useBulkEmailStore((store) => store.signature);
  const setSignature = useBulkEmailStore((store) => store.setSignature);
  const emails = useBulkEmailStore((store) => store.emails);
  const setEmails = useBulkEmailStore((store) => store.setEmails);
  const tabIndex = useBulkEmailStore((store) => store.tabIndex);
  const [isTestEmail, setIsTestEmail] = useState(false);
  const fileInputRef: any = useRef(null);

  const [contacts, groups, minimizedContacts] = useContactGroups(emails);

  const currentDate = moment();

  // Set the minimum date to the current date
  const minDate = currentDate;

  const minTimeCheck = formDate.isSame(moment(), "day") ? moment() : null;
  // Set the minimum time to the current time
  const minTime = moment().set({
    hour: currentDate.hours(),
    minute: currentDate.minutes(),
    second: 0,
  });

  useEffect(() => {
    if (!tabIndex) clearForm();
  }, [tabIndex]);

  const handleDateChange = (date) => {
    setFormDate(date);
  };

  const handleFileUpload = (e) => {
    if (e.target.files?.length > 0) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (evt: any) => {
        const data = new Uint8Array(evt.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        // Find column with header containing the word "email"
        let emailColumn: any = null;
        for (const key in sheet) {
          const emailHeaderValue = sheet[key]?.v;
          if (
            typeof emailHeaderValue === "string" &&
            (emailHeaderValue.toLowerCase().includes("email") ||
              emailHeaderValue.toLowerCase().includes("e-mail 1 - value"))
          ) {
            emailColumn = key.substring(0, key.length - 1);
            break;
          }
        }

        if (emailColumn) {
          const emailAddresses: any = [];
          let rowIndex = 2; // Assuming the first row is header
          console.log(sheet[`${emailColumn}${rowIndex}`]);
          while (sheet[`${emailColumn}${rowIndex}`]) {
            emailAddresses.push(sheet[`${emailColumn}${rowIndex}`].v);
            rowIndex++;
          }
          setEmails(emailAddresses);
        } else {
          alert('No column header with the word "email" found.');
        }
      };
      reader.readAsArrayBuffer(file);
      if (file) {
        fileInputRef.current.value = "";
      }
    }
  };

  const onEditorSave = async (testEmail?: any) => {
    if (formDate.diff(moment(), "seconds") < 0 && isFuture && !testEmail) {
      setDateError("Please select one of the options");
      return;
    }

    if (isLoaded && editorRef.current) {
      setIsLoaded(false);
      editorRef.current.fetchState();
    }

    if (emailError || password.trim() === "" || subject.trim() === "") {
      setError("Please fill out all fields");
      return;
    }

    if (emails.length === 0 && !testEmail) {
      setError("Emails required");
      return;
    }
    setLoading(true);

    try {
      // const tEmail = typeof testEmail !== "object" ? testEmail : undefined;
      let data: any = {
        email: contactEmail,
        password,
        host: selectedHost,
        port: dropdowns.find((d) => d.value === selectedHost)?.port,
        subject,
        emails,
        testEmail,
        emailType,
        templateId,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        startTime: formDate.valueOf(),
        isFuture,
        template: {
          subject,
          body,
          signature: { text: signature, image: [] },
        },
      };

      if (files.length > 0) {
        data.template.signature.image = files;
      }

      const response = await serverPOST("/email/sendemail", data);
      if (response && response.constructor !== String) {
        setSuccess("Email scheduled successfully");
        clearForm();
        fetchTemplates();
      } else {
        setError("Unable to send email");
        fetchTemplates();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleFile = async (e) => {
    setError("");
    const maxSize = 2 * 1024 * 1024; // 2MB in bytes

    let files1 = [...e.target?.files];
    const tempF: any = [];
    const tempFName: any = [];

    if (files1?.length + files?.length > 4) {
      // setError(t("You can only add 4 attachments"));
      return;
    }

    files1?.map(async (file) => {
      if (file?.size > maxSize) {
        // setError(t("file_size"));
        return;
      }

      const blob = new Blob([file.size], { type: file.type });

      if (blob instanceof Blob) {
        let img = await convertBase64(file);
        tempF.push(img);
        tempFName.push(file.name);
      } else {
        console.error("Invalid file type:", file);
        setError("Unable to upload image");
        return null;
      }
      setFiles([...files, ...tempF]);
      setFileNames([...fileNames, ...tempFName]);
    });
  };

  const removeFile = (fileName, i) => {
    setFileNames(fileNames?.filter((x) => x !== fileName));
    setFiles(files?.filter((x, ind) => ind !== i));
  };

  return (
    <>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 16,
        }}
      >
        <Box
          style={{
            border: "1px solid #DAD2E5",
            borderRadius: 6,
            padding: 12,
            textAlign: "left",
          }}
        >
          <Typography variant="h3">From</Typography>
          <Typography variant="h4" color={"#70757A"} fontWeight={400}>
            Who is sending this email?
          </Typography>
          <Box mb={1}>
            <FormControl
              variant="standard"
              sx={{
                width: "100%",
                marginTop: "10px",
                textAlign: "left",
                border: "1px solid #DAD2E5",
                borderRadius: "6px",
                backgroundColor: "#F7F7F9",
                marginRight: 2,
              }}
            >
              <Select
                value={selectedHost}
                disableUnderline={true}
                style={{
                  fontSize: "16px",
                  textAlign: "left",
                  padding: "0px 8px",
                }}
                onChange={(e) => setSelectedHost(e.target.value)}
              >
                {dropdowns &&
                  dropdowns.map((data) => {
                    return (
                      <MenuItem
                        key={data.name}
                        value={data.value}
                        className={classes.menuItem}
                      >
                        {data.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Box>
          <Box style={{ marginTop: 16 }}>
            <TextField
              placeholder={"Sender email"}
              variant="standard"
              value={contactEmail}
              sx={{
                width: "100%",
              }}
              InputProps={{
                disableUnderline: true,
                classes: { input: ` ${pclasses.createInput}` },
              }}
              onChange={(e) => handleFormData(e, setContactEmail)}
              onBlur={() =>
                handleEmailValidation(
                  inputRef,
                  setEmailError,
                  setEmailHelperText
                )
              }
              error={emailError}
              helperText={emailHelperText}
              id="add-user-email"
              inputRef={inputRef}
            />
          </Box>
          <Box style={{ marginTop: 16 }}>
            <TextField
              placeholder={"App password"}
              variant="standard"
              value={password}
              sx={{
                width: "100%",
              }}
              InputProps={{
                disableUnderline: true,
                classes: { input: ` ${pclasses.createInput}` },
              }}
              onChange={(e) => handleFormData(e, setPassword)}
            />
          </Box>
        </Box>

        <Box
          style={{
            border: "1px solid #DAD2E5",
            borderRadius: 6,
            padding: 12,
            textAlign: "left",
          }}
        >
          <Typography variant="h3">To</Typography>
          <Typography variant="h4" color={"#70757A"} fontWeight={400}>
            Who are you sending this email to?
          </Typography>
          <Box style={{ marginTop: 16, textAlign: "left" }}>
            <Participants
              setParticipants={setEmails}
              participants={emails}
              contacts={contacts}
              groups={groups}
              minimizedContacts={minimizedContacts}
              participantLabels={undefined}
              checkOnlySkeding={false}
              showBorders={true}
            />
            <Box>
              <IconButton
                component="label"
                disableRipple
                style={{ paddingLeft: 0 }}
              >
                <input
                  type="file"
                  accept=".xls,.xlsx,.csv"
                  hidden
                  name="image"
                  onChange={handleFileUpload}
                  ref={fileInputRef}
                />
                <UploadFileIcon
                  style={{
                    color: theme.palette.primary.main,
                  }}
                />
                <Box>
                  <Typography color="primary" variant="h5" fontWeight={400}>
                    Upload Emails (csv,xls,xlsx)
                  </Typography>
                </Box>
              </IconButton>
            </Box>
          </Box>
        </Box>
        <Box
          style={{
            border: "1px solid #DAD2E5",
            borderRadius: 6,
            padding: 12,
            textAlign: "left",
          }}
        >
          <CreateEmailTemplate
            body={body}
            setBody={setBody}
            fileNames={fileNames}
            files={files}
            handleFile={handleFile}
            handleFormData={handleFormData}
            invisible={invisible}
            setInvisible={setInvisible}
            pclasses={pclasses}
            subject={subject}
            setSubject={setSubject}
            signature={signature}
            setSignature={setSignature}
            removeFile={removeFile}
          />
        </Box>

        <Box
          style={{
            border: "1px solid #DAD2E5",
            borderRadius: 6,
            padding: 12,
            textAlign: "left",
          }}
        >
          <Typography variant="h3">Schedule</Typography>

          <Box style={{ textAlign: "left" }} mt={1}>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={!isFuture}
                  onChange={() => {
                    setIsFuture((prev) => !prev);
                  }}
                />
              }
              label={
                <Typography variant="h4" fontWeight={400}>
                  Send immediately
                </Typography>
              }
            />
          </Box>
          {isFuture && (
            <>
              <Box display="flex" alignItems={"center"} gap={1}>
                <InputLabel htmlFor="title">
                  <Typography variant="h4">Set schedule date:</Typography>
                </InputLabel>
                <Button disableRipple>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDateTimePicker
                      open={calendarOpen}
                      onOpen={() => setCalendarOpen(true)}
                      onClose={() => setCalendarOpen(false)}
                      value={formDate}
                      disabled={!isFuture}
                      disablePast={true}
                      minDate={minDate}
                      minTime={minTimeCheck ? minTime : null}
                      onChange={(newValue: any) => {
                        handleDateChange(newValue);
                      }}
                      PaperProps={{
                        sx: {
                          span: { color: "black" },
                          "& .MuiPickersDay-root": {
                            color: "black",
                            "&.Mui-selected": {
                              color: "white !important",
                              backgroundColor: "#479AE9 !important",
                              "&:hover": {
                                opacity: 0.8,
                              },
                            },
                          },
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          onClick={(e) => setCalendarOpen((prev) => !prev)}
                          onKeyDown={(e) => e.preventDefault()}
                          className={classes.dateTime}
                          InputProps={{
                            disableUnderline: true,
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Button>
              </Box>
              <Box
                style={{ textAlign: "left" }}
                display="flex"
                alignItems="center"
              >
                <RecurringMeetingCheck
                  meetingType={emailType}
                  setMeetingType={setEmailType}
                />
              </Box>
            </>
          )}
        </Box>

        <Button onClick={() => onEditorSave()} variant="contained">
          {loading ? (
            <CircularProgress style={{ color: "white" }} size={20} />
          ) : (
            t("save")
          )}
        </Button>
        {isTestEmail && (
          <TestEmail
            onClose={() => setIsTestEmail(false)}
            onSave={(email) => {
              onEditorSave(email);
              setIsTestEmail(false);
            }}
          />
        )}

        <Box>
          <Typography
            variant="h3"
            color={"#479AE9"}
            style={{ cursor: "pointer" }}
            onClick={() => setIsTestEmail(true)}
          >
            Send a test email
          </Typography>
        </Box>
      </Box>
      <ErrorSnackbar
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        vertical={"bottom"}
        horizontal={"center"}
        duration={undefined}
      />
      <ErrorSnackbar
        open={!!dateError}
        handleClose={() => setDateError("")}
        message={dateError}
        vertical={"bottom"}
        horizontal={"center"}
        duration={undefined}
      />
      <SnackbarComponent
        open={!!success}
        handleClose={() => setSuccess("")}
        message={success}
        vertical={"bottom"}
        horizontal={"center"}
      />
    </>
  );
}
