import React, { useState, useEffect } from "react";
import * as contentful from "contentful";
export const blogContext = React.createContext();

export default function BlogsProvider({ children }) {
  const [blogs, setBlogs] = useState(null);

  const client = contentful.createClient({
    space: "x8t4mpbb17cx",
    accessToken: "QnJZyY1kHxLbGmtlNfLX5gV6Z4V-2QM_7h00pOkpZMM",
  });
  return (
    <blogContext.Provider value={{ blogs, setBlogs, client }}>
      {children}
    </blogContext.Provider>
  );
}
