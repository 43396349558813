import { useState, useEffect, useRef } from "react";

import {
  TextField,
  InputAdornment,
  Box,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import SearchIcon from "@mui/icons-material/Search";

import { serverGET } from "Utils/HttpFunctions";
import { useDebounce } from "hooks/useDebounce";
import { useTranslation } from "react-i18next";
import useContactStore from "../../Utils/contactStore";

const useStyles = makeStyles({
  root: {
    "& .MuiInputBase-root": {
      backgroundColor: "#F7F7F9",
      borderRadius: "6px",
    },
  },
});

export default function ContactSearchBar({ invalidTeam }) {
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const controllerRef = useRef(null);
  const classes = useStyles();
  const searchBarRef = useRef(null);
  const { t } = useTranslation();
  const searchQuery = useDebounce(query, 2000);

  const { setSearchedContacts } = useContactStore();

  /**
   * Focuses on the searchbar if the user hasn't selected >= 2 contacts to create a new team
   * Used to indicate to user that they can search for contacts to create a team
   */
  useEffect(() => {
    if (invalidTeam) {
      searchBarRef.current.focus();
      setTimeout(() => {
        searchBarRef.current.blur();
      }, 3000);
    }
  }, [invalidTeam]);

  /**
   * Makes request to backend for search query whenever searchQuery changes
   * Sets searchedContacts to [] when user clears their search
   */
  useEffect(() => {
    if (searchQuery || searchQuery.trim().length < 0) {
      const controller = new AbortController();
      controllerRef.current = controller;
      searchForQuery(controller.signal);
    } else if (searchQuery.length === 0) {
      setSearchedContacts([]);
    }

    return cancelSearch();
  }, [searchQuery]);

  /**
   * Makes request to the backend for the contact name set in the searchbar
   */
  const searchForQuery = async () => {
    setSearchedContacts([]);
    setLoading(true);

    try {
      // const response = await serverGET(`/contact/search?q=${query}`, controller.signal)
      const response = await serverGET(`/contact/search?q=${query}`);

      if (response) {
        if (response.data.searchedContacts.length === 0) {
          setSearchedContacts(null);
        } else {
          setSearchedContacts(response.data.searchedContacts);
        }
      }
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("fetch aborted");
      } else {
        console.error(error);
      }
    }

    setLoading(false);
  };

  const cancelSearch = () => {
    if (controllerRef.current) {
      controllerRef.current.abort();
    }
  };

  /**
   * Sets the query state var when the user changes the value in the searchbar
   * @param {ChangeEvent} e - change to the input value
   */
  const handleSearch = (e) => {
    setQuery(e.target.value);
  };

  return (
    <Box display="flex" alignItems="center">
      <TextField
        placeholder={t("enter_search")}
        classes={classes}
        inputProps={{
          sx: {
            height: "30px",
            padding: "0px 8px 0px 0px",
            borderRadius: "6px",
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon fontSize="small" />
            </InputAdornment>
          ),
        }}
        onChange={(e) => handleSearch(e)}
        value={query}
        ref={searchBarRef}
      />

      {loading && <CircularProgress style={{ marginLeft: "20px" }} />}
    </Box>
  );
}
