import { Box } from "@mui/material";
import ColorIndicator from "../meetings/ColorIndicator";
import React from "react";

export default function Pastel() {
  return (
    <>
      <ColorIndicator text={"legend_one_to_one"} color={"#95C3F2"} />
      <ColorIndicator text={"legend_group_meetings"} color={"#CBADF8"} />
      <ColorIndicator text={"legend_website_bookings"} color={"#DBF27E"} />
      <ColorIndicator text={"legend_tasks"} color={"#B0E4FF"} />
      <ColorIndicator text={"legend_external_meetings"} color={"#B1F2E6"} />
      <ColorIndicator text={"legend_external_events"} color={"#F8E08C"} />
    </>
  );
}
