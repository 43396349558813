import { Skeleton, Box } from "@mui/material";

export const dashboardMeetingsLoader = Array.apply(null, Array(10)).map((i) => {
    return( 
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        key={i}
      >
        <Box
          display="flex"
          flexDirection="column"
        >
          <Box
            display="flex"
            justifyContent="center"
            width="100%"
          >
            <Skeleton 
              variant="text" 
              width={"20%"} 
              height={14}
              sx={{
                margin: "5px"
              }}
            />
          </Box>
          <Skeleton 
            variant="rounded" 
            width={"100%"} 
            height={75}
            sx={{
                margin: "5px 10px 5px 0px",
                borderRadius: "8px"
            }}
          />
        </Box>
      </Box>
    )
  })

export const meetingsPageLoader = Array.apply(null, Array(10)).map((i) => {
    return( 
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        key={i}
      >
        <Box
          display="flex"
          flexDirection="column"
        >
          <Skeleton 
            variant="rounded" 
            width={"100%"} 
            height={210}
            sx={{
                marginTop: "20px",
                borderRadius: "8px"
            }}
          />
        </Box>
      </Box>
    )
})
