import { create } from "zustand";

const useBulkEmailStore = create((set, get) => ({
  password: "",
  setPassword: (data) => {
    set({ password: data });
  },

  contactEmail: "",
  setContactEmail: (data) => {
    set({ contactEmail: data });
  },

  selectedHost: "smtp.gmail.com",
  setSelectedHost: (data) => {
    set({ selectedHost: data });
  },

  templateId: "",
  setTemplateId: (data) => {
    set({ templateId: data });
  },

  subject: "",
  setSubject: (data) => {
    set({ subject: data });
  },

  body: "",
  setBody: (data) => {
    set({ body: data });
  },

  emails: [],
  setEmails: (data) => {
    set({ emails: data });
  },

  files: [],
  setFiles: (data) => {
    set({ files: data });
  },

  fileNames: [],
  setFileNames: (data) => {
    set({ fileNames: data });
  },

  signature: "",
  setSignature: (data) => {
    set({ signature: data });
  },

  templates: [],
  setTemplates: (data) => {
    set({ templates: data });
  },

  deleteModal: null,
  setDeleteModal: (data) => {
    set({ deleteModal: data });
  },

  tabIndex: 0,
  setTabIndex: (index) => {
    set({ tabIndex: index });
  },
}));

export default useBulkEmailStore;
