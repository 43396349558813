import { create } from "zustand";

const useWebsiteStore = create((set, get) => ({
  calendarData: {},
  setCalendarData: (data) => {
    set({ calendarData: data });
  },

  tabIndex: 0,
  setTabIndex: (index) => {
    set({ tabIndex: index });
  },

  // used in booking page
  askUser: {
    name: true,
    email: true,
    payment: false,
    questions: [],
  },
  setAskUser: (data) => {
    set({ askUser: data });
  },

  // used in services page
  services: [],
  setServices: (data) => {
    set({ services: data });
  },

  websiteLocations: [],
  setWebsiteLocations: (data) => {
    set({ websiteLocations: data });
  },

  // used in details page
  details: {
    title: "",
    description: "",
    picture: "",
    category: "",
  },

  setDetails: (data) => {
    set({ details: data });
  },

  // multiple places
  question: {
    type_of_question: "",
    index: -1,
    label: "",
    max_characters: 0,
    is_required: false,
    options: [],
  },

  // select category (index)
  category: "",
  setCategory: (data) => {
    set({ category: data });
  },

  emailTemplateType: "",
  setEmailTemplateType: (data) => {
    set({ emailTemplateType: data });
  },

  selectedEmailTemplate: {},
  setSelectedEmailTemplate: (data) => {
    set({ selectedEmailTemplate: data });
  },

  websiteNotifications: [],
  setWebsiteNotifications: (data) => {
    set({ websiteNotifications: data });
  },
}));

export default useWebsiteStore;
