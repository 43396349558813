import { TextField, InputAdornment, useTheme, Grid } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import DialogWithCloseIcon from "components/DialogWithCloseIcon";
import FreeTrialDialog from "components/payment/FreeTrialDialog";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import puseStyle from "./styles";
import ToolTip from "../Global/ToolTip";
/**
 * Searchbar for finding events near the user, rendered in the top navigation bar
 */
export default function EventsSearchbar({
  handleSearch,
  handleKeyPress,
  setSearch,
  isPaid,
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const [dialog, setDialog] = useState(false);
  const navigate = useNavigate();
  const classes = puseStyle();

  const openDialog = () => {
    setDialog(true);
  };

  const handleClose = () => {
    setDialog(false);
  };

  return (
    <Grid>
      <ToolTip title={t("search_tooltip")}>
        <TextField
          type={"text"}
          size="small"
          autoComplete="off"
          placeholder={t("search_events")}
          id="search-bar"
          // disabled={!isPaid}
          onClick={() => {
            if (!isPaid) openDialog();
          }}
          onChange={(e) => {
            if (isPaid) setSearch(e.target.value);
          }}
          onKeyDown={handleKeyPress}
          autoFocus={false}
          sx={{
            backgroundColor: theme.palette.secondary.light,
            marginRight: "25px",
            width: { xs: "auto", md: "auto" },
          }}
          InputProps={{
            style: {
              height: 30,
              borderRadius: "8px",
              padding: "0px",
            },
            startAdornment: (
              <InputAdornment position="end" onClick={handleSearch}>
                <SearchIcon className={classes.searchIcon} />
              </InputAdornment>
            ),
          }}
        />
      </ToolTip>
      <DialogWithCloseIcon
        open={dialog}
        handleClose={handleClose}
        dialogSize={"sm"}
      >
        <FreeTrialDialog
          dialogTitle={t("upgrade_premium")}
          dialogDesc={t("free_limit_reached")}
          onClick={() => {
            navigate("/payment-services");
            handleClose();
          }}
        />
      </DialogWithCloseIcon>
    </Grid>
  );
}
