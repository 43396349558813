import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  "@global": {
    p: {
      fontSize: 10,
    },
  },
  marginHeader: {
    marginTop: "20px",
  },
  anchorStyle: {
    textDecoration: "none",
    textTransform: "uppercase",
    whiteSpace: "nowrap",
    cursor: "pointer",
    width: "100%",
    color: "inherit",
  },

  tabListStyle: {
    padding: "12px 16px 12px 16px",
    cursor: "pointer",
    display: "flex",
    color: "inherit",
    [theme.breakpoints.down("md")]: {
      padding: "8px",
    },
  },
  activeTabListStyle: {
    padding: "12px 16px 12px 16px",
    cursor: "pointer",
    color: theme.palette.primary.main,
    display: "flex",
    [theme.breakpoints.down("md")]: {
      padding: "8px",
    },
  },
  circle: {
    minWidth: 22,
    height: 22,
    borderRadius: 11,
    backgroundColor: "rgba(71, 154, 233, 0.5)",
    marginRight: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  tabContainer: {
    width: "100%",
    display: "flex",
    position: "sticky",
    top: "18px",
    zIndex: 1200,
    overflowY: "auto",
    height: "95vh",
    overflowX: "hidden",
  },
  scrollpyStyles: {
    display: "flex",
    flexDirection: "column",
    listStyleType: "none",
    justifyContent: "flex-start",
    width: "100%",
    paddingLeft: 0,
    overflow: "visible",
    wordBreak: "break-all",
    color: theme.palette.info.light,
  },

  spinnerContainer: {
    flexDirection: "row",
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(20, 0),
  },
  active: {
    color: theme.palette.text.primary,
  },
}));

export default useStyles;
