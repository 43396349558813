import React, { useContext, useEffect } from "react";
import { useCookies } from "react-cookie";
import { httpGetUnsecure } from "Utils/HttpFunctions";
import { useNavigate } from "react-router-dom";
import { authContext } from "context/Auth";
import loggingInDev from "loggingInDev";
import { LoginLoader } from "components/LoginLoader";
import useStyle from "./styles";

export default function GoogleSignin() {
  const navigate = useNavigate();
  const [cookie] = useCookies();
  const { setIsLogged } = useContext(authContext);
  const classes = useStyle();

  useEffect(() => {
    getAuth();
  }, []);

  const getAuth = async () => {
    loggingInDev("window location ==>", window.location);
    const path = `/google/callback${
      window.location.search
    }&offset=${new Date().getTimezoneOffset()}&timezone=${
      Intl.DateTimeFormat().resolvedOptions().timeZone
    }`;
    const response = await httpGetUnsecure(path);

    if (response && response.constructor !== String) {
      loggingInDev("response inside component ===>", response);
      const data = response.data.user;

      localStorage.setItem("token", data.token);
      localStorage.setItem("first_name", data.first_name);
      localStorage.setItem("last_name", data.last_name);
      localStorage.setItem("refresh_token", data.refresh_token);
      localStorage.setItem("isEmailVerified", data.isEmailVerified);
      localStorage.setItem("user_id", data.id);
      localStorage.setItem("email", data.email);
      localStorage.openpages = Date.now();
      sessionStorage.setItem("loggedIn", true);
      console.log("data ===> ", data);
      setIsLogged(true);
      const redirectPath = cookie.redirectPath;
      if (redirectPath) {
        if (!data.is_welcomed) {
          navigate(`${redirectPath}&userSignup=true&isCalendar=true`, {
            replace: true,
          });
        } else {
          navigate(redirectPath);
        }
      } else {
        if (!data.is_welcomed) {
          navigate("/dashboard?userSignup=true&isCalendar=true", {
            replace: true,
          });
        } else {
          navigate("/dashboard", { replace: true });
        }
      }
    } else {
      navigate("/login?access_error=true");
      return;
    }
  };

  return (
    <div className={classes.centerLoader}>
      <LoginLoader />
    </div>
  );
}
