import React from "react";
import { Typography, Box, useTheme, useMediaQuery } from "@mui/material";
import { ReactComponent as CircleLogo } from "assets/skeding-circle-logo.svg";
import { useTranslation } from "react-i18next";

export default function SkedingBanner({ removePadding }) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();

  return (
    <Box
      style={{
        paddingTop: mobile || removePadding ? null : "5%",
        width: removePadding ? "auto" : "250px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography variant="body2">{t("powered_by")}</Typography>

      <CircleLogo style={{ marginLeft: "2px", width: 70, height: 25 }} />
    </Box>
  );
}
