import { Box } from "@mui/system";
import { Player } from "@lottiefiles/react-lottie-player";
import Animate from "assets/skeding-loader.json";
import { useRef } from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export const LoginLoader = () => {
  const ref = useRef("");
  const { t } = useTranslation();

  return (
    <Box>
      <Player
        ref={ref}
        autoplay={true}
        loop={true}
        controls={true}
        src={Animate}
        speed={1}
        style={{
          height: "100px",
        }}
      >
        <Typography
          style={{
            fontSize: "16px",
          }}
        >
          {t("login_message")}
        </Typography>
      </Player>
    </Box>
  );
};
