import { useContext, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import EventIcon from "@mui/icons-material/Event";
import SettingsIcon from "@mui/icons-material/Settings";
import ContactsIcon from "@mui/icons-material/Contacts";
import ContactSupportOutlinedIcon from "@mui/icons-material/ContactSupportOutlined";
import AutoAwesomeMosaicOutlinedIcon from "@mui/icons-material/AutoAwesomeMosaicOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import WebIcon from "@mui/icons-material/Web";
import { BsPeople } from "react-icons/bs";
import { ReactComponent as SkedingBetaIcon } from "../../assets/gradient-beta-logo-menu.svg";
import styles from "styles/styles.module.css";
import NavigationButton from "./NavigationButton";
import QRCodeButton from "../QrCode/QRCodeButton";
import LogoutButton from "./LogoutButton";
import TourButton from "./TourButton";
import InstantMeeting from "./InstantMeeting";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import { accountContext } from "../../context/user";
import useStyle from "./styles";
/**
 * Content loaded in the left-hand side navigation drawer
 */
export default function DrawerContent({
  handleDrawerToggle,
  isMobile,
  classes,
}) {
  const navigate = useNavigate();
  const drawerScrollSection = useRef(null);
  const [instantMeetingPopup, setInstantMeetingPopup] = useState(false);
  const { t } = useTranslation();
  const { user } = useContext(accountContext);
  const pclasses = useStyle();
  // const showManage = user?.package?.quantity > 1 ? true : false;

  return (
    <Box style={{ height: "97vh" }}>
      <Box className={pclasses.drawerLogo}>
        <SkedingBetaIcon
          className={pclasses.logoIcon}
          onClick={() => {
            navigate("/");
            handleDrawerToggle();
          }}
        />
      </Box>
      <Box className={pclasses.drawerContent}>
        <Box display="flex" flexDirection="column" style={{ height: "75%" }}>
          <Box
            className={`${styles.upper} ${pclasses.drawerContentInside}`}
            ref={drawerScrollSection}
          >
            <NavigationButton
              link={"/dashboard"}
              icon={<EventIcon className={classes.icon} />}
              name={t("my_calendar")}
              classProp="calendar"
              handleDrawerToggle={handleDrawerToggle}
              isMobile={isMobile}
            />
            <NavigationButton
              link={"/instant-meeting"}
              icon={<AddIcon className={classes.icon} />}
              name={t("instant_meeting")}
              classProp="instant-meeting"
              handleDrawerToggle={handleDrawerToggle}
              isMobile={isMobile}
              isInstantPopup={true}
              instantMeetingPopup={instantMeetingPopup}
              setInstantMeetingPopup={setInstantMeetingPopup}
            />
            <NavigationButton
              link={"/createmeeting"}
              icon={<PlaylistAddIcon className={classes.icon} />}
              name={t("create") + " " + t("meeting")}
              classProp="new-meeting"
              handleDrawerToggle={handleDrawerToggle}
              isMobile={isMobile}
              meetingPopup={isMobile ? false : true}
            />

            {/* {showManage && (
              <NavigationButton
                link={"/manage-members"}
                icon={<BsPeople className={classes.icon} />}
                name={t("manage_members")}
                classProp="manage"
                handleDrawerToggle={handleDrawerToggle}
                isMobile={isMobile}
              />
            )} */}

            <NavigationButton
              link={"/meetings"}
              icon={<MoreHorizIcon className={pclasses.meetingsIcon} />}
              name={t("pending_meetings")}
              classProp="meetings"
              handleDrawerToggle={handleDrawerToggle}
              isMobile={isMobile}
            />
            <NavigationButton
              link={"/shareable-meeting"}
              icon={<AutoAwesomeMosaicOutlinedIcon className={classes.icon} />}
              name={t("sharebale_mmeting")}
              classProp="templates"
              handleDrawerToggle={handleDrawerToggle}
              isMobile={isMobile}
            />
            <NavigationButton
              link={"/preferences"}
              icon={<SettingsIcon className={classes.icon} />}
              name={t("preferences_heading")}
              classProp="Preferences"
              handleDrawerToggle={handleDrawerToggle}
              isMobile={isMobile}
            />
            <NavigationButton
              link={"/contacts"}
              icon={<ContactsIcon className={classes.icon} />}
              name={t("contacts")}
              classProp="contacts"
              handleDrawerToggle={handleDrawerToggle}
              isMobile={isMobile}
            />
            {/* BR-1 */}
            <NavigationButton
              link={"/add-to-website"}
              icon={<WebIcon className={classes.icon} />}
              name={t("add_to_web")}
              classProp="website"
              handleDrawerToggle={handleDrawerToggle}
              isMobile={isMobile}
            />
            <NavigationButton
              link={"/email"}
              icon={<WebIcon className={classes.icon} />}
              name={t("email")}
              classProp="email"
              handleDrawerToggle={handleDrawerToggle}
              isMobile={isMobile}
            />
            <NavigationButton
              link={"https://skeding-new-site.webflow.io/faqs"}
              icon={<ContactSupportOutlinedIcon className={classes.icon} />}
              name={t("faq")}
              classProp="faq"
              handleDrawerToggle={handleDrawerToggle}
              isMobile={isMobile}
            />
            <TourButton t={t} />
            {/* BR-1 */}
            {/* {!small && <TourButton setRun={props.props.setRun} />} */}

            {/* <ToggleButton
                classProp="mode"
                handleDrawerToggle={handleDrawerToggle}
                icon={<DarkModeIcon />}
              /> */}
          </Box>
        </Box>

        {/* QR code */}
        <Box
          mt="30px"
          display="flex"
          flexDirection="column"
          sx={{
            justifyContent: { xs: "flex-start", md: "flex-end" },
          }}
        >
          <QRCodeButton
            handleDrawerToggle={handleDrawerToggle}
            isMobile={isMobile}
            t={t}
          />
          <LogoutButton t={t} />
        </Box>
        <InstantMeeting
          instantMeeting={instantMeetingPopup}
          setInstantMeeting={setInstantMeetingPopup}
        />
      </Box>
    </Box>
  );
}
