import React, { useState, useEffect } from "react";

import {
  Avatar,
  IconButton,
  useMediaQuery,
  Typography,
  Box,
  Popover,
  useTheme,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import { useStyles } from "../../styles/popoverElement";
import { stringToColor, getContactInitials } from "../../Utils/CommonFunctions";
import { Group, GroupProps } from "../../interfaces";
import DeleteConfirmation from "./DeleteConfirmation";
import contactsSkeletons from "./ContactsSkeletons";

/**
 * Renders the contacts for a selected group
 * @param {object} selectedGroup - the selected group being displayed
 */
export default function TeamContacts({
  tab,
  groups,
  groupsFetched,
  palette,
  selectedGroup,
  setSelectedGroup,
  setEditTeam,
  getGroups,
}) {
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [groupPopover, setGroupPopover] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const classes = useStyles();

  const handleMoreClickGroup = (e, group: Group) => {
    setAnchorEl(e.target);
    setSelectedGroup(group);
    setGroupPopover(!groupPopover);
  };

  const handleBlurGroup = (e) => {
    setSelectedGroup("");
    setGroupPopover(!groupPopover);
  };

  const handleDelete = () => {
    setGroupPopover(!groupPopover);
    setDeleteConfirm(!deleteConfirm);
  };

  const handleShowGroup = () => {
    if (setEditTeam) {
      setEditTeam(true);
    }
  };

  return (
    <>
      {groupsFetched === false ? (
        contactsSkeletons("groups")
      ) : groupsFetched && groups.length > 0 ? (
        <>
          <Box
            data-group={tab}
            overflow="auto"
            display="flex"
            flexDirection="column"
            sx={{
              margin: { md: { margin: "0px 18px" } },
            }}
          >
            {groups.map((group, i) => {
              return (
                <Box
                  display="flex"
                  alignItems="center"
                  style={{ marginTop: "15px" }}
                  key={i}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      width: "50%",
                      cursor: "pointer",
                      textTransform: "capitalize",
                    }}
                    align="left"
                    onClick={() => {
                      setSelectedGroup(group);
                      handleShowGroup();
                    }}
                  >
                    {group.group_name}
                  </Typography>
                  <Box
                    display={"flex"}
                    style={{ width: "50%", justifyContent: "space-between" }}
                  >
                    <Box display="flex">
                      {group.contacts.map((teamMember, j) => {
                        const teamMemberInitials =
                          getContactInitials(teamMember);
                        if (j === 5) return;

                        return (
                          <Avatar
                            sx={{
                              ml: "-7px",
                              bgcolor: stringToColor(
                                teamMember.contact_name,
                                palette
                              ),
                              fontSize: "10px",
                              color: theme.palette.text.primary,
                              fontWeight: "500",
                              width: "24px",
                              height: "24px",
                            }}
                            key={j}
                          >
                            {teamMemberInitials[0].toUpperCase()}
                            {teamMemberInitials.length > 1
                              ? teamMemberInitials[1].toUpperCase()
                              : null}
                          </Avatar>
                        );
                      })}
                      {group.contacts.length > 5 ? <div>...</div> : null}
                    </Box>
                    <IconButton
                      onClick={(e) => handleMoreClickGroup(e, group)}
                      data-id={group._id}
                      sx={{ color: theme.palette.text.primary, padding: "0px" }}
                    >
                      <MoreHorizIcon />
                    </IconButton>
                    <Popover
                      open={groupPopover}
                      anchorEl={anchorEl}
                      onClose={handleBlurGroup}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      PaperProps={{
                        elevation: 1,
                        sx: {
                          width: 200,
                          borderRadius: "8px",
                          // boxShadow: "2px 3px 3px 2px #00000026",
                        },
                      }}
                      // style={{}}
                    >
                      <Box
                        onClick={handleShowGroup}
                        className={classes.popOverElement}
                      >
                        <Typography sx={{ p: 2 }}>Edit</Typography>
                      </Box>
                      <Box
                        onClick={handleDelete}
                        className={classes.popOverElement}
                      >
                        <Typography sx={{ p: 2 }}>Delete</Typography>
                      </Box>
                    </Popover>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </>
      ) : null}

      <DeleteConfirmation
        deleteConfirm={deleteConfirm}
        setDeleteConfirm={setDeleteConfirm}
        refreshFunction={getGroups}
        type={"group"}
        selected={selectedGroup}
        setSelected={null}
      />
    </>
  );
}
