import makeStyles from "@mui/styles/makeStyles";
const useStyle = makeStyles((theme) => ({
  accordion: {
    boxSizing: "border-box",
    marginBottom: "16px",
    borderRadius: "12px",
    background: "none",
    width: "100%",
    border: "none",

    "&:before": {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      border: "1px solid #E0E2E7",
      padding: "12px",
    },
  },

  avatar: {
    height: "40px",
    width: "40px",
    marginRight: "20px",
    color: theme.palette.text.primary,
    fontWeight: "500",
    fontSize: "16px",

    [theme.breakpoints.up("md")]: {
      marginRight: "28px",
    },
  },
  checkbox: {
    color: theme.palette.text.primary,
    paddingRight: "0px",
    paddingLeft: "0px",
    "& .MuiSvgIcon-root": { fontSize: 18, borderRadius: "4px" },
    [theme.breakpoints.up("md")]: {
      paddingRight: "28px",
    },
  },
  mobileWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "55%",
    overflow: "auto",
  },
  desktopWrapper: {
    display: "flex",
    width: "100%",
    alignItems: "center",
  },
  centerBetween: {
    alignItems: "center",
    justifyContent: "space-between",
  },
  centerBaseline: {
    alignItems: "baseline",
    justifyContent: "space-between",
  },
  textBtn: {
    paddingRight: "0px",
    fontSize: "14px",
  },

  textInput: {
    color: theme.palette.text.primary,
    backgroundColor: "#F7F7F9",
    borderRadius: "6px",
    paddingLeft: "8px",
    marginTop: "0px",
    width: "100%",
  },

  createInput: {
    fontSize: "16px",
    fontWeight: 400,
    color: theme.palette.text.primary,
    border: "1px solid #DAD2E5",
    borderRadius: "6px",
    paddingLeft: "8px",
  },

  dialogContent: {
    flexDirection: "column",
    justifyContent: "center",
    width: "auto",
  },

  textCrumb: {
    color: theme.palette.primary.main,
    cursor: "pointer",
    marginLeft: "5%",
  },

  groupName: {
    color: "#6F727A",
    textTransform: "capitalize",
    width: "max-content",
  },

  groupNameTeam: {
    textTransform: "capitalize",
    fontSize: "18px",
    fontWeight: 500,
    marginLeft: "5%",
  },

  smallAvatar: {
    marginRight: "5%",
    width: "24px",
    height: "24px",
    fontSize: "10px",
    color: theme.palette.text.primary,
    textTransform: "capitalize",
  },
}));

export default useStyle;
