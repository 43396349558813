import { Tab } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: "none",
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.h4.fontSize,
      marginRight: theme.spacing(1),
      color: "#70757A",
      "&.Mui-selected": {
        color: "#3C4242",
        fontWeight: "500",
      },
      "&.Mui-focusVisible": {
        backgroundColor: "rgba(100, 95, 228, 0.32)",
      },
    })
);

export default StyledTab;