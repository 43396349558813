import { useState, useEffect } from "react";

import { Button, useTheme } from "@mui/material";

import { serverPOST } from "Utils/HttpFunctions";
import SnackbarComponent from "../Global/SnackbarComponent";
import ErrorSnackbar from "../ErrorSnackbar";
import { useTranslation } from "react-i18next";
import useContactStore from "../../Utils/contactStore";
import ToolTip from "../Global/ToolTip";

/**
 * Button for inviting a group of contacts to become skeding users
 */
export default function InviteContactsButton({ setInviteSent }) {
  const [inviteSuccessful, setInviteSuccessful] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");
  const theme = useTheme();
  const [reset, setReset] = useState(false);
  const { t } = useTranslation();
  const {
    newGroupMembers: contactsToInvite,
    setNewGroupMembers: setContactsToInvite,
  } = useContactStore();

  /**
   * Enables the button only if at least one contact has been selected
   */
  useEffect(() => {
    if (contactsToInvite.length < 1) {
      setDisabled(true);
    } else setDisabled(false);
  }, [contactsToInvite]);

  useEffect(() => {
    setInviteSent(false);
  }, [reset]);

  /**
   * Makes a request to the backend API to send emails to all the invited contacts
   */
  const handleInviteContacts = async () => {
    setError("");
    if (contactsToInvite.length < 1) return;

    const emailArray = contactsToInvite.map((contact) => contact.email);

    const body = {
      contacts: emailArray,
    };

    const response = await serverPOST("/contact/invite", body);

    if (response && response.constructor !== String) {
      if (!response?.data?.success) {
        setError(t("skeding_user_err"));
      }
      if (response?.data?.success) {
        setInviteSuccessful(true);
      }
    } else {
      setError(
        "An error occurred while trying to invite your contacts, please try again"
      );
      setInviteSuccessful(false);
      // console.error(response)
    }
  };

  return (
    <>
      <ToolTip
        title={
          "Select users from your contact list and click on ‘Invite Contacts’ to invite your contacts to skeding."
        }
        placement={"top-end"}
      >
        <Button
          variant="outlined"
          onClick={handleInviteContacts}
          disabled={disabled}
          sx={{
            border: `1px solid ${theme.palette.primary.main}`,
            color: theme.palette.primary.main,
          }}
        >
          {t("invite_contacts")}
        </Button>
      </ToolTip>

      {error ? (
        <ErrorSnackbar
          open={!!error}
          handleClose={() => {
            setError("");
            setContactsToInvite([]);
            // initial true to make checked invites unchecked
            setInviteSent(true);
            setReset((prev) => !prev);
          }}
          message={error}
          vertical={"bottom"}
          horizontal={"center"}
        />
      ) : (
        <SnackbarComponent
          open={inviteSuccessful}
          handleClose={() => {
            setInviteSuccessful(false);
            setContactsToInvite([]);
            // initial true to make checked invites unchecked
            setInviteSent(true);
            setReset((prev) => !prev);
          }}
          message={t("invite_success")}
          vertical={"bottom"}
          horizontal={"center"}
        />
      )}
    </>
  );
}
