import React from "react";
import ProfilePicture from "../Account/ProfilePicture";
import { accountContext } from "context/user";
import { useContext } from "react";
import { useState } from "react";
import { serverPUT } from "Utils/HttpFunctions";
import loggingInDev from "loggingInDev";
import { useEffect } from "react";
import useQRCodeStore from "../../Utils/qrCodeStore";
import { convertBase64 } from "../../Utils/CommonFunctions";

export default function EditProfilePicture({ mobile }) {
  const [imageOpen, setImageOpen] = useState(false);
  const { user, getUser } = useContext(accountContext);
  const [refresh, setRefresh] = useState(false);
  const [uploadError, setUploadError] = useState("");
  const [disabledImage, setDisabledImage] = useState(false);
  const { formData, setFormData } = useQRCodeStore();

  useEffect(() => {
    getUser();
  }, [refresh]);

  const handleImageOpen = () => {
    setImageOpen(true);
  };

  const handleImageClose = () => {
    setImageOpen(false);
  };

  const handleImage = async (image) => {
    setDisabledImage(true);
    const base64 = await convertBase64(image);
    if (base64) {
      const data = {
        picture: base64,
      };

      const response = await serverPUT(`/account`, data);
      if (response && response.constructor !== String) {
        loggingInDev("image modified =>", response);
        setRefresh(!refresh);
        setDisabledImage(false);
        setImageOpen(false);
        setFormData({
          ...formData,
          user_id: { ...formData.user_id, picture: base64 },
        });
      } else {
        loggingInDev("error =>", response);
        setUploadError(response);
        setDisabledImage(false);
      }
    }
  };

  return (
    <ProfilePicture
      user={user}
      handleImageOpen={handleImageOpen}
      handleImageClose={handleImageClose}
      imageOpen={imageOpen}
      handleImage={handleImage}
      mobile={mobile}
      disabledImage={disabledImage}
      // uploadError={uploadError}
    />
  );
}
