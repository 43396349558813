import React, { useState, useEffect } from "react";

import { serverGET } from "Utils/HttpFunctions";
import {
  convertUTCMillisecondsToDate,
  checkLocation,
  sortEventsByStartTime,
} from "Utils/CommonFunctions";
import {
  MEETING_INVITES_COLOR,
  PENDING_MEETINGS_COLOR,
  RECURRING_EVENT,
  WEBSITE_BOOKINGS_COLOR,
} from "Utils/Constants";

export const meetingsContext = React.createContext();

export default function MeetingsProvider({ children }) {
  const [pendingMeetings, setPendingMeetings] = useState([]);
  const [meetingInvites, setMeetingInvites] = useState([]);
  const [websiteBookings, setWebsiteBookings] = useState([]);
  const [loadingPendingMeetings, setLoadingPendingMeetings] = useState(true);
  const [loadingMeetingInvites, setLoadingMeetingInvites] = useState(true);
  const [noPendingMeetings, setNoPendingMeetings] = useState(false);
  const [noMeetingInvites, setNoMeetingInvites] = useState(false);
  const [meetingDeleted, setMeetingDeleted] = useState(false);
  const [meetingResponded, setMeetingResponded] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();
    getPlannedEvents(abortController);

    return () => {
      abortController.abort();
    };
  }, []);

  const getPlannedEvents = async (abortController) => {
    setLoadingPendingMeetings(true);
    setLoadingMeetingInvites(true);

    const response = await serverGET("/meeting/all", abortController.signal);

    if (response && response.constructor !== String) {
      const formattedPendingMeetings = await response.data.pendingMeetings;
      const formattedMeetingInvites = await response.data.meetingInvites;
      const formattedWebisteBookings = await response.data.websiteBookings;
      const formattedSearchEvents = await response.data.searchEvents;

      let tempPendingMeetings = [];
      formattedPendingMeetings.forEach((event) => {
        tempPendingMeetings.push({
          id: event.id,
          title: event.title,
          start: convertUTCMillisecondsToDate(event.start_datetime),
          end: convertUTCMillisecondsToDate(event.end_datetime),
          proposedTimeslots: event?.proposed_timeslots,
          location: event.location,
          description: event.description,
          skedingParticipants: event.skeding_participants,
          nonskedingParticipants: event.non_skeding_participants,
          nonskedingParticipantsNotResponded:
            event.non_skeding_participants_not_responded,
          nonskedingParticipantPollingStatus:
            event.non_skeding_participants_polling_status,
          totalAccepted: event.total_accepted,
          totalResponded: event.total_responded,
          duration: event.duration_minutes,
          fileName: event?.attachment_file_name,
          case: event?.case,
          backgroundColor: PENDING_MEETINGS_COLOR,
          createdTime: event.created_at,
          // only for single slot meeting case
          nonSkedingParticipantsRejected:
            event?.non_skeding_participants_rejected,
        });
      });

      // sortEventsByStartTime(tempPendingMeetings);
      setPendingMeetings(tempPendingMeetings);

      let tempMeetingInvites = await formattedMeetingInvites.map((event) => {
        return {
          id: event.id,
          title: event.title,
          start: convertUTCMillisecondsToDate(event.start_datetime),
          end: convertUTCMillisecondsToDate(event.end_datetime),
          description: event?.description,
          location: event.location,
          skedingParticipants: event.skeding_participants,
          nonskedingParticipants: event.non_skeding_participants,
          nonskedingParticipantPollingStatus:
            event.non_skeding_participants_polling_status,
          totalAccepted: event.total_accepted,
          totalResponded: event.total_responded,
          initiator: event.initiator_name,
          initiatorEmail: event.initiator_email,
          duration: event.duration_minutes,
          case: event?.case,
          fileName: event?.attachment_file_name,
          backgroundColor: MEETING_INVITES_COLOR,
          // only for single slot meeting case
          nonSkedingParticipantsRejected:
            event?.non_skeding_participants_rejected,
        };
      });
      setMeetingInvites(tempMeetingInvites);

      // BR-1
      let tempWebsiteBookings = await formattedWebisteBookings.map((event) => {
        return {
          id: event.id,
          title: event.title,
          start: convertUTCMillisecondsToDate(event.start_datetime),
          end: convertUTCMillisecondsToDate(event.end_datetime),
          location: event.location,
          skedingParticipants: event.skeding_participants,
          nonskedingParticipants: event.non_skeding_participants,
          nonskedingParticipantsNotResponded:
            event.non_skeding_participants_not_responded,
          totalAccepted: event.total_accepted,
          duration: event.duration_minutes,
          description: event?.description,
          fileName: event?.attachment_file_name,
          non_skeding_participant_data: event?.non_skeding_participant_data,
          color: "#C4E7FF",
          backgroundColor: WEBSITE_BOOKINGS_COLOR,
          totalResponded: 1,
        };
      });
      setWebsiteBookings(tempWebsiteBookings);

      // BR-1
      // let tempSearchEvents = await formattedSearchEvents.map((event) => {
      //   return {
      //     id: event.id,
      //     title: event.title,
      //     start: convertUTCMillisecondsToDate(event.start_datetime),
      //     end: convertUTCMillisecondsToDate(event.end_datetime),
      //     location: event.location,
      //     skedingParticipants: event.skeding_participants,
      //     nonskedingParticipants: event.non_skeding_participants,
      //     nonskedingParticipantsNotResponded:
      //       event.non_skeding_participants_not_responded,
      //     totalAccepted: event.total_accepted,
      //     duration: event.duration_minutes,
      //     description: event?.description,
      //     fileName: event?.attachment_file_name,
      //     non_skeding_participant_data: event?.non_skeding_participant_data,
      //     color: "#C4E7FF",
      //   };
      // });
      // setSearchEvents(tempSearchEvents);

      setMeetingDeleted(false);
      setMeetingResponded(false);
    } else {
      if (pendingMeetings.length === 0) {
        setNoPendingMeetings(true);
      }
      if (meetingInvites.length === 0) {
        setNoMeetingInvites(true);
      }
    }
    setLoadingPendingMeetings(false);
    setLoadingMeetingInvites(false);
  };

  return (
    <meetingsContext.Provider
      value={{
        pendingMeetings,
        getPlannedEvents,
        setPendingMeetings,
        meetingInvites,
        setMeetingInvites,
        websiteBookings,
        setWebsiteBookings,
        loadingMeetingInvites,
        setLoadingMeetingInvites,
        loadingPendingMeetings,
        setLoadingPendingMeetings,
        noPendingMeetings,
        setNoPendingMeetings,
        noMeetingInvites,
        setNoMeetingInvites,
        meetingDeleted,
        setMeetingDeleted,
        meetingResponded,
        setMeetingResponded,
      }}
    >
      {children}
    </meetingsContext.Provider>
  );
}
