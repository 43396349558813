import { useState, useRef } from "react";
import { NavLink } from "react-router-dom";

import {
  Typography,
  Box,
  Button,
  Divider,
  Checkbox,
  useTheme,
  Grid,
  useMediaQuery,
} from "@mui/material";
import ErrorSnackbar from "components/ErrorSnackbar";
import { ExtLogins } from "pages/MeetingSignup/UserSignup";
import { colors } from "Utils/colors";
import { serverPOST } from "Utils/HttpFunctions";
import EmailForm from "components/EmailForm";
import PasswordRequirements from "components/password recovery/PasswordRequirements";
import PasswordsForm from "components/PasswordsForm";
import RequirementsErrorMessage from "components/password components/RequirementsErrorMessage";
import MatchingErrorMessage from "components/password components/MatchingErrorMessage";
import loggingInDev from "loggingInDev";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
export default function SignupForm({
  email,
  setEmail,
  values,
  setValues,
  setSignupClick,
  setShowTerms,
}) {
  const [emailValid, setEmailValid] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [matchError, setMatchError] = useState(false);
  const [matchHelperText, setMatchHelperText] = useState("");
  const [validationHelper, setValidationHelper] = useState("");
  const [showRequirements, setShowRequirements] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [signupError, setSignupError] = useState("");
  const recaptchaRef = useRef();
  const [checkTerms, setCheckTerms] = useState(false);
  const [checkPolicy, setCheckPolicy] = useState(false);
  const validationRef = useRef(null);
  const matchingRef = useRef(null);
  const theme = useTheme();
  const [error, setError] = useState("");
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();

  const validationRegex =
    /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[-+_!@#$%^&*.,?])/;

  const handlePasswordValidation = () => {
    switch (validatePassword()) {
      case true:
        setValidationError(false);
        setValidationHelper("");
        handleMatchPasswords();
        break;
      case false:
        setValidationError(true);
        setValidationHelper(<RequirementsErrorMessage />);
        break;
      default:
        break;
    }
  };

  const validatePassword = () => {
    switch (
      validationRef.current.value.length > 8 &&
      validationRegex.test(validationRef.current.value)
    ) {
      case true:
        return true;
      default:
        return false;
    }
  };

  const handleMatchPasswords = () => {
    switch (validationRef.current.value !== matchingRef.current.value) {
      case true:
        setPasswordValid(false);
        setMatchError(true);
        setMatchHelperText(<MatchingErrorMessage />);
        break;
      default:
        setPasswordValid(true);
        setMatchError(false);
        setMatchHelperText("");
        handleRegister();
        break;
    }
  };

  const handleRegister = async () => {
    if (!checkTerms || !checkPolicy) {
      setError(t("terms_err"));
      return;
    }
    if (!recaptchaRef.current.getValue()) {
      console.log("invalid captcha");
      return;
    }
    if (emailValid) {
      setDisabled(true);
      const response = await serverPOST("/auth/register", {
        email: email,
        password: values.password1,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        captcha: recaptchaRef.current.getValue(),
      });
      console.log("response: ", response);
      if (response && response.constructor !== String) {
        if (response.code === 201) {
          loggingInDev("link sent =>", response);
          setSignupClick(true);
          setDisabled(false);
        }
      } else {
        loggingInDev("error =>", response);
        setDisabled(false);
        setSignupError(response);
      }
    }
  };

  return (
    <Box
      style={{
        width: "fit-content",
      }}
    >
      <Typography
        variant="h2"
        align={"center"}
        style={{ marginTop: 20, marginBottom: 20 }}
      >
        {t("signup_free")}
      </Typography>
      <ExtLogins />
      <Box
        mt={2}
        mb={2}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Divider sx={{ width: "40%" }}>
          <div>{t("or")}</div>
        </Divider>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        rowGap="10px"
        alignItems={"center"}
      >
        {signupError !== "" ? (
          <Typography color="red">{signupError}</Typography>
        ) : null}
        <EmailForm
          setEmailValid={setEmailValid}
          email={email}
          setEmail={setEmail}
        />
      </Box>
      <Box mt={1.5}>
        <PasswordsForm
          setShowRequirements={setShowRequirements}
          setPasswordValid={setPasswordValid}
          validationError={validationError}
          setValidationError={setValidationError}
          matchError={matchError}
          setMatchError={setMatchError}
          matchHelperText={matchHelperText}
          validationHelper={validationHelper}
          setValidationHelper={setValidationHelper}
          values={values}
          setValues={setValues}
          validationRef={validationRef}
          matchingRef={matchingRef}
        />
      </Box>
      <Box display="flex" justifyContent={"center"} mt={1.5}>
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey="6LdwPyEjAAAAADew9Uyt4UOQjPoo4zWncUaVs_IU"
          type={"image"}
          size={"normal"}
          onChange={(e) => (recaptchaRef.current.value = e)}
        />
      </Box>
      <Box mt={1.5}>
        <Button
          variant="contained"
          sx={{
            borderRadius: "8px",
            backgroundColor: colors.primary,
            width: "100%",
            maxWidth: "520px",
            fontSize: "14px",
            fontWeight: 500,
            paddingTop: "8px",
            paddingBottom: "8px",
          }}
          onClick={handlePasswordValidation}
          disabled={disabled}
        >
          {t("create_account")}
        </Button>
      </Box>
      <Box
        display={"flex"}
        alignItems={mobile ? "flex-start" : "center"}
        mt={"14px"}
        mb={"5px"}
      >
        <Checkbox
          style={{
            padding: "0 12px 0 0",
          }}
          size="small"
          checked={checkTerms}
          onChange={() => setCheckTerms(!checkTerms)}
          sx={{
            color: theme.palette.text.primary,
            "&.Mui-checked": {
              color: "#F7F7F9",
              stroke: theme.palette.text.primary,
              strokeWidth: "2px",
              strokeLineJoin: "round",
            },
          }}
        />
        <Grid
          display={"flex"}
          direction={mobile ? "column" : "row"}
          textAlign={"left"}
        >
          <Typography variant="h4" pr={0.5} textAlign={"left"}>
            {t("agree_text")}
          </Typography>
          <Typography
            align="left"
            variant="h4"
            ml={0.5}
            style={{
              color: colors.primary,
              cursor: "pointer",
              textDecoration: "underline",
            }}
            textAlign={"left"}
            onClick={() => setShowTerms((prev) => !prev)}
          >
            {t("terms_cond")}
          </Typography>
        </Grid>
      </Box>

      <Box display={"flex"} alignItems={mobile ? "flex-start" : "center"}>
        <Checkbox
          style={{
            padding: "0 12px 0 0",
          }}
          size="small"
          checked={checkPolicy}
          onChange={() => setCheckPolicy(!checkPolicy)}
          sx={{
            color: theme.palette.text.primary,
            "&.Mui-checked": {
              color: "#F7F7F9",
              stroke: theme.palette.text.primary,
              strokeWidth: "2px",
              strokeLineJoin: "round",
            },
          }}
        />
        <Grid
          display={"flex"}
          direction={mobile ? "column" : "row"}
          textAlign={"left"}
        >
          <Typography variant="h4" pr={0.5} textAlign={"left"}>
            {t("agree_text")}
          </Typography>
          <Typography
            align="left"
            variant="h4"
            ml={0.5}
            style={{
              color: colors.primary,
              cursor: "pointer",
              textDecoration: "underline",
            }}
            textAlign={"left"}
            onClick={() => {
              window.open("https://www.skeding.io/privacy-policy");
            }}
          >
            {t("privacy_policy")}
          </Typography>
        </Grid>
      </Box>

      <Typography style={{ marginTop: 16 }} align="center" variant="h3">
        {t("already_account")}{" "}
        <NavLink to="/login" style={{ color: theme.palette.primary.main }}>
          {t("login")}
        </NavLink>
      </Typography>
      <ErrorSnackbar
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        vertical={"bottom"}
        horizontal={"center"}
      />
    </Box>
  );
}
