import React from "react";
import {
  AlignmentType,
  Document,
  HeadingLevel,
  Packer,
  Paragraph,
  TextRun,
} from "docx";
import moment from "moment";

export class DocumentCreator {
  public create([transcript, summary, meeting, t]): Document {
    const meetingDate = `${moment(meeting?.start).format(
      "DD/MM/YYYY"
    )} | ${moment(meeting?.start).format("LT")} - ${moment(meeting?.end).format(
      "LT"
    )}`;

    const participants = [
      ...meeting.skedingParticipants.map((p) => p.email),
      ...meeting.nonskedingParticipants,
    ];
    const summarBlocks = summary.split(/\n\s*\n/);

    const document = new Document({
      sections: [
        {
          children: [
            new Paragraph({
              text: `Meeting: "${meeting.title}"`,
              heading: HeadingLevel.TITLE,
              alignment: AlignmentType.LEFT,
            }),
            this.createLineBreak(),
            this.createSubHeading(meetingDate),
            this.createLineBreak(),
            this.createSubHeading(t("organizer") + ":"),
            this.createSubHeading(meeting.initiator),
            this.createLineBreak(),
            this.createSubHeading(t("participants") + ":"),
            this.createSubHeading(participants.join(",")),
            this.createLineBreak(),
            this.createHeading(t("meeting_minutes")),
            ...summarBlocks.map((block) => {
              return this.createText(block.trim());
            }),
            this.createLineBreak(),
            this.createHeading(t("transcript")),
            ...transcript.map((block) => {
              return this.createText(block.trim());
            }),
          ],
        },
      ],
    });

    return document;
  }

  public createText(text: string): Paragraph {
    return new Paragraph({
      alignment: AlignmentType.LEFT,
      children: [new TextRun({ text, size: 24 })],
      spacing: {
        before: 200,
        after: 200,
      },
    });
  }

  public createLineBreak(): Paragraph {
    return new Paragraph({
      alignment: AlignmentType.LEFT,
      children: [new TextRun({ text: "" })],
    });
  }

  public createHeading(text: string): Paragraph {
    return new Paragraph({
      text: text,
      heading: HeadingLevel.HEADING_1,
      thematicBreak: true,
    });
  }

  public createSubHeading(text: string): Paragraph {
    return new Paragraph({
      text: text,
      heading: HeadingLevel.HEADING_2,
    });
  }
}

export const generateDoc = async (
  transcript: string,
  summary: string,
  meeting: any,
  t: any
): Promise<Blob> => {
  const documentCreator = new DocumentCreator();

  const doc = documentCreator.create([transcript, summary, meeting, t]);

  const blob = await Packer.toBlob(doc);
  console.log("🚀 ~ file: CreateSummary.tsx:65 ~ blob:", blob);
  return blob;
};
