import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Box,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReactComponent as LinkIcon } from "assets/link-icon.svg";
import useStyle from "./styles";

export default function CopyToClipboardDialog({
  open,
  handleCloseCopyToClipboard,
  copiedLink,
  setSnackBar,
}) {
  const { t } = useTranslation();
  const classes = useStyle();
  const handleCopyLink = () => {
    navigator.clipboard.writeText(copiedLink);
    setSnackBar(true);
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseCopyToClipboard}
      maxWidth={"xs"}
      fullWidth
      PaperProps={{ sx: { padding: 3, boxSizing: "border-box" } }}
    >
      <DialogTitle
        id="alert-dialog-title"
        sx={{
          textAlign: "center",
        }}
      >
        <Typography variant="h2">{t("copy_link")}</Typography>
      </DialogTitle>
      <DialogActions className={classes.copyContent}>
        <Box className={classes.copyDialog}>
          <Box display="flex" paddingTop="8px" width="auto">
            <LinkIcon />
          </Box>
          <input
            type="text"
            value={copiedLink}
            readOnly
            className={classes.copyInput}
          ></input>
          <Button variant="submit" onClick={handleCopyLink}>
            {t("copy")}
          </Button>
        </Box>
        <Button
          onClick={handleCloseCopyToClipboard}
          variant="outlined"
          //   style={{ padding: "8px 35px" }}
        >
          {t("close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
