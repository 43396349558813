import React, { useState } from "react";
import {
  Paper,
  Autocomplete,
  TextField,
  useTheme,
  InputAdornment,
  IconButton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
// import Geocode from "react-geocode";
import { serverGET } from "Utils/HttpFunctions";
import { useTranslation } from "react-i18next";

// Geocode.setApiKey("AIzaSyB2y_R_q5fMF7BW4frpSY6sEC60_eC--uU");
// Geocode.setLanguage("en");
// Geocode.enableDebug(false);

export default function SearchAutocomplete(props) {
  const theme = useTheme();
  const { t } = useTranslation();
  const [locations, setLocations] = useState([
    { label: "Loading...", id: 0, code: "Load" },
  ]);

  const handleOnChange = async (e, value) => {
    let temp = [];
    const response = await serverGET(
      `/search/findlocations?location=${e.target.value}`
    );
    if (response && response.constructor !== String) {
      console.log(response.data);
      temp = await response.data.data;
      setLocations(temp);
    }
    const loc = temp.find((l) => l.label === value);
    props.setLocation(loc);
  };

  const CustomPaper = (props) => {
    return (
      <Paper
        sx={{ textAlign: "start", padding: "0px" }}
        elevation={4}
        {...props}
      />
    );
  };
  return (
    <div>
      <Autocomplete
        disablePortal
        id="location-box"
        onInputChange={(event, newInputValue) =>
          handleOnChange(event, newInputValue)
        }
        options={locations}
        PaperComponent={CustomPaper}
        sx={{
          width: props.mobile ? 140 : 190,
          height: 28,
          backgroundColor: "transparent",
          border: "1px solid #DAD2E5",
          borderRadius: "6px",
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={t("search_by_location")}
            variant="standard"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                props.handleLocationSearch(props.location);
              }
            }}
            InputProps={{
              disableUnderline: true,
              style: {
                fontWeight: 400,
                fontSize: 14,
                height: 28,
                paddingRight: "0px",
                paddingLeft: "10px",
              },
              ...params.InputProps,
              endAdornment: (
                <>
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="search"
                      onClick={() => props.handleLocationSearch(props.location)}
                    >
                      <SearchIcon sx={{ fontSize: "17px", color: "#3C4242" }} />
                    </IconButton>
                  </InputAdornment>
                  {/* {params.InputProps.endAdornment} */}
                </>
              ),
            }}
          />
        )}
      />
    </div>
  );
}
