import {
  Box,
  Breadcrumbs,
  Button,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
// import useBulkEmailStore from "../../Utils/bulkEmailStore";
import { serverGET } from "../../Utils/HttpFunctions";
import EditIcon from "@mui/icons-material/EditOutlined";
import { CreateBulkEmail } from "./CreateBulkEmail";
import useBulkEmailStore from "../../Utils/bulkEmailStore";
import BreadCrumb from "../AddToWebsite/BreadCrumb";
// import { CreateBulkEmail } from "./CreateBulkEmail";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export default function BulkEmailTemplates({
  classes,
  pclasses,
  clearForm,
  handleFormData,
  fetchTemplates,
}) {
  const theme = useTheme();
  const [isEdit, setIsEdit] = useState(false);
  const setPassword = useBulkEmailStore((store) => store.setPassword);
  const setContactEmail = useBulkEmailStore((store) => store.setContactEmail);
  const setTemplateId = useBulkEmailStore((store) => store.setTemplateId);
  const setSelectedHost = useBulkEmailStore((store) => store.setSelectedHost);
  const setSubject = useBulkEmailStore((store) => store.setSubject);
  const setBody = useBulkEmailStore((store) => store.setBody);
  const setFiles = useBulkEmailStore((store) => store.setFiles);
  const setFileNames = useBulkEmailStore((store) => store.setFileNames);
  const setSignature = useBulkEmailStore((store) => store.setSignature);
  const templates = useBulkEmailStore((store) => store.templates);
  const setDeleteModal = useBulkEmailStore((store) => store.setDeleteModal);

  const handleEditTemplate = (template) => {
    setSubject(template.subject ? template.subject : "");
    setContactEmail(template.email ? template.email : "");
    setSelectedHost(template.host ? template.host : "");
    setPassword(template.password ? template.password : "");
    setTemplateId(template._id ? template._id : "");
    setBody(template.template.body ? template.template.body : "");
    setSignature(
      template.template.signature.text ? template.template.signature.text : ""
    );

    if (template.template.signature.image.length > 0) {
      setFileNames(["Singature-1"]);
      setFiles(template.template.signature.image);
    } else {
      setFileNames([]);
      setFiles([]);
    }
    setIsEdit(true);
  };

  return (
    <Box>
      {templates.length === 0 ? (
        <Typography variant="h3">No template found</Typography>
      ) : (
        <>
          {isEdit ? (
            <Box>
              <Box mb={2}>
                <Breadcrumbs
                  separator={<ArrowForwardIosIcon style={{ width: 12 }} />}
                  aria-label="breadcrumb"
                >
                  <Typography
                    variant={"h3"}
                    onClick={() => {
                      setIsEdit(false);
                      clearForm();
                    }}
                    style={{
                      textTransform: "capitalize",
                      cursor: "pointer",
                    }}
                  >
                    Templates
                  </Typography>
                  <Typography
                    variant={"h3"}
                    // onClick={selected >= index ? actions[index] : () => console.log("")}
                    fontWeight={500}
                    color={theme.palette.primary.main}
                    style={{
                      textTransform: "capitalize",
                      cursor: "default",
                    }}
                  >
                    Edit Template
                  </Typography>
                </Breadcrumbs>
              </Box>
              <CreateBulkEmail
                classes={classes}
                pclasses={pclasses}
                clearForm={clearForm}
                handleFormData={handleFormData}
                fetchTemplates={fetchTemplates}
              />
            </Box>
          ) : (
            templates.length > 0 && (
              <Box>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  {templates.map((template: any) => {
                    return (
                      <Box
                        style={{
                          backgroundColor: theme.palette.info.contrastText,
                          borderRadius: 8,
                          padding: "16px",
                          marginBottom: "12px",
                        }}
                      >
                        <Box
                          display={"flex"}
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Box alignItems="center" gap={1}>
                            <Typography
                              variant="h3"
                              fontWeight="500"
                              style={{
                                textTransform: "capitalize",
                                width: "220px",
                              }}
                              align="left"
                            >
                              {template.subject || "No Subject"}
                            </Typography>
                            <Typography
                              variant="h4"
                              align="left"
                              fontWeight={400}
                            >
                              {template.email}
                            </Typography>
                          </Box>

                          <Box>
                            <IconButton
                              onClick={() => setDeleteModal(template._id)}
                            >
                              <DeleteOutlineRoundedIcon
                                style={{
                                  color: "red",
                                  cursor: "pointer",
                                  fontSize: "16px",
                                }}
                              />
                            </IconButton>
                            <IconButton
                              onClick={() => handleEditTemplate(template)}
                            >
                              <EditIcon
                                style={{
                                  cursor: "pointer",
                                  fontSize: "16px",
                                }}
                              />
                            </IconButton>
                          </Box>
                        </Box>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            )
          )}
        </>
      )}
    </Box>
  );
}
