import { Typography, Box, Grid } from "@mui/material";
import useStyle from "styles/formStyles";
import Participants from "../NewEvent/Participants";
import useContactGroups from "../../Utils/hooks/useContactGroups";
import useParticipantStatus from "../../hooks/useParticipantStatus";

import ColorIndicator from "../meetings/ColorIndicator";
/**
 * The form that opens in a dialog when the user clicks the InviteCollaboratorsButton
 */
export default function InviteCollaboratorsForm({
  collaborators,
  setCollaborators,
  t,
}) {
  const [contacts, groups, minimizedContacts] = useContactGroups(collaborators);
  const classes = useStyle();
  const [participantLabels] = useParticipantStatus(collaborators);

  return (
    <>
      <Box
        display="flex"
        columnGap="8px"
        alignItems="center"
        width="100%"
        marginBottom="30px"
        className={classes.root}
      >
        <Typography fontSize={14} fontWeight={500}>
          {t("email")}
        </Typography>
        <Box className={classes.participant} style={{ width: "90%" }}>
          <Grid width={"100%"}>
            <Participants
              participants={collaborators}
              setParticipants={setCollaborators}
              participantLabels={participantLabels}
              contacts={contacts}
              isRequired={false}
              groups={groups}
              minimizedContacts={minimizedContacts}
            />
          </Grid>
          <Grid display={"flex"} alignItems={"center"}>
            <ColorIndicator text={"member"} color={"#B0E4FF"} />
            <ColorIndicator text={"non_member"} color={"#D5D6D7"} />
          </Grid>
        </Box>
      </Box>
    </>
  );
}
