import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  chip: {
    background: "#F7F7F9",
    color: theme.palette.secondary.dark,
    fontSize: theme.typography.h3.fontSize,
    "& .MuiChip-icon": {
      color: theme.palette.primary.main,
      fontSize: "14px",
    },
    marginLeft: "4px",
    borderRadius: "4px",
    maxWidth: "100px",
    height: "24px",
  },
  fullChip: {
    background: "#F7F7F9",
    color: theme.palette.secondary.dark,
    fontSize: theme.typography.h3.fontSize,
    "& .MuiChip-icon": {
      color: theme.palette.primary.main,
      fontSize: "14px",
    },
    width: "auto",
    height: "24px",
    marginLeft: "4px",
    borderRadius: "4px",
  },
}));
