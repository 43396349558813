import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function ColorIndicator({ text, color, border }) {
  const { t } = useTranslation();
  return (
    <Box display="flex" alignItems="center" mr="14px">
      <Box
        sx={{
          backgroundColor: color,
          width: 12,
          height: 12,
          borderRadius: "50%",
          mr: "4px",
          border: border ? `1px solid ${border}` : "none",
        }}
      />
      <Typography style={{ color: "#363B53" }} variant="body1" fontWeight="400">
        {t(text)}
      </Typography>
    </Box>
  );
}
