import { Box, Button, Popover, useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";
import TimezoneSelect from "react-timezone-select";
import { useTimezoneSelect, allTimezones } from "react-timezone-select";

// const labelStyle = "original";
// const timezones = {
//   ...allTimezones,
// };

export default function Timezones({
  selectedTimezone,
  setSelectedTimezone,
  isOrganizer,
}) {
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box>
      <TimezoneSelect
        value={selectedTimezone}
        onChange={(e: any) => setSelectedTimezone(e.value)}
        styles={{
          valueContainer: (provided, state) => ({
            ...provided,
            fontSize: small || isOrganizer ? "12px" : "14px",
            fontWeight: 400,
            backgroundColor: theme.palette.secondary.main,
            width: "100%",
            padding: "6px 8px",
            border: "none",
          }),
        }}
      />
    </Box>
  );
}
