import {
  Button,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ProfileAvatar from "../../components/ProfileAvatar";
import Facebook from "assets/facebook.svg";
import LinkedIn from "assets/linkedin.svg";
import Instagram from "assets/instagram.svg";
import Twitter from "assets/twitter.svg";
import { authContext } from "context/Auth";
import vCard from "vcf";
import { AiOutlinePlusCircle } from "react-icons/ai";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Youtube from "assets/youtube.svg";
import Snapchat from "assets/snapchat.svg";
import Tiktok from "assets/tiktok.svg";
import Shopify from "assets/shopify.svg";
import CloseIcon from "@mui/icons-material/Close";
import {
  serverPOST,
  serverGET,
  serverPUT,
  serverGETWithToken,
} from "Utils/HttpFunctions";
import EditProfilePicture from "../../components/QrCode/EditProfilePicture";
import { CardLabels } from "../../components/QrCode/CardLabels";
import { SocialCard } from "../../components/QrCode/SocialCard";
import Languages from "../../components/Account/Languages";
import { ReactComponent as EyeVisible } from "assets/eye_visible.svg";
import { ReactComponent as EyeHidden } from "assets/eye_hidden.svg";
import Phone from "../../components/NewEvent/Phone";
import { Link } from "react-router-dom";
import { isValidPhoneNumber } from "react-phone-number-input";
import ErrorSnackbar from "components/ErrorSnackbar";
import Spinner from "../../components/Global/Spinner";
import CustomTabPanel from "../../components/Global/CustomTabPanel";

export default function QRCodeCard({
  handleScheduleMeeting,
  token,
  is_user,
  setQRCodeCard,
  handleQROpen,
}) {
  const { t } = useTranslation();
  const [value, setValue] = useState(0);
  const [cardColor, setCardColor] = useState("#97EEDE40");
  const { user } = useContext(authContext);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("xs"));
  const [phoneNumberList, setPhoneNumberList] = useState([
    { value: "", is_hidden: false },
  ]);
  const [emailList, setEmailList] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    name: user?.name,
    email: [
      {
        value: user?.email,
        is_hidden: false,
      },
    ],
    mobile_phone: [
      {
        value: "",
        is_hidden: false,
      },
    ],
    personal_website: {
      value: "",
      is_hidden: false,
    },
    street: {
      value: "",
      is_hidden: false,
    },
    city: {
      value: "",
      is_hidden: false,
    },
    state: {
      value: "",
      is_hidden: false,
    },
    postal_code: {
      value: "",
      is_hidden: false,
    },
    country: {
      value: "",
      is_hidden: false,
    },
    company_name: {
      value: "",
      is_hidden: false,
    },
    company_role: {
      value: "",
      is_hidden: false,
    },
    fb_link: {
      value: "",
      is_hidden: false,
    },
    linkedin_link: {
      value: "",
      is_hidden: false,
    },
    insta_link: {
      value: "",
      is_hidden: false,
    },
    twitter_link: {
      value: "",
      is_hidden: false,
    },
    youtube_link: {
      value: "",
      is_hidden: false,
    },
    snapchat_link: {
      value: "",
      is_hidden: false,
    },
    tiktok_link: {
      value: "",
      is_hidden: false,
    },
    shopify_link: {
      value: "",
      is_hidden: false,
    },
    user_id: {
      _id: "",
      picture: "",
    },
  });

  useEffect(() => {
    if (!is_user) setLoading(true);

    const getQRCodeInfo = async () => {
      let response;
      if (!token) {
        response = await serverGET(`/qrCodeInfo`);
      } else {
        response = await serverGETWithToken("/qrCodeInfo", token);
      }

      if (response && response.constructor !== String) {
        if (response.code === 200) {
          let tempPhones = [];
          let data = response.data;
          data.mobile_phone.map((mp) => {
            tempPhones.push({ value: mp.value, is_hidden: mp.is_hidden });
          });
          setPhoneNumberList(tempPhones);

          let tempEmails = [];

          data.email.map((em) => {
            tempEmails.push({ value: em.value, is_hidden: em.is_hidden });
          });
          setEmailList(tempEmails);
          setFormData(() => {
            setLoading(false);
            return response.data;
          });
        } else {
          setLoading(false);

          setFormData({ ...formData, email: user?.email, name: user?.name });
        }
      } else {
        setLoading(false);
      }
    };
    getQRCodeInfo();
  }, [token, user]);

  useEffect(() => {
    value === 0
      ? setCardColor("#97EEDE40")
      : value === 1
      ? setCardColor("#F7F7F9")
      : setCardColor("#479AE933");
  }, [value]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCreateContactClick = () => {
    // create a vcard file
    const cardData = {
      name: formData.name ? formData.name : "",
      email: formData?.email,
      mobile_phone: formData.mobile_phone,
      company_name:
        formData.company_name?.value && !formData.company_name?.is_hidden
          ? formData.company_name?.value
          : "",
    };
    var vcard =
      "BEGIN:VCARD\nVERSION:4.0\nN:" +
      cardData.name +
      "\nFN:" +
      cardData.name +
      "\n";
    for (const mobilePhone of cardData.mobile_phone) {
      if (mobilePhone.is_hidden) continue;
      vcard += "TEL:" + mobilePhone.value + "\n";
    }

    for (const email of cardData.email) {
      if (email.is_hidden) continue;
      vcard += "EMAIL:" + email.value + "\n";
    }

    vcard += "ORG:" + cardData.company_name + "\n";
    vcard += "END:VCARD";

    var blob = new Blob([vcard], { type: "text/vcard" });

    if (window.navigator.msSaveOrOpenBlob) {
      // For Internet Explorer and Microsoft Edge
      window.navigator.msSaveOrOpenBlob(blob, formData.name + ".vcf");
    } else {
      // For other browsers, including Safari
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = formData.name + ".vcf";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      setFormData({
        ...formData,
        [name]: value,
      });
    } else {
      setFormData({
        ...formData,
        [name]: { ...formData[name], value: value },
      });
    }
  };

  const handleHideShow = (name) => {
    if (name === "location") {
      const oldVal = formData.street?.is_hidden;
      setFormData({
        ...formData,
        street: { ...formData.street, is_hidden: !oldVal },
        city: { ...formData.city, is_hidden: !oldVal },
        state: { ...formData.state, is_hidden: !oldVal },
        postal_code: { ...formData.postal_code, is_hidden: !oldVal },
        country: { ...formData.country, is_hidden: !oldVal },
      });
    } else {
      const oldVal = formData[name]?.is_hidden;
      setFormData({
        ...formData,
        [name]: { ...formData[name], is_hidden: !oldVal },
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isInvalidNumber = false;
    phoneNumberList.map((phone) => {
      if (!isValidPhoneNumber(phone.value)) {
        setError(t("phone_err"));
        isInvalidNumber = true;
      }
    });
    if (isInvalidNumber) return;

    if (formData.user_id._id === "") {
      delete formData["user_id"];
      const response = await serverPOST("/qrCodeInfo", {
        ...formData,
        mobile_phone: phoneNumberList,
        email: emailList.filter((x) => x.value !== ""),
      });
      if (response && response.constructor !== String) {
        handleQROpen();
      }
    } else {
      const codeId = formData.user_id._id;
      delete formData["user_id"];
      const response = await serverPUT(`/qrCodeInfo/${codeId}`, {
        ...formData,
        mobile_phone: phoneNumberList,
        email: emailList.filter((x) => x.value !== ""),
      });
      if (response && response.constructor !== String) {
        handleQROpen();
      }
    }
  };

  const handlePhoneNumberList = (val, index, colName) => {
    let temp_list = [...phoneNumberList];
    let current_number = temp_list[index];
    current_number[colName] = val;

    setPhoneNumberList(temp_list);
  };

  const handleEmailList = (e, index) => {
    let temp_list = [...emailList];
    let current_email = temp_list[index];
    current_email.value = e.target?.value;
    setEmailList(temp_list);
  };

  const handleEmailDelete = (i) => {
    let emailListTemp = [...emailList];
    emailListTemp.splice(i, 1);
    setEmailList(emailListTemp);
  };

  const handlePhoneDelete = (i) => {
    let phoneListTemp = [...phoneNumberList];
    phoneListTemp.splice(i, 1);
    setPhoneNumberList(phoneListTemp);
  };
  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <Grid container direction={"column"}>
          <form onSubmit={handleSubmit}>
            <Grid
              item
              xs={12}
              sx={{
                backgroundColor: cardColor,
              }}
              display="flex"
              direction="column"
              justifyContent={"center"}
              alignItems={"center"}
              gap={4}
              py={4}
            >
              {!is_user ? (
                <Box
                  style={{
                    justifyContent: "start",
                    display: "flex",
                    width: "100%",
                    // marginLeft: "16px",
                  }}
                >
                  <Languages />
                </Box>
              ) : (
                <CloseIcon
                  style={{
                    color: "gray",
                    cursor: "pointer",
                    marginLeft: "auto",
                    paddingRight: "16px",
                  }}
                  onClick={() => setQRCodeCard(false)}
                />
              )}
              <Grid
                item
                display={"flex"}
                direction="column"
                alignItems={"center"}
                gap={1}
                mx={6}
              >
                {!is_user ? (
                  <></>
                ) : (
                  <>
                    <Typography variant="h2">{t("add_details")}</Typography>
                    <Typography
                      variant="h4"
                      fontWeight={400}
                      color="#70757A"
                      textAlign={"center"}
                    >
                      {t("add_details_desc")}
                    </Typography>
                  </>
                )}
              </Grid>
              <Grid
                item
                direction="column"
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                gap={0.2}
              >
                {is_user ? (
                  <EditProfilePicture mobile={mobile} />
                ) : (
                  <ProfileAvatar
                    height={"80px"}
                    width={"80px"}
                    user={formData.user_id}
                  />
                )}
                <Typography variant="h3" fontWeight={500}>
                  {formData.name}
                </Typography>
                {/* {is_user ? (
              <></>
            ) : (
              <Typography variant="h4" fontWeight={400}>
                ( {t("organizer")})
              </Typography>
            )} */}
              </Grid>

              <Grid item direction="column" display={"flex"} gap={2}>
                {is_user ? (
                  <>
                    {/* <Button variant="contained" onClick={handleQROpen}>
                  Generate QR Code
                </Button> */}
                    <Button variant="contained" type="submit">
                      {t("generate_qr_code")}
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      variant="contained"
                      onClick={handleCreateContactClick}
                    >
                      {t("add_to_contacts")}
                    </Button>
                    <Button variant="contained" onClick={handleScheduleMeeting}>
                      {t("schedule_a_meeting")}
                    </Button>
                  </>
                )}
              </Grid>
            </Grid>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ backgroundColor: cardColor }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  centered
                  style={{ fontSize: "14px" }}
                >
                  <Tab
                    label={t("contact")}
                    style={{
                      textTransform: "capitalize",
                    }}
                  />
                  <Tab
                    label={t("company")}
                    style={{
                      textTransform: "capitalize",
                    }}
                  />
                  <Tab
                    label={t("social")}
                    style={{
                      textTransform: "capitalize",
                    }}
                  />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <Grid container textAlign={"left"} direction={"column"}>
                  <Box>
                    {phoneNumberList.map((phoneNumber, index) => {
                      return (
                        <Box
                          display="flex"
                          // alignItems="flex-end"
                          style={{
                            width: "100%",
                          }}
                        >
                          <PhoneLabel
                            title={"Phone Number"}
                            value={phoneNumber.value}
                            name={"email"}
                            handleChange={handleEmailList}
                            isRequired={index === 0 ? true : false}
                            placeholder={"Enter email"}
                            type={"email"}
                            index={index}
                            CardIcon={<PhoneOutlinedIcon />}
                            is_user={is_user}
                            handlePhoneNumberList={handlePhoneNumberList}
                            phoneNumber={phoneNumber}
                            handlePhoneDelete={handlePhoneDelete}
                          />
                        </Box>
                      );
                    })}
                    {!is_user ? (
                      <></>
                    ) : (
                      <Typography
                        variant="h3"
                        mb={2}
                        color={"#479AE9"}
                        style={{
                          cursor: "pointer",
                          // justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                        }}
                        onClick={() =>
                          setPhoneNumberList([
                            ...phoneNumberList,
                            { value: "", is_hidden: false },
                          ])
                        }
                      >
                        <AiOutlinePlusCircle style={{ marginRight: "5px" }} />
                        {t("add_phone")}
                      </Typography>
                    )}
                  </Box>

                  <>
                    {emailList.map((email, index) => {
                      return (
                        <EmailLabel
                          title={`${t("email")}${index === 0 ? "*" : ""}`}
                          value={email.value}
                          name={"email"}
                          handleChange={handleEmailList}
                          isRequired={index === 0 ? true : false}
                          placeholder={"Enter email"}
                          type={"email"}
                          index={index}
                          CardIcon={<EmailOutlinedIcon />}
                          is_user={is_user}
                          handleEmailDelete={handleEmailDelete}
                        />
                      );
                    })}
                    {!is_user ? (
                      <></>
                    ) : (
                      <Typography
                        variant="h3"
                        mb={2}
                        color={"#479AE9"}
                        style={{
                          cursor: "pointer",
                          // justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                        }}
                        onClick={() =>
                          setEmailList([
                            ...emailList,
                            { value: "", is_hidden: false },
                          ])
                        }
                      >
                        <AiOutlinePlusCircle style={{ marginRight: "5px" }} />
                        {t("add_email")}
                      </Typography>
                    )}
                  </>
                  <CardLabels
                    value={formData.personal_website?.value}
                    title={t("personal_website")}
                    CardIcon={<LanguageOutlinedIcon />}
                    link={formData.personal_website?.value}
                    is_user={is_user}
                    formName={"personal_website"}
                    handleFormChange={handleFormChange}
                    placeHolder={"www.mypersonalsite.com"}
                    type="url"
                    isDisabled={formData.personal_website?.is_hidden}
                    handleHideShow={handleHideShow}
                  />
                  <CardLabels
                    value={formData.street?.value}
                    title={t("location")}
                    CardIcon={<LocationOnOutlinedIcon />}
                    handleFormChange={handleFormChange}
                    is_location={true}
                    formData={formData}
                    is_user={is_user}
                    handleHideShow={handleHideShow}
                    formName={"location"}
                    isDisabled={formData.street?.is_hidden}
                  />
                </Grid>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <Grid container textAlign={"left"} direction={"column"}>
                  <CardLabels
                    value={formData.company_name?.value}
                    title={t("company_name")}
                    CardIcon={<BusinessOutlinedIcon />}
                    is_user={is_user}
                    formName={"company_name"}
                    handleFormChange={handleFormChange}
                    placeHolder={"My Company"}
                    isDisabled={formData.company_name?.is_hidden}
                    handleHideShow={handleHideShow}
                  />
                  <CardLabels
                    value={formData.company_role?.value}
                    title={t("designation")}
                    CardIcon={<AccountCircleOutlinedIcon />}
                    is_user={is_user}
                    formName={"company_role"}
                    handleFormChange={handleFormChange}
                    placeHolder={"CEO"}
                    isDisabled={formData.company_role?.is_hidden}
                    handleHideShow={handleHideShow}
                  />
                </Grid>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <Grid container direction={"column"} gap={3}>
                  <SocialCard
                    socialName="Facebook"
                    SocialLogo={Facebook}
                    bgColor="#96DCFF40"
                    value={formData.fb_link?.value}
                    is_user={is_user}
                    formName={"fb_link"}
                    handleFormChange={handleFormChange}
                    placeHolder={"e.g http://social-media.com"}
                    isDisabled={formData.fb_link?.is_hidden}
                    handleHideShow={handleHideShow}
                  />
                  <SocialCard
                    socialName="LinkedIn"
                    SocialLogo={LinkedIn}
                    bgColor="#B488F640"
                    is_user={is_user}
                    value={formData.linkedin_link?.value}
                    formName={"linkedin_link"}
                    handleFormChange={handleFormChange}
                    placeHolder={"e.g http://social-media.com"}
                    handleHideShow={handleHideShow}
                    isDisabled={formData.linkedin_link?.is_hidden}
                  />
                  <SocialCard
                    socialName="Instagram"
                    SocialLogo={Instagram}
                    bgColor="#F5D04F40"
                    is_user={is_user}
                    value={formData.insta_link?.value}
                    formName={"insta_link"}
                    handleFormChange={handleFormChange}
                    isDisabled={formData.insta_link?.is_hidden}
                    handleHideShow={handleHideShow}
                    placeHolder={"e.g http://social-media.com"}
                  />
                  <SocialCard
                    socialName="Twitter"
                    SocialLogo={Twitter}
                    bgColor="#97EEDE40"
                    is_user={is_user}
                    value={formData.twitter_link?.value}
                    formName={"twitter_link"}
                    handleFormChange={handleFormChange}
                    isDisabled={formData.twitter_link?.is_hidden}
                    handleHideShow={handleHideShow}
                    placeHolder={"e.g http://social-media.com"}
                  />
                  <SocialCard
                    socialName="Youtube"
                    SocialLogo={Youtube}
                    bgColor="#97EEDE40"
                    is_user={is_user}
                    value={formData.youtube_link?.value}
                    formName={"youtube_link"}
                    handleFormChange={handleFormChange}
                    isDisabled={formData.youtube_link?.is_hidden}
                    handleHideShow={handleHideShow}
                    placeHolder={"e.g http://social-media.com"}
                  />

                  <SocialCard
                    socialName="Snapchat"
                    SocialLogo={Snapchat}
                    bgColor="#E5F6FF"
                    is_user={is_user}
                    value={formData.snapchat_link?.value}
                    formName={"snapchat_link"}
                    handleFormChange={handleFormChange}
                    isDisabled={formData.snapchat_link?.is_hidden}
                    handleHideShow={handleHideShow}
                    placeHolder={"e.g http://social-media.com"}
                  />

                  <SocialCard
                    socialName="Tiktok"
                    SocialLogo={Tiktok}
                    bgColor="#ECE1FD"
                    is_user={is_user}
                    value={formData.tiktok_link?.value}
                    formName={"tiktok_link"}
                    handleFormChange={handleFormChange}
                    isDisabled={formData.tiktok_link?.is_hidden}
                    handleHideShow={handleHideShow}
                    placeHolder={"e.g http://social-media.com"}
                  />

                  <SocialCard
                    socialName="Shopify"
                    SocialLogo={Shopify}
                    bgColor="#FCF3D3"
                    is_user={is_user}
                    value={formData.shopify_link?.value}
                    formName={"shopify_link"}
                    handleFormChange={handleFormChange}
                    isDisabled={formData.shopify_link?.is_hidden}
                    handleHideShow={handleHideShow}
                    placeHolder={"e.g http://social-media.com"}
                  />
                </Grid>
              </CustomTabPanel>
            </Box>
          </form>
          <ErrorSnackbar
            open={!!error}
            handleClose={() => setError("")}
            message={error}
            vertical={"bottom"}
            horizontal={"center"}
          />
        </Grid>
      )}
    </>
  );
}

const EmailLabel = ({
  handleChange,
  value,
  placeholder,
  name,
  isRequired,
  title,
  type,
  index,
  CardIcon,
  is_user,
  handleEmailDelete,
}) => {
  const handleAccountClick = () => {
    if (value) {
      if (
        !value.startsWith("http://") &&
        !value.startsWith("https://") &&
        !value.startsWith("tel:")
      ) {
        value = "https://" + value;
      }

      window.open(value, "_blank");
    }
  };
  return (
    <Grid
      item
      direction={"row"}
      display={"flex"}
      gap={1}
      pb={1}
      mb={3}
      sx={{
        borderBottom: "0.5px #B3B5B6 solid",
      }}
    >
      {CardIcon}
      <Grid item width={"100%"}>
        <Typography variant="h3" fontWeight={500} mb={0.5}>
          {title}
        </Typography>

        <Grid item display={"flex"}>
          {is_user ? (
            <Box display={"flex"} width={"100%"}>
              <TextField
                placeholder={placeholder}
                name={name}
                value={value}
                onChange={(e) => {
                  if (index !== undefined) handleChange(e, index);
                  else handleChange(e);
                }}
                type={type}
                disabled={value?.is_hidden}
                // error={error.length > 0}

                required={isRequired}
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  style: {
                    color: "#479AE9",
                    width: "250px",
                  },
                }}
              />
              {index === 0 ? null : (
                <IconButton
                  aria-label="delete"
                  style={{
                    marginLeft: "auto",
                  }}
                  onClick={() => handleEmailDelete(index)}
                >
                  <DeleteOutlineOutlinedIcon
                    style={{
                      color: "#363B53",
                      cursor: "pointer",
                      width: 16,
                      height: 16,
                    }}
                  />
                </IconButton>
              )}
            </Box>
          ) : (
            <Link
              onClick={handleAccountClick}
              style={{ textDecoration: "none" }}
            >
              <Typography
                variant="h3"
                fontWeight={400}
                color={"#479AE9"}
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "280px",
                }}
              >
                {value}
              </Typography>
            </Link>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const PhoneLabel = ({
  value,
  title,
  index,
  CardIcon,
  is_user,
  handlePhoneNumberList,
  phoneNumber,
  handlePhoneDelete,
}) => {
  const handleAccountClick = () => {
    if (value) {
      if (value.startsWith("+")) {
        value = "tel:" + value;
      }
      if (
        !value.startsWith("http://") &&
        !value.startsWith("https://") &&
        !value.startsWith("tel:")
      ) {
        value = "https://" + value;
      }

      window.open(value, "_blank");
    }
  };
  return (
    <>
      {!is_user && phoneNumber.is_hidden ? (
        <></>
      ) : (
        <Grid
          item
          direction={"row"}
          display={"flex"}
          gap={1}
          pb={1}
          mb={3}
          width={"100%"}
          sx={{
            borderBottom: "0.5px #B3B5B6 solid",
          }}
        >
          {CardIcon}
          <Box width={"100%"}>
            <Typography variant="h3" fontWeight={500} mb={0.5}>
              {title}
            </Typography>

            <Grid item display={"flex"}>
              {is_user ? (
                <Box
                  display={"flex"}
                  justifyContent={"end"}
                  alignItems={"center"}
                  direction={"row"}
                  flex={1}
                >
                  <Box>
                    <Phone
                      showBorder={false}
                      phone={phoneNumber.value}
                      setPhone={(e) => handlePhoneNumberList(e, index, "value")}
                      isDisabled={phoneNumber.is_hidden}
                    />
                  </Box>
                  <Box
                    marginLeft={"auto"}
                    width={"100%"}
                    alignItems={"center"}
                    justifyContent={"flex-end"}
                    display={"flex"}
                  >
                    {index === 0 ? null : (
                      <IconButton
                        aria-label="delete"
                        onClick={() => handlePhoneDelete(index)}
                      >
                        <DeleteOutlineOutlinedIcon
                          style={{
                            color: "#363B53",
                            cursor: "pointer",
                            width: 16,
                            height: 16,
                          }}
                        />
                      </IconButton>
                    )}
                    {phoneNumber.is_hidden ? (
                      <EyeHidden
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handlePhoneNumberList(false, index, "is_hidden")
                        }
                      />
                    ) : (
                      <EyeVisible
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handlePhoneNumberList(true, index, "is_hidden")
                        }
                      />
                    )}
                  </Box>
                </Box>
              ) : (
                <Link
                  onClick={handleAccountClick}
                  style={{ textDecoration: "none", width: "100%" }}
                >
                  <Typography
                    variant="h3"
                    fontWeight={400}
                    color={"#479AE9"}
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "280px",
                    }}
                  >
                    {value}
                  </Typography>
                </Link>
              )}
            </Grid>
          </Box>
        </Grid>
      )}
    </>
  );
};
