import {
  Grid,
  Box,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { useStyles } from "styles/eventLocation";

export default function EventBox({
  events,
  addToCalendar,
  setLoading,
  setModal,
  setClickedEvent,
  loading,
}) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const tab = useMediaQuery(theme.breakpoints.down("lg"));

  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container alignItems="flex-start" mt="20px">
      <Grid item xs={12}>
        <Grid container rowSpacing="20px" columnSpacing="20px">
          {events?.length > 0
            ? events.map((event, index) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    display="flex"
                    justifyContent="flex-start"
                    key={index}
                  >
                    <Box
                      display="flex"
                      alignItems="flex-start"
                      sx={{
                        // width: mobile ? "300px" : "540px",
                        width: "100%",
                        cursor: "pointer",
                        minHeight: mobile ? "100px" : "130px",
                        backgroundColor: "rgba(150, 220, 255, 0.25)",
                        borderRadius: "8px",
                        alignItems: "center",
                      }}
                      onClick={
                        tab
                          ? () => {
                              setClickedEvent(event);
                              setModal(true);
                            }
                          : () => setClickedEvent(event)
                      }
                    >
                      <img
                        src={event?.thumbnail}
                        alt="event"
                        style={{
                          width: mobile ? "100px" : "118px",
                          height: mobile ? "100px" : "118px",
                          borderRadius: "8px",
                          marginLeft: "6px",
                          // marginTop: "6px",
                        }}
                      />
                      <Box
                        style={{
                          height: "125px",
                          // flexGrow: 1,
                          overflow: "hidden",
                          padding: "10px 20px",
                          boxSizing: "border-box",
                          backgroundColor: "rgba(208, 237, 252, 0.25)",
                          justifyContent: "center",
                        }}
                        display="flex"
                        flexDirection="column"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                      >
                        <Box style={{ width: "100%" }}>
                          <Typography
                            align="left"
                            variant="h3"
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                            noWrap={true}
                          >
                            {event?.title}
                          </Typography>
                        </Box>
                        <Box mt={0.5} mb={0.5}>
                          <Typography
                            align="left"
                            variant="h4"
                            fontWeight="400"
                          >
                            {event?.dates?.startDatetime
                              ? moment(event?.dates?.startDatetime).format(
                                  "ddd, DD MMM, h:mm A"
                                )
                              : event?.dates?.start_date}
                          </Typography>
                          <Typography
                            align="left"
                            variant="body1"
                            color={theme.palette.info.light}
                            className={classes.ellipsis}
                          >
                            {event?.location[0]}
                          </Typography>
                        </Box>
                        {event?.dates?.isSlotPossible ? (
                          <Box mt={0.5}>
                            <Button
                              style={{
                                // width: "175px",
                                height: "28px",
                                padding: "5px 15px",
                              }}
                              disableRipple
                              disableElevation
                              variant="submit"
                              onClick={(e) => {
                                e.stopPropagation();
                                setLoading(true);
                                addToCalendar(event);
                              }}
                              disabled={event.selected}
                            >
                              {t("add_calendar")}
                            </Button>
                          </Box>
                        ) : null}
                      </Box>
                    </Box>
                  </Grid>
                );
              })
            : !loading && (
                <Box
                  display={"flex"}
                  justifyContent="center"
                  style={{ width: "100%" }}
                >
                  <Typography variant="h3" align="center">
                    {t("no_search")}
                  </Typography>
                </Box>
              )}
        </Grid>
      </Grid>
    </Grid>
  );
}
