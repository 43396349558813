import React from "react";
import { QRCodeCanvas } from "qrcode.react";
import { Box, Button, Dialog, Typography, IconButton } from "@mui/material";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

export default function QRCode({ code, title, duration, open, handleClose }) {
  const { t } = useTranslation();

  const downloadCode = () => {
    const qrCodeURL = document
      .getElementById("qrCode")
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    console.log(qrCodeURL);
    let aEl = document.createElement("a");
    aEl.href = qrCodeURL;
    aEl.download = `${title}.png`;
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={"xs"}
      fullWidth={true}
      PaperProps={{
        sx: { padding: 3, boxSizing: "border-box", borderRadius: "16px" },
      }}
    >
      <Box display="flex" alignItems="center" flexDirection="column" mb={1}>
        <Box width="100%" display="flex" justifyContent="flex-end">
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography variant="h2" align="center" style={{ width: "100%" }}>
          QR Code
        </Typography>
        <Box mt={2} style={{ textAlign: "center" }}>
          <QRCodeCanvas
            value={code}
            renderAs="canvas"
            size={240}
            id="qrCode"
            style={{ padding: 10 }}
            includeMargin={true}
            bgColor="#F7F7F9"
          />
          <Box mt={2}>
            <Typography
              variant="h3"
              align="center"
              fontWeight={400}
              style={{ textTransform: "capitalize" }}
            >
              {title}
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              justifyContent={"center"}
              mt={1}
            >
              <AccessTimeOutlinedIcon style={{ width: "14px" }} />
              <Typography
                variant="h3"
                align="center"
                style={{ fontWeight: 400, marginLeft: 4 }}
              >
                {duration} {t("mins")}
              </Typography>
            </Box>
            <Box mt={4}>
              <Button
                variant="outlined"
                sx={{
                  margin: "0 24px",
                  width: "110px",
                }}
                onClick={handleClose}
              >
                {t("cancel")}
              </Button>
              <Button variant="small-submit" onClick={downloadCode}>
                {t("download")}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}
