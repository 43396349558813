export const calendarStyles = (mode, mobile) => `
#calendar {
  max-width: 900px;
  margin: 0 auto;
}
.fc .fc-toolbar-title {
  font-size: 20px;
  font-weight: 500;
  color: ${mode === "light" ? "#363B53" : "#ffffff"};
  margin: 0;
  display:inline;
  
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 1em;
  margin-top: 1em;
  
}

thead {
  border-width: 0;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: inherit;
}        

.fc .fc-timegrid-col.fc-day-today {
  background-color: inherit;
}

.fc-daygrid-event {
  position: relative;
  white-space: nowrap;
  border-radius: 3px;
  font-size: 0.85em;
  font-size: var(--fc-small-font-size, 0.85em);
}

.fc-timegrid-event,
.fc-timegrid-more-link {
  font-size: 0.85em;
  font-size: var(--fc-small-font-size, 0.85em);
  border-radius: 3px;
}

.fc .fc-button-primary {
  color: inherit;
  background-color: #F7F7F9;
  border-color: #F7F7F9;
}

.fc .fc-button-primary:hover {
  background-color: #F7F7F9;
  color: #2c3e50;
  border: 0;
}

.fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active {
  color: inherit;
  background-color: #F7F7F9;
}

thead {
  border-width: 0;
  font-size: 14px;
}

table {
  border-color: #F7F7F9;
}

.fc-theme-standard .fc-scrollgrid {
  border: 0px solid var(--fc-border-color, #ddd);
}
.fc-col-header-cell-cushion {
  font-size: 16px !important;
  font-weight: 500 !important;
  // color: ${mode === "light" ? "#363B53" : "#ffffff"};
}

thead .fc-scroller {
  overflow: hidden !important;
}

.fc-col-header-cell .fc-day {
  border-width: 0;
}

.fc-theme-standard td,
.fc-theme-standard th {
  border: 0px solid var(--fc-border-color, #ddd);
}

// .fc-theme-standard td {
//   height: ${mobile ? "auto" : "50px !important"} ;
// }

.fc .fc-timegrid-slot-label {
  line-height: 50px;
}

.fc .fc-daygrid-day-top {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
}

.fc .fc-daygrid-body {
  position: relative;
  z-index: 1;
  border: ${mobile ? "none" : "1px solid var(--fc-border-color, #ddd)"};
}

.fc .fc-daygrid-day-frame {
  position: relative;
  min-height: 100%;
  border: ${mobile ? "none" : "1px solid var(--fc-border-color, #ddd)"}; 
}

.fc .fc-daygrid-day-number {
  font-size: ${mobile ? "12px" : "18px"};
  font-weight: ${mobile ? "400" : "600"}
  color: ${mode === "light" ? "#363B53" : "#ffffff"};
}

.fc .fc-timegrid-col-frame {
  border: 1px solid var(--fc-border-color, #ddd);
  border-left: 0px;
}

.fc .fc-timegrid-slot {
  border-bottom: 1px solid var(--fc-border-color, #ddd);
}

.fc .fc-timegrid-slot-label {
  border-bottom: 0px !important;
  border-top: 0px !important;
  font-size: 11px;
  font-weight: 500;
  color: ${mode === "light" ? "#363B53" : "#ffffff"};
  vertical-align:top;
}

.fc-timegrid-col-frame .fc-timegrid-now-indicator-container {
  border-top: 0px !important;
}

.fc .fc-bg-event {
  background: #07135d;
  opacity: 1;
}
.fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-number {
  font-size: ${mobile ? "12px" : "18px"};
  font-weight: ${mobile ? "400" : "600"}
  color: #479AE9 ;

}
.fc .fc-today-button {
  margin-top: -4px;
  color: #363B53;
  border: 1px solid;
  padding: 2px 12px;
  font-weight: 500;
  margin-left: 12px;
  cursor:pointer;
  text-transform: capitalize;
  font-size:12px;
}
.fc .fc-today-button:hover {
  font-weight: 500;
  background-color: #EFFAFF;
  border: 1px solid #479AE9;
  color: #479AE9;

}
.fc-day-today {
  color: #fff;
  border-radius:4px;
  background-color: #479AE9;
}
.fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-number {
  font-size: ${mobile ? "12px" : "18px"};
  font-weight: ${mobile ? "400" : "600"};
  border-radius:50% !important;
  background-color: #479AE9;
  min-width:28px;
  min-height:28px;
  text-align:center;
  display:flex;
  justify-content:center;
  align-items:center;
}
}

`;
