import makeStyles from "@mui/styles/makeStyles";
const useStyle = makeStyles((theme) => ({
  calBtnBox: {
    display: "flex",
    width: "fit-content",
    background: theme.palette.secondary.light,
    borderRadius: "8px",
    padding: "4px",
    border: "1px solid #DAD2E5",
  },
  calBtn: {
    minWidth: 70,
    height: 20,
  },

  setTime: {
    textAlign: "left",
    fontSize: 16,
    fontWeight: 400,
  },

  grayMsg: {
    fontSize: "14px",
    color: "gray",
  },

  calTask: {
    borderRadius: "8px",
    marginLeft: "4px",
    padding: "8px 12px",
    boxSizing: "border-box",
    marginBottom: 8,
    backgroundColor: "#95C3F2" + "33",
    boxShadow: `-4px 0px 0px #95C3F2`,
  },

  checkbox: {
    color: theme.palette.text.primary,
    "&.Mui-checked": {
      color: "#F7F7F9",
      stroke: theme.palette.text.primary,
      strokeWidth: "2px",
      strokeLineJoin: "round",
    },
  },
  ellipsesText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "90%",
  },

  accordion: {
    background: "none",
    width: "100%",
    "&:before": {
      display: "none",
    },
  },
  accordionDetail: {
    padding: 0,
    display: "flex",
    flexWrap: "wrap",
    marginTop: 10,
    width: "100%",
  },

  todoContainer: {
    overflow: "auto",
    minHeight: "fit-content",
    maxHeight: "30vh",
    marginTop: 8,
    width: "100%",
  },

  circleContainer: {
    width: 36,
    height: "min-content",
    margin: "5px 10px 5px 0px",
    cursor: "pointer",
  },
  circle: {
    width: 16,
    height: 16,
    borderRadius: "50%",
    border: "1px solid",
    cursor: "pointer",
  },

  editTodo: {
    borderRadius: "8px",
    width: "100%",
    padding: "8px 12px",
    boxSizing: "border-box",
    marginBottom: 8,
    backgroundColor: "#B0E4FF",
  },

  todoInput: {
    fontSize: "14px",
    fontWeight: 400,
    color: theme.palette.text.primary,
    textAlign: "left",
  },

  upcomingContainer: {
    width: "100%",
    borderRadius: "8px",
    padding: "12px 8px 12px 12px",
    boxSizing: "border-box",
    marginBottom: 8,
    marginTop: 5,
    cursor: "pointer",
  },
  upcomingText: {
    display: "none",
    textOverflow: "ellipsis",
    overflowX: "hidden",
    marginTop: "-1px",
    width: "95%",
    fontWeight: "400",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },

  participants: {
    display: "none",
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "95%",
    whiteSpace: "nowrap",
    textAlign: "start",
    paddingLeft: "8px",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },

  avatar: {
    fontSize: "10px",
    color: "#000000",
    width: "24px",
    height: "24px",
    marginLeft: "-8px",
  },

  markRead: {
    minWidth: 12,
    height: 12,
    border: "1px solid",
    borderRadius: "50%",
    marginRight: 10,
    cursor: "pointer",
  },

  todoHeight: {
    overflow: "auto",
    maxHeight: "25vh",
  },

  mobileUpcoming: {
    width: "36px",
    height: "min-content",
    borderRadius: "50%",
    padding: "2px",
    margin: "5px 10px 5px 0px",
    cursor: "pointer",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },

  upcomingEvent: {
    width: "36px",
    padding: "2px",
    margin: "5px 10px 5px 0px",
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  eventContainer: {
    width: "40px",
    height: "min-content",
    borderRadius: "50%",
    padding: "2px",
    margin: "5px 10px 5px 0px",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },

  upcomingHeight: {
    height: "40vh",
    marginBottom: "10px",
    marginTop: "10px",
    overflow: "auto",
    [theme.breakpoints.up("md")]: {
      width: "auto",
      height: "100%",
    },
  },
  noMsg: {
    height: "100%",
    width: "100%",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "40px",
    margin: "auto",
    [theme.breakpoints.up("md")]: {
      width: "auto",
    },
  },
  uploadBtn: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },

  fileChip: {
    background: theme.palette.secondary.light,
    overflow: "hidden",
    textOverflow: "ellipses",
    borderRadius: "4px",
  },
  chipColor: {
    color: "#96DCFF",
  },
  file: {
    display: "flex",
    justifyContent: "flex-end",
    "& .MuiBadge-badge": {
      maxWidth: "0",
      maxHeight: "35%",
    },
  },

  attachement: {
    color: theme.palette.text.primary,
    background: theme.palette.secondary.main,
    borderRadius: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "25px",
    maxWidth: "150px",
    minWidth: "150px",
  },
  dottedText: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },

  mainMeetingMinutesContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
  },

  meetingMinutesContainer: {
    borderTop: "1px solid #C8C8C9BF",
    borderBottom: "1px solid #C8C8C9BF",
    display: "flex",
    [theme.breakpoints.up("md")]: {
      height: 400,
    },
  },

  transcriptContainer: {
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    gap: 8,
    borderRight: "1px solid #C8C8C9BF",
    overflowY: "auto",
    height: "100%",
    padding: 16,
  },

  summaryContainer: {
    textAlign: "left",
    display: "flex",
    flexDirection: "column",
    gap: 8,
    padding: 16,
  },
  summaryTextfield: {
    minHeight: 300,
    fontSize: 14,
    color: "#70757A",
    display: "flex",
    alignItems: "baseline",
  },

  transcriptButton: {
    display: "flex",
    gap: 16,
    justifyContent: "center",
    flexDirection: "column",
    backgroundColor: "white",
    zIndex: 1,
  },
  sendEmail: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  link: {
    color: "#3C4242",
    textDecoration: "none",
    overflow: "wrap",
    width: "85%",
    wordBreak: "break-word",
    color: theme.palette.primary.main,
  },
  borderInput: {
    fontSize: "16px",
    fontWeight: 400,
    color: theme.palette.text.primary,
    border: "1px solid #DAD2E5",
    borderRadius: "6px",
    paddingLeft: "8px",
  },
}));

export default useStyle;
