import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

export default function Progress({ stage, t }) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box display="flex" justifyContent={"center"} style={{ width: "100%" }}>
      {[1, 2, 3].map((element, i) => {
        return (
          <Box display={"flex"} alignItems="center" key={i}>
            <Box
              style={{ textAlign: "center" }}
              display={"flex"}
              alignItems="center"
              flexDirection={"column"}
            >
              <Box
                style={{
                  background:
                    stage > i ? theme.palette.primary.main : "transparent",
                  border: `2px solid ${
                    stage >= i ? theme.palette.primary.main : "#DAD2E5"
                  }`,
                  width: 32,
                  height: 32,
                  borderRadius: "50%",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  marginBottom: "8px",
                }}
              >
                <Typography
                  style={{
                    color:
                      stage === i
                        ? theme.palette.primary.main
                        : stage > i
                        ? "#fff"
                        : "#DAD2E5",
                  }}
                  variant="h3"
                >
                  {element}
                </Typography>
              </Box>
              <Typography
                style={{
                  color:
                    stage === i
                      ? theme.palette.primary.main
                      : stage > i
                      ? "#000"
                      : "#DAD2E5",
                  width: mobile ? "" : "140px",
                }}
                variant="h4"
              >
                {t(
                  element === 1
                    ? t("progress1")
                    : element === 2
                    ? t("progress2")
                    : t("progress3")
                )}
              </Typography>
            </Box>
            {i < 2 && (
              <span
                style={{
                  width: mobile ? "80px" : "120px",
                  height: "1px",
                  marginTop: "-20px",
                  backgroundColor:
                    stage >= i ? theme.palette.primary.main : "#DAD2E5",
                }}
              />
            )}
          </Box>
        );
      })}
    </Box>
  );
}
