import makeStyles from "@mui/styles/makeStyles";
const pUseStyle = makeStyles((theme) => ({
  inputLabel: {
    marginTop: "10px",
    marginBottom: "-10px",
  },
  mainContainer: {
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "100%",
  },
  timeInterval: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  availTitle: {
    textAlign: "left",
    width: "fit-content",
    fontWeight: 400,
  },
  availDesc: {
    textAlign: "left",
    fontWeight: 400,
    width: "fit-content",
    color: "gray",
    marginTop: "4px",
  },
  buttonContainer: {
    justifyContent: "center",
    alignItems: "center",
    columnSpacing: 32,
  },
  lockedBox: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1,
  },
  lockedContainer: {
    flexDirection: "row",
    padding: 16,
    borderRadius: 2,
    backgroundColor: "#DFF0FB",
    flexWrap: "nowrap",
    gap: 1,
  },
  textDecor: {
    textDecoration: "underline",
    color: "#479AE9",
    cursor: "pointer",
  },
}));
export default pUseStyle;
