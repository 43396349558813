import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import CounterBox from "./Counter";
import useMembersStore from "../../Utils/membersStore";
import { accountContext } from "../../context/user";
import { serverPUT } from "../../Utils/HttpFunctions";
import SnackbarComponent from "../Global/SnackbarComponent";

export default function UpgradeSubsciption({ handleClose }) {
  const { user, getUser } = useContext(accountContext);
  const [newQuantity, setNewQuantity] = useState(1);
  const [resposne, setResponse] = useState("");
  const [disabled, setDisabled] = useState(false);

  // useEffect(() => {
  //   if (user) {
  //     setNewQuantity(user?.package?.quantity + 1);
  //   }
  // }, [user]);

  const handleIncrement = () => {
    setNewQuantity((prevCount) => prevCount + 1);
  };

  const handleDecrement = () => {
    // if (newQuantity > user?.package?.quantity + 1) {
    if (newQuantity > 1) {
      setNewQuantity((prevCount) => prevCount - 1);
    }
  };
  const handleProceed = async () => {
    setDisabled(true);
    const response = await serverPUT(
      `/account/upgrade-subscription/${user?.package?.membership?._id}`,
      {
        newQuantity,
        currentQuantity: user?.package?.quantity ?? 0,
      }
    );
    if (response && response.constructor !== String) {
      setTimeout(() => {
        getUser();
      }, 4000);

      setResponse("Subscription updated successfully");
    } else {
      setResponse("Something went wrong while updating subscription");
    }
    setDisabled(false);
  };
  return (
    <>
      <Typography variant="h2">Upgrade Subscription</Typography>
      <Typography variant="h4" style={{ marginTop: "8px" }}>
        Add more seats to current subscription
      </Typography>

      <Box mt={2} display="flex" justifyContent="center" alignItems="center">
        <CounterBox
          disable={undefined}
          count={newQuantity}
          handleDecrement={handleDecrement}
          handleIncrement={handleIncrement}
        />
      </Box>
      <Grid item display="flex" gap={3} justifyContent={"center"} my={2}>
        <Button variant="outlined" onClick={handleClose}>
          {"Close"}
        </Button>
        <Button variant="contained" onClick={handleProceed} disabled={disabled}>
          {"Upgrade"}
        </Button>
      </Grid>

      <SnackbarComponent
        open={!!resposne}
        handleClose={() => {
          handleClose();
          setResponse("");
        }}
        message={resposne}
        vertical={"bottom"}
        horizontal={"center"}
      />
    </>
  );
}
