import React from "react";
import { Box, Grid, TextField, Typography, useTheme } from "@mui/material";
import { ReactComponent as EyeVisible } from "assets/eye_visible.svg";
import { ReactComponent as EyeHidden } from "assets/eye_hidden.svg";
import useStyle from "./styles";

export function QRInput({
  handleChange,
  value,
  placeholder,
  name,
  isRequired,
  title,
  width,
  type,
  handleHideShow,
  index,
}) {
  const theme = useTheme();
  const classes = useStyle();
  return (
    <Box flex={1}>
      <Typography variant="h4" width={width} mr={2}>
        {title}
      </Typography>

      <Grid item display="flex" alignItems="center" justifyContent={"center"}>
        <TextField
          placeholder={placeholder}
          name={name}
          value={name === "name" ? value : value?.value}
          onChange={(e) => {
            if (index !== undefined) handleChange(e, index);
            else handleChange(e);
          }}
          type={type}
          disabled={value?.is_hidden}
          // error={error.length > 0}
          variant="standard"
          sx={{
            width: "100%",
            marginTop: "4px",
          }}
          InputProps={{
            disableUnderline: true,
            classes: { input: classes.textfield },
          }}
          required={isRequired}
        />
        {name === "name" ||
        name === "email" ||
        name === "street" ||
        name === "city" ||
        name === "state" ||
        name === "postal_code" ||
        name === "country" ? (
          <></>
        ) : value?.is_hidden ? (
          <EyeHidden
            className={classes.eyeIcon}
            onClick={() => handleHideShow(name)}
          />
        ) : (
          <EyeVisible
            className={classes.eyeIcon}
            onClick={() => handleHideShow(name)}
          />
        )}
      </Grid>
    </Box>
  );
}
