import React, { useContext, useEffect, useState } from "react";
import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { integrationContext } from "../../context/Integrations";
import { serverGET } from "../../Utils/HttpFunctions";
import { GET_USER_PREFERENCES_PATH } from "../../Utils/ApiPaths";
/**
 * Component for selecting a time interval
 */
function MeetingTypeColor({ meetingType, handleMeetingTypeChange }) {
  const [meetingTypes, setMeetingTypes] = useState([]);

  useEffect(() => {
    const getUserPreferences = async () => {
      try {
        const response = await serverGET(
          GET_USER_PREFERENCES_PATH,
          undefined,
          undefined
        );
        if (response) {
          if (response.code === 200) {
            const types =
              response.data?.meeting_types?.length > 0
                ? response.data.meeting_types
                : [];
            setMeetingTypes(types);
          }
        } else {
          return response.data.message;
        }
      } catch (e) {}
    };
    getUserPreferences();
  }, []);

  const { t } = useTranslation();

  const styles = {
    fontSize: "14px",
    fontWeight: 400,
    borderRadius: "6px",
    paddingLeft: "8px",
    textAlign: "left",
  };
  return meetingTypes.length > 0 ? (
    <>
      <Typography variant="h4" mt={1}>
        {t("Type & Color") + ":"}
      </Typography>
      <FormControl variant="standard" sx={{ marginTop: "10px" }}>
        <Select
          labelId="time-interval-label"
          id="event-time-interval"
          disableUnderline={true}
          value={meetingType?.color}
          // IconComponent={"div"}
          onChange={(e) => {
            const selectedMeeting = meetingTypes.find(
              (x) => x.color === e.target.value
            );
            handleMeetingTypeChange({
              title: selectedMeeting?.title,
              color: selectedMeeting?.color,
            });
          }}
          style={styles}
          displayEmpty={true}
        >
          <MenuItem value={""}>{t("Select a type")}</MenuItem>
          {meetingTypes.map((x: { title: string; color: string }) => {
            return (
              <MenuItem value={x.color}>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 4,
                    padding: 2,
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <circle cx="10" cy="10" r="10" fill={x.color} />
                  </svg>
                  {x.title}
                </Box>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </>
  ) : null;
}

export default React.memo(MeetingTypeColor);
