import {
  Chip,
  Stack,
  Typography,
  IconButton,
  Badge,
  Box,
  useTheme,
  MenuItem,
  Select,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CancelIcon from "@mui/icons-material/Cancel";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import { useEffect } from "react";
import moment from "moment";
import {
  ALL_SKEDING_USERS,
  MEETING_THROUGH_QR_CODE,
  MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS,
  ONE_NONSKEDING_MULTIPLE_SKEDING_USERS,
  ONE_NONSKEDING_ONE_SKEDING_USER,
  ONE_SKEDING_MULTIPLE_NONSKEDING_USERS,
} from "Utils/Constants";
import { useRef } from "react";
import { useLayoutEffect } from "react";
import { convertUTCMillisecondsToDate } from "Utils/CommonFunctions";

export default function ShowTimes({
  events,
  eventCase,
  selectedSlot,
  setSelectedSlot,
  errorAmount,
  setErrorAmount,
  meetingCreated,
  calendarRef,
  qrMeeting,
  dateObject,
  selectedDate,
  setSharedAvailableTimes: setTimes,
  sharedAvailableTimes: times,
  appendSlots,
  months,
}) {
  // loggingInDev("props events", events);
  const [error, setError] = useState("");
  // const [times, setTimes] = useState([]);
  const theme = useTheme();
  const [noTimesError, setNoTimesError] = useState(false);

  useEffect(() => {
    if (eventCase === "NonSignUpOneSelect") {
      const timeArr =
        events &&
        events.map((e) => {
          return {
            start: convertUTCMillisecondsToDate(e.startTime),
            end: convertUTCMillisecondsToDate(e.endTime),
          };
        });
      setTimes(timeArr);
    } else {
      setTimes(events);
    }
  }, [events]);

  useEffect(() => {
    setSelectedSlot(selectedSlot);
  }, [selectedSlot]);

  useEffect(() => {
    if (meetingCreated) setSelectedSlot("");
  }, [meetingCreated]);

  const handleMoveRecommended = (e, index) => {
    setError("");
    setErrorAmount("");
    if (
      (eventCase === ALL_SKEDING_USERS ||
        (eventCase === ONE_NONSKEDING_ONE_SKEDING_USER && qrMeeting)) &&
      selectedSlot?.length < 1
    ) {
      const selectedTimeSlot = times[index];
      const selectedToAdd = {
        originalIndex: index,
        value: selectedTimeSlot,
      };
      const tempSelected = [...selectedSlot, selectedToAdd];
      const tempAll = [...times];
      tempAll.splice(index, 1);
      setSelectedSlot(tempSelected);
      setTimes(tempAll);
    } else if (
      ((eventCase === ONE_NONSKEDING_ONE_SKEDING_USER && !qrMeeting) ||
        eventCase === ONE_NONSKEDING_MULTIPLE_SKEDING_USERS) &&
      selectedSlot?.length < 5
    ) {
      const selectedTimeSlot = times[index];
      const selectedToAdd = {
        originalIndex: index,
        value: selectedTimeSlot,
      };
      const tempSelected = [...selectedSlot, selectedToAdd];
      const tempAll = [...times];
      tempAll.splice(index, 1);
      setSelectedSlot(tempSelected);
      setTimes(tempAll);
    }
    //  else if (
    //   eventCase === MULTIPLE_SKEDING_MULTIPLE_NONSKEDING_USERS ||
    //   eventCase === ONE_SKEDING_MULTIPLE_NONSKEDING_USERS
    // ) {
    //   const selectedTimeSlot = times[index];
    //   const selectedToAdd = {
    //     originalIndex: index,
    //     value: selectedTimeSlot,
    //   };
    //   const tempSelected = [...selectedSlot, selectedToAdd];
    //   const tempAll = [...times];
    //   tempAll.splice(index, 1);
    //   setSelectedSlot(tempSelected);
    //   setTimes(tempAll);
    // }
    else if (eventCase === "NonSignUp" && selectedSlot?.length < 3) {
      const selectedTimeSlot = times[index];
      const selectedToAdd = {
        originalIndex: index,
        value: selectedTimeSlot,
      };
      const tempSelected = [...selectedSlot, selectedToAdd];
      const tempAll = [...times];
      tempAll.splice(index, 1);
      setSelectedSlot(tempSelected);
      setTimes(tempAll);
    } else if (eventCase === "NonSignUpOneSelect" && selectedSlot?.length < 1) {
      const selectedTimeSlot = times[index];
      const selectedToAdd = {
        originalIndex: index,
        value: selectedTimeSlot,
      };
      const tempSelected = [...selectedSlot, selectedToAdd];
      const tempAll = [...times];
      tempAll.splice(index, 1);
      setSelectedSlot(tempSelected);
      setTimes(tempAll);
    } else {
      if (
        eventCase === ALL_SKEDING_USERS ||
        (eventCase === ONE_NONSKEDING_ONE_SKEDING_USER && qrMeeting)
      ) {
        setError("You can only select a single timeslot");
      } else if (
        eventCase === ONE_NONSKEDING_ONE_SKEDING_USER ||
        eventCase === ONE_NONSKEDING_MULTIPLE_SKEDING_USERS
      ) {
        setError(
          "Please select no more than 5 timeslots for the convenience of your participants"
        );
      } else if (eventCase === "NonSignUpOneSelect") {
        setError("You can only select a single slot for meeting");
      } else if (eventCase === "NonSignUp") {
        setError("You can only select 3 timeslots");
      }
    }
  };

  const handleRemoveClick = (index) => {
    setError("");
    setErrorAmount("");
    const tempSelected = [...selectedSlot];
    const specificSlot = tempSelected[index];
    const tempAllSlots = [...times];
    tempSelected.splice(index, 1);
    tempAllSlots.splice(specificSlot.originalIndex, 0, specificSlot.value);
    setSelectedSlot(tempSelected);
    setTimes(tempAllSlots);
  };

  function DateSelector(props) {
    const { calendarRef, dateObject, noTimesError } = props;
    return (
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ marginLeft: 10 }}
      >
        <CurrentDate dateObject={dateObject} />
        <Month dateObject={dateObject} />
        <Year calendarRef={calendarRef} dateObject={dateObject} />
        {noTimesError ? (
          <Box display="flex" alignItems="center" columnGap="5px">
            <ErrorOutline fontSize="small" sx={{ color: "red" }} />
            <Typography
              variant="h4"
              align="left"
              sx={{
                fontWeight: 400,
                color: "red",
              }}
            >
              no recommended times for this date
            </Typography>
          </Box>
        ) : null}
      </Stack>
    );
  }

  function CurrentDate({ dateObject: dateData }) {
    const dates = [...Array(32).keys()];
    dates.shift(); //removes 0 at the beginning of array

    const handleChange = (event) => {
      dateData.setDate(event.target.value);
    };

    return (
      <Select
        id="choose-date"
        value={dateData?.date}
        defaultValue={dateData?.date}
        onChange={handleChange}
        variant="standard"
        sx={{ fontWeight: 500, fontSize: "14px" }}
        disableUnderline={true}
      >
        {dates.map((number, i) => (
          <MenuItem key={i} value={number}>
            {number}
          </MenuItem>
        ))}
      </Select>
    );
  }

  return (
    <Stack spacing="10px" style={{ marginTop: 15 }}>
      <Box display="flex" alignItems="center" flexWrap="wrap">
        <Typography
          variant="h3"
          align="left"
          sx={{
            display:
              eventCase === "NonSignUpOneSelect" ||
              eventCase === "NonSignUp" ||
              qrMeeting
                ? "none"
                : "block",
            fontWeight: 500,
          }}
        >
          Recommended Available Times on
        </Typography>
        {window.location.pathname === "/newmeeting" && (
          <DateSelector
            calendarRef={calendarRef}
            dateObject={dateObject}
            noTimesError={noTimesError}
          />
        )}
      </Box>
      <Typography
        variant="h4"
        align="left"
        sx={{
          display:
            eventCase === "NonSignUpOneSelect" ||
            eventCase === "NonSignUp" ||
            qrMeeting
              ? "none"
              : "block",
          fontWeight: 400,
          color: theme.palette.info.light,
          marginTop: "3px !important",
        }}
      >
        {eventCase === ALL_SKEDING_USERS ||
        (eventCase === ONE_NONSKEDING_ONE_SKEDING_USER && qrMeeting) ? (
          <>Please select one of the recommended slots</>
        ) : (
          <>
            Select several recommended slots (up to 5) for the convenience of
            the invitee
          </>
        )}
      </Typography>
      {error && !errorAmount && (
        <Typography style={{ color: "red" }} variant="h6" align="left">
          {error}
        </Typography>
      )}
      {errorAmount && (
        <Typography
          style={{ color: "red", marginTop: 2 }}
          variant="h6"
          align="left"
        >
          {errorAmount}
        </Typography>
      )}
      {eventCase === "NonSignUpOneSelect" ? (
        <RecommendedTimesWithoutArrow
          handleMoveRecommended={handleMoveRecommended}
          times={times}
        />
      ) : (
        <RecommendedTimes
          handleMoveRecommended={handleMoveRecommended}
          times={times}
          setNoTimesError={setNoTimesError}
          dateObject={dateObject}
          months={months}
          appendSlots={appendSlots}
        />
      )}
      <Box mt={2} />
      {selectedSlot?.length > 0 ? (
        <Typography
          variant="h3"
          align="left"
          sx={{
            fontWeight: 500,
          }}
        >
          Selected Timeslots
        </Typography>
      ) : null}
      <SelectedTimes
        selectedSlot={selectedSlot}
        setSelectedSlot={setSelectedSlot}
        handleRemoveClick={handleRemoveClick}
        eventCase={eventCase}
      />
    </Stack>
  );
}

function RecommendedTimesWithoutArrow(props) {
  const { times } = props;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Stack
      justifyContent="flex-start"
      direction="row"
      alignItems="center"
      columnGap="8px"
      rowGap="8px"
      sx={{
        width: { xs: "100%", md: "430px" },
        flexWrap: "wrap",
      }}
    >
      {times &&
        times.map((time, i) => {
          var startTime = moment(time?.start).format("hh:mm a");
          var endTime = moment(time?.end).format("hh:mm a");
          var startDate = moment(time?.start).format("ddd, MMM D");
          return (
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
              key={i}
            >
              <Chip
                label={
                  <div>
                    <Typography
                      style={{ color: theme.palette.info.light }}
                      variant="h4"
                      fontWeight={300}
                    >
                      {startDate}
                    </Typography>
                    <Typography
                      style={{ color: theme.palette.text.primary }}
                      variant="h3"
                      fontWeight={400}
                    >
                      {mobile ? startTime : startTime + " - " + endTime}
                    </Typography>{" "}
                  </div>
                }
                key={i}
                id={i}
                sx={{
                  background: "#96DCFF80",
                  borderRadius: "8px",
                  p: { xs: "20px 8px", md: "20px 2px 20px 2px" },
                }}
                clickable={true}
                onClick={(e) => props.handleMoveRecommended(e, i)}
              />
            </Box>
          );
        })}
    </Stack>
  );
}

export function RecommendedTimes(props) {
  const [view, setView] = useState([]);
  const [lastSlice, setLastSlice] = useState(6);
  const [leftClick, setLeftClick] = useState(false);
  const { times, dateObject, setNoTimesError, appendSlots, months } = props;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  //uses the last slice to determine how many times to show in the view
  useEffect(() => {
    if (leftClick === false) {
      switch (lastSlice % 6) {
        case 1:
          setView(timesComponents?.slice(lastSlice - 1, lastSlice));
          break;
        case 2:
          setView(timesComponents?.slice(lastSlice - 2, lastSlice));
          break;
        case 3:
          setView(timesComponents?.slice(lastSlice - 3, lastSlice));
          break;
        case 4:
          setView(timesComponents?.slice(lastSlice - 4, lastSlice));
          break;
        case 5:
          setView(timesComponents?.slice(lastSlice - 5, lastSlice));
          break;
        default:
          setView(timesComponents?.slice(lastSlice - 6, lastSlice));
          break;
      }
    } else {
      setView(timesComponents.slice(lastSlice - 6, lastSlice));
    }
  }, [lastSlice, leftClick, times]);

  useEffect(() => {
    if (dateObject) {
      // const newDate = moment(dateObject.recommendedDate);
      // const diff = moment(newDate).diff(moment(), "days");
      // const calendarMonth = moment().month(dateObject.month).format("M");

      // if (diff >= 30 && months.includes(calendarMonth)) {
      //   const lastValue = times.length > 0 && times[times.length - 1];
      //   appendSlots(new Date(lastValue.end));
      //   const index = months.indexOf(calendarMonth);
      //   if (index > -1) {
      //     months.splice(index, 1);
      //   }
      // }
      moveToDateInRecommended();
    }
  }, [dateObject]);

  // useEffect(() => {
  //   if(view.length > 0) {
  //     setDateDropdownToFirstInView()
  //   }
  // }, [view])

  const renderRecommended = () => {
    const timeComponents =
      times &&
      times.map((time, i) => {
        var startTime = moment(time?.start).format("hh:mm a");
        var endTime = moment(time?.end).format("hh:mm a");
        var startDate = moment(time?.start).format("ddd, MMM D");

        return (
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              marginBottom: 5,
              marginRight: "5px",
            }}
            key={i}
            data-start={time?.start}
          >
            <Chip
              label={
                <div>
                  <Typography
                    style={{ color: theme.palette.info.light, marginTop: 5 }}
                    variant="h4"
                    fontWeight={300}
                  >
                    {startDate}
                  </Typography>
                  <Typography
                    style={{ color: theme.palette.text.primary }}
                    variant="h3"
                    fontWeight={400}
                  >
                    {mobile ? startTime : startTime + " - " + endTime}
                  </Typography>{" "}
                </div>
              }
              key={i}
              id={i}
              sx={{
                background: "#96DCFF80",
                borderRadius: "8px",
                p: { xs: "20px 8px", md: "20px 0px 25px 0px" },
                "& .MuiChip-label": {
                  paddingLeft: "8px",
                  paddingRight: "8px",
                },
              }}
              clickable={true}
              onClick={(e) => props.handleMoveRecommended(e, i)}
            />
          </Box>
        );
      });
    return timeComponents;
  };

  const timesComponents = renderRecommended();

  const handleLeftClick = () => {
    setLeftClick(true);

    switch (lastSlice - view?.length <= 0) {
      case true:
        setLastSlice(6);
        break;
      case false:
        setLastSlice(lastSlice - view?.length);
        break;
      default:
        break;
    }
  };

  const handleRightClick = () => {
    setLeftClick(false);

    switch (lastSlice + 6 >= timesComponents?.length) {
      case true:
        setLastSlice(timesComponents?.length);
        break;
      case false:
        setLastSlice(lastSlice + 6);
        break;
      default:
        break;
    }
  };

  const moveToDateInRecommended = () => {
    const dateFromDropdown = new Date(dateObject.recommendedDate);
    const date = dateFromDropdown.getDate();
    const month = dateFromDropdown.getMonth();
    const year = dateFromDropdown.getFullYear();

    const dateIndexInTimes = times.findIndex((availableTime) => {
      return (
        availableTime.start.getDate() === date &&
        availableTime.start.getMonth() === month &&
        availableTime.start.getFullYear() === year
      );
    });

    const today = new Date();
    const dateToday = today.getDate();
    const monthToday = today.getMonth();
    const yearToday = today.getFullYear();

    if (dateIndexInTimes !== -1) {
      const multiple = Math.trunc(dateIndexInTimes / 6);
      const lastSlice = multiple * 6 + 6;
      const firstSlice = multiple * 6;
      setView(timesComponents?.slice(firstSlice, lastSlice));
      setNoTimesError(false);
    }
    //handles default case on load
    else if (dateToday === date && monthToday === month && yearToday === year) {
      setView(timesComponents?.slice(0, 6));
      setNoTimesError(false);
    } else {
      setNoTimesError(true);
    }
  };

  // const setDateDropdownToFirstInView = () => {
  //   const firstDateInView = view[0].props["data-start"]
  //   console.log('first in view: ', firstDateInView)
  //   // dateObject.setDate(firstDateInView.getDate())
  //   // dateObject.setMonth(moment(firstDateInView).format("MMMM"))
  //   // dateObject.setMonthInt(firstDateInView.getMonth())
  //   // dateObject.setYear(firstDateInView.getFullYear())
  //   // dateObject.setRecommendedDate(moment(firstDateInView).format("M/D/YYYY"))
  // }

  return (
    <Stack justifyContent="flex-start" direction="row" alignItems="center">
      {props.noSlot ? (
        <Box mt={1}>
          <Chip
            label={
              <div>
                <Typography
                  style={{ color: theme.palette.info.light, marginTop: 5 }}
                  variant="h4"
                  fontWeight={300}
                >
                  No Slot found
                </Typography>
              </div>
            }
            sx={{
              background: theme.palette.secondary.light,
              borderRadius: "8px",
              p: { xs: 0, md: "20px 0px 25px 0px" },
              width: 140,
            }}
          />
        </Box>
      ) : (
        <>
          <IconButton onClick={() => handleLeftClick()} style={{ padding: 0 }}>
            <ChevronLeftIcon style={{ color: theme.palette.text.primary }} />
          </IconButton>
          <Box
            spacing="8px"
            display="flex"
            justifyContent={"center"}
            sx={{
              overflowX: { xs: "auto", md: "hidden" },
              flexWrap: "wrap",
              width: { xs: "100%", md: "430px" },
            }}
          >
            {view}
          </Box>
          <IconButton onClick={() => handleRightClick()} style={{ padding: 0 }}>
            <ChevronRightIcon style={{ color: theme.palette.text.primary }} />
          </IconButton>
        </>
      )}
    </Stack>
  );
}

function SelectedTimes(props) {
  const renderSelected = () => {
    if (props?.selectedSlot !== undefined) {
      const selected =
        props.selectedSlot &&
        props.selectedSlot.map((selected, i) => (
          <BadgedChip
            key={i}
            selected={selected}
            id={i}
            handleRemoveClick={props.handleRemoveClick}
            setSelectedSlot={props.setSelectedSlot}
          />
        ));
      return selected;
    }
  };

  const selectedComponents = renderSelected();

  return (
    <Box
      spacing="8px"
      display="flex"
      justifyContent={"flex-start"}
      sx={{
        // overflowX: { xs: "scroll", md: "hidden" },
        overflow: "visible",
        flexWrap: "wrap",
        width: { xs: "100%", md: "85%" },
        marginLeft: "40px",
      }}
    >
      {selectedComponents}
    </Box>
  );
}

function BadgedChip(props) {
  const [invisible, setInvisible] = useState(true);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  var startTime = moment(props?.selected.value?.start).format("hh:mm a");
  var endTime = moment(props?.selected.value?.end).format("hh:mm a");
  var startDate = moment(props?.selected.value?.start).format("ddd, MMM D");

  return (
    <Badge
      color="primary"
      badgeContent={
        <IconButton onClick={() => props.handleRemoveClick(props.id)}>
          <CancelIcon sx={{ color: "#96DCFF" }} />
        </IconButton>
      }
      invisible={invisible}
      onMouseOver={() => setInvisible(false)}
      onMouseOut={() => setInvisible(true)}
      sx={{
        "& .MuiBadge-badge": { maxWidth: "0", maxHeight: "35%" },
        marginTop: "10px",
        marginRight: "5px",
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Chip
          label={
            <div>
              <Typography
                style={{ color: theme.palette.info.light, marginTop: 5 }}
                variant="h4"
                fontWeight={300}
              >
                {startDate}
              </Typography>
              <Typography
                style={{ color: theme.palette.text.primary }}
                variant="h3"
                fontWeight={400}
              >
                {mobile ? startTime : startTime + " - " + endTime}
              </Typography>{" "}
            </div>
          }
          sx={{
            background: "#72B0EE80",
            borderRadius: "8px",
            minWidth: "94px",

            p: { xs: "20px 8px", md: "20px 0px 25px 0px" },
            // border: "1.5px solid #479AE9",
            "& .MuiChip-label": {
              paddingLeft: "8px",
              paddingRight: "8px",
            },
          }}
          onMouseOut={() => setInvisible(true)}
          onMouseOver={() => setInvisible(false)}
        />
      </Box>
    </Badge>
  );
}

function Month({ dateObject: monthData }) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleChange = (event) => {
    monthData.setMonth(event.target.value);
    monthData.setMonthInt(
      months.findIndex((month) => month === event.target.value)
    );
  };

  return (
    <Select
      id="choose-month"
      value={monthData?.month}
      label="Month"
      disableUnderline={true}
      defaultValue={monthData?.month}
      onChange={handleChange}
      variant="standard"
      sx={{ fontWeight: 500, fontSize: "14px" }}
    >
      {months.map((month, i) => (
        <MenuItem key={i} value={month}>
          {month}
        </MenuItem>
      ))}
    </Select>
  );
}

function Year({ calendarRef, dateObject: yearData }) {
  const [year, setYear] = useState(parseInt(yearData?.year)); // used for tracking current year
  // const [nextYear, setNextYear] = useState(parseInt(yearData?.year) + 1);
  // const [prevYear, setPrevYear] = useState(parseInt(yearData?.year) - 1);
  const [selected, setSelected] = useState(parseInt(yearData?.year)); // used for tracking the selected year, defaults to current year

  useEffect(() => {
    setYear(calendarRef?.current?.getApi()?.getDate()?.getFullYear());
    // setNextYear(year + 1);
    setSelected(yearData?.year);
  }, [calendarRef, year, yearData?.year]);

  const handleChange = (event) => {
    yearData.setYear(event.target.value); //sets year in the main component
    setSelected(event.target.value);
  };

  return (
    <Select
      id="choose-year"
      value={selected}
      label="Month"
      defaultValue={year}
      onChange={handleChange}
      variant="standard"
      sx={{ fontWeight: 500, fontSize: "14px" }}
      disableUnderline={true}
    >
      <MenuItem value={parseInt(yearData?.year) - 1}>
        {parseInt(yearData?.year) - 1}
      </MenuItem>
      <MenuItem value={year}>{year}</MenuItem>
      <MenuItem value={parseInt(yearData?.year) + 1}>
        {parseInt(yearData?.year) + 1}
      </MenuItem>
    </Select>
  );
}
