import { Grid, Typography, useMediaQuery, Box } from "@mui/material";

import { ExtLogins } from "pages/MeetingSignup/UserSignup";
import { useTranslation } from "react-i18next";
import { ReactComponent as SkedingBetaIcon } from "../../assets/logo-beta.svg";
import useStyle from "./styles";

/**
 * Sign up form shown to nonskeding user when they're responding to a meeting invitation
 */
export default function Signup(props) {
  const mobile = useMediaQuery(props.theme.breakpoints.down("md"));
  const { t } = useTranslation();
  const classes = useStyle();
  return (
    <>
      {mobile ? (
        <>
          <Grid item xs={12} sx={{ pb: "10px" }}>
            <ExtLogins login={false} />
          </Grid>
          <Grid item xs={12} align="center">
            <Typography
              sx={{ fontSize: "15px", fontWeight: "400", mb: 5 }}
              align="center"
            >
              {t("signup_msg_web")}
              <br />
              {t("free_account")}
            </Typography>
          </Grid>
        </>
      ) : (
        <Box
          display="flex"
          justifyContent={"center"}
          alignItems="center"
          flexDirection="column"
        >
          <Box className={classes.signup}>
            <SkedingBetaIcon />
          </Box>
          <Box mt="30px">
            <Typography
              variant="h3"
              align="center"
              color={props.theme.palette.text.disabled}
            >
              {t("signup_msg_web")}
              <br />
              {t("free_account")}
            </Typography>
          </Box>
          <Box sx={{ mt: "14px" }}>
            <ExtLogins login={false} />
          </Box>
          <Box sx={{ mt: "30px" }}>
            <Typography
              sx={{
                fontWeight: "500",
                fontSize: "28px",
              }}
              align="center"
            >
              {t("welcome_desc")}
            </Typography>
          </Box>
          <Box sx={{ mt: "14px" }}>
            <Typography
              color={props.theme.palette.text.disabled}
              variant="h3"
              align="center"
            >
              {t("welcome_desc2")} <br /> {t("welcome_desc3")}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
}
