import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Typography,
  Button,
  Alert,
  AlertTitle,
  Grid,
  Box,
  Skeleton,
  Dialog,
  CircularProgress,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import "styles/newEvent.css";
import { serverGET, serverPUT } from "Utils/HttpFunctions";
import { Title } from "../../pages/NewEvent";
import Description from "components/NewEvent/Description";
import Location from "components/NewEvent/Location";
import TimeInterval from "components/NewEvent/TimeInterval";
import { useNavigate } from "react-router-dom";
import { integrationContext } from "context/Integrations";
import moment from "moment";
import PhysicalLocation from "components/NewEvent/PhysicalLocation";
import Phone from "components/NewEvent/Phone";
import VirtualLink from "components/NewEvent/Link";
import { checkUrl } from "Utils/CommonFunctions";
import CustomInterval from "components/NewEvent/CustomInterval";
import useStyle from "../../styles/formInputs";
import useAvailability from "../../Utils/hooks/useAvailability";
import AvailableTimeslots from "../Preferences/AvailableTimeslots";
import Participants from "../NewEvent/Participants";
import useContactGroups from "../../Utils/hooks/useContactGroups";
import { useTranslation } from "react-i18next";
import { RoomNo } from "../../pages/RoomNo";

export default function EditTemplate({
  templateId,
  open,
  setRefresh,
  handleClose,
  setModal,
}) {
  const [title, setTitle] = useState("");
  const [timeInterval, setTimeInterval] = useState([]);
  const [template, setTemplate] = useState();
  const [error, setError] = useState("");
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");
  const [disabled, setDisabled] = useState(false);
  const { user } = useContext(integrationContext);
  const navigate = useNavigate();
  const [room, setRoom] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [link, setLink] = useState("");
  const [customTime, setCustomTime] = useState("");
  const classes = useStyle();
  const [participants, setParticipants] = useState([]);
  const [contacts, groups, minimizedContacts] = useContactGroups(participants);
  const { t } = useTranslation();

  const days = [
    t("Sun"),
    t("Mon"),
    t("Tue"),
    t("Wed"),
    t("Thr"),
    t("Fri"),
    t("Sat"),
  ];

  const {
    handleSaveButtonClick,
    calInfo,
    setCalInfo,
    error: slotErr,
  } = useAvailability(true);

  useEffect(() => {
    if (open) {
      const abortController = new AbortController();
      getMeetingTemplate(abortController);
      return () => {
        abortController.abort();
      };
    }
  }, [open]);

  const clearForm = () => {
    setTitle("");
    setTimeInterval("");
    setDescription("");
    setLocation("");
    setPhone("");
    setLink("");
    setAddress("");
  };

  const parseDuration = (response) => {
    if (response.data.duration === 60) {
      return (
        "0" +
        Math.floor(response.data.duration / 60) +
        ":0" +
        ((response.data.duration % 60) + ":" + "00").toString()
      );
    } else {
      return (
        "0" +
        Math.floor(response.data.duration / 60) +
        ":" +
        (
          (response.data.duration % 60 == 0
            ? (response.data.duration % 60) + "0"
            : response.data.duration % 60) +
          ":" +
          "00"
        ).toString()
      );
    }
  };

  const getMeetingTemplate = async (abortController) => {
    const calendarLocations = ["google meet", "outlook", "teams", "zoom"];
    const response = await serverGET(
      `/meeting-template/${templateId}`,
      abortController.signal
    );
    if (response && response.constructor !== String) {
      if (response.code === 200) {
        setTemplate(response.data);
        setTitle(response.data.title);
        setParticipants(
          response.data?.skeding_participants?.map((p) => p.email)
        );

        if ([15, 30, 45, 60].includes(response.data.duration)) {
          setTimeInterval(parseDuration(response));
        } else {
          setTimeInterval("custom");
          setCustomTime(parseDuration(response));
        }

        setDescription(response.data.agenda);
        if (calendarLocations.includes(response.data.location.toLowerCase())) {
          setLocation(response.data.location);
        } else {
          if (
            response.data.location.includes("https") ||
            response.data.location.includes("http")
          ) {
            setLocation("Link");
            setLink(response.data.location);
          } else if (response.data.location.includes("+")) {
            setLocation("Phone");
            setPhone(response.data.location);
          } else {
            setLocation("Other");
            setAddress(response.data.location);
            setRoom(response.data.meeting_room ?? "");
          }
        }

        const result = response.data.organizer_available_timeslots.reduce(
          function (r, a) {
            r[a.start_day] = r[a.start_day] || [];
            r[a.start_day].push(a);
            return r;
          },
          Object.create(null)
        );

        const temp = [...calInfo];
        response.data.organizer_available_timeslots.map((item) => {
          temp[item.start_day] = {
            isActive: true,
            label: days[item.start_day],
            slot: result[item.start_day],
          };
        });
        setCalInfo(temp);
      }
    }
  };

  const handleUpdate = async () => {
    if (timeInterval === "" || location === "") {
      setError(t("mandatory_field_error"));
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      return;
    }
    if (
      (location === "Other" && address === "") ||
      (location === "Phone" && phone === "") ||
      (location === "Link" && link === "")
    ) {
      setError(t("address_err"));
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      setDisabled(false);
      return;
    }
    if (timeInterval === "custom" && customTime === "") {
      setError("Error! Please enter valid time interval");
      return;
    }
    if (location === "Phone" && phone.length < 6) {
      setError(t("phone_err"));
      return false;
    }
    if (location === "Link" && !checkUrl(link)) {
      setError(t("link_err"));
      return false;
    }
    var loc =
      location === "Other"
        ? address
        : location === "Phone"
        ? phone
        : location === "Link"
        ? link
        : location;

    const duration_minutes =
      timeInterval === "custom"
        ? moment.duration(customTime).asMinutes()
        : typeof timeInterval === "string"
        ? moment.duration(timeInterval).asMinutes()
        : timeInterval;

    const organizerAvailability = await handleSaveButtonClick();

    setDisabled(true);
    let data = {
      title,
      agenda: description,
      location: loc,
      duration: duration_minutes,
      meeting_room: location === "Other" ? (room ? room : "") : "",
      organizer_available_timeslots: organizerAvailability.available_timeslots,
      organizer_blocked_timeslots: organizerAvailability.blocked_timeslots,
      participants,
    };
    const response = await serverPUT(
      `/meeting-template/${templateId}?offset=${new Date().getTimezoneOffset()}`,
      data
    );
    if (response && response.constructor !== String) {
      navigate("/shareable-meeting", {
        state: { showMessage: true, update: true },
      });
      setDisabled(false);
      handleClose();
      clearForm();
      setRefresh((prev) => !prev);
      setModal({ open: true, msg: t("shareable_edit") });
    } else {
      console.log("create template failed");
      setDisabled(false);
    }
  };

  const handleIntervalChange = (event) => {
    console.log(event.target.value);
    setTimeInterval(event.target.value);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={"md"}
      fullWidth
      PaperProps={{
        sx: { padding: 2, borderRadius: "8px" }, // Set position to relative
      }}
    >
      {template ? (
        <Box>
          <Box width="100%" display="flex" justifyContent="flex-end">
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box>
            <Typography variant="h2" fontWeight={500} align="center">
              {t("edit_shareable")}
            </Typography>
          </Box>
          {error && (
            <Alert severity="error">
              <AlertTitle style={{ textAlign: "left" }}>
                {t("error")}
              </AlertTitle>
              {error}
            </Alert>
          )}
          <Grid
            container
            className={classes.root}
            marginTop="20px"
            columnSpacing={4}
            alignItems={"start"}
          >
            <Grid container item xs={12} md={6}>
              <Alignment
                text={t("title")}
                comp={<Title setTitle={setTitle} title={title} />}
              />
              <Alignment
                text={t("participants")}
                comp={
                  <Box style={{ width: "100%", overflowX: "auto" }}>
                    <Participants
                      setParticipants={setParticipants}
                      participants={participants}
                      participantLabels={[]}
                      contacts={contacts}
                      groups={groups.filter((g) =>
                        g.contacts.every(
                          (c) => c.is_contact_skeding_user === true
                        )
                      )}
                      minimizedContacts={minimizedContacts.filter(
                        (p) => p.isSkeding
                      )}
                      user={user}
                      checkOnlySkeding={true}
                      isRequired={false}
                    />
                  </Box>
                }
              />

              <Alignment
                text={t("duration")}
                comp={
                  <TimeInterval
                    timeInterval={timeInterval}
                    handleIntervalChange={handleIntervalChange}
                    notNewMeeting={true}
                  />
                }
              />
              <Alignment
                text={timeInterval === "custom" ? t("custom") : null}
                comp={
                  <>
                    {timeInterval === "custom" ? (
                      <Grid container item xs={12}>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          width="100%"
                        >
                          <CustomInterval
                            setCustomTime={setCustomTime}
                            customTime={customTime}
                          />
                        </Box>
                      </Grid>
                    ) : null}{" "}
                  </>
                }
              />

              <Alignment
                text={t("location")}
                comp={
                  <Location
                    setLocation={setLocation}
                    location={location}
                    user={user}
                    notNewMeeting={true}
                  />
                }
              />
              <Alignment
                text={
                  location === "Other"
                    ? t("address")
                    : location === "Phone"
                    ? t("phone_no")
                    : location === "Link"
                    ? t("link")
                    : null
                }
                subcomp={
                  location === "Other" ? (
                    <Grid container mb={2}>
                      <Grid item md={3.3}>
                        {
                          <Typography variant="h3">
                            {t("room_no") + ":"}
                          </Typography>
                        }
                      </Grid>
                      <Grid item md={8.7} xs={12}>
                        <RoomNo room={room} setRoom={setRoom} />
                      </Grid>
                    </Grid>
                  ) : null
                }
                comp={
                  <>
                    {location === "Other" && (
                      <PhysicalLocation
                        setAddress={setAddress}
                        address={address}
                      />
                    )}

                    {location === "Phone" && (
                      <Phone setPhone={setPhone} phone={phone} />
                    )}
                    {location === "Link" && (
                      <VirtualLink setLink={setLink} link={link} />
                    )}
                  </>
                }
              />
              <Alignment
                text={t("desc")}
                comp={
                  <Description
                    setDescription={setDescription}
                    description={description}
                  />
                }
              />
            </Grid>

            <Grid container item xs={12} md={6}>
              <Typography
                align="left"
                variant="h3"
                sx={{
                  width: { xs: "auto", md: "fit-content" },
                  fontWeight: "400 !important",
                }}
              >
                {t("avail_title")}
              </Typography>

              <Typography
                align="left"
                variant="h4"
                fontWeight={400}
                style={{
                  width: "fit-content",
                  color: "gray",
                  marginTop: "4px",
                }}
              >
                {t("avail_desc")}
              </Typography>

              <AvailableTimeslots calInfo={calInfo} setCalInfo={setCalInfo} />
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={12}
            justifyContent={"center"}
            columnSpacing={4}
            sx={{ mt: 4 }}
          >
            {/* <Grid item>
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
            </Grid> */}
            <Grid item>
              <Button
                variant="contained"
                onClick={handleUpdate}
                disabled={disabled}
              >
                {disabled ? (
                  <CircularProgress style={{ color: "white" }} size={20} />
                ) : (
                  t("done")
                )}
              </Button>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box style={{ width: "100%", padding: "20px 10px" }}>
          <Skeleton animation="wave" height={40} width="100%" />
          <Skeleton
            animation="wave"
            height={40}
            width="100%"
            style={{ marginTop: "2rem", marginBottom: "2rem" }}
          />
          <Skeleton
            animation="wave"
            height={40}
            width="100%"
            style={{ marginTop: "2rem", marginBottom: "2rem" }}
          />
          <Skeleton animation="wave" height={40} width="100%" />
        </Box>
      )}
    </Dialog>
  );
}

function Alignment({ text, comp, subcomp }) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box style={{ width: "100%", display: "block" }}>
      <Box
        display={mobile ? "block" : "flex"}
        alignItems="center"
        columnGap={3}
        flex={1}
        mb={mobile ? 1.5 : 0}
      >
        {text && (
          <Box sx={{ width: { md: 130, xs: 100 } }}>
            <Typography variant="h3">{text}:</Typography>
          </Box>
        )}
        {comp && comp}
      </Box>
      <Box>{subcomp && subcomp}</Box>
    </Box>
  );
}
