import { useState, useEffect, useRef, useReducer, useContext } from "react";

import {
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Box,
} from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ListIcon from "@mui/icons-material/List";

import styles from "styles/styles.module.css";
import { meetingsPageLoader } from "components/meetingsLoader";
import { downloadFileAttachment } from "Utils/CommonFunctions";
import { meetingsContext } from "context/meetings";
import MeetingsCalendar from "components/meetings/MeetingsCalendar";
import MeetingDetailsMyMeetings from "components/meetings/MeetingDetailsMyMeetings";
import Meetings from "../../components/meetings/Meetings";
import StyledTabs from "components/meetings/StyledTabs";
import StyledTab from "components/meetings/StyledTab";
import { useTranslation } from "react-i18next";
import useStyle from "./styles";

const reducer = (meetingDetailPopup, action) => {
  switch (action.type) {
    case "open":
      console.log("open returns: ", {
        meeting: action.meeting,
        show: true,
      });
      return {
        meeting: action.meeting,
        show: true,
      };
    case "close":
      return {
        meeting: null,
        show: false,
      };
  }
};

export default function UserMeetings() {
  const {
    pendingMeetings,
    getPlannedEvents,
    meetingInvites,
    setMeetingInvites,
    websiteBookings,
    loadingMeetingInvites,
    loadingPendingMeetings,
    noPendingMeetings,
    setNoPendingMeetings,
    noMeetingInvites,
    setNoMeetingInvites,
    meetingDeleted,
    setMeetingDeleted,
    meetingResponded,
    setMeetingResponded,
  } = useContext(meetingsContext);

  const [meetingDetailPopup, setMeetingDetailPopup] = useReducer(reducer, {
    show: false,
    meeting: null,
  });
  const [calView, setCalView] = useState(false);
  const [tab, setTab] = useState(0);

  const calendarRef = useRef(null);
  const theme = useTheme();
  const tablet = useMediaQuery(theme.breakpoints.down("lg"));
  const { t } = useTranslation();
  const classes = useStyle();

  /**
   * Fetches the planned events when a meeting is responded to
   */
  useEffect(() => {
    const abortController = new AbortController();
    if (meetingDeleted || meetingResponded) {
      getPlannedEvents(abortController);
    }
    return () => {
      abortController.abort();
    };
  }, [meetingDeleted, meetingResponded]);

  /**
   * Sets a boolean variable to show "No pending meetings" text
   * if the pending meetings returned from the backend is an empty array
   */
  useEffect(() => {
    if (pendingMeetings?.length === 0) {
      setNoPendingMeetings(true);
    } else {
      setNoPendingMeetings(false);
    }
  }, [pendingMeetings]);

  /**
   * Sets a boolean variable to show "No meeting invites" text
   * if the meeting invited returned from the backend is an empty array
   */
  useEffect(() => {
    if (meetingInvites?.length === 0) {
      setNoMeetingInvites(true);
    } else {
      setNoMeetingInvites(false);
    }
  }, [meetingInvites]);

  /**
   * Downloads a file for a given event
   * @param {string} fileName - the name of the file to be downloaded
   * @param {string} eventId - the id of the event for which the file is being downloaded
   */
  const handleDownload = async (fileName, eventId) => {
    await downloadFileAttachment(fileName, eventId);
  };

  return (
    <Grid container sx={{ padding: 0 }}>
      <Grid
        item
        xs={12}
        lg={4.5}
        style={{ display: tablet ? (calView ? "none" : "block") : "block" }}
      >
        <Box className={classes.container}>
          <Box className={classes.padding}>
            {tablet ? (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                style={{ width: "100%" }}
              >
                <Typography align="left" variant="h2">
                  {t("my_meetings")}
                </Typography>
                <Button
                  variant="text"
                  style={{ fontSize: 14 }}
                  startIcon={calView ? <ListIcon /> : <CalendarMonthIcon />}
                  onClick={() => setCalView((prev) => !prev)}
                >
                  {calView ? t("list_view") : t("cal_view")}
                </Button>
              </Box>
            ) : (
              <Typography align="left" variant="h2">
                {t("my_meetings")}
              </Typography>
            )}

            <StyledTabs value={tab} onChange={(e, newTab) => setTab(newTab)}>
              <StyledTab
                sx={{
                  padding: { xs: "12px 0px", md: "12px 16px" },
                }}
                label={t("pending_meetings")}
              />
              <StyledTab label={t("invite_meetings")} />
              {/* BR-1 */}
              <StyledTab label={t("website_bookings")} />
            </StyledTabs>
          </Box>

          <Box flexGrow={1} className={`${styles.upper} ${classes.panelBox}`}>
            <TabPanel tab={tab} index={0}>
              {loadingPendingMeetings ? (
                meetingsPageLoader
              ) : noPendingMeetings ? (
                <Typography
                  variant="h4"
                  align="left"
                  fontWeight={400}
                  className={classes.noMsg}
                >
                  {t("no_pending_msg")}
                </Typography>
              ) : pendingMeetings ? (
                <Meetings
                  tab={tab}
                  meetings={pendingMeetings}
                  setMeetingDeleted={setMeetingDeleted}
                  setMeetingDetailPopup={setMeetingDetailPopup}
                  meetingDetailPopup={meetingDetailPopup}
                  handleDownload={handleDownload}
                  calendarRef={calendarRef}
                />
              ) : null}
            </TabPanel>
            <TabPanel tab={tab} index={1}>
              {loadingMeetingInvites ? (
                meetingsPageLoader
              ) : noMeetingInvites ? (
                <Typography
                  variant="h4"
                  align="left"
                  fontWeight={400}
                  className={classes.noMsg}
                >
                  {t("no_invites_msg")}
                </Typography>
              ) : meetingInvites ? (
                <Meetings
                  tab={tab}
                  meetings={meetingInvites}
                  setMeetingDeleted={setMeetingDeleted}
                  setMeetingDetailPopup={setMeetingDetailPopup}
                  meetingDetailPopup={meetingDetailPopup}
                  handleDownload={handleDownload}
                  calendarRef={calendarRef}
                  setMeetingResponded={setMeetingResponded}
                  meetingInvites={meetingInvites}
                  setMeetingInvites={setMeetingInvites}
                />
              ) : null}
            </TabPanel>
            {/* BR-1 */}
            <TabPanel tab={tab} index={2}>
              {websiteBookings && websiteBookings.length > 0 ? (
                <Meetings
                  tab={tab}
                  meetings={websiteBookings}
                  setMeetingResponded={setMeetingResponded}
                  setMeetingDetailPopup={setMeetingDetailPopup}
                  meetingDetailPopup={meetingDetailPopup}
                  handleDonwload={handleDownload}
                  setMeetingDeleted={setMeetingDeleted}
                  calendarRef={calendarRef}
                />
              ) : (
                <Typography
                  variant="h4"
                  fontWeight={400}
                  className={classes.noMsg}
                >
                  {t("no_web_msg")}
                </Typography>
              )}
            </TabPanel>
          </Box>
        </Box>
        <MeetingDetailsMyMeetings
          open={meetingDetailPopup.show}
          event={meetingDetailPopup.meeting}
          tab={tab}
          handleClose={() => setMeetingDetailPopup({ type: "close" })}
          handleDownload={handleDownload}
          title={
            tab === 0
              ? t("meeting_details")
              : tab === 1
              ? t("meeting_invite")
              : t("web_bookings")
          }
        />
      </Grid>
      <Grid
        item
        xs={12}
        lg={7.5}
        style={{
          display: tablet ? (calView ? "block" : "none") : "block",
          height: "85vh",
        }}
      >
        <Box style={{ marginLeft: tablet ? 0 : "16px" }}>
          {tablet ? (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              style={{ width: "100%" }}
            >
              <Typography align="left" variant="h2">
                {t("your") + " " + t("meetings")}
              </Typography>
              <Button
                variant="text"
                style={{ fontSize: 14 }}
                startIcon={calView ? <ListIcon /> : <CalendarMonthIcon />}
                onClick={() => setCalView((prev) => !prev)}
              >
                {calView ? t("list_view") : t("cal_view")}
              </Button>
            </Box>
          ) : null}

          <MeetingsCalendar
            calendarRef={calendarRef}
            setMeetingDetailPopup={setMeetingDetailPopup}
            meetingDetailPopup={meetingDetailPopup}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

function TabPanel(props) {
  const { children, tab, index, ...other } = props;
  return (
    <div {...other}>{tab === index && <Grid container>{children}</Grid>}</div>
  );
}
