import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import Comment from "../meetings/Comment";
import makeStyles from "@mui/styles/makeStyles";
import { serverPUT } from "../../Utils/HttpFunctions";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SnackbarComponent from "../Global/SnackbarComponent";
const useStyle = makeStyles((theme: any) => ({
  checkbox: {
    color: theme.palette.text.primary,
    "&.Mui-checked": {
      color: "#F7F7F9",
      stroke: theme.palette.text.primary,
      strokeWidth: "2px",
      strokeLineJoin: "round",
    },
  },
}));

export default function SendNotes({ meeting, handleNoteClick }) {
  const [emails, setEmails] = useState<string[]>([]);
  const [contacts, setContacts] = useState<string[]>([]);
  const [toggleList, setToggleList] = useState(false);
  const [notes, setNotes] = useState("");
  const [loading, setLoading] = useState(false);
  const classes = useStyle();
  const [successMsg, setSuccessMsg] = useState("");

  useEffect(() => {
    // Function to combine emails
    const combineEmails = () => {
      // Extract emails from arr2
      const skedingParticipants = meeting?.skedingParticipants?.map(
        (obj) => obj.email
      );
      const nonSkedingParticipants = meeting?.nonskedingParticipants ?? [];
      return [...skedingParticipants, ...nonSkedingParticipants];
    };

    // Use useEffect to set the combined emails on component mount
    setEmails(combineEmails());
    setContacts(combineEmails());
    if (meeting?.meeting_notes) {
      setNotes(meeting?.meeting_notes?.description);
    }
  }, [meeting]);

  const chips = useMemo(() => {
    return emails?.map((contactName, index) => {
      return (
        <Chip
          key={index}
          variant="filled"
          label={contactName}
          style={{
            background: meeting?.nonskedingParticipants?.includes(contactName)
              ? "#B3B5B680"
              : "#B0E4FF",
            borderRadius: "4px",
            fontSize: "14px",
            color: "#3C4242",
            overflow: "hidden",
            whiteSpace: "nowrap",
            maxWidth: "260px",
          }}
        />
      );
    });
  }, [emails]);

  const handleCheck = (event, email) => {
    if (event.target.checked) {
      setEmails((prev) => [...prev, email]);
    } else {
      const temp = [...emails];
      const index = temp.indexOf(email);
      if (index !== -1) {
        temp.splice(index, 1);
      }
      setEmails(temp);
    }
  };
  const handleSend = async () => {
    setLoading(true);
    if (notes.trim() === "" || emails.length === 0) {
      return;
    }
    const response = await serverPUT(`/meeting/sendnotes/${meeting.id}`, {
      participants: emails,
      description: notes,
    });
    console.log("🚀 ~ handleSend ~ response:", response);
    if (response === "Data has been added successfully!") {
      setSuccessMsg("Your notes have been sent");
      setTimeout(() => {
        handleNoteClick();
      }, 3000);
    } else {
      console.log("error");
    }
    setLoading(false);
  };
  return (
    <Box>
      {meeting?.meeting_notes ? (
        <>
          <Typography variant="h2" align="center" sx={{ marginBottom: "8px" }}>
            View Notes
          </Typography>
          <Box>
            <Typography
              variant="h4"
              align="left"
              sx={{ paddingLeft: "10px" }}
              mb={-2}
            >
              Notes:
            </Typography>

            <Comment
              comment={notes}
              setComment={setNotes}
              disabled={true}
              placeholder="For adding notes for participants, please type it in here..."
            />
          </Box>
          <Stack
            direction="row"
            spacing="20px"
            justifyContent="center"
            pb={4}
            pt={1}
          >
            <Button onClick={handleNoteClick} variant="outlined">
              Cancel
            </Button>
          </Stack>
        </>
      ) : (
        <>
          <Typography variant="h2" align="center" sx={{ marginBottom: "8px" }}>
            Send Notes
          </Typography>
          <Box>
            <Typography
              variant="h4"
              align="left"
              sx={{ paddingLeft: "10px" }}
              mb={-2}
            >
              Notes:
            </Typography>

            <Comment
              comment={notes}
              setComment={setNotes}
              placeholder="For adding notes for participants, please type it in here..."
              disabled={false}
            />
          </Box>
          <Box sx={{ paddingLeft: "10px" }} mt={2}>
            <Typography variant="h4" align="left">
              Select Participants:
            </Typography>
            <Box
              display={"flex"}
              gap={1}
              mt={1}
              flexWrap={"wrap"}
              alignItems="center"
            >
              {chips}
              <Box>
                <Button
                  variant="text"
                  disableRipple={true}
                  onClick={() => setToggleList((prev) => !prev)}
                >
                  {toggleList ? (
                    <KeyboardArrowUpIcon style={{ color: "#3C4242" }} />
                  ) : (
                    <KeyboardArrowDownIcon style={{ color: "#3C4242" }} />
                  )}
                </Button>
              </Box>
            </Box>
            {toggleList && (
              <Box>
                {contacts.map((email) => (
                  <Box
                    alignItems={"center"}
                    sx={{
                      display: "flex",
                      gap: 1,
                    }}
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            checked={emails.includes(email)}
                            disableRipple={true}
                            className={classes.checkbox}
                            onChange={(e) => handleCheck(e, email)}
                            style={{
                              transform: "scale(0.7)",
                            }}
                          />
                        }
                        label={
                          <Box display={"flex"} alignItems="center" gap={1}>
                            <Avatar />

                            <Box
                              display="flex"
                              flexDirection="column"
                              alignItems="flex-start"
                              justifyContent="center"
                            >
                              <Typography variant="h4" fontWeight="400">
                                {email}
                              </Typography>
                            </Box>
                          </Box>
                        }
                      />
                    </FormGroup>
                  </Box>
                ))}
              </Box>
            )}
            <Stack
              direction="row"
              spacing="20px"
              justifyContent="center"
              pb={4}
              pt={1}
            >
              <Button onClick={handleNoteClick} variant="outlined">
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={handleSend}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress style={{ color: "white" }} size={20} />
                ) : (
                  "Send"
                )}
              </Button>
            </Stack>
          </Box>
        </>
      )}
      <SnackbarComponent
        open={!!successMsg}
        handleClose={() => setSuccessMsg("")}
        message={successMsg}
        vertical={"bottom"}
        horizontal={"center"}
      />
    </Box>
  );
}
