import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

import {
  Box,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

import { authContext } from "context/Auth";
import { useStyles } from "../../styles/navigationButtonStyles.js";

export default function LogoutButton({ t }) {
  const { isLogged, setIsLogged } = useContext(authContext);
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(["redirectPath"]);
  const classes = useStyles();

  /**
   * Clears all browser data when the user logs out
   */
  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    removeCookie("redirectPath", { path: "/" });
    setIsLogged(false);
  };

  useEffect(() => {
    if (isLogged === false) {
      navigate("/login");
    }
  }, [isLogged]);

  return (
    <Box className={classes.inactive} onClick={handleLogout}>
      <ListItemButton className={classes.inactiveButton}>
        <ListItemIcon className={classes.icon}>
          <ExitToAppIcon className={classes.iconSize} />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography align="left" variant="h4" color={"white"}>
              {t("logout")}
            </Typography>
          }
          className={classes.text}
          disableTypography={true}
        />
      </ListItemButton>
    </Box>
  );
}
