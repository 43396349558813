import makeStyles from "@mui/styles/makeStyles";

const useStyle = makeStyles((theme) => ({
  container: {
    margin: "auto",
    width: "90%",
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  btn: {
    width: 120,
    margintLeft: "10px",
  },
  slotsWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "space-between",
    marginTop: 0,
  },
  chipWrapper: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    alignItems: "flex-start",
  },
  chip: {
    borderRadius: "8px",
    padding: "20px 8.5px 20px 8.5px",
    "&&:hover": {
      backgroundColor: "rgb(210, 210, 210)",
    },
    "&&:focus": {
      backgroundColor: "rgba(114, 176, 238, 0.6)",
    },
  },
  smallChip: {
    borderRadius: "8px",
    padding: "8px 4px 8px 4px",
    "&&:hover": {
      backgroundColor: "rgb(210, 210, 210)",
    },
    "&&:focus": {
      backgroundColor: "rgba(114, 176, 238, 0.6)",
    },
  },
  desc: {
    marginLeft: "0px",
    color: theme.palette.info.light,
    marginTop: 4,
    marginBottom: 4,
  },
  badgeChip: {
    maxHeight: 420,
    overflowY: "auto",
  },

  calBox: {
    overFlow: "auto",
    // height: "380px",
    // [theme.breakpoints.up("md")]: {
    //   height: "460px",
    // },
  },
  badgedChip: {
    gap: "8px",
  },
  blue: {
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "center",
    gap: 16,
  },
}));

export default useStyle;
