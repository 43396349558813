import { create } from "zustand";

const useWebsiteClientStore = create((set, get) => ({
  userData: {},
  setUserData: (data) => {
    set({ userData: data });
  },

  selectedService: null,
  setSelectedService: (data) => {
    set({ selectedService: data });
  },

  selectedLocation: null,
  setSelectedLocation: (data) => {
    set({ selectedLocation: data });
  },
}));

export default useWebsiteClientStore;
