import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  Button,
  Grid,
  Box,
  Stack,
  IconButton,
  Card,
  Chip,
  useTheme,
  useMediaQuery,
  Divider,
} from "@mui/material";
import "styles/newEvent.css";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import loggingInDev from "loggingInDev";
import { serverGETWithToken, serverPOSTWithToken } from "Utils/HttpFunctions";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  convertDateToUTCMilliseconds,
  convertUTCMillisecondsToDate,
} from "Utils/CommonFunctions";
import { colors } from "Utils/colors";
import CreateShareableMeetingDialog from "components/MeetingTemplates/CreateShareableMeetingDialog";
import { MEETING_THROUGH_SHAREABLE_CALENDAR } from "Utils/Constants";
import BookingDetails from "components/PublicCalendar/BookingDetails";
import SkedingBanner from "components/SkedingBanner";
import SnackbarComponent from "components/Global/SnackbarComponent";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { authContext } from "context/Auth";
import { useContext } from "react";
import Spinner from "../../components/Global/Spinner";
import Style from "style-it";
import DialogBox from "../../components/DialogBox";
import { shareableCalendarStyles } from "../../styles/shareableCalendarStyles";
import { useTranslation } from "react-i18next";
import ErrorSnackbar from "components/ErrorSnackbar";
import Timezones from "../../components/Account/Timezones";
import moment from "moment-timezone";
import useStyle from "./styles";
import DialogWithCloseIcon from "../../components/DialogWithCloseIcon";
import Textfield from "../../components/AddToWebsite/Textfield";
import { serverPOST } from "../../Utils/HttpFunctions";
import Datepicker from "../../components/Global/Datepicker";

export default function ShareableCalendar() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [navLink, setNavLink] = useState(false);
  const [shareableData, setShareableData] = useState();
  const calendarRef = useRef(null);
  const [selectedSlot, setSelectedSlot] = useState();
  const [date, setDate] = useState(null);
  const [eventDays, setEventDays] = useState([]);
  const [daySlots, setDaySlots] = useState([]);
  const [popup, setPopup] = useState(false);
  const [responseModal, setResponseModal] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate();
  const [mobCalView, setMobCalView] = useState(true);
  const { isLogged } = useContext(authContext);
  const [noSlotModal, setNoSlotModal] = useState(false);
  const [error, setError] = useState("");
  const [selectedTimezone, setSelectedTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const classes = useStyle();

  const { t } = useTranslation();
  const handleSnackClose = () => {
    navigate("/");
  };

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    if (token) {
      getMeetingShareableData();
    }
  }, [token]);

  const getMeetingShareableData = async () => {
    if (token) {
      const response = await serverGETWithToken(
        `/meeting-template/shareable/calendar?offset=${new Date().getTimezoneOffset()}`,
        token,
        {
          params: {
            currentTime: convertDateToUTCMilliseconds(new Date()),
          },
        }
      );
      if (response && response.constructor !== String) {
        loggingInDev("getting data", response.data);
        setShareableData(response.data);
      } else {
        setError(response);
      }
    }
  };

  useEffect(() => {
    if (shareableData) {
      Promise.all([getRenderChips(), getUniqueDays()]);
      if (
        shareableData?.suggestions?.allAvailbleSlots?.length < 1 &&
        !noSlotModal
      ) {
        setNoSlotModal(true);
      }
    }
  }, [shareableData, selectedTimezone]);

  const getRenderChips = async () => {
    const daySlots = await renderChips(
      new Date(shareableData?.suggestions?.allAvailbleSlots[0]?.startTime)
    );
    setDate(
      new Date(shareableData?.suggestions?.allAvailbleSlots[0]?.startTime)
    );
    setDaySlots(daySlots);
  };

  const getUniqueDays = async () => {
    const parsedDays = await shareableData.suggestions.allAvailbleSlots.map(
      (slot) => {
        let start = convertUTCMillisecondsToDate(slot.startTime);
        return {
          start:
            start.getFullYear() +
            "-" +
            (start.getMonth() + 1 < 10
              ? "0" + (start.getMonth() + 1)
              : start.getMonth() + 1) +
            "-" +
            (start.getDate() < 10 ? "0" + start.getDate() : start.getDate()),
        };
      }
    );
    const uniqueDays = parsedDays.filter(
      (v, i, a) => a.findIndex((v2) => v2.start === v.start) === i
    );
    setEventDays(uniqueDays);
  };

  const handleCalendarSelect = async (selection) => {
    setDate(new Date(selection?.date));
    const daySlots = await renderChips(selection?.date);
    if (daySlots.length > 0) {
      setDaySlots(daySlots);
      setMobCalView((prev) => !prev);
    } else {
      setDaySlots([]);
    }
  };

  const compareDateWithoutTime = (dateA, dateB) => {
    const date1 = new Date(dateA.start);
    date1.setHours(0, 0, 0, 0);
    const date2 = new Date(dateB);
    date2.setHours(0, 0, 0, 0);
    if (date1 > date2) {
      return false;
    } else if (date1 < date2) {
      return false;
    } else {
      return true;
    }
  };

  const renderChips = async (selectedDate) => {
    const parsedSlots = await shareableData.suggestions.allAvailbleSlots.map(
      (slot) => {
        let start = convertUTCMillisecondsToDate(slot.startTime);
        let end = convertUTCMillisecondsToDate(slot.endTime);
        return {
          start,
          end,
        };
      }
    );
    const daySlots = await parsedSlots.filter((v) => {
      let result = compareDateWithoutTime(v, selectedDate);
      if (result) {
        return v;
      }
    });
    return daySlots;
  };

  const handleCreate = async () => {
    setPopup(true);
  };

  const createMeeting = async (name, email) => {
    setDisabled(true);

    const data = {
      initiator_user_id: shareableData.templateData.skeding_user_id,
      title: shareableData.templateData.title,
      duration_minutes: shareableData.templateData.duration,
      start_datetime: convertDateToUTCMilliseconds(selectedSlot.start),
      end_datetime: convertDateToUTCMilliseconds(selectedSlot.end),
      participants: [email],
      time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      utc_offset: new Date().getTimezoneOffset(),
      location: shareableData.templateData.location,
      room: shareableData.templateData?.meeting_room,
      description: shareableData.templateData.agenda,
      skeding_participants: shareableData.templateData.skeding_participants,
      case: MEETING_THROUGH_SHAREABLE_CALENDAR,
      non_skeding_participant_data: {
        participant_name: name,
        participant_email: email,
      },
    };
    const response = await serverPOSTWithToken(
      "/meeting/shareable",
      data,
      token
    );
    if (response && response.constructor !== String) {
      setResponseModal(true);
      setPopup(false);
      setDisabled(false);
    } else {
      loggingInDev("error =>", response);
      setDisabled(false);
    }
  };

  // if (isLogged) {
  //   return <Typography>{t("logged_in_err")}</Typography>;
  // }
  if (error) {
    return (
      <ErrorSnackbar
        open={!!error}
        handleClose={() => navigate("/login")}
        message={error}
        vertical={"bottom"}
        horizontal={"center"}
      />
    );
  }

  return shareableData ? (
    noSlotModal ? (
      <DialogBox
        msg={t("no_avail_slots_meeting")}
        handleClose={() => setNoSlotModal(false)}
        close={true}
        modal={noSlotModal}
      />
    ) : (
      <Style>
        {shareableCalendarStyles()}
        <div className={classes.container}>
          <SnackbarComponent
            open={responseModal}
            handleClose={handleSnackClose}
            message={t("meeting_created")}
            vertical={"bottom"}
            horizontal={"center"}
          />
          <Grid
            container
            columnSpacing={6}
            style={{ marginTop: mobile ? 24 : 30 }}
          >
            <Grid item xs={12} md={3.5}>
              <BookingDetails
                heading={t("book_appointment")}
                firstName={shareableData?.userData?.first_name}
                lastName={shareableData?.userData?.last_name}
                title={shareableData?.templateData?.title}
                duration={shareableData?.templateData?.duration}
                location={shareableData?.templateData?.location}
                agenda={shareableData?.templateData?.agenda}
                profile={shareableData?.userData?.picture}
                email={shareableData?.userData?.email}
                participants={shareableData?.templateData?.skeding_participants.map(
                  (p) => p.email
                )}
                roomNo={shareableData.templateData?.meeting_room}
                showLng={true}
              />
            </Grid>

            <Grid item xs={12} md={5}>
              <MobileHeadingToggler
                mobile={mobile}
                mobCalView={mobCalView}
                setMobCalView={setMobCalView}
                t={t}
              />
              {mobile && (
                <Box style={{ textAlign: "left" }} mt={1}>
                  <Divider />
                  <Box mt={2}>
                    <Typography variant="h4" style={{ marginBottom: "4px" }}>
                      Timezone
                    </Typography>
                    <Timezones
                      selectedTimezone={selectedTimezone}
                      setSelectedTimezone={setSelectedTimezone}
                    />
                  </Box>
                </Box>
              )}

              {mobile && !mobCalView ? (
                <MobileSlotsToggler
                  mobile={mobile}
                  date={date}
                  theme={theme}
                  daySlots={daySlots}
                  selectedSlot={selectedSlot}
                  setSelectedSlot={setSelectedSlot}
                  t={t}
                  timezone={selectedTimezone}
                />
              ) : (
                <CalendarComponent
                  mobile={mobile}
                  date={date}
                  theme={theme}
                  shareableData={shareableData}
                  calendarRef={calendarRef}
                  setNavLink={setNavLink}
                  eventDays={eventDays}
                  navLink={navLink}
                  handleCalendarSelect={handleCalendarSelect}
                  t={t}
                />
              )}
              {mobile && (
                <Box
                  display="flex"
                  justifyContent="center"
                  mt={mobile ? 4 : 0}
                  mb={mobile ? 4 : 0}
                >
                  <Box>
                    <Link to="/login" style={{ textDecoration: "none" }}>
                      <Button
                        variant="outlined"
                        sx={{ mr: "10px" }}
                        className={classes.btn}
                      >
                        {t("cancel")}
                      </Button>
                    </Link>
                  </Box>
                  <Box>
                    <Button
                      sx={{
                        ml: "10px",
                      }}
                      className={classes.btn}
                      variant="contained"
                      onClick={handleCreate}
                      disabled={selectedSlot ? false : true}
                    >
                      {t("done")}
                    </Button>
                  </Box>
                </Box>
              )}
            </Grid>

            <CreateShareableMeetingDialog
              initiatorEmail={shareableData?.userData?.email}
              popup={popup}
              setPopup={setPopup}
              createMeeting={createMeeting}
              disabled={disabled}
            />
            {mobile ? null : (
              <Grid item xs={12} md={3.5} className={classes.slotsWrapper}>
                <Box style={{ textAlign: "left" }}>
                  <Typography variant="h4" style={{ marginBottom: "4px" }}>
                    Timezone
                  </Typography>
                  <Timezones
                    selectedTimezone={selectedTimezone}
                    setSelectedTimezone={setSelectedTimezone}
                  />
                </Box>
                <Box flexGrow={1}>
                  <MobileSlotsToggler
                    mobile={mobile}
                    date={date}
                    theme={theme}
                    daySlots={daySlots}
                    selectedSlot={selectedSlot}
                    setSelectedSlot={setSelectedSlot}
                    t={t}
                    timezone={selectedTimezone}
                  />
                </Box>
                <Box display="flex" justifyContent="center" mt={0} mb={0}>
                  <Box>
                    <Link to="/login" style={{ textDecoration: "none" }}>
                      <Button
                        variant="outlined"
                        sx={{ width: 120, mr: "10px", p: "6px 0px" }}
                      >
                        {t("cancel")}
                      </Button>
                    </Link>
                  </Box>
                  <Box>
                    <Button
                      sx={{
                        ml: "10px",
                        p: "8px 0px",
                      }}
                      className={classes.btn}
                      variant="contained"
                      onClick={handleCreate}
                      disabled={selectedSlot ? false : true}
                    >
                      {t("done")}
                    </Button>
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>
          <SkedingBanner />
        </div>
      </Style>
    )
  ) : (
    <Box>
      <Spinner />
    </Box>
  );
}

function Navigation(props) {
  const handleLeftClick = () => {
    props.calendarRef.current.getApi().prev();
    props.handleTitle();
  };

  const handleRightClick = () => {
    props.calendarRef.current.getApi().next();
    props.handleTitle();
  };

  return (
    // need to fix alignment of view selector and calendar
    // try using spacer with stack to achieve proper alignment
    <Grid container>
      <Grid item xs={12}>
        <Stack justifyContent="flex-end" direction="row">
          <IconButton onClick={handleLeftClick}>
            <ChevronLeftIcon fontSize="medium" sx={{ color: "#3C4242" }} />
          </IconButton>
          <IconButton onClick={handleRightClick}>
            <ChevronRightIcon fontSize="medium" sx={{ color: "#3C4242" }} />
          </IconButton>
        </Stack>
      </Grid>
    </Grid>
  );
}

function BadgedChip({
  slot,
  setSelectedSlot,
  selectedSlot,
  t,
  timezone,
  isOrganizer,
}) {
  var startTime = moment.tz(slot?.start, timezone).format("hh:mm a");
  const classes = useStyle();
  return slot ? (
    <Box className={classes.chipWrapper} mt={2} sx={{ flexBasis: ["33%"] }}>
      <Chip
        label={
          <Typography
            variant={isOrganizer ? "h4" : "h3"}
            fontWeight={500}
            style={{ color: "#000" }}
          >
            {startTime}
          </Typography>
        }
        className={isOrganizer ? classes.smallChip : classes.chip}
        sx={{
          background:
            selectedSlot === slot ? "rgba(114, 176, 238, 0.6)" : "#F7F7F9",
        }}
        clickable={true}
        onClick={() => {
          setSelectedSlot(slot);
        }}
      />
    </Box>
  ) : (
    <Typography>{t("no_mutual_day")}</Typography>
  );
}

export function MobileHeadingToggler({
  mobile,
  mobCalView,
  setMobCalView,
  t,
  isOrganizer,
}) {
  return (
    <Box
      display="flex"
      alignItems="center"
      style={{
        display: mobile ? (mobCalView ? "none" : "flex") : "none",
        width: "100%",
      }}
      mt={3}
      mb={"2px"}
    >
      <Box flexGrow={1}>
        <Typography
          variant={isOrganizer ? "h3" : "h2"}
          align="left"
          style={{ fontWeight: 500 }}
        >
          {t("set_date")}
        </Typography>
      </Box>

      <Button
        variant="text"
        style={{ padding: 0 }}
        onClick={() => setMobCalView((prev) => !prev)}
        startIcon={<CalendarMonthOutlinedIcon />}
      >
        <Typography
          variant={isOrganizer ? "h5" : "h4"}
          color="primary"
          align="left"
          style={{ fontWeight: 500 }}
        >
          {t("view_calendar")}
        </Typography>
      </Button>
    </Box>
  );
}

export function MobileSlotsToggler({
  date,
  isOrganizer,
  daySlots,
  selectedSlot,
  setSelectedSlot,
  t,
  timezone,
}) {
  const [modal, setModal] = useState(false);
  const classes = useStyle();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [nameErrorMessage, setNameErrorMessage] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [searchParams] = useSearchParams();
  const [success, setSuccess] = useState("");
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [formDate, setFormDate] = useState(() => {
    const initialMoment = moment(date);
    return initialMoment;
  });
  const currentDate = new Date();

  const handleName = (e) => {
    setName(e.target.value);
    setNameErrorMessage("");
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
    setEmailErrorMessage("");
  };

  const handleClick = () => {
    setFormDate(moment(date));
    setModal(!modal);
  };

  const handleCancel = () => {
    setModal(false);
    setEmail("");
    setName("");
  };

  const handleProceed = async () => {
    const startTime = new Date(date);
    startTime.setHours(0, 0, 0, 0);

    const endTime = new Date(date);
    endTime.setHours(23, 59, 59, 999);

    const userId = searchParams.get("userId");

    const data = {
      initiator_user_id: userId,
      email,
      name,
      startTime: startTime.getTime(),
      endTime: endTime.getTime(),
    };
    const response = await serverPOST(`/web-integration/waitlist`, data);
    if (response && response.constructor !== String) {
      setModal(false);
      setSuccess("You have successfully entered in waitlist");
    }
  };

  const handleDateChange = async (newValue) => {};

  return (
    <Box flexGrow={1}>
      <SnackbarComponent
        vertical="bottom"
        horizontal="center"
        handleClose={() => setSuccess("")}
        open={!!success}
        message={success}
      />
      <Box>
        <Typography
          variant={isOrganizer ? "h3" : "h2"}
          align="left"
          style={{ marginLeft: "0px", fontWeight: 500 }}
          mt={2}
        >
          {t("pick_for")}{" "}
          {date
            ? moment(date).format("DD/MM/YYYY")
            : moment().format("DD/MM/YYYY")}
        </Typography>
        <Typography
          variant={isOrganizer ? "h5" : "h4"}
          align="left"
          fontWeight={400}
          className={classes.desc}
        >
          {t("shareable_desc")}
        </Typography>
      </Box>

      <Box display="flex" flexWrap="wrap" gap={"8px"}>
        {daySlots.length > 0 ? (
          daySlots.map((slot, index) => {
            return (
              <BadgedChip
                key={index}
                slot={slot}
                selectedSlot={selectedSlot}
                setSelectedSlot={setSelectedSlot}
                t={t}
                timezone={timezone}
                isOrganizer={isOrganizer}
              />
            );
          })
        ) : (
          <Typography
            align="center"
            variant={isOrganizer ? "h4" : "h3"}
            style={{ width: "100%", marginTop: 10 }}
          >
            {moment(currentDate).diff(moment(date), "seconds") < 0 ? (
              <>
                No available timeslots on the selected day. You can select
                another day or{" "}
                <span className={classes.blue} onClick={handleClick}>
                  Join Waiting List
                </span>
              </>
            ) : (
              <>Cannot select slot on past date.</>
            )}
          </Typography>
        )}
        {modal && (
          <DialogWithCloseIcon
            open={modal}
            handleClose={handleCancel}
            dialogSize={"xs"}
          >
            <Box className={classes.addServiceContainer}>
              <Typography variant="h2">{"Join Waiting List"}</Typography>
              <Typography variant="body1">
                Select a date from the calendar below to see available
                timelslots
              </Typography>
              <Box display="flex" flexDirection={"column"} gap={2} mt={1}>
                <Button
                  variant="text"
                  style={{
                    width: "135px",
                    marginLeft: 6,
                    padding: 0,
                  }}
                  disableRipple={true}
                >
                  <Datepicker
                    open={calendarOpen}
                    onChange={(newValue) => {
                      setFormDate(newValue);
                      handleDateChange(newValue);
                    }}
                    toggler={() => setCalendarOpen((prev) => !prev)}
                    value={formDate}
                  />
                </Button>

                <Textfield
                  label={t("name")}
                  value={name}
                  handleChange={handleName}
                  placeholder={t("enter_name")}
                  isBorder={false}
                  name={"name"}
                  error={nameErrorMessage}
                />

                <Textfield
                  label={t("email")}
                  value={email}
                  handleChange={handleEmail}
                  placeholder={t("enter_email")}
                  isBorder={false}
                  name={"email"}
                  error={emailErrorMessage}
                />
                <Box className={classes.buttonGroup} mt={1} mb={1}>
                  <Button variant="outlined" onClick={handleCancel}>
                    {t("cancel")}
                  </Button>
                  <Button variant="contained" onClick={handleProceed}>
                    {t("proceed")}
                  </Button>
                </Box>
              </Box>
            </Box>
          </DialogWithCloseIcon>
        )}
      </Box>
    </Box>
  );
}

export function CalendarComponent({
  mobile,
  theme,
  calendarRef,
  setNavLink,
  eventDays,
  navLink,
  handleCalendarSelect,
  t,
  isOrganizer,
}) {
  const [title, setTitle] = useState("");
  const classes = useStyle();

  useEffect(() => {
    handleTitle();
  }, []);

  const handleTitle = () => {
    const viewTitle =
      calendarRef?.current?.getApi()?.currentDataManager?.data?.viewTitle;
    setTitle(viewTitle);
  };

  return (
    <>
      {/* <Box mb={2}>
        <BreadCrumb selected={2} isClient={!isOrganizer} />
      </Box> */}
      <Box mt={mobile ? 3 : 0}>
        <Typography
          variant={isOrganizer ? "h3" : "h2"}
          align="left"
          style={{ marginLeft: "0px", fontWeight: 500 }}
        >
          {t("pick_date")}
        </Typography>
        <Typography
          variant={isOrganizer ? "h5" : "h4"}
          align="left"
          fontWeight={400}
          className={classes.desc}
        >
          {t("shareable_desc2")}
        </Typography>
      </Box>

      <Card
        className="create-calendar"
        sx={{
          boxShadow: "0",
          borderRadius: "10px",
          backgroundColor: theme.palette.secondary.main,
          padding: "15px",
          boxSizing: "border-box",
          marginTop: "12px",
        }}
      >
        <Stack direction="column" justifyContent="center" spacing="2px">
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing="2px"
          >
            <Box style={{ width: "100%", textAlign: "left" }}>
              <Typography variant={isOrganizer ? "h3" : "h2"}>
                {/* {!date
                  ? shareableData
                    ? new Date(
                        shareableData?.suggestions?.allAvailbleSlots[0]?.startTime
                      ).toLocaleString("en-US", {
                        weekday: "long",
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })
                    : new Date(publicData?.slots[0]).toLocaleString("en-US", {
                        weekday: "long",
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })
                  : date.toLocaleString("en", {
                      weekday: "long",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })} */}
                {title && t(title.split(" ")[0]) + " " + title.split(" ")[1]}
              </Typography>
            </Box>
            <Navigation
              calendarRef={calendarRef}
              setNavLink={setNavLink}
              handleTitle={handleTitle}
              isOrganizer={isOrganizer}
            />
          </Stack>
          <Box
            className={classes.calBox}
            sx={{
              height: {
                xs: isOrganizer ? "300px" : "380px",
                md: isOrganizer ? "300px" : "480px",
              },
            }}
          >
            {eventDays && (
              <FullCalendar
                plugins={[dayGridPlugin, interactionPlugin]}
                className="create-calendar-arrows create-calendar-date"
                initialView="dayGridMonth"
                expandRows={true}
                slotLabelFormat={{
                  hour: "numeric",
                  minute: "2-digit",
                  hour12: true,
                }}
                height={"100%"}
                events={eventDays}
                eventDisplay="background"
                eventTextColor={colors.primary}
                eventBackgroundColor={"rgba(150, 220, 255, 0.5)"}
                headerToolbar={{
                  left: "",
                  center: "",
                  right: "",
                }}
                slotEventOverlap={false}
                displayEventTime={false}
                views={{
                  dayGridMonth: {
                    dayHeaderFormat: { weekday: "short" },
                  },
                }}
                navLinks={navLink}
                // selectable={true}
                dateClick={handleCalendarSelect}
                // select={handleCalendarSelect}
                ref={calendarRef}
              />
            )}
          </Box>
        </Stack>
      </Card>
    </>
  );
}
