import { useEffect, useMemo, useRef, useState } from "react";

import {
  Box,
  Typography,
  Chip,
  Stack,
  useTheme,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import moment from "moment";
import useMeetingStore from "../../Utils/createMeetingStore";
import ColorIndicator from "../meetings/ColorIndicator";
import {
  BUSY_SLOTS_COLOR,
  MODERATE_SLOTS_COLOR,
  OPEN_SLOTS_COLOR,
} from "../../Utils/Constants";
import { useTranslation } from "react-i18next";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

/**
 * Displays the recommended time slot chips in a box on the new meeting page
 */
export default function RecommendedTimes(props) {
  const { times, formDate, scrollToElem, eventCase, createMeeting } = props;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  const boxRef = useRef(null);
  const { t } = useTranslation();

  //uses the last slice to determine how many times to show in the view
  // useEffect(() => {
  //   if (leftClick === false) {
  //     switch (lastSlice % 6) {
  //       case 1:
  //         setView(timesComponents?.slice(lastSlice - 1, lastSlice));
  //         break;
  //       case 2:
  //         setView(timesComponents?.slice(lastSlice - 2, lastSlice));
  //         break;
  //       case 3:
  //         setView(timesComponents?.slice(lastSlice - 3, lastSlice));
  //         break;
  //       case 4:
  //         setView(timesComponents?.slice(lastSlice - 4, lastSlice));
  //         break;
  //       case 5:
  //         setView(timesComponents?.slice(lastSlice - 5, lastSlice));
  //         break;
  //       default:
  //         setView(timesComponents?.slice(lastSlice - 6, lastSlice));
  //         break;
  //     }
  //   } else {
  //     setView(timesComponents.slice(lastSlice - 6, lastSlice));
  //   }
  // }, [lastSlice, leftClick, times]);

  // useEffect(() => {
  //   if (dateObject) {
  //     if (formDate) {
  //       const eventStartDate =
  //         times.length > 0 && moment(times[0]?.start).startOf("day");
  //       const eventEndDate =
  //         times.length > 0 &&
  //         moment(times[times.length - 1]?.start).startOf("day");

  //       const time = formDate.toDate();
  //       time.setHours(0);
  //       time.setMinutes(0);
  //       time.setSeconds(0);
  //       time.setMilliseconds(0);
  //       if (formDate.isBetween(eventStartDate, eventEndDate, "[]")) {
  //         moveToDateInRecommended();
  //       }
  //     }
  //   }
  // }, [dateObject]);

  // useEffect(() => {
  //   if (dateObject) {
  //     // const newDate = moment(dateObject.recommendedDate);
  //     // const diff = moment(newDate).diff(moment(), "days");
  //     // const calendarMonth = moment().month(dateObject.month).format("M");

  //     // if (diff >= 30 && months.includes(calendarMonth)) {
  //     //   const lastValue = times.length > 0 && times[times.length - 1];
  //     //   appendSlots(new Date(lastValue.end));
  //     //   const index = months.indexOf(calendarMonth);
  //     //   if (index > -1) {
  //     //     months.splice(index, 1);
  //     //   }
  //     // }
  //     moveToDateInRecommended();
  //   }
  // }, [dateObject]);

  // useEffect(() => {
  //   if(view.length > 0) {
  //     setDateDropdownToFirstInView()
  //   }
  // }, [view])

  //   const renderRecommended = () => {
  //     const timeComponents =
  //       times &&
  //       times.map((time, i) => {
  //         var startTime = moment(time?.start).format("hh:mm a");
  //         var endTime = moment(time?.end).format("hh:mm a");
  //         var startDate = moment(time?.start).format("ddd, MMM D");

  //         return (
  //           <Box
  //             style={{
  //               display: "flex",
  //               // flexDirection: "column",
  //               alignItems: "center",
  //               textAlign: "center",
  //               marginBottom: 5,
  //               marginRight: "5px",
  //             }}
  //             key={i}
  //             data-start={time?.start}
  //             //   ref={scrollTo}
  //           >
  //             <Chip
  //               label={
  //                 <div>
  //                   <Typography
  //                     style={{ color: theme.palette.info.light, marginTop: 5 }}
  //                     variant="h4"
  //                     fontWeight={300}
  //                   >
  //                     {startDate}
  //                   </Typography>
  //                   <Typography
  //                     style={{ color: theme.palette.text.primary }}
  //                     variant="h3"
  //                     fontWeight={400}
  //                   >
  //                     {mobile ? startTime : startTime + " - " + endTime}
  //                   </Typography>{" "}
  //                 </div>
  //               }
  //               key={i}
  //               id={i}
  //               sx={{
  //                 background: theme.palette.secondary.light,
  //                 borderRadius: "8px",
  //                 p: { xs: "20px 8px", md: "20px 0px 25px 0px" },
  //                 "& .MuiChip-label": {
  //                   paddingLeft: "8px",
  //                   paddingRight: "8px",
  //                 },
  //               }}
  //               clickable={true}
  //               onClick={(e) => props.handleMoveRecommended(e, i)}
  //             />
  //           </Box>
  //         );
  //       });
  //     return timeComponents;
  //   };

  // const timesComponents = renderRecommended();

  // const handleLeftClick = () => {
  //   setLeftClick(true);

  //   switch (lastSlice - view?.length <= 0) {
  //     case true:
  //       setLastSlice(6);
  //       break;
  //     case false:
  //       setLastSlice(lastSlice - view?.length);
  //       break;
  //     default:
  //       break;
  //   }
  // };

  // const handleRightClick = () => {
  //   setLeftClick(false);

  //   switch (lastSlice + 6 >= timesComponents?.length) {
  //     case true:
  //       setLastSlice(timesComponents?.length);
  //       break;
  //     case false:
  //       setLastSlice(lastSlice + 6);
  //       break;
  //     default:
  //       break;
  //   }
  // };

  return (
    <>
      <Stack justifyContent="flex-start" direction="row" alignItems="center">
        {props.noSlot ? (
          <Box mt={1}>
            <Chip
              label={
                <div>
                  <Typography
                    style={{ color: theme.palette.info.light, marginTop: 5 }}
                    variant="h4"
                    fontWeight={300}
                  >
                    {t("no_slot_msg")}
                  </Typography>
                </div>
              }
              sx={{
                background: theme.palette.secondary.light,
                borderRadius: "8px",
                p: { xs: 0, md: "20px 0px 25px 0px" },
                width: 140,
              }}
            />
          </Box>
        ) : (
          <>
            <Box
              spacing="8px"
              display="flex"
              justifyContent={
                eventCase === "NonSignUpOneSelect" ? "flex-start" : "center"
              }
              sx={{
                overflowX: "auto",
                // flexWrap: "wrap",
                width: { xs: "100%", md: "630px" },
                // maxHeight: "150px",
                maxHeight: "200px",
                justifyContent: "flex-start",
              }}
              ref={boxRef}
            >
              <RecommendedChips
                times={times}
                theme={theme}
                mobile={mobile}
                props={props}
                // scrollToDate={scrollToDate}
                scrollToElem={scrollToElem}
                formDate={formDate}
                boxRef={boxRef}
                createMeeting={createMeeting}
                eventCase={eventCase}
              />
            </Box>
          </>
        )}
      </Stack>
    </>
  );
}

function RecommendedChips({
  times,
  theme,
  mobile,
  props,
  scrollToElem,
  createMeeting,
  boxRef,
  eventCase,
}) {
  // using state from store
  const { organizerMeetings } = useMeetingStore((state) => state);
  const [view, setView] = useState([]);
  const [lastSlice, setLastSlice] = useState(3);
  const { t } = useTranslation();

  useEffect(() => {
    if (scrollToElem !== null && scrollToElem !== undefined) {
      scrollToElem.scrollIntoView();
    } else if (boxRef.current !== null) {
      boxRef.current.scrollTop = "0px";
    }
  }, [scrollToElem]);

  const handleSlotClick = (event, date) => {
    const originalIndex = times.findIndex(
      (item) => item.start === date.start && item.end === date.end
    );
    if (originalIndex !== -1) {
      props.handleMoveRecommended(event, originalIndex);
    } else {
      console.log("Date not found in original array");
    }
  };

  const renderRecommended = useMemo(() => {
    // const memorizedChips = useMemo(() => {
    let groups = times.reduce((groups, slot) => {
      let date = moment(slot.start).format("DD-MM-YYYY");
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(slot);
      return groups;
    }, {});

    const groupArrays = Object.keys(groups).map((date) => {
      return {
        date,
        slots: groups[date],
      };
    });

    // return (
    // times &&
    // times

    const memorizedChips = groupArrays.map((g, index) => {
      return (
        <Box style={{ textAlign: "center" }} key={index}>
          <Box
            mt={1}
            mb={1}
            style={{
              position: "sticky",
              top: 0,
              backgroundColor: "white",
              zIndex: 10,
            }}
          >
            <Typography variant="h4">
              {moment(g.date, "DD-MM-YYYY").format("ddd DD")}
            </Typography>
          </Box>
          {g.slots.map((time, i) => {
            var startTime = moment(time?.start).format("hh:mm a");
            var endTime = moment(time?.end).format("hh:mm a");
            var startDate = moment(time?.start).format("ddd, MMM D");

            // identifier to compare date only
            var startToCompare = moment(time?.start).format("DD-MM-YYYY");

            let totalMeetings = 0;
            let totalDuration = 0;

            if (organizerMeetings && organizerMeetings[startToCompare]) {
              //total meetings
              totalMeetings = organizerMeetings[startToCompare]?.length;
              //total time
              totalDuration = organizerMeetings[startToCompare].reduce(
                (total, a) =>
                  a.duration_minutes ? total + a.duration_minutes : total + 0,
                0
              );
            }

            let backgroundColor = !createMeeting
              ? "#72B0EE80"
              : totalMeetings > 5 || totalDuration > 240
              ? BUSY_SLOTS_COLOR
              : totalMeetings > 3 || totalDuration > 120
              ? MODERATE_SLOTS_COLOR
              : OPEN_SLOTS_COLOR;

            return (
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                  marginBottom: 5,
                  marginRight: mobile ? "3px" : "5px",
                }}
                key={i}
                data-start={time?.start}
                data-startdate={time?.start.getDate()}
              >
                <Chip
                  label={
                    <div>
                      <Typography
                        style={{
                          color: theme.palette.text.primary,
                          marginTop: 6,
                          // fontSize: "9px",
                        }}
                        variant="h5"
                        fontWeight={400}
                      >
                        {startDate}
                      </Typography>
                      <Typography
                        style={{ color: theme.palette.text.primary }}
                        variant="h4"
                        fontWeight={400}
                      >
                        {mobile ? startTime : startTime + " - " + endTime}
                      </Typography>{" "}
                    </div>
                  }
                  key={i}
                  id={i}
                  sx={{
                    width: mobile ? "110px" : "inherit",
                    background: backgroundColor,
                    // background: "#72B0EE80",
                    borderRadius: "8px",
                    p: { xs: "20px 8px", md: "20px 0px 25px 0px" },
                    "&&:hover": {
                      opacity: 0.8,
                      background: backgroundColor,
                    },
                    "& .MuiChip-label": {
                      paddingLeft: "4px",
                      paddingRight: "4px",
                    },
                  }}
                  clickable={true}
                  // onClick={(e) => props.handleMoveRecommended(e, i)}
                  onClick={(e) => handleSlotClick(e, time)}
                />
              </Box>
            );
          })}
        </Box>
      );
    });
    // );
    // }, [times, createMeeting]);

    return memorizedChips;
  }, [times, createMeeting, eventCase]);

  const timesComponents = renderRecommended;

  useEffect(() => {
    switch (lastSlice % 3) {
      case 0:
        setView(timesComponents.slice(lastSlice - 3, lastSlice));
        break;
      case 1:
        setView(timesComponents.slice(lastSlice - 1, lastSlice));
        break;
      case 2:
        setView(timesComponents.slice(lastSlice - 2, lastSlice));
        break;
      default:
        break;
    }
  }, [lastSlice, timesComponents]);

  //renders the first view
  const handleView = () => {
    switch (lastSlice === 3) {
      case true:
        return timesComponents.slice(0, lastSlice);
      case false:
        return view;
      default:
        break;
    }
  };

  const handleLeftClick = () => {
    switch (lastSlice - view.length <= 0) {
      case true:
        setLastSlice(3);
        break;
      case false:
        setLastSlice(lastSlice - view.length);
        break;
      default:
        break;
    }
  };

  const handleRightClick = () => {
    switch (lastSlice + 3 >= timesComponents.length) {
      case true:
        console.log("true case");
        console.log(lastSlice);
        setLastSlice(timesComponents.length);
        break;
      case false:
        console.log("false case");
        console.log(lastSlice);
        setLastSlice(lastSlice + 3);
        break;
      default:
        break;
    }
  };
  // const memorizedChips = useMemo(() => {
  //   let groups = times.reduce((groups, slot) => {
  //     let date = moment(slot.start).format("DD-MM-YYYY");
  //     if (!groups[date]) {
  //       groups[date] = [];
  //     }
  //     groups[date].push(slot);
  //     return groups;
  //   }, {});

  //   const groupArrays = Object.keys(groups).map((date) => {
  //     return {
  //       date,
  //       slots: groups[date],
  //     };
  //   });

  //   return (
  //     // times &&
  //     // times

  //     groupArrays.map((g, index) => {
  //       return (
  //         <Box style={{ textAlign: "center" }} key={index}>
  //           <Box mt={1} mb={1}>
  //             <Typography variant="h4">{g.date}</Typography>
  //           </Box>
  //           {g.slots.map((time, i) => {
  //             var startTime = moment(time?.start).format("hh:mm a");
  //             var endTime = moment(time?.end).format("hh:mm a");
  //             var startDate = moment(time?.start).format("ddd, MMM D");

  //             // identifier to compare date only
  //             var startToCompare = moment(time?.start).format("DD-MM-YYYY");

  //             let totalMeetings = 0;
  //             let totalDuration = 0;

  //             if (organizerMeetings && organizerMeetings[startToCompare]) {
  //               //total meetings
  //               totalMeetings = organizerMeetings[startToCompare]?.length;
  //               //total time
  //               totalDuration = organizerMeetings[startToCompare].reduce(
  //                 (total, a) =>
  //                   a.duration_minutes ? total + a.duration_minutes : total + 0,
  //                 0
  //               );
  //             }

  //             let backgroundColor = !createMeeting
  //               ? "#72B0EE80"
  //               : totalMeetings > 5 || totalDuration > 240
  //               ? BUSY_SLOTS_COLOR
  //               : totalMeetings > 3 || totalDuration > 120
  //               ? MODERATE_SLOTS_COLOR
  //               : OPEN_SLOTS_COLOR;

  //             return (
  //               <Box
  //                 style={{
  //                   display: "flex",
  //                   alignItems: "center",
  //                   textAlign: "center",
  //                   marginBottom: 5,
  //                   marginRight: mobile ? "3px" : "5px",
  //                 }}
  //                 key={i}
  //                 data-start={time?.start}
  //                 data-startdate={time?.start.getDate()}
  //               >
  //                 <Chip
  //                   label={
  //                     <div>
  //                       <Typography
  //                         style={{
  //                           color: theme.palette.text.primary,
  //                           marginTop: 6,
  //                           // fontSize: "9px",
  //                         }}
  //                         variant="h4"
  //                         fontWeight={400}
  //                       >
  //                         {startDate}
  //                       </Typography>
  //                       <Typography
  //                         style={{ color: theme.palette.text.primary }}
  //                         variant="h3"
  //                         fontWeight={400}
  //                       >
  //                         {mobile ? startTime : startTime + " - " + endTime}
  //                       </Typography>{" "}
  //                     </div>
  //                   }
  //                   key={i}
  //                   id={i}
  //                   sx={{
  //                     width: mobile ? "110px" : "inherit",
  //                     background: backgroundColor,
  //                     // background: "#72B0EE80",
  //                     borderRadius: "8px",
  //                     p: { xs: "20px 8px", md: "20px 0px 25px 0px" },
  //                     "&&:hover": {
  //                       opacity: 0.8,
  //                       background: backgroundColor,
  //                     },
  //                     "& .MuiChip-label": {
  //                       paddingLeft: "4px",
  //                       paddingRight: "4px",
  //                     },
  //                   }}
  //                   clickable={true}
  //                   // onClick={(e) => props.handleMoveRecommended(e, i)}
  //                   onClick={(e) => handleSlotClick(e, time)}
  //                 />
  //               </Box>
  //             );
  //           })}
  //         </Box>
  //       );
  //     })
  //   );
  // }, [times, createMeeting]);

  // return <>{memorizedChips}</>;
  return (
    <Box style={{ margin: "auto" }}>
      {times?.length > 0 ? (
        <Stack
          justifyContent="flex-start"
          direction="row"
          alignItems="flex-start"
          // spacing="8px"
        >
          <IconButton
            onClick={() => handleLeftClick()}
            style={{ padding: "0px" }}
          >
            <ChevronLeftIcon />
          </IconButton>
          <Box display="flex">{handleView()}</Box>

          <IconButton
            onClick={() => handleRightClick()}
            style={{ padding: "0px" }}
          >
            <ChevronRightIcon />
          </IconButton>
        </Stack>
      ) : (
        <Box mt={1}>
          <Chip
            label={
              <div>
                <Typography
                  style={{ color: theme.palette.info.light, marginTop: 5 }}
                  variant="h4"
                  fontWeight={300}
                >
                  {t("no_slot_msg")}
                </Typography>
              </div>
            }
            sx={{
              background: theme.palette.secondary.light,
              borderRadius: "8px",
              p: { xs: 0, md: "20px 0px 25px 0px" },
              width: 140,
            }}
          />
        </Box>
      )}
    </Box>
  );
}
