import { useContext, useEffect, useState } from "react";

import {
  Box,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Button,
  Grid,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import DialogWithCloseIcon from "components/DialogWithCloseIcon";
import "rc-time-picker/assets/index.css";
import "styles/picker.css";
import { integrationContext } from "context/Integrations";
import SnackbarComponent from "components/Global/SnackbarComponent";
import useStyles from "styles/formInputs";
import { accountContext } from "../../context/user";
import FreeTrialDialog from "components/payment/FreeTrialDialog";
import { handleIsLock } from "../../Utils/CommonFunctions";
import useStyle from "./styles";
import Availability from "../../components/AddToWebsite/Availability";
import Services from "../../components/AddToWebsite/Services";
import useWebsiteStore from "../../Utils/websiteStore";
import { serverGET } from "../../Utils/HttpFunctions";
import CustomTabPanel from "../../components/Global/CustomTabPanel";
import AddDetails from "../../components/AddToWebsite/AddDetails";
import BookingForm from "../../components/AddToWebsite/BookingForm";
import { LeftLayout, RightLayout } from "../../components/TwoSectionLayout";
import SectionTabs from "../../components/AddToWebsite/SectionTabs";
import Booking from "../../components/AddToWebsite/Preview/Booking";
import ServiceOptions from "../../components/AddToWebsite/Preview/ServiceOptions";
import AvailabilityPreview from "../../components/AddToWebsite/Preview/AvailabilityPreview";
import { ReactComponent as LinkIcon } from "assets/link-icon.svg";
import WebsiteLocations from "../../components/AddToWebsite/WebsiteLocations";
import WebsiteLocationPreview from "../../components/AddToWebsite/Preview/WebsiteLocationPreview";
import WebsiteNotifications from "../../components/AddToWebsite/WebsiteNotifications";
import WebsiteNotificationPreview from "../../components/AddToWebsite/Preview/WebsiteNotificationPreview";

export default function AddToWebsite() {
  const { user: userAccounts } = useContext(accountContext);
  const classes = useStyles();
  const theme = useTheme();

  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  const [snackBar, setSnackBar] = useState(false);
  const { t } = useTranslation();
  const [isLocked, setisLocked] = useState(false);
  const pclasses = useStyle();
  const setCalendarData = useWebsiteStore((store) => store.setCalendarData);
  const calendarData = useWebsiteStore((store) => store.calendarData);
  const tabIndex = useWebsiteStore((store) => store.tabIndex);
  const setServices = useWebsiteStore((store) => store.setServices);

  useEffect(() => {
    if (userAccounts) setisLocked(handleIsLock(userAccounts));
  }, [userAccounts]);

  useEffect(() => {
    if (calendarData && calendarData.services?.length > 0) {
      setServices(calendarData.services);
    }
  }, [calendarData]);

  useEffect(() => {
    const fetchServices = async () => {
      const response = await serverGET(
        `/web-integration`,
        undefined,
        undefined
      );

      if (response && response.constructor !== String) {
        console.log("🚀 ~ fetchServices ~ response.data:", response.data);
        setCalendarData(response.data);
      }
    };
    fetchServices();
  }, []);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(
      `https://app.skeding.io/public-calendar?userId=${userAccounts?.id}`
    );
    setSnackBar(true);
  };

  return (
    <AddToWebsiteLocked isLocked={isLocked}>
      <Grid
        container
        sx={{
          opacity: isLocked ? "0.5" : "1",
        }}
      >
        <>
          <RightLayout scrollable={true} lgSize={4.5}>
            <Typography
              variant="h2"
              textAlign={"left"}
              style={{
                margin: "20px 20px 0 20px",
              }}
            >
              Add To Website
            </Typography>
            <SectionTabs />
            <CustomTabPanel value={tabIndex} index={0}>
              <AddDetails t={t} />
            </CustomTabPanel>
            <CustomTabPanel value={tabIndex} index={1}>
              <WebsiteLocations />
            </CustomTabPanel>

            <CustomTabPanel value={tabIndex} index={2}>
              <Services />
            </CustomTabPanel>
            <CustomTabPanel value={tabIndex} index={3}>
              <Availability />
            </CustomTabPanel>
            <CustomTabPanel value={tabIndex} index={4}>
              <BookingForm />
            </CustomTabPanel>
            <CustomTabPanel value={tabIndex} index={5}>
              <WebsiteNotifications />
            </CustomTabPanel>
          </RightLayout>
          {mobile ? (
            <></>
          ) : (
            <LeftLayout inverted={true} lgSize={7.5}>
              <Box pt="20px">
                <Box className={pclasses.copyContent}>
                  <Typography
                    variant="h3"
                    align="left"
                    style={{ paddingBottom: "10px" }}
                  >
                    {t("appointment_link")}
                  </Typography>

                  <Box className={pclasses.copyDialog}>
                    <Box display="flex" paddingTop="8px" width="auto">
                      <LinkIcon />
                    </Box>
                    <Box className={pclasses.copyInput}>
                      <Typography className={pclasses.link} variant={"h5"}>
                        {`${process.env.REACT_APP_WEB}/public-calendar?userId=${userAccounts?.id}`}
                      </Typography>
                    </Box>
                    <Button variant="submit" onClick={handleCopyLink}>
                      {t("copy")}
                    </Button>
                  </Box>
                </Box>
              </Box>

              <Typography variant="h3" textAlign={"left"} p={"20px"} pb={0}>
                {t("preview")}
              </Typography>
              <CustomTabPanel value={tabIndex} index={0}>
                <ServiceOptions />
              </CustomTabPanel>
              <CustomTabPanel value={tabIndex} index={1}>
                <WebsiteLocationPreview />
              </CustomTabPanel>
              <CustomTabPanel value={tabIndex} index={2}>
                <ServiceOptions />
              </CustomTabPanel>
              <CustomTabPanel value={tabIndex} index={3}>
                <AvailabilityPreview />
              </CustomTabPanel>
              <CustomTabPanel value={tabIndex} index={4}>
                <Booking />
              </CustomTabPanel>
              <CustomTabPanel value={tabIndex} index={5}>
                <WebsiteNotificationPreview />
              </CustomTabPanel>
            </LeftLayout>
          )}
        </>
      </Grid>
      <SnackbarComponent
        open={snackBar}
        handleClose={() => setSnackBar(false)}
        message={t("link_copied")}
        vertical={"bottom"}
        horizontal={"center"}
      />
    </AddToWebsiteLocked>
  );
}

export function Breaks(props) {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <FormControl
      variant="standard"
      sx={{ width: "100%" }}
      style={{ marginTop: 10, textAlign: "left" }}
    >
      <Select
        id="select-break-dropdown"
        value={props.breaks}
        onChange={props.handleBreaksDurationChange}
        label={t("no_breaks")}
        IconComponent={"div"}
        inputProps={{ textAlign: "start" }}
        disableUnderline={true}
        style={{
          textAlign: "left",
          color: theme.palette.text.primary,
        }}
        displayEmpty={true}
      >
        <MenuItem value="0"> {t("no_breaks")}</MenuItem>
        <MenuItem value="15">{t("15_mins")}</MenuItem>
        <MenuItem value="30">{t("30_mins")}</MenuItem>
        <MenuItem value="60">{t("60_mins")}</MenuItem>
      </Select>
    </FormControl>
  );
}

const AddToWebsiteLocked = ({ isLocked, children }) => {
  const [dialog, setDialog] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyle();

  useEffect(() => {
    if (isLocked) openDialog();
  }, [isLocked]);

  const openDialog = () => {
    setDialog(true);
  };

  const handleClose = () => {
    setDialog(false);
  };

  return (
    <Box position="relative">
      {isLocked && (
        <Box className={classes.lockedBox}>
          <DialogWithCloseIcon
            open={dialog}
            handleClose={handleClose}
            dialogSize={"sm"}
          >
            <FreeTrialDialog
              dialogTitle={t("upgrade_premium")}
              dialogDesc={t("free_limit_reached")}
              onClick={() => {
                navigate("/payment-services");
                handleClose();
              }}
            />
          </DialogWithCloseIcon>
        </Box>
      )}
      {children}
    </Box>
  );
};
