import React, { useEffect, useState, useContext, Suspense, lazy } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { useIdleTimer } from "react-idle-timer";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { tourContext } from "context/Tour";
import { authContext } from "context/Auth";
import Calendar from "pages/Calendar/Calendar";
import NewEvent from "pages/NewEvent";
import NavigationDrawer from "components/Navigation/NavigationDrawer";
import NavBar from "components/Navigation/NavBar";
import InitialSignup from "pages/InitialSignup";
import GoogleSignin from "pages/Signin/GoogleSigning";
import AppleSignin from "pages/Signin/AppleSigning";
import ZoomSigning from "pages/Signin/ZoomSigning";
import HubspotSignin from "pages/Signin/HubspotSigning";
import LoginEmailPass from "pages/LoginEmailPass";
import Logout from "pages/Logout";
import Contacts from "pages/Contacts";
import PaymentServices from "pages/PaymentServices/PaymentServices";
import OutlookSignin from "pages/Signin/OutlookSigning";
import TeamsSignin from "pages/Signin/TeamsSigning";
import UserMeetings from "pages/UserMeetings";
import ShareableMeetings from "pages/ShareableMeetings";
import ShareableCalendar from "pages/ShareableCalendar";
import AddToWebsite from "pages/AddToWebsite/AddToWebsite";
import PublicCalendar from "pages/PublicCalendar";
import OutlookIntegration from "pages/OutlookIntegration";
import GoogleIntegration from "pages/GoogleIntegration";
import SearchEvents from "pages/SearchEvents";
import InitiatorSelect from "pages/InitiatorSelect/InitiatorSelect";
import ErrorPage from "pages/ErrorPage";
import MeetingsProvider from "context/meetings";
import DesktopFlow from "components/NewEvent/DesktopFlow";
import Spinner from "./components/Global/Spinner";
import SubscriptionInvite from "./pages/SubscriptionInvite";
import useMeetingStore from "./Utils/createMeetingStore";
import Email from "./pages/Email";
import WebsiteBookingParticipant from "./pages/WebsiteBookingParticipant";

const ManageMembers = lazy(() => import("./pages/ManageMembers/ManageMembers"));
const Admin = lazy(() => import("pages/Admin"));
const Preferences = lazy(() => import("pages/Preferences"));
const QRCode = lazy(() => import("pages/QRCode/QRCode"));
const AccountDetails = lazy(() =>
  import("pages/AccountDetails/AccountDetails")
);
const RecoverPassword = lazy(() => import("pages/RecoverPassword"));
const NewPassword = lazy(() => import("pages/NewPassword"));
const TermsConditons = lazy(() =>
  import("components/TermsConditions/TermsConditons")
);
const Notifications = lazy(() => import("pages/Notifications"));
const AllSkedingMeetingInvitation = lazy(() =>
  import("pages/AllSkedingMeetingInvitation/AllSkedingMeetingInvitation")
);
const UserSignup = lazy(() => import("pages/MeetingSignup/UserSignup"));
const MultiInitiatorResponse = lazy(() =>
  import("pages/MultiInitiatorResponse/MultiInitiatorResponse")
);
const TokenExpire = lazy(() => import("pages/TokenExpire"));
const QRCodeMeetingNonSkeding = lazy(() =>
  import("pages/QrClient/QRCodeMeetingNonSkeding")
);

const QRCodeCard = lazy(() => import("pages/QrClient/QRCodeCard"));
// const Blogs = lazy(() => import("pages/Blogs"));
// const Blog = lazy(() => import("pages/Blog"));
// const BlogDetail = lazy(() => import("pages/BlogDetail"));

export default function UserRoutes() {
  const { isLogged } = useContext(authContext);
  const [check, setCheck] = useState(null);

  useEffect(() => {
    setCheck(checkIfLogged());
  }, [isLogged]);

  const checkIfLogged = () => {
    if (isLogged === false) {
      return <PublicRoute />;
    } else if (isLogged === true) {
      return <PrivateRoute />;
    } else {
      return null;
    }
  };

  return check;
}

function PrivateRoute() {
  const drawerWidth = 180;
  const { setRun } = useContext(tourContext);
  const { setIsLogged } = useContext(authContext);
  const { meetingModal } = useMeetingStore();
  const theme = useTheme();
  const extraSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [cookies, setCookie, removeCookie] = useCookies(["redirectPath"]);
  const location = useLocation();
  const { pathname } = location;
  console.log("🚀 ~ PrivateRoute ~ pathname:", pathname);
  const timeout = 7200000;

  const onIdle = () => {
    setIsLogged(false);
    removeCookie("redirectPath", { path: "/" });
    localStorage.clear();
    window.location.pathname = "/";
  };

  useIdleTimer({ onIdle, timeout });

  return (
    <Box sx={{ display: extraSmall ? "block" : "flex" }}>
      {pathname !== "/terms-conditions" &&
        pathname !== "/error" &&
        pathname !== "/public-calendar" &&
        pathname !== "/shareable-calendar" &&
        pathname !== "/website-booking-participant" && (
          <NavigationDrawer drawerWidth={drawerWidth} setRun={setRun} />
        )}
      <Box
        sx={{
          flexGrow: 1,
          marginLeft: pathname !== "/error" ? { xs: "16px", md: "16px" } : 0,
          marginRight: pathname !== "/error" ? { xs: "16px", md: "16px" } : 0,
        }}
      >
        {pathname !== "/terms-conditions" &&
          pathname !== "/error" &&
          pathname !== "/public-calendar" &&
          pathname !== "/shareable-calendar" &&
          pathname !== "/website-booking-participant" && (
            <NavBar drawerWidth={drawerWidth} />
          )}
        <Suspense fallback={<Spinner />}>
          <Routes>
            <Route exact path="/" element={<Navigate to="/dashboard" />} />
            <Route path="/google/callback" element={<GoogleSignin />} />
            <Route path="/outlook/callback" element={<OutlookSignin />} />
            <Route path="/zoom/callback" element={<ZoomSigning />} />
            <Route path="/hubspot/callback" element={<HubspotSignin />} />
            <Route path="/outlook/logout" element={<Logout />} />
            <Route path="/outlook/teams/callback" element={<TeamsSignin />} />
            <Route path="/dashboard" element={<Calendar />} />
            <Route path="/createmeeting" element={<NewEvent />} />
            <Route path="/preferences" element={<Preferences />} />
            <Route path="/accountdetails" element={<AccountDetails />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/contacts" element={<Contacts />} />
            <Route path="/payment-services" element={<PaymentServices />} />
            <Route path="/qr-code" element={<QRCode />} />
            {/* <Route path="/faqs" element={<FAQs />} /> */}
            <Route
              path="/meetings"
              element={
                <MeetingsProvider>
                  <UserMeetings />
                </MeetingsProvider>
              }
            />
            <Route path="/meeting-poll" element={<UserSignup />} />
            <Route path="/choose-meeting-time" element={<UserSignup />} />
            <Route path="/initiator-select" element={<InitiatorSelect />} />

            <Route path="/shareable-meeting" element={<ShareableMeetings />} />
            <Route
              path="/meeting-poll/results"
              element={<MultiInitiatorResponse />}
            />
            <Route
              path="/meeting-response"
              element={<AllSkedingMeetingInvitation />}
            />
            <Route path="/google/integration" element={<GoogleIntegration />} />
            <Route
              path="/outlook/integration"
              element={<OutlookIntegration />}
            />
            <Route path="/terms-conditions" element={<TermsConditons />} />
            <Route path="/shareable-calendar" element={<ShareableCalendar />} />
            <Route path="/search-events" element={<SearchEvents />} />
            <Route path="/error" element={<ErrorPage />} />
            {/* <Route path="*" element={<Navigate to={`/error?code=404`} />} /> */}
            <Route path="*" element={<Navigate to={`/`} />} />

            {/* BR-1 */}
            <Route path="/qrmeeting" element={<NewEvent />} />
            <Route path="/add-to-website" element={<AddToWebsite />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/manage-members" element={<ManageMembers />} />
            <Route
              path="/subscription-invite"
              element={<SubscriptionInvite />}
            />
            <Route path="/email" element={<Email />} />
            <Route path="/public-calendar" element={<PublicCalendar />} />
            <Route
              path="/website-booking-participant"
              element={<WebsiteBookingParticipant />}
            />
          </Routes>
          {meetingModal && <DesktopFlow />}
        </Suspense>
      </Box>
    </Box>
  );
}

function PublicRoute() {
  return (
    <Suspense fallback={<Spinner />}>
      <Routes>
        <Route path="/register" exact element={<InitialSignup />} />
        <Route path="/login" element={<LoginEmailPass />} />
        <Route path="/forgotpassword" element={<RecoverPassword />} />
        <Route path="/google/callback" element={<GoogleSignin />} />
        <Route path="/outlook/callback" element={<OutlookSignin />} />
        <Route path="/apple/callback" element={<AppleSignin />} />
        <Route path="/zoom/callback" element={<ZoomSigning />} />
        <Route path="/hubspot/callback" element={<HubspotSignin />} />
        <Route path="/choose-meeting-time" element={<UserSignup />} />
        <Route
          path="/meeting-response"
          element={<AllSkedingMeetingInvitation />}
        />
        <Route path="/meeting-poll" element={<UserSignup />} />
        <Route path="/shareable-calendar" element={<ShareableCalendar />} />
        <Route
          path="/meeting-poll/results"
          element={<MultiInitiatorResponse />}
        />
        <Route path="/token-expire" element={<TokenExpire />} />
        <Route path="/reset-password" element={<NewPassword />} />
        <Route path="/public-calendar" element={<PublicCalendar />} />
        <Route
          path="/website-booking-participant"
          element={<WebsiteBookingParticipant />}
        />
        <Route path="/qrmeeting" element={<QRCodeMeetingNonSkeding />} />
        <Route path="/qrcard" element={<QRCodeCard />} />
        <Route path="/error" element={<ErrorPage />} />
        <Route
          path="/preferences"
          element={<Navigate to={"/login?redirect_path=preferences"} />}
        />
        {/* <Route path="*" element={<Navigate to={`/error?code=404`} />} /> */}

        <Route path="/subscription-invite" element={<SubscriptionInvite />} />
        <Route path="*" element={<Navigate to={"/login"} />} />

        {/* BR-1 */}
        {/* <Route path="/resources" element={<Blogs />} />
      <Route path="/resources/:id" element={<Blog />} />
      <Route path="/blog/details" element={<BlogDetail />} /> */}
      </Routes>
    </Suspense>
  );
}
