import { makeStyles } from "@mui/styles";

export const useStyle = makeStyles((theme) => ({
    tooltip: {
        backgroundColor: '#fff',
        color: '#363B53',
        boxShadow: '0 1px 4px rgba(0,0,0,0.25)',
        fontSize: 14,
        fontWeight: 'normal',
        borderRadius: '12px',
        padding: "16px",
        '& .MuiTooltip-arrow': {
          color: '#fff',
        },
    },
    arrow: {
        color: '#fff',
        '&:before': {
          backgroundColor: '#fff',
        },
    },
    buttonDisabled: {
      color: "rgba(0, 0, 0, 0.26)",
      border: "1px solid rgba(0, 0, 0, 0.12)",
      fontWeight: "500",
      '&:hover': {
        color: "rgba(0, 0, 0, 0.26)",
        border: "1px solid rgba(0, 0, 0, 0.12)",
        backgroundColor: "transparent"
      }
    },
    buttonEnabled: {
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`
    }
}))