import { useState, useEffect } from "react";

export const useDebounce = (value, milliSeconds) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    // if the value passed to the function (the query value) changes, the handler function waits the amount of time in milliseconds to return
    // the debounced value
    const timer = setTimeout(
      () => setDebouncedValue(value),
      milliSeconds || 1000
    );
    return () => {
      clearTimeout(timer);
    };
  }, [value, milliSeconds]);

  return debouncedValue;
};
