const getDesignTokens = (mode) => ({
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: "none",
          fontSize: 12,
          fontWeight: 600,
        },
      },
      variants: [
        {
          props: { variant: "selected" },
          style: {
            background: "#479AE9",
            color: "#FFFFFF",
            borderRadius: "6px",
            fontSize: "14px",
            fontWeight: "500",
            width: "20px",
            "&:hover": {
              background: "#2587E3",
              color: "#FFFFFF",
            },
            "@media (max-width:600px)": {
              padding: "3px 20px",
            },
          },
        },
        {
          props: { variant: "contained" },
          style: {
            backgroundColor: "#479AE9",
            color: "#FFFFFF",
            padding: "0px 16px",
            borderRadius: "6px",
            fontSize: "14px",
            fontWeight: 500,
            minWidth: "90px",
            // textTransform: "capitalize",
            "&:hover": {
              backgroundColor: "#2587E3",
              color: "#FFFFFF",
            },
            "@media (max-width:600px)": {
              // padding: "3px 20px",
            },
            "&:disabled": {
              color: "#F1F1F1",
            },
          },
        },
        {
          props: { variant: "unselected" },
          style: {
            fontSize: "14px",
            fontWeight: "500",
            background: "#F7F7F9",
            color: "#479AE9",
            borderRadius: "6px",
          },
        },
        {
          props: { variant: "submit" },
          style: {
            background: "#479AE9",
            color: "#FFFFFF",
            padding: "2px 16px",
            borderRadius: "6px",
            fontSize: "14px",
            fontWeight: 500,
            minWidth: "80px",
            "&:hover": {
              background: "#2587E3",
              color: "#FFFFFF",
            },
            "&:disabled": {
              color: "#F1F1F1",
              background: "#DAD2E5",
            },
            "@media (max-width:600px)": {
              padding: "3px 20px",
            },
          },
        },
        {
          props: { variant: "small-submit" },
          style: {
            background: "#479AE9",
            color: "#FFFFFF",
            padding: "1px 28px",
            borderRadius: "6px",
            fontSize: "14px",
            fontWeight: 500,
            minWidth: "90px",
            "&:hover": {
              background: "#2587E3",
              color: "#FFFFFF",
            },
            "&:disabled": {
              color: "#F1F1F1",
              background: "#DAD2E5",
            },
            "@media (max-width:600px)": {
              padding: "3px 20px",
            },
          },
        },
        {
          props: { variant: "instant" },
          style: {
            background: "#479AE9",
            color: "#FFFFFF",
            padding: "4px 20px",
            borderRadius: "6px",
            fontSize: "10px",
            fontWeight: 500,
            "&:hover": {
              backgroundColor: "#2587E3",
              color: "#FFFFFF",
            },
            "@media (max-width:600px)": {
              padding: "4px 20px",
            },
          },
        },
        {
          props: { variant: "cancel" },
          style: {
            backgroundColor: "transparent",
            border: mode === "light" ? "1px solid #3C4242" : "1px solid white",
            color: mode === "light" ? "#3C4242" : "white",
            fontSize: "14px",
            fontWeight: 500,
            textTransform: "capitalize",
            padding: "0px 28px",
            borderRadius: "6px",
            "@media (max-width:600px)": {
              // padding: "3px 20px",
            },
            "&:hover": {
              backgroundColor: "#EFFAFF",
              // border: "1px solid #479AE9",
              // color: "#479AE9",
            },
            textDecoration: "none",
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            border: mode === "light" ? "1px solid #3C4242" : "1px solid white",
            color: mode === "light" ? "#3C4242" : "white",
            padding: "0px 16px",
            borderRadius: "6px",
            fontSize: "14px",
            fontWeight: 500,
            minWidth: "90px",
            "&:hover": {
              backgroundColor: "#EFFAFF",
              border: "1px solid #479AE9",
              color: "#479AE9",
            },
            "@media (max-width:600px)": {
              padding: "0px 12px",
            },
            textDecoration: "none",
          },
        },
        {
          props: { variant: "reject" },
          style: {
            background: "#FF3D00",
            color: "#FFFFFF",
            borderRadius: "8px",
            padding: "4px 36px",
            fontSize: "12px",
            fontWeight: 600,

            "&:hover": {
              background: "#FF3D00",
              color: "#FFFFFF",
            },
            "@media (max-width:600px)": {
              padding: "3px 20px",
            },
          },
        },
      ],
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: "24px",
          height: "24px",
          fontSize: 10,
          color: "#3C4242",
          fontWeight: 500,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: "16px",
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          backgroundColor: "#3C4242",
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          fontSize: "16px",
          fontWeight: 400,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: "16px",
          fontWeight: 400,
        },
      },
    },

    // MuiTooltip: {
    //   styleOverrides: {
    //     tooltip: {
    //       color: "#3C4242",
    //       backgroundColor: "#FBFBFF",
    //       boxShadow: "2px 3px 15px 2px rgba(0, 0, 0, 0.05)",
    //       borderRadius: "26px",
    //     },
    //   },
    // },
  },
  palette: {
    mode,
    ...(mode === "light"
      ? {
          common: {
            black: "#000",
            white: "#fff",
          },
          primary: {
            main: "#479AE9",
            chipBg: "#72B0EE80",
            light: "#1E4CCC",
            dark: "#479AE9",
          },
          secondary: {
            main: "#F7F7F9",
            light: "#F7F7F9",
            gray: "#E0E2E7",
            lighter: "#F7F7F9",
            dark: "#3C4242",
            contrastText: "#FEFEFE",
          },
          background: {
            default: "#FEFEFE",
            light: "#F7F7F9",
          },
          text: {
            primary: "#3C4242",
            secondary: "#3C4242",
            disabled: "#70757A",
            line: "#B3B5B6",
          },
          info: {
            main: "#FBFBFF",
            light: "#70757A",
            dark: "#96DCFF",
            contrastText: "rgba(150, 220, 255, 0.25)",
          },
        }
      : {
          common: {
            black: "#000",
            white: "#fff",
          },
          primary: {
            main: "#479AE9",
            light: "#1E4CCC",
            dark: "#479AE9",
          },
          secondary: {
            main: "#212121",
            light: "#3C4242",
            dark: "#FEFEFE",
            contrastText: "#212121",
          },
          background: {
            default: "#3C4242",
            light: "#F7F7F9",
          },
          text: {
            primary: "#fff",
            secondary: "#000",
          },
          info: {
            main: "#212121",
            light: "#FEFEFE",
          },
        }),
  },

  typography: {
    fontFamily: "'Roboto', sans-serif",
    h1: {
      fontWeight: 500,
      fontSize: "32px",
      color: "#3C4242",
      "@media (max-width:600px)": {
        // fontWeight: 700,
        // fontSize: "20px",
      },
    },
    h2: {
      fontWeight: 600,
      fontSize: "20px",
      color: "#3C4242",
      "@media (max-width:600px)": {
        // fontWeight: 600,
        // fontSize: "16px",
      },
    },
    h3: {
      fontWeight: 500,
      fontSize: "16px",
      color: "#3C4242",
      "@media (max-width:600px)": {
        // fontSize: "14px",
      },
    },
    h4: {
      fontWeight: 500,
      fontSize: "14px",
      color: "#3C4242",
      "@media (max-width:600px)": {
        // fontSize: "12px",
      },
    },
    h5: {
      fontWeight: 600,
      fontSize: "12px",
      color: "#3C4242",
      "@media (max-width:600px)": {
        // fontSize: "10px",
      },
    },
    h6: {
      fontWeight: 400,
      fontSize: "10px",
      color: "#3C4242",
      "@media (max-width:600px)": {
        // fontSize: "10px",
      },
    },
    body1: {
      fontWeight: 400,
      fontSize: "12px",
      color: "#3C4242",
    },
    body2: {
      fontWeight: 400,
      fontSize: "10px",
      color: "#3C4242",
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: "8px",
      color: "#3C4242",
    },
  },
});

export default getDesignTokens;
