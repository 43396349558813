import React, { useContext } from "react";
import { accountContext } from "../../../context/user";
import useWebsiteStore from "../../../Utils/websiteStore";
import PublicCalendarComponent from "../PublicCalendarComponent";
import useStyle from "../styles";

export default function AvailabilityPreview() {
  const details = useWebsiteStore((store) => store.details);
  const { user } = useContext(accountContext);
  const classes = useStyle();
  const publicData = {
    slots: [1711530000000, 1711540800000],
    userWebCalendarPreferences: {
      title: details.title,
      description: details.description,
      email: details.email,
      meeting_room: "",
      phone_number: details.phone_number,
      location: details.location,
      skeding_user_id: {
        first_name: user?.name.split(" ")[0],
        last_name: user?.name.split(" ")[1],
        picture: details.picture,
      },
    },
  };
  return (
    <div
      className={classes.servicePreview}
      style={{
        paddingTop: "1rem",
      }}
    >
      <PublicCalendarComponent
        handlePublicCreate={() => console.log("hello")}
        publicData={publicData}
        selectedSlot={null}
        setSelectedSlot={() => console.log("hello")}
        responseModal={false}
        loading={false}
        isOrganizer={true}
        meetingData={undefined}
        meetingId={undefined}
      />
    </div>
  );
}
