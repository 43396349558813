import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
  FormControl,
  Radio,
  FormControlLabel,
  RadioGroup,
  TextField,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";

import { ReactComponent as Google } from "assets/google.svg";
import { ReactComponent as OutlookIcon } from "assets/outlook.svg";
import { ReactComponent as Zoom } from "assets/zoom.svg";
import { ReactComponent as Teams } from "assets/teams.svg";
import { ReactComponent as GoogleMeet } from "assets/meet.svg";
import { ReactComponent as Hubspot } from "assets/hubspot.svg";
import { integrationContext } from "context/Integrations";
import { serverPOST, serverPUT } from "Utils/HttpFunctions";
import { checkUrl } from "Utils/CommonFunctions";
import { VIRTUAL_LINK } from "Utils/Constants";
import { useStyles } from "../styles/preferencesButton";
import StripeIcon from "assets/payment.png";
import HelpComponent from "components/Global/Help";
import DialogWithCloseIcon from "../components/DialogWithCloseIcon";
import { useTranslation } from "react-i18next";
import { serverGET } from "../Utils/HttpFunctions";

export default function Integrations() {
  const { user, getUser } = useContext(integrationContext);
  const { t } = useTranslation();
  const theme = useTheme();
  const [switchModal, setSwitchModal] = useState(false);
  const [linkModal, setLinkModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disconnectModal, setDisconnectModal] = useState({
    indicator: "",
    modal: false,
  });
  const classes = useStyles();

  const handleSwitchModal = () => {
    setSwitchModal((prev) => !prev);
  };
  const handleDisconnectModal = () => {
    setDisconnectModal({ indicator: "", modal: false });
  };

  const zoomConnect = () => {
    window.open(process.env.REACT_APP_ZOOM_CALLBACK, "_blank");
  };

  const teamsConnect = () => {
    window.open(process.env.REACT_APP_TEAMS_CALLBACK, "_self");
  };

  const googleConnect = () => {
    window.open(process.env.REACT_APP_GOOGLE_INTEGRATION, "_self");
  };

  const hubspotConnect = async () => {
    const response = await serverGET(`/hubspot/oauth`, undefined, undefined);
    if (response && response.constructor !== String) {
      window.open(response.data, "_self");
    }
  };

  const outlookConnect = () => {
    window.open(process.env.REACT_APP_OUTLOOK_INTEGRATION, "_self");
  };

  const zoomDisconnect = async () => {
    setLoading(true);
    // if the env is production it should redirect to marketplace and authorize disconnect from there
    // if env is dev it should make call to server to disconnect and open the window after
    if (process.env.development === "prod") {
      window.open("https://marketplace.zoom.us/user/installed", "_blank");
    } else {
      const response = await serverPUT(`/zoom/disconnect`, {});
      window.open("https://marketplace.zoom.us/user/installed", "_blank");
      if (response && response.constructor !== String) {
        if (response.data && response.code === 200) {
          setDisconnectModal({ indicator: "", modal: false });
          await getUser();
          setLoading(false);
          window.open("https://marketplace.zoom.us/user/installed", "_blank");
        }
      }
    }

    setLoading(false);
  };

  const teamsDisconnect = async () => {
    setLoading(true);
    const response = await serverPUT(`/outlook/teams/disconnect`, {});
    if (response && response.constructor !== String) {
      if (response.data && response.code === 200) {
        setDisconnectModal({ indicator: "", modal: false });
        await getUser();
      }
    }
    setLoading(false);
  };

  const hubspotDisconnect = async () => {
    setLoading(true);
    const response = await serverPUT(`/hubspot/disconnect`, {});
    if (response && response.constructor !== String) {
      if (response.data && response.code === 200) {
        setDisconnectModal({ indicator: "", modal: false });
        await getUser();
      }
    }
    setLoading(false);
  };

  const stripeConnect = async () => {
    const response = await serverPOST(`/payment/account`, {
      email: "appskeding@gmail.com",
    });
    if (response && response.constructor !== String) {
      window.open(response.data.url, "_self");
    }
  };
  return user ? (
    <div style={{ maxWidth: "520px" }}>
      <Typography align="left" variant="h3">
        {t("connect_calendar")}
      </Typography>
      {user && !user.is_google_synced && !user.is_microsoft_synced ? (
        <>
          <Box
            display="flex"
            alignItems="center"
            justifyContent={"space-between"}
            mt={4}
          >
            <Box display="flex" alignItems="center">
              <Google />
              <Typography variant={"h5"} style={{ marginLeft: 10 }}>
                Google
              </Typography>
            </Box>

            <Button variant="instant" onClick={googleConnect}>
              {t("connect")}
            </Button>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent={"space-between"}
            mt={4}
          >
            <Box display="flex" alignItems="center">
              <OutlookIcon />

              <Typography variant={"h5"} style={{ marginLeft: 10 }}>
                Outlook
              </Typography>
            </Box>
            <Button variant="instant" onClick={outlookConnect}>
              {t("connect")}
            </Button>
          </Box>
        </>
      ) : null}
      <Box mt={3}>
        <GoogleSwitch user={user} handleSwitchModal={handleSwitchModal} />
      </Box>
      <Box mt={3}>
        <OutlookSwich user={user} handleSwitchModal={handleSwitchModal} />
      </Box>

      {/* <>
          <Box
            display="flex"
            alignItems="center"
            justifyContent={"space-between"}
            mt={4}
          >
            <Box display="flex" alignItems="center">
              <Google />
              <Typography 
                variant="h3" 
                fontWeight="400"
                sx={{ marginLeft: "10px" }}
              >
                Google
              </Typography>
            </Box>

            <Button variant="instant" onClick={googleConnect}>
              Connect
            </Button>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent={"space-between"}
            mt={4}
          >
            <Box display="flex" alignItems="center">
              <OutlookIcon />

              <Typography 
                variant="h3" 
                fontWeight="400"
                style={{ marginLeft: 10 }}
              >
                Outlook
              </Typography>
            </Box>
            <Button variant="instant" onClick={outlookConnect}>
              Connect
            </Button>
          </Box>
        </> */}
      <Box display="flex" alignItems={"center"} sx={{ mt: 5 }}>
        <Typography align="left" variant="h3">
          {t("set_virtual")}
        </Typography>
        <Box ml={1}>
          <HelpComponent msg={VIRTUAL_LINK} position={"bottom"} />
        </Box>
      </Box>

      <Button
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          width: "100%",
          border: "1px solid #479AE9",
          color: theme.palette.text.primary,
          height: 30,
          marginTop: 10,
        }}
        startIcon={
          <LinkIcon
            style={{ transform: "rotate(45deg)", padding: "0px 6px" }}
          />
        }
        onClick={() => setLinkModal((prev) => !prev)}
      >
        <Typography
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            fontWeight: 400,
          }}
          variant="h4"
        >
          {user && user.virtual_link ? user.virtual_link : t("add_virtual")}
        </Typography>
      </Button>

      <Typography align="left" variant="h3" sx={{ mt: 5 }}>
        {t("connect_apps")}
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        justifyContent={"space-between"}
        mt={4}
        ml={1}
      >
        <Box display="flex" alignItems="center" columnGap="16px">
          <Zoom />
          <Typography variant={"h3"} fontWeight={400}>
            Zoom
          </Typography>
        </Box>

        <Button
          variant="small-submit"
          onClick={() => {
            user && user.is_zoom_synced
              ? setDisconnectModal({ indicator: "zoom", modal: true })
              : zoomConnect();
          }}
          className={user.is_zoom_synced ? classes.disconnect : null}
        >
          {user.is_zoom_synced ? t("disconnect") : t("connect")}
        </Button>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent={"space-between"}
        mt={"24px"}
        ml={1}
      >
        <Box display="flex" alignItems="center" columnGap="16px">
          <Teams />
          <Typography variant={"h3"} fontWeight={400}>
            Microsoft Teams
          </Typography>
        </Box>
        {/* {user && user.is_teams_synced ? ( */}
        <Button
          variant="small-submit"
          onClick={() => {
            user && user.is_zoom_synced
              ? setDisconnectModal({ indicator: "teams", modal: true })
              : teamsConnect();
          }}
          className={user.is_teams_synced ? classes.disconnect : null}
        >
          {user.is_teams_synced ? t("disconnect") : t("connect")}
        </Button>
      </Box>
      {user && user.is_google_synced ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent={"space-between"}
          mt={3}
          ml={1}
        >
          <Box display="flex" alignItems="center" columnGap="16px">
            <GoogleMeet />
            <Typography variant={"h3"} fontWeight={400}>
              Google Meet
            </Typography>
          </Box>
          <Button
            variant="small-submit"
            disabled={user.is_google_synced}
            className={user.is_google_synced ? classes.disconnect : null}
          >
            {t("connected")}
          </Button>
        </Box>
      ) : null}

      <Box
        display="flex"
        alignItems="center"
        justifyContent={"space-between"}
        mt={3}
        ml={1}
      >
        <Box display="flex" alignItems="center" columnGap="16px">
          <Hubspot style={{ width: 26, height: 26 }} />
          <Typography variant={"h3"} fontWeight={400}>
            HubSpot
          </Typography>
        </Box>
        <Button
          variant="small-submit"
          onClick={() => {
            user && user.is_hubspot_synced
              ? setDisconnectModal({ indicator: "hubspot", modal: true })
              : hubspotConnect();
          }}
          className={user.is_hubspot_synced ? classes.disconnect : null}
        >
          {user.is_hubspot_synced ? t("disconnect") : t("connect")}
        </Button>
      </Box>
      <SwitchDialogue
        switchModal={switchModal}
        handleClose={handleSwitchModal}
        googleConnect={googleConnect}
        outlookConnect={outlookConnect}
        t={t}
      />
      <DisconnectDialogue
        disconnectModal={disconnectModal?.modal}
        loading={loading}
        handleClose={handleDisconnectModal}
        label={disconnectModal?.indicator}
        handleProceed={
          disconnectModal?.indicator === "zoom"
            ? zoomDisconnect
            : disconnectModal?.indicator === "teams"
            ? teamsDisconnect
            : hubspotDisconnect
        }
        t={t}
      />
      <LinkDialogue
        linkModal={linkModal}
        setLinkModal={setLinkModal}
        user={user}
        getUser={getUser}
        t={t}
      />
    </div>
  ) : (
    <>{t("loading")}</>
  );
}

export function SwitchDialogue({
  switchModal,
  handleClose,
  outlookConnect,
  googleConnect,
  t,
}) {
  const [value, setValue] = useState("Google");
  const handleChange = (e) => {
    setValue(e.target.value);
  };
  const handleProceed = () => {
    if (value === "Google") {
      googleConnect();
    } else {
      outlookConnect();
    }
  };
  return (
    <DialogWithCloseIcon handleClose={handleClose} open={switchModal}>
      <Typography variant="h2">Switch Calendar</Typography>
      <Box mt={2} mb={2}>
        <FormControl>
          <RadioGroup
            aria-labelledby="radio-buttons-group"
            name="calendars"
            value={value}
            onChange={handleChange}
          >
            <FormControlLabel
              value="Google"
              control={<Radio style={{ color: "#479AE9" }} />}
              label={
                <Box display="flex" alignItems="center">
                  <Google />
                  <Typography variant={"h3"} style={{ marginLeft: 10 }}>
                    Google
                  </Typography>
                </Box>
              }
            />
            <FormControlLabel
              value="Outlook"
              control={<Radio style={{ color: "#479AE9" }} />}
              label={
                <Box display="flex" alignItems="center">
                  <OutlookIcon />
                  <Typography variant={"h3"} style={{ marginLeft: 10 }}>
                    Outlook
                  </Typography>
                </Box>
              }
            />
          </RadioGroup>
        </FormControl>
      </Box>
      <Box mb={"10px"}>
        <Button
          variant="outlined"
          onClick={handleClose}
          style={{
            padding: "1px 0",
          }}
        >
          {t("cancel")}
        </Button>
        <Button
          variant="contained"
          style={{
            marginLeft: 20,
            padding: "2px 0",
          }}
          onClick={handleProceed}
        >
          {t("proceed")}
        </Button>
      </Box>
    </DialogWithCloseIcon>
  );
}

function DisconnectDialogue({
  disconnectModal,
  handleClose,
  handleProceed,
  label,
  loading,
  t,
}) {
  return (
    <DialogWithCloseIcon handleClose={handleClose} open={disconnectModal}>
      <Typography variant="h3" style={{ padding: "30px" }}>
        {t("confirm_disconnect")} {label}?
      </Typography>
      <Box mb={"10px"}>
        <Button
          style={{
            marginRight: 20,
          }}
          variant="outlined"
          onClick={handleClose}
        >
          {t("cancel")}
        </Button>
        <Button
          variant="contained"
          style={{
            marginLeft: 20,
          }}
          onClick={handleProceed}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress style={{ color: "white" }} size={20} />
          ) : (
            t("proceed")
          )}
        </Button>
      </Box>
    </DialogWithCloseIcon>
  );
}

function LinkDialogue({ linkModal, setLinkModal, getUser, t }) {
  const [value, setValue] = useState("");
  const [error, setError] = useState("");

  const theme = useTheme();
  const handleChange = (e) => {
    if (error) setError("");
    setValue(e.target.value);
  };

  const handleProceed = async () => {
    if (!checkUrl(value)) {
      setError("Invalid url entered");
      return;
    }
    const response = await serverPUT(`/preference/virtual`, {
      virtual_link: value,
    });
    if (response && response.constructor !== String) {
      setLinkModal(false);
      await getUser();
    }
  };
  const handleClose = () => {
    setLinkModal((prev) => !prev);
  };
  return (
    <DialogWithCloseIcon handleClose={handleClose} open={linkModal}>
      <Typography variant="h2">{t("add_virtual")}</Typography>
      <Box
        style={{
          // width: "100%",
          border: "1px solid #479AE9",
          color: theme.palette.text.primary,
          minHeight: 30,
          display: "flex",
          alignItems: "center",
          marginTop: 14,
          marginBottom: 20,
          borderRadius: 8,
        }}
      >
        <TextField
          id="event-link"
          placeholder="https://..."
          variant="standard"
          multiline
          error={!!error}
          helperText={error}
          maxRows={3}
          value={value}
          sx={{ width: "100%" }}
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LinkIcon
                  style={{
                    transform: "rotate(45deg)",
                    padding: "0px 6px",
                    width: 16,
                  }}
                />
              </InputAdornment>
            ),
            disableUnderline: true,
            style: {
              fontSize: "12px",
              fontWeight: 400,
              color: theme.palette.text.disabled,
            },
          }}
        />
      </Box>

      <Box mb={"10px"}>
        <Button
          style={{
            padding: "1px 0",
          }}
          variant="outlined"
          onClick={handleClose}
        >
          {t("close")}
        </Button>
        <Button
          variant="contained"
          style={{
            marginLeft: 20,
            padding: "2px 0",
          }}
          onClick={handleProceed}
        >
          {t("proceed")}
        </Button>
      </Box>
    </DialogWithCloseIcon>
  );
}

export const GoogleSwitch = ({ user, handleSwitchModal }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("sm"));

  return user && user.is_google_synced ? (
    <Box
      display="flex"
      alignItems="center"
      justifyContent={"space-between"}
      flexWrap="wrap"
    >
      <Box display="flex" alignItems="center">
        <Google />
        <Typography
          variant={"h3"}
          fontWeight={400}
          style={{ marginLeft: 10 }}
          width={small ? "120px" : "170px"}
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {user?.google_data?.email}
        </Typography>
      </Box>
      <Button variant="small-submit" onClick={handleSwitchModal}>
        {t("switch")}
      </Button>
    </Box>
  ) : null;
};

export const OutlookSwich = ({ user, handleSwitchModal }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("sm"));

  return user && user.is_microsoft_synced ? (
    <Box
      display="flex"
      alignItems="center"
      justifyContent={"space-between"}
      flexWrap="wrap"
    >
      <Box display="flex" alignItems="center">
        <OutlookIcon />
        <Typography
          variant={"h3"}
          fontWeight={400}
          style={{ marginLeft: 10 }}
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          width={small ? "120px" : "170px"}
        >
          {user?.outlook_data?.email}
        </Typography>
      </Box>
      <Button variant="small-submit" onClick={handleSwitchModal}>
        {t("switch")}
      </Button>
    </Box>
  ) : null;
};
