import { useState, useEffect, useRef } from "react";
import { TextField, Box, Button, Typography, IconButton } from "@mui/material";
import loggingInDev from "loggingInDev";
import { serverPOST } from "Utils/HttpFunctions";
import { handleEmailValidation } from "Utils/CommonFunctions";
import ErrorSnackbar from "components/ErrorSnackbar";
import SnackbarComponent from "components/Global/SnackbarComponent";
import ImportDialogue from "./ImportDialogue";
import useStyles from "styles/formStyles";
import puseStyle from "./styles";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";

/**
 * The form used to create a new contact
 * Rendered on the page in desktop view, rendered inside the CreateContactDialog in mobile view
 */
export default function NewContactForm({
  theme,
  getContacts,
  createContactDialog,
  handleCreateContactDialog,
}) {
  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactOrganization, setContactOrganization] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [error, setError] = useState("");
  const [emailHelperText, setEmailHelperText] = useState("");
  const [success, setSuccess] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [importModal, setImportModal] = useState(false);
  const classes = useStyles();
  const pclasses = puseStyle();
  const inputRef = useRef();
  const { t } = useTranslation();

  /**
   * Disables the create button if the contact name or email fields are empty
   */
  useEffect(() => {
    if (contactName.trim() === "" || contactEmail === "") {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [contactName, contactEmail]);

  /**
   * Clears the form data
   */
  const clearFormData = () => {
    setContactName("");
    setContactEmail("");
    setEmailError(false);
    setEmailHelperText("");
    setContactOrganization("");
  };

  /**
   * Clears the form data when the user clicks the clear button
   */
  const handleClear = () => {
    clearFormData();
  };

  /**
   * General handler used for setting the new contact state variables
   */
  const handleFormData = (e, setter) => {
    setter(e.target.value);
  };

  /**
   * Handler for creating the contact
   * Checks if the email and contact name fields are valid
   */
  const handleCreate = () => {
    if (emailError === false && contactName) {
      createNewContact();
      setTimeout(() => {
        if (handleCreateContactDialog) {
          handleCreateContactDialog();
        }
      }, 1500);
    }
  };

  /**
   * Makes server request to create a new contact
   * Opens a snackbar with a success message if successful or error message if unsuccessful
   * @async
   */
  const createNewContact = async () => {
    setDisabled(true);
    setSuccess(false);

    var data = {
      name: contactName,
      email: contactEmail,
      organization: contactOrganization ? contactOrganization : undefined,
    };

    const response = await serverPOST("/contact", data);

    if (response && response.constructor !== String) {
      loggingInDev("accepted response =>", response);
      clearFormData();
      setSuccess(true);
    } else {
      setError(response);
      loggingInDev("error =>", response);
    }
    setDisabled(false);
    getContacts();
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <ErrorSnackbar
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        vertical={"bottom"}
        horizontal={"center"}
      />

      <SnackbarComponent
        open={success}
        handleClose={() => setSuccess(false)}
        message={t("snackbar_messages.3")}
        vertical={"bottom"}
        horizontal={"center"}
      />
      {!createContactDialog ? null : (
        <IconButton
          style={{
            marginLeft: "auto",
            display: "flex",
          }}
          onClick={
            createContactDialog ? handleCreateContactDialog : handleClear
          }
        >
          <CloseIcon />
        </IconButton>
      )}

      <Typography variant="h3" align="left">
        {t("create_new_contact")}
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        width="95%"
        mt="20px"
        // className={classes.root}
      >
        <Typography variant="h4">{t("name") + ":"}</Typography>
        <TextField
          placeholder={t("contact_name")}
          variant="standard"
          value={contactName}
          sx={{
            width: "100%",
            marginTop: "4px",
          }}
          InputProps={{
            disableUnderline: true,

            classes: { input: `${classes.input} ${pclasses.createInput}` },
          }}
          onChange={(e) => handleFormData(e, setContactName)}
          id="add-user-name"
        />
        <Typography variant="h4" marginTop="15px">
          {t("email") + ":"}
        </Typography>
        <Box style={{ marginTop: "4px", width: "100%" }}>
          <TextField
            placeholder={t("contact_email")}
            variant="standard"
            value={contactEmail}
            sx={{
              width: "100%",
            }}
            InputProps={{
              disableUnderline: true,
              classes: { input: ` ${pclasses.createInput}` },
            }}
            onChange={(e) => handleFormData(e, setContactEmail)}
            onBlur={() =>
              handleEmailValidation(inputRef, setEmailError, setEmailHelperText)
            }
            error={emailError}
            helperText={emailHelperText}
            id="add-user-email"
            inputRef={inputRef}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          mt: "20px",
          justifyContent: "center",
          columnGap: "20px",
        }}
      >
        <Button
          onClick={
            createContactDialog ? handleCreateContactDialog : handleClear
          }
          variant="outlined"
        >
          {t("clear")}
        </Button>
        <Button
          variant="small-submit"
          onClick={handleCreate}
          disabled={disabled}
        >
          {t("create")}
        </Button>
      </Box>
      <Box sx={{ marginTop: "20px" }}>
        <Button
          variant="submit"
          onClick={() => setImportModal(true)}
          sx={{
            marginLeft: "14px",
          }}
        >
          {t("update_contacts")}
        </Button>
        <ImportDialogue
          importModal={importModal}
          setImportModal={setImportModal}
          getContacts={getContacts}
          handleCreateContactDialog={handleCreateContactDialog}
        />
      </Box>
    </Box>
  );
}
