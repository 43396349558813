import { useState } from "react";

import {
  Badge,
  IconButton,
  Box,
  Chip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import moment from "moment";
import useStyle from "./styles";

export default function BadgedSelectedChip(props) {
  const [invisible, setInvisible] = useState(true);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));
  var startTime = moment(props?.selected?.start).format("hh:mm a");
  var endTime = moment(props?.selected?.end).format("hh:mm a");
  var startDate = moment(props?.selected?.start).format("ddd, MMM D");
  const classes = useStyle();
  return (
    <Badge
      color="primary"
      badgeContent={
        <IconButton onClick={() => props.handleRemoveClick(props.id)}>
          <CancelIcon className={classes.chipColor} />
        </IconButton>
      }
      invisible={invisible}
      onMouseOver={() => setInvisible(false)}
      onMouseOut={() => setInvisible(true)}
      sx={{
        "& .MuiBadge-badge": { maxWidth: "0", maxHeight: "35%" },
        marginTop: "10px",
        marginRight: "5px",
      }}
    >
      <Box className={classes.flexCenter}>
        <Chip
          label={
            <div>
              <Typography
                className={classes.chipDate}
                variant="h4"
                fontWeight={400}
              >
                {startDate}
              </Typography>
              <Typography
                style={{ color: theme.palette.text.primary }}
                variant="h3"
                fontWeight={400}
              >
                {mobile ? startTime : startTime + " - " + endTime}
              </Typography>{" "}
            </div>
          }
          sx={{
            background: theme.palette.primary.chipBg,
            p: { xs: "20px 8px", md: "20px 0px 25px 0px" },
          }}
          className={classes.chip}
          onMouseOut={() => setInvisible(true)}
          onMouseOver={() => setInvisible(false)}
        />
      </Box>
    </Badge>
  );
}
