import { Breadcrumbs, Typography, useTheme } from "@mui/material";
import React from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useTranslation } from "react-i18next";
import useWebsiteClientStore from "../../Utils/websiteClientStore";

export default function BreadCrumb({ selected, isClient }) {
  const theme = useTheme();
  const setSelectedLocation = useWebsiteClientStore(
    (state) => state.setSelectedLocation
  );
  const setSelectedService = useWebsiteClientStore(
    (state) => state.setSelectedService
  );
  const selectedService = useWebsiteClientStore(
    (state) => state.selectedService
  );
  const selectedLocation = useWebsiteClientStore(
    (state) => state.selectedLocation
  );
  const data = [
    selectedLocation ? selectedLocation?.name : "Location",
    selectedService ? selectedService?.name : "Service",
    "Date & Time",
  ];

  const actions = [
    () => {
      setSelectedLocation(null);
      setSelectedService(null);
    },
    () => {
      setSelectedService(null);
    },
  ];
  return (
    <Breadcrumbs
      separator={<ArrowForwardIosIcon style={{ width: 12 }} />}
      aria-label="breadcrumb"
    >
      {data.map((item, index) => (
        <Typography
          variant={!isClient ? "h5" : "h3"}
          onClick={selected >= index ? actions[index] : () => console.log("")}
          fontWeight={selected === index ? 500 : 400}
          color={selected === index ? theme.palette.primary.main : "inherit"}
          style={{
            textTransform: "capitalize",
            cursor: selected > index ? "pointer" : "default",
          }}
        >
          {item}
        </Typography>
      ))}
    </Breadcrumbs>
  );
}
