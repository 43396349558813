import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useContext, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate, useSearchParams } from "react-router-dom";
import DialogWithCloseIcon from "../components/DialogWithCloseIcon";
import { authContext } from "../context/Auth";
import { accountContext } from "../context/user";
import { serverGET, serverPOST, serverPUT } from "../Utils/HttpFunctions";
import SnackbarComponent from "../components/Global/SnackbarComponent";
import { useTranslation } from "react-i18next";
import ErrorSnackbar from "../components/ErrorSnackbar";

const SubscriptionInvite = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { isLogged } = useContext(authContext);
  const [cookie, setCookie] = useCookies(["redirectPath"]);
  const { user, getUser } = useContext(accountContext);
  const [modal, setModal] = useState("");
  const [joinTeamModal, setJoinTeamModal] = useState("");
  const { t } = useTranslation();
  const permitScheduler = searchParams.get("scheduler");
  const isSignUp = searchParams.get("userSignup");
  const isCalendar = searchParams.get("isCalendar");

  const [membership, setMembership] = useState<any>(null);
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (isLogged === false) {
      setCookie(
        "redirectPath",
        `${window.location.pathname}${window.location.search}`,
        { path: "/", maxAge: 300 }
      );
      navigate("/login");
    } else {
      if (user) {
        const membershipId = searchParams.get("membership");
        const getMembershipData = async () => {
          const response = await serverGET(
            `/account/${membershipId}`,
            undefined,
            undefined
          );
          if (response && response.constructor !== String) {
            const currentUser = response?.data?.members.find(
              (u) => u?.email === user?.email
            );
            setMembership(response.data);

            if (currentUser) {
              if (
                !permitScheduler &&
                currentUser.is_invitation_accepted !== -1
              ) {
                setModal("You have already responded to this action");
                return;
              }
              if (
                permitScheduler === "true" &&
                currentUser?.scheduler?.email === undefined
              ) {
                setModal("You have already responded to this action");
                return;
              }
            } else {
              setModal("You have been removed from the team");
            }
          }
        };
        getMembershipData();
      }
    }
  }, [isLogged, user]);

  const handleResponse = async (res, joinNewTeam) => {
    const membershipId = searchParams.get("membership");

    if (permitScheduler) {
      const response = await serverPUT(
        `/account/updatestatus/${membershipId}`,
        {
          permitScheduler: true,
          status: res,
        }
      );
      if (response && response.constructor !== String) {
        if (response?.data?.updated) {
          await getUser();
        }
        setJoinTeamModal(
          res
            ? joinNewTeam
              ? `Your previous plan is cancelled and now you are part of ${membership?.team_name}`
              : "You response has been recorded successfully"
            : "You response has been recorded successfully"
        );
      } else {
        console.log("response", response);
      }
    } else {
      const response = await serverPUT(
        `/account/updatestatus/${membershipId}`,
        {
          status: res,
        }
      );
      if (response && response.constructor !== String) {
        if (response?.data?.updated) {
          await getUser();
        }

        setJoinTeamModal(res ? t("toast_messages.2") : t("toast_messages.3"));
      } else {
        setMessage(response);
      }
    }
  };

  const handleClose = () => {
    setModal("");
    // setJoinTeamModal("");
    if (isSignUp) {
      let params = "?userSignup=true";
      if (isCalendar) {
        params = params + "&isCalendar=true";
      }
      navigate("/dashboard" + params);
    } else {
      navigate("/payment-services");
    }
  };

  const handleKeepPremium = () => {
    navigate("/");
    setJoinTeamModal(t("toast_messages.3"));
  };

  const handleCancelPlanJoinNewTeam = async () => {
    const response = await serverPOST("/account/cancel-subscription");
    if (response && response.constructor !== String) {
      // setRenewSubscription(true);
      await getUser();
      await handleResponse(1, true);
      // navigate("/payment-services");
    } else {
      // setSuccess("Something went wrong while cancelling your plan");
    }
  };

  const schedulerEmail = membership?.members?.find(
    (u) => u?.email === user?.email
  )?.scheduler.email;

  return (
    <>
      {user?.package?.paid &&
      user?.package?.membership?._id !== searchParams.get("membership") ? (
        <DialogWithCloseIcon
          handleClose={() => navigate("/")}
          open={true}
          dialogSize={undefined}
        >
          <Typography variant="h2">{t("leave_old_team")}</Typography>

          {!membership ? (
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress color="primary" />
            </Box>
          ) : (
            <>
              <Typography variant="h3">{t("leave_old_team_desc")}</Typography>
              <Typography variant="h3">{t("want_to_proceed")}</Typography>
            </>
          )}
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              mt: "30px",
              columnGap: "20px",
            }}
          >
            <Button variant="outlined" onClick={handleKeepPremium}>
              {t("keep_premium")}
            </Button>
            <Button variant="contained" onClick={handleCancelPlanJoinNewTeam}>
              {t("cancel_&_join_new")}
            </Button>
          </Box>
        </DialogWithCloseIcon>
      ) : (
        <DialogWithCloseIcon
          handleClose={() => navigate("/")}
          open={true}
          dialogSize={undefined}
        >
          {permitScheduler ? (
            <></>
          ) : (
            <Typography variant="h2">{t("join_team")}</Typography>
          )}
          {!membership ? (
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress color="primary" />
            </Box>
          ) : permitScheduler ? (
            <Typography variant="h3">
              {t("manageScheduleConfirmation", { schedulerEmail })}
            </Typography>
          ) : (
            <Typography variant="h3">
              {`${t("join_team_desc")} ${membership?.team_name}?`}
            </Typography>
          )}
          <Box
            display="flex"
            justifyContent="center"
            sx={{
              mt: "30px",
              columnGap: "20px",
            }}
          >
            <Button variant="outlined" onClick={() => handleResponse(0, false)}>
              {t("reject")}
            </Button>
            <Button
              variant="contained"
              onClick={() => handleResponse(1, false)}
            >
              {t("accept")}
            </Button>
          </Box>
        </DialogWithCloseIcon>
      )}

      <SnackbarComponent
        open={!!modal}
        handleClose={handleClose}
        message={modal}
        vertical={"bottom"}
        horizontal={"center"}
      />

      <SnackbarComponent
        open={!!joinTeamModal}
        handleClose={handleClose}
        message={joinTeamModal}
        vertical={"bottom"}
        horizontal={"center"}
      />

      <ErrorSnackbar
        open={!!message}
        handleClose={() => setMessage("")}
        message={message}
        vertical={"bottom"}
        duration={3000}
        horizontal={"center"}
      />
    </>
  );
};

export default SubscriptionInvite;
