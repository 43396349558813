import { useEffect, useState } from "react";
import { serverGET } from "Utils/HttpFunctions";
import { GET_USER_PREFERENCES_PATH } from "Utils/ApiPaths";
import { convertDateToUTCMilliseconds } from "Utils/CommonFunctions";
import { CAL_INFO, UNAVAILABLE_DATA } from "../Constants";
import loggingInDev from "loggingInDev";

export default function useAvailability(isEdit) {
  const days = ["Sun", "Mon", "Tue", "Wed", "Thr", "Fri", "Sat"];
  const [error, setError] = useState("");
  const [calInfo, setCalInfo] = useState(CAL_INFO);

  /**
   * Fetches user preferences after updating
   */
  useEffect(() => {
    if (!isEdit) {
      getPreferencesData();
    }
  }, []);

  /**
   * Fetches user preferences from the DB
   * @async
   */
  const getPreferencesData = async () => {
    try {
      const response = await serverGET(GET_USER_PREFERENCES_PATH);
      if (response) {
        if (response.code === 200) {
          const data = response.data;

          const result = data.available_timeslots.reduce(function (r, a) {
            r[a.start_day] = r[a.start_day] || [];
            r[a.start_day].push(a);
            return r;
          }, Object.create(null));

          const temp = [...calInfo];
          data.available_timeslots.map((item) => {
            temp[item.start_day] = {
              isActive: true,
              label: days[item.start_day],
              slot: result[item.start_day],
            };
          });
          setCalInfo(temp);
        }
      }
    } catch (e) {
      loggingInDev(e);
    }
  };

  const calculateEndDay = (day) => {
    if (day === 6) {
      return 0;
    } else return ++day;
  };

  /**
   * Saves the changes to the user preferences
   * @async
   */
  const handleSaveButtonClick = async () => {
    setError("");
    const filtered1 = await calInfo.filter((item) => {
      return item.isActive === true;
    });
    const errorCheck = filtered1.filter((f) =>
      f.slot.some((item) => {
        const startDate = new Date(
          2022,
          10,
          10,
          item.start_hours,
          item.start_minutes,
          0,
          0
        );
        const endDate = new Date(
          2022,
          10,
          item.end_hours === 0 ? 11 : 10,
          item.end_hours,
          item.end_minutes,
          0,
          0
        );
        return endDate < startDate;
      })
    );

    if (errorCheck.length > 0) {
      setError("end time must be greater than start time");
      return;
    }

    let slotsArr = [];
    const s = filtered1.forEach((sl) => {
      sl.slot.forEach((s) => {
        let obj;
        if (s.end_hours == 0 && (s.end_minutes == 0 || s.end_minutes == 30)) {
          obj = {
            start_hours: s.start_hours,
            start_minutes: s.start_minutes,
            start_day: s.start_day,
            end_hours: s.end_hours,
            end_minutes: s.end_minutes,
            end_day: calculateEndDay(s.start_day),
          };
        } else {
          obj = {
            start_hours: s.start_hours,
            start_minutes: s.start_minutes,
            start_day: s.start_day,
            end_hours: s.end_hours,
            end_minutes: s.end_minutes,
            end_day: s.end_day,
          };
        }

        slotsArr.push(obj);
      });
    });
    const groups = slotsArr.reduce((groups, slot) => {
      let start = slot.start_day;
      if (!groups[start]) {
        groups[start] = [];
      }
      groups[start].push(slot);
      return groups;
    }, {});

    const groupArrays = Object.keys(groups).map((start) => {
      return {
        start_day: parseInt(start),
        slots: groups[start],
      };
    });

    let unavail = [];
    for (let i = 0; i < UNAVAILABLE_DATA.length; i++) {
      let uv = UNAVAILABLE_DATA[i];

      let un = groupArrays.find((f) => f.start_day === uv.start_day);
      if (un) {
        let slotsLength = un.slots.length;

        for (let j = 0; j < slotsLength; j++) {
          let s = un.slots[j];

          if (s.start_hours === 0 && s.end_hours === 0) {
            continue;
          }

          if (s.start_hours === 0 && s.start_minutes === 0) {
            let obj = {
              start_day: s.start_day,
              start_hours: s.end_hours,
              start_minutes: 0,
              end_day: s.end_day,
              end_hours: j < slotsLength - 1 ? un.slots[j + 1].start_hours : 0,
              end_minutes: 0,
            };

            if (obj.end_hours === 0) {
              obj = {
                ...obj,
                end_day: obj.end_day === 6 ? 0 : obj.end_day + 1,
              };
            }
            unavail.push(obj);

            continue;
          }

          if (j == 0) {
            let obj = {
              start_day: s.start_day,
              start_hours: 0,
              start_minutes: 0,
              end_day: s.end_day,
              end_hours: s.start_hours,
              end_minutes: s.start_minutes,
            };

            if (obj.end_hours !== 0 && obj.end_day !== obj.start_day) {
              obj = {
                ...obj,
                end_day: obj.end_day === 0 ? 6 : obj.end_day - 1,
              };
            }
            unavail.push(obj);
          }

          if (
            (s.start_hours !== 0 && s.end_hours !== 0) ||
            (s.start_hours === 0 && s.start_minutes !== 0)
          ) {
            let obj2 = {
              start_day: s.start_day,
              start_hours: s.end_hours,
              start_minutes: s.end_minutes,
              end_day: s.end_day,
              end_hours: j < slotsLength - 1 ? un.slots[j + 1].start_hours : 0,
              end_minutes:
                j < slotsLength - 1 ? un.slots[j + 1].start_minutes : 0,
            };

            if (obj2.end_hours === 0) {
              obj2 = {
                ...obj2,
                end_day: obj2.end_day === 6 ? 0 : obj2.end_day + 1,
              };
            }
            unavail.push(obj2);
          }
        }
      } else {
        unavail.push(uv);
      }
    }
    const data = {
      blocked_timeslots: unavail,
      available_timeslots: slotsArr,
    };

    return data;
  };

  return {
    handleSaveButtonClick,
    calInfo,
    error,
    setCalInfo,
  };
}
